import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import "./collapse.css";
import { Collapse } from "antd";
import ExperienceConatiner from "../../ProfileCreation/Experience/ExperienceConatiner";
import ExperienceConatainerNew from "Profile/Experience/ExperienceContainer";
import styled from "styled-components";
import ReferenceContainer from "ProfileCreation/Reference/ReferenceContainer";
import CertificationsContainer from "Profile/Certifications/CertificationsContainer";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
import { Accordion, Button, Flex, Typography } from "venwiz-ui";
import { colors } from "Theme";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { HiOutlineTrash } from "react-icons/hi2";

const { Panel } = Collapse;

interface CollapseProps {
  isFirstError?: React.MutableRefObject<boolean>;
  validationState?: boolean;
  buttonType?: boolean;
  headerName?: string;
  subHeader?: string;
  data?: any;
  formik?: any;
  usedFor?: "certification" | "experience" | string;
  defaultActiveKey?: number;
  onSubmit?: () => void;
  onHandleAddCard?: (e: any) => void;
  handleAlertMessage?: (e: any) => void;
  handleValidation?: (e: any) => void;
  handleDeleteProject?: (id: any) => void;
  newExperienceLayout?: boolean;
  showDeleteOption: boolean;
  onHandleAddButton?: (e) => void;
  projectLength?: number;
  showNewAccordionLayout?: boolean;
  listOfExpandedAccordions?: number[];
  setListOfExpandedAccordions?: React.Dispatch<React.SetStateAction<number[]>>;
  containerPropsWidth?: string;
}

interface CollapseDivProps {
  usedFor?: "certification" | "experience" | string;
}

const Header = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  letter-spacing: 0.01em;

  color: #215ec9;
`;

const SubHeader = styled.div`
  color: ${props => `${props.theme.colors.primary}`};
  font-size: 11px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

const ImageFail = styled.div`
  background-image: url(/images/warning.svg);
  background-size: cover;
  margin-right: 3px;
  height: auto;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 50px;
  top: 20px;
`;

const ImgWrapper = styled.img`
  margin: 10px 0px 0px 12px;
  cursor: pointer;
@media only screen and (max-width: 640px) {
  margin: 0px 15px 0px 12px;
}
`;

const CollapseDiv = styled(Collapse)<CollapseDivProps>`
& .ant-collapse-content-box{
  padding:20px 0px 0px 0px;
}

& .ant-collapse-header {
  height: ${props => `${props.usedFor === "experience" ? '40px' :'80px' }`};
}
& .ant-collapse-item {
  width: 840px;
}
@media only screen and (max-width: 640px) {
  & .ant-collapse-item {
    width:100vw;
    max-width: 396px;
  }
  & .ant-collapse-header {
    width: 92vw;
  }
  & .ant-collapse-content-box{
    width:92.01vw;
  }
}
`;
const DesktopView=styled.div`
display:block;
@media only screen and (max-width: 640px) {
display:none;
}
`
const MobileView=styled.div`
display:none;
@media only screen and (max-width: 640px) {
display:block;
}
`
const CollapseComponent = ({
  handleAlertMessage,
  formik,
  onHandleAddCard,
  validationState,
  data,
  headerName,
  onSubmit,
  subHeader,
  usedFor,
  defaultActiveKey,
  handleValidation,
  handleDeleteProject,
  newExperienceLayout,
  projectLength,
  onHandleAddButton,
  isFirstError,
  showNewAccordionLayout,
  listOfExpandedAccordions, 
  setListOfExpandedAccordions,
  containerPropsWidth
}: CollapseProps) => {
  const [alert, setAlert] = useState(true);
  const [color, setColor] = useState("none");
  const [showDeleteOption, setShowDeleteOption] = useState(true);

  useEffect(() => {
    if (projectLength && projectLength <= 1) {
      setShowDeleteOption(false);
    } else {
      setShowDeleteOption(true);
    }
  }, [projectLength]);

  const renderTrashIcon = ( customIcon?: React.ReactNode | JSX.Element )=>{
    return <>
      {showDeleteOption  && usedFor === "experience" && (
        <>{
          customIcon || 
          <ImgWrapper
            onClick={() => handleDeleteProject(data.index)}
            src="/images/trash.svg"
          />
        }</>
      )}
    </>
  };

  /**
   * Show New Layout for Accordions
   */
  if (showNewAccordionLayout) {
    const KEY = data && data?.index ? data.index + 1 : 1;
    const IS_CURRENT_ACCORDION_EXPANDED = listOfExpandedAccordions 
      ? listOfExpandedAccordions?.includes(KEY) : false;

    return (
      <Accordion
        key={KEY}
        accordionOpenIcon={<></>}
        accordionCloseIcon={<></>}
        id={`update-profile-page-accordion-${KEY}`}
        isExpand={IS_CURRENT_ACCORDION_EXPANDED}
        onExpand={(value) => {
          if (setListOfExpandedAccordions) {
            setListOfExpandedAccordions(prev => {
              if (prev?.includes(KEY)) {
                /** Close the selected Accordion  */
                return prev?.filter(accordionKey => accordionKey !== KEY);
              }
              /** Open the selected Accordion  */
              return [KEY];
            })
          }
        }}
        // STYLES
        containerProps={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: { default: "100%", sm: containerPropsWidth || "70vw" },
        }}
        contentProps={{
          width: "100%",
          display: "flex",
          flexDirection: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { default: "4px", xxs: "4px" }
        }}
        headerProps={{
          height: "36px",
          color: "#fff",
          backgroundColor: "#215EC9",
        }}
        // JSX
        accordionTitle={
          <Flex
            flexDirection={"column"} 
            className={usedFor === "experience" ? "experience-div" : ""}
            backgroundColor={colors.blue}
          >
            <Flex 
              alignItems={"center"} 
              justifyContent={"space-between"}
            >
              <Typography
                fontStyle="normal"
                fontWeight="500"
                fontSize="14px"
                letterSpacing="0.01em"
                color="#fff"
              >
                {headerName}
              </Typography>
              <Flex gap={"8px"} alignItems={"center"} justifyContent={"flex-end"}>
                {renderTrashIcon(
                  <Flex
                    cursor="pointer"
                    alignItems={"center"}
                    justifyContent={"center"}
                    onClick={() => handleDeleteProject(data.index)}
                  >
                    <HiOutlineTrash fontSize={"22px"} />
                  </Flex>
                )}
                <Flex
                  cursor={"pointer"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                >
                  {IS_CURRENT_ACCORDION_EXPANDED ? (
                    <RxCaretUp fontSize={"30px"} />
                  ) : (
                    <RxCaretDown fontSize={"30px"} />
                  )}
                </Flex>
              </Flex>
            </Flex>
            {subHeader && (
              <Typography fontSize={"11px"} color={colors.white}>
                {subHeader}
              </Typography>
            )}
          </Flex>
        }
      >
        {usedFor === "experience" && newExperienceLayout && (
          <ExperienceConatainerNew
            isFirstError={isFirstError}
            handleAlertMessage={(e: any) => {
              setAlert(e);
            }}
            validationState={validationState}
            data={data}
          />
        )}
      </Accordion>
    );
  }

  /**
   * Old Accordion Style
   */
  return (
    <>
      <div style={{ display: "flex", alignItems: "start" }}>
        <CollapseDiv
          style={{
            backgroundColor: color,
            width: `${(usedFor === "experience" ||
              usedFor === "onboarding-certification") &&
              "100%"}`,
            marginRight: "10px",
          }}
          onChange={()=>{}}
          expandIconPosition={"right"}
          expandIcon={(e) => (
            <div style={{display:"flex"}}>
            <MobileView>
              {renderTrashIcon()}
            </MobileView>
            <img
              data-tracking-id={HEAP_DATA_ATTRIBUTES.EXPERIENCE.EXPAND_PROJECT}
              src={`/images/ProfileCreation/${e.isActive ? "minus.svg" : "plus.svg"}`}
            />
            </div>
          )}
          defaultActiveKey={defaultActiveKey}
          usedFor={usedFor}
        >
          <Panel
            forceRender={true}
            header={
              <Div className={usedFor === "experience" ? "experience-div" : ""}>
                <Header>{headerName}</Header>
                {subHeader && <SubHeader>{subHeader}</SubHeader>}
              </Div>
            }
            key={data && data.index ? data.index + 1 : 1}
          >
            <div>
              {(usedFor === "onboarding-certification" ||
                usedFor === "certification") && (
                <CertificationsContainer
                  onHandleAddCard={e => {
                    onHandleAddCard(e);
                  }}
                  data={data}
                  inputType={headerName}
                  onHandleAddButton={e => {
                    if (onHandleAddButton) onHandleAddButton(e);
                  }}
                />
              )}
              {usedFor === "references" && (
                <ReferenceContainer data={data} />
              )}
              {usedFor === "experience" && (
                <>
                  {newExperienceLayout ? (
                    <ExperienceConatainerNew
                      isFirstError={isFirstError}
                      handleAlertMessage={(e: any) => {
                        setAlert(e);
                      }}
                      validationState={validationState}
                      data={data}
                    />
                  ) : (
                    <div style={{ display: "flex" }}>
                      <ExperienceConatiner
                        handleAlertMessage={(e: any) => {
                          setAlert(e);
                        }}
                        validationState={validationState}
                        data={data}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </Panel>
        </CollapseDiv>
          <DesktopView>
          {renderTrashIcon()}
          </DesktopView>
      </div>
      <br />
      <span>Expand Icon Position: </span>
    </>
  );
};

export default CollapseComponent;
