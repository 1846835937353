import React from "react";
import InputBox, { InputBoxProps } from "../../InputBox/input";
import { LogoImage } from "../../../Image";
import styled from "styled-components";
import InputWithHeader from "../../InputWithHeader/input";
import InputWithHeaderForEdit, { InputWithHeaderProps } from "../inputHeader";
type usedFor = "login" | "registration" | "profileCreation" | undefined;
type InputSizes = "small" | "middle" | "large" | "extraLarge" | undefined;

interface InputBoxWithIconProps {
  usedFor: usedFor;
  inputBoxSize: InputSizes;
  buttonOnClick: (e: any) => void;
  inputTextType?: any;
  importanceType: string;
  child?: string;
  fields?: any;
  name?: string;
  otherProps?: any;
  ref?: any;
  placeholder?: string;
  disabled?: boolean;
  setRef?: boolean;
  buttonType?: boolean;
//   onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onHandleChange?:(e:any)=>void;
  value?: string;
  hidden?:boolean;
  withHeader?: boolean
  verification?: boolean
}

const Wrapper = styled.div`
  position: relative;
`;

const InputWithIcon = ({ ...props }: InputBoxWithIconProps) => {
  return (
    // <InputBox sizes = {props.sizes} importanceType = {props.importanceType} />
    <Wrapper>
      <InputWithHeaderForEdit
        withHeader= {props.withHeader}
        usedFor={props.usedFor}
        children={props.child}
        placeholder={props.placeholder}
        inputBoxSize={props.inputBoxSize}
        importance={props.importanceType}
        name={props.name}
        value={props.value}
        disable={props.disabled}
        onHandleChange={(e:any)=>{
            props.onHandleChange(e)
          }}
          hidden={props.hidden}
      />

      <div style={{ position: "absolute", bottom: "17px", right: "20px" }}>
      { props.hidden && <LogoImage
          imageSrc="/images/addIcon.svg"
          altMesg="edit button"
          imageType="icon"
          onClick={(e) => props.buttonOnClick(e)}
        />}
      { props.verification && <LogoImage
          imageSrc="/images/addIcon.svg"
          altMesg="edit button"
          imageType="icon"
          onClick={(e) => props.buttonOnClick(e)}
        />}
      </div>
    </Wrapper>
  );
};

export default InputWithIcon;
