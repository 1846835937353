export const getProfileLink=(userInfoData)=>{
  const link = window?.location?.href;
    if (link.includes("-dev")) {
        return `https://client-dev.venwiz.com/vendor/${userInfoData?.gstn}`;
      } else if (link.includes("-stg")) {
        return `https://client-stg.venwiz.com/vendor/${userInfoData?.gstn}`;
      }  else if (link.includes("-qa")) {
        return `https://client-qa.venwiz.com/vendor/${userInfoData?.gstn}`;
      }else if (link.includes("localhost")) {
        return `http://localhost:3000/vendor/${userInfoData?.gstn}`;
      } else {
        return `https://client.venwiz.com/vendor/${userInfoData?.gstn}`;
      }
}