import ProfileCreationModals from "Dashboard/ProfileCreation/ProfileCreationModals";
import React from "react";
import { useDispatch } from "react-redux";
import { PublishModalState } from "reducers/marketingSuite";
import ShareableLinkContent from "./ShareableLinkContent";
import { PublishModalActionMess, PublishModalBttn, PublishModalWrapper } from "./ShareableLinkModal.styles";

const body = () => {
  return (
    <PublishModalWrapper>
      <PublishModalActionMess>Your profile needs to be published to enable this feature.</PublishModalActionMess>
      <PublishModalBttn onClick={()=>{window.location.href="/userinfo"}}>Update profile</PublishModalBttn>
    </PublishModalWrapper>
  );
};

const NotPublishedModal = () => {
  const dispatch = useDispatch();
  return (
    <ProfileCreationModals
      title={"Profile"}
      onClose={() => {
        PublishModalState(dispatch, false);
      }}
      width={"478px"}
    >
      {body()}
    </ProfileCreationModals>
  );
};

export default NotPublishedModal;
