export const PROFILE_STATUS_PERCENTAGE_REQUESTED =
  "profileCreationStatus/PROFILE_STATUS_PERCENTAGE_REQUESTED";
export const PROFILE_STATUS_PERCENTAGE_SUCCESS =
  "profileCreationStatus/PROFILE_STATUS_PERCENTAGE_SUCCESS";
export const PROFILE_STATUS_PERCENTAGE_FAILURE =
  "profileCreationStatus/PROFILE_STATUS_PERCENTAGE_FAILURE";

export const PROJECT_VIDEO_SUCCESS =
"profileCreationStatus/PROJECT_VIDEO_SUCCESS";

export const CERT_DOC_STATUS_SUCCESS =
"profileCreationStatus/CERT_DOC_STATUS_SUCCESS";

export const COMP_PROFILE_STATUS_SUCCESS =
"profileCreationStatus/COMP_PROFILE_STATUS_SUCCESS";


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,

  percentageData: null,
  projectVideoStatus: null,
  certDocStatus: null,
  companyProfileStatus: null,

  
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_STATUS_PERCENTAGE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case PROFILE_STATUS_PERCENTAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        percentageData: action.result,
      };
  
    case PROJECT_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        projectVideoStatus: action.result,
      };

    case CERT_DOC_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        certDocStatus: action.result,
      };

    case COMP_PROFILE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyProfileStatus: action.result,
      };

    case PROFILE_STATUS_PERCENTAGE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const getProfileStatusPercentage = (data) => ({
    types: [PROFILE_STATUS_PERCENTAGE_REQUESTED, PROFILE_STATUS_PERCENTAGE_SUCCESS, PROFILE_STATUS_PERCENTAGE_FAILURE],
    promise: (client) => client.get(`dashboard_profile_status_get`, {}),
  });

  export const postProjectVideoStatus = (data) => ({
    types: [PROFILE_STATUS_PERCENTAGE_REQUESTED, PROJECT_VIDEO_SUCCESS, PROFILE_STATUS_PERCENTAGE_FAILURE],
    promise: (client) => client.post(`product_video_post`, {}),
  });

  export const getCertDocsStatus = (data) => ({
    types: [PROFILE_STATUS_PERCENTAGE_REQUESTED, CERT_DOC_STATUS_SUCCESS, PROFILE_STATUS_PERCENTAGE_FAILURE],
    promise: (client) => client.get(`cert_docs_status_get`, {}),
  });

  export const getCompanyProfileStatus = (data) => ({
    types: [PROFILE_STATUS_PERCENTAGE_REQUESTED, COMP_PROFILE_STATUS_SUCCESS, PROFILE_STATUS_PERCENTAGE_FAILURE],
    promise: (client) => client.get(`company_profile_status_get`, {}),
  });