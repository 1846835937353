
/**
 * Initial state for clicked review. This is used to avoid undefined error and TS error
 */
export const CLICKED_REVIEW_INITIAL_STATE = {
  _id: "",
  isActive: false,
  updatedAt: "",
  createdAt: "",
  gstn: "",
  clientId: "",
  clientDetails: {
    firstName: "",
    lastName: "",
    designation: "",
    email: "",
    company: "",
    logo: "",
    name: "",
  },
  overallRating: 0,
  overallReview: "",
  detailedReview: {
    vendorCompetency: {
      rating: 0,
      comment: "",
    },
    qualityOfExecution: {
      rating: 0,
      comment: "",
    },
    onTimeExecution: {
      rating: 0,
      comment: "",
    },
    responsiveness: {
      rating: 0,
      comment: "",
    },
    withinBudget: {
      rating: 0,
      comment: "",
    },
    ethics: {
      rating: 0,
      comment: "",
    },
    safety: {
      rating: 0,
      comment: "",
    },
    processAndDocumentation: {
      rating: 0,
      comment: "",
    },
  },
};
