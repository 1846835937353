import styled from "styled-components";

export const ButtonWrapper = styled.div<any>`
  display: flex;
  padding: ${(props) => props?.$padding};
  gap: 10px;
  @media screen and (max-width: 640px) {
    z-index: 10;
    border-top: none;
    padding: 0px;
    position: fixed;
    bottom: 0px;
    left: ${(props) => props?.$isAddInfoOpen && "0"};
  }
`;
