import MarketingSuite from "MarketingSuite";

export const GET_DIGITAL_BUS_PDF_REQUESTED = "filegetPdf/GET_DIGITAL_BUS_PDF_REQUESTED";
export const GET_DIGITAL_BUS_PDF_SUCCESS = "filegetPdf/GET_DIGITAL_BUS_PDF_SUCCESS";
export const GET_DIGITAL_BUS_PDF_FAILURE = "filegetPdf/GET_DIGITAL_BUS_PDF_FAILURE";

export const GET_BROUCHURE_PDF_REQUESTED = "filegetPdf/GET_BROUCHURE_PDF_REQUESTED";
export const GET_BROUCHURE_PDF_SUCCESS = "filegetPdf/GET_BROUCHURE_PDF_SUCCESS";
export const GET_BROUCHURE_PDF_FAILURE = "filegetPdf/GET_BROUCHURE_PDF_FAILURE";

export const UPDATE_PUSHLISHED_MODAL_STATUS = "filegetPdf/UPDATE_PUSHLISHED_MODAL_STATUS";

const initialState = {
  getPdfLoading: false,
  getPdfLoaded: false,
  getPdfError: false,
  getPdfErrorData: false,
  digitalBusCard: null,
  marketingBrochureCard: null,
  ShowPublishModal:false
};

const marketingSuite = (state = initialState, action) => {
  switch (action.type) {
    case GET_DIGITAL_BUS_PDF_REQUESTED:
      return {
        ...state,
        getPdfLoading: true,
      };

    case GET_DIGITAL_BUS_PDF_SUCCESS:
      return {
        ...state,
        getPdfLoading: false,
        getPdfLoaded: true,
        getPdfError: false,
        digitalBusCard: action.result,
      };

    case GET_DIGITAL_BUS_PDF_FAILURE:
      return {
        ...state,
        getPdfLoading: false,
        getPdfLoaded: true,
        getPdfError: true,
        getPdfErrorData: action.error.message,
      };

      case GET_BROUCHURE_PDF_REQUESTED:
        return {
          ...state,
          getPdfLoading: true,
        };
  
      case GET_BROUCHURE_PDF_SUCCESS:
        return {
          ...state,
          getPdfLoading: false,
          getPdfLoaded: true,
          getPdfError: false,
          marketingBrochureCard: action.result,
        };
  
      case GET_BROUCHURE_PDF_FAILURE:
        return {
          ...state,
          getPdfLoading: false,
          getPdfLoaded: true,
          getPdfError: true,
          getPdfErrorData: action.error.message,
        };

      case UPDATE_PUSHLISHED_MODAL_STATUS:
        return {
          ShowPublishModal:action.payload
        }

    default:
      return state;
  }
};
export default marketingSuite;
export const getDigitalCardData = payload => ({
  types: [GET_DIGITAL_BUS_PDF_REQUESTED, GET_DIGITAL_BUS_PDF_SUCCESS, GET_DIGITAL_BUS_PDF_FAILURE],
  promise: client => client.get(`get_user_details_pdf`, {}),
});

export const getBrochureData = payload => ({
  types: [GET_BROUCHURE_PDF_REQUESTED, GET_BROUCHURE_PDF_SUCCESS, GET_BROUCHURE_PDF_FAILURE],
  promise: client => client.get(`get_marketing_brochure_pdf`, {}),
});

export const PublishModalState = (dispatch, data) => {
  dispatch({
    type: UPDATE_PUSHLISHED_MODAL_STATUS,
    payload: data,
  });
};