import Column from 'antd/lib/table/Column';
import { Colors } from 'Theme';
// eslint-disable-next-line import/prefer-default-export
export const cardTheme = (colors:Colors) => ({
  types: {
    userCard: {
      height:'132px',
      width:'517px',
      backgroundColor:'#F4F6FF',
      borderRadius:'30px',
      padding: '30px',
    },
    progressCard: {
      default: {
        width:'263px',
        height:'100%',
        backgroundColor:'#F4F6FF',
        borderRadius:'30px',
        padding: '0px',
        display: 'flex'
      },
      profileCreationSidebar: {
        width: '420px',
        height: 'auto',
        backgroundColor: colors.white,
        borderRadius:'60px',
        padding: '60px 10px 41px 30px',
        display: 'block',
        boxShadow: '0px 20px 30px rgba(159, 179, 248, 0.12)'
        
      },
      small: {
        width: '100px',
        height: '894px',
        backgroundColor: colors.white,
        borderRadius:'30px',
        padding: '60px 10px 41px 30px'
      }
    },
    viewCard: {
      height:'195px',
      width:'248px',
      backgroundColor:'#F4F6FF',
      borderRadius:'30px'
    },
    cardWithoutEdit: {
      height:'284px',
      width:'415px',
      backgroundColor:'#F4F6FF',
      borderRadius:'8px',
      display: 'flex',
      padding:'10%'
    },
    referalCard:{
      width:'375px',
      height:'121px',
    },
    imageCard:{
      width: '248px',
      height: '127px',
      backgroundColor: '#FED467',
      borderRadius:'30px',
    },
    cardWithDropletHeader:{
      width: '248px',
      height: '147px',
    },
    dropletHeaderContainer:{
      height:'24px',
      padding:'12px',
      primaryColor:'#112B7A',
      secondaryColor:'#1D9E60',
      fontWeight:'600',
      fontSize:'10px',
      lineHeight:'15px',
      letterSpacing:'0.01em',
      color:'#FFFFFF',
    },
    referenceCard:{
      width: '415px',
      height: 'auto',
      backgroundColor:'#F4F6FF',
      borderRadius: '8px',
      padding: '20px'
    },
    peopleInfoContainer:{
      display:'flex',
      flexDirection:'Column',
      marginTop:'20px',
    },
    cardTags:{
      selectItem:{
        backgroundColor: '#f4f6ff',
        padding: '20px 22px 19px 16px',
        borderRadius: '8px',
        margin: '0px 10px 0px 0px'
      },
      dropDownCard:{
        backgroundColor: '#f4f6ff',
        padding: '20px 22px 19px 16px',
        borderRadius: '8px',
        margin: '0px 10px 0px 0px',
        width: '100%'
      },
      selectItemWithLogo:{
        backgroundColor: '#f4f6ff',
        padding: '20px 22px 19px 16px',
        borderRadius: '8px',
        margin: '0px 0px 0px 10px'
      },
      fileUploadTag:{
        backgroundColor: '#f4f6ff',
        padding: '15px 27px 17px 23px;',
        borderRadius: '8px',
        width: '100%',
        margin: '10px 0px 10px 0px'
      },
      fileUploadReferenceTag:{
        backgroundColor: '#ffff',
        padding: '15px 27px 17px 23px;',
        border: 'none'
        // borderRadius: '8px',
        // width: '100%',
      },
      videoUploadTag:{
        backgroundColor: '#f4f6ff',
        padding: '15px 27px 17px 23px;',
        borderRadius: '8px',
        width: '100%',
        margin: '10px 0px 10px 0px'
      },
      jobRequest:{
        backgroundColor: '#f4f6ff',
        padding: '15px 27px 17px 23px;',
        borderRadius: '8px',
        width: '400px',
        margin: '10px 0px 10px 0px'
      }
    },
    
  },
  cursor:'pointer',
  borderRadius: '4px',
  color:colors.gray,
  states: {
    default: colors.grayDark,
    hover: colors.primary,
    active: colors.primary,
    disabled: colors.grayDark,
    success: colors.green,
    failure: colors.red
  }
});
