import React from "react";
import AntButton, { ButtonProps as AntButtonProps } from "antd/es/button";
import styled from "styled-components";
import { Sizes } from "../utils";

interface BttnStyles {
  borderRadius: string;
  width:string
}
export interface ButtonProps extends AntButtonProps {
  onClick: () => void;
  variant?:
    | "primary"
    | "secondary"
    | "white"
    | "blue"
    | "tertiary"
    | "danger"
    | "red";
  size: Sizes;
  flexible?: boolean;
  usedFor?: string;
  buttonLoading?: boolean;
  color?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  $mobileScreenStyles?:BttnStyles;
}

export const ButtonBase = styled(AntButton)<ButtonProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: ${props =>
    `${props.theme.button.sizes[props.size || "primary"].borderRadius}`};
  background-color: ${props =>
    `${props.theme.colors[props.variant || "primary"]}`};
  border: ${props =>
    `1px solid ${
      props.color ? props.color : props.theme.colors[props.variant || "primary"]
    }`};
  color: ${props =>
    props.usedFor === "banner" || props.usedFor === "dropdownClear"
      ? `${props.theme.colors.blue}`
      : `${props.color ? props.color : props.theme.button.color}`};
  font-size: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].fontSize}`};
  font-weight: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].fontWeight}`};
  /* height: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].height}`};*/
  height:${props =>
    props.usedFor === "iconButton"
      ? `${"60px"}`
      : `${props.theme.button.sizes[props.size || "middle"].height}`};
  line-height: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].lineHeight}`};
  padding: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].padding}`};
  width: ${props =>
    `${props.theme.button.sizes[props.size || "middle"].width}`};

/*  to re-use this button component per new design overriding below
    properties by new props as prev properties values are from themes
*/
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  line-height: ${props => props.lineHeight};
  width: ${props => props.width};
  height: ${props => props.height};
  border-radius: ${props => props.borderRadius};

    &:active,
    &:focus {
      background-color: ${props =>
        `${props.theme.button.states[props.variant || "primary"]?.active}`};
      border-color: ${props =>
        `${props.theme.button.states[props.variant || "primary"]?.active}`};
      color: ${props => `${props.theme.button.color}`};
    }
    &:hover {
      background-color: ${props =>
        `${props.theme.button.states[props.variant || "primary"]?.active}`};
      color: ${props => `${props.color || "#FFFFFF"}`};
    }
  
    &:disabled {
      border-color:#D7EADF;
      color: ${props => `${props.theme.button.color}`};
      background: #D7EADF;
    }

    @media only screen and (max-width: 640px) {
     width: ${props =>
      props?.$mobileScreenStyles?.width || `${props.theme.button.sizes[props.size || "middle"]?.mobileStyles?.width}`};
     padding: ${props =>
          `${props.theme.button.sizes[props.size || "middle"]?.mobileStyles?.padding}`};
     height:${props =>
            props.usedFor === "iconButton"
              ? `${"60px"}`
              : `${props.theme.button.sizes[props.size || "middle"]?.mobileStyles?.height}`};
    border-radius:${props => props?.$mobileScreenStyles?.borderRadius};
    }
`;

const Button = ({ children, buttonLoading, ...rest }: ButtonProps) => (
  <>
    <ButtonBase loading={buttonLoading ? true : false} {...rest}>
      {children}
    </ButtonBase>
  </>
);

export default Button;
