const LocalStorageService = {
  get: (itemName) => {
    const item = localStorage.getItem(itemName);
    const numPattern = new RegExp(/^\d+$/);

    // eslint-disable-next-line
    const jsonPattern = new RegExp(/[\[{].*[\}\]]/);

    if (typeof item === undefined || typeof item === null) {
      return null;
    }

    if (jsonPattern.test(item)) {
      return JSON.parse(item);
    }
    if (numPattern.test(item)) {
      return parseFloat(item);
    }
    return item;
  },

  set: (itemName, item) => {
    if (typeof item === 'object') {
      localStorage.setItem(itemName, JSON.stringify(item));
    } else {
      localStorage.setItem(itemName, item);
    }
  },

  remove: (itemName) => {
    localStorage.removeItem(itemName);
  },

  removeMany: (items) => {
    items.forEach(item => {
      localStorage.removeItem(item);
    })
  },

  removeAll: () => {
    localStorage.clear();
  }
};

export default LocalStorageService;
