import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";

import { Modal, Button } from "antd";

import { PlayCircleOutlined } from "@ant-design/icons";
import useInterval from "hooks/useInterval";
import { useDispatch, useSelector } from "react-redux";
import { DashboardLayout } from "components/Layout";
import ProfileLink from "./ProfileLink";
import BusinessCard from "./BusinessCard";
import { getDigitalCardData,getBrochureData } from "reducers/marketingSuite";
import MultiPageViewer from "./Component/MultiPageViewer";
import MarketingBrouchure from "./MarketingBrochure";

const MarketingSuiteWrapper = styled.div`
  width: 100%;
  margin: 25px 0px 0px 30px;
  display: flex;
`;

const SelectionContainer = styled.div`
  width: 30%;
  height: 100%;
`;

const BodyContainer = styled.div<any>`
  width: 70%;
  height: ${props => (props.expand ? "calc(100%)" : "280px")};
  background: #f4f6ff;
  border-radius: 30px;
`;

const MarketingSuiteHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #215ec9;
`;

const ButtonDiv = styled(Button)<any>`
  width: 258px;
  height: 60px;
  background-color: ${props => (props?.selected ? "#215ec9" : "transparent")};
  border-radius: 8px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
  /* identical to box height, or 24px */
  text-align: center;
  letter-spacing: 0.01em;
  color: ${props => (props?.selected ? "#fff" : "#4D5D8B")};
  &.ant-btn-primary:hover,
  .ant-btn-primary:focus {
    color: none;
    background: "#215ec9";
    border-color: "#215ec9";
  }
  &.ant-btn-primary {
    color: ${props => (props?.selected ? "#fff" : "#4D5D8B")};
    background: ${props => props?.selected && "#215ec9"};
    border-color: ${props => props?.selected && "#215ec9"};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const MarketingSuite = () => {
  const [selectedButton, setSelectedButton] = useState("link");
  const dispatch = useDispatch();
  const [isPdfAvailable, setIsPdfAvailable] = useState(false);
  const authState = useSelector((state: any) => state.auth);

  const buttonType = [
    { name: "Shareable Profile Link", keyName: "link" },
    { name: "Digital Business Card", keyName: "card" },
    { name: "Marketing Brochure", keyName: "brouchure" },

  ];
  useEffect(() => {
    dispatch(getDigitalCardData({}));
    dispatch(getBrochureData({}))
  }, []);

  const renderBody = type => {
    switch (type) {
      case "link":
        return <ProfileLink></ProfileLink>;
      case "brouchure":
        return <MarketingBrouchure/>;
      case "card":
        return (
          <BusinessCard
            onPdfUpload={() => {
              setIsPdfAvailable(true);
            }}
          ></BusinessCard>
        );
    }
  };
  return (
    // <ClientLayout scrollable={true} title="MarketingSuite">
    <DashboardLayout height="100%">
      <MarketingSuiteWrapper>
        <SelectionContainer>
          <MarketingSuiteHeader>Our Offerings</MarketingSuiteHeader>
          <ButtonContainer>
            {buttonType.map((item, index) => {
              return (
                <ButtonDiv
                  type="primary"
                  selected={selectedButton === item?.keyName}
                  onClick={() => setSelectedButton(item?.keyName)}
                >
                 { item?.name}
                </ButtonDiv>
              );
            })}
          </ButtonContainer>
        </SelectionContainer>
        <BodyContainer expand={true}>
          {renderBody(selectedButton)}
        </BodyContainer>
      </MarketingSuiteWrapper>
    </DashboardLayout>
    // </ClientLayout>
  );
};
export default MarketingSuite;
