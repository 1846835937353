import { IVendorProfileInitialState } from "../ViewProfile.interfaces";
import { mockData } from "../ViewProfile.mockdata";
import { Types as VendorProfileTypes } from "./types";

export const vendorProfileInitialState: IVendorProfileInitialState = {
  loading: false,
  data: {},
  verificationData: {},
  reviewsData: {},
  error: null,
};

// type of keys of VendorProfileTypes object
type IKeys = keyof typeof VendorProfileTypes;

interface IACTION_TYPE {
  // type of values of VendorProfileTypes
  type: typeof VendorProfileTypes[IKeys];
  result: any;
}

export default (state = vendorProfileInitialState, action: IACTION_TYPE) => {
  switch (action.type) {
    case VendorProfileTypes.VENDOR_PROFILE_GET_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case VendorProfileTypes.VENDOR_PROFILE_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action?.result,
        verificationData: action?.result,
        reviewsData: action?.result,
        error: null,
      };

    case VendorProfileTypes.VENDOR_PROFILE_GET_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.result,
      };

    default:
      return state;
  }
};
