import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { colorArray } from "../../../constants/mockData";
export interface ProfileProps {
  imgSrc?: string;
  data?: string;
  onClick:()=>void
}

const ProfileBase = styled.div`
  margin: 0px 10px 0px 0px;
  @media only screen and (max-width: 640px) {
  width:28px;
  height:28px;
  }
`;

const AvatarDiv = styled(Avatar)`
  & .ant-avatar-string{
    position:relative;
    left:0;
  }
  @media only screen and (max-width: 640px) {
      &.ant-avatar-lg {
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
    }
  }
`;

const Profile = ({ imgSrc, data, onClick }: ProfileProps) => {
  const [choseColor, setChooseColor] = useState(colorArray[6]);
  return (
    <ProfileBase onClick={onClick}>
      <AvatarDiv
        size="large"
        style={{ backgroundColor: choseColor, verticalAlign: "middle" }}
      >
        {imgSrc?.length?<img src={imgSrc} style={{width:"100%",height:"100%",objectFit:"cover"}} alt={"company logo"} />:data && data.charAt(0)}
      </AvatarDiv>
    </ProfileBase>
  );
};

export default Profile;
