import { Select, Tooltip } from "antd";
import {
  getVerificationComponentBorderColor,
  getVerificationStatusImgSrc
} from "Profile/helpers";
import React, { useState } from "react";
import { CertOrOemProps } from "../Interfaces/Capabilities.interfaces";
import {
  SelectDropdown,
  SelectDropdownDiv,
  VerficationStatus
} from "./CertOrOem.styles";

export const CertOrOemSelect = (props: CertOrOemProps) => {
  const [focusState, setFocusState] = useState(false);
  const [options, setOptions] = useState(props?.optionsLibrary);
  const { Option } = Select;

  const TooltipMsg = {
    notVerified: "Authorization failed, please delete and add correct doc",
    inProgress: "Upload authorization to verify"
  };

  const handleSearch = (value: any) => {
    if (value?.length) {
      props?.handleChange(props.usedFor, "UPDATE", value.trim(), props.index);
      setOptions(
        props?.optionsLibrary?.filter((name: any) =>
          name.toLowerCase().includes(value.toLowerCase())
        ) || []
      );
    } else {
      setOptions(props.optionsLibrary);
    }
  };

  const setDropdownValuesBind = (e: any) => {
    props?.handleChange(props.usedFor, "UPDATE", options[e], props.index);
  };

  const onBlurHandler = () => {
    const isValidOption = options.includes(props.selectedValue);
    if (!isValidOption)
      props?.handleChange(props.usedFor, "UPDATE", props?.selectedValue, props.index);
  };

  return (
    <SelectDropdownDiv optionsLength={options.length}>
      <SelectDropdown
        showArrow={false}
        dropdownStyle={{
          fontWeight: "500",
          fontSize: "16px",
          height: "40px !important",
          borderRadius: "0px 0px 8px 8px",
          borderTop: "none",
          boxShadow: " 0px 10px 12px rgba(159, 179, 248, 0.4)",
          left: "425px !important",
          top: "829px !important"
        }}
        dropdownAlign={{ offset: [5, -15] }}
        $focusState={focusState}
        onFocus={() => setFocusState(true)}
        onBlur={() => {
          props?.selectedValue.length && setFocusState(false);
          props.usedFor === "CERT" && onBlurHandler();
        }}
        showSearch
        disabled={props.usedFor === "ITR" ? false : props?.uploadedDoc}
        onSearch={handleSearch}
        value={props?.selectedValue?.length ? props?.selectedValue : undefined}
        listHeight={80}
        filterOption={false}
        onSelect={(e: any) => setDropdownValuesBind(e)}
        style={{
          width: "319px"
        }}
        placeholder={props?.inputPlaceholder || "Enter Name"}
        notFoundContent={null}
        listItemHeight={3}
        getPopupContainer={trigger => trigger.parentNode}
        defaultActiveFirstOption={false}
        $borderColor={getVerificationComponentBorderColor(
          props?.selectedValue,
          props?.verificationStatus,
          true
        )}
        data-tracking-id={props?.dropDownTrackingId}
        {...props}
      >
        {options &&
          options.map((item, index) => (
            <Option
              style={{
                height: "40px",
                marginBottom: "2px"
              }}
              value={index}
              key={item}
            >
              {item}
            </Option>
          ))}
      </SelectDropdown>
      {props?.selectedValue.length > 0 &&
        (props?.verificationStatus !== "verified" ? (
          <Tooltip
            color="#ffffff"
            placement={"bottomRight"}
            arrowPointAtCenter={true}
            title={
              props?.verificationStatus === "notVerified"
                ? TooltipMsg.notVerified
                : TooltipMsg.inProgress
            }
            overlayInnerStyle={{
              fontSize: "12px",
              color: "#011759",
              padding: "12px 20px",
              borderRadius: "12px",
              whiteSpace: "normal",
              lineHeight: "18px"
            }}
            getPopupContainer={(trigger: any) => trigger.parentNode}
          >
            {props?.selectedValue.length > 0 && (
              <VerficationStatus
                src={getVerificationStatusImgSrc(props?.verificationStatus)}
                $bgColor={"#ffffff"}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.style.display = "none";
                }}
              ></VerficationStatus>
            )}
          </Tooltip>
        ) : (
          <VerficationStatus
            src={getVerificationStatusImgSrc(props?.verificationStatus)}
            $bgColor={"#f5f5f5"}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.style.display = "none";
            }}
          />
        ))}
    </SelectDropdownDiv>
  );
};
