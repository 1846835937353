import { Types as FileUploadTypes } from "./types";

export const ActionCreators: any = {
  uploadFilesToPermanentFolder: (multipleFilesFormData: any) => ({
    types: [
      FileUploadTypes.FILE_OPERATIONS_REQUEST,
      FileUploadTypes.UPLOAD_FILES_TO_PERMANENT_FOLDER_SUCCESS,
      FileUploadTypes.FILE_OPERATIONS_FAILURE,
    ],
    promise: (client: any) => client.post("upload_files_to_permanent_folder", { multipleFilesFormData }),
  }),
  uploadFilesToOpenPermanentFolder: (multipleFilesFormData: any) => ({
    types: [
      FileUploadTypes.FILE_OPERATIONS_REQUEST,
      FileUploadTypes.UPLOAD_FILES_TO_PERMANENT_FOLDER_SUCCESS,
      FileUploadTypes.FILE_OPERATIONS_FAILURE,
    ],
    promise: (client: any) => client.post("upload_files_to_permanent_folder_open", { multipleFilesFormData }),
  }),
};

export default ActionCreators;
