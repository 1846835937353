import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { verifyEmailLink, verifyEmailVerificationLink } from "reducers/auth";
import { useHistory } from "react-router-dom";
import { getEmail } from "constants/auth";
import { AppConfig } from "ui-constants";
import { getPreviousGuestVisitedRouteOrMyProfileRoute } from "shared/handlers/restrictOrNavigateToOnBoarding";

const VerifyEmail = () => {
  const ROUTE_TO_BE_VIEWED_AFTER_LOGIN = getPreviousGuestVisitedRouteOrMyProfileRoute();
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    dispatch(
      verifyEmailVerificationLink(
        id,
      ),
    ).then(res => {
      if (res?.status === 200 || res?.error === "Already verified.") {
        history.push(ROUTE_TO_BE_VIEWED_AFTER_LOGIN);
      } else {
        history.push("/login", {
          notificationType: "error",
          notificationText: "Email verification failed",
        });
      }
    });
  }, []);

  return <div>Verify Email</div>;
};

export default VerifyEmail;
