export const REFERENCE_REQUESTED = "referenceInfo/REFERENCE_REQUESTED";
export const REFERENCE_SUCCESS = "referenceInfo/REFERENCE_SUCCESS";
export const REFERENCE_FAILURE = "referenceInfo/REFERENCE_FAILURE";

export const REFERENCE_POST_REQUESTED =
  "referenceInfo/REFERENCE_POST_REQUESTED";
export const REFERENCE_POST_SUCCESS = "referenceInfo/REFERENCE_POST_SUCCESS";
export const REFERENCE_POST_FAILURE = "referenceInfo/REFERENCE_POST_FAILURE";

export const REFERENCE_CREATE_REQUESTED =
  "referenceInfo/REFERENCE_CREATE_REQUESTED";
export const REFERENCE_CREATE_SUCCESS =
  "referenceInfo/REFERENCE_CREATE_SUCCESS";
export const REFERENCE_CREATE_FAILURE =
  "referenceInfo/REFERENCE_CREATE_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: null,

  referenceInfoGetdata: null,
  referenceInfoPostData: null,
  referenceInfoCreateData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REFERENCE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        referenceInfoGetdata: action.result,
      };

    case REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case REFERENCE_POST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case REFERENCE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        referenceInfoPostData: action.result,
      };
    case REFERENCE_POST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case REFERENCE_CREATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case REFERENCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        referenceInfoCreateData: action.result,
      };
    case REFERENCE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const referenceInfoGet = (data) => ({
  types: [REFERENCE_REQUESTED, REFERENCE_SUCCESS, REFERENCE_FAILURE],
  promise: (client) => client.get(`reference_info_get_data`, {}),
});

export const referenceInfoCreate = (data) => ({
  types: [
    REFERENCE_CREATE_REQUESTED,
    REFERENCE_CREATE_SUCCESS,
    REFERENCE_CREATE_FAILURE,
  ],
  promise: (client) =>
    client.post("reference_info_create_data", {
      data,
    }),
});

export const referenceInfoPost = (data) => ({
  types: [
    REFERENCE_POST_REQUESTED,
    REFERENCE_POST_SUCCESS,
    REFERENCE_POST_FAILURE,
  ],
  promise: (client) =>
    client.post("reference_info_post_data", {
      data,
    }),
});
