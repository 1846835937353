import React, { useEffect, useState } from "react";
import "antd/dist/antd.css";
import { Upload, message } from "antd";

import styled from "styled-components";
import { LogoImage } from "../Image";
import { getHeaders } from "constants/auth";
import { ApiConfig } from "ui-constants";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Box } from "venwiz-ui";
import { colors } from "Theme";

interface UploadImageProps {
  title: string;
  imgSrc?: string;
  optional?: boolean;
  onUpload?: (imageUrl: string, name: string) => void;
  disabled?: boolean;
  usedAt?: string;
  getImgSrc?: (img: any, name: string,setCurrentFile:any) => void;
  height: string;
  width: string;
  isError?: (value, message) => void;
  error?: boolean;
}

export const UploadAntd = styled(Upload)<UploadImageProps>`
  & .ant-upload-select-picture-card {
    border: 1px solid #D4E5FE;
    border-radius: ${props =>
      props.usedAt === "scopeOfWork" ? "8px" : "12px"};
    width: ${props => `${props.width}`};
    height: ${props => `${props.height}`};
    background: ${props =>
      props.usedAt === "scopeOfWork" ? "#F4F6FF" : "#FFFFFF"};
    max-height: ${props =>
      `${props.usedAt === "scopeOfWork" ? props.height : ""}`};
    border-top: ${props => props.error && "1px solid #BE000B"};
    border-right: ${props => props.error && "1px solid #BE000B"};
    border-bottom: ${props => props.error && "1px solid #BE000B"};
  }
  & .ant-upload.ant-upload-select-picture-card > span {
    padding: 0px;
  }
  img {
    max-height: ${props =>
      `${
        props.usedAt === "scopeOfWork" ||
        (props.usedAt === "companyLogo" && props.imgSrc !== undefined)
          ? props.height
          : "auto"
      }`};
    object-fit: ${props =>
      `${(props.usedAt === "scopeOfWork" ||
        (props.usedAt === "companyLogo" && props.imgSrc !== undefined)) &&
        "contain"}`};
  }
  @media screen and (max-width: 640px) {
    & .ant-upload-select-picture-card {
    width: 125px;
    height: 123px;
    max-height:123px;
    }
    img{
      width: 125px;
      height: 123px;
      max-height:123px;
    }
  }
`;

const Container=styled.div`
  display:flex;
  flex-direction:column;
  margin-bottom: 5px;
  @media screen and (max-width: 640px) {
  flex-direction:row;
  align-items:flex-end;
  }
`

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  height: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 21px;
  img {
    height: 14px;
    width: auto;
    margin-right: 10px;
  }
  @media screen and (max-width: 640px) {
    margin-right:0px;
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${props => `${props.theme.colors.darkBlue}`};
  opacity: 0.7;
  margin-bottom: 6px;
  @media screen and (max-width: 640px) {
    margin-bottom:5px;
  }
`;

const Div = styled.div`
  @media screen and (max-width: 640px) {
    height:148px;
  }
`;

export const ImgAvatar = styled.img`
  width: 100%;
  border-radius: 12px;
`;

function UploadImage(props: UploadImageProps) {
  const [maxSize, setMaxSize] = useState<number>();
  const HOST_NAME = ApiConfig.hostname;
  const [loading, setloading] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const [tempImageUrl, setTempImageUrl] = useState("");
  const [verifyResolution, setVerifyResolution] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState(false);
  
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  function checkResolution(file): boolean {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener("load", event => {
      const _loadedImageUrl = event.target.result;
      const image: any = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        const { width, height } = image;
        if (width < 320 || height < 640) {
          setVerifyResolution(false);
          setErrorMessage("Resolution should be 320x640px min.");
          setError(true);
          return false;
        }
        return true;
        // set image width and height to your state here
      });
    });
    return true;
  }

  useEffect(() => {
    if (props.usedAt === "scopeOfWork") {
      setMaxSize(10);
    } else {
      setMaxSize(5);
    }
  }, [props]);

  useEffect(() => {
    if (props?.isError) props?.isError(error, errorMessage);
  }, [error, errorMessage]);

  function beforeUpload(file) {
    setCurrentFile(file);
    setVerifyResolution(true);
    let resolution = true;
    if (props.usedAt === "scopeOfWork") {
      const valueType = checkResolution(file);
      if (valueType) {
        setError(true);

        setloading(false);
      }
    }

    const isJpg = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpg) {
      setErrorMessage(
        "Only JPEGs allowed. Please upload the correct file format."
      );
    }

    const isLt5M = file.size / 1024 / 1024 < maxSize;
    if (!isLt5M) {
      setErrorMessage(
        `File upload could not be completed.
        Max. file size ${maxSize}MB`
      );
    }
    
    if(isJpg && isLt5M){
      setError(false);
    } else {
      setError(true);
    }

    return isJpg && isLt5M;
  }

  const handleChange = info => {
    if (info.file.status === "uploading") {
      setloading(true);
      return;
    }
    if (info.file.status === "done" && verifyResolution) {
      getBase64(info.file.originFileObj, imageUrl => {
        setErrorMessage("");
        setError(false);
        setTempImageUrl(imageUrl);
        if (props.getImgSrc) {
          props.getImgSrc(imageUrl, info?.file?.name,currentFile);
        }
      });
      setloading(false);
      props.onUpload(info.file.response, info?.file?.name);
    }
    if (info.file.status === "error") {
      setloading(false);
    }
    setloading(false);
  };

  const Icon = () => {
    return;
  };

  const uploadButton =
    props.usedAt === "scopeOfWork" ? (
      <Box>
        <IconWrap>
          {loading && (
            <Spin indicator={antIcon} />
          )}
            Upload images to gallery
        </IconWrap>
      </Box>
    ) : (
      <div>
        {loading ? (
          <LoadingOutlined />
        ) : (
          <IconWrap>
            <LogoImage
              imageSrc={`/images/upload.svg`}
              altMesg="edit button"
              imageType="icon"
            />
          </IconWrap>
        )}
      </div>
    );

  return (
    <Container>
      <Div>
        { (props?.usedAt === "companyLogo" && props?.title) && <Title>{props.title}</Title> }
        <UploadAntd
          accept={"image/jpeg"}
          error={error}
          disabled={!loading || props.disabled}
          name="file"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          action={`${HOST_NAME}/v1/file/upload`}
          headers={{ Authorization: getHeaders() }}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          {...props}
        >
          {props.imgSrc && errorMessage === "" ? (
            <ImgAvatar
              alt="avatar"
              src={tempImageUrl !== "" ? tempImageUrl : props.imgSrc}
            />
          ) : (
            uploadButton
          )}
        </UploadAntd>
      </Div>
    </Container>
  );
}

export default UploadImage;
