import React from "react";
import { IRatings } from "./interfaces/interfaces";
import { RatingDiv } from "./Rating.styles";
import { StarFilled } from "@ant-design/icons";

const Rating = (props: IRatings) => {
  const { value, color, disabledStatus } = props;
  const ratingStar = (
    <div>
      <StarFilled style={{ borderColor: `1px solid ${color}` }} />
    </div>
  );
  return (
    <RatingDiv
      value={value}
      allowHalf={props?.allowHalf}
      style={{ color: color }}
      character={ratingStar}
      disabled={disabledStatus}
      onChange={props.onChange}
    ></RatingDiv>
  );
};

export default Rating;
