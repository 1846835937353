import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setIsLoading, verifyEmailOTP } from "reducers/auth";
import styled from "styled-components";
import SuccessMsg from "Authentication/SuccessMsg/SuccessMsg";
import { Button } from "components/Button";
import OtpBox from "components/Otp/OtpBox/OtpBox";
import { AppConfig } from "ui-constants";
import SessionStorageService from "services/SessionStorageService";
import ApiConstant from "constants/ApiConstant";
import { checkForMobileScreen, checkForSmallerMobileScreen } from "shared/handlers/getUserScreenSize.handler";
interface EmailOtpProps {
  handleEmailOtp: (e: boolean) => void;
  submit?: boolean;
  email: any;
  otpInputBoxWidth?: string;
  handleSuccessState?: (x?: any) => void;
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Header = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 45px;
  color: ${props => `${props.theme.colors.blue}`};
  margin-top: 60px;
  margin-bottom: 60px;
`;
const SubHeader = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 45px;
  color: #47c882;
  // margin-bottom: 16px;
`;

const Divs = styled.div`
  display: flex;
  justify-content: space-between;
  height: auto;
  margin-bottom: 20px;
`;

const OtpDiv = styled(Divs)<{otpInputBoxWidth?: string}>`
  flex-direction: column;
  width: 100%
`;

const OtpEmailVerify = ({
  handleEmailOtp,
  submit,
  email,
  otpInputBoxWidth,
  handleSuccessState,
}: EmailOtpProps) => {
  const { OTP } = AppConfig;
  const dispatch = useDispatch();
  const history = useHistory();

  const authState = useSelector((state: any) => state.auth);
  const {
    userData,
    verifyEmailOTPLoading,
    verifyEmailOTPLoaded,
    verifyEmailOTPData,
  } = authState;

  const [showSuccessMessage, setShowSuccessMessage] = useState("");

  const emailMask = email => {
    var maskedEmail = email.replace(/([^@\.])/g, "*").split("");
    var previous = "";
    for (let i = 0; i < maskedEmail.length; i++) {
      if (i <= 1 || previous == "." || previous == "@") {
        maskedEmail[i] = email[i];
      }
      previous = email[i];
    }
    return maskedEmail.join("");
  };

  const [otpStore, setOtpStore] = useState("");

  const handleComplete = otp => {
    setOtpStore(otp);
  };
  useEffect(() => {
    if (verifyEmailOTPLoaded && verifyEmailOTPData) {
      // setShowSuccessMessage("SuccessMessage");
      if (handleSuccessState) handleSuccessState();
    }
  }, [verifyEmailOTPLoaded, verifyEmailOTPData]);

  const setEmailPopUpState=()=>{
    SessionStorageService.set(ApiConstant.STORAGE_KEYS.showEmailVerificationPopup, false);
  }
  const handleClick = () => {
    if (!verifyEmailOTPLoading) {
      setIsLoading(dispatch, { payload: true });
      dispatch(
        verifyEmailOTP(
          JSON.stringify({
            email: email,
            otp: otpStore,
          })
        )
      )
        .then(() => {
          setIsLoading(dispatch, { payload: false });
          setEmailPopUpState();
        })
        .catch(() => {
          setIsLoading(dispatch, { payload: false });
        });
    }
  };

  useEffect(() => {
    if (submit) {
      handleClick();
    }
  }, [submit]);

  useEffect(() => {}, []);
  switch (showSuccessMessage) {
    case "SuccessMessage":
      return (
        <Container>
          <SuccessMsg
            usedFor="otp"
            desc={"Congratulations your email address is successfully verified"}
            onNextButton={e => {
              setShowSuccessMessage("LoginSuccessMessage");
            }}
          >
            Next
          </SuccessMsg>
        </Container>
      );
    case "LoginSuccessMessage":
      return (
        <Container>
          <SuccessMsg
            usedFor="otp"
            desc={"Congratulations your registration is completed"}
            isLogin
            onNextButton={e => {
              history.push("/login");
            }}
          >
            Login
          </SuccessMsg>
        </Container>
      );
  }

  return (
    <Container>
      <OtpDiv otpInputBoxWidth={otpInputBoxWidth}>
        <OtpBox
          type={"email"}
          value={ email}
          text={""}
          reset={true}
          onComplete={handleComplete}
        />
      </OtpDiv>
      <Button
        onClick={handleClick}
        variant="secondary"
        size="large"
        style={{ margin: "20px 0px 0px 0px", padding:checkForMobileScreen()?"0px": "20px 190px" }}
      >
        SUBMIT
      </Button>
    </Container>
  );
};

export default OtpEmailVerify;
