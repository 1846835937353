import { CardTag } from "components/Card";
import React from "react";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { trimmedString } from "Profile/Finance/helper";
import { InputWrap, UploadIcon } from "../CapabilitiesNew.styles";
import { CertOrOemSelect } from "./CertOrOemSelect";
import {
  TagAndFileConainer,
  SelectDiv,
  Container,
  UploadFileDiv,
  DeleteIconWrapper,
  DeleteIcon
} from "./CertOrOem.styles";
import { CertOrOemProps } from "../Interfaces/Capabilities.interfaces";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";

export const CertOrOem = (props: CertOrOemProps) => {
  const heapTrackingId = () => {
    const { CERTIFICATE, OEM } = HEAP_DATA_ATTRIBUTES.CAPABILITIES.UPLOAD;
    switch (props.usedFor) {
      case "CERT":
        return CERTIFICATE;

      case "OEM":
        return OEM;
    
      default:
        return "";
    }
  }
  return (
    <Container>
      <TagAndFileConainer>
        <SelectDiv>
          <CertOrOemSelect {...props} />
        </SelectDiv>
        <UploadFileDiv $isDocUploaded={props?.uploadedDoc?.fileName?.length}>
          {props?.uploadedDoc ? (
            <CardTag
              onClose={() => {
                props.handleChange(
                  props.usedFor,
                  "DELETE",
                  props?.selectedValue,
                  props.index
                );
              }}
              cardText={
                props.usedFor === "ITR"
                  ? trimmedString(props?.selectedFile?.name, 15)
                  : trimmedString(props?.uploadedDoc?.fileName, 15)
              }
              usedFor="fileUploadTag"
              closable={checkForMobileScreen() ? false : true}
              data={props?.uploadedDoc}
              height="40px"
              key={props?.selectedValue}
              fileIcon={<img src="/images/note_new.svg" />}
            />
          ) : (
            <>
              <UploadIcon
                src={
                  checkForMobileScreen()
                    ? "/images/upload_white.svg"
                    : "/images/upload.svg"
                }
              />
              <span>
                {props?.uploadText ||
                  (checkForMobileScreen() ? "Upload Certificate" : "Upload")}
              </span>
              <InputWrap
                type="file"
                onChange={e => props.getFile(e, props.usedFor, props.index)}
                accept="application/pdf,image/jpeg"
                disabled={
                  props.usedFor === "ITR"
                    ? false
                    : !props?.selectedValue?.length
                }
                data-tracking-id={heapTrackingId()}
              />
            </>
          )}
        </UploadFileDiv>
      </TagAndFileConainer>
      <DeleteIconWrapper>
        <DeleteIcon
          src="/images/trash.svg"
          onClick={() =>
            props.handleChange(
              props.usedFor,
              "DELETE",
              props?.selectedValue,
              props.index
            )
          }
        />
      </DeleteIconWrapper>
    </Container>
  );
};
