import React, { useEffect, useState } from "react";
import { BasicLayOut } from "..";
import styled from "styled-components";
import { ButtonWithIcon, Button } from "../../Button";
import { CardProgress, CardInfo } from "../../Card";
import { Label } from "../../Label";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { ChangePasswordContainer } from "../../../Settings";
import { SettingsSidebar } from "../../../constants/mockData";
import { Link, useHistory } from "react-router-dom";

interface ProfileCreationProps {
  bodyContent: React.ReactNode;
}

const InnerWrapper = styled.div`
  display: flex;
  width: calc(100% - 60px);
  background-color: ${props => `${props.theme.colors.white}`};
  border-radius: ${props => `${props.theme.globalStyles.borderRadius}`};
  margin-top: 100px;
  margin-bottom: 30px;
  height: 100vh;
`;
const ProfileCreationHeader = styled.div`
  margin-top: 16px;
  width: 330px;
  height: 39px;
  border-bottom: 1px solid #d4e5fe;
  display: flex;
  align-items: center;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentWrapper = styled.div`
  padding: 31px 60px 30px 60px;
  width: 100%;
`;

const ProfileProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  padding: 26px 0px 26px 0px;
`;
const ProfileHeading = styled.div`
  color: #011759;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
`;
const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
`;
const ProfileInfo = styled.div`
  height: calc(100vh - 458px);
  overflow-y: scroll;
`;

const SettingsLayOut = ({ bodyContent }: ProfileCreationProps) => {
  const [item, setItem] = useState(bodyContent);
  const [currentRoute, setCurrentRoute] = useState("/userinfo");
  const history = useHistory();

  const handleClick = () => { };

  const handleRender = (item: any) => {
    setCurrentRoute("");
    switch (item.cardName) {
      case "Change Password":
        setItem(<ChangePasswordContainer />);
        break;
      default:
    }
  };

  const handleInfoClick = e => {
    const componentChilds = document.querySelectorAll(".profileInfo")[0]
      .children;
    const arr = [].slice.call(componentChilds);
    arr.map(item => {
      if (item.title == e.currentTarget.title) {
        item.style.background = "#F4F6FF";
      } else {
        item.style.background = "transparent";
      }
    });
  };
  return (
    <BasicLayOut>
      <InnerWrapper>
        <CardProgress type="profileCreationSidebar">
          <ButtonWithIcon
            onClick={handleClick}
            size="small"
            variant="blue"
            iconPlacement="left"
            iconClass="icon-arrow-left"
          >
            Back
          </ButtonWithIcon>
          <ProfileCreationHeader>Settings</ProfileCreationHeader>
          {/* <ProfileProgress>
            <ProgressBar />
            <ProfileHeading>
              <Label fontVariant = "bold" text="UP Hardwares" htmlFor="profileName" />
            </ProfileHeading>
            <ProfileName>Lucas George</ProfileName>
          </ProfileProgress> */}
          <ProfileInfo className="profileInfo">
            {SettingsSidebar.map((item, index) => {
              return (
                <Link
                  to={item.linkTo}
                  className={currentRoute === item.linkTo ? "active" : ""}
                >
                  <CardInfo
                    onClick={e => {
                      handleRender(item);
                      handleInfoClick(e);
                    }}
                    usedAt="progressView"
                    percentValue={item.status}
                    key={index}
                    title={item.cardName}
                    imgSrc={item.icon}
                  />
                </Link>
              );
            })}
          </ProfileInfo>
        </CardProgress>
        <ContentWrapper>{item}</ContentWrapper>
      </InnerWrapper>
    </BasicLayOut>
  );
};

export default SettingsLayOut;
