/* eslint-disable */
import React from "react";
import styled from "styled-components";

interface TagProps {
  underline?: "required" | "notRequired";
  text?: string;
  textUpperCase?: boolean;
  onClick?: () => void
}

const Base = styled.a<TagProps>`
  font-size: 12px;
  color: ${(props) => props.theme.colors.blue};
  font-weight: 700;
  text-decoration: ${(props) => (props.underline === "required" ? `underline` : `none`)};
  text-transform: ${(props) => (props.textUpperCase ? "uppercase" : "capitalize")};
  @media only screen and (max-width: 640px) {
    font-size: 12px;
  }
`;
function AuthenticationTags(props: TagProps) {
  return <Base {...props}>{props?.text}</Base>;
}

export default AuthenticationTags;
