export const TECHCERTS_REQUESTED = "techCerts/TECHCERTS_REQUESTED";
export const TECHCERTS_SUCCESS = "techCerts/TECHCERTS_SUCCESS";
export const TECHCERTS_FAILURE = "techCerts/TECHCERTS_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,

  techCertsData: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TECHCERTS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case TECHCERTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        techCertsData: action.result,
      };
    
    case TECHCERTS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const getTechCerts = () => ({
  types: [TECHCERTS_REQUESTED, TECHCERTS_SUCCESS, TECHCERTS_FAILURE],
  promise: (client) =>
    client.get("get_tech_certificates_lib")
});

