import React, { useRef, useEffect } from "react";
import {
  Box,
  Card,
  Divider,
  Flex,
  Modal,
  Typography,
  InputWithLabel,
  Button,
  StylesProps,
} from "venwiz-ui";
import { IManufCapabilitiesModalProps } from "Profile/Capabilities/Interfaces/Capabilities.interfaces";
import { RiCloseFill } from "react-icons/ri";
import {
  Formik,
  Form,
  FormikTouched,
  FormikErrors,
  FormikProps,
  FormikHelpers,
} from "formik";
import { colors } from "Theme";
import {
  IMachine,
  IPlantMachineDetail,
} from "venwiz-ui/dist/esm/components/organism/ManufCapabilitiesTable/ManufCapabilitiesTable.interface";
import {
  FORMIK_MACHINE,
  SORTED_MACHINE_PROCESS_NAMES,
  ALL_AVAILABLE_MACHINE_NAMES,
} from "Profile/Capabilities/components/PlantMachineDetails/plantMachineDetails.constant";
import { isDecimalNumber } from "shared/handlers/regex.handler";
import { MODAL_STYLES, FlexRow, StyledCustomDropdown } from "./plantMachineDetailsModal.styles";
import { toggleIsPageEdited } from "reducers/profileCreation";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { heapTrackTrigger } from "shared/handlers/heapAnalytics.handler";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";

interface IProps extends IManufCapabilitiesModalProps {
  showModal: boolean;
  handleClose: () => void;
  dropdownOptions?: {
    machineProcess?: string[];
    machineName?: string[];
    machineMake?: string[];
  };
}

export const AddOrEditMachineModal = (props: IProps) => {
  const {
    isFormAddNewOrEditExisting,
    indexOfCurrMachine,
    indexOfCurrPlant,
  } = props;
  const ID_OF_EXISTING_MACHINE = (props?.currMachine as IMachine)?._id;
  const { INITIAL_ERRORS, INITIAL_STATE, SCHEMA } = FORMIK_MACHINE;
  const { neo4jOemTags } = useSelector((state: RootState) => state.tags);
  const MACHINE_MAKE_DROPDOWN = neo4jOemTags?.length
    ? neo4jOemTags?.map((tagObj: {name: string}) => ({ value: tagObj?.name || "" })) 
    : [];
  const [formState, setFormState] = React.useState<IMachine>(INITIAL_STATE);
  const formRef = useRef<FormikProps<typeof INITIAL_STATE>>();
  const dispatch = useDispatch();
  const {
    PROCESS,
    MACHINE_MAKE,
    NUMBER_OF_MACHINES,
    MODEL_NAME,
    MACHINE_NAME,
    SPECIFICATION,
    OTHER_INFORMATION,
    SAVE_MACHINE_DETAILS,
  } = HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.MACHINE.FORM;

  useEffect(() => {
    const EXISTING_MACHINE = props?.currMachine as IMachine;
    setFormState({
      // inject remaining keys like _id, gallery
      ...EXISTING_MACHINE,
      process: EXISTING_MACHINE?.process || "",
      name: EXISTING_MACHINE?.name || "",
      number: EXISTING_MACHINE?.number || null,
      specification: EXISTING_MACHINE?.specification || "",
      modelName: EXISTING_MACHINE?.modelName || "",
      otherInfo: EXISTING_MACHINE?.otherInfo || "",
      make: EXISTING_MACHINE?.make || "",
      gallery: EXISTING_MACHINE?.gallery || [],
    });
  }, [props?.currMachine]);

  const handleOnSubmitForm = (
    values: typeof INITIAL_STATE,
    formikHelpers: FormikHelpers<typeof INITIAL_STATE>
  ) => {
    const ID_OF_EXISTING_PLANT = (props?.currPlant as IPlantMachineDetail)?._id;
    const MACHINE_PAYLOAD_OBJECT = {
      ...props?.currMachine,
      process: values?.process || "",
      name: values?.name || "",
      number: values?.number || 1,
      specification: values?.specification || "",
      modelName: values?.modelName || "",
      otherInfo: values?.otherInfo || "",
      make: values?.make || "",
    } as IMachine;

    if (isFormAddNewOrEditExisting === "EDIT_EXISTING") {
      /** Update Existing Machine */
      props.setPlantMachineDetails((prev) => {
        return prev?.map((plantObj, plantIdx) => {
          const isSamePlant = (
            plantObj?._id 
            && ID_OF_EXISTING_PLANT 
            && plantObj?._id === ID_OF_EXISTING_PLANT
          ) || indexOfCurrPlant === plantIdx;

          if (isSamePlant) {
            return {
              ...plantObj,
              machines: plantObj?.machines?.map((existingMachine, machineIdx) => {
                const isSameMachine = (
                  existingMachine?._id 
                  && ID_OF_EXISTING_MACHINE 
                  && existingMachine?._id === ID_OF_EXISTING_MACHINE
                ) || indexOfCurrMachine === machineIdx;

                return isSameMachine
                  ? { ...existingMachine, ...MACHINE_PAYLOAD_OBJECT }
                  : existingMachine;
              }),
            };
          }
          return plantObj;
        });
      });
    } else if (isFormAddNewOrEditExisting === "ADD_NEW") {
      /** Add New Machine */
      props?.setPlantMachineDetails((prev) => {
        return prev?.map((plantObj, plantIdx) => {
          const isSamePlant = (
            plantObj?._id 
            && ID_OF_EXISTING_PLANT 
            && plantObj?._id === ID_OF_EXISTING_PLANT
          ) || indexOfCurrPlant === plantIdx;

          if (isSamePlant) {
            return {
              ...plantObj,
              machines: [...plantObj?.machines, { ...MACHINE_PAYLOAD_OBJECT, gallery: [] }, ],
            };
          }
          return plantObj;
        });
      });
    }

    /** Heap Analytics */
    heapTrackTrigger({
      windowObj: window,
      eventName: SAVE_MACHINE_DETAILS,
      value: {
        process: values?.process || "",
        name: values?.name || "",
        number: values?.number || "",
        specification: values?.specification || "",
        modelName: values?.modelName || "",
        otherInfo: values?.otherInfo || "",
        make: values?.make || "",
      },
    });

    /** Clear State */
    props?.handleClose();
    setFormState(INITIAL_STATE);
    formikHelpers?.resetForm();
    toggleIsPageEdited(dispatch, true);
  };

  const handleOnChange = ({
    value,
    TYPE,
  }: {
    value: string | number | null;
    TYPE: keyof IMachine;
  }) => {
    const VAL = value;
    switch (TYPE) {
      case "process":
      case "name":
      case "specification":
      case "modelName":
      case "otherInfo":
      case "make":
        setFormState((prev) => ({ ...prev, [TYPE]: VAL || "" }));
        break;

      case "number":
        setFormState((prev) => ({
          ...prev,
          number: String(VAL)?.length
            ? isDecimalNumber(String(VAL))
              ? Number(VAL)
              : prev?.number
            : null,
        }));
        break;

      default:
        break;
    }
  };

  return (
    <Modal
      padding={"12px 20px 30px 20px"}
      backgroundStyles={{ zIndex: 1000 }}
      show={props?.showModal}
      handleClose={() => {}}
      closeIcon={
        <Box
          padding={"5px"}
          cursor={"pointer"}
          onClick={() => {
            /**
             * handleClose callback function must only be triggered on clicking the close icon
             * and not on clicking the modal handleClose prop
             * because it will close the modal:
             *  - even when the user clicks outside the modal
             *  - or if the form has validation errors
             */
            props?.handleClose();
          }}
        >
          <RiCloseFill size={20} color={"#666d77"} />
        </Box>
      }
      // To ensure that the modal is responsive and takes up the entire screen on mobile devices
      maxWidth={{ default: "100vw", sm: "1070px" }}
      width={{ default: "100vw", sm: "75vw" }}
      maxHeight="80vh"
      height={{ default: "80vh", sm: "auto" }}
      overflow="auto"
      position="relative"
      modalHeader={
        <Typography
          padding="4px"
          fontSize={"14px"}
          fontWeight={600}
          color={colors.darkBlue}
        >
          Machine Details
        </Typography>
      }
      modalBody={
        <Formik
          /** prop enableReinitialize
           *  helps to re-render form with new Initial State Values
           *  (like new values injected by API Call from useEffect) */
          enableReinitialize
          /** prop innerRef
           * is used to access form values outside the Formik Component */
          innerRef={formRef}
          initialValues={formState}
          validationSchema={SCHEMA}
          isInitialValid={SCHEMA.isValidSync(INITIAL_STATE)}
          initialErrors={INITIAL_ERRORS}
          onSubmit={handleOnSubmitForm}
        >
          {(formikProps) => {
            const {
              errors,
              touched,
              setValues,
              setFieldValue,
              values,
              isValid,
            } = formikProps;

            const ERROR_MESSAGE = {
              process: errors?.process || "",
              name: errors?.name || "",
              number: errors?.number || "",
              specification: errors?.specification || "",
              modelName: errors?.modelName || "",
              otherInfo: errors?.otherInfo || "",
              make: errors?.make || "",
            };

            const HAS_ERRORS = {
              process: touched?.process && Boolean(errors?.process),
              name: touched?.name && Boolean(errors?.name),
              number: touched?.number && Boolean(errors?.number),
              specification:
                touched?.specification && Boolean(errors?.specification),
              modelName: touched?.modelName && Boolean(errors?.modelName),
              otherInfo: touched?.otherInfo && Boolean(errors?.otherInfo),
              make: touched?.make && Boolean(errors?.make),
            };

            return (
              <Form
                onChange={(e: React.FormEvent) => {
                  // handleOnChangeForm(e, formikProps)
                }}
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* Form Field Wrapper */}
                <FlexRow>
                  {/* Row #1 */}
                  <FlexRow
                    flexDirection={{ default: "column", md: "row" }}
                  >
                    {/* Machine Process Dropdown */}
                    <Flex
                      id={`machine-process`}
                      flexDirection={"column"}
                      width={"100%"}
                      flex={1}
                    >
                      <Box
                        width={"100%"}
                        padding={"4px"}
                        borderWidth={"1px"}
                        borderStyle={"solid"}
                        borderRadius={"8px"}
                        borderColor={"#ffffff"}
                      >
                        <Typography
                          variant={"p"}
                          height={"17px"}
                          fontSize={"12px"}
                          color={"#7E8FAB"}
                          marginBottom={"8px"}
                          letterSpacing={"1.2px"}
                        >
                          Process
                        </Typography>
                        <StyledCustomDropdown.CustomDropdown
                          filterOption
                          placeholder="Cutting"
                          style={{ width: "100%", borderRadius: "12px" }}
                          value={values?.process || ""}
                          options={SORTED_MACHINE_PROCESS_NAMES?.map((str) => ({ value: str }))}
                          onSelect={(value, option) => {
                            handleOnChange({ value, TYPE: "process" });
                          }}
                          onChange={(value, option) => {
                            handleOnChange({ value, TYPE: "process" });
                          }}
                          onBlur={() => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { process: values?.process || "" },
                              eventName: PROCESS,
                            });
                          }}
                        />
                      </Box>
                    </Flex>

                    {/* Machine Name Dropdown */}
                    <Flex
                      id={`machine-name`}
                      flexDirection={"column"}
                      flex={1}
                      width={"100%"}
                    >
                      <Box
                        width={"100%"}
                        padding={"4px"}
                        borderWidth={"1px"}
                        borderStyle={"solid"}
                        borderRadius={"8px"}
                        borderColor={HAS_ERRORS.name ? "#D91515" : "#ffffff"}
                      >
                        <StyledCustomDropdown.MandatoryTypography
                          variant={"p"}
                          height={"17px"}
                          fontSize={"12px"}
                          color={"#7E8FAB"}
                          marginBottom={"8px"}
                          letterSpacing={"1.2px"}
                        >
                          Machine Name
                        </StyledCustomDropdown.MandatoryTypography>
                        <StyledCustomDropdown.CustomDropdown
                          filterOption
                          placeholder="CNC Laser Cutting Machine"
                          style={{ width: "100%", borderRadius: "12px" }}
                          value={values?.name || ""}
                          options={ALL_AVAILABLE_MACHINE_NAMES?.map((str) => ({ value: str }))}
                          onSelect={(value, option) => {
                            handleOnChange({ value, TYPE: "name" });
                          }}
                          onChange={(value, option) => {
                            handleOnChange({ value, TYPE: "name" });
                          }}
                          onBlur={() => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { name: values.name || "" },
                              eventName: MACHINE_NAME,
                            });
                          }}
                        />
                      </Box>
                      {HAS_ERRORS.name && (
                        <Typography
                          color={HAS_ERRORS.name ? "#D91515" : "#7E8FAB"}
                          fontSize={{ default: "8px", xxs: "8px", sm: "12px" }}
                          textAlign={"left"}
                          paddingTop={"6px"}
                          fontStyle={"italic"}
                        >
                          {ERROR_MESSAGE.name}
                        </Typography>
                      )}  
                    </Flex>
                  </FlexRow>

                  {/* Row #2 */}
                  <FlexRow>
                    {/* Machine Number input */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"Number of Machines"}
                      id={`machine-number`}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.number}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "4px",
                          width: "100%",
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        htmlType: "number",
                        inputProps: {
                          style: MODAL_STYLES.COMMON.TEXT_INPUT_STYLES,
                          name: "number",
                          label: "number",
                          placeholder: "8",
                          value: values?.number || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange({
                              value: e?.target?.value,
                              TYPE: "number",
                            });
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { numberOfMachines: values.number || "" },
                              eventName: NUMBER_OF_MACHINES,
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.number}
                    />

                    {/* Machine Model Name input */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"Model Name"}
                      id={`model-name`}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.modelName}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "4px",
                          width: "100%",
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        inputProps: {
                          style: MODAL_STYLES.COMMON.TEXT_INPUT_STYLES,
                          name: "modelName",
                          label: "modelName",
                          placeholder: "XT-E1530",
                          value: values?.modelName || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange({
                              value: e?.target?.value || "",
                              TYPE: "modelName",
                            });
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { modelName: values.modelName || "" },
                              eventName: MODEL_NAME,
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.modelName}
                    />
                  </FlexRow>

                  {/* Row #3 */}
                  <FlexRow>
                    {/* Machine Make Dropdown */}
                    <Flex
                      id={`machine-make`}
                      flexDirection={"column"}
                      width={"100%"}
                      // flex={{ default: 1.5, md: 1.5 }}
                    >
                      <Box
                        width={"100%"}
                        padding={"4px"}
                        borderWidth={"1px"}
                        borderStyle={"solid"}
                        borderRadius={"8px"}
                        borderColor={"#ffffff"}
                      >
                        <Typography
                          variant={"p"}
                          height={"17px"}
                          fontSize={"12px"}
                          color={"#7E8FAB"}
                          marginBottom={"8px"}
                          letterSpacing={"1.2px"}
                        >
                          Machine Make
                        </Typography>
                        <StyledCustomDropdown.CustomDropdown
                          filterOption
                          placeholder="XTLASER"
                          style={{ width: "100%", borderRadius: "12px" }}
                          value={values?.make || ""}
                          options={MACHINE_MAKE_DROPDOWN}
                          onSelect={(value, option) => {
                            handleOnChange({ value, TYPE: "make" });
                          }}
                          onChange={(value, option) => {
                            handleOnChange({ value, TYPE: "make" });
                          }}
                          onBlur={() => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { make: values.make || "" },
                              eventName: MACHINE_MAKE,
                            });
                          }}
                        />
                      </Box>
                    </Flex>
                  </FlexRow>

                  {/* Row #4 */}
                  <FlexRow>
                    {/* Machine Specification input */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        // flex: { default: 1, md: 2 },
                      }}
                      header={"Specification"}
                      id={`machine-specification`}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.specification}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "4px",
                          width: "100%",
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        inputProps: {
                          style: MODAL_STYLES.COMMON.TEXT_INPUT_STYLES,
                          name: "machineSpecification",
                          label: "machineSpecification",
                          placeholder: "Enter machine specifications and specifications of the product being manufactured",
                          value: values?.specification || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange({
                              value: e?.target?.value || "",
                              TYPE: "specification",
                            });
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { specification: values.specification || "" },
                              eventName: SPECIFICATION,
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.specification}
                    />
                  </FlexRow>

                  {/* Row #5 */}
                  {/* Machine Other Information input */}
                  {/* <FlexRow>
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"Other Information"}
                      id={`machine-other-information`}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.otherInfo}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "4px",
                          width: "100%",
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        inputProps: {
                          style: MODAL_STYLES.COMMON.TEXT_INPUT_STYLES,
                          name: "machineOtherInformation",
                          label: "machineOtherInformation",
                          placeholder: "Enter any other information about the machine",
                          value: values?.otherInfo || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange({
                              value: e?.target?.value || "",
                              TYPE: "otherInfo",
                            });
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              value: { otherInfo: values.otherInfo || "" },
                              eventName: OTHER_INFORMATION,
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.otherInfo}
                    />
                  </FlexRow> */}

                </FlexRow>

                {/* Form Actions Wrapper */}
                <Flex
                  paddingLeft={"4px"}
                  width={"100%"}
                  alignItems={"center"}
                  marginTop={"20px"}
                  alignSelf={"center"}
                  justifyContent={"flex-start"}
                >
                  <Button
                    variant="primary"
                    colorSchema="blue"
                    width={"max-content"}
                    {...({ type: "submit" } as StylesProps)}
                    style={{
                      backgroundColor: colors.blue,
                      borderColor: colors.blue,
                    }}
                  >
                    {isFormAddNewOrEditExisting === "EDIT_EXISTING" ? "Update" : "Save"}
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
};
