import { Types as OnBoardingTypes } from "./types";

export const ActionCreators: any = {
  getOnboardingData: (gstn: string, email: string) => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.GET_ONBOARDING_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.get(`onboarding_api/${gstn}/${email}`),
  }),

  getEquipmentFamilyList: () => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.GET_EQUIPMENT_FAMILY_LIST_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.get("get_equipment_family_list"),
  }),
  getOpenEquipmentFamilyList: () => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.GET_EQUIPMENT_FAMILY_LIST_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.get("get_equipment_family_list_open"),
  }),

  getServiceGroupList: () => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.GET_SERVICE_GROUP_LIST_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.get("get_service_group_list"),
  }),
  getOpenServiceGroupList: () => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.GET_SERVICE_GROUP_LIST_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.get("get_service_group_list_open"),
  }),

  postOnboardingData: (data: any) => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.POST_ONBOARDING_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.post(`onboarding_api`, { data }),
  }),

  verifySecUserGstnCert: (data: any) => ({
    types: [
      OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED,
      OnBoardingTypes.VERIFY_SUB_USER_GST_CERT_SUCCESS,
      OnBoardingTypes.ONBOARDING_OPERATION_FAILURE,
    ],
    promise: client => client.post('sec_user_gstn_verify', {data} ),
  }),

  verifyOnboardingLink: (token: any) => ({
    types: [
      OnBoardingTypes.VERIFY_ONBOARDING_LINK_REQUESTED,
      OnBoardingTypes.VERIFY_ONBOARDING_LINK_SUCCESS,
      OnBoardingTypes.VERIFY_ONBOARDING_LINK_FAILURE,
    ],
    promise: client => client.get(`verify_onboarding_link/${token}` ),
  }),

}

export default ActionCreators;
