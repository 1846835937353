import { Drawer } from "antd";
import styled from "styled-components";
interface Styles{
  $width:string;
}

export const DrawerDiv = styled(Drawer)<Styles>`
  display: none;
  @media only screen and (max-width: 640px) {
    display: block;
    margin-top: 56px;
    & .ant-drawer-content-wrapper {
      width: ${props=>props?.$width} !important;
    }
    & .ant-drawer-body{
      padding:20px 0px 0px 00px;
    }
  }
`;

export const NavItem=styled.div`
font-weight: 500;
font-size: 14px;
line-height: 30px;
color: #011759;
margin-bottom:3.25vh;
padding-left:40px;
`
