import React, { useRef, useState } from "react";
import styled from "styled-components";
import InputWithHeaderForEdit from "../inputHeader";
import { LogoImage } from "../../../Image";
import { Formik, ErrorMessage, useField } from "formik";
type Sizes =
  | "small"
  | "middle"
  | "large"
  | "semiMiddle"
  | "extraLarge"
  | "profileCreationSmall"
  | "profileCreationMedium"
  | "profileCreationLarge"
  | undefined;
type usedFor =
  | "login"
  | "registration"
  | "profileCreationHighlitedlabel"
  | "profileCreation"
  | "profileCreationLarge"
  | undefined;

interface InputWithEditProps {
  id?: string;
  usedFor?: usedFor;
  inputBoxSize?: Sizes;
  children?: string;
  inputType: string;
  importance: string;
  label?: string;
  name: string;
  error?: any;
  touch?: any;
  imgSrc?: string;
  placeholder?: string;
  value?: any;
  withHeader?: boolean;
  onChange?: (e: any) => void;
  hidden?: boolean;
  disabled?: boolean;
  buttonDisable?: boolean;
  inputFieldError?: boolean;
  maxChar?: number;
  inputBoxStyle?: object;
  inputDataAttribute?: object;
}

const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 22px;
`;

const InputWithEdit = ({
  onChange,
  buttonDisable,
  disabled = true,
  inputFieldError,
  ...props
}: InputWithEditProps) => {
  // const [field]=useField(props);
  // const elementName = field.name;
  const [editClick, setEditClick] = useState(false);
  const handleClick = () => {
    setEditClick(!editClick);
  };

  return (
    <>
      <InputWithHeaderForEdit
        inputDataAttribute={props?.inputDataAttribute}
        inputFieldError={inputFieldError}
        usedFor={props.usedFor}
        inputBoxSize={props.inputBoxSize}
        inputType={props.inputType}
        importance={props.importance}
        label={props.label}
        name={props.name}
        //refValue={editClick}
        placeholder={props.placeholder}
        disable={disabled}
        value={props.value}
        withHeader={props.withHeader}
        hidden={props.hidden}
        buttonDisable={buttonDisable}
        maxChar={props.maxChar}
        onHandleChange={e => {
          onChange(e);
        }}
        inputBoxStyle={props.inputBoxStyle}
      >
        {props.children}
      </InputWithHeaderForEdit>
      {!!props.imgSrc && (
        <Div onClick={handleClick}>
          <LogoImage
            imageSrc={props.imgSrc}
            altMesg="edit button"
            imageType="icon"
          />
        </Div>
      )}
    </>
  );
};

export default InputWithEdit;
