import styled from "styled-components";
import { Form } from "formik";
import { Textarea } from "components/TextArea/TextAreaWithHeader/TextArea";
import { BoxContainer } from "components/DatePicker/DatePickerWithLabel/DatePickerWithLabel";
import { InputBox } from "components/Input/InputBoxWithEdit/inputBox";
import { InputWithLabel, StylesProps, Box } from "venwiz-ui";
import { Property } from "csstype";
import { BoxProps } from "venwiz-ui/dist/cjs/styled-system/primitives/Box";

export const FormikForm = styled(Form)`
  overflow: hidden;
`;

export const AddMore = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #215ec9;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #215ec9;
  background: #f4f6ff;
  border-radius: 8px;
  width: 550px;
  margin: auto;
  height: 40px;
  @media only screen and (max-width: 640px) {
    width: 100%;
    max-width: 93.15vw;
  }
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;
export const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  margin-bottom: 30px;
`;

export const ContentInputs = styled.div`
  margin-top: 20px;
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
  @media only screen and (max-width: 640px) {
    /* wrt to 180px from top and plus 60px height of save button =240px*/
    height: calc(100vh - 240px);
    overflow-x: hidden;
    padding-bottom: 120px;
  }
`;

export const Div = styled.div`
  position: relative;
`;

export const FormWrapper = styled.div`
  width: 840px;
  @media only screen and (max-width: 640px) {
    width: 100vw;
    /* padding: 0px 16px; */
    padding: 0px 10px;
  }
`;

export const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;
`;

export const BtnMargin = styled.div`
  margin-left: 30px;
`;

export const RequiredAnnotationLabel = styled.span<any>`
  color: ${props => props.color};
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;

export const RequiredWrapper = styled.div`
  margin-left: 15px;
`;

// ===== ExperienceContainer Styles =======

export const WarningMsg = styled.div`
  display: flex;
  position: absolute;
  top: 95px;
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  height: auto;
  margin-top: 20px;
  @media only screen and (max-width: 640px) {
    margin-top: 0px;
    .ClientIndustry {
      display: none;
    }
  }
`;

export const ContainerDiv = styled.div<any>`
  margin-bottom: ${props => props.marginBottom || "40px"};
  display: flex;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 0px;
    @supports (-webkit-touch-callout: none) {
      margin-bottom: 20px;
    }
    ${BoxContainer} {
      width: 41.824vw !important;
    }
    ${InputBox} {
      width: 100%;
    }
  }
`;
export const ProdDescBttnWrapper = styled.div<any>`
  .MobileScreen {
    display: none;
  }
  @media only screen and (max-width: 640px) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    .MobileScreen {
      display: flex;
    }
  }
`;

export const ImageDiv = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivRow = styled.div`
  display: flex;
`;

export const FlexWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 40px;
  @media only screen and (max-width: 640px) {
    .project-cost-dropdown {
      display: none;
    }
    row-gap: 15px;
  }
`;

export const ProjScopeWrapper = styled(FlexWrapContainer)<any>`
  @media only screen and (max-width: 640px) {
    justify-content: flex-start;
    flex-direction: row;
    display: ${props => (props?.$isOpen ? "flex" : "none")};
    flex-wrap: nowrap;
    position: relative;
    flex-direction: column;
    row-gap: 0px;
    width: 92.525vw;
    height: 238px;
    background: #f4f6ff;
    border: 1px solid #d4e5fe;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px 11px;
    border-radius: 8px 0px 8px 0px;
    ${Textarea} {
      width: 86.9vw;
    }
  }
`;
export const DateDesktopView = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;
export const DateMobileView = styled.div<any>`
  display: none;
  @media only screen and (max-width: 640px) {
    width: 100%;
    display: block;
  }
`;
export const HeaderName = styled.div`
  width: auto;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
  opacity: 0.7;
  color: #011759;
`;

export const DropdownWrapper = styled.div`
  margin-right: 10px;
`;

export const InputWrap = styled.input`
  position: absolute;
  right: 0px;
  right: -30px;
  opacity: 0;
  z-index: 100;
  width: 160px;
  height: 60px;
  top: 27px;
  bottom: 0px;
  cursor: pointer;
  width: 60px;
`;

export const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #011759;
  margin-bottom: 10px;
  @media only screen and (max-width: 640px) {
    margin-top: 15px;
    margin-bottom: 0px;
  }
`;

export const CompanyVerifyTooltipDiv = styled.div`
  .ant-tooltip {
    left: 19% !important;
  }
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 17px;
  }
  .ant-tooltip-arrow {
    width: 25px;
    height: 20px;
  }
  .ant-tooltip-arrow-content {
    width: 17px;
    height: 17px;
    bottom: 7px;
  }
  @media only screen and (max-width: 640px) {
    .ant-tooltip {
      left: 145px !important;
    }
    .ant-tooltip-inner {
      height: 60px;
    }
  }
`;

export const CompanyVerficationStatus = styled.img<{
  position?: Property.Position,
  cursor?: Property.Cursor,
}>`
  position: ${props => props?.position || "absolute"};
  cursor: ${props => props?.cursor || "pointer"};
  width: 20px;
  height: 20px;
  top: 32px;
  right: 25px;
`;

export const ProjectDocTextDiv = styled.div`
  display: block;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: rgba(1, 23, 89, 0.6);
  width: 600px;
  @media only screen and (max-width: 640px) {
    width: 92.01vw;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const UploadBttn = styled.button<any>`
  position: relative;
  width: 200px;
  height: 40px;
  border-radius: 8px;
  margin-left: 10px;
  margin-top: 32px;
  font-weight: 600;
  font-size: 14px;
  background: #215ec9;
  border: 1px solid #215ec9;
  border-radius: 8px;
  color: #ffffff;
  @media only screen and (max-width: 640px) {
    width: 45.305vw;
    margin-left: 0px;
    margin-top: 0px;
    border: ${props => props?.$error && "1px solid #BE000B"};
    background: ${props => props?.$error && "rgba(190, 0, 11, 0.05)"};
    color: ${props => props?.$error && "#215EC9"};
  }
`;
export const UploadIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 10px;
  @media only screen and (max-width: 640px) {
    max-width: 191px;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  row-gap: 10px;
  max-width: 550px;
  column-gap: 10px;
  margin-bottom: 10px;
`;

export const CustomTagContainer = styled(TagContainer)`
  .ant-tag {
    border: 1px solid #215ec9;
    border-radius: 8px;
  }
`;

export const InputBoxStyle = {
  width: "180px",
  height: "40px",
  fontSize: "14px",
  lineHeight: "21px",
  paddingRight: "20px"
};

export const VenwizUIDatePickerWithLabel = styled(InputWithLabel)`
  & .react-datepicker__navigation{
    top: 12px;
  }

  .react-datepicker {
    font-size: 1.5rem;
  }

  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-size: 1.8rem;
  }

  .react-datepicker__close-icon {
    padding: 12px;
    background-color: green;
  }
`;

export const EXPERIENCE_STYLES = {
  ROW: {
    gap: "12px",
    // width: "100%",
    width: { default: "100%", xxs: "90vw", lg: "800px" },
    flexDirection: { default: "column", "lg": "row" },
    justifyContent: "center",
    alignItems: { default: "center", lg: "flex-start" },
    margin: "0 auto",
  } as StylesProps,
  INPUT_FIELD: {
    height: "70px",
    backgroundColor: "#ffffff",
    width: { default: "85vw", lg: "244px" },
    margin: { default: "0 auto", lg: "" },
  } as StylesProps,
};

export interface ICustomReactQuillEditorWrapperProps extends BoxProps {
  isError?: boolean;
  innerWidth?: string;
}

export const ExperienceCustomReactQuillEditorWrapper = styled(Box)<ICustomReactQuillEditorWrapperProps>`
  .ql-toolbar.ql-snow {
    border: 0 !important;
    border-bottom: 1px solid rgb(212, 229, 254) !important;
    background-color: #fff !important;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;

  }

  .ql-container.ql-snow {
    border: 0 !important;
  }

  .ql-container {
    height: 200px;
    font-family: Poppins;
    font-size: 14px;
  }

  .ql-editor {
    background-color: #fff;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  .quill {
    width: 100%;
    border: ${props => props?.isError ? "1px solid #D91515" : "0 !important"};
  }

  p {
    margin-bottom: 0 !important;
    display: contents !important;
  }
`;