import { Button } from "antd";
import { BoxContainer, Textarea } from "components/TextArea/TextAreaWithHeader/TextArea";
import styled from "styled-components";
import { InputBox } from "components/Input/InputBoxWithEdit/inputBox";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
  @media only screen and (max-width: 640px) {
    margin-bottom: 43px;
  }
`;
export const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #011759;
  opacity: 0.7;
`;
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  @media only screen and (max-width: 640px) {
    width: 100%;
    position: relative;
    ${InputBox} {
      padding-left: 10px;
    }
  }
`;
export const Bttn = styled(Button)`
  width: 200px;
  height: 40px;
  background: #47c882;
  border-radius: 100px;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 2.196vw;
  img {
    display: none;
  }
  @media only screen and (max-width: 640px) {
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #215ec9;
    position: absolute;
    width: 40px;
    height: 100%;
    right: 0;
    border-radius: 0px 8px 8px 0px;
    span {
      display: none;
    }
    img {
      display: block;
    }
    :focus,
    :hover {
      background: #215ec9;
      color: #ffffff;
    }
  }
`;
export const TextAreaWrapper = styled.div`
  height: 120px;
  width: auto;
  @media only screen and (max-width: 640px) {
    height: 80px;
    width: 100%;
    ${BoxContainer} {
      height: 80px;
    }
    ${Textarea} {
      padding-left: 10px;
    }
  }
`;
