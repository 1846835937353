import { PublishModalState } from "reducers/marketingSuite";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";

export const marketingSuiteRoute = (dispatch) => {
  const isPublished = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)?.isPublished;
  if (isPublished) {
    window.open("/marketingsuite", "_blank");
  } else {
    PublishModalState(dispatch, true);
  }
}

export const setIsPublishedData=(data)=>{
  const userData = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData);
  LocalStorageService.set(AppConfig.STORAGE_KEYS.userData,{...userData,isPublished:data?.isPublished});
}