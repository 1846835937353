import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Formik } from "formik";
import { notification } from "antd";
import { UserInfoModal } from "ProfileCreation";
import { InputWithEdit, InputWithHeader } from "components/Input";
import { checkDuplicateMobile, checkFakeDuplicateMobile } from "reducers/auth";
import { showEditReducer, toggleIsPageEdited } from "reducers/profileCreation";
import { userInfoGet, userInfoUpdate } from "reducers/userInfo";
import { UserInfo } from "ContextApi/ContextApi";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import FormField from "Profile/shared/FormField/FormField";
import { handleScrollToView } from "shared/handlers/form.handler";
import {
  ButtonWrapper,
  ContentInputs,
  FormikForm,
  FormWrapper,
  STYLES,
  Wrapper,
} from "./UserInfo.styles";
import {
  HEAP_DATA_ATTRIBUTES,
  HEAP_DATA_TRACKING_ID,
} from "constants/heapAnalytics";

const UserInformation = () => {
  let isFirstError = false;
  const history = useHistory();
  const userInformation = {
    gstn: "",
    userInfoId: "",
    vendorId: "_id",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    companyRole: "",
  };
  const dispatch = useDispatch();
  const phoneRegex = /^(\+91\d{10})$/;
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData, error, userUpdateResponse, errorData } = userInfoState;
  const authState = useSelector((state: any) => state.auth);
  const { checkIsDuplicateMobile } = authState;
  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton, isPageEdited } = buttonState;
  const [userInfo, setUserInfo] = useState(userInformation);
  const [showPhoneValue, setshowPhoneValue] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [saveState, setSaveState] = useState(false);
  let validateData = false;

  useEffect(() => {
    if (error) {
      dispatch(userInfoGet({}));
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      openNotification(errorData);
    }
  }, [error]);

  useEffect(() => {
    toggleIsPageEdited(dispatch, false);
  }, []);

  useEffect(() => {
    if (userInfoData !== null) {
      setUserInfo(userInfoData);
    }
  }, [userInfoData]);

  useEffect(() => {
    if (showPhoneValue != "" && showPhoneValue.length === 13) {
      if (userInfoData.phoneNumber !== showPhoneValue) {
        dispatch(
          checkDuplicateMobile(
            JSON.stringify({
              mobileNo: `${showPhoneValue}`,
            })
          )
        );
      } else {
        checkFakeDuplicateMobile(dispatch, false);
      }
    }
  }, [showPhoneValue]);

  useEffect(() => {
    if (checkIsDuplicateMobile) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [checkIsDuplicateMobile]);

  const validate = Yup.object({
    phoneNumber: Yup.string()
      .matches(
        phoneRegex,
        "Invalid ! please enter your mobile number in the correct format"
      )
      .max(13, "Please enter your mobile number in the correct format"),
  });

  if (
    userInfo.firstName !== undefined &&
    userInfo.firstName !== "" &&
    userInfo.phoneNumber !== undefined &&
    userInfo.phoneNumber !== ""
  ) {
    validateData = true;
  } else {
    validateData = false;
  }

  const handleFormikValidation = (e) => {
    setUserInfo({ ...userInfo, [e.name]: e.value });
    toggleIsPageEdited(dispatch, true);
  };

  const handlePhoneBlur = (e) => {
    if (e != showPhoneValue) {
      setshowPhoneValue(e);
    }
  };

  const handleOnChange = (e) => {
    const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const numberRegex = /\d/;
    if (
      !specialChar.test(e.target.value) &&
      !numberRegex.test(e.target.value)
    ) {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value.trimStart() });
      toggleIsPageEdited(dispatch, true);
    }
  };

  const handleClick = (type) => {
    if (type === "save") {
      setSaveState(true);
      if (validateData) {
        setIsDataSaving(true);
        dispatch(
          userInfoUpdate({
            gstn: getGstn(),
            firstName: userInfo.firstName,
            lastName: userInfo.lastName,
            phoneNumber: userInfo.phoneNumber,
            companyRole: userInfo.companyRole,
          })
        )
          .then((res) => {
            if (!res?.mobileVerificationRequired) {
              dispatch(userInfoGet({}));
            }
            notificationFunc("success");
            setIsDataSaving(false);
          })
          .catch(() => {
            setIsDataSaving(false);
          });
      }
    }
  };

  const openNotification = (placement) => {
    notification.error({
      message: `Error`,
      description: placement,
    });
  };

  const onHandleScroll = (id) => {
    if (!isFirstError) {
      handleScrollToView(id);
      isFirstError = true;
    }
    return true;
  };
  return (
    <>
      <UserInfo.Provider value={[userInfo, setUserInfo]}>
        <Formik
          initialValues={userInformation}
          validationSchema={validate}
          onSubmit={(values) => {
            dispatch(
              userInfoUpdate({
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                phoneNumber: userInfo.phoneNumber,
              })
            ).then((res) => {
              if (!res?.mobileVerificationRequired) {
                dispatch(userInfoGet({}));
              }
            });
          }}
        >
          {(formik) => (
            <FormikForm>
              <ContentInputs>
                <Wrapper>
                  <FormWrapper>
                    <div id="name">
                      <FormField
                        showError={saveState && userInfo.firstName.length < 1}
                        label={"Full Name"}
                        isStar={true}
                      >
                        <InputWithEdit
                          withHeader={false}
                          usedFor="profileCreation"
                          inputBoxSize="large"
                          inputType="text"
                          importance="important"
                          label=""
                          name="firstName"
                          value={`${userInfo.firstName}`}
                          inputFieldError={
                            saveState &&
                            userInfo.firstName.length < 1 &&
                            onHandleScroll("name")
                          }
                          placeholder="Full Name"
                          onChange={(e) => {
                            handleOnChange(e);
                          }}
                          inputBoxStyle={STYLES.InputStyle}
                        >
                          FullName
                        </InputWithEdit>
                      </FormField>
                    </div>
                    <FormField
                      showError={saveState && userInfo.email.length < 1}
                      label={"Email"}
                      isStar={true}
                    >
                      <InputWithEdit
                        withHeader={false}
                        buttonDisable
                        usedFor="profileCreation"
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        label="Email Address"
                        name="email"
                        value={userInfo.email}
                        placeholder="Enter Email"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                        inputBoxStyle={STYLES.InputStyle}
                      ></InputWithEdit>
                    </FormField>
                    <div id="phone">
                      <FormField
                        showError={saveState && userInfo.phoneNumber.length < 1}
                        label={"Mobile Number"}
                        isStar={true}
                        info="The updated mobile number will be used for all future logins"
                      >
                        <InputWithHeader
                          style={STYLES.InputStyle}
                          withHeader={false}
                          change={true}
                          usedFor="profileCreation"
                          inputBoxSize="large"
                          inputType="text"
                          importance="important"
                          label="Mobile Number"
                          name="phoneNumber"
                          error={formik.errors}
                          touch={formik.touched}
                          labelSize="12px"
                          value={userInfo.phoneNumber}
                          placeholder="Enter Mobile Number"
                          {...(formik.touched["phoneNumber"] &&
                            formik.errors["phoneNumber"] === undefined &&
                            handlePhoneBlur(formik.values.phoneNumber))}
                          onChange={(e) => {
                            handleFormikValidation(e);
                          }}
                          inputFieldError={
                            saveState &&
                            userInfo.phoneNumber.length < 1 &&
                            onHandleScroll("phone")
                          }
                        ></InputWithHeader>
                        {userUpdateResponse &&
                          userUpdateResponse.mobileVerificationRequired && (
                            <UserInfoModal phoneNum={userInfo.phoneNumber} />
                          )}
                      </FormField>
                    </div>
                    <FormField showError={false} label={"Role"} isStar={false}>
                      <InputWithEdit
                        inputBoxStyle={STYLES.InputStyle}
                        withHeader={false}
                        usedFor="profileCreation"
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        label=""
                        name="companyRole"
                        value={userInfo.companyRole}
                        placeholder="Add your role in the company"
                        onChange={(e) => {
                          handleOnChange(e);
                        }}
                      ></InputWithEdit>
                    </FormField>
                  </FormWrapper>
                </Wrapper>
              </ContentInputs>
              <ButtonWrapper>
                <ProfileCreationButtons
                  dataAttribute={{
                    [HEAP_DATA_TRACKING_ID]:
                      HEAP_DATA_ATTRIBUTES.USER_INFO.SAVE_AND_CONTINUE,
                  }}
                  isloading={isDataSaving}
                  buttonText={"SAVE CHANGES"}
                  pagination={true}
                  pageNumber={1}
                  usedAt={"userInfo"}
                  state={!isPageEdited}
                  handleStateChange={(value) => {
                    if (value === "cancel") {
                      setUserInfo(userInfoData);
                    } else {
                      isFirstError = false;
                      handleClick(value);
                    }
                  }}
                  style={{
                    minWidth: "210px",
                    height: "40px",
                    borderRadius: "4px",
                  }}
                ></ProfileCreationButtons>
              </ButtonWrapper>
            </FormikForm>
          )}
        </Formik>
      </UserInfo.Provider>
    </>
  );
};

export default UserInformation;
