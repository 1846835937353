import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "../../components/Button";
import CollapseComponent from "../../components/Collapse";
import {
  showEditReducer,
  toggleIsPageEdited,
} from "../../reducers/profileCreation";
import {
  referenceInfoCreate,
  referenceInfoGet,
  referenceInfoPost,
} from "../../reducers/referenceInfo";
import { referenceLocalizationData } from "../../ui-constants/localizationData";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "../../components/ProfileCreationButtons";
import { UserInfo } from "../../ContextApi/ContextApi";
import ReferencesContainer from "./ReferencesContainer";
import { LogoImage } from "components/Image";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import {
  HEAP_DATA_ATTRIBUTES,
  HEAP_DATA_TRACKING_ID,
} from "constants/heapAnalytics";
import { Box } from "venwiz-ui";
import { useMediaQuery } from "hooks/useMediaQuery.hook";
import { DEVICE } from "ui-constants/mediaQueries.constants";

const ContentInputs = styled.div`
  height: calc(100vh - 291px);
  width: 100%;
  overflow-y: scroll;
`;
const ReferenceInfoContainer = styled.div`
  display: ${(props) =>
    `${props.theme.card.types["peopleInfoContainer"].display}`};
  flex-direction: ${(props) =>
    `${props.theme.card.types["peopleInfoContainer"].flexDirection}`};
  margin-top: ${(props) =>
    `${props.theme.card.types["peopleInfoContainer"].marginTop}`};
`;

const ContentSubHeading = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #011759;
  height: 21px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 19px 0px 20px 0px;
  position: fixed;
  bottom: 0;
  @media screen and (max-width: 640px) {
    z-index: 10;
    border-top: none;
    padding: 0px;
  }
`;

const HeaderDiv = styled.div`
  margin-bottom: 20px;
`;

const BtnMargin = styled.div`
  margin-left: 30px;
`;

const ReferenceListWrapper = styled.div`
  width: 100%;
  display: flex;
  color: #011759;
  background: #f4f6ff;
  padding: 20px;
  border-radius: 8px;
  margin: 10px 0;
`;

const ReferenceDetailsWrapper = styled.div`
  border-radius: 8px;
  word-break: break-all;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Label = styled.div`
  font-size: 12px;
`;

const Data = styled.div`
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ImgWrapper = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`;

const Headline = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #011759;
  margin: 10px 0;
`;

const FileName = styled.a`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const References = () => {
  const gstn = getGstn();
  const history = useHistory();
  const [isDataSaving, setIsDataSaving] = useState(false);
  const isDesktopView = useMediaQuery(DEVICE.xs);

  const userInformation = [
    {
      clientCompanyName: "",
      contactName: "",
      mobileNo: "",
      clientEmail: "",
      roleInOrg: "",
      plantName: "",
      completionCertificate: {
        name: "",
        file: {
          fileName: "",
          fileUrl: "",
          fileSize: "",
        },
      },
    },
  ];
  const [reference, setReference] = useState(userInformation);
  const handleAddReference = (newReferences) => {
    setReference([...reference, ...newReferences]);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(referenceInfoGet({}));
    toggleIsPageEdited(dispatch, false);
  }, []);

  const [buttonType, setButtonType] = useState(true);

  const buttonState = useSelector((state: any) => state.profileCreation);
  const { isPageEdited } = buttonState;

  const referenceState = useSelector((state: any) => state.referenceInfo);
  const { referenceInfoGetdata } = referenceState;

  useEffect(() => {
    if (referenceInfoGetdata && referenceInfoGetdata.reference !== undefined) {
      setReference(referenceInfoGetdata.reference);
    }
  }, [referenceInfoGetdata]);

  const handleClick = (type) => {
    if (type == "save") {
      setIsDataSaving(true);

      if (referenceInfoGetdata?.referenceId) {
        dispatch(
          referenceInfoPost({
            gstn,
            referenceId: referenceInfoGetdata?.referenceId,
            reference: [...reference],
          })
        ).then(
          (res) => {
            setIsDataSaving(false);
            notificationFunc("success");
            dispatch(referenceInfoGet({}));
            toggleIsPageEdited(dispatch, false);
          },
          (err) => {
            openNotification(err?.message);
            setIsDataSaving(false);
          }
        );
      } else {
        dispatch(
          referenceInfoCreate({
            gstn,
            reference: [...reference],
          })
        ).then(
          (res) => {
            dispatch(referenceInfoGet({}));
            toggleIsPageEdited(dispatch, true);
          },
          (err) => {
            openNotification(err?.message);
          }
        );
      }
    }
    setButtonType(!buttonType);
  };

  const setUserData = () => {
    if (Object.keys(referenceInfoGetdata)?.length)
      setReference(referenceInfoGetdata.reference);
  };

  const handleOnDelete = (index) => {
    let editCurrentData = [...reference];
    editCurrentData.splice(index, 1);
    setReference(editCurrentData);
  };

  return (
    <>
      <UserInfo.Provider value={[reference, setReference]}>
        <ContentInputs>
          <div>
            <ReferenceInfoContainer>
              <ReferencesContainer
                handleAddReference={(newReference) =>
                  handleAddReference(newReference)
                }
              ></ReferencesContainer>
              <Box padding="0 16px">
                <Headline>List of References</Headline>
                {reference.map((item, index) => {
                  return (
                    <ReferenceListWrapper>
                      <ReferenceDetailsWrapper>
                        <Label>Company Name</Label>
                        <Data>
                          {item.clientCompanyName.length
                            ? item.clientCompanyName
                            : "-"}
                        </Data>
                      </ReferenceDetailsWrapper>
                      <ReferenceDetailsWrapper>
                        <Label>POC Name</Label>
                        <Data>
                          {item.contactName.length ? item.contactName : "-"}
                        </Data>
                      </ReferenceDetailsWrapper>
                      <ReferenceDetailsWrapper>
                        <Label>Email</Label>
                        <Data>
                          {item.clientEmail.length ? item.clientEmail : "-"}
                        </Data>
                      </ReferenceDetailsWrapper>
                      {item.completionCertificate?.file?.fileName && (
                        <ReferenceDetailsWrapper
                          className={
                            item.completionCertificate?.file?.fileName
                              ? "link"
                              : ""
                          }
                          style={
                            item.completionCertificate?.file?.fileName
                              ? {
                                  background: "#FFFFFF",
                                  // width: "100%",
                                  padding: "0 20px",
                                  height: "40px",
                                }
                              : { width: "100%" }
                          }
                        >
                          <Data
                            style={
                              item.completionCertificate?.file?.fileName
                                ? { display: "flex" }
                                : {}
                            }
                          >
                            {item.completionCertificate?.file?.fileName && (
                              <img
                                src="/images/note.svg"
                                style={{
                                  marginRight: isDesktopView ? "10px" : "0",
                                }}
                              />
                            )}
                            {isDesktopView && (
                              <FileName
                                href={item.completionCertificate?.file?.fileUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.completionCertificate?.name}
                              </FileName>
                            )}
                          </Data>
                        </ReferenceDetailsWrapper>
                      )}

                      <ReferenceDetailsWrapper
                        style={{ width: "40px", alignItems: "center" }}
                      >
                        <ImgWrapper
                          onClick={() => handleOnDelete(index)}
                          src="/images/trash.svg"
                        />
                      </ReferenceDetailsWrapper>
                    </ReferenceListWrapper>
                  );
                })}
              </Box>
            </ReferenceInfoContainer>
          </div>
        </ContentInputs>
        <ButtonWrapper>
          <ProfileCreationButtons
            dataAttribute={{
              [HEAP_DATA_TRACKING_ID]:
                HEAP_DATA_ATTRIBUTES.REFERENCES.SAVE_AND_CONTINUE,
            }}
            isloading={isDataSaving}
            usedAt={"references"}
            pagination={true}
            pageNumber={7}
            buttonText={"SAVE CHANGES"}
            state={!isPageEdited}
            handleStateChange={(value) => {
              handleClick(value);
            }}
            style={{
              minWidth: "210px",
              height: "40px",
              borderRadius: "4px",
            }}
          ></ProfileCreationButtons>
        </ButtonWrapper>
      </UserInfo.Provider>
    </>
  );
};

export default References;
function openNotification(message: any) {
  throw new Error("Function not implemented.");
}
