const app = {
  STORAGE_KEYS: {
    pricingClick: "pricingClick",
    userData: "userData",
    userOnboarded: "userOnboarded",
    isRememberEnabled: "isRememberEnabled",
    navigationNonLoggedIn: "navigationNonLoggedIn",
    applicationFlags: "applicationFlags",
    guestVisitedLinks: "guestVisitedLinks",
    openJobsTrackingLinks: "openJobsTrackingLinks",
    tempUserData: "tempUserData",
    isOtpPageRefresh: "isOtpPageRefresh",
    jobRequestInterestResponse: "jobRequestInterestResponse",
    nonVerifiedData: "nonVerifiedData",
    inviteId: "inviteId",
    mandatoryFieldPopup: "mandatoryFieldPopup",
    jobId: "jobId",
    selectedJobRequestTab: "selectedJobRequestTab",
    showMonetizationModal: "showMonetizationModal",
    remindAcceptMonetization: "remindAcceptMonetization",
    capturedJobIntViaEmail: "capturedJobIntViaEmail",
    jobViewed: "jobViewed",
    /** used to store video-popup's visibility state in UI */
    showVideoModalFromPopUp: "showVideoModalFromPopUp",
    /** used to store previous route and handle back button scenario */
    locationNameToUserInfoRoute: "locationNameToUserInfoRoute",
    /** used to hide open-jobs-summary */
    wasPreviousRouteOpenJobDetails: "wasPreviousRouteOpenJobDetails",
    /** previously selected-open-jobs-pagination-number */
    openJobsPageNumber: "openJobsPageNumber",
    /** used in open-jobs-details page,
     * as state transfer key in react-router */
    isFromOpenJobsDetailsPage: "isFromOpenJobsDetailsPage",
    /** previously selected-open-jobs-filter-options */
    prevSelectedOpenJobsFilterOptions: "prevSelectedOpenJobsFilterOptions",
    /** previous open-job search Keyword */
    prevOpenJobSearchKeyword: "prevOpenJobSearchKeyword",
    /** used to scroll to service-offerings in capabilities */
    scrollToServiceOffering: "scrollToServiceOffering",
    /** used to store existing user's  
     * previous route when the user has not logged in */
    prevGuestRouteOfExistingUser: "prevGuestRouteOfExistingUser",
  },
  OTP: {
    inputCount: 6,
  },
  authRouteData: {
    register: {
      name: "Register",
      link: "/registration",
      text: "Dont have an account?",
    },
    login: {
      name: "Login",
      link: "/login",
      text: "Have an account?",
    },
  },
  PUBLIC_ROUTES: [
    "/",
    "/registration",
    "/login",
    "/forgotpassword",
    "/job",
    "/new-onboarding-link/",
    "/goto-vendors",
    "/vendor-agreement",
    "/open-jobs",
  ],
  USER_RESPONSE_SOURCE: {
    VENDOR_WEBAPP: "vendor_webapp",
    VENDOR_EMAIL: "vendor_email",
  },
  INITIAL_PAGE_TO_BE_VIEWED: {
    dashboard: "/dashboard",
    myProfile: "/my-profile",
  },
  EXISTING_USER_SEARCH_PARAMS: "?user=existing",
};

export default app;
