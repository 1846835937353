import styled from "styled-components";
import { Form } from "formik";

export const FormikForm = styled(Form)`
  padding: 20px 0;
`;

export const ContentInputs = styled.div`
  height: calc(100vh - 391px);
  width: 100%;
  padding-bottom: 30px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormWrapper = styled.div`
  width: 840px;
  @media only screen and (max-width: 640px) {
    width: 100vw;
    padding: 0px 16px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 10px 0px 20px 0px;
  position: fixed;
  bottom: 0;
  @media screen and (max-width: 640px) {
    z-index: 10;
    border-top: none;
    padding: 0px;
  }
`;
export const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
`;

//style object for the button
export const STYLES = {
  InputStyle: {
    height: "40px",
  },
};
