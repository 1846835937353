import styled from "styled-components";

export const TextAreaForDummyForm = styled.textarea`
  width: 50%;
  resize: none;
  outline: none;
  box-shadow: none;
  border: 2px solid #f5f9fd;
  border-radius: 4px;
  background: #f5f9fd;
  font-size: 12px;
  @media screen and (max-width: 480px) {
    width: 100%;
    margin-top: 20px;
  }
`;