export const Types = {
  // common Types
  ONBOARDING_OPERATION_REQUESTED: "onboarding/ONBOARDING_OPERATION_REQUESTED",
  ONBOARDING_OPERATION_FAILURE: "onboarding/ONBOARDING_OPERATION_FAILURE",

  // unique Types
  GET_ONBOARDING_SUCCESS: "onboarding/GET_ONBOARDING_SUCCESS",
  POST_ONBOARDING_SUCCESS: "onboarding/POST_ONBOARDING_SUCCESS",
  GET_EQUIPMENT_FAMILY_LIST_SUCCESS: "onboarding/GET_EQUIPMENT_FAMILY_LIST_SUCCESS",
  GET_SERVICE_GROUP_LIST_SUCCESS: "onboarding/GET_SERVICE_GROUP_LIST_SUCCESS",

  //secondary user gstn cert upload & verify
  VERIFY_SUB_USER_GST_CERT_SUCCESS: "onboarding/VERIFY_SUB_USER_GST_CERT_SUCCESS",

  //verify onboarding link
  VERIFY_ONBOARDING_LINK_REQUESTED: "onboarding/VERIFY_ONBOARDING_LINK_REQUESTED",
  VERIFY_ONBOARDING_LINK_SUCCESS: "onboarding/VERIFY_ONBOARDING_LINK_SUCCESS",
  VERIFY_ONBOARDING_LINK_FAILURE: "onboarding/VERIFY_ONBOARDING_LINK_FAILURE",
} as const;
