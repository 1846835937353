import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import InputBoxForEdit from "./inputBox";
type usedFor =
  | "login"
  | "registration"
  | "profileCreation"
  | "profileCreationHighlitedlabel"
  | "profileCreationLarge"
  | undefined;
type Sizes =
  | "small"
  | "middle"
  | "large"
  | "extraLarge"
  | "semiMiddle"
  | "medium"
  | "profileCreationSmall"
  | "profileCreationMedium"
  | "profileCreationLarge"
  |"registrationPhoneView"
  |"registrationNew"
  | undefined;

export interface InputWithHeaderProps {
  usedFor?: usedFor;
  inputBoxSize?: Sizes;
  children?: string;
  inputType?: string;
  importance: string;
  label?: string;
  name: string;
  error?: any;
  touch?: any;
  value?: any;
  fields?: any;
  refValue?: boolean;
  placeholder?: string;
  disable?: boolean;
  secondary?: boolean;
  onHandleChange?: (e: any) => void;
  buttonDisable?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  buttonType?: boolean;
  hidden?: boolean;
  withHeader?: boolean;
  inputFieldError?: boolean;
  type?: number | string;
  maxChar?: any;
  minChar?: any;
  isFieldVerified?: boolean;
  inputBoxStyle?: object;
  inputDataAttribute?: object;
}

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: auto;
  color: ${(props) => props.theme.colors.darkBlue};
  margin-right: 10px;
  position: relative;
  @media only screen and (max-width: 640px) {
  margin-right: 0px;
  width:100%;
  }
`;
const HeaderName = styled.div`
  width: auto;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
`;

const InputWithHeaderForEdit = ({
  onHandleChange,
  minChar,
  fields,
  hidden = true,
  onChange,
  type,
  buttonType,
  buttonDisable,
  value,
  name,
  label,
  inputFieldError,
  disable,
  error,
  refValue,
  secondary,
  touch,
  ...props
}: InputWithHeaderProps) => {
  const authState = useSelector((state: any) => state.auth);

  return (
    <BoxContainer
      style={{ height: `${props.usedFor}` === "login" ? "auto" : "auto" }}
    >
      {props.withHeader && (
        <HeaderName
          style={{
            height: `${props.usedFor}` === "login" ? "21px" : "18px",
            marginBottom: `${props.usedFor}` === "login" ? "15px" : "10px",
            opacity: `${props.usedFor === "profileCreation" ? "0.7" : "1"}`,
          }}
        >
          {props?.children}
        </HeaderName>
      )}
      {hidden && (
        <InputBoxForEdit
          inputFieldError={inputFieldError}
          sizes={props.inputBoxSize}
          inputTextType={props.inputType}
          importanceType={props.importance}
          child={props?.children}
          // fields={fields}
          // otherProps={props}
          type={type}
          // setRef={refValue}
          disabled={disable}
          placeholder={props.placeholder}
          secondInput={secondary}
          label={label}
          name={name}
          value={value}
          buttonDisable={buttonDisable}
          buttonType={buttonType}
          maxChar={props.maxChar}
          // onChange = {onChange}
          onHandleChange={(e: any) => {
            onHandleChange(e);
          }}
          minChar={minChar}
          style={props?.inputBoxStyle}
          {...props?.inputDataAttribute}
        />
      )}
    </BoxContainer>
  );
};

export default InputWithHeaderForEdit;
