import React from "react";
import { createGlobalStyle } from "styled-components";
import { globalStyles } from "Theme";
import routes from "./routes/Routes.jsx";
import "./scss/global.css";
import { UserContext } from "../src/ContextApi/ContextApi";
import SessionStorageService from "services/SessionStorageService.js";
import ReactGA from 'react-ga4';

export const GlobalStyle = createGlobalStyle`
  html {
    font-size:  ${props => props.theme.baseFontSize};
  }
  body {
    font-family:  ${props => props.theme.fontFamily};
    background-color: ${props => props.theme.bodyBG};
    color: ${props => props.theme.baseFontColor};
    position:relative;
    overflow-x:hidden;
    overflow-y:auto;
    
    & .ant-popover-inner {
      border-radius: 6px;
  }


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  }
  ::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  ::-webkit-scrollbar-thumb {
    width: 8px;
    height: 50px;
    background: #d5d9ec;
    border-radius: 10px; /* color of the scroll thumb */
    /* roundness of the scroll thumb */
    /* creates padding around scroll thumb */
  }

  /* npm dropdown package cancel button removal*/


`;
const userInfo = {
  firstName: "",
  lastName: "",
  email: "",
  mobileNo: "",
};
const url = window.location.href;
//if prod url
if (url.match("vendor")) {
  const script = document.createElement("script");
  let userId;
  if (sessionStorage?.userData) {
    const sessionStorageUserData = JSON?.parse(sessionStorage?.userData);
    userId = sessionStorageUserData?.user?.userId;
  }

  //GA4
  script.src = "https://www.googletagmanager.com/gtag/js?id=G-HELW83SKK4";
  script.async = true;
  document.body.appendChild(script);
  if (userId) {
    window.ga("set", "userId", userId);
  }
}

const App = () => {
  ReactGA.initialize("G-378VXLM2YZ"); //intialized the GA analytics here

  return (
    <div>
      <UserContext.Provider value={userInfo}>
        <GlobalStyle theme={globalStyles} />
        {routes}
      </UserContext.Provider>
    </div>
  );
};

export default App;
