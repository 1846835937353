import LocalStorageService from "services/LocalStorageService"
import { AppConfig } from "ui-constants"

type KeyNameTypes = 
  "isCapabilitiesCompleted" |
  "isCertificatesCompleted" |
  "isCompanyInfoCompleted" |
  "isExperiencesCompleted" |
  "isComplianceCompleted" |
  "isProfileCompleted"


export const updateProfileFlagToTrue  = (keyName: KeyNameTypes) => LocalStorageService.set(
  AppConfig.STORAGE_KEYS.userData, 
  { ...LocalStorageService.get(AppConfig.STORAGE_KEYS.userData), 
    ProfileFlag: {
      ...LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)?.ProfileFlag,
      [keyName]: true
    }
  }
)