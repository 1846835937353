
import React from 'react'
import { RiCloseFill } from 'react-icons/ri';
import { Box, Button, Flex, Modal, Typography } from 'venwiz-ui'
import { parametersConstants } from './constants';
import { TextAreaForDummyForm } from './addReviewDummyForm.styles';
import Rating from 'components/Rating/Rating';
import { IParametersConstants } from './interfaces';
import { IoArrowBack } from 'react-icons/io5';

interface IProps {
  handleCloseModal: () => void
}

export const DummyAddReviewForm = (props: IProps) => {
  const onClose = () => props?.handleCloseModal();

  return (
    <Modal
      backgroundStyles={{
        bg: { default: "none", xxs: "none", sm: "rgba(0, 0, 0, 0.5)" },
        top: { default: "60px", xxs: "60px", sm: "0px" },
        zIndex: 99999,
        padding: { default: "0px", xxs: "0px", sm: "0 12.8px" },
      }}
      width={{ default: "100%", xxs: "100%", sm: "60%" }}
      maxW={{ default: "100%", xxs: "100%", sm: "548px" }}
      height={{ default: "100%", xxs: "100%", sm: "max-content" }}
      show={true}
      handleClose={onClose}
      closeIcon={
        <Box 
          display={{ default: "none", xxs: "none", sm: "block" }} 
          fontSize={"20px"}
          cursor={"pointer"}
          color={"#011759"}
        >
          <RiCloseFill />
        </Box>
      }
      modalHeader={
        <Flex
          width={"100%"}
          paddingRight={"22px"}
          paddingBottom={"12px"}
          gap={"8px"}
          flexDirection={"column"}
          borderBottom={"2px solid #F4F9FF"}
        >
          <Flex
            color={"#011759"}
            justifyContent={"center"}
            fontSize={"20px"}
            fontWeight={600}
            position={"relative"}
          >
            <Box
              left={"6px"}
              fontSize={"20px"}
              onClick={onClose}
              cursor={"pointer"}
              position={"absolute"}
              display={{ default: "block !important", xxs: "block !important", sm: "none !important" }}
            >
              <IoArrowBack />
            </Box>
            <Flex
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              gap={"4px"}
            >
              <Typography>
                Vendor Review
              </Typography>
              <Typography
                fontWeight={400}
                fontStyle={"italic"}
                fontSize={{ default: "10px", sm: "12px" }}
              >
                This is a preview of the Review form that is sent to the client.
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      }
      modalFooter={(
        <>
          <Flex justifyContent={"center"} alignItems={"center"} padding={"18.75px"}>
            <Button
              disabled
              height={"40px"}
              variant={"primary"}
              colorSchema={"green"}
              style={{ cursor: "no-drop" }}
              width={{ default: "100%", xxs: "100%", sm: "248px" }}
            >
              {"Submit Review"}
            </Button>
          </Flex>
        </> 
      )}
      modalBody={
        <>
          <Box
            padding={"22.5px 32.5px"}
            position={"relative"}
            overflow={"auto"}
            maxHeight={"65vh"}
          >
            <Box>
              <Box
                borderBottom={"2px solid #F4F9FF"}
                paddingBottom={"22.5px"}
                marginBottom={"22.5px"}
              >
                <Box
                  color={"#011759"}
                  fontSize={"14px"}
                  fontWeight={600}
                  marginBottom={"10px"}
                >
                  Rate the vendor based on past performance*
                </Box>
                <Box>
                  <Box
                    justifyContent={"space-between"}
                    marginBottom={"10px"}
                    display={{ default: "block", xxs: "block", sm: "flex" }}
                  >
                    <Box>
                      <Typography
                        color={"#4D5D8B"}
                        fontSize={"14px"} /* 0.875rem converted to 14px */
                        fontWeight={500}
                        marginBottom={"5px"}
                      >
                        {"ABC Company Name"}
                      </Typography>
                      <Rating
                        value={Number("5")}
                        color={"#FFCB45"}
                        disabledStatus
                      ></Rating>
                    </Box>
                    <TextAreaForDummyForm 
                      disabled
                      style={{ color: "#000" }}
                      value={"description added by client"} 
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box>
              <Box
                borderBottom={"1px solid #F4F9FF"}
                paddingBottom={"14px"}
                marginBottom={"14px"}
              >
                <Box
                  color={"#011759"}
                  fontSize={"14px"}
                  fontWeight={600}
                  marginBottom={"10px"}
                >
                  Share vendor performance on key parameters
                </Box>
                {/* ParameterItemsContainer */}
                <Flex 
                  flexDirection={"column"}
                  height={"auto"}
                  overflow={"hidden"}
                >
                  {parametersConstants?.map(
                    (item: IParametersConstants, index: number) => {
                      const keyName: string = item?.keyName;
                      return (
                        <Box>
                          <Box
                            justifyContent={"space-between"}
                            marginBottom={"10px"}
                            display={{ default: "block", xxs: "block", sm: "flex" }}
                          >
                            <Box>
                              <Typography
                                color={"#4D5D8B"}
                                fontSize={"14px"}
                                fontWeight={500}
                                marginBottom={"8px"}
                              >
                                {index + 1}. {item?.bttnText}
                              </Typography>
                              <Rating
                                value={Number("5")}
                                color={"#FFCB45"}
                                disabledStatus
                              ></Rating>
                            </Box>
                            <TextAreaForDummyForm
                              disabled
                              style={{ color: "#000" }}
                              value={"description added by client"}
                            />
                          </Box>
                        </Box>
                      );
                    }
                  )}
                </Flex>            
              </Box>
            </Box>
          </Box>
        </>
      }
    />
  )
}
