import { emailRegex } from "Authentication/Register/RegisterContainer/RegistrationRules";
import { domainNames as restrictedDomainNamesList } from "constants/domainNames";

export const REQUEST_FOR_REVIEW_FORM_STATUS = {
  REVIEW_REQUESTED: "reviewRequested",
  REVIEW_ACCEPTED: "reviewAccepted",
} as const;


export const isRequestForReviewEmailValid = email => {
  const isValidDomainName = (enteredEmail: string) => {
    if (!enteredEmail) return true;
    const email = enteredEmail?.toLowerCase();
    const emailAddress = email?.includes("@") ? email?.split("@") : ["", "."];
    const domainName = emailAddress[1]?.split(".");
    /** is domain not in restrictedDomainNamesList */
    const isDomainNameValid = !restrictedDomainNamesList?.includes(domainName[0]);
    return isDomainNameValid;
  };

  // return isValidDomainName(email) && emailRegex.test(email);
  return emailRegex.test(email);
};