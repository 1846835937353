import React from "react";
import styled from "styled-components";
import { TextboxStyleType } from "../TextBox/TextBox";

export interface LabelProps {
  text: string;
  headerName?: string;
  htmlFor?: string;
  styleType?: TextboxStyleType;
  elementTypes?: string;
  fontVariant?: "light" | "regular" | "medium" | "bold";
  cursorType?: "pointer" | "default" | "not-allowed";
  multiLine?: boolean;
  error?: boolean;
  usedAt?:string;
  fontColor?: string;
  whiteSpace?: "nowrap" | "normal";
}

export const LabelBase = styled.label<Partial<LabelProps>>`
  white-space: ${props => props?.whiteSpace || "normal"};
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${props => props.multiLine && "break-spaces"};
  color: ${props =>
    props.error
      ? "#BE000B"
      : `${props.theme.label.sizes[`${props.htmlFor}`].color}`};
  font-size: ${props =>
    `${props.theme.fontSizes[`${props.htmlFor}`].fontSize}`};
  line-height: ${props =>
    `${props.theme.fontSizes[`${props.htmlFor}`].lineHeight}`};
  font-style: ${props =>
    `${props.theme.fontSizes[`${props.htmlFor}`].fontStyle}`};
  font-weight: ${props =>
    `${props.theme.fontWeights[props.fontVariant || "regular"]}`};
  cursor: ${props => `${props.cursorType || "default"}`};
  margin-right: ${props =>
    `${props.theme.label.sizes[`${props.htmlFor}`].marginRight || "0px"}`};
  opacity: ${props => (props.htmlFor === "profileCreation" ? "0.7" : "1")};
  color: ${props => props.fontColor};
  @media only screen and (max-width: 640px) {
  font-size:10px;
  }
`;

const Label = (props: LabelProps) => {
  return <LabelBase {...props}>{props.text}</LabelBase>;
};

export default Label;
