import React from 'react';
import styled from 'styled-components';

interface CardWaterDropHeader {
  cardType: 'viewCard' | 'referalCard' | 'infoCard' | 'cardWithDropletHeader'| 'referenceCard',
  headerName?: 'vendor' | 'client' | 'searchViews',
  children?: string
}

const Card = styled.div`
cursor:${props => `${props.theme.card.types.cursor}`};
width:auto;
position:absolute;
top:-12px;
height: ${props => `${props.theme.card.types['dropletHeaderContainer'].height}`};
background-color: ${props => `${props.children === 'vendor' ? props.theme.card.types['dropletHeaderContainer'].secondaryColor : props.theme.card.types['dropletHeaderContainer'].primaryColor}`};
border-radius: 12px 12px 12px 0px;
padding-right:${props => `${props.theme.card.types['dropletHeaderContainer'].padding}`};
padding-left:${props => `${props.theme.card.types['dropletHeaderContainer'].padding}`};
padding-top:5px;
padding-bottom:5px;
display:flex;
justify-content: center;
font-weight:600;
font-size:10px;
align-items: center;
line-height: 15px;
letter-spacing: 0.01em;
color: #FFFFFF;
text-transform:uppercase;
`;

const CardWaterDropHeader = ({ ...rest }: CardWaterDropHeader) => {
  // const {headerName}={...rest}
  return (
    <Card {...rest}>
      {

      }
    </Card>
  );
};

export default CardWaterDropHeader;
