import React, { useState } from "react";
import { Collapse, Select } from "antd";
import styled from "styled-components";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
const { Panel } = Collapse;

interface Props{
  isAddManufacturingTrue:boolean,
  children:any
}
const Header = styled.div`
  color: ${props => `${props.theme.colors.blue}`};
  font-weight: 600;
  font-size: 14px;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`;

const Div = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: ${props => !props.isOpen  && "center"};
  width: 100%;
`;

export const CollapseDiv = styled(Collapse)<any>`
.ant-collapse{
  background-color: #F4F6FF ;
  width: 860px !important;
}
  .ant-collapse-header{
    height: 40px;
    text-align: center;
  }

  .ant-collapse-content-box {
    padding: 05px 0px 0px 0px;
  }
  @media screen and (max-width: 640px) {
    .ant-collapse{
    }
    .ant-collapse-content {
      background-color:transparent;
    }
    .ant-collapse-header{
      display:${props=>props?.$isOpen && "none"};
      background: #FFFFFF;
      border: 1px solid #D4E5FE;
      border-radius: 8px !important;
      font-size: 12px !important;
    }
  }

  @media screen and (max-width: 1270px) {
    .ant-collapse{
      width: 790px!important;
    }
  }
  @media screen and (max-width: 1220px) {
    .ant-collapse{
      width: 710px!important;
    }
  }
  @media screen and (max-width: 1170px) {
    .ant-collapse{
      width: 640px!important;
    }
  }
`;
const ManufacturingCapabilities = (props:Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <CollapseDiv
        style={{ backgroundColor: "#F4F6FF", width: "550px" }}
        onChange={() => {
          setIsOpen(!isOpen);
        }}
        $isOpen={isOpen}
        expandIcon={() =>
          isOpen?  <img  src={`/images/ProfileCreation/minus.svg`} />:
          (props.isAddManufacturingTrue && <img  src={`/images/ProfileCreation/plus.svg`} />)
        }
      >
        <Panel
          forceRender={true}
          header={
            <Div 
              isOpen={isOpen ? isOpen : (props.isAddManufacturingTrue ? true : false)} 
              data-tracking-id={HEAP_DATA_ATTRIBUTES.CAPABILITIES.EXPAND.MANUFACTURE}
            >
              <Header>
                {isOpen
                  ? "- Manufacturing Capabilities"
                  : (props.isAddManufacturingTrue
                      ? "View Manufacturing Capabilities" : "+ Add Manufacturing Capabilities")}
              </Header>
            </Div>
          }
          key={1}
          showArrow={false}
        >
          {props.children}
        </Panel>
      </CollapseDiv>
    </>
  );
};

export default ManufacturingCapabilities;
