import React, { useContext, useEffect, useState } from "react";
import { Form } from "formik";
import styled from "styled-components";
import openNotification from "constants/antdNotification";
import BasicDropdown from "components/Dropdown/BasicDropdown/BasicDropdown";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";
import InputWithHeaderForEdit from "components/Input/InputBoxWithEdit/inputHeader";
import TextAreaWithHeader from "components/TextArea/TextAreaWithHeader/TextArea";
import { UserInfo } from "ContextApi/ContextApi";
import { DropdownData } from "constants/mockData";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import { Button } from "components/Button";
import { CardTag } from "components/Card";
import { fileUploadPost } from "reducers/fileUpload";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "components/Label";
import { LogoImage } from "components/Image";
import { Upload, message } from "antd";
import { InputWithHeader } from "components/Input";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";

export const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
`;

export const FormikForm = styled(Form)`
  overflow: auto;
`;

interface ExperienceProps {
  buttonType?: boolean;
  data?: any;
  validationState?: boolean;
  formik?: any;
  onSubmit?: () => void;
  handleAlertMessage?: (e: any) => void;
}
const WarningMsg = styled.div`
  display: flex;
  position: absolute;
  top: 95px;
`;
const Container = styled.div`
  width: 100%;
  height: auto;
  overflow-y:hidden;
`;
const Div = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
`;
const ImageDiv = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DivRow = styled.div`
  display: flex;
`;
const FlexWrapContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  margin-bottom: 40px;
`;

const HeaderName = styled.div`
  width: auto;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
  opacity: 0.7;
  color: #011759;
`;
const DropdownWrapper = styled.div`
  margin-right: 10px;
`;

const InputWrap = styled.input`
  position: absolute;
  right: 0px;
  right: -17px;
  opacity: 0;
  z-index: 100;
  width: 160px;
  height: 60px;

  top: 27px;
  bottom: 0px;
  cursor: pointer;
  width: 60px;
`;
const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #011759;

  opacity: 0.7;
  margin-bottom: 10px;
`;
const LinkTo = styled.a``;
const ExperienceConatiner = ({
  data,
  handleAlertMessage,
  validationState,
  buttonType,
}: ExperienceProps) => {
  const dispatch = useDispatch();
  const [tempDoc, setTempDoc] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [fileName, setFileName] = useState("");
  const [experienceInfoState, setExperienceInfoState] = useContext(UserInfo);
  const experienceInfoSelector = useSelector(
    (state: any) => state.experienceInfo
  );
  const { experienceInfoData } = experienceInfoSelector;
  const fileUploadState = useSelector((state: any) => state.fileUpload);
  const { fileUploadData } = fileUploadState;

  useEffect(() => {
    if (
      data.item.city !== "" &&
      data.item.clientCompanyName !== "" &&
      (data.item.clientIndustry !== "" || checkForMobileScreen()) &&
      data.item.projEndDate !== "" &&
      data.item.projStartDate !== "" &&
      data.item.projStartDate !== "DD-MM-YYYY" &&
      data.item.projEndDate !== "DD-MM-YYYY" &&
      data.item.description.length
    ) {
      handleAlertMessage(true);
    } else {
      handleAlertMessage(false);
    }
  }, [experienceInfoState]);

  const handleOnChange = (e, type = "others") => {
    let editCurrentData = [...experienceInfoState.experience];

    if (type === "clientIndustry") {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        ["clientIndustry"]: e,
        flag: true,
      };
    } else if (type === "documents") {
      if (documents[0] === undefined) {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          ["document"]: {},
          flag: true,
        };
      } else {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          ["document"]: documents[0],
          flag: true,
        };
      }
    } else {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        [e.target.name]: e.target.value,
        flag: true,
      };
    }
    setExperienceInfoState({
      experienceId: experienceInfoState.experienceId,
      experience: editCurrentData,
    });
  };

  // useEffect(() => {
  //   if (fileUploadData) {
  //     setDocuments([
  //       ...documents,
  //       { name: fileName, documentFile: { ...fileUploadData } },
  //     ]);
  //     setTempDoc([]);
  //     setFileName("");
  //   }
  // }, [fileUploadData]);

  const getFile = (e: any) => {
    const file = e.target.files[0];
    if (file.size / 1024 / 1024 > 20) {
      message.error(
        `File size exceeds the 20 mb limit. Please try uploading a smaller file size`
      );
    }
    if (typeof file !== "undefined" && file.size / 1024 / 1024 < 20) {
      setFileName(file?.name);
      dispatch(fileUploadPost(file))
        .then(response => {
          setDocuments([{ name: file?.name, documentFile: { ...response } }]);
          setTempDoc([]);
          setFileName("");
        })
        .catch(err => {
          openNotification("Document Upload Failed");
          setTempDoc([]);
        });
      setTempDoc([
        {
          name: file?.name,
          documentFile: { fileName: file?.name, fileSize: file?.size },
        },
      ]);
    }
  };
  const closeTag = data => {
    setDocuments(
      documents.filter(item => {
        return item.name !== data.name;
      })
    );
  };

  useEffect(() => {
    handleOnChange(null, "documents");
  }, [documents]);

  useEffect(() => {
   
    if (experienceInfoData?.experience) {
      const value = experienceInfoData?.experience[data.index]?.document;
  
      if (value?.name) {
        setDocuments([value]);
      }
    }
  }, [experienceInfoData]);
  return (
    <>
      <Container>
        <Div>
          <InputWithHeaderForEdit
            withHeader={true}
            maxChar={50}
            buttonType={buttonType}
            usedFor="profileCreation"
            inputBoxSize="large"
            inputType="text"
            importance="important"
            label="Project Name"
            name="projectName"
            value={data.item.projectName}
            placeholder="Enter Project Name"
            onHandleChange={e => {
              handleOnChange(e);
            }}
            buttonDisable={!buttonType}
          >
            Project Name
          </InputWithHeaderForEdit>
        </Div>
        <Div>
          <InputWithHeaderForEdit
            withHeader
            maxChar={50}
            usedFor="profileCreation"
            inputBoxSize="large"
            inputType="text"
            importance="important"
            name="clientCompanyName"
            label="Client Company Name"
            value={data.item.clientCompanyName}
            onHandleChange={e => {
              handleOnChange(e);
            }}
            placeholder={"Client Company Name"}
            buttonDisable={!buttonType}
          >
            Client Company Name*
          </InputWithHeaderForEdit>
          {data.item.clientCompanyName === "" && validationState && (
            <ErrorMessageComponent name={"Please enter your client name"} />
          )}
        </Div>
        <Div>
          <InputWithHeaderForEdit
            maxChar={150}
            withHeader
            usedFor="profileCreation"
            inputBoxSize="large"
            inputType="text"
            importance="important"
            label="first Name"
            name="companyProfile"
            value={data.item.companyProfile}
            onHandleChange={e => {
              handleOnChange(e);
            }}
            buttonDisable={!buttonType}
          >
            Company Profile (Website URL or LinkedIn profile)
          </InputWithHeaderForEdit>
        </Div>
        <FlexWrapContainer>
          <Div>
            <HeaderName>Client Industry*</HeaderName>
            <DropdownWrapper>
              <BasicDropdown
                sizes="medium"
                placeholder={
                  data.item.clientIndustry !== ""
                    ? data.item.clientIndustry
                    : "Client Industry"
                }
                data={DropdownData.clientIndustry}
                onChange={(element: string) => {
                  handleOnChange(element, "clientIndustry");
                }}
                usedFor="profileCreation"
                value={data?.item?.clientIndustry || undefined}
                disabled={!buttonType}
              />
            </DropdownWrapper>

            {data.item.clientIndustry === "" && validationState && (
              <ErrorMessageComponent
                name={"Please enter the client industry"}
              />
            )}
          </Div>
          <Div>
            <InputWithHeaderForEdit
              withHeader
              usedFor="profileCreation"
              inputBoxSize="medium"
              inputType="text"
              importance="important"
              name="city"
              label="City"
              placeholder={"City"}
              value={data.item.city}
              onHandleChange={e => {
                handleOnChange(e);
              }}
              buttonDisable={!buttonType}
            >
              City*
            </InputWithHeaderForEdit>
            {data.item.city === "" && validationState && (
              <ErrorMessageComponent name={"Please enter your city"} />
            )}
          </Div>
          <Div>
            <InputWithHeaderForEdit
              withHeader
              maxChar={data.item.projEndDate}
              usedFor="profileCreation"
              inputBoxSize="medium"
              inputType="date"
              importance="important"
              name="projStartDate"
              label="Project Start Date"
              value={data.item.projStartDate !== "" && data.item.projStartDate}
              onHandleChange={e => {
                handleOnChange(e);
              }}
              buttonDisable={!buttonType}
            >
              Project Start Date*
            </InputWithHeaderForEdit>

            {(data.item.projStartDate === "" ||
              data.item.projStartDate === "DD-MM-YYYY") &&
              validationState && (
                <ErrorMessageComponent
                  name={"Please enter the date in DD-MM-YYYY"}
                />
              )}
          </Div>
          <Div>
            <InputWithHeaderForEdit
              withHeader
              minChar={data.item.projStartDate}
              usedFor="profileCreation"
              inputBoxSize="medium"
              inputType="date"
              importance="important"
              name="projEndDate"
              label="Project End Date"
              value={data.item.projEndDate !== "" && data.item.projEndDate}
              onHandleChange={e => {
                handleOnChange(e);
              }}
              buttonDisable={!buttonType}
            >
              Project Completion Date*
            </InputWithHeaderForEdit>
            {(data.item.projEndDate === "" ||
              data.item.projEndDate === "DD-MM-YYYY") &&
              validationState && (
                <ErrorMessageComponent
                  name={"Please enter the date in DD-MM-YYYY"}
                />
              )}
          </Div>
        </FlexWrapContainer>
        <DivRow>
          <Div>
            <TextAreaWithHeader
              headerLabel="Description of Project*"
              placeholder="Enter Project Description"
              size="small"
              usedFor="profileCreation"
              buttonDisable={!buttonType}
              value={data.item.description}
              name="description"
              onChange={e => {
                handleOnChange(e);
              }}
            />
            {data.item.description.length === 0 && validationState && (
              <ErrorMessageComponent name={"Please enter your project name"} />
            )}
          </Div>
          {!(tempDoc?.length || documents?.length) && (
            <div
              style={{
                width: "280px",
                position: "relative",
              }}
            >
              <Heading>Documents</Heading>
              <InputBoxForEdit
                sizes="medium"
                inputTextType="text"
                importanceType="important"
                child="Document"
                placeholder={"Add POs / Relevant Doc"}
                buttonType={true}
                buttonDisable
                onHandleChange={() => {}}
                disabled={!buttonType}
              />

              <Button
                disabled={!buttonType}
                onClick={() => {
                  // handleAddKey
                }}
                variant="white"
                size="small"
                style={{
                  borderRadius: "0px 8px 8px 0px",
                  width: "60px",
                  marginTop: "0px",
                  position: "relative",
                  left: "240px",
                  top: "-60px",
                  height: "60px",
                  fontSize: "16px",
                  border: "1px solid #D5D9EC",
                  color: "#011759",
                  background: "#215EC9",
                }}
              >
                <img src="/images/upload_white.svg" />
              </Button>

              {!!buttonType && (
                <InputWrap
                  type="file"
                  onChange={getFile}
                  accept="application/pdf,image/jpeg"
                />
              )}
              <WarningMsg>
                <ImageDiv>
                  <LogoImage
                    imageSrc="/images/ProfileCreation/info.svg"
                    altMesg="edit button"
                    imageType="icon"
                  />
                </ImageDiv>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Label
                    fontVariant="bold"
                    htmlFor="brochureSize"
                    text="Supports .pdf and .jpeg formats.
              Max. file size 20mb."
                  />
                </div>
              </WarningMsg>
            </div>
          )}
          <div>
            {!!(tempDoc?.length || documents?.length) && (
              <Heading>Documents</Heading>
            )}
            {tempDoc.map((item, index) => {
              return (
                <CardTag
                  keys={index}
                  onClose={() => {
                    closeTag(item);
                  }}
                  cardText={item.name}
                  usedFor="fileUploadTag"
                  usedAt="experience"
                  fileSize={item.documentFile.fileSize / 1000000}
                  closable={true}
                  loading
                />
              );
            })}
            {documents.map(item => {
              return (
                <CardTag
                  data={item}
                  onClose={() => {
                    closeTag(item);
                  }}
                  cardText={item.name}
                  usedAt="experience"
                  usedFor="fileUploadTag"
                  fileSize={item.documentFile.fileSize / 1000000}
                  closable={!buttonType}
                  cursorType={(!buttonType && "pointer") || "default"}
                />
              );
            })}
          </div>
        </DivRow>
      </Container>
    </>
  );
};

export default ExperienceConatiner;
