import React from 'react';
import styled from 'styled-components';
interface ProgressBarProps {
  children?: React.ReactNode,
  type?: 'profileCreationSidebar' | 'small'
}

const Card = styled.div<Partial<ProgressBarProps>>`
display: ${props => `${props.theme.card.types['progressCard'][props.type || 'default'].display}`};
flex-direction: column;
align-items: center;
padding: ${props => `${props.theme.card.types['progressCard'][props.type || 'default'].padding}`};
color: ${props => `${props.theme.colors.darkBlue}`};
cursor:${props => `${props.theme.card.types.cursor}`};
width:${props => `${props.theme.card.types['progressCard'][props.type || 'default'].width}`};
height: ${props => `${props.theme.card.types['progressCard'][props.type || 'default'].height}`};
background-color: ${props => `${props.theme.card.types['progressCard'][props.type || 'default'].backgroundColor}`};
border-radius: ${props => `${props.theme.card.types['progressCard'][props.type || 'default'].borderRadius}`};
padding-top: 20px;
box-shadow: ${props => `${props.theme.card.types['progressCard'][props.type || 'default'].boxShadow}`};
`;


const CardProgress = ({ children, type }: ProgressBarProps) => {

  return (
    <Card type={type}>
      {children}
    </Card>

  );
};

export default CardProgress;
