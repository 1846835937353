import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setResetPwdToken, verifyEmailLink } from "reducers/auth";
import { useHistory } from "react-router-dom";

const VerifyPassword = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { id } = useParams<{ id: string }>();
  useEffect(() => {
    dispatch(verifyEmailLink(id)).then(res => {
      if (res?.status === 200) {
        dispatch(setResetPwdToken(res?.resetPwdToken));
        history.push("/resetpassword")
      } else {
        history.push("/login", {
          notificationType: "error",
          notificationText: "Email verification failed"
        });
      }
    });
  }, []);

  return <>VerifyPassword</>;
};

export default VerifyPassword;
