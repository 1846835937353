export const DOCUMENT_REQUESTED = "documentInfo/DOCUMENT_REQUESTED";
export const DOCUMENT_SUCCESS = "documentInfo/DOCUMENT_SUCCESS";
export const DOCUMENT_FAILURE = "documentInfo/DOCUMENT_FAILURE";

export const DOCUMENT_POST_REQUESTED = "documentInfo/DOCUMENT_POST_REQUESTED";
export const DOCUMENT_POST_SUCCESS = "documentInfo/DOCUMENT_POST_SUCCESS";
export const DOCUMENT_POST_FAILURE = "documentInfo/DOCUMENT_POST_FAILURE";

export const DOCUMENT_CREATE_REQUESTED =
  "documentInfo/DOCUMENT_CREATE_REQUESTED";
export const DOCUMENT_CREATE_SUCCESS = "documentInfo/DOCUMENT_CREATE_SUCCESS";
export const DOCUMENT_CREATE_FAILURE = "documentInfo/DOCUMENT_CREATE_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: null,

  documentInfoGetData: null,
  documentInfoPostData: null,
  documentInfoCreateData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOCUMENT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        documentInfoGetData: action.result,
      };

    case DOCUMENT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case DOCUMENT_POST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENT_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        documentInfoPostData: action.result,
      };
    case DOCUMENT_POST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case DOCUMENT_CREATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case DOCUMENT_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        documentInfoCreateData: action.result,
      };
    case DOCUMENT_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const documentInfoGet = (data) => ({
  types: [DOCUMENT_REQUESTED, DOCUMENT_SUCCESS, DOCUMENT_FAILURE],
  promise: (client) => client.get(`document_info_get_data`, {}),
});

export const documentInfoPost = (data) => ({
  types: [
    DOCUMENT_POST_REQUESTED,
    DOCUMENT_POST_SUCCESS,
    DOCUMENT_POST_FAILURE,
  ],
  promise: (client) =>
    client.post("document_info_post_data", {
      data,
    }),
});

export const documentInfoCreate = (data) => ({
  types: [
    DOCUMENT_CREATE_REQUESTED,
    DOCUMENT_CREATE_SUCCESS,
    DOCUMENT_CREATE_FAILURE,
  ],
  promise: (client) =>
    client.post("document_info_create_data", {
      data,
    }),
});
