import styled from "styled-components";
import MultiSelect from "react-multi-select-component";
import TextArea from "antd/lib/input/TextArea";
import DropdownWithHeader from "components/Dropdown/DropdownWithHeader/DropdownWithHeader";
import { DropdownAntd } from "components/Dropdown/BasicDropdown/BasicDropdown";
import { Button as BttnDiv, Modal } from "antd";
import Button, { ButtonBase } from "components/Button/Button";
import { CollapseDiv } from "./ManufacturingCapabilities";
import { TagAntd } from "components/Card/CardTag/Card";
import { LabelBase } from "components/Label/Label";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
import { Box, Button as VZuiButton } from "venwiz-ui";
import { BoxProps } from "venwiz-ui/dist/cjs/styled-system/primitives/Box";

interface DisplayProps {
  hide?: boolean;
  onChange?: (e: any) => void;
}

export const HeaderName = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
  display: flex;
  justify-content: space-between;
  color: #011759;
`;

export const MultiSelectDropdown = styled(MultiSelect)<any>`
  width: 550px;
  border-radius: 12px;
  color: #011759;
  margin-right: 15px;
  .dropdown-content {
    position: absolute;
    bottom: 0;
  }
  .dropdown-container {
    height: 40px !important;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */
    color: #011759;
    background: ${(props) =>
      props.error ? "rgba(190, 0, 11, 0.05)" : "#ffffff"};
    border: 1px solid #d4e5fe !important;
    border-radius: 8px !important;
    border: ${(props) => props.error && "1px solid #BE000B !important"};
  }
  .gray {
    color: #011759 !important ;
  }
  @media screen and (max-width: 640px) {
    width: 92.01vw;
    margin-right: 0px;

    .clear-selected-button {
      display: none !important;
    }
    .dropdown-content {
      display: none;
    }
  }
`;

export const ContentHeader = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
  background: white;
  display: flex;
  align-items: center;
  height: 60px;
  color: ${(props) => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${(props) => `${props.theme.fontWeights.bold}`};
`;

export const CapabiltyTitles = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #215ec9;
  margin-bottom: 40px;
`;
export const DropdownWithTagDiv = styled.div`
  width: auto;
  height: auto;
  margin-right: 10px;
  @media screen and (max-width: 640px) {
    position: relative;
    margin-right: 0px;
  }
`;
export const TagContainer = styled.div<any>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  row-gap: 10px;
  @media screen and (max-width: 640px) {
    display: ${(props) => props?.$hideOnMobileScreen && "none"};
  }
`;
export const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  margin-bottom: 10px;
  letter-spacing: 0.1em;
  color: #011759;
  opacity: 0.7;
`;
export const FormWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 40px;
  margin-bottom: 60px;
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;
  }
`;

export const InputIconWrapper = styled.div`
  margin-top: 12px;
`;
export const InputCardIconWrapper = styled.div`
  margin-top: 12px;
  max-width: 550px;
`;
export const TextAreaWrapper = styled.div`
  margin-bottom: 0px;
  @media screen and (max-width: 1270px) {
    margin-bottom: 0px;
  }
`;
export const ManufactureTextAreaWrapper = styled(TextAreaWrapper)`
  margin-bottom: 10px;
`;
export const CardContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
export const DisplayToggle = styled.div<DisplayProps>`
  display: ${(props) => (`${props.hide}` ? "block" : "none")};
  width: auto;
  height: auto;
`;

export const PlantLocationWrapper = styled.div`
  max-width: 730px;
  display: flex;
  justify-content: space-between;
`;
export const ErrorMessageAlignment = styled.div`
  margin-top: -20px;
`;
export const BtnMargin = styled.div`
  margin-left: 30px;
`;

export const LocationContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 270px;
  max-width: 270px;
  height: auto;
  @media screen and (max-width: 640px) {
    .ant-select-dropdown {
      display: none;
    }
  }
`;
export const TextAreaDiv = styled.textarea<any>`
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #011759;
    opacity: 0.4;
  }
  margin-right: 15px;
  width: ${(props) => props.theme.textbox.sizes["medium"].width};
  height: ${(props) =>
    props.isCertificateAdded
      ? "60px"
      : props.theme.textbox.sizes["medium"].height};
  border-radius: ${(props) => props.theme.textbox.sizes["medium"].borderRadius};
  border: ${(props) =>
    props.error
      ? "1px solid #BE000B"
      : props.theme.textbox.sizes["medium"].border};
  color: ${(props) => props.theme.textbox.sizes["medium"].color};
  resize: ${(props) => props.theme.textbox.sizes["medium"].resize};
  padding: ${(props) => props.theme.textbox.sizes["medium"].padding};
  outline: ${(props) => props.theme.textbox.sizes["medium"].outline};
  overflow: ${(props) => props.isCertificateAdded && "hidden"};
  background: ${(props) =>
    props.error ? "rgba(190, 0, 11, 0.05)" : "#ffffff"};
  line-height: 21px;
  color: #011759;
  opacity: 0.8;
  @media screen and (max-width: 640px) {
    margin-right: 0px;

    ::-webkit-input-placeholder {
      font-weight: 400;
      font-size: 12px;
      color: #011759;
      opacity: 0.4;
    }
    width: 100%;
    padding: 12px;
    font-size: 14px;
  }
`;
export const InputFlexWrapper = styled.div`
  margin-top: 23px;
  @media screen and (max-width: 1270px) {
    margin: 5px 15px 0px 0px;
  }
`;

export const CardWrapper = styled.div`
  margin: 22.5px 0px 0px 10px;
  @media screen and (max-width: 1270px) {
    margin: 5px 0px 0px 0px;
  }
`;
export const ManufacturingCardWrapper = styled.div`
  width: 550px;
  margin-top: 8px;
  @media screen and (max-width: 640px) {
    width: 86.5vw;
    ${TagAntd} {
      padding: 6px 17px;
      max-width: 373px;
      background: #ffffff;
      font-weight: 500;
    }
    ${LabelBase} {
      font-size: 12px;
      line-height: 18px;
      text-align: justify;
      color: #011759;
      opacity: 0.7;
    }
  }
  @media screen and (max-width: 410px) {
    width: 84.5vw;
  }
`;
export const InputDivWrap = styled.div`
  width: 280px;
  height: 60px;
  position: relative;
  @media screen and (max-width: 640px) {
    width: 44.155vw;
    max-width: 190px;
  }
`;

export const InputWrap = styled.input`
  position: absolute;
  right: 0px;
  opacity: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;
export const LocationServedDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 800px;
  @media screen and (max-width: 640px) {
    width: max-content;
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  width: max-content;
`;

export const FlexColumn = styled.div<any>`
  @media screen and (max-width: 640px) {
    position: relative;
    background: ${(props) => props?.$respScreenStyle && "#F4F6FF"};
    padding: ${(props) => props?.$respScreenStyle && "12px"};
    margin-bottom: ${(props) => props?.$respScreenStyle && "20px"};
    border: ${(props) => props?.$respScreenStyle && "1px solid #D4E5FE"};
    border-radius: ${(props) => props?.$respScreenStyle && "8px"};
  }
`;
export const KeyCustomer = styled.div`
  display: flex;
  width: 850px;
  @media screen and (max-width: 1270px) {
    flex-direction: column;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 640px) {
    width: 92.09vw;
  }
`;
export const LocationServedTagContainer = styled.div`
  display: flex;
`;
export const StateServedTagContainer = styled.div<any>`
  display: flex;
  margin-top: 8px;
  width: 270px;
  flex-wrap: wrap;
  @media screen and (max-width: 640px) {
    display: ${(props) => props?.$hideOnMobileScreen && "none"};
  }
`;

export const GloballyServedTagContainer = styled.div`
  display: flex;
`;
export const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const WarningMsg = styled.div`
  display: flex;
  margin-top: 10px;
`;
export const RequiredAnnotationLabel = styled.span<any>`
  color: ${(props) => props.color};
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;
export const RequiredWrapper = styled.div`
  margin-left: 15px;
`;

export const UploadIcon = styled.img`
  height: 14px;
  width: 14px;
  margin-right: 10px;
`;

export const TextDiv = styled.div`
  font-size: 14px;
  line-height: 21px;
  text-align: justify;
  letter-spacing: 0.01em;
  color: #011759;
  @media screen and (max-width: 640px) {
    font-size: 12px;
  }
`;

export const ColumnDiv = styled.div<any>`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  row-gap: 20px;
  @media screen and (max-width: 640px) {
    row-gap: ${(props) => props?.$respScreenRowGap};
  }
`;

export const AddBttn = styled.div<any>`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #215ec9;
  margin-top: -10px;
  cursor: pointer;
  width: 76px;
  @media screen and (max-width: 640px) {
    position: absolute;
    top: ${(props) => props?.$top};
    right: ${(props) => props?.$right};
    font-size: 12px;
  }
`;
export const CertificatesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
`;
export const TextAreaView = styled.div``;
export const ContentInputs = styled.div`
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
  margin-top: 20px;
  .TextAreaMobileView{
    display:none;
  }
  .TextAreaDesktopView{
    display:block;
  }
  #natureOfWork,#locationServed,#serviceOffering,#industryServed,#keyCustomer,#videoUrl,#imageUpload{
    margin-bottom: 40px;
  }
  @media screen and (max-width: 640px) {
  height: calc(100vh - 241px);
  overflow-x:hidden;
  padding:12px 0px 0px 12px;

  #natureOfWork,#locationServed,#serviceOffering{
  order:-1;
  }

  #serviceOffering{
  width: 92.01vw;;
  order:-1
  }

  #industryServed,#locationsServedWorldwide,${CertificatesWrapper},${InputFlexWrapper}{
  display:none;
  }
  
  #keyCustomer{
    margin-right:0px;
  }
  ${TextAreaDiv}{
  margin-right:0px;
  }

  ${DropdownAntd}{
  width: 92.01vw;;
  }

  .TextAreaMobileView{
      display:block;
      width: 100%;
  p{
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: #011759;
  }    
  }
  .TextAreaDesktopView{
      display:none;
  }
  }
`;

export const BttnContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;
  height: auto;
  align-items: baseline;
`;
export const Bttn = styled(BttnDiv)<any>`
  @media screen and (max-width: 640px) {
    width: 44.15vw;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 40px;
  border: 1px solid #215ec9;
  border-radius: 8px;
  background: #215ec9;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  &:hover {
    background: #215ec9;
    color: #ffffff;
  }
  border: ${(props) => props?.$error && "1px solid #BE000B"};
  background: ${(props) => props?.$error && "rgba(190, 0, 11, 0.05)"};
  color: ${(props) => props?.$error && "#215EC9"};
`;
export const AddManuallyBttn = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: ${(props) => !props?.$isOpen && "center"};
  width: ${(props) => props?.$width};
  height: ${(props) => props?.$height};
  background: #f4f6ff;
  border: 1px solid #d4e5fe;
  border-radius: ${(props) => props?.$borderRadius};
  font-weight: 600;
  font-size: 12px;
  color: #215ec9;
  cursor: pointer;
  border-bottom: ${(props) => props?.$isOpen && "hidden"};
  padding-top: ${(props) => props?.$isOpen && "12px"};
  @media screen and (max-width: 640px) {
    width: ${(props) => props?.$usedForResponsiveScreen && "100%"};
    background: ${(props) => props?.$background};
  }
`;
export const CertificateBttns = styled(AddManuallyBttn)`
  @media screen and (max-width: 640px) {
    background: #ffffff;
    color: #215ec9;
  }
`;
export const AdditionalInfoWrapper = styled.div`
  display: none;
  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    width: 92.09vw;
    border-top: 1px solid #e6e6e6;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    span {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.1em;
      color: #011759;
      opacity: 0.7;
      margin-bottom: 2px;
    }
    p {
      font-size: 12px;
      line-height: 18px;
      text-align: justify;
      color: #011759;
      margin-bottom: 12px;
    }
  }
`;
export const LineHider = styled.div`
  width: 147px;
  height: 1px;
  position: absolute;
  top: -1px;
  right: 0;
  background: #f4f6ff;
`;
export const KeyCustomerDescContainer = styled.div<any>`
  width: 100%;
  height: 104px;
  position: relative;
  padding: 12px;
  background: #f4f6ff;
  border: 1px solid #d4e5fe;
  box-sizing: border-box;
  border-radius: ${(props) => props?.$borderRadius};
  ${TextAreaDiv} {
    max-width: 372px;
    width: 100%;
    height: 80px;
    padding: 12px;
  }
`;
export const DisplayCertOnRespScreen = styled(ContentInputs)`
  ${CollapseDiv} {
    max-width: 100%;
  }
`;
export const KeyCustomerFileRespView = styled.div<any>`
  display: ${(props) => (props?.$hideOnMobileScreen ? "block" : "none")};
  @media screen and (max-width: 640px) {
    display: ${(props) => (props?.$hideOnMobileScreen ? "none" : "block")};
  }
`;

export interface ICustomReactQuillEditorWrapperProps extends BoxProps {
  isError?: boolean;
}

export const CapabilitiesCustomReactQuillEditorWrapper = styled(Box)<ICustomReactQuillEditorWrapperProps>`
  .ql-toolbar.ql-snow {
    border: 0 !important;
    border-bottom: 1px solid rgb(212, 229, 254) !important;
  }

  .ql-container.ql-snow {
    border: 0 !important;
  }

  .ql-container {
    height: 200px;
    font-family: Poppins;
    font-size: 14px;
  }

  .ql-editor.ql-blank {
    background-color: ${props => props?.isError ? "rgba(190,0,11,0.05)" : "inherit"};
  }

  .quill {
    width: 55vw;
    @media screen and (max-width: 640px) {
      width: 100%;
    }
  }

  p {
    margin-bottom: 0 !important;
    display: contents !important;
  }
`;

export const CaptionButton = styled(VZuiButton)`
  height: 18px;
  width: 100px;
  margin-top: 6px;
  padding: 2px 14px;
  border-radius: 1px;
  background-color: rgba(33, 94, 201, 0.1);
`;
