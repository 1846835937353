import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Tag } from 'antd';
import CardSpinner from 'components/Spinner/CardSpinner';
import { useHistory } from 'react-router-dom';
import { checkForMobileScreen, checkForSmallerMobileScreen } from 'shared/handlers/getUserScreenSize.handler';
import { Label } from '../../Label';

interface CardTagProps {
  cardText: string;
  usedFor:
    | 'selectItem'
    | 'selectItemWithLogo'
    | 'fileUploadTag'
    | 'videoUploadTag'
    | 'fileUploadReferenceTag'
    | 'dropDownCard'
    | 'jobRequest';
  onClose?: (e: any) => void;
  closeIcon?: any;
  fileSize?: number;
  closable?: boolean;
  loading?: boolean;
  cursorType?: 'pointer' | 'not-allowed' | 'default';
  keys?: number;
  usedAt?: string;
  data?: any;
  multiLine?: boolean;
  fileType?: string;
  loginStatus?: boolean;
  height?: string;
  fileIcon?: ReactNode;
  labelFontVariant?: 'medium' | 'bold';
  labelFontColor?: string;
  labelWhiteSpace?: 'nowrap' | 'normal';
}

const Div = styled.div`
  margin-bottom: 1px;
  @media screen and (max-width: 640px) {
    width: 79px;
    overflow: hidden;
  }
`;

export const TagAntd = styled(Tag)<CardTagProps>`
  height: ${(props) => props?.height};
  display: flex;
  align-items: center;
  background-color: ${(props) => `${props.theme.card.types.cardTags[props.usedFor].backgroundColor}`};
  padding: ${(props) => `${props.theme.card.types.cardTags[props.usedFor].padding}`};
  border-radius: ${(props) => `${props.theme.card.types.cardTags[props.usedFor].borderRadius}`};
  width: ${(props) => `${
    props.usedAt === 'experience'
      ? '280px'
      : props.usedAt === 'companyInfo'
        ? '204px'
        : props.theme.card.types.cardTags[props.usedFor].width || 'auto'
  }`};
  margin: ${(props) => `${props.theme.card.types.cardTags[props.usedFor].margin || '0px'}`};
  border: none;
  cursor: ${(props) => `${props.cursorType || 'default'}`};
  justify-content: space-between;
  & :hover {
    opacity: unset;
  }
  ${Div} {
    ${(props) => props.usedAt === 'companyInfo' && {
    maxWidth: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }}
  }
  @media only screen and (max-width: 640px) {
    padding: 10px 2px;
    margin: 3px 0px 1px 0px;
  }
`;

export const FileInfoWrapper = styled.div<CardTagProps>`
  display: flex;
  flex-direction: column;
  padding: ${(props) => `${
    props.usedFor === 'fileUploadReferenceTag'
      ? '0px 278px 0px 15px'
      : props.usedAt === 'experience'
        ? '0px 5px 0px 15px'
        : props.usedFor === 'fileUploadTag' || props.usedFor === 'videoUploadTag'
          ? '0'
          : '0px 45px 0px 15px'
  }`};
  cursor: ${(props) => `${props.cursorType || 'default'}`};
  max-width: ${(props) => `${['fileUploadTag', 'jobRequest'].includes(props.usedFor) ? '100%' : '200px'}`};
  margin: 0 10px;
`;

const LinkTo = styled.a``;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CardTag = ({ ...props }: CardTagProps) => {
  const history = useHistory();
  const textLength = checkForSmallerMobileScreen() ? 10 : 14;
  const shortenText = (text: string) => {
    if (text) {
      const value = text.split('.');
      if (
        text.length > 20
        && (props.usedAt === 'experience' || props.usedAt === 'fileUploadTag' || checkForMobileScreen())
      ) {
        if (value.length <= 1) {
          return `${value[0].substring(0, textLength)}...${value[0].substring(value[0].length - 4)}`;
        }
        return `${value[0].substring(0, textLength)}...${value[0].substring(value[0].length - 4)}.${value[1]}`;

        return `${value[0].substring(0, 14)}...${value[0].substring(value[0].length - 4)}.${value[1]}`;
      }
    }

    return text;
  };

  const onHandleClick = () => {
    history.push('/login');
  };
  const checkForTempFile = (doc) => {
    if (doc?.file?.fileUrl.includes('-temp')) {
      return doc?.file?.localImg || doc?.file?.fileUrl;
    }
    return doc?.file?.fileUrl;
  };
  return (
    <div key={props.keys}>
      {(props.usedFor === 'selectItem'
        || props.usedFor === 'dropDownCard'
        || props.usedFor === 'selectItemWithLogo') && (
        <TagAntd
          key={props.cardText}
          icon={props.usedFor === 'selectItemWithLogo' && <img src="/images/ProfileCreation/info.svg" />}
          {...props}
          closable={!props.closable}
          closeIcon={props.closeIcon ? <img src={props.closeIcon} /> : <img src="/images/xCircle.svg" />}
          onClose={(e) => props.onClose(e)}
        >
          <Label
            text={shortenText(props.cardText)}
            htmlFor="profileCreationCard"
            fontVariant={props.labelFontVariant || 'bold'}
            multiLine={props?.multiLine}
            whiteSpace={props.labelWhiteSpace}
            fontColor={props?.labelFontColor}
          />
        </TagAntd>
      )}

      {(props.usedFor === 'jobRequest'
        || props.usedFor === 'fileUploadTag'
        || props.usedFor === 'videoUploadTag'
        || props.usedFor === 'fileUploadReferenceTag') && (
        <TagAntd
          {...props}
          key={props.cardText}
          closable={!props.closable}
          closeIcon={<img src="/images/xCircle.svg" />}
          onClose={(e) => {
            props.onClose(e);
            e.preventDefault();
          }}
        >
          <FlexContainer>
            {props.loading ? (
              <CardSpinner />
            ) : props.fileType === 'video' ? (
              <img src="/images/video.svg" />
            ) : (
              props?.fileIcon || <img src="/images/note.svg" />
            )}
            <FileInfoWrapper {...props}>
              <Div>
                <LinkTo
                  href={
                    props?.data?.documentFile?.fileUrl
                    || checkForTempFile(props?.data)
                    || props?.data?.videoUrl
                    || props?.data?.certificationFile?.fileUrl
                    || props?.data?.fileUrl
                  }
                  target="_blank"
                >
                  <Label
                    fontVariant="bold"
                    text={shortenText(props?.cardText)}
                    htmlFor="profileHeader"
                    cursorType={props.cursorType}
                    multiLine={props?.multiLine}
                    whiteSpace={props?.labelWhiteSpace}
                  />
                </LinkTo>
              </Div>
              <Label
                fontVariant="regular"
                text={props?.fileSize ? `${props.fileSize} Mb` : ''}
                htmlFor="profileHeader"
                cursorType={props.cursorType}
              />
            </FileInfoWrapper>
          </FlexContainer>
          {props.usedFor === 'jobRequest' && !props?.loginStatus && (
            <img style={{ cursor: 'pointer' }} src="/images/lock.svg" onClick={onHandleClick} />
          )}
        </TagAntd>
      )}
    </div>
  );
};

export default CardTag;
