import React, { useState } from "react";
import styled from "styled-components";
import { InputWithEdit } from "../Input";
import InputBoxForEdit from "../Input/InputBoxWithEdit/inputBox";
import { Button } from "../Button";
import openNotification from "constants/antdNotification";
interface UploadFileProps {
  onHandleFileUpload?: any;
  onHandleFileName?: any;
  getDocumentName?: any;
  name?: string;
  onFileUploadError?: any;
}

const Wrapper = styled.div`
  display: flex;
  padding: 10px 10px 0px 0;
`;

const InputFlexWrapper = styled.div``;

const InputUploadWrapper = styled(InputFlexWrapper)`
  display: flex;
  position: relative;
`;

const InputWrap = styled.input`
  position: absolute;
  right: 0px;
  opacity: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  top: -0px;
  bottom: 0px;
  cursor: pointer;
`;

const WarningImg = styled.img`
  width: 26px;
  height: 26px;
  margin-top: 40px;
`;
const MessageWrapper = styled.div`
  display: flex;
`;
const UploadFile = (props: UploadFileProps) => {
  const [documents, setDocuments] = useState([]);
  const [documentName, setDocumentName] = useState("Upload Certificate");
  const [fileName, setFileName] = useState("");
  const [inputFieldError, setInputFieldError] = useState(false);

  const handleAddKey = () => {};

  const getFile = (e: any) => {
    const file = e.target.files[0];
    if (file?.size / 1024 / 1024 > 10) {
      //openNotification('File size exceeds the 10Mb limit. Please try uploading a smaller file size');
      setInputFieldError(true);
      if(props?.onFileUploadError)
      props?.onFileUploadError(true);
    } 
    if (
      typeof file !== "undefined" &&
      file?.size / 1024 / 1024 <= 10 &&
      documents.length < 10
    ) {
      setInputFieldError(false);
      // setDocumentName(file.name);;
      if(props?.onFileUploadError)
      props?.onFileUploadError(false);

      props?.onHandleFileUpload(file);
      setFileName("");
    }
  };

  props?.getDocumentName(documentName);
const onInputClick=(event)=>{
  event.target.value=""
}
  return (
    <Wrapper>
      <InputFlexWrapper>
        <MessageWrapper>
          <InputWithEdit
            inputFieldError={inputFieldError}
            inputBoxSize="small"
            inputType="text"
            importance="important"
            placeholder="Name of Certificate"
            usedFor="profileCreation"
            name={props?.name}
            withHeader={false}
            disabled={false}
            onChange={e => {
              setFileName(e.target.value);
              props?.onHandleFileName(e);
            }}
            maxChar={50}
            value={fileName?.trimStart()}
          ></InputWithEdit>

          {/* {inputFieldError && <WarningImg src="/images/Auth/warning.svg" />} */}
        </MessageWrapper>
      </InputFlexWrapper>

      {
        <InputUploadWrapper>
          <InputBoxForEdit
            sizes="small"
            inputTextType="text"
            importanceType="important"
            child="Document"
            placeholder={documentName || "Certification Document"}
            buttonType={true}
            buttonDisable
            onHandleChange={() => {}}
          />

          {/* <Button
            onClick={handleAddKey}
            variant="white"
            size="small"
            style={{
              borderRadius: "0px 8px 8px 0px",
              width: "160px",
              marginTop: "0px",
              position: "relative",
              left: "-49px",
              top: "-2px",
              height: "60px",
              fontSize: "16px",
              border: "1px solid #D5D9EC",
              color: "#011759",
            }}
          >
            Browse
          </Button> */}

          <Button
            onClick={handleAddKey}
            variant="blue"
            size="small"
            style={{
              borderRadius: "0px 8px 8px 0px",
              width: "60px",
              marginTop: "0px",
              position: "absolute",
              right: "0",
              top: "0",
              height: "60px",
            }}
          >
            <img src="/images/upload_white.svg" />
          </Button>
          <InputWrap
            type="file"
            onChange={getFile}
            accept="application/pdf,image/jpeg"
            onClick={onInputClick}
          />
        </InputUploadWrapper>
      }
    </Wrapper>
  );
};
export default UploadFile;
