import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { AppConfig } from "ui-constants";

export const getUserDetails = () => {
    if (LocalStorageService.get(AppConfig.STORAGE_KEYS.userData) || SessionStorageService.get(AppConfig.STORAGE_KEYS.userData)) {
    return true;
    } 
    return false;
  };

  export const getUserData = () => {
    if (LocalStorageService.get(AppConfig.STORAGE_KEYS.userData) || SessionStorageService.get(AppConfig.STORAGE_KEYS.userData)) {
      switch(true) {
        case LocalStorageService.get(AppConfig.STORAGE_KEYS.userData) !== null: return LocalStorageService.get(AppConfig.STORAGE_KEYS.userData); break;
        case SessionStorageService.get(AppConfig.STORAGE_KEYS.userData) !== null: return SessionStorageService.get(AppConfig.STORAGE_KEYS.userData); break;
      }
    } 
    return;
  };