import { Row, Col, Divider, Carousel } from "antd";
import styled from "styled-components";

interface ColProps {
  $mobScreenHeight?: string;
  $mobScreenMaxHeight?: string;
  $mobScreenBorderRadius?: string;
  $mobScreenBgColor?: string;
  $mobScreenMarginTop?: string;
  $mobScreenPosition?: string;
}

export const RowDiv = styled(Row)`
  height: 100%;
  display: flex;
  @media only screen and (max-width: 640px) {
    flex-direction: column;
    height: 100vh;
    position: relative;
    background-color: rgb(1, 23, 89);
  }
`;

export const Spinner = styled.div`
  position: absolute;
  text-align: center;
  font-size: 10px;
  backdrop-filter: blur(2px);
  z-index: 1500;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
export const ColDiv = styled(Col)<ColProps>`
  height: 100vh;
  width: 50vw;
  flex: 1 1;
  @media only screen and (max-width: 640px) {
    width: 100vw;
    height: ${(props) => props?.$mobScreenHeight};
    max-height: ${(props) => props?.$mobScreenMaxHeight};
    border-radius: ${(props) => props?.$mobScreenBorderRadius};
    background-color: ${(props) => props?.$mobScreenBgColor};
    position: ${(props) => props?.$mobScreenPosition};
    margin-top: ${(props) => props?.$mobScreenMarginTop};
  }
`;
export const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  displat: flex;
  background-image: url(/images/Auth/background.png);
  background-size: cover;
  padding: 40px 0px 0px 20px;
  @media only screen and (max-width: 640px) {
    background-image: none;
    background-color: rgb(1, 23, 89);
    height: 67px;
    display: flex;
    align-items: center;
    padding: 0px;
  }
`;
export const RightLogoWrapper = styled.div`
  @media only screen and (max-width: 640px) {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    position: fixed;
    padding: 0px 20px;
  }
`;
export const Logo = styled.div`
  background-image: url("/images/logo.svg");
  width: 62px;
  height: 50px;
  @media only screen and (max-width: 640px) {
    width: 34px;
    height: 28px;
    background-repeat: round;
    background-color: rgb(1, 23, 89);
  }
`;
export const RightCarouselWrapper = styled.div`
  height: calc(100%- 218px);
  margin-left: 40px;
  display: flex;
  // align-items:center;
  margin-top: 110px;
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;
export const CarouselBase = styled(Carousel)`
  & .slick-dots-bottom {
    bottom: -2px;
  }

  & .slick-list {
    text-align: center;
  }

  & .slick-dots {
    & li button {
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }

    & li.slick-active {
      width: 4%;
    }
  }

  height: 233px;
  width: 38vw;
  color: #ffff;
`;

export const CarouselText = styled.div`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: ${(props) => `${props.theme.colors.white}`};
`;

export const LeftContainer = styled.div`
  width: 100%;
  height: calc(100% - 64px);
  background: #ffffff;
  display: flex;
  justify-content: center;
  margin-top: 64px;
  @media only screen and (max-width: 640px) {
    margin-top: 16px;
    height: calc(100vh - 84px);
  }
`;

export const FooterWrapper = styled.div`
  width: auto;
  visibility: hidden;
  display: flex;
  @media only screen and (max-width: 1023px) {
    visibility: visible;
  }
`;
export const FooterText = styled.div<any>`
  font-weight: ${(props) => `${props.$weight}`};
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  color: #ffffff;
  margin-left: ${(props) => `${props.$marginLeft}`};
  cursor: ${(props) => `${props?.$cursor}`};
`;
