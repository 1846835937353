import { authModalHeightBreakPoints } from "Authentication/modalHeights";
import React from "react";
import styled from "styled-components";
import { FlexContainer,Container } from "./AuthenticationModel.styles";

interface IAuthenticationModalProps {
  bodyContent: React.ReactNode;
  footerContent: React.ReactNode;
  modalHeight?: string;
}
interface ContainerProps {
  modalHeight?: string;
  autoHeight?: boolean;
  $heights?:any;
}

export const AuthModal = styled.div<ContainerProps>`
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(18, 59, 192, 0.07);
  border-radius: 60px;
  position: relative;
  width: 560px;
  height: ${(props) => (props?.$heights?.mobileStyles?.[props?.modalHeight])};
  @media only screen and (min-height: 1032px) {
  height: ${(props) => (props?.$heights?.tabletStyles?.[props?.modalHeight])};
  }
  @media only screen and (max-width: 640px) {
  width: 100vw;
  margin-top:67px;
  border-radius: 32px 32px 0px 0px;
  /*margin top is 67px*/
  height:calc(100vh - 67px);
  }
`;

const AuthenticationModal = (props: IAuthenticationModalProps) => (
  <FlexContainer>
    <AuthModal {...props} $heights={authModalHeightBreakPoints}>
      <Container>{props.bodyContent}</Container>
    </AuthModal>
  </FlexContainer>
);

export default AuthenticationModal;
