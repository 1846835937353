import Button from "components/Button/Button";
import { InputWithEdit } from "components/Input";
import React, { useEffect, useRef, useState } from "react";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import { useDispatch, useSelector } from "react-redux";
import { CardTag } from "components/Card";
import {
  backfillKeyContacts,
  companyInfoOnboardingPost,
  companyInfoUpdate,
  getCompanyInfo,
} from "reducers/companyInfo";
import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
import UploadImage from "components/UploadImage/UploadImage";
import { fileUploadPost } from "reducers/fileUpload";
import { DatePickerWithLabel } from "components/DatePicker";
import { useHistory } from "react-router-dom";
import {
  profileCompletionStatus,
  profileLayoutError,
  resetProfileCompletionStatus,
  showEditReducer,
  toggleIsPageEdited,
} from "reducers/profileCreation";
import { setIsLoading } from "reducers/auth";
import { Spin, Tooltip } from "antd";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import FormField from "Profile/shared/FormField/FormField";
import { handleScrollToView } from "shared/handlers/form.handler";

import { updateProfileFlagToTrue } from "shared/handlers/updateProfiileFlag.handler";
import BasicDropdown from "components/Dropdown/BasicDropdown/BasicDropdown";
import { DropdownData } from "constants/mockData";
import { ButtonWrapper } from "Profile/Profile.styles";
import {
  checkForMobileScreen,
  checkForSmallerMobileScreen,
} from "shared/handlers/getUserScreenSize.handler";
import { AppConfig } from "ui-constants";
import {
  ContentInputs,
  FormWrapper,
  InputDiv,
  InputWrap,
  TagContainer,
  ProfileImageWrapper,
  Spinner,
  FormWrapperDiv,
  FormWrapperSection,
  VerficationStatus,
  AddressStatusTooltipDiv,
  UploadIcon,
  BrocuherTextDiv,
  ResScreenImageWrapper,
  CompNameWebsiteWrapper,
  WebsiteWrapper,
  ResScreenWebsiteWrapper,
  Bttn,
} from "./CompanyInformation.styles";
import { updateDashBoardData } from "reducers/dashboard";
import {
  HEAP_DATA_ATTRIBUTES,
  HEAP_DATA_TRACKING_ID,
} from "constants/heapAnalytics";

interface ImageData {
  fileName: string;
  fileSize: number;
  fileUrl: string;
}

interface FileData {
  name: string;
  file: ImageData;
}

const CompanyInformation = () => {
  const websiteChar = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
  const buttonState = useSelector((state: any) => state.profileCreation);
  const dispatch = useDispatch();
  const localStrUserData = LocalStorageService.get(
    AppConfig.STORAGE_KEYS.userData
  );
  const companyInfoState = useSelector((state: any) => state.companyInfo);
  const { companyInfoData, loaded, error, updatedError } = companyInfoState;
  const history = useHistory();
  const profileCompletionData = useSelector(
    (state: any) => state.profileCreation
  ).profileCompletionStatus;
  const [saveState, setSaveState] = useState(false);
  const authState = useSelector((state: any) => state.auth);
  const { isLoading } = authState;
  const [isSiteVerified, setIsSiteVerified] = useState(false);
  const [brochureUploadError, setBrochureUploadError] = useState(false);
  const [addressUploadError, setAddressUploadError] = useState(false);
  const isOnBoarding = window.location.href.includes("onboarding");
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [isCompanyEditable, setIsCompanyEditable] = useState(false);
  const [isTurnOverSlabEditable, setIsTurnOverSlabEditable] = useState(false);
  const isFirstError = useRef(true);

  const companyInformation = {
    vendorId: `${SessionStorageService.get("vendorId") ||
      LocalStorageService.get("vendorId")}`,
    companyName: "",
    yoc: "",
    gstn: getGstn(),
    website: "",
    cin: "",
    fulladdress: {
      address: "",
      state: undefined,
      city: undefined,
      pincode: "",
    },
    turnOver: "",
    total: "",
    companyLogo: {
      fileName: "",
      fileSize: 0,
      fileUrl: "",
    },
    companyBrochure: [],
    companyVideo: {},
    turnOverSlab: "",
    addressProof: [],
    turnOverSlabModified: false,
    yocModified: false,
  };
  let validateData = false;
  const [userInfo, setUserInfo] = useState(companyInformation);

  useEffect(() => {
    showEditReducer(dispatch, (buttonState.editButton = false));
    if (
      window.location.href.includes("onboarding") &&
      profileCompletionData?.profileCompleteness
    ) {
      history.push("/userinfo");
    } else {
      dispatch(getCompanyInfo({}));
    }
  }, [profileCompletionData]);

  useEffect(() => {
    if (companyInfoData && Object.keys(companyInfoData).length > 1) {
      companyInfoData.companyBrochure = companyInfoData.companyBrochure || [];
      companyInfoData.addressProof = companyInfoData.addressProof || [];
      setUserInfo({ ...companyInfoData });
      if (companyInfoData?.total) {
        showEditReducer(dispatch, (buttonState.editButton = true));
      }
      if (!isOnBoarding) {
        showEditReducer(dispatch, (buttonState.editButton = false));
      }
    }
    // on initial onboarding if api doesnt give companyname we hve to allow user to edit since it is not editable ,so below usestate is used
    if (companyInfoData?.companyName?.length) {
      setIsCompanyEditable(true);
    } else {
      setIsCompanyEditable(false);
    }
    if (companyInfoData?.turnOverSlab) {
      setIsTurnOverSlabEditable(true);
    } else {
      setIsTurnOverSlabEditable(false);
    }
  }, [companyInfoData]);

  useEffect(() => {
    if (userInfo?.website?.length) {
      if (websiteChar.test(userInfo.website)) {
        setIsSiteVerified(true);
      } else {
        setIsSiteVerified(false);
      }
    } else {
      setIsSiteVerified(true);
    }
  }, [userInfo.website]);

  if (
    userInfo?.companyName !== undefined &&
    userInfo?.companyName !== "" &&
    userInfo?.fulladdress?.address !== undefined &&
    userInfo?.fulladdress?.address !== "" &&
    userInfo?.companyBrochure?.length &&
    isSiteVerified
  ) {
    validateData = true;
  } else {
    validateData = false;
  }
  const setUserdata = (name: string, data) => {
    if (name === "turnOverSlab") {
      setUserInfo({
        ...userInfo,
        [name]: `Slab: ${data}`,
        ["turnOverSlabModified"]: true,
      });
    } else {
      setUserInfo({
        ...userInfo,
        [name]: data,
      });
    }
  };
  const handleOnChange = (e: any) => {
    if (e?.target?.name === "address") {
      setUserdata("fulladdress", {
        ...userInfo.fulladdress,
        address: e.target?.value.trimStart(),
      });
    } else {
      setUserdata(e.target.name, e.target?.value.trimStart());
    }
  };

  const handleUpload = (imageDetails: any) => {
    setUserdata("companyLogo", imageDetails);
    toggleIsPageEdited(dispatch, true);
  };

  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false);
  }, []);

  const handleClick = (type) => {
    if (type === "save") {
      setSaveState(true);
      if (validateData) {
        setIsDataSaving(true);
        if (isOnBoarding) {
          dispatch(companyInfoOnboardingPost(userInfo)).then(() => {
            profileLayoutError(dispatch, false);
            resetProfileCompletionStatus(dispatch);
            updateProfileFlagToTrue("isCompanyInfoCompleted");
            toggleIsPageEdited(dispatch, false);
            history.push("/onboarding/capabilities");
          });
        } else {
          dispatch(companyInfoUpdate(userInfo))
            .then((res) => {
              setIsDataSaving(false);
              notificationFunc("success");
              profileLayoutError(dispatch, false);
              toggleIsPageEdited(dispatch, false);
              dispatch(getCompanyInfo({})).then((res) => {
                updateDashBoardData(dispatch, res);
              });
              const data = {
                gstn: userInfo?.gstn,
                domain_name: userInfo?.website
              }
              dispatch(backfillKeyContacts(data))
            })
            .catch((res) => {
              setIsDataSaving(false);
            });
        }
      } else {
        profileLayoutError(dispatch, true);
      }
    }
    if (loaded && !error) {
    }
  };
  const getFile = (e: any, fileType: "companyBrochure" | "addressProof") => {
    const file = e.target.files[0];
    let isDuplicate = false;
    if (fileType === "addressProof") {
      isDuplicate = userInfo.addressProof.some(
        (proof) => proof.name === file.name
      );
    }
    if (
      typeof file !== "undefined" &&
      file?.size / 1024 / 1024 <= 10 &&
      !isDuplicate
    ) {
      if (fileType === "companyBrochure") setBrochureUploadError(false);
      if (fileType === "addressProof") setAddressUploadError(false);
      setIsLoading(dispatch, { payload: true });
      dispatch(fileUploadPost(file)).then(
        (res) => {
          const data = userInfo[fileType].length
            ? [...userInfo[fileType], { name: file?.name, file: res }]
            : [{ name: file?.name, file: res }];
          setUserdata(fileType, data);
          setIsLoading(dispatch, { payload: false });
          if (fileType === "companyBrochure") setBrochureUploadError(false);
          if (fileType === "addressProof") setAddressUploadError(false);
          toggleIsPageEdited(dispatch, true);
        },
        (error) => {}
      );
    } else if (file?.size / 1024 / 1024 > 10 && !isDuplicate) {
      if (fileType === "companyBrochure") setBrochureUploadError(true);
      if (fileType === "addressProof") setAddressUploadError(true);
    } else {
      if (fileType === "companyBrochure") setBrochureUploadError(false);
      if (fileType === "addressProof") setAddressUploadError(false);
    }
  };

  const handleDateChange = (date, dateString) => {
    setUserInfo({
      ...userInfo,
      yoc: `${dateString}`,
      ["yocModified"]: true,
    });
  };
  const onInputClick = (event) => {
    event.target.value = "";
  };

  const onHandleScroll = (id) => {
    if (isFirstError.current) {
      handleScrollToView(id);
      isFirstError.current = false;
    }
    return true;
  };

  const removeFile = (arr: Array<FileData>, item: FileData): Array<FileData> =>
    arr.filter((itr) => itr.file.fileUrl !== item.file.fileUrl);

  const renderImgupload = () => (
    <UploadImage
      height="148px"
      width="148px"
      title="Company Logo"
      imgSrc={userInfo?.companyLogo?.fileUrl}
      onUpload={handleUpload}
      disabled={false}
      usedAt="companyLogo"
    />
  );

  const renderWebsiteComp = () => (
    <InputDiv id="website">
      <FormField
        showError={
          userInfo?.website?.length &&
          saveState &&
          !websiteChar.test(userInfo.website)
        }
        label="Website"
        isStar={false}
        customErrorMessage={
          checkForSmallerMobileScreen()
            ? "Incorrect website"
            : checkForMobileScreen()
            ? "Incorrect website format"
            : "Enter correct website format"
        }
        isPaddingNotRequired
      >
        <InputWithEdit
          inputFieldError={
            userInfo?.website?.length &&
            saveState &&
            !websiteChar.test(userInfo.website)
              ? onHandleScroll("website")
              : false
          }
          withHeader={false}
          usedFor="profileCreation"
          inputBoxSize="profileCreationMedium"
          inputType="text"
          importance="important"
          value={userInfo?.website}
          name="website"
          placeholder="Eg: www.abcd.com"
          onChange={(e) => {
            handleOnChange(e);
            toggleIsPageEdited(dispatch, true);
          }}
        />
      </FormField>
    </InputDiv>
  );
  return (
    <>
      {isLoading && (
        <Spinner>
          <Spin
            style={{ position: "absolute", top: "50%" }}
            size="large"
            tip="Loading..."
          />
        </Spinner>
      )}
      <ContentInputs>
        <FormWrapper>
          <FormWrapperSection>
            <FormWrapperDiv>
              <CompNameWebsiteWrapper>
                <InputDiv id="companyName">
                  <FormField
                    showError={saveState && !userInfo?.companyName}
                    label="Company Name"
                    isStar
                    isPaddingNotRequired
                  >
                    <InputWithEdit
                      inputDataAttribute={{
                        [HEAP_DATA_TRACKING_ID]:
                          HEAP_DATA_ATTRIBUTES.COMPANY_INFO.NAME,
                      }}
                      inputFieldError={
                        saveState && !userInfo?.companyName.length
                          ? onHandleScroll("companyName")
                          : false
                      }
                      withHeader={false}
                      usedFor="profileCreation"
                      inputBoxSize="profileCreationMedium"
                      inputType="text"
                      importance="important"
                      value={userInfo?.companyName}
                      name="companyName"
                      buttonDisable={isCompanyEditable}
                      onChange={(e) => {
                        handleOnChange(e);
                        toggleIsPageEdited(dispatch, true);
                      }}
                      placeholder="Enter Company Name"
                    />
                  </FormField>
                </InputDiv>
                <ResScreenWebsiteWrapper>
                  {renderWebsiteComp()}
                </ResScreenWebsiteWrapper>
              </CompNameWebsiteWrapper>
              <ResScreenImageWrapper>{renderImgupload()}</ResScreenImageWrapper>
              <InputDiv id="yoc">
                <FormField
                  showError={false}
                  label="Year of Incorporation"
                  isStar={false}
                  isPaddingNotRequired
                  responsiveScreenLabel={
                    checkForSmallerMobileScreen()
                      ? "Incorporated in"
                      : "Year of Incorporation"
                  }
                >
                  <DatePickerWithLabel
                    inputDataTrackingId={HEAP_DATA_ATTRIBUTES.COMPANY_INFO.YOI}
                    usedFor="profileOnboarding"
                    placeholder={
                      userInfo?.hasOwnProperty("yoc") &&
                      userInfo?.yoc?.length > 1
                        ? userInfo.yoc
                        : "DD - MM - YYYY"
                    }
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      handleDateChange(date, dateString);
                      toggleIsPageEdited(dispatch, true);
                    }}
                    //inputFieldError={saveState && !userInfo?.yoc?.length ? onHandleScroll("yoc") : false}
                  />
                </FormField>
              </InputDiv>
              <WebsiteWrapper>{renderWebsiteComp()}</WebsiteWrapper>

              <InputDiv id="turnOverSlab" className="InputTurnoverSlab">
                <FormField
                  isStar={false}
                  label={
                    checkForMobileScreen()
                      ? "Annual Turnover"
                      : "Annual Turnover Slab"
                  }
                  showError={false}
                  isPaddingNotRequired
                  dataTrackingId={
                    HEAP_DATA_ATTRIBUTES.COMPANY_INFO.ANNUAL_TURN_OVER
                  }
                >
                  <BasicDropdown
                    sizes="profileCreationSmall"
                    placeholder="Select Turnover"
                    data={DropdownData.turnOverSlab}
                    onChange={(e: string) => {
                      setUserdata("turnOverSlab", e);
                      toggleIsPageEdited(dispatch, true);
                    }}
                    usedFor="companyInfo_turnOverSlab"
                    value={
                      userInfo?.turnOverSlab
                        ? userInfo.turnOverSlab.slice(5).trim()
                        : undefined
                    }
                    disabled={isTurnOverSlabEditable}
                  />
                </FormField>
              </InputDiv>
            </FormWrapperDiv>
            <FormWrapperDiv>
              <ProfileImageWrapper>{renderImgupload()}</ProfileImageWrapper>
            </FormWrapperDiv>
          </FormWrapperSection>
          <FormWrapperSection>
            <InputDiv id="address">
              <FormField
                showError={
                  addressUploadError ||
                  (saveState && !userInfo?.fulladdress?.address.length)
                }
                label="Registered Address"
                isStar
                info="This should match the address declared in your GST filings"
                customErrorMessage={
                  addressUploadError
                    ? "Required Field. Supports .pdf, .jpeg, .png, .docx formats. Max. file size 10MB"
                    : ""
                }
              >
                <InputWithEdit
                  inputDataAttribute={{
                    [HEAP_DATA_TRACKING_ID]:
                      HEAP_DATA_ATTRIBUTES.COMPANY_INFO.ADDRESS_EDIT,
                  }}
                  inputFieldError={
                    saveState && !userInfo?.fulladdress?.address.length
                      ? onHandleScroll("address")
                      : false
                  }
                  withHeader={false}
                  usedFor="profileCreation"
                  inputBoxSize="profileCreationLarge"
                  inputType="text"
                  importance="important"
                  value={userInfo?.fulladdress?.address}
                  name="address"
                  onChange={(e) => {
                    handleOnChange(e);
                    toggleIsPageEdited(dispatch, true);
                  }}
                  inputBoxStyle={{
                    borderColor: `${
                      userInfo?.fulladdress?.address
                        ? userInfo?.addressProof?.length
                          ? "#47C882"
                          : saveState && onHandleScroll("address")
                          ? "#F4C234"
                          : "#F4C234"
                        : ""
                    }`,
                    paddingRight: "50px",
                  }}
                />
                {!!(
                  userInfo?.fulladdress?.address &&
                  !userInfo?.addressProof?.length
                ) && (
                  <AddressStatusTooltipDiv>
                    <Tooltip
                      color="#ffffff"
                      placement="bottomRight"
                      arrowPointAtCenter
                      title={
                        "Upload address proof to verify your business address\n Ex : Electricity/ Telephone bill, Rent agreement, Trade license"
                      }
                      overlayStyle={{
                        maxWidth: checkForMobileScreen() ? "74vw" : "400px",
                      }}
                      overlayInnerStyle={{
                        fontSize: "12px",
                        color: "#011759",
                        padding: "12px 20px",
                        borderRadius: "12px",
                        whiteSpace: "normal",
                        lineHeight: "18px",
                        width: checkForMobileScreen() && "76.04vw",
                      }}
                      getPopupContainer={(trigger: any) => trigger?.parentNode}
                    >
                      <VerficationStatus src="/images/warning_yellow.svg" />
                    </Tooltip>
                  </AddressStatusTooltipDiv>
                )}
                {!!(
                  userInfo?.addressProof?.length &&
                  userInfo?.fulladdress?.address
                ) && <VerficationStatus src="/images/profile_complete.svg" />}
              </FormField>
              {!!userInfo?.addressProof?.length && (
                <TagContainer>
                  {userInfo.addressProof.map((proof) => (
                    <CardTag
                      onClose={() => {
                        setUserdata(
                          "addressProof",
                          removeFile(userInfo.addressProof, proof)
                        );
                        toggleIsPageEdited(dispatch, true);
                      }}
                      usedFor="fileUploadTag"
                      cardText={proof?.name}
                      closable={false}
                      data={proof}
                      height="40px"
                      key={proof.file.fileUrl}
                      labelWhiteSpace="nowrap"
                    />
                  ))}
                </TagContainer>
              )}
            </InputDiv>
            <FormWrapperDiv>
              <Bttn
                $marginTop="42px"
                $backgroundColor="#FFFFF"
                $color="#215EC9"
                onClick={() => {}}
              >
                <UploadIcon src="/images/upload.svg" />
                Upload Proof
                <InputWrap
                  data-tracking-id={
                    HEAP_DATA_ATTRIBUTES.COMPANY_INFO.PROOF_UPLOAD
                  }
                  onClick={onInputClick}
                  type="file"
                  onInput={(e) => getFile(e, "addressProof")}
                  accept="application/pdf,image/jpeg,image/png,image/jpg,.doc,.docx,application/msword"
                />
              </Bttn>
            </FormWrapperDiv>
          </FormWrapperSection>
          <FormWrapperSection id="brochure">
            <FormField
              showError={
                brochureUploadError ||
                (saveState && !userInfo?.companyBrochure?.length
                  ? onHandleScroll("companyBrochure")
                  : false)
              }
              label="Company Brochure"
              isStar
              customErrorMessage={
                brochureUploadError
                  ? "Supports .pdf, .ppt, .jpeg, .png, .docx formats. Max. file size 10MB"
                  : ""
              }
              isPaddingNotRequired
            >
              {/* inline vw value is wrt to 430px */}
              <FormWrapperDiv
                style={{ rowGap: "0px" }}
                $maxWidth="78.6vw"
                $respScreenMaxWidth="76vw"
              >
                <BrocuherTextDiv>
                  <span className="desktopText">
                    Upload your company brochure/ pitchdeck/ product-service
                    offerings or any such documents which can project your
                    capabilities to the clients.
                  </span>
                  <span className="mobileText">
                    Upload your company brochure/ pitchdeck/ product-service
                    offerings or any such documents
                  </span>
                </BrocuherTextDiv>
                {!!userInfo?.companyBrochure?.length && (
                  <TagContainer style={{ marginTop: "10px" }}>
                    {userInfo?.companyBrochure?.map((doc) => (
                      <CardTag
                        onClose={(e) => {
                          setUserdata(
                            "companyBrochure",
                            removeFile(userInfo.companyBrochure, doc)
                          );
                          toggleIsPageEdited(dispatch, true);
                        }}
                        usedFor="fileUploadTag"
                        labelWhiteSpace="nowrap"
                        cardText={doc?.name}
                        closable={false}
                        data={doc}
                        height="40px"
                        key={doc.file.fileUrl}
                      />
                    ))}
                  </TagContainer>
                )}
              </FormWrapperDiv>
            </FormField>
            <Bttn
              $onError={
                brochureUploadError ||
                (saveState && !userInfo?.companyBrochure?.length)
              }
              $marginTop="22px"
              onClick={() => {}}
              $backgroundColor="#215EC9"
              $color="#FFFFF"
            >
              <UploadIcon
                src={`/images/${
                  brochureUploadError ||
                  (saveState && !userInfo?.companyBrochure?.length)
                    ? "upload_blue"
                    : "upload_white"
                }.svg`}
              />
              Upload Document
              <InputWrap
                onClick={onInputClick}
                type="file"
                onInput={(e) => getFile(e, "companyBrochure")}
                accept="application/pdf,image/jpeg,image/png,image/jpg,.doc,.docx,application/msword,.ppt, .pptx"
                id="companyBrochure"
                data-tracking-id={HEAP_DATA_ATTRIBUTES.COMPANY_INFO.DOC_UPLOAD}
              />
            </Bttn>
          </FormWrapperSection>
        </FormWrapper>
      </ContentInputs>
      <ButtonWrapper $padding="19px 0px 20px 0px">
        <ProfileCreationButtons
          dataAttribute={{
            [HEAP_DATA_TRACKING_ID]:
              HEAP_DATA_ATTRIBUTES.COMPANY_INFO.SAVE_AND_CONTINUE,
          }}
          isloading={isDataSaving}
          buttonText={isOnBoarding ? "SAVE & CONTINUE" : "SAVE CHANGES"}
          usedAt="onboarding"
          state={!buttonState.isPageEdited}
          pagination
          pageNumber={2}
          handleStateChange={(value) => {
            isFirstError.current = true;
            handleClick(value);
          }}
          style={{
            minWidth: "210px",
            height: "40px",
            borderRadius: "4px",
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default CompanyInformation;
