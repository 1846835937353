import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left:56%;
  @media only screen and (max-width: 1023px) {
    padding-left:0;
    width: 100vw;
    position:fixed;
  }
`;

export const AuthModal = styled.div`
  background: #ffffff;
  box-shadow: 0px 30px 60px rgba(18, 59, 192, 0.07);
  border-radius: 60px;
  position: relative;
  width: 560px;
  height: 904px;
`;

export const Container = styled.div`
  margin: 60px 60px 0px 60px;
  @media only screen and (max-width: 1023px) {
    margin: 40px 40px 0px 40px;
  }
  @media only screen and (max-width: 640px) {
    margin-top:16px;
    /*67px margin top + margin-top 16px=84px*/
    height:calc(100vh - 84px);
  }
`;

export const FooterDiv = styled.div`
  display: flex;
  aign-item: center;
  justify-content: center;
  padding: 24px 60px 32px 60px;
  position: absolute;
  heigth: 180px;
  width: 560px;
  background-color: white;
  bottom: 0px;
  border-radius: 0px 0px 60px 60px;
  height: 95px;
`;
