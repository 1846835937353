import { Button } from "antd";
import { InputWithEdit } from "components/Input";
import notificationFunc from "components/Notification";
import { TextAreaWithHeader } from "components/TextArea";
import { BoxContainer, Textarea } from "components/TextArea/TextAreaWithHeader/TextArea";
import FormField from "Profile/shared/FormField/FormField";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { putShareableLink } from "reducers/shareableLink";
import { returnEmails } from "shared/handlers/extractEmail.handler";
import { getProfileLink } from "shared/handlers/profileLink.handler";
import styled from "styled-components";
import { message } from "antd";
import { InputBox } from "components/Input/InputBoxWithEdit/inputBox";
import { Bttn, Container, TextAreaWrapper, Wrapper } from "./ShareableLinkContent.styles";

const ShareableLinkContent = () => {
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const companyInfoState = useSelector((state: any) => state.companyInfo);
  const { companyInfoData } = companyInfoState;
  const userInfo = JSON.parse(sessionStorage?.user);
  const dispatch = useDispatch();
  const { userInfoData } = userInfoState;
  const [textAreaState, setTextAreaState] = useState("");
  const [emailData, setEmailData] = useState<any>([]);
  const [shareBttnState, setShareBttnState] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const handleError = (array) => {
    setTextAreaState(array?.join(","));
  };

  const onHandleShare = () => {
    const emails = returnEmails(textAreaState);
    setShareBttnState(true);
    setEmailData(returnEmails(textAreaState));
    if (emails?.getValid?.length) {
      setLoadingState(true);
      dispatch(
        putShareableLink({
          vendorName: `${userInfo?.firstName} ${userInfo?.lastName}`,
          vendorCompanyName: companyInfoData?.companyName,
          sharableProfileLink: getProfileLink(userInfoData),
          emailIds: emails?.getValid,
        })
      )
        .then(() => {
          setLoadingState(false);
          notificationFunc("success", "Your profile has been shared successfully.");
          handleError(emails?.nonValid);
        })
        .catch((res) => {
          setLoadingState(false);
          notificationFunc("error", "There was some error.");
        });
    } else {
      handleError(emails?.nonValid);
    }
  };
  const body = () => {
    return (
      <Container>
        <FormField showError={false} label={"Shareable Link"} isStar={false}>
          <Wrapper>
            <InputWithEdit
              withHeader={false}
              usedFor="profileCreationLarge"
              inputBoxSize="profileCreationLarge"
              inputType="text"
              importance="important"
              value={getProfileLink(userInfoData)}
              name="shareableLink"
              buttonDisable={false}
              onChange={() => {}}
            ></InputWithEdit>
            <Bttn
              shape={"round"}
              onClick={() => {
                message.info("Copied!");
                navigator.clipboard.writeText(getProfileLink(userInfoData));
              }}
            >
              <span>COPY LINK</span>
              <img src="/images/copy-content.svg" alt="copy icon" />
            </Bttn>
          </Wrapper>
        </FormField>
        <FormField
          label={"Share via email"}
          isStar={false}
          showError={shareBttnState && textAreaState.length && emailData?.nonValid?.length}
          customErrorMessage={`Link was not shared for the following emails,Please enter valid email address.`}
        >
          <Wrapper>
            <TextAreaWrapper>
              <TextAreaWithHeader
                headerLabel=""
                placeholder="Enter emails separated by comma [abc123@test.com, ...]"
                size="medium"
                usedFor="profileCreation"
                buttonDisable={false}
                value={textAreaState}
                name="description"
                onChange={(e) => {
                  setShareBttnState(false);
                  setTextAreaState(e.target.value);
                }}
              />
            </TextAreaWrapper>
            <Bttn
              shape={"round"}
              onClick={onHandleShare}
              disabled={!textAreaState.length || loadingState}
              loading={loadingState}
            >
              <span>SHARE</span>
              <img src="/images/share.svg" alt="share icon" />
            </Bttn>
          </Wrapper>
        </FormField>
      </Container>
    );
  };
  return <>{body()}</>;
};

export default ShareableLinkContent;
