import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import InputWithHeader, { ErrorMessageDiv } from "../InputWithHeader/input";
import { Button } from "../../Button";
import { useField } from "formik";
import { LogoImage } from "../../Image";
import PasswordStrengthEstimator from "../../../utils/passwordStrengthEstimator/index";
import Input from "../InputBox/input";
import InputWithHeaderForEdit from "../InputBoxWithEdit/inputHeader";
import InputBoxForEdit from "../InputBoxWithEdit/inputBox";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { PasswordDiv,IconWrapper } from "./InputBoxWithIcon.styles";
import { InputBoxProps } from "../Interfaces/InputInterface";

const InputBoxWithIcon = (props: InputBoxProps) => {
  const sizes = ["small", "large", "extraLarge"];
  const [icon, setIcon] = useState<boolean>(false);
  const [field] = useField(props);
  const { handlePassword } = { ...props };
  const handleClickIcon = () => {
    setIcon(!icon);
  };

  const strength: number = PasswordStrengthEstimator(field.value);

  useEffect(() => {
    if (handlePassword) handlePassword(strength);
  }, [strength]);

  useEffect(() => {
    if (props?.formikError?.confirmPassword && props.touchedResponse?.confirmPassword && props?.errorCallback) {
      props?.errorCallback("Passwords do not match");
    }
    if (!(props?.formikError?.confirmPassword && props.touchedResponse?.confirmPassword) && props?.errorCallback) {
      props?.errorCallback("");
    }
  }, [props.formikError, props.touchedResponse]);

  return (
    <PasswordDiv $hideErrorMess={props.name === "confirmPassword"}>
      {props.withHeader ? (
        props.name === "gstNo" ||
        props.name === "password" ||
        props.name === "newPassword" ||
        props.name === "confirmPassword" ? (
          <InputWithHeader
            inputDataAttribute={props.inputDataAttribute}
            placeholder={props.placeholder}
            usedFor={props.usedFor}
            inputBoxSize={props.inputBoxSize}
            inputType={props.usedFor == "profileCreation" ? "text" : `${icon ? "text" : "password"}`}
            importance="important"
            label="password"
            name={props.name}
            error={props.formikError}
            touch={props.touchedResponse}
            hidden={props.hidden}
            max={props.max}
            value={props?.value}
          >
            {props.children}
          </InputWithHeader>
        ) : (
          <InputWithHeaderForEdit
            placeholder={props.placeholder}
            usedFor={props.usedFor}
            inputBoxSize={props.inputBoxSize}
            inputType={props.usedFor == "profileCreation" ? "text" : `${icon ? "text" : "password"}`}
            importance="important"
            label="password"
            name={props.name}
            error={props.formikError}
            touch={props.touchedResponse}
            onHandleChange={props.onChange}
            hidden={props.hidden}
          >
            {props.children}
          </InputWithHeaderForEdit>
        )
      ) : props?.name === "gstNo" ? (
        <InputWithHeader
          inputDataAttribute={props.inputDataAttribute}
          placeholder="GST Number"
          inputBoxSize={props?.inputBoxSize}
          usedFor="registration"
          inputType="text"
          importance="important"
          label="gst Number"
          name="gstNo"
          error={props.formikError}
          touch={props.touchedResponse}
          max={15}
          value={props?.value}
        />
      ) : (
        <InputBoxForEdit
          sizes={props.inputBoxSize}
          inputTextType={props.usedFor == "profileCreation" ? "text" : "password"}
          importanceType={"important"}
          child={props.children}
          fields={field}
          otherProps={props}
          // setRef={refValue}
          // disabled={disable}
          placeholder={props.placeholder}
          // buttonType={buttonType}
          onChange={props.onChange}
        />
      )}

      {props.usedFor !== "profileCreation" && (
        <IconWrapper
          $bottom={props?.customIcon && checkForMobileScreen() ? "9px" : sizes.includes(props?.inputBoxSize) && "17px"}
          onClick={handleClickIcon}
        >
          {props?.customIcon ? (
            props?.customIcon
          ) : icon ? (
            <EyeOutlined style={{ color: "#215EC9", zIndex: 100 }} />
          ) : (
            <EyeInvisibleOutlined style={{ color: "#215EC9", zIndex: 100 }} />
          )}
        </IconWrapper>
      )}
    </PasswordDiv>
  );
};

export default InputBoxWithIcon;
