import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "components/Button";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import { fileUploadPost } from "reducers/fileUpload";
import { UserInfo } from "ContextApi/ContextApi";
import openNotification from "constants/antdNotification";
import { PlusSquareFilled } from "@ant-design/icons";
import { toggleIsPageEdited } from "reducers/profileCreation";
import { STYLES } from "Profile/UserInfo/UserInfo.styles";
interface ReferencesProps {
  data?: any;
  handleAddReference: (e) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  //less than 768px
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
const WarningMsg = styled.div`
  height: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #215ec9;
  margin: 10px 0px 22px 0px;
`;
const InputFlexWrapper = styled.div`
  padding: 10px 10px 0px 0;
`;

const InputDivWrap = styled.div`
  max-width: 340px;
  position: relative;
`;

const InputWrap = styled.input`
  position: absolute;
  left: 200px;
  opacity: 0;
  z-index: 1;
  width: 60px;
  height: 60px;
  cursor: pointer;
`;

const Image = styled.img`
  margin-right: 8.5px;
`;
const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const initialReference = {
  clientCompanyName: "",
  contactName: "",
  clientEmail: "",
  completionCertificate: {},
};

const ReferencesContainer = ({
  data,
  handleAddReference,
}: ReferencesProps) => {
  const [newReference, setNewReference] = useState(initialReference);
  const [references, setReferences] = useState([]);
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const [tempDoc, setTempDoc] = useState([]);

  const getFile = (e: any) => {
    const file = e.target.files[0];
    const fileName = file?.name;
    // let editCurrentData = [...reference];
    if (file?.size < 20971520) {
      setFileName(fileName);
      setTempDoc([{ name: fileName, file: { fileSize: file?.size } }]);
      dispatch(fileUploadPost(file)).then(
        res => {
          const newCompletionCertificate = {
            name: fileName,
            file: res,
          };
          setNewReference({
            ...newReference,
            completionCertificate: newCompletionCertificate,
          });
          setTempDoc([]);
          toggleIsPageEdited(dispatch, true);
        },
        error => {
          setTempDoc([]);
        }
      );
    } else {
      openNotification(
        "File size exceeds the 20Mb limit. Please try uploading a smaller file size"
      );
    }
  };

  const handleAddKey = () => {
    setReferences([...references, ...[newReference]]);
    handleAddReference([newReference]);
    setFileName("");
    setNewReference(initialReference);
    toggleIsPageEdited(dispatch, true);
  };

  useEffect(()=>{
    toggleIsPageEdited(dispatch, false);
  },[])

  return (
    <>
      <Wrapper>
        <FlexBox>
          <InputFlexWrapper>
            <InputBoxForEdit
              maxChar={50}
              sizes="semiMiddle"
              inputTextType="text"
              importanceType="important"
              child="First Name"
              fields="input"
              name="clientCompanyName"
              placeholder="Company"
              value={newReference.clientCompanyName}
              style={{ width: "340px", height: "40px" }}
              onHandleChange={e => {
                setNewReference({
                  ...newReference,
                  clientCompanyName: e.target.value.trim(),
                });
              }}
            />
          </InputFlexWrapper>
          <InputFlexWrapper>
            <InputBoxForEdit
              maxChar={50}
              sizes="semiMiddle"
              inputTextType="text"
              importanceType="important"
              child="Last Name"
              fields="input"
              name="contactName"
              value={newReference.contactName}
              placeholder="POC Name"
              style={{ width: "340px", height : "40px" }}
              onHandleChange={e => {
                setNewReference({
                  ...newReference,
                  contactName: e.target.value.trim(),
                });
              }}
            />
          </InputFlexWrapper>
        </FlexBox>
        <FlexBox>
          <InputFlexWrapper>
            <InputBoxForEdit
              maxChar={256}
              sizes="semiMiddle"
              inputTextType="text"
              importanceType="important"
              child="Qualification"
              fields="input"
              name="clientEmail"
              value={newReference.clientEmail}
              placeholder="Email"
              style={{ width: "340px", height : "40px" }}
              onHandleChange={e => {
                setNewReference({
                  ...newReference,
                  clientEmail: e.target.value.trim(),
                });
              }}
            />
          </InputFlexWrapper>
          <InputFlexWrapper>
            <InputDivWrap>
              <InputWrap
                type="file"
                onChange={getFile}
                accept="application/pdf,image/jpeg"
              />
              <InputBoxForEdit
                sizes="middle"
                inputTextType="text"
                importanceType="important"
                child="Upload References (Optional)"
                fields="input"
                placeholder="Upload Documents"
                value={fileName}
                buttonDisable
                style={{
                  width: "205px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  height : "40px"
                }}
              />

              <Button
                onClick={() => {
                  // handleAddKey
                }}
                variant="blue"
                size="small"

                style={{
                  borderRadius: "0px 8px 8px 0px",
                  width: "60px",
                  marginTop: "0px",
                  position: "absolute",
                  right: "80px",
                  top: "0",
                  height: "40px",
                }}
              >
                <img src="/images/upload_white.svg" />
              </Button>
              <Button
                onClick={() => {
                  handleAddKey();
                }}
                disabled={
                  !(
                    newReference?.clientCompanyName?.length ||
                    newReference?.clientEmail?.length ||
                    newReference?.contactName?.length
                  )
                }
                variant="blue"
                size="small"
                style={{
                  borderRadius: "8px",
                  width: "60px",
                  marginTop: "0px",
                  position: "absolute",
                  right: "0",
                  top: "0",
                  height: "40px",
                }}
              >
                <img src="/images/plusBttn.svg" />
              </Button>
            </InputDivWrap>
            <WarningMsg>
              <Image src="/images/ProfileCreation/info.svg"></Image>
              Supports .pdf and .jpeg formats. Max. file size 20mb.
            </WarningMsg>
          </InputFlexWrapper>
        </FlexBox>
      </Wrapper>
    </>
  );
};

export default ReferencesContainer;
