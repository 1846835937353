import * as Yup from "yup";
import {
  IMachine,
  IPlantMachineDetail,
} from "venwiz-ui/dist/esm/components/organism/ManufCapabilitiesTable/ManufCapabilitiesTable.interface";

/**
 * for Add/Edit Plant Form via modal
 */

/** Measurement Unit Schema
 * - value can be null
 * - value and unit are optional & dependent on each other */
const measurementUnitSchema = Yup.object().shape(
  {
    value: Yup.number()
      .when("unit", (unit: string, schema: Yup.NumberSchema) => {
        return Boolean(unit?.length)
          ? schema.required("Value is required")
          : schema;
      })
      .nullable(true)
      .optional(),
    unit: Yup.string()
      .when("value", (value: number, schema: Yup.StringSchema) => {
        return value !== null && Boolean(String(value)?.length)
          ? schema.required("Unit is required")
          : schema;
      })
      .optional(),
  },
  [["value", "unit"]]
);

export const FORMIK_PLANT = {
  INITIAL_STATE: {
    location: {
      city: "",
      state: "",
    },
    totalArea: {
      value: null,
      unit: "",
    },
    workshopArea: {
      value: null,
      unit: "",
    },
    capacity: {
      value: null,
      unit: "",
    },
    otherInfo: "",
    machines: [],
  } as Partial<IPlantMachineDetail>,
  INITIAL_ERRORS: {
    location: {
      city: "",
      state: "",
    },
    totalArea: {
      value: null,
      unit: "",
    },
    workshopArea: {
      value: null,
      unit: "",
    },
    capacity: {
      value: null,
      unit: "",
    },
    otherInfo: "",
  },
  /** Add/Edit Plant Details Schema */
  SCHEMA: Yup.object({
    location: Yup.object({
      /** mandatory fields */
      city: Yup.string().required("City is required"),
      state: Yup.string(),
    }),

    /** optional fields */
    totalArea: measurementUnitSchema,
    workshopArea: measurementUnitSchema,
    capacity: measurementUnitSchema,
    otherInfo: Yup.string(),
  }),
};

/**
 * for Add/Edit Machine Form via modal
 */
export const FORMIK_MACHINE = {
  INITIAL_STATE: {
    process: "",
    name: "",
    number: null,
    modelName: "",
    make: "",
    otherInfo: "",
    specification: "",
    gallery: [],
  } as IMachine,
  INITIAL_ERRORS: {
    process: "",
    name: "",
  },

  /** Add/Edit Machine Details Schema */
  SCHEMA: Yup.object({
    /** mandatory fields */
    name: Yup.string().required("Machine name is required"),
    
    /** optional fields */
    process: Yup.string().optional(),
    number: Yup.number().nullable(true).optional(),
    modelName: Yup.string().optional(),
    make: Yup.string().optional(),
    otherInfo: Yup.string().optional(),
    specification: Yup.string().optional(),
  }),
};

/**
 * Machine Process and Name Relation dropdown options
 */

export const MACHINE_PROCESS_AND_NAME_RELATION = {
  /** "process_name": [ ...machine_names ] */
  Cutting: [
    "Plasma cutting machine(CNC)",
    "Plasma cutting machine(Non CNC)",
    "Laser cutting machine(CNC)",
    "Laser cutting machine(Non CNC)",
    "Waterjet cutting machine",
    "Shearing machine",
    "Band saws",
    "Profile cutting machine",
    "Power Hacksaw machine",
    "Planer/Shaper machine",
  ],
  "Bending/ Rolling": [
    "Press brake machine(CNC)",
    "Press brake machine(Non CNC)",
    "Tube and pipe bending machine",
    "Plate rolling machine",
    "Angle rolling machine",
  ],
  Welding: [
    "TIG/ Gas tungsten arc welding (GTAW) machines",
    "MIG/ Gas metal arc welding (GMAW) machines",
    "Shielded metal arc welding (SMAW) machine",
    "Flux-cored arc welding (FCAW) machine",
    "Spot welding machine",
    "Stud welding machine",
    "Submerged arc welding (SAW) machines",
    "Projection(Resistance) welding machine",
    "Column & Boom Welding Machine",
    "H beam welding machine",
  ],
  Drilling: [
    "Radial Drilling machine",
    "Magnetic Drilling machine",
    "Portable Drilling machine",
    "Hand drill machine",
    "Pillar drill machine",
  ],
  Machining: [
    "Lathe machine",
    "Milling machine",
    "Vertical Machining Center(VMC)",
    "Horizontal Machining Center(HMC)",
    "Grinding machine",
    "Boring machine",
    "Turning machine(CNC)",
    "Turning machine(Non CNC)",
    "Spinning machine",
    "Jig Boring machine",
    "EDM(Electrical Discharge Machining) machines",
  ],
  Stamping: [
    "Stamping Power Press",
    "Blanking Press",
    "Punching machine",
  ],
  Polishing: [
    "Shot Blasting facility",
    "Magnetic Deburring machine",
    "Vibratory Finishing machine",
    "Sandblasting facility",
    "Buffing machine",
  ],
  Other: ["Painting facility", "Powder Coating facility"],
  "Auxiliary/ Non process": [
    "EOT/ Crane",
    "Welding Rotators",
    "Forklift",
    "Chain Pulley Blocks",
    "Mobile Cranes",
  ],
} as const;

export const ALL_AVAILABLE_MACHINE_NAMES = Object.values(MACHINE_PROCESS_AND_NAME_RELATION)
  ?.flat()
  ?.sort((a, b) => a.localeCompare(b)) || [];

export const SORTED_MACHINE_PROCESS_NAMES =
  Object.keys(MACHINE_PROCESS_AND_NAME_RELATION).sort((a, b) =>
    a.localeCompare(b)
  ) || [];

/**
 * Plant Dropdown Options
 */
export const PLANT_DROPDOWN_OPTIONS = {
  AREA: [
    { value: "sqft", label: "sqft" },
    { value: "sqm", label: "sqm" },
    { value: "sqkm", label: "sqkm" },
    { value: "acres", label: "acres" },
    { value: "hectares", label: "hectares" },
    { value: "other", label: "other" },
  ],
  CAPACITY: [
    { value: "MT/month", label: "MT/month" },
    { value: "MT/year", label: "MT/year" },
    { value: "other", label: "other" },
  ],
};
