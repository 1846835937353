import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { userInfoGet } from "reducers/userInfo";
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { handleLogout } from "shared/handlers/logout.handler";
import { handleCancelRetainSearchKey } from "shared/handlers/requestHandlers";
import { getUserDetails } from "shared/handlers/getUserLoginStatus";
import { AvatarOptions } from "../../../constants/mockData";
import { LogoImage } from "../../Image";
import { Label } from "../../Label";
import { Avatar } from "../../Profile";
import { AvatarArrow, Div, HeaderWrapper, Line, LoginButton,NavAvatarWrapper, LogoDiv, LogoWrapper, OuterWrapper, PopOverContent, PopoverDiv } from "./Layout.styles";
import NavigationTab from "../NavigationTab/NavigationTab";
import { Popover } from "antd";
import { PageSpinner } from "components/Spinner/PageSpinner";

interface LayOutProps {
  title?: string;
  children: React.ReactNode;
}


const BasicLayOut = ({ title, children }: LayOutProps) => {
  const companyInfoState = useSelector((state: any) => state.companyInfo);
  const { companyInfoData} = companyInfoState;
  const [currentRoute, setCurrentRoute] = useState("/dashboard");
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData } = userInfoState;
  const isOnboardingRoute = window.location.href.includes("onboarding");
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state: any) => state.auth);
  const { authedUserData, isLoading } = authState;

  useEffect(() => {
    if (getUserDetails()) {
      dispatch(userInfoGet({})).then(res => {
        SessionStorageService.set("user", res);
      });
    }
  }, [LocalStorageService]);

  const handleClick = item => {
    if (item.cardName === "Logout") {
      handleLogout({ dispatch });
    }
    handleCancelRetainSearchKey();
  };

  const handleRoute = () => {
    if (!isOnboardingRoute) {
      history.push("/dashboard");
    }
    handleCancelRetainSearchKey();
  };

  const handleOnLogin = () => {
    history.push("/login");
  };

  const renderPopoverContent=(item)=>{
    return (<PopOverContent key={item.id}>
              {item.cardName}
            </PopOverContent>
          )
  }

  return (
    <OuterWrapper>
      <PageSpinner isLoading={isLoading}/>
      {/* <Header>
        <LogoWrapper>
          <LogoDiv>
            <LogoImage
                id="desktopImg"
                imageSrc="/images/DashBoard/venwiz-logo-white.svg"
                altMesg="logo"
                imageType="logo"
                onClick={handleRoute}
            />
              <LogoImage
                id="mobileImg"
                imageSrc="/images/DashBoard/venwiz-logo-white-text.svg"
                altMesg="logo"
                imageType="logo"
                onClick={handleRoute}
            />
          </LogoDiv>
        </LogoWrapper>        
        {getUserDetails() ? (
          <NavAvatarWrapper>
          <NavigationTab></NavigationTab>
          <PopoverDiv
            placement="bottomRight"
            getPopupContainer={trigger => trigger.parentElement}
            content={
              AvatarOptions.map(item => (
                <Link
                  onClick={() => handleClick(item)}
                  to={item.linkTo}
                  className={currentRoute === item.linkTo ? "active" : ""}
                >
                  {item?.checkAuthAccess ? (
                    !!authedUserData?.ProfileFlag?.isProfileCompleted && renderPopoverContent(item)
                  ):renderPopoverContent(item)}
                </Link>
              ))
            }
            trigger="click"
          >
            <Div>
              <Avatar
                data={userInfoData?.firstName}
                imgSrc={companyInfoData?.companyLogo?.fileUrl}
              />
              <Label
                text={""}
                htmlFor="avatar"
                fontVariant="bold"
                cursorType="pointer"
              />
              <AvatarArrow id="desktopImg" src="/images/DashBoard/arrow-down.svg" />
              <AvatarArrow id="mobileImg" src="/images/DashBoard/hamburger-icon.svg" />
            </Div>
          </PopoverDiv>
          </NavAvatarWrapper>
        ) : (
          <LoginButton type="primary" shape="round" onClick={handleOnLogin}>
            <img
              style={{ marginRight: "10px" }}
              src="/images/login-avatar.svg"
            />
            Login to Venwiz
          </LoginButton>
        )}
      </Header> */}
      {children}
    </OuterWrapper>
  );
};

export default BasicLayOut;
