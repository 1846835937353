import { ISortName, IHeaderNames } from "Request/requestList.types";
import moment from "moment";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";

export const REQUEST_STATUS: any = {
  job_request: "-",

  // for Plant Visit Scheduler - PVS
  new_update: "New Update",
  updated_by_you: "Updated by you",
  updated_by_client: "Updated by Client",

  // for JR
  INTERESTED: "Interested",
  NOT_INTERESTED: "Not Interested",
  NOT_VIEWED: "Not Viewed",
  "RFQ Updated": "RFQ Updated",
  "RFQ Removed": "RFQ Removed",
  "RFQ Received": "RFQ Received",
  "RFQ Sent": "RFQ Sent",
  "RFQ Created": "RFQ Created",
  "Quotation Sent": "Quotation Sent",
  "Quotation Received": "Quotation Received",
  "Job Request Received": "Job Request Received",
  "Finalizing Vendors": "Finalizing Vendors"
};

export enum REQUEST_TYPE {
  PLANT_VISIT = "Plant visit scheduler",
  JOB_REQUEST = "job_request",
  VENDOR_REQUEST = "vendorrequest",
  ERFX = "erfx"
}

export const REQUEST_LISTING_HEADERS: {
  name: IHeaderNames;
  key: ISortName;
  sortable: boolean;
  width: string;
  textAlign?: string; 
}[] = [
  { name: "Client Name", key: "clientName", sortable: true, width: "20%" },
  { name: "Request Type", key: "requestType", sortable: true, width: "20%" },
  { name: "Job Title", key: "", sortable: false, width: "23%" },
  { name: "Last Updated", key: "lastUpdate", sortable: true, width: "20%" },
  { name: "Status", key: "", sortable: false, width: "17%" },
  { name: "Pricing Policy", key: "", sortable: false, width: "30%" },
];

export enum OPEN_JOBS_COLUMN_HEADERS {
  JOB_ID = "Job ID",
  JOB_TITLE = "Job Title",
  JOB_DESCRIPTION = "Description",
  JOB_POSTED_DATE = "Posted Date",
  JOB_LOCATION = "Job Location",
};

export const OPEN_AND_RELEVANT_REQUESTS: {
  name: IHeaderNames;
  key: ISortName;
  sortable: boolean;
  width: string;
  textAlign?: string;
}[] = [
  { name: OPEN_JOBS_COLUMN_HEADERS.JOB_ID, 
    key: "requestId", 
    sortable: true, 
    width: "10%",
  },
  { name: OPEN_JOBS_COLUMN_HEADERS.JOB_TITLE,
    key: "title",
    sortable: true,
    width: "15%",
  },
  { name: OPEN_JOBS_COLUMN_HEADERS.JOB_DESCRIPTION,
    key: "description", 
    sortable: false, 
    width: "35%",
  },
  {
    name: OPEN_JOBS_COLUMN_HEADERS.JOB_POSTED_DATE,
    key: "requestedOn",
    sortable: true,
    width: "9%",
  },
  { name: OPEN_JOBS_COLUMN_HEADERS.JOB_LOCATION, 
    key: "location", 
    sortable: false, 
    width: "15%",
  },
];

export const PLANT_VISIT_REQUESTS: {
  name: IHeaderNames;
  key: ISortName;
  sortable: boolean;
  width: string;
  textAlign?: string;
}[] = [
  {
    name: "Client Company Name",
    key: "clientCompanyName",
    sortable: false,
    width: "20%",
  },
  { name: "Location", key: "location", sortable: false, width: "20%" },
  {
    name: "Slots",
    key: "slots",
    sortable: false,
    width: "20%",
  },
  { name: "Status", key: "status", sortable: false, width: "20%" },
];

export enum VENDOR_SUGGESTED_BY_KEY {
  admin = "8C697",
}

export const JOB_REQUEST_VERSION_1 = "v1";

export const FORM_DATA_KEY = "files[]";

export const QUOTATION_FILES_SUPPORTED_TYPES: Array<string> = [
  "dwg",
  "vnd",
  "dwg",
  "autocad_dwg",
  "jpeg",
  "jpg",
  "png",
  "tiff",
  "bmp",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "txt",
  "pdf",
  "zip",
  "rar",
  "stp",
  "csv",
];

export const GENERATE_ZIP_FILE_NAME = (jobRequestId: string) => {
  return moment().format(`[RFQ_${jobRequestId}]_DDMMYYYY_hhmmss`);
};

export const ACTIVITYLOGSTYPE = {
  FUPLOAD: "FUpload",
  FDOWNLOAD: "FDownload",
  FDELETE: "FDelete",
  FRFQ_PUBLISHED: "FRPublished",
  FRFQ_RE_PUBLISHED: "FRrePublished",
  FQUOTE_SUBMIT: "FQsubmit",
  FQUOTE_RE_SUBMIT: "FQresubmit",
};
export enum JOB_STATUS {
  "In scope discussion" = "In scope discussion",
  "Accepting quotes" = "Accepting quotes",
  "Quotes under review" = "Quotes under review",
  "Job awarded" = "Job awarded",
  "Job on hold" = "Job on hold",
  "Inactive job" = "Inactive job",
}

export enum VENDOR_REQUEST_STATUS {
  "InProgress" = "InProgress",
  "Pending" = "Pending",
  "Published" = "Published",
}

export const FILE_MORE_DROPDOWN_LABELS = {
  DOWNLOAD: "Download",
  REMOVE_FILE: "Remove File",
  FILE_HISTORY: "File History",
  EDIT_DESCRIPTION: "Edit Description",
  UPLOAD_NEW_VESION: "Upload New Version",
  EDIT_FILE_NAME: "Edit File Name",
};

export const QUOTATION_MORE_DROPDOWN_JOB_ADMIN = [
  {
    label: FILE_MORE_DROPDOWN_LABELS.DOWNLOAD,
    onClick: () => {},
    visible: true,
    heapTrackingId: HEAP_DATA_ATTRIBUTES.JOB_REQUEST_DETAILS_PAGE.DOWNLOAD_QUOTATION_FILE,
  },
  {
    label: FILE_MORE_DROPDOWN_LABELS.UPLOAD_NEW_VESION,
    onClick: () => {},
    visible: true,
    heapTrackingId: HEAP_DATA_ATTRIBUTES.JOB_REQUEST_DETAILS_PAGE.UPLOAD_NEW_FILE_VERSION_CTA,
  },
  //Required in future //
  // { label: FILE_MORE_DROPDOWN_LABELS.EDIT_DESCRIPTION, onClick: () => {}, visible: true },
  // { label: FILE_MORE_DROPDOWN_LABELS.EDIT_FILE_NAME, onClick: () => {}, visible: true },
  {
    label: FILE_MORE_DROPDOWN_LABELS.REMOVE_FILE,
    onClick: () => {},
    visible: true,
    heapTrackingId: HEAP_DATA_ATTRIBUTES.JOB_REQUEST_DETAILS_PAGE.REMOVE_FILE_CTA,
  },
  {
    label: FILE_MORE_DROPDOWN_LABELS.FILE_HISTORY,
    onClick: () => {},
    visible: true,
    heapTrackingId: HEAP_DATA_ATTRIBUTES.JOB_REQUEST_DETAILS_PAGE.FILE_HISTORY_CTA,
  },
];

export const QUOTATION_MORE_DROPDOWN_LABELS = {
  DOWNLOAD: "Download",
  REMOVE_FILE: "Remove File",
  FILE_HISTORY: "File History",
  EDIT_DESCRIPTION: "Edit Description",
  UPLOAD_NEW_VESION: "Upload New Version",
  EDIT_FILE_NAME: "Edit File Name",
  UPDATE_FILE_HISTORY: "Update File History",
  SWITCH_PUBLISH_FILE: "Switch Publish File",
  RESTORE_FILE_CHECK: "Restore File Check",
};

export const QUOTATION_CONFIRM_UPDATE_POPUP_NAMES = [
  QUOTATION_MORE_DROPDOWN_LABELS.REMOVE_FILE,
  QUOTATION_MORE_DROPDOWN_LABELS.UPLOAD_NEW_VESION,
  QUOTATION_MORE_DROPDOWN_LABELS.SWITCH_PUBLISH_FILE,
  QUOTATION_MORE_DROPDOWN_LABELS.RESTORE_FILE_CHECK,

];

export const QUOTATION_UPDATE_POP_MODAL_STATE = {
  modalType: {
    label: "",
  },
  data: {
    fileName: "",
    files: [],
    isDeleted: false,
    activeVersion: 0,
    latestVersion: 0,
    finalVersion: 0,
    isFinal: false,
    version: 0,
    editFileName: "",
    publishVersion: 0,
    newUpdate: false,
  },
  apiCallOnAccept: false,
};

export const QUOTATION_MODAL_STATE = {
  removeModal: {
    isOpen: false,
    fileName: "",
    isAutoPublish: false,
    isFinal: false,
  },
  fileHistoryModal: {
    isOpen: false,
    files: [],
    activeVersion: 0,
    createdBy: "",
    fileName: "",
    isFinal: false,
    isPublished: false,
    isAutoPublish: false,
  },
  editDescOrNameModal: {
    isOpen: false,
    data: { desc: "", fileName: "", editFileName: "" },
    editType: "",
  },
};

export const QUOTATIONS_LISTING_COLUMN_HEADERS = {
  fileName: { headerName: "File Name", width: "20%" },
  version: { headerName: "Version", width: "15%" },
  quoteType: { headerName: "Type of quote", width: "20%" },
  lastUpdated: { headerName: "Last Updated", width: "15%" },
  description: { headerName: "Description", width: "25%" },
  menu: { headerName: "", width: "5%" },
};

export const ACTIVITYLOG_ACTION_FILE_TYPE = {
  RFQ: "RFQ",
  QUOTATIONS: "Quotations",
};

export const QUOTATION_TYPE = {
  TECHNICAL: 'technical',
  COMMERCIAL: 'commercial'
}

export const PREVIOUSLY_SUBMITTED_QUOTATIONS_VISBIBLE_ON_CIENT_SIDE = "Previously submitted quotes are still visible to the client, please ensure that you click on Submit Quotation button."

export const FILE_STATUS = {
  MARK_AS_FINAL: {
    label: "Mark as Final",
    color: "#215EC9",
    isLink: true,
    disabledColor: "#B3B3B3",
    backgroundColor: "#F4F6FF",
  },
  READY_TO_SUBMIT: {
    label: "Ready to submit",
    color: "#011759",
    isLink: false,
    borderColor: "#D4E5FE",
  },
  SUBMITTED: {
    label: "Submitted",
    color: "#011759",
    isLink: false,
    borderColor: "#D4E5FE",
  },
};

export const FILE_STATUS_TOOLTIP_MOBILE= "Files marked as final will be submitted as a part of quotations."
export const FILE_STATUS_TOOLTIP= `${FILE_STATUS_TOOLTIP_MOBILE} You can still update from the menu on the right.`

export const SUBMIT_BUTTON_TOOLTIP_PRIOR_UPLOAD = "Add & finalise at least one file to enable submission";

export const SUBMIT_BUTTON_TOOLTIP_PRIOR_ACCEPTING_QUOTES = "Waiting for client to start accepting quotes. You can track the status on top right section of the job.";

export const VENDOR_REMOVED_BY_CLIENT = "Client has removed you from the job";

export const VENDOR_REMOVED_LISTING_STATUS = "Removed from the Job";

export enum INT_RESP_VIA_EMAIL {
  INTERESTED = "true",
  NOT_INTERESTED = "false",
  NEED_MORE_INFO = "info"
}

export const CLIENT_NOT_ACCEPTING_QUOTE = "Client is not accepting quotations";
export const PQR_NOT_SUBMITTED = "Complete Self Evaluation (PQR)";

export const ADD_FILES_FOR_SUBMISSION = "Add files to enable submission"

export const DOC_UPLOAD_AUTO_PUBLISH_TEXT =
  "Submit the uploaded quotations (available for view/download by the client)";
export const DOC_UPDATE_AUTO_PUBLISH_TEXT = "Submit changes (available for view/download by the client)";

export const NOT_INTERESTED_REASONS = [
  {label:"Not capable to carry out the job", value:"NOT_CAPABLE"},
  {label: "Did not understand the scope of work", value:"UNDERSTANDING_ISSUE"},
  {label: "Not available", value:" NOT_AVAILABLE"},
]

export const QUOTE_SUBMISSION_THANK_YOU_MSG ="Thank you for submitting the quotation, we will get back to you shortly. To assist us in the process, we need to understand the relevance of your application.";
export const PAST_EXPR_PO_SUBMISSION_THANK_YOU_MSG ="Thank you for submitting the past Experience/PO docs, we will get back to you shortly. To assist us in the process, we need to understand the relevance of your application.";

export const JOB_FLOW ={
  VENDOR_REPONSE: "vendor_response",
  QUOTATION:"quotation",
  PQR:"pqr",
  PRICING_POLICY:"pricing_policy"
}

