import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { AppConfig } from "ui-constants";
import { clearAuthStore, logOut } from "reducers/auth";
import { resetProfileCompletionStatus } from "reducers/profileCreation";

interface IHandleLogoutProps {
  dispatch: any;
}

export const handleLogout = ({ dispatch }: IHandleLogoutProps) => {
  dispatch(logOut());
  resetProfileCompletionStatus(dispatch);
  dispatch(clearAuthStore());
  // clearing storage data at logout
  LocalStorageService.removeAll();
  SessionStorageService.removeAll();
  LocalStorageService.set(AppConfig.STORAGE_KEYS.applicationFlags, {
    showDemoVideo: false,
  });
  LocalStorageService.set(AppConfig.STORAGE_KEYS.mandatoryFieldPopup, {
    showPopup: true,
  });
  window.location.assign("/login");
};