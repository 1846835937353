export const VENDORS_ESCROW_INFO_VERIFY_TOKEN_REQUESTED = "goto-vendors/VENDORS_ESCROW_INFO_VERIFY_TOKEN_REQUESTED";
export const VENDORS_ESCROW_INFO_VERIFY_TOKEN_SUCCESS = "goto-vendors/VENDORS_ESCROW_INFO_VERIFY_TOKEN_SUCCESS";
export const VENDORS_ESCROW_INFO_VERIFY_TOKEN_FAILURE = "goto-vendors/VENDORS_ESCROW_INFO_VERIFY_TOKEN_FAILURE";

export const initialState = {
  loading: false,
  loaded: false,
  error: "",
  data: {},
  verificationStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VENDORS_ESCROW_INFO_VERIFY_TOKEN_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case VENDORS_ESCROW_INFO_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        verificationStatus: "VERIFIED",
      };

    case VENDORS_ESCROW_INFO_VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        verificationStatus: "NOT_VERIFIED",
      };

    default:
      return state;
  }
};

/**
 * 
 * @param {{ token: string }} data 
 */
export const verifyVendorsEscrowInfoToken = data => ({
  types: [
    VENDORS_ESCROW_INFO_VERIFY_TOKEN_REQUESTED,
    VENDORS_ESCROW_INFO_VERIFY_TOKEN_SUCCESS,
    VENDORS_ESCROW_INFO_VERIFY_TOKEN_FAILURE
  ],
  promise: client => client.post("verify_vendors_escrow_info_vendor_agreement_token", { data }),
});
