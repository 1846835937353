import React, { useState, useEffect, useRef } from "react";
import { Box, Card, Flex, Modal, Typography, InputWithIcon, Button } from "venwiz-ui";
import { colors } from "Theme";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { LargeImageAndVideoFlexContainer, GalleryThumbnailsFlexContainer, CaptionInputFieldContainerBox } from "./GalleryModal.styles";
import { MdEdit } from "react-icons/md";
import AntdToast from "components/Toast/AntdToast";
import { capabilitiesUpdateCaptionPost, capabilitiesUpdateCaptionState } from "reducers/capabilities";
import { useDispatch } from "react-redux";
import { FaRegEye } from "react-icons/fa";
import { ISelectedTabType, IGalleryModalProps} from "./GalleryModal.interfaces";
import { getYoutubeThumbnailFromUrl } from "shared/handlers/youtube.handler";

const GalleryModal = (props: IGalleryModalProps) => {
  const {
    gstn,
    sowId,
    galleryData,
    currentIndex,
    setCurrentIndex,
    viewGalleryModal,
    isEditableGallery,
    setViewGalleryModal,
    isCurrentCaptionBeingEdited,
    showPulsingCaptionInputFieldBorder,
    setShowPulsingCaptionInputFieldBorder
  } = props;

  const dispatch = useDispatch();
  const MOBILE_VIEW = window.innerWidth < 768;
  const [currentCaption, setCurrentCaption] = useState("");
  const thumbnailItemRef = useRef<HTMLDivElement|null>(null);
  const refForCaptionInputField = useRef<HTMLInputElement>(null);
  const [imageVideoData, setImageVideoData] = useState(galleryData);
  const [disableNavigationButtons, setDisableNavigationButtons] = useState(false);
  const [selectedTab, setSelectedTab] = useState<ISelectedTabType>("All Images");
  const GALLERY_ITEMS = {
    ALL: galleryData || [],
    PRODUCT: galleryData?.filter((item) => item?.category && (item?.category as string)?.toLowerCase()?.includes("product")) || [],
    FACTORY: galleryData?.filter((item) => item?.category && (item?.category as string)?.toLowerCase()?.includes("factory")) || [],
    OTHER: galleryData?.filter((item) => item?.category && (item?.category as string)?.toLowerCase()?.includes("other")) || [],
  };

  /** Change the tab content based on the selected tab */
  useEffect(() => {
    if (galleryData?.length > 0) {

      switch (selectedTab) {
        case "All Images":
          setImageVideoData(GALLERY_ITEMS.ALL);
          break;

        case "Products":
          setImageVideoData(GALLERY_ITEMS.PRODUCT);
          break;
          
        case "Factory":
          setImageVideoData(GALLERY_ITEMS.FACTORY);
          break;

        case "Other":
          setImageVideoData(GALLERY_ITEMS.OTHER);
          break;

        default:
          break;
      };
    }
  }, [selectedTab, galleryData]);

  /** Set the current caption based on the current index */
  useEffect(() => {
    setCurrentCaption(imageVideoData?.[currentIndex]?.caption || "");
  }, [currentIndex, imageVideoData])

  /** Handle Auto Horizontal Scroll for GalleryThumbnailsFlexContainer */
  useEffect(() => {
    if (galleryData?.length > 0 && thumbnailItemRef?.current) {
      thumbnailItemRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [selectedTab, currentIndex]);

  /** close caption edit when user changes the category-tab */
  useEffect(() => {
    toggleEditCaption(false);
  }, [selectedTab]);

  /** Show Pulsing Border when user clicks on the input field */
  useEffect(() => {
    if (isCurrentCaptionBeingEdited && refForCaptionInputField?.current) {
      refForCaptionInputField.current.focus();
    }
  }, [isCurrentCaptionBeingEdited]);

  const renderTabBarButton = ({ 
    onClick, 
    children,
    isSelected
  }: { 
    isSelected: boolean,
    onClick: () => void, 
    children: React.ReactNode,
  }) => {
    return (
      <Box
        cursor={"pointer"}
        fontSize={"14px"}
        onClick={onClick}
        fontWeight={isSelected ? 600 : 400}
        color={isSelected ? colors.blue : colors.gray}
        borderBottom={isSelected ? `2px solid ${colors.blue}` : "none"}
      >
        {children}
      </Box>
    );
  };

  /** This function is used to show the pulsing border around the caption input field */
  const handleTogglePulsingCaptionInputFieldForGallery = (flag: boolean) => {
    if (setShowPulsingCaptionInputFieldBorder) setShowPulsingCaptionInputFieldBorder(flag);
  };

  const handleSetIsCurrentCaptionBeingEdited = (flag: boolean) => {
    if (props?.setIsCurrentCaptionBeingEdited) props?.setIsCurrentCaptionBeingEdited(flag);
  };

  const toggleEditCaption = (flag: boolean) => {
    handleSetIsCurrentCaptionBeingEdited(flag);
    setDisableNavigationButtons(flag);
  };

  const handleChangeActiveMediaItem = (TYPE: "NEXT" | "PREVIOUS") => {
    handleSetIsCurrentCaptionBeingEdited(false);
    handleTogglePulsingCaptionInputFieldForGallery(false);
    if (TYPE === "NEXT") {
      setCurrentIndex(currentIndex + 1);
    } else if (TYPE === "PREVIOUS") {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handlePOSTAPIForCaptionUpdate = async (updateCaptionPayload) => {
    dispatch(capabilitiesUpdateCaptionPost(updateCaptionPayload)).then((res) => {
      if (res?.data?.statusCode !== 400) {
        AntdToast({
          type: "success",
          message: `Success`,
          description: `Caption Updated Successfully`,
        });
      } else {
        AntdToast({
          type: "error",
          message: `Error`,
          description: `Unable to Update Caption`,
        });
        setCurrentCaption(imageVideoData?.[currentIndex]?.caption || "");
      }
    }).catch((err) => {
      AntdToast({
        type: "error",
        message: `Error`,
        description: `Unable to Update Caption`,
      });
      setCurrentCaption(imageVideoData?.[currentIndex]?.caption || "");
    });
  };


  const handleUpdateCaption = ({ TYPE, galleryData, UNIQUE_FILE_URL } : {
    TYPE: "IMAGE" | "VIDEO",
    UNIQUE_FILE_URL: string,
    galleryData: { imageName?: string, file?: { fileUrl: string }, videoUrl?: string }[],
  }) => {

    const prepareGalleryPayload = (imagesOrVideos: { 
      videoUrl?: string,
      isNotPresentInDB?: boolean,
      file?: { fileUrl: string }, 
    }[]) => {
      /** remove the items which are only present in UI State and not in DB */
      const imagesOrVideosPresentInDB = imagesOrVideos?.filter((existingItem) => {
        return existingItem?.isNotPresentInDB !== true;
      });
      return imagesOrVideosPresentInDB?.map((existingItem) => {
        return existingItem?.file?.fileUrl === UNIQUE_FILE_URL || existingItem?.videoUrl === UNIQUE_FILE_URL
          // inject the new caption
          ? { ...existingItem, caption: currentCaption }
          : existingItem;
      });
    };

    if (TYPE === "IMAGE") {
      const gallery = prepareGalleryPayload(
        galleryData.filter((mediaItem) => mediaItem?.imageName)
      );
      if (gstn && sowId && gallery?.length) {
        const IMAGE_CAPTION_PAYLOAD = { gstn, sowId, gallery }
        handlePOSTAPIForCaptionUpdate(IMAGE_CAPTION_PAYLOAD);
      }
    } else if (TYPE === "VIDEO") {
      const companyVideo = prepareGalleryPayload(
        galleryData.filter((mediaItem) => !mediaItem?.imageName)
      );
      if (gstn && companyVideo?.length) {
        const VIDEO_CAPTION_PAYLOAD = { gstn, sowId, companyVideo }
        handlePOSTAPIForCaptionUpdate(VIDEO_CAPTION_PAYLOAD);
      }
    }
  };

  const handleOnClickSaveCaption = () => {
    toggleEditCaption(false);
    const IS_IMAGE = imageVideoData?.[currentIndex]?.imageName;
    const IS_MEDIA_PRESENT_IN_DB = !imageVideoData?.[currentIndex]?.isNotPresentInDB;
    const UNIQUE_FILE_URL = IS_IMAGE 
      ? imageVideoData?.[currentIndex]?.file?.fileUrl
      : imageVideoData?.[currentIndex]?.videoUrl || imageVideoData?.[currentIndex]?.file?.fileUrl || "";

    if (IS_MEDIA_PRESENT_IN_DB) {
      if (galleryData?.length) {
        /** Make POST API Call for the item present in DB */
        handleUpdateCaption({
          galleryData,
          UNIQUE_FILE_URL,
          TYPE: IS_IMAGE ? "IMAGE" : "VIDEO",
        });
      }
    } else {
      const CURRENT_MEDIA_ITEM = imageVideoData?.[currentIndex];
      const galleryImages = galleryData?.filter((mediaItem) => mediaItem?.imageName);
      const galleryVideos = galleryData?.filter((mediaItem) => !mediaItem?.imageName);
      /** Update State for the item that is only present in redux state */
      if (IS_IMAGE) {
        dispatch(capabilitiesUpdateCaptionState({
          type: "IMAGE",
          galleryData: galleryImages
            ?.map(imgObj => imgObj?.file?.fileUrl === UNIQUE_FILE_URL 
              ? { ...CURRENT_MEDIA_ITEM, caption: currentCaption } 
              : imgObj
            )?.concat(galleryVideos),
          })
        );
        setCurrentCaption(imageVideoData?.[currentIndex]?.caption || "");
      } else {
        // is_video
        dispatch(capabilitiesUpdateCaptionState({
          type: "VIDEO",
          galleryData: galleryData?.map(videoObj => {
            const EXISTING_VIDEO_FILE_URL = videoObj?.file?.fileUrl || videoObj?.videoUrl || ""
            return EXISTING_VIDEO_FILE_URL === UNIQUE_FILE_URL
              ? { ...CURRENT_MEDIA_ITEM, caption: currentCaption }
              : videoObj;
          }),
        }));
      }
      AntdToast({
        type: "info",
        message: `Note`,
        description: `Please click "Save Changes" button to save the ${IS_IMAGE ? "Image" : "Video"} & caption`,
      });
    }
  };

  return (
    <Modal
      overflow={"hidden"}
      maxHeight={"700px"}
      position={"relative"}
      show={viewGalleryModal}
      minHeight={{ default: "70vh" }}
      backgroundStyles={{ zIndex: 1000 }}
      width={{ sm: "70vw !important", default: "100vw !important" }}
      handleClose={() => {
        setViewGalleryModal(false);
        setSelectedTab("All Images");
        setCurrentIndex(0);
        handleSetIsCurrentCaptionBeingEdited(false);
        handleTogglePulsingCaptionInputFieldForGallery(false);
      }}
      closeIcon={
        <img src={"/images/ClientVendorView/close-icon.svg"} alt="close-icon" />
      }
      modalHeader={props?.galleryHeader || (
        <Box
          pl={"0"}
          pr={"0"}
          display={"flex"}
          fontWeight={600}
          fontSize={"20px"}
          color={"#011759"}
          position={"relative"}
          justifyContent={"center"}
          borderBottom={"2px solid #F4F9FF"}
        >
          {"Gallery"}
        </Box>
      )}
      modalBody={
        <>
          <Flex flexDirection="column">
            {/* Tab Bar */}
            {!props?.hideTabBar && (
              <Card
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                gap={{ default: "18px", xs: "30px" }}
              >
                {renderTabBarButton({
                  children: "All",
                  isSelected: selectedTab === "All Images",
                  onClick: () => {
                    setCurrentIndex(0);
                    setSelectedTab("All Images");
                  },
                })}
                {GALLERY_ITEMS.PRODUCT?.length > 0 && (
                  renderTabBarButton({
                    children: "Products",
                    isSelected: selectedTab === "Products",
                    onClick: () => {
                      setCurrentIndex(0);
                      setSelectedTab("Products");
                    },
                  })
                )}
                {GALLERY_ITEMS.FACTORY?.length > 0 && (
                  renderTabBarButton({
                    children: "Factory",
                    isSelected: selectedTab === "Factory",
                    onClick: () => {
                      setCurrentIndex(0);
                      setSelectedTab("Factory");
                    },
                  })
                )}
                {GALLERY_ITEMS.OTHER?.length > 0 && (
                  renderTabBarButton({
                    children: "Others",
                    isSelected: selectedTab === "Other",
                    onClick: () => {
                      setCurrentIndex(0);
                      setSelectedTab("Other");
                    },
                  })
                )}
              </Card>
            )}
            {/* Tab Content */}
            <Flex
              maxWidth={"100vw"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {/* Navigation Button - Desktop View */}
              {!MOBILE_VIEW && (
                <>
                  {currentIndex == 0 ? (
                    <Box marginRight={"16px"} cursor={"no-drop"}>
                      <img
                        src={"/images/ClientVendorView/previous.svg"}
                        alt="previous-icon"
                      />
                    </Box>
                  ) : (
                    <Box
                      cursor={ disableNavigationButtons ? "no-drop" : "pointer"}
                      marginRight={"16px"}
                      onClick={() => {
                        if (!disableNavigationButtons) handleChangeActiveMediaItem("PREVIOUS");
                      }}
                    >
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={"/images/ClientVendorView/right-arrow-icon.svg"}
                        alt="right-arrow-icon"
                      />
                    </Box>
                  )}
                </>
              )}
              {/* Show Big Image or Video */}
              <LargeImageAndVideoFlexContainer
                height={{ default: "50%", sm: "400px" }}
              >
              {imageVideoData?.[currentIndex]?.imageName ? (
                <Flex
                  width={"100%"}
                  height={"100%"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  position={"relative"}
                >
                  <img
                    width={"100%"}
                    height={"100%"}
                    src={imageVideoData?.[currentIndex]?.file?.fileUrl}
                    alt="file-url"
                    style={{
                      objectFit: "contain",
                      aspectRatio: "16/9",
                    }}
                  />
                  {/* Mobile View - Eye Button */}
                  <Flex
                    right={"0"}
                    bottom={"0"}
                    padding={"4px"}
                    margin={"6px"}
                    alignItems={"center"}
                    position={"absolute"}
                    borderRadius={"100%"}
                    justifyContent={"center"}
                    backgroundColor={"rgba(0, 0, 0, 0.4)"}
                    display={{ default: "flex", sm: "none" }}
                  >
                    <a 
                      target="_blank"
                      rel="noreferrer"
                      href={imageVideoData?.[currentIndex]?.file?.fileUrl}
                      style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                    >
                      <FaRegEye color={colors.white} fontSize={"24px"} />
                    </a>
                  </Flex>
                </Flex>
              ) : (
                <>
                  {/* //check if video is youtube or not */}
                  {imageVideoData?.[currentIndex]?.videoUrl?.includes("you") ? (
                    <iframe
                      style={{
                        margin: "0",
                        padding: "0",
                        width: "100%",
                        height: "100%",
                        aspectRatio: "16/9",
                      }}
                      allowFullScreen
                      src={
                        //convert youtube url to embed url and remove values after & symbol
                        //also convert https://youtu.be/yRlXr-pBybs these type of url to embed url
                        //if url is already embed url then it will not change
                        //if youtube.com with watch
                        imageVideoData[currentIndex]?.videoUrl?.includes(
                          "youtu.be"
                        )
                          ? imageVideoData[currentIndex]?.videoUrl?.replace(
                              "youtu.be",
                              "youtube.com/embed"
                            )
                          : imageVideoData[currentIndex]?.videoUrl?.includes(
                              "watch"
                            )
                          ? imageVideoData[currentIndex]?.videoUrl
                              ?.replace("watch?v=", "embed/")
                              .split("&")[0]
                          : imageVideoData[currentIndex]?.videoUrl
                      }
                    />
                  ) : (
                    <video
                      width={"100%"}
                      height={"100%"}
                      poster={"/images/video-thumbnail.png"}
                      src={imageVideoData?.[currentIndex]?.file?.fileUrl || ""}
                      controls
                    />
                  )}
                </>
              )}
              </LargeImageAndVideoFlexContainer>
              {/* Navigation Button - Desktop View */}
              {!MOBILE_VIEW && (
                <>
                  {currentIndex == imageVideoData.length - 1 ? (
                    <Box marginLeft={"16px"} cursor={"no-drop"}>
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={"/images/ClientVendorView/previous.svg"}
                        alt="preivous-icon"
                      />
                    </Box>
                  ) : (
                    <Box
                      marginLeft={"16px"}
                      cursor={disableNavigationButtons ? "no-drop" : "pointer"}
                      onClick={() => {
                        if (!disableNavigationButtons) handleChangeActiveMediaItem("NEXT");
                      }}
                    >
                      <img
                        src={"/images/ClientVendorView/right-arrow-icon.svg"}
                        alt="right-arrow-icon"
                      />
                    </Box>
                  )}
                </>
              )}
            </Flex>
            {/* Container for clickable components like: 
              * edit/save button and gallery thumbnails */}
            <Flex
              gap={"8px"}
              width={"100%"}
              marginTop={"16px"}
              alignSelf={"flex-end"}
              justifySelf={"flex-end"}
              flexDirection={"column"}
            >
              {isCurrentCaptionBeingEdited ? (
                <CaptionInputFieldContainerBox 
                  width={"80%"} 
                  margin={"0 auto"}
                  position={"relative"} 
                  $pulsingBorder={showPulsingCaptionInputFieldBorder}
                >
                  <InputWithIcon
                    inputVariant={"input"}
                    {...{
                      inputProps: {
                        ref: refForCaptionInputField,
                        width: "100%",
                        height: "60px",
                        paddingRight: "6px",
                        color: colors.darkBlue,
                        value: currentCaption || "",
                        id: `add-or-edit-text-input-field-${currentIndex}`,
                        name: `add-or-edit-text-input-field-${currentIndex}`,
                        placeholder: `${currentCaption?.length > 0 ? "Edit" : "Add"} Caption`,
                        onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                          setCurrentCaption(e?.target?.value || "");
                        },
                      },
                    }}
                    inputContainerProps={{
                      gap: "6px",
                      display: "flex",
                      alignItems: "center",
                      padding: "2px 6px 2px 4px",
                      backgroundColor: "#ffffff",
                      justifyContent: "space-between",
                      /**class handles pulsing animation */
                      className: "caption-input-field-container",
                    }}
                    rightIcon={
                      <Flex gap={"8px"} alignItems={"center"} paddingRight={"8px"}>
                        <AiOutlineCheck 
                          fontSize={"20px"}
                          color={colors.green}
                          onClick={handleOnClickSaveCaption}
                        />
                        <AiOutlineClose
                          fontSize={"18px"}
                          color={"#66739b"}
                          onClick={() => { 
                            toggleEditCaption(false);
                            setCurrentCaption(imageVideoData?.[currentIndex]?.caption || "");
                          }}
                        />
                      </Flex>
                    }
                  />
                </CaptionInputFieldContainerBox>
              ) : (
                <Flex
                  alignItems={"center"}
                  justifyContent={"center"}
                  width={"100%"}
                >
                  <Typography
                    textAlign={"center"}
                    variant={"span"}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant={"span"}
                      fontWeight={"400"}
                      paddingRight={"8px"}
                      color={colors.darkBlue}
                      fontSize={{ default: "12px", sm: "14px" }}
                      >
                      {currentCaption || ""}
                    </Typography>
                    {isEditableGallery && galleryData?.length > 0 && (
                      <Flex
                        alignItems={"center"}
                        cursor={"pointer"}
                        onClick={() => {
                          toggleEditCaption(true);
                          handleTogglePulsingCaptionInputFieldForGallery(false);
                        }}
                      >
                        {currentCaption ? (
                          <MdEdit color={colors.blue} fontSize={"18px"} />
                        ) : (
                          <Button
                            variant={"primary"}
                            colorSchema={"blue"}
                            height={"22px"}
                            style={{
                              padding: "0 6px",
                              cursor: "pointer",
                              fontSize: MOBILE_VIEW ? "12px" : "14px",
                            }}
                          >
                            Add Caption
                          </Button>
                        )}
                      </Flex>
                    )}
                  </Typography>
                </Flex>
              )}
              {/* Navigation Buttons - Mobile View */}
              {MOBILE_VIEW && (
                <Flex justifyContent="center">
                  {currentIndex == 0 ? (
                    <Box marginRight={"16px"} cursor={"no-drop"}>
                      <img
                        src={"/images/ClientVendorView/previous.svg"}
                        alt="disabled-previous-icon"
                      />
                    </Box>
                  ) : (
                    <Box
                      marginRight={"16px"}
                      cursor={disableNavigationButtons ? "no-drop" : "pointer"}
                      onClick={() => {
                        if (!disableNavigationButtons) handleChangeActiveMediaItem("PREVIOUS");
                      }}
                    >
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={"/images/ClientVendorView/right-arrow-icon.svg"}
                        alt="previous-arrow-icon"
                      />
                    </Box>
                  )}
                  {currentIndex == imageVideoData?.length - 1 ? (
                    <Box marginLeft={"16px"} cursor={"no-drop"}>
                      <img
                        style={{ transform: "rotate(180deg)" }}
                        src={"/images/ClientVendorView/previous.svg"}
                        alt="disabled-right-icon"
                      />
                    </Box>
                  ) : (
                    <Box
                      marginLeft={"16px"}
                      cursor={disableNavigationButtons ? "no-drop" : "pointer"}
                      onClick={() => {
                        if (!disableNavigationButtons) handleChangeActiveMediaItem("NEXT");
                      }}
                    >
                      <img
                        src={"/images/ClientVendorView/right-arrow-icon.svg"}
                        alt="right-arrow-icon"
                      />
                    </Box>
                  )}
                </Flex>
              )}
              {/* Show All Images/Video thumbnail preview */}
              <GalleryThumbnailsFlexContainer
                overflowX={"auto"}
                paddingTop={{ default: "2px", xs: "6px"}}
                paddingBottom={"4px"}
                alignContent={"center"}
                justifyContent={"flex-start"}
                borderTop={"1px solid #EFEFEF"}
                maxWidth={{
                  sm: "70vw !important",
                  default: "100vw !important",
                }}
              >
                {imageVideoData?.map((item, index) => {
                  const IS_CURRENT_IETM_SELECTED = currentIndex === index;
                  const MEDIA_URL = item?.file?.fileUrl || item?.videoUrl || "";
                  const IS_YOUTUBE_URL = MEDIA_URL?.includes("you");
                  const VIDEO_THUMBNAIL_IMG_SRC = IS_YOUTUBE_URL
                    ? getYoutubeThumbnailFromUrl(MEDIA_URL, 0)
                    : "/images/video-thumbnail.png";

                  return (
                    //show a border around the current image
                    <Flex
                      ref={IS_CURRENT_IETM_SELECTED ? thumbnailItemRef : null}
                      key={index}
                      alignItems={"center"}
                      justifyContent={"center"}
                      marginRight={"16px"}
                      position={"relative"}
                      padding={"4px"}
                      borderRadius={"8px"}
                      onClick={() => {
                        setCurrentIndex(index)
                        handleSetIsCurrentCaptionBeingEdited(false);
                      }}
                      background={
                        IS_CURRENT_IETM_SELECTED
                          ? "linear-gradient(180deg, #215EC9 0%, #47C882 100%)"
                          : "2px solid #F4F9FF"
                      }
                    >
                      {item?.imageName ? (
                        <img
                          width={"auto"}
                          height={"60px"}
                          src={item?.file?.fileUrl}
                          alt={"image-preview"}
                          style={{
                            background: "white",
                            borderRadius: IS_CURRENT_IETM_SELECTED ? "8px" : "",
                          }}
                        />
                      ) : (
                        <video
                          width={"auto"}
                          height={"60px"}
                          poster={VIDEO_THUMBNAIL_IMG_SRC}
                          style={{
                            padding: "0",
                            margin: "0",
                            borderRadius: IS_CURRENT_IETM_SELECTED ? "8px" : "",
                          }}
                        />
                      )}
                    </Flex>
                  );
                })}
              </GalleryThumbnailsFlexContainer>
            </Flex>
          </Flex>
        </>
      }
    />
  );
};

export default GalleryModal;
