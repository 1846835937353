import React, { useState } from 'react';
import { Title } from 'Request/RequestList.styles';
import { Modal } from 'venwiz-ui';
import CertDocsModalContent from './CertDocsModalContent';
import CompanyProfileModalContent from './CompanyProfileModalContent';
import { ProfileCreationModal } from './ProfileCreation.styles';

interface ProfileCreationModalsProps {
  children: React.ReactNode;
  title: string | React.ReactNode;
  onClose: () => void;
  width: string | number;
  margin?:string;
}

const ProfileCreationModals = (props: ProfileCreationModalsProps) => (
  <Modal
    modalHeader={<Title>{props?.title}</Title>}
    modalBody={props.children}
    show={true}
    handleClose={props.onClose}
    padding={"40px 20px"}
    width={{ default: "100%", md: props.width || "430px" }}
  ></Modal>

);

export default ProfileCreationModals;
