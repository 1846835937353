export const REGEX = {
    NUMBER: /^[0-9]+$/,
    DECIMAL_NUMBER: /^[0-9]+(\.[0-9]+)?$/,
    WORD: /^[a-zA-Z]+$/,
    WORD_WITH_SPACE: /^[a-zA-Z ]+$/
};

export const isNumber = (str: string) => REGEX.NUMBER.test(str) || false;
export const isDecimalNumber = (str: string) => str.match(REGEX.DECIMAL_NUMBER) || false;
export const isWordWithSpace = (str: string) => REGEX.WORD_WITH_SPACE.test(str) || false;
