import React from 'react';
import styled from 'styled-components';

interface CardProps {
  cardType: 'userCard' | 'progressCard' | 'viewCard' | 'imageCard' | 'referalCard';
  children?: React.ReactNode
}

const Card = styled.div<Partial<CardProps>>`
position: relative;
padding:${props => `${props.theme.card.types[`${props.cardType}`].padding}`};
cursor:${props => `${props.theme.card.types.cursor}`};
width:${props => `${props.theme.card.types[`${props.cardType}`].width}`};
height: ${props => `${props.theme.card.types[`${props.cardType}`].height}`};
background-color: ${props => `${props.theme.card.types[`${props.cardType}`].backgroundColor}`};
border-radius: ${props => `${props.theme.card.types[`${props.cardType}`].borderRadius}`};
`;

const CardUser = ({ children, cardType }: CardProps) => {
  return (
    <Card cardType={cardType}>
      {children}
    </Card>
  );
};

export default CardUser;
