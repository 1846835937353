import React from 'react';
import styled from 'styled-components';

export interface LabelWithoutEditProps {
  HeaderText: string,
  DetailText: string,
  headerName?: string,
  htmlFor?: string,
  elementTypes?: string
  fontVariant?:'light'|'regular'|'medium'|'bold'
  cursorType?:'pointer'
}

const LabelWrapper = styled.div`
width:100%;
height:100%;
display: flex;
flex-direction: column;
padding:10px;
`
const LabelHeader=styled.label<Partial<LabelWithoutEditProps>>`
color:#011759;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
`
const LabelHeaderDetails=styled.label<Partial<LabelWithoutEditProps>>`
color:#011759;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
`

const LabelWithoutEdit = ({...props}: LabelWithoutEditProps) => { 
  return  (
 <LabelWrapper>
    <LabelHeader {...props}>{props.HeaderText}</LabelHeader>
    <LabelHeaderDetails {...props}>{props.DetailText}</LabelHeaderDetails>
  </LabelWrapper>
  )}
;

export default LabelWithoutEdit;
