import React from "react";
import Checkbox from "components/ClientCheckbox/Checkbox";
import { COMPLIANCE_VALUES } from "../constant";
import { CheckBoxContainer, CheckBoxesList, HeaderText } from "../Compliance.styles";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";

const ComplianceCheckBoxes = props => {
  const trackingId = (checkboxType: string) => {
    const { GST, LIN, PAN, URN } = HEAP_DATA_ATTRIBUTES.COMPLIANCE.CHECKBOX;
    switch (checkboxType) {
      case "gst":
        return GST;
      case "lin":
        return LIN;
      case "urn":
        return URN;
      case "pan":
        return PAN;

      default:
        return "";
    }
  };

  return (
    <>
      <CheckBoxContainer>
        <HeaderText>Select all documents that are applicable</HeaderText>
        <CheckBoxesList>
          {Object.entries(props.checkBoxList).map((element: any, index) => {
            const disable = ["gst", "pan"].includes(element[0]);
            return (
              <li>
                <Checkbox
                  key={index}
                  isDisabled={disable}
                  isChecked={element[1].checked}
                  value={COMPLIANCE_VALUES[element[0]]?.checkBoxLabel}
                  onChange={(e: boolean) => {
                    props.handleCheckBoxList(e, element[0]);
                  }}
                  dataTrackingId={trackingId(element[0])}
                />
              </li>
            );
          })}
        </CheckBoxesList>
      </CheckBoxContainer>
    </>
  );
};

export default ComplianceCheckBoxes;
