interface ApiInterface {
  hostname: string;
  hosttype: object;
  communication_hostname: string;
  endpoints: {
    [key: string]: string;
  };
}

const api: ApiInterface = {
  hostname: process.env.REACT_APP_API_URL ?? "",
  communication_hostname: process.env.REACT_APP_COMMUNICATION_API_URL ?? "",
  hosttype: {
    vendor: "vendor",
    communication: "communication",
  },
  endpoints: {
    login: "/v1/auth/login",
    logout: "/v1/auth/logout",
    register: "/v1/auth/register",
    check_duplicate_email: "/v1/auth/check-duplicate-email",
    check_duplicate_mobile: "/v1/auth/check-duplicate-mobile",
    request_email_otp: "v1/auth/passwordless/request-email-otp",
    verify_email_otp: "/v1/auth/verify-email-otp",
    verify_mobile_otp: "/v1/auth/verify-mobile-otp",
    request_change_password_email: "/v1/auth/requestChangePasswordEmail",
    request_login_mobile_otp: "/v1/auth/passwordless/request",
    verify_login_mobile_otp: "/v1/auth/passwordless/verify",
    verify_gstn_number: "/v1/auth/check-duplicate-gstn",
    forgot_password_email: "/v1/auth/forgot-password",
    verify_email_link: "/v1/auth/verify-reset-email",
    email_verification_link: "/v1/auth/send-verify-email-link",
    verify_email_link_dashboard: "/v1/auth/verify-email-link",
    user_knows_about_help_button : "/v1/auth/user-knows-about-help-button",

    sec_user_gstn_verify: "/v2/vendor-profile/sec-user-gstn-verify",
    /** used to validate OTP Input Field(to verify Phone Number) in Registration Page  */
    generate_otp: "v1/auth/send-mobile-otp",
    verify_generated_otp: "v1/auth/mobile-otp-verification",
    //reset password
    request_reset_password: "/v1/auth/reset-password",
    //userinfo APIs
    userinfo_get_data: "/v1/user-info",
    userinfo_update_data: "/v1/user-info/update",
    userinfo_otp_verify: "/v1/user-info/update/verify-otp",
    userinfo_otp_resend: "/v1/user-info/update/resend-otp",
    userinfo_section_get_data: "/v1/user-info/user-section",
    userinfo_section_update_data: "/v1/user-info/user-section",

    //Capabilities APIs
    capabilities_get_data: "/v1/vendor-profile/capabilities",
    capabilities_get_onboarding_data: "/v1/vendor-profile/capabilities-sow/get",
    capabilities_post_onboarding_data:
      "/v1/vendor-profile/capabilities-sow/create",
    capabilities_update_onboarding_data:
      "/v1/vendor-profile/sow-capability/update",

    capabilities_post_data: "/v1/vendor-profile/capabilities/create",
    capabilities_update_caption_data: "/v1/vendor-profile/capabilities-caption",
    capabilities_update_data: "/v1/vendor-profile/capabilities/update",
    // resend otp and email
    resend_mobile_otp: "/v1/auth/resend-mobile-otp",
    resend_email_otp: "/v1/auth/resend-email-otp",
    //People APIs
    people_get_data: "/v1/vendor-profile/people/key-management",
    people_post_data: "/v1/vendor-profile/people/key-management/update",
    people_delete_data: "/v1/vendor-profile/people/key-management/delete",
    people_create_data: "/v1/vendor-profile/people/key-management/create",
    manpower_strength_create_data: "/v1/vendor-profile/people/strength/create",
    manpower_strength_update_data: "/v1/vendor-profile/people/strength/update",
    manpower_strength_get_data: "/v1/vendor-profile/people/strength",
    //companyInfo APIs
    companyinfo_get_data: "/v1/vendor-profile/company-info",
    companyinfo_post_data: "/v1/vendor-profile/company-info/create",
    companyinfo_update_data: "/v2/vendor-profile/company-info/update",
    backfill_key_contacts: "/v1/vendor-profile/backfill-key-contacts",
    companyinfo_get_onboarding_data: "/v2/vendor-profile/get-company-info",
    companyinfo_post_onboarding_data:
      "/v2/vendor-profile/onboarding-company-info/create",
    // request_email_otp: '/v1/auth/request-email-otp',
    // request_mobile_otp: '/v1/auth/request-mobile-otp',
    experienceinfo_get_data: "/v1/vendor-profile/experience",
    experienceinfo_create_onboarding_data: `/v2/vendor-profile/onboarding-experience/create`,
    experienceinfo_create_data: `/v1/vendor-profile/experience/create`,
    experienceinfo_update_data: "/v1/vendor-profile/experience/update",
    experienceinfo_delete_data: "/v1/vendor-profile/experience/delete",
    update_experience_tutorial_viewed_for_a_vendor: "/v1/vendor-profile/experience/tutorial-viewed",
    // certification info api's
    certification_info_get_data: "v1/vendor-profile/certifications",
    certification_info_post_data: "/v1/vendor-profile/certifications/update",
    certification_info_create_data: "/v1/vendor-profile/certifications/create",
    // reference info api's
    reference_info_get_data: "/v1/vendor-profile/references",
    reference_info_post_data: "/v1/vendor-profile/references/update",
    reference_info_create_data: "/v1/vendor-profile/references/create",
    //document info api's
    document_info_get_data: "/v1/vendor-profile/documents",
    document_info_post_data: "/v1/vendor-profile/documents/update",
    document_info_create_data: "/v1/vendor-profile/documents/create",
    // scope of work api's
    scopeofwork_info_get_data: "/v1/vendor-profile/scope-of-work",
    scopeofwork_info_post_data: "/v1/vendor-profile/scope-of-work/update",
    scopeofwork_info_create_data: "/v1/vendor-profile/scope-of-work/create",

    // file upoad api's
    file_upload_post_data: "/v1/file/upload",
    file_upload_post_data_permanent: "/v1/file/make-permanent",
    upload_multiple_files: "/v1/file/upload-multiple",
    // dashboard api's
    dashboard_percentage_get_data: "/v1/profile-completion",
    dashboard_analytics_data: "/v1/dashboard",
    // Location api's
    country_get_data: "/v1/location/countries",
    city_post_data: "/v1/location/cities-by-state",
    state_post_data: "/v1/location/state",
    // chatList api
    chatlist_get_data: "/v1/communication/message-list",
    chat_get_messages: "/v1/communication/fetch",
    chat_get_old_messages: "/v1/communication/fetch-old",
    send_message: "/v1/communication/send-message",
    delete_all_chats: "/v1/communication/clear-chat",
    delete_chat: "/v1/communication/delete-message",
    //invite apis
    send_invite_api: "/v1/invite/send",
    get_invitee_list: "/v1/invite/list-invites",
    resend_invite_api: "/v1/invite/re-send",

    upload_file: "/v1/communication/upload-file",
    get_shared_files: "/v1/communication/get-shared-files",

    //profile-completion
    get_profile_completion_status: "/v1/profile-completion/profile-status",

    //marketingSUite
    get_user_details_pdf: "/v1/generate-business-card",
    get_marketing_brochure_pdf: "/v1/generate-brouchure",

    //Request-plantvisit
    get_request_type_list: "/v1/request-flow/request-type-list/get",
    post_request_create: "/v1/request-flow/create",
    post_request_update: "/v1/request-flow/update",
    post_request_delete: "/v1/request-flow/delete-by-request-id",
    get_request: "/v1/request-flow/request-by-request-id",
    get_request_list: "/v1/request-flow/get",
    get_new_update_request_count: "/v1/request-flow/request-status-update",
    get_job_summary: "/v1/vendor-jobs/get-job-summary",
    get_open_jobs: "/v1/vendor-jobs/get-jobs",
    get_open_jobs_public: "/v1/vendor-jobs/get-open-jobs",
    get_job_summary_public: "/v1/vendor-jobs/get-open-job-summary",
    get_open_jobs_locations: "/v1/vendor-jobs/get-open-jobs-locations",
    get_open_jobs_banner: "/v1/vendor-jobs/get-open-jobs-banner",

    //shareablelink api
    put_shareable_link: "/v2/vendor-profile/mail-shareable-profile-link",

    // agent-tracking-on-vendor-onboarding
    agent_code_tracking: "/v1/agent/validate-agent",

    //job-request
    get_job_request_data: "/v1/shared-links/vendor-request",
    save_job_request_interest_response: "/v1/request-flow/saveResponse",
    submit_quotations: "/v1/quotation/submit-quotations",
    need_job_info_email_response: "v1/request-flow/need-more-job-info",
    save_pqr: "v2/vendor-profile/save-pqr",
    update_no_interest_response:"v1/vendor-jobs/update-no-interest-response",
    update_quote_reminder:"v1/vendor-jobs/update-quotes-submission-reminder",
    get_job_flow: "v1/vendor-jobs/get-job-flow",
    apply_job_vendor: "v1/request-flow/apply-job-vendor",
    //v2
    get_job_request_data_v2: "/v2/shared-links/vendor-request-v3",

    //rfq quotation
    delete_quotation_document: "/v2/quotation/remove-quotation-v2",
    quotation_file_history: "/v2/quotation/get-quotation-history",
    add_quotations: "/v2/quotation/add-quotations-v2",
    restore_quotation_version: "/v2/quotation/restore-quotation-doc",
    get_rfq_quotation: "/v2/quotation/get-quotations-v2",
    publish_rfq_quotation_file: "v2/quotation/publish-quotation-doc",
    submit_rfq_quotation: "v2/quotation/submit-quotations-v2",
    submit_open_quotation: "v2/quotation/submit-open-quotation",
    submit_past_experience_or_po_docs: "v1/vendor-jobs/save-past-experience-or-po-docs",

    //getOemTags
    get_oem_tags_lib: "v1/vendor-profile/allTags/OEM",
    create_oem_tag: "v1/vendor-profile/tags/create",

    // compliance
    compliance_get_data: "v1/compliance/get-compliance",
    update_compliance_data: "v1/compliance/update-compliance-doc",
    verify_urn_tan: "v1/compliance/get-verify",

    //get-technical-certificates-options
    get_tech_certificates_lib: "v1/vendor-profile/certs/technical",

    // finance
    finance_get_data: "v1/financial-information",
    update_finance_data: "v1/financial-information",
    create_finance_data: "v1/financial-information",

    //dashboard profile creation status
    product_video_post: "v1/checklist/product-tour",
    cert_docs_status_get: "v1/checklist/company-documents",
    company_profile_status_get: "v1/checklist/company-profile",
    dashboard_profile_status_get: "v1/checklist/dashboard",

    //activity-log
    log_activity: "v1/activity-log/create-activity-log",
    get_activity_log: "v1/activity-log/get-activity-log",
    get_latest_five_activity: "v1/activity-log/get-latest-activity",

    //file upload
    upload_files_to_permanent_folder: "v1/file/uploads",
    upload_files_to_permanent_folder_open: "v1/file/open-uploads",
    upload_sec_user_gstn_file: "v1/file/gstn-file-upload",

    // new onboarding
    onboarding_api: "v1/new-onboarding",
    get_equipment_family_list: "v1/tags/equipFamilyList",
    get_equipment_family_list_open: "v1/tags/open-equipFamilyList",
    get_service_group_list: "v1/tags/serviceGroupList",
    get_service_group_list_open: "v1/tags/open-serviceGroupList",

    // neo4J OEM tags
    get_neo4j_oem_tags: "v1/tags/getOemTags",

    //job monetization
    update_job_monetization: "v1/request-flow/update-job-monetization",
    update_job_monetization_last_contacted:
      "v1/request-flow/update-job-monetization-last-contacted",
    update_last_activity_date: "v1/job-rfq/update-last-activity-date",
    save_pricing_consent: "v1/request-flow/save-pricing-consent",

    get_vendor_data_sharing_consent:
      "v1/final-vendor/get-vendor-data-sharing-consent",
    save_vendor_data_sharing_consent:
      "v1/final-vendor/save-vendor-data-sharing-consent",

    //verify onboarding
    verify_onboarding_link: "v1/auth/verify-onboarding-link",
    // vendor profile
    get_vendor_profile: "v2/vendor-profile/vendor-profile-view",

    //open-experience
    check_if_user_exists: "v1/auth/check-if-user-exists-in-db",
    open_experiences: "v1/vendor-profile/open-experience",
    open_experienceinfo_update_data: "v1/vendor-profile/experience/update",

    // goto-venodrs
    verify_goto_vendor_token: "v1/goto-vendors/update-goto-vendor-status",

    // verify-vendors-escrow-info vendor-agreement 
    verify_vendors_escrow_info_vendor_agreement_token: "v1/vendors-escrow-info/update-vendor-agreement-accepted-status",
  },
};
export default api;
