import styled from "styled-components";

export const UnorderedList=styled.ul`
  color: #011759;
  width:280px;
  padding-left: 15px;
  display:none;
  @media only screen and (max-width: 640px) {
    display:block;
  }
`
export  const List=styled.li`
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: 0;
`
export const Text=styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  text-align: justify;
  color: #011759;
`
export const PasswordDiscription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  margin-left: 5px;
  color: #215ec9;
  margin-left:8px;
`;

export const HideDiv=styled.div`
  width:auto;
  @media only screen and (max-width: 640px) {
  display:none;
  }
`

export const FlexDiv=styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: start;
  margin-top:4px;
  @media only screen and (max-width: 640px) {
  display:none;
  }
`