import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "components/Button";
import { CardTag } from "components/Card";
import { LogoImage } from "components/Image";
import { InputWithEdit } from "components/Input";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import { Label } from "components/Label";
import {
  documentInfoCreate,
  documentInfoGet,
  documentInfoPost,
} from "reducers/documentInfo";
import { fileUploadPost } from "reducers/fileUpload";
import { showEditReducer } from "reducers/profileCreation";
import openNotification from "constants/antdNotification";
import { documentsReqLocalizationData } from "ui-constants/localizationData";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";

const ContentInputs = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  padding-bottom: 30px;
`;

const ReferenceInfoContainer = styled.div`
  display: ${props =>
    `${props.theme.card.types["peopleInfoContainer"].display}`};
  flex-direction: ${props =>
    `${props.theme.card.types["peopleInfoContainer"].flexDirection}`};
  margin-top: ${props =>
    `${props.theme.card.types["peopleInfoContainer"].marginTop}`};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 575px;
`;
const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;
const SubHeader = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentSubHeading = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #011759;
  width: 581px;
  height: 42px;
`;
const InputFlexWrapper = styled.div`
  padding: 10px 10px 0px 0;
`;

const InputUploadWrapper = styled(InputFlexWrapper)`
  padding: 10px 10px 0px 0;
  min-width: 1372px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const FormWrapper = styled.div``;
const HeaderDiv = styled.div`
  margin-bottom: 20px;
`;
const WarningMsg = styled.div`
  display: flex;
`;


const InputWrap = styled.input`
  position: relative;
  right: 0px;
  left: -209px;
  opacity: 0;
  z-index: 1;
  width: 160px;
  height: 60px;
  top: -19px;
  bottom: 0px;
`;
const InputFlex = styled.div`
  display: block;
  max-width: 550px;
`;
const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonDiv = styled.div`
  margin-bottom: 10px;
`;
const UploadedContainer = styled.div`
  height: auto;
  margin-top: 20px;
`;
const BorderLine = styled.div`
  margin-top: 20px;
  width: 570px;
  border-top: 1px solid gray;
`;
const WarningImg = styled.img`
  width: 26px;
  height: 26px;
  margin-top: 40px;
`;
const MessageWrapper = styled.div`
  display: flex;
`;
const BtnMargin = styled.div`
  margin-left: 30px;
`;
const LinkTo = styled.a``;

const DocumentInfoTab = () => {
  const history = useHistory();
  const documentState = useSelector((state: any) => state.documentInfo);
  const {
    documentInfoGetData,
    documentInfoPostData,
    documentInfoCreateData,
    errorData,
  } = documentState;

  const fileUploadState = useSelector((state: any) => state.fileUpload);
  const { fileUploadData, uploadErrorData } = fileUploadState;

  const buttonState = useSelector((state: any) => state.profileCreation);
  const dispatch = useDispatch();

  const [documents, setDocuments] = useState([]);
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [fileSize, setFileSize] = useState(0);
  const [tempDoc, setTempDoc] = useState([]);
  const [inputFieldError, setInputFieldError] = useState(false);

  useEffect(() => {
    dispatch(documentInfoGet({}));
  }, []);

  useEffect(() => {
    if (documentInfoPostData || documentInfoCreateData)
      dispatch(documentInfoGet({}));
  }, [documentInfoPostData, documentInfoCreateData]);

  useEffect(() => {
    if (documentInfoGetData?.document?.length > 0) {
      showEditReducer(dispatch, (buttonState.editButton = false));
      setDocuments([...documentInfoGetData?.document]);
    } else {
      showEditReducer(dispatch, (buttonState.editButton = true));
      setDocuments([]);
    }
  }, [documentInfoGetData]);

  useEffect(() => {
    if (fileUploadData) {
      setDocuments([
        ...documents,
        { name: fileName, documentFile: { ...fileUploadData } },
      ]);
      setTempDoc([]);
      setFileName("");
    }
  }, [fileUploadData]);

  useEffect(() => {
    if (errorData) {
      openNotification(errorData);
      dispatch(documentInfoGet({}));
    }
  }, [errorData]);

  useEffect(() => {
    if (uploadErrorData) {
      openNotification(uploadErrorData);
      setTempDoc([]);
    }
  }, [uploadErrorData]);

  const handleClick = type => {
    showEditReducer(dispatch, !buttonState.editButton);
    if (type === "save") {
      if (documentInfoGetData?.documentId) {
        dispatch(
          documentInfoPost({
            documentId: documentInfoGetData.documentId,
            document: [...documents],
          })
        );
      } else {
        dispatch(
          documentInfoCreate({
            document: [...documents],
          })
        );
      }
    }
  };

  const handleAddKey = () => {};
  const getFile = (e: any) => {
    const file = e.target.files[0];
    setDocumentName(file?.name);
    if (typeof file !== "undefined") {
      setFile(file);
      setFileSize(file?.size);
      setInputFieldError(false);
    }
  };

  const closeTag = (data: any) => {
    setFileName("");
    setDocuments(documents.filter(item => item.name !== data.name));
  };

  const handleSetFileName = e => {
    setFileName(e.target.value);
  };
  const onAdd = () => {
    const dupFileFlag = documents?.some((item: any) => item?.name === fileName);
    if (
      fileName !== "Enter Type of document" &&
      fileName !== "" &&
      file !== undefined &&
      fileSize < 20971520 &&
      documents?.length < 10 &&
      !dupFileFlag
    ) {
      dispatch(fileUploadPost(file));
      setTempDoc([
        ...tempDoc,
        { name: fileName, documentFile: { fileName: documentName, fileSize } },
      ]);
      setInputFieldError(false);
      setFile(undefined);
      setDocumentName("Select the file");
    } else {
      if (documents?.length === 10) {
        openNotification("Maximum only 10 documents can be uploaded");
      }
      if (fileSize > 20971520) {
        openNotification(
          "File size exceeds the 20Mb limit. Please try uploading a smaller file size"
        );
      }
      setInputFieldError(true);
    }
  };

  const setUserData=()=>{
    if (Object.keys(documentInfoGetData)?.length)
    setDocuments([...documentInfoGetData?.document]);

  }
  return (
    <>
      <ContentHeader>Documents</ContentHeader>
      <ContentInputs>
        <FormWrapper>
          <ReferenceInfoContainer>
            <HeaderDiv>
              <ContentSubHeading>
                {documentsReqLocalizationData.heading}
              </ContentSubHeading>
            </HeaderDiv>
            <Wrapper>
              <InputFlexWrapper>
                <MessageWrapper>
                  <InputWithEdit
                    inputFieldError={inputFieldError}
                    inputBoxSize="large"
                    inputType="text"
                    importance="important"
                    placeholder="Enter Type of document"
                    value={fileName}
                    usedFor="profileCreation"
                    name="documentDetails"
                    withHeader
                    hidden={buttonState.editButton}
                    disabled={false}
                    onChange={handleSetFileName}
                    maxChar={50}
                  >
                    Document Details
                  </InputWithEdit>

                  {inputFieldError && (
                    <WarningImg src="/images/Auth/warning.svg" />
                  )}
                </MessageWrapper>
              </InputFlexWrapper>

              {buttonState.editButton && (
                <InputUploadWrapper>
                  <InputBoxForEdit
                    sizes="middle"
                    inputTextType="text"
                    importanceType="important"
                    child="Document"
                    placeholder={documentName || "Select the file"}
                    buttonType={true}
                    buttonDisable
                    onHandleChange={() => {}}
                  />
                  <Button
                    onClick={handleAddKey}
                    variant="white"
                    size="small"
                    style={{
                      borderRadius: "0px 8px 8px 0px",
                      width: "160px",
                      marginTop: "0px",
                      position: "relative",
                      left: "-49px",
                      top: "-2px",
                      height: "60px",
                      fontSize: "16px",
                      border: "1px solid #D5D9EC",
                      color: "#011759",
                    }}
                  >
                    Browse
                  </Button>
                  <InputWrap
                    type="file"
                    onChange={getFile}
                    accept="application/pdf,image/jpeg"
                  />
                </InputUploadWrapper>
              )}
              {buttonState.editButton && (
                <ButtonDiv>
                  <Button
                    onClick={onAdd}
                    variant="blue"
                    size="small"
                    style={{
                      width: "160px",
                      marginTop: "0px",
                      height: "60px",
                      border: "1px solid white",
                    }}
                  >
                    Add
                  </Button>
                </ButtonDiv>
              )}
            </Wrapper>
            {buttonState.editButton && (
              <WarningMsg>
                <Div>
                  <LogoImage
                    imageSrc="/images/ProfileCreation/info.svg"
                    altMesg="edit button"
                    imageType="icon"
                  />
                </Div>
                <Label
                  fontVariant="bold"
                  htmlFor="brochureSize"
                  text="Supports .pdf and .jpeg formats. Max. file size 20mb."
                />
              </WarningMsg>
            )}

            {buttonState.editButton && documents.length > 0 && (
              <BorderLine></BorderLine>
            )}

            <InputFlex>
              {(documents.length > 0 || tempDoc.length > 0) && (
                <UploadedContainer>
                  <SubHeader>Uploaded</SubHeader>
                  {tempDoc.map((item, index) => {
                    return (
                      <CardTag
                        keys={index}
                        onClose={() => closeTag(item)}
                        cardText={item.name}
                        usedFor="fileUploadTag"
                        fileSize={item.documentFile.fileSize / 1000000}
                        closable={true}
                        loading
                      />
                    );
                  })}
                  {documents.map(item => {
                    return (
                      <LinkTo
                        href={
                          !buttonState.editButton && item?.documentFile?.fileUrl
                        }
                      >
                        <CardTag
                          onClose={() => closeTag(item)}
                          cardText={item.name}
                          usedFor="fileUploadTag"
                          fileSize={item.documentFile.fileSize / 1000000}
                          closable={!buttonState.editButton}
                          cursorType={
                            (!buttonState.editButton && "pointer") || "default"
                          }
                        />
                      </LinkTo>
                    );
                  })}
                </UploadedContainer>
              )}
            </InputFlex>
          </ReferenceInfoContainer>
        </FormWrapper>
      </ContentInputs>
      <ButtonWrapper>
        <ProfileCreationButtons
          usedAt={"documents"}
          state={!buttonState.editButton}
          handleStateChange={value => {
            if (value === "cancel") {
              showEditReducer(dispatch, !buttonState.editButton);
              setUserData();
              setTempDoc([]);
              setFileName("");
            } else {
              handleClick(value);
            }
          }}
        ></ProfileCreationButtons>
        {/* {buttonState.editButton ? (
          <Button
            onClick={() => handleClick("save")}
            variant="secondary"
            size="middle"
            style={{ padding: "20px 105px" }}
          >
            Save
          </Button>
        ) : (
          <Button
            onClick={() => handleClick("edit")}
            variant="primary"
            size="middle"
            style={{ padding: "20px 105px" }}
          >
            Edit
          </Button>
        )}
        <BtnMargin>
          <Button
            disabled={buttonState.editButton}
            onClick={() => {
              history.push("/dashboard");
            }}
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Home
          </Button>
        </BtnMargin> */}
      </ButtonWrapper>
    </>
  );
};

export default DocumentInfoTab;
