import { VerificationStatus } from "Profile/enums";

export const AUTH_STORE = "auth/AUTH_STORE";
export const SET_IS_LOADING = "auth/SET_IS_LOADING";

export const AUTH_REQUESTED = "auth/AUTH_REQUESTED";
export const AUTH_SUCCESS = "auth/AUTH_SUCCESS";
export const AUTH_FAILURE = "auth/AUTH_FAILURE";
export const AUTH_REGISTRATION = "auth/AUTH_REGISTRATION";
export const AUTH_KARZA_DATA = "auth/AUTH_KARZA_DATA";

export const AUTH_MOBILE_OTP_REQUESTED = "auth/AUTH_MOBILE_OTP_REQUESTED";
export const AUTH_MOBILE_OTP_SUCCESS = "auth/AUTH_MOBILE_OTP_SUCCESS";
export const AUTH_MOBILE_OTP_FAILURE = "auth/AUTH_MOBILE_OTP_FAILURE";

export const AUTH_EMAIL_OTP_REQUESTED = "auth/AUTH_EMAIL_OTP_REQUESTED";
export const AUTH_EMAIL_OTP_SUCCESS = "auth/AUTH_EMAIL_OTP_SUCCESS";
export const AUTH_EMAIL_OTP_FAILURE = "auth/AUTH_EMAIL_OTP_FAILURE";

export const AUTH_EMAIL_LINK_REQUESTED = "auth/AUTH_EMAIL_LINK_REQUESTED";
export const AUTH_EMAIL_LINK_SUCCESS = "auth/AUTH_EMAIL_LINK_SUCCESS";
export const AUTH_EMAIL_LINK_FAILURE = "auth/AUTH_EMAIL_LINK_FAILURE";

export const SIGNUP_REQUESTED = "auth/SIGNUP_REQUESTED";
export const SIGNUP_SUCCESS = "auth/SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "auth/SIGNUP_FAILURE";

export const VERIFY_EMAIL_OTP_REQUESTED = "auth/VERIFY_EMAIL_OTP_REQUESTED";
export const VERIFY_EMAIL_OTP_SUCCESS = "auth/VERIFY_EMAIL_OTP_SUCCESS";
export const VERIFY_EMAIL_OTP_FAILURE = "auth/VERIFY_EMAIL_OTP_FAILURE";

export const VERIFY_MOBILE_OTP_REQUESTED = "auth/VERIFY_MOBILE_OTP_REQUESTED";
export const VERIFY_MOBILE_OTP_SUCCESS = "auth/VERIFY_MOBILE_OTP_SUCCESS";
export const VERIFY_MOBILE_OTP_FAILURE = "auth/VERIFY_MOBILE_OTP_FAILURE";

export const VERIFY_EMAIL_LINK_REQUESTED = "auth/VERIFY_EMAIL_LINK_REQUESTED";
export const VERIFY_EMAIL_LINK_SUCCESS = "auth/VERIFY_EMAIL_LINK_SUCCESS";
export const VERIFY_EMAIL_LINK_FAILURE = "auth/VERIFY_EMAIL_LINK_FAILURE";

export const VERIFY_RESEND_OTP_SUCCESS = "auth/VERIFY_RESEND_OTP_SUCCESS";

export const NOT_VERIFIED_DATA = "auth/NOT_VERIFIED_DATA";

// GSTN
export const CHECK_GSTN_SUCCESS = "auth/CHECK_GSTN_SUCCESS";
export const CHECK_GSTN_FAILURE = "auth/CHECK_GSTN_FAILURE";
// UserInfo Otp
export const USERINFO_VERIFY_OTP_REQUESTED = "auth/USERINFO_VERIFY_OTP_REQUESTED";
export const USERINFO_VERIFY_OTP_FAILURE = "auth/USERINFO_VERIFY_OTP_FAILURE";
export const USERINFO_VERIFY_OTP_SUCCESS = "auth/USERINFO_VERIFY_OTP_SUCCESS";

export const CHECK_DUPLICATE_MOBILE_REQUESTED = "auth/CHECK_DUPLICATE_MOBILE_REQUESTED";
export const CHECK_DUPLICATE_MOBILE_SUCCESS = "auth/CHECK_DUPLICATE_MOBILE_SUCCESS";
export const CHECK_DUPLICATE_MOBILE_FAILURE = "auth/CHECK_DUPLICATE_MOBILE_FAILURE";

export const CHECK_DUPLICATE_EMAIL_REQUESTED = "auth/CHECK_DUPLICATE_EMAIL_REQUESTED";
export const CHECK_DUPLICATE_EMAIL_SUCCESS = "auth/CHECK_DUPLICATE_EMAIL_SUCCESS";
export const CHECK_DUPLICATE_EMAIL_FAILURE = "auth/CHECK_DUPLICATE_EMAIL_FAILURE";

export const RESET_PASSWORD_REQUESTED = "auth/RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_SUCCESS = "auth/RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "auth/RESET_PASSWORD_FAILURE";

export const CLEAR_AUTH = "auth/CLEAR_AUTH";

export const RESET_STATE = "auth/RESET_STATE";
export const RESET_AUTH_STATE = "auth/RESET_AUTH_STATE";
export const USER_NOT_VERIFIED_STATE = "user/USER_NOT_VERIFIED_STATE";

export const VALIDATE_AGENT_CODE_REQUESTED = "auth/VALIDATE_AGENT_CODE";
export const VALIDATE_AGENT_CODE_SUCCESS = "auth/VALIDATE_AGENT_CODE";
export const VALIDATE_AGENT_CODE_FAILURE = "auth/VALIDATE_AGENT_CODE";

/** used to Generate OTP(for new Phone Number) in Registration Page */
export const GENERATE_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED = "auth/GENERATE_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED";
export const GENERATE_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS = "auth/GENERATE_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS";
export const GENERATE_OTP_FOR_NEW_PHONE_NUMBER_FAILURE = "auth/GENERATE_OTP_FOR_NEW_PHONE_NUMBER_FAILURE";

/** used to validate OTP Input Field(to verify Phone Number) in Registration Page  */
export const VERIFY_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED = "auth/VERIFY_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED";
export const VERIFY_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS = "auth/VERIFY_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS";
export const VERIFY_OTP_FOR_NEW_PHONE_NUMBER_FAILURE = "auth/VERIFY_OTP_FOR_NEW_PHONE_NUMBER_FAILURE";

//send link to verify the email in dashboard
export const SEND_VERIFY_EMAIL_REQUESTED = "auth/SEND_VERIFY_EMAIL_REQUESTED";
export const SEND_VERIFY_EMAIL_SUCCESS = "auth/SEND_VERIFY_EMAIL_SUCCESS";
export const SEND_VERIFY_EMAIL_FAILURE = "auth/SEND_VERIFY_EMAIL_FAILURE";

// used to verify email in dashboard page
export const VERIFY_EMAIL_REQUESTED = "auth/VERIFY_EMAIL_REQUESTED";
export const VERIFY_EMAIL_SUCCESS = "auth/VERIFY_EMAIL_SUCCESS";
export const VERIFY_EMAIL_FAILURE = "auth/VERIFY_EMAIL_FAILURE";

export const SET_AGENT_CODE = "auth/SET_AGENT_CODE";

export const SET_RESET_PWD_TOKEN = "auth/SET_RESET_PWD_TOKEN";

export const LOG_OUT_SUCCESS = "auth/LOG_OUT_SUCCESS";

export const USER_KNOWS_ABOUT_HELP_BUTTON_REQUESTED = "auth/USER_KNOWS_ABOUT_HELP_BUTTON_REQUESTED";
export const USER_KNOWS_ABOUT_HELP_BUTTON_SUCCESS = "auth/USER_KNOWS_ABOUT_HELP_BUTTON_SUCCESS";
export const USER_KNOWS_ABOUT_HELP_BUTTON_FAILURE = "auth/USER_KNOWS_ABOUT_HELP_BUTTON_FAILURE";

const initialState = {
  isLoading: false,
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userNotVerified: null,
  userData: null,
  karzaData: null,
  authedUserData: undefined,
  otpError: false,
  mobileOtpError: false,
  emailOtpError: false,

  loginMobileOtpLoading: false,
  loginMobileOtpLoaded: false,
  loginMobileOtpSuccess: false,
  loginMobileOtpError: false,
  loginMobileOtpErrorData: false,

  requestChangePasswordEmailLoading: false,
  requestChangePasswordEmailSuccess: false,
  requestChangePasswordEmailError: false,

  loginEmailOtpLoading: false,
  loginEmaileOtpLoaded: false,
  loginEmailOtpSuccess: false,
  loginEmailOtpError: false,
  loginEmailOtpErrorData: false,

  resetPasswordLoading: false,
  resetPasswordLoaded: false,
  resetPasswordSuccess: false,
  resetPasswordError: false,
  resetPasswordErrorData: false,

  verifyEmailLinkLoading: false,
  verifyEmailLinkSuccess: false,
  verifyEmailLinkError: false,

  sendVerifyEmailLinkLoading: false,
  sendVerifyEmailLinkSuccess: false,
  sendVerifyEmailLinkError: false,

  registerLoading: false,
  registerLoaded: false,
  registerError: false,
  registerData: undefined,
  registerCompleted: false,

  verifyEmailOTPLoading: false,
  verifyEmailOTPLoaded: false,
  verifyEmailOTPError: false,
  verifyEmailOTPMessage: false,
  verifyEmailOTPData: undefined,

  verifyEmailLoading: false,
  verifyEmailError: false,
  verifyEmailSuccess: false,

  verifyMobileOTPLoading: false,
  verifyMobileOTPLoaded: false,
  verifyMobileOTPError: false,
  verifyMobileOTPData: undefined,

  checkDuplicateEmailLoading: false,
  checkDuplicateEmailLoaded: false,
  checkDuplicateEmailError: false,
  checkDuplicateEmailData: undefined,
  checkIsDuplicateEmail: undefined,

  checkDuplicateMobileLoading: false,
  checkDuplicateMobileLoaded: false,
  checkDuplicateMobileError: false,
  checkDuplicateMobileData: undefined,
  checkIsDuplicateMobile: undefined,

  checkDuplicateGstNo: undefined,
  checkMobileResendOtpData: undefined,
  checkResendOtpData: undefined,

  userInfoOtpLoading: false,
  userInfoOtpLoaded: false,
  userInfoOtpError: false,
  userInfoOtpSuccess: false,

  agentCode: "",
  isAgentCodeValid: null,
  isAgentCodeValidLoading: false,
  isAgentCodeValidError: null,
  resetPwdToken: null,

  /** used to Generate OTP(for new Phone Number) in Registration Page */
  generateOTPLoading: false,
  generateOTPError: null,
  generatedOTPData: null, // object

  /** used to validate OTP Input Field(to verify Phone Number) in Registration Page  */
  // isOTPValidForNewPhoneNumber: null, // null | true | false
  verifyOTPForNewPhoneNumberData: null,
  isOTPVerificationLoading: false,
  isOTPVefificationError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_NOT_VERIFIED_STATE:
      return {
        ...state,
        userNotVerified: null,
      };
    case AUTH_REQUESTED:
      return {
        ...state,
        loading: true,
        otpError: false,
        error: false,
        mobileOtpError: false,
        errorData: "",
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        authedUserData: action.result,
        otpError: false,
        userNotVerified: null,
        mobileOtpError: false,
        verifyMobileOTPData: true,
        errorData: "",
      };

    case AUTH_EMAIL_LINK_REQUESTED:
      return {
        ...state,
        requestChangePasswordEmailLoading: true,
      };

    case AUTH_EMAIL_LINK_SUCCESS:
      return {
        ...state,
        requestChangePasswordEmailSuccess: true,
        requestChangePasswordEmailError: false,
        requestChangePasswordEmailLoading: false,
      };

    case AUTH_EMAIL_LINK_FAILURE:
      return {
        ...state,
        requestChangePasswordEmailError: true,
        requestChangePasswordEmailSuccess: false,
        requestChangePasswordEmailLoading: false,
      };

    case AUTH_REGISTRATION:
      return {
        ...state,
        loading: false,
        loaded: true,
        userData: action.payload,
      };

    case AUTH_KARZA_DATA:
      return {
        ...state,
        karzaData: action.payload,
      };

    case AUTH_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        otpError: true,
        errorData: action.error.message,
        mobileOtpError: true,
      };

    case AUTH_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        loginMobileOtpLoading: true,
      };

    case AUTH_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        loginMobileOtpLoading: false,
        loginMobileOtpLoaded: true,
        loginMobileOtpError: false,
        loginMobileOtpSuccess: true,
      };

    case AUTH_MOBILE_OTP_FAILURE:
      return {
        ...state,
        loginMobileOtpLoading: false,
        loginMobileOtpLoaded: true,
        loginMobileOtpError: true,
        loginMobileOtpErrorData: action.error.message,
      };

    case RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        resetPasswordLoading: true,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordLoaded: true,
        resetPasswordError: false,
        resetPasswordSuccess: true,
        resetPwdToken: null,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPasswordLoading: false,
        resetPasswordLoaded: true,
        resetPasswordError: true,
        resetPasswordErrorData: action.error.message,
      };

    case AUTH_EMAIL_OTP_REQUESTED:
      return {
        ...state,
        loginEmailOtpLoading: true,
      };

    case AUTH_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        loginEmailOtpLoading: false,
        loginEmailOtpLoaded: true,
        loginEmailOtpError: false,
        loginEmailOtpSuccess: true,
      };

    case AUTH_EMAIL_OTP_FAILURE:
      return {
        ...state,
        loginEmailOtpLoading: false,
        loginEmailOtpLoaded: true,
        loginEmailOtpError: true,
        loginEmailOtpErrorData: action.error.message,
      };

    case SIGNUP_REQUESTED:
      return {
        ...state,
        registerLoading: true,
      };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        registerLoaded: true,
        registerData: action.result,
        registerCompleted: true,
      };
    case SIGNUP_FAILURE:
      return {
        ...state,
        registerLoading: false,
        registerLoaded: true,
        registerError: action.error.message,
      };

    case VERIFY_EMAIL_OTP_REQUESTED:
      return {
        ...state,
        verifyEmailOTPLoading: true,
        emailOtpError: false,
        verifyEmailOTPMessage: "",
      };
    case VERIFY_EMAIL_OTP_SUCCESS:
      return {
        ...state,
        authedUserData: action.result,
        verifyEmailOTPLoading: false,
        verifyEmailOTPData: true,
        verifyEmailOTPLoaded: true,
        emailOtpError: false,
        verifyEmailOTPMessage: "",
      };

    case VERIFY_EMAIL_OTP_FAILURE:
      return {
        ...state,
        verifyEmailOTPLoading: false,
        verifyEmailOTPError: true,
        verifyEmailOTPMessage: action.error.message,
        verifyEmailOTPLoaded: true,
        emailOtpError: true,
      };

    case VERIFY_MOBILE_OTP_REQUESTED:
      return {
        ...state,
        verifyMobileOTPLoading: true,
        mobileOtpError: false,
      };
    case VERIFY_MOBILE_OTP_SUCCESS:
      return {
        ...state,
        verifyMobileOTPLoading: false,
        verifyMobileOTPLoaded: true,
        verifyMobileOTPData: true,
        mobileOtpError: false,
      };
    case VERIFY_MOBILE_OTP_FAILURE:
      return {
        ...state,
        verifyMobileOTPLoading: false,
        verifyMobileOTPLoaded: true,
        verifyMobileOTPError: true,
        mobileOtpError: true,
      };

    case VERIFY_EMAIL_LINK_REQUESTED:
      return {
        ...state,
        verifyEmailLinkLoading: true,
        verifyEmailLinkSuccess: false,
        verifyEmailLinkError: false,
      };

    case VERIFY_EMAIL_LINK_SUCCESS:
      return {
        ...state,
        verifyEmailLinkLoading: false,
        verifyEmailLinkSuccess: true,
        verifyEmailLinkError: false,
      };

    case VERIFY_EMAIL_LINK_FAILURE:
      return {
        ...state,
        verifyEmailLinkLoading: false,
        verifyEmailLinkSuccess: false,
        verifyEmailLinkError: true,
      };

    case SEND_VERIFY_EMAIL_REQUESTED:
      return {
        ...state,
        sendVerifyEmailLinkLoading: true,
        sendVerifyEmailLinkSuccess: false,
        sendVerifyEmailLinkError: false,
      };

    case SEND_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        sendVerifyEmailLinkLoading: false,
        sendVerifyEmailLinkSuccess: true,
        sendVerifyEmailLinkError: false,
      };

    case SEND_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        sendVerifyEmailLinkLoading: false,
        sendVerifyEmailLinkSuccess: false,
        sendVerifyEmailLinkError: true,
      };

    case VERIFY_EMAIL_REQUESTED:
      return {
        ...state,
        verifyEmailLoading: true,
        verifyEmailError: false,
        verifyEmailSuccess: false,
      };

    case VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailError: false,
        verifyEmailSuccess: true,
      };

    case VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmailError: true,
        verifyEmailSuccess: false,
      };

    case CHECK_DUPLICATE_MOBILE_REQUESTED:
      return {
        ...state,
        checkDuplicateMobileLoading: true,
      };
    case CHECK_DUPLICATE_MOBILE_SUCCESS:
      return {
        ...state,
        checkDuplicateMobileLoading: false,
        checkDuplicateMobileData: true,
        checkIsDuplicateMobile: action.result.isDuplicateMobile,
      };
    case CHECK_DUPLICATE_MOBILE_FAILURE:
      return {
        ...state,
        checkDuplicateMobileLoading: false,
        checkDuplicateMobileError: action.payload,
      };
    case CHECK_DUPLICATE_EMAIL_REQUESTED:
      return {
        ...state,
        checkDuplicateEmailLoading: true,
      };
    case CHECK_DUPLICATE_EMAIL_SUCCESS:
      return {
        ...state,
        checkDuplicateEmailLoading: false,
        checkDuplicateEmailData: true,
        checkIsDuplicateEmail: action.result.isDuplicateEmail,
      };
    case CHECK_DUPLICATE_EMAIL_FAILURE:
      return {
        ...state,
        checkDuplicateEmailLoading: false,
        checkDuplicateEmailError: action.payload,
      };

    // GsnN Reducer
    case CHECK_GSTN_SUCCESS:
      return {
        ...state,
        checkDuplicateEmailLoading: false,
        checkDuplicateEmailData: true,
        checkDuplicateGstNo: action.result.isDuplicateGstn,
      };
    case CHECK_GSTN_FAILURE:
      return {
        ...state,
        checkDuplicateGstNo: null,
      };

    case CLEAR_AUTH:
      return initialState;

    case VERIFY_RESEND_OTP_SUCCESS:
      return {
        ...state,
        verifyMobileOTPLoading: false,
        verifyMobileOTPLoaded: false,
        verifyEmailOTPLoading: false,
        otpError: false,
      };

    case SET_AGENT_CODE:
      return { ...state, agentCode: action.payload };

    case VALIDATE_AGENT_CODE_REQUESTED:
      return {
        ...state,
        isAgentCodeValid: action.result?.valid,
        isAgentCodeValidLoading: true,
      };

    case VALIDATE_AGENT_CODE_SUCCESS:
      return {
        ...state,
        isAgentCodeValid: action.result?.valid,
        isAgentCodeValidLoading: false,
      };

    case VALIDATE_AGENT_CODE_FAILURE:
      return {
        ...state,
        isAgentCodeValid: action.result?.valid,
        isAgentCodeValidError: action.result?.error,
        isAgentCodeValidLoading: false,
      };

    case USER_KNOWS_ABOUT_HELP_BUTTON_SUCCESS:
      return {
        ...state,
        authedUserData: {
          ...state.authedUserData,
          accessedHelp: true,
        }
      };

    case USER_KNOWS_ABOUT_HELP_BUTTON_REQUESTED:
      return {
        ...state,
      };

    case USER_KNOWS_ABOUT_HELP_BUTTON_FAILURE:
      return {
        ...state,
        authedUserData: {
          ...state.authedUserData,
          accessedHelp: false,
        }
      };

    default:
      return state;

    case USERINFO_VERIFY_OTP_REQUESTED:
      return {
        ...state,
        userInfoOtpLoading: true,
        otpError: false,
      };
    case USERINFO_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        userInfoOtpLoading: false,
        userInfoOtpLoaded: true,
        userInfoOtpSuccess: true,
        otpError: false,
      };
    case USERINFO_VERIFY_OTP_FAILURE:
      return {
        ...state,
        userInfoOtpLoading: false,
        userInfoOtpLoaded: true,
        userInfoOtpError: true,
        otpError: true,
      };
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        loading: action.payload,
      };
    case RESET_STATE:
      return {
        ...state,
        verifyEmailOTPData: undefined,
        emailOtpError: false,
        verifyMobileOTPData: undefined,
        mobileOtpError: false,
        loginMobileOtpSuccess: false,
        verifyEmailOTPMessage: "",
        errorData: "",
      };
    case RESET_AUTH_STATE:
      return {
        ...state,
        authedUserData: action.payload,
      };

    case NOT_VERIFIED_DATA: {
      return {
        ...state,
        userNotVerified: action.payload,
      };
    }

    case SET_RESET_PWD_TOKEN: {
      return {
        ...state,
        resetPwdToken: action.payload,
      };
    }

    case GENERATE_OTP_FOR_NEW_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        generateOTPLoading: false,
        generateOTPError: action?.error?.message,
      };
    }

    case GENERATE_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED: {
      return { ...state, generateOTPLoading: true };
    }

    case GENERATE_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        generateOTPLoading: false,
        generateOTPError: null,
        generatedOTPData: action?.result,
      };
    }

    case VERIFY_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED: {
      return { ...state, isOTPVerificationLoading: true };
    }

    case VERIFY_OTP_FOR_NEW_PHONE_NUMBER_FAILURE: {
      return {
        ...state,
        isOTPVerificationLoading: false,
        isOTPVefificationError: action?.error?.message,
      };
    }

    case VERIFY_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS: {
      return {
        ...state,
        isOTPVefificationError: null,
        isOTPVerificationLoading: false,
        verifyOTPForNewPhoneNumberData: action?.result,
      };
    }
  }
};

export const storeUserData = (dispatch, data) => {
  dispatch({
    type: AUTH_STORE,
    payload: data,
  });
};

export const setIsLoading = (dispatch, data) => {
  dispatch({
    type: SET_IS_LOADING,
    payload: data.payload,
  });
};

export const login = data => ({
  types: [AUTH_REQUESTED, AUTH_SUCCESS, AUTH_FAILURE],
  promise: client =>
    client.post("login", {
      data,
    }),
});

export const register = data => ({
  types: [SIGNUP_REQUESTED, SIGNUP_SUCCESS, SIGNUP_FAILURE],
  promise: client =>
    client.post("register", {
      data,
    }),
});

export const checkDuplicateEmail = data => ({
  types: [CHECK_DUPLICATE_EMAIL_REQUESTED, CHECK_DUPLICATE_EMAIL_SUCCESS, CHECK_DUPLICATE_EMAIL_FAILURE],
  promise: client =>
    client.post("check_duplicate_email", {
      data,
    }),
});

export const checkDuplicateMobile = data => ({
  types: [CHECK_DUPLICATE_MOBILE_REQUESTED, CHECK_DUPLICATE_MOBILE_SUCCESS, CHECK_DUPLICATE_MOBILE_FAILURE],
  promise: client =>
    client.post("check_duplicate_mobile", {
      data,
    }),
});

export const checkFakeDuplicateMobile = (dispatch, bool) => {
  dispatch({
    type: CHECK_DUPLICATE_MOBILE_SUCCESS,
    result: { isDuplicateMobile: bool },
  });
};

export const resendMobileOTP = data => ({
  types: [VERIFY_MOBILE_OTP_REQUESTED, VERIFY_RESEND_OTP_SUCCESS, VERIFY_MOBILE_OTP_FAILURE],
  promise: client =>
    client.post("resend_mobile_otp", {
      data,
    }),
});

export const resendUserInfoOTP = data => ({
  types: [VERIFY_MOBILE_OTP_REQUESTED, VERIFY_RESEND_OTP_SUCCESS, VERIFY_MOBILE_OTP_FAILURE],
  promise: client =>
    client.post("userinfo_otp_resend", {
      data,
    }),
});

export const resendEmailOTP = data => ({
  types: [VERIFY_EMAIL_OTP_REQUESTED, VERIFY_RESEND_OTP_SUCCESS, VERIFY_EMAIL_OTP_FAILURE],
  promise: client =>
    client.post("resend_email_otp", {
      data,
    }),
});

export const verifyLoginMobileOTP = data => ({
  types: [AUTH_REQUESTED, AUTH_SUCCESS, AUTH_FAILURE],
  promise: client =>
    client.post("verify_login_mobile_otp", {
      data,
    }),
});

export const verifyEmailOTP = data => ({
  types: [AUTH_REQUESTED, VERIFY_EMAIL_OTP_SUCCESS, AUTH_FAILURE],
  promise: client =>
    client.post("verify_email_otp", {
      data,
    }),
});

export const verifyEmailLink = id => ({
  types: [VERIFY_EMAIL_LINK_REQUESTED, VERIFY_EMAIL_LINK_FAILURE, VERIFY_EMAIL_LINK_SUCCESS],
  promise: client => client.get(`verify_email_link/${id}`),
});

export const verifyMobileOTP = data => ({
  types: [VERIFY_MOBILE_OTP_REQUESTED, VERIFY_MOBILE_OTP_SUCCESS, VERIFY_MOBILE_OTP_FAILURE],
  promise: client =>
    client.post("verify_mobile_otp", {
      data,
    }),
});

/**
 * 
 * @param {{ password: string, email: string, resetPwdToken: string }} data
 */
export const requestResetPassword = data => ({
  types: [RESET_PASSWORD_REQUESTED, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
  promise: client =>
    client.post("request_reset_password", {
      data,
    }),
});

export const setUserData = data => ({
  type: AUTH_REGISTRATION,
  payload: data,
});

export const setKarzaData = data => ({
  type: AUTH_KARZA_DATA,
  payload: data,
});

export const requestLoginMobileOtp = data => ({
  types: [AUTH_MOBILE_OTP_REQUESTED, AUTH_MOBILE_OTP_SUCCESS, AUTH_MOBILE_OTP_FAILURE],
  promise: client =>
    client.post("request_login_mobile_otp", {
      data,
    }),
});

export const requestEmailorMobileOtp = data => ({
  types: [AUTH_MOBILE_OTP_REQUESTED, AUTH_MOBILE_OTP_SUCCESS, AUTH_MOBILE_OTP_FAILURE],
  promise: client =>
    client.post("request_login_mobile_otp", {
      data,
    }),
});

export const requestEmailMobileOtp = data => ({
  types: [AUTH_EMAIL_OTP_REQUESTED, AUTH_EMAIL_OTP_SUCCESS, AUTH_EMAIL_OTP_FAILURE],
  promise: client =>
    client.post("request_email_otp", {
      data,
    }),
});

/**
 *
 * @param {{ email: string }} data
 */
export const sendEmailVerificationLink = data => ({
  types: [SEND_VERIFY_EMAIL_REQUESTED, SEND_VERIFY_EMAIL_SUCCESS, SEND_VERIFY_EMAIL_FAILURE],
  promise: client =>
    client.post(`email_verification_link`, {
      data,
    }),
});
/**
 *
 * @param {{ email: string }} data
 */
export const verifyEmailVerificationLink = (id) => ({
  types: [VERIFY_EMAIL_REQUESTED, VERIFY_EMAIL_SUCCESS, VERIFY_EMAIL_FAILURE],
  promise: client =>
    client.put(`verify_email_link_dashboard/${id}`),
});

export const clearAuthStore = () => ({
  type: CLEAR_AUTH,
});

export const checkDuplicateGst = data => ({
  types: [CHECK_DUPLICATE_MOBILE_REQUESTED, CHECK_GSTN_SUCCESS, CHECK_GSTN_FAILURE],
  promise: client =>
    client.post("verify_gstn_number", {
      data,
    }),
});

export const userInfoOtpVerify = data => ({
  types: [USERINFO_VERIFY_OTP_REQUESTED, USERINFO_VERIFY_OTP_SUCCESS, USERINFO_VERIFY_OTP_FAILURE],
  promise: client =>
    client.post("userinfo_otp_verify", {
      data,
    }),
});

export const resetOtpState = dispatch => {
  dispatch({
    type: RESET_STATE,
    payload: "",
  });
};

export const forgotPassword = data => ({
  types: [AUTH_MOBILE_OTP_REQUESTED, AUTH_MOBILE_OTP_SUCCESS, AUTH_MOBILE_OTP_FAILURE],
  promise: client =>
    client.post("forgot_password_email", {
      data,
    }),
});

export const requestChangePasswordEmail = data => ({
  types: [AUTH_EMAIL_LINK_REQUESTED, AUTH_EMAIL_LINK_SUCCESS, AUTH_EMAIL_LINK_FAILURE],
  promise: client =>
    client.post("request_change_password_email", {
      data,
    }),
});

export const setNotVerifiedData = (dispatch, data) => {
  dispatch({
    type: NOT_VERIFIED_DATA,
    payload: data,
  });
};
export const resetAuthData = dispatch => {
  dispatch({
    type: RESET_AUTH_STATE,
    payload: {},
  });
};

export const resetUserNotVerifiedData = dispatch => {
  dispatch({
    type: USER_NOT_VERIFIED_STATE,
    payload: {},
  });
};
export const validateAgentCode = agentCode => {
  return {
    types: [VALIDATE_AGENT_CODE_REQUESTED, VALIDATE_AGENT_CODE_SUCCESS, VALIDATE_AGENT_CODE_FAILURE],
    promise: client => client.post(`agent_code_tracking/${agentCode}`),
  };
};

export const setResetPwdToken = resetPwdToken => ({
  type: SET_RESET_PWD_TOKEN,
  payload: resetPwdToken,
});

export const logOut = () => ({
  types: [AUTH_REQUESTED, LOG_OUT_SUCCESS, AUTH_FAILURE],
  promise: client => client.post("logout", {}),
});

/**
 * used to generate OTP(to verify Phone Number) in Registration Page
 * @param {{ mobileNo: string }} data
 */
export const generateOTP = data => ({
  types: [
    GENERATE_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED,
    GENERATE_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS,
    GENERATE_OTP_FOR_NEW_PHONE_NUMBER_FAILURE,
  ],
  promise: client => client.post("generate_otp", { data }),
});

/**
 * verifies OTP(sent to Phone No.) in Registration Page
 * @param {{ mobileNo: string, otp: string }} data
 */
export const verifyGeneratedOTP = data => ({
  types: [
    VERIFY_OTP_FOR_NEW_PHONE_NUMBER_REQUESTED,
    VERIFY_OTP_FOR_NEW_PHONE_NUMBER_SUCCESS,
    VERIFY_OTP_FOR_NEW_PHONE_NUMBER_FAILURE,
  ],
  promise: client => client.post("verify_generated_otp", { data }),
});

/**
 * @param {{ email: string }} data
 */
export const updateUserKnowsAboutHelpButton = data => {
  return {
    types: [
      USER_KNOWS_ABOUT_HELP_BUTTON_REQUESTED,
      USER_KNOWS_ABOUT_HELP_BUTTON_SUCCESS,
      USER_KNOWS_ABOUT_HELP_BUTTON_FAILURE,
    ],
    promise: client => client.post("user_knows_about_help_button", { data }),
  }
};