import { notification } from "antd";

const notificationFunc = (type: 'success' | 'info' | 'error', message="")  => {
  const constants = {
    success: { desc: "Your changes have been saved.", message: "Success" },
    error: { desc: "Please try again.", message: "Error" },
    info: { desc: "Something went wrong", message: "Info" },
  };
  notification[type]({
    message: constants[type]?.message,
    description: message?.length ? message : constants[type]?.desc
  });
};

export default notificationFunc;