import React from "react";
import styled from "styled-components";
import { Tag, Tooltip } from "antd";
import { Label } from "../../Label";
import CardSpinner from "components/Spinner/CardSpinner";
interface CardTagProps {
  cardText: string;
  usedFor:
    | "selectItem"
    | "selectItemWithLogo"
    | "fileUploadTag"
    | "fileUploadReferenceTag"
    | "dropDownCard";
  onClose?: (e: any) => void;
  closeIcon?: any;
  fileSize?: number;
  closable?: boolean;
  loading?: boolean;
  cursorType?: "pointer" | "default";
  keys?: number;
  usedAt?: string;
  data?: any;
  multiLine?: boolean;
  labelFontVariant?: "medium" | "bold";
  labelFontColor?: string;
}

const TagAntd = styled(Tag)<CardTagProps>`
  display: flex;
  align-items: center;
  background-color: ${props =>
    `${props.theme.card.types.cardTags[props.usedFor].backgroundColor}`};
  padding: 10px 10px 10px 10px;
  border-radius: ${props =>
    `${props.theme.card.types.cardTags[props.usedFor].borderRadius}`};
  width: 127px;
  border: none;
  cursor: ${props => `${props.cursorType || "default"}`};
  justify-content: space-between;
  & :hover {
    opacity: unset;
  }
`;

const CardLocationTag = ({ ...props }: CardTagProps) => {
  const shortenText = (text: string) => {
    if (text) {
      const value = text.split(".");
      if (value[0].length > 20 && props.usedAt === "experience") {
        return `${value[0].substring(0, 14)}...${value[0].substring(
          value[0].length - 4
        )}.${value[1]}`;
      }
    }

    return text;
  };
  return (
    <div style={{ marginTop: "3px" }} key={props.keys}>
      {
        <TagAntd
          key={props.cardText}
          icon={
            props.usedFor === "selectItemWithLogo" && (
              <img src="/images/ProfileCreation/info.svg"></img>
            )
          }
          {...props}
          closable={!props.closable}
          closeIcon={
            props.closeIcon ? (
              <img src={props.closeIcon}></img>
            ) : (
              <img src="/images/xCircle.svg"></img>
            )
          }
          onClose={e => props.onClose(e)}
        >
          <Tooltip title={props.cardText}>
            <Label
              text={props.cardText}
              htmlFor="profileHeader"
              fontVariant={ props?.labelFontVariant || "bold"}
              multiLine={props?.multiLine}
              fontColor={props?.labelFontColor}
            />
          </Tooltip>
        </TagAntd>
      }
    </div>
  );
};

export default CardLocationTag;
