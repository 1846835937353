
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useField } from "formik";
import Input from "../InputBox/input";
import InputWithHeader from "../InputWithHeader/input";
import AntButton, { ButtonProps as AntButtonProps } from 'antd/es/button';
import { Button } from "antd";
import InputBoxForEdit from "../InputBoxWithEdit/inputBox";
import InputWithHeaderForEdit from "../InputBoxWithEdit/inputHeader";
import EditAndDelete from "components/EditAndDelete/EditAndDelete";

type Sizes =
  | "small"
  | "semiMiddle"
  | "medium"
  | "middle"
  | "large"
  | "extraLarge"
  | "profileCreationmedium"
  | "profileCreationSmall"
  | undefined;


export interface InputBoxProps {
  hide?: boolean,
  valueFirstField?: any,
  valueSecondField?: any,
  valueThirdField?: any,
  onHandleDelete?: (e, value) => void,
  indexValue?: number
}
const ButtonAntD = styled(AntButton)`
width: 100px;
 height: 60px;
 border-radius:8px;
 
`
const Wrapper = styled.div`
display:flex;
width:100%;
align-items: flex-end;
margin-bottom:12px


`
const Div = styled.div`
margin-right:10px
`
const ThreeInputFieldWithEdit = ({ hide = true, indexValue, onHandleDelete, valueFirstField, valueSecondField, valueThirdField }: InputBoxProps) => {
  const [disable, setDisable] = useState(true);
  const inputRef = useRef(null);

  const handleClick = () => {

    setDisable(!disable)
  }
  return (
    <>
      <Wrapper>
        <Div>
          <InputBoxForEdit
            usedFor="capability"
            sizes={"profileCreationLarge"}
            buttonDisable={true}
            placeholder={"Enter City Name"}
            inputTextType={"text"}
            importanceType={"important"}
            // fields={field}
            // otherProps={props}
            // setRef={refValue}
            // disabled={disable}
            value={valueFirstField}
            buttonType={disable}

          // error={props.formikError}
          // touch={props.touchedResponse}
          />

        </Div>
        <Div>
          <InputBoxForEdit
            usedFor="capability"
            sizes={"profileCreationMedium"}
            inputTextType={"text"}
            importanceType={"important"}
            buttonDisable={true}
            value={valueSecondField}
            // fields={field}
            // otherProps={props}
            // setRef={refValue}
            // disabled={disable}
            placeholder={"Enter Make"}
            buttonType={disable}

          />
        </Div>
        <Div>
          <InputBoxForEdit
            usedFor="capability"
            sizes={"profileCreationSmall"}
            inputTextType={"text"}
            importanceType={"important"}
            buttonDisable={true}
            value={valueThirdField}
            // fields={field}
            // otherProps={props}
            // setRef={refValue}
            // disabled={disable}
            buttonType={disable}
            placeholder={"Enter Capacity"}
          // buttonType={buttonType}
          />
        </Div>
        {hide && <EditAndDelete indexValue={indexValue} onHandleClick={(e, index) => {
          onHandleDelete(e, index)
        }} />}
      </Wrapper>
    </>
  );
};

export default ThreeInputFieldWithEdit;
