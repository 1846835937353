import { Types as FileUploadTypes } from "./types";

const initialState = {
  isUploading: false,
  fileUploadData: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case FileUploadTypes.FILE_OPERATIONS_REQUEST:
      return {
        isUploading: true,
        ...state,
      };
    case FileUploadTypes.UPLOAD_FILES_TO_PERMANENT_FOLDER_SUCCESS:
      return {
        ...state,
        isUploading: false,
        fileUploadData: action.result,
      };
    case FileUploadTypes.FILE_OPERATIONS_FAILURE: 
      return {
        ...state,
        isUploading: false,
      };
    default:
      return state;
  }
};
