/** Types */
export const TYPES = {
  GET_NEO4J_OEM_TAGS_REQUESTED: "tags/GET_NEO4J_OEM_TAGS_REQUESTED",
  GET_NEO4J_OEM_TAGS_SUCCESS: "tags/GET_NEO4J_OEM_TAGS_SUCCESS",
  GET_NEO4J_OEM_TAGS_FAILED: "tags/GET_NEO4J_OEM_TAGS_FAILED",
} as const;

type KEYS = keyof typeof TYPES;
type ACTION_TYPES = typeof TYPES[KEYS];

export const initialState = {
  fetchingData: false,
  isFetchingComplete: false,
  error: "",
  neo4jOemTags: [] as any[],
};

/** Reducer */
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_NEO4J_OEM_TAGS_REQUESTED:
      return {
        ...state,
        fetchingData: true,
        isFetchingComplete: false,
        error: "",
      };
    
    case TYPES.GET_NEO4J_OEM_TAGS_SUCCESS:
      return {
        ...state,
        fetchingData: false,
        isFetchingComplete: true,
        neo4jOemTags: action?.result || [],
      };

    case TYPES.GET_NEO4J_OEM_TAGS_FAILED:
      return {
        ...state,
        fetchingData: false,
        isFetchingComplete: false,
        error: action.error.message,
      };

    default:
      return state;
  }
}

/** Actions */
export const getNeo4JOEMTags = () => {
  return {
    types: [
      TYPES.GET_NEO4J_OEM_TAGS_REQUESTED,
      TYPES.GET_NEO4J_OEM_TAGS_SUCCESS,
      TYPES.GET_NEO4J_OEM_TAGS_FAILED,
    ],
    promise: (client) => client.get("get_neo4j_oem_tags", {}),
  };
};