import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileLayoutError, toggleIsPageEdited } from "reducers/profileCreation";
import { getGstn } from "constants/auth";
import { Spin } from "antd";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import notificationFunc from "components/Notification";
import _ from "lodash";
import { createFinanceInfo, getFinanceInfo, updateFinanceInfo } from "reducers/finance";
import {
  InputDiv,
  ButtonWrapper,
  Spinner,
  RowWrapper,
  AddBttn,
  ColumnDiv,
  InputWrapper,
  UploadButton,
  LogoUpload,
  DocumentUploadDiv,
  UploadText,
  ContentInputs,
} from "Profile/Finance/Finance.styles";
import FormField from "Profile/shared/FormField/FormField";
import { InputWithEdit } from "components/Input";
import { CertOrOem } from "Profile/Capabilities/components/CertOrOem";
import { fileUploadPost } from "reducers/fileUpload";
import { yearOptions } from "./constant";
import { trimmedString } from "./helper";
import { handleScrollToView } from "shared/handlers/form.handler";
import { LogoImage } from "components/Image";
import { CardTag } from "components/Card";
import { HEAP_DATA_ATTRIBUTES, HEAP_DATA_TRACKING_ID } from "constants/heapAnalytics";
import { Box } from "venwiz-ui";

const Finance = () => {
  const initialFinanceState = {
    contact: "",
    email: "",
    incomeTaxDetails: [
      {
        year: "",
        name: "",
        file: {
          fileName: "",
          fileUrl: "",
          fileSize: "",
        },
      },
    ],
    cancelledCheque: {
      name: "",
      file: {
        fileName: "",
        fileUrl: "",
        fileSize: "",
      },
    },
    accountNumber: "",
    ifscCode: "",
    bankName: "",
    accountName: "",
  };
  let isFirstError = false;
  const dispatch = useDispatch();
  const buttonState = useSelector((state: any) => state.profileCreation);
  const financeState = useSelector((state: any) => state.finance);
  const { financeInfo, loaded, error } = financeState;
  const gstn = getGstn();
  const authState = useSelector((state: any) => state.auth);
  const { isLoading } = authState;
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [financeData, setFinanceData] = useState(initialFinanceState);
  const [inputSizeExceeds, setInputSizeExceeds] = useState(false);

  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false);
    dispatch(getFinanceInfo({}));
  }, []);

  useEffect(() => {
    if (financeInfo) {
      if (!financeInfo.incomeTaxDetails || financeInfo.incomeTaxDetails?.length === 0) {
        const itr = [
          {
            year: "",
            name: "",
            file: {
              fileName: "",
              fileUrl: "",
              fileSize: "",
            },
          },
        ];
        financeInfo["incomeTaxDetails"] = itr;
      }
      setFinanceData(financeInfo);
    }
  }, [financeInfo]);

  const validateInput = () => {
    if (financeData?.contact?.length && financeData?.email?.length && financeData?.cancelledCheque?.file?.fileUrl?.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleSaveClick = (value) => {
    const validation = validateInput();
    if (value === "save") {
      setIsDataSaving(true);
      setSaveState(true);
      if (validation) {
        setSaveState(false);
        if (financeInfo?.referenceId) {
          dispatch(updateFinanceInfo(financeData))
            .then(() => {
              setIsDataSaving(false);
              toggleIsPageEdited(dispatch, false);
              dispatch(getFinanceInfo({}));
              notificationFunc("success");
            })
            .catch(() => {
              setIsDataSaving(false);
            });
        } else {
          if (financeData.incomeTaxDetails?.length === 1 && financeData.incomeTaxDetails?.[0]?.file?.fileUrl) {
            financeData.incomeTaxDetails = [];
          }
          dispatch(createFinanceInfo(financeData))
            .then(() => {
              setIsDataSaving(false);
              toggleIsPageEdited(dispatch, false);
              dispatch(getFinanceInfo({}));
              notificationFunc("success");
            })
            .catch(() => {
              setIsDataSaving(false);
            });
        }
      } else {
        setIsDataSaving(false);
      }
    } else {
      setIsDataSaving(false);
    }
  };

  const onHandleScroll = (id) => {
    if (!isFirstError) {
      handleScrollToView(id);
      isFirstError = true;
    }
    return true;
  };

  const handleAddNew = () => {
    const financeDataCopy = _.cloneDeep(financeData);
    if (financeDataCopy.incomeTaxDetails?.length < 3) {
      financeDataCopy.incomeTaxDetails.push({
        year: "",
        name: "",
        file: {
          fileName: "",
          fileUrl: null,
          fileSize: "",
        },
      });
    }
    setFinanceData(financeDataCopy);
  };

  const handleITRChange = (type, method, value, index) => {
    toggleIsPageEdited(dispatch, true);
    const financeDataCopy = _.cloneDeep(financeData);
    if (method === "UPDATE") {
      financeDataCopy.incomeTaxDetails[index].year = value;
    } else if (method === "DELETE") {
      if (index === 0) {
        financeDataCopy.incomeTaxDetails[0] = initialFinanceState.incomeTaxDetails[0];
      } else {
        financeDataCopy.incomeTaxDetails?.splice(index, 1);
      }
    }
    setFinanceData(financeDataCopy);
  };

  const handleInputChange = (value, key) => {
    setFinanceData({
      ...financeData,
      [key]: value,
    });
    toggleIsPageEdited(dispatch, true);
  };

  const getFile = (e: any, type, index = -1) => {
    let file = e.target.files[0];
    const financeDataCopy = _.cloneDeep(financeData);

    if (typeof file !== "undefined" && file?.size / 1024 / 1024 <= 10) {
      dispatch(fileUploadPost(file)).then(
        (res) => {
          const fileData = {
            year: financeDataCopy.incomeTaxDetails[index].year,
            name: file?.name || res.fileName,
            file: res,
          };
          financeDataCopy.incomeTaxDetails[index] = fileData;
          setFinanceData(financeDataCopy);
          toggleIsPageEdited(dispatch, true);
        },
        (error) => {}
      );
    }
  };

  const handleCancelledCheckUpload = (e) => {
    let file = e.target.files[0];
    const financeDataCopy = _.cloneDeep(financeData);

    if (typeof file !== "undefined" && file?.size / 1024 / 1024 <= 10) {
      dispatch(fileUploadPost(file)).then(
        (res) => {
          const fileData = {
            name: file?.name || res.fileName,
            file: res,
          };
          financeDataCopy["cancelledCheque"] = fileData;

          setFinanceData(financeDataCopy);
          toggleIsPageEdited(dispatch, true);
        },
        (error) => {}
      );
    } else {
      setInputSizeExceeds(true);
    }
  };

  return (
    <Box width={{
      default: "100%",
      sm: "75vw",
    }}>
      {isLoading && (
        <Spinner>
          <Spin style={{ position: "absolute", top: "50%" }} size="large" tip="Loading..." />
        </Spinner>
      )}

      <ContentInputs>
        <RowWrapper>
          <InputDiv id="contact">
            <FormField showError={saveState && !financeData?.contact?.length} label={"Registered Contact"} isStar={true} isPaddingNotRequired={true}>
              <InputWithEdit
                inputFieldError={saveState && !financeData?.contact?.length ? onHandleScroll("contact") : false}
                withHeader={false}
                usedFor="profileCreation"
                inputBoxSize="profileCreationMedium"
                inputType="text"
                importance="important"
                value={financeData.contact}
                name="contact"
                onChange={(e) => {
                  handleInputChange(e.target.value, "contact");
                }}
                placeholder="Enter Registered Contact"
              ></InputWithEdit>
            </FormField>
          </InputDiv>

          <InputDiv id="email">
            <FormField showError={saveState && !financeData?.email?.length} label={"Registered Email"} isStar={true} isPaddingNotRequired={true}>
              <InputWithEdit
                inputFieldError={saveState && !financeData?.email?.length ? onHandleScroll("email") : false}
                withHeader={false}
                usedFor="profileCreation"
                inputBoxSize="profileCreationMedium"
                inputType="text"
                importance="important"
                value={financeData.email}
                name="email"
                onChange={(e) => {
                  handleInputChange(e.target.value, "email");
                }}
                placeholder="Enter Registered Email"
              ></InputWithEdit>
            </FormField>
          </InputDiv>
        </RowWrapper>

        <ColumnDiv>
          <FormField
            isStar={false}
            info="We require ITR for previous 3 years."
            label={"Income Tax Return (" + financeData?.incomeTaxDetails?.length + "/3)"}
            showError={false}
            isPaddingNotRequired={false}
            children={""}
          ></FormField>

          <>
            {financeData?.incomeTaxDetails?.map((tag, index) => (
              <CertOrOem
                optionsLibrary={yearOptions}
                selectedValue={tag?.year}
                uploadedDoc={tag?.file?.fileUrl ? tag : null}
                getFile={getFile}
                selectedFile={tag}
                handleChange={handleITRChange}
                key={index}
                index={index}
                usedFor="ITR"
                inputPlaceholder="Select Financial Year"
                uploadText="Upload ITR"
              />
            ))}
            {financeData?.incomeTaxDetails?.length<3 && <AddBttn onClick={() => handleAddNew()}>+ Add New</AddBttn>}
          </>
        </ColumnDiv>

        <>
          <FormField
            showError={(saveState && !financeData?.cancelledCheque?.file?.fileUrl?.length) || inputSizeExceeds}
            isStar={true}
            label={"Payment Details"}
            isPaddingNotRequired={false}
            customErrorMessage={inputSizeExceeds ? "Supports .pdf, .jpeg, .png formats. Max. file size 10MB" : null}
          >
            <DocumentUploadDiv id="cheque">
              <UploadButton 
                errorState={saveState && !financeData?.cancelledCheque?.file?.fileUrl?.length}
                {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.FINANCIAL_INFO.UPLOAD_CHECK_BUTTON }}
              >
                <InputWrapper
                  key={financeData?.cancelledCheque?.file?.fileUrl || ""}
                  type="file"
                  accept="image/jpeg,image/png,application/pdf"
                  onChange={(e) => handleCancelledCheckUpload(e)}
                />
                <LogoUpload>
                  <LogoImage imageSrc="/images/Compliance/compliance_upload.svg" altMesg="status" imageType="icon" />
                </LogoUpload>
                <UploadText>Upload Cancelled Cheque</UploadText>
              </UploadButton>

              {financeData?.cancelledCheque?.file?.fileUrl?.length && (
                <CardTag
                  onClose={() => {
                    setFinanceData({
                      ...financeData,
                      cancelledCheque: {
                        name: "",
                        file: {
                          fileName: "",
                          fileUrl: null,
                          fileSize: "",
                        },
                      },
                    });
                    setInputSizeExceeds(false);
                    toggleIsPageEdited(dispatch, true);
                  }}
                  usedFor="fileUploadTag"
                  cardText={trimmedString(financeData?.cancelledCheque?.name, 15)}
                  closable={false}
                  data={financeData?.cancelledCheque}
                  height="40px"
                  key={financeData?.cancelledCheque?.file?.fileUrl}
                  labelWhiteSpace="nowrap"
                />
              )}
            </DocumentUploadDiv>
          </FormField>
        </>

        <RowWrapper>
          <InputDiv id="accountNumber">
            <FormField showError={false} label={"Bank Account Number"} isStar={false} isPaddingNotRequired={true}>
              <InputWithEdit
                withHeader={false}
                usedFor="profileCreation"
                inputBoxSize="profileCreationMedium"
                inputType="text"
                importance="important"
                value={financeData?.accountNumber}
                name="accountNumber"
                onChange={(e) => {
                  handleInputChange(e.target.value, "accountNumber");
                }}
                placeholder="Enter Bank Account Number"
                inputDataAttribute={{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.FINANCIAL_INFO.TEXT_FIELD.BANK_ACC_NUMBER }}
              ></InputWithEdit>
            </FormField>
          </InputDiv>

          <InputDiv id="ifscCode">
            <FormField showError={false} label={"Bank IFSC Code"} isStar={false} isPaddingNotRequired={true}>
              <InputWithEdit
                withHeader={false}
                usedFor="profileCreation"
                inputBoxSize="profileCreationMedium"
                inputType="text"
                importance="important"
                value={financeData?.ifscCode}
                name="ifscCode"
                onChange={(e) => {
                  handleInputChange(e.target.value, "ifscCode");
                }}
                placeholder="Enter IFSC Code"
                inputDataAttribute={{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.FINANCIAL_INFO.TEXT_FIELD.IFSC_CODE }}
              ></InputWithEdit>
            </FormField>
          </InputDiv>
        </RowWrapper>

        <RowWrapper>
          <InputDiv id="bankName">
            <FormField showError={false} label={"Bank Name"} isStar={false} isPaddingNotRequired={true}>
              <InputWithEdit
                withHeader={false}
                usedFor="profileCreation"
                inputBoxSize="profileCreationMedium"
                inputType="text"
                importance="important"
                value={financeData?.bankName}
                name="bankName"
                onChange={(e) => {
                  handleInputChange(e.target.value, "bankName");
                }}
                placeholder="Enter Bank Name"
                inputDataAttribute={{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.FINANCIAL_INFO.TEXT_FIELD.BANK_NAME }}
              ></InputWithEdit>
            </FormField>
          </InputDiv>

          <InputDiv id="accountName">
            <FormField showError={false} label={"Account Holder Name"} isStar={false} isPaddingNotRequired={true}>
              <InputWithEdit
                withHeader={false}
                usedFor="profileCreation"
                inputBoxSize="profileCreationMedium"
                inputType="text"
                importance="important"
                value={financeData?.accountName}
                name="accountName"
                onChange={(e) => {
                  handleInputChange(e.target.value, "accountName");
                }}
                placeholder="Enter Account Holder Name"
                inputDataAttribute={{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.FINANCIAL_INFO.TEXT_FIELD.ACC_HOLDER_NAME }}
              ></InputWithEdit>
            </FormField>
          </InputDiv>
        </RowWrapper>
      </ContentInputs>

      <ButtonWrapper>
        <ProfileCreationButtons
          isloading={isDataSaving}
          buttonText={"SAVE CHANGES"}
          usedAt={"onboarding"}
          state={!buttonState.isPageEdited}
          pagination={true}
          pageNumber={5}
          handleStateChange={(value) => {
            if (value === "cancel") {
            } else {
              isFirstError = true;
              handleSaveClick(value);
            }
          }}
          style={{
            minWidth: "210px",
            height: "40px",
            borderRadius: "4px",
          }}
          dataAttribute={{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.FINANCIAL_INFO.SAVE_AND_CONTINUE }}
        ></ProfileCreationButtons>
      </ButtonWrapper>
    </Box>
  );
};

export default Finance;
