import React from "react";
import styled from "styled-components";
import CardWaterDropHeader from "../CardWaterDropHeader/card";
import { Tooltip } from "antd";

interface CardViewProps {
  cardType: "viewCard" | "referalCard" | "infoCard" | "cardWithDropletHeader" | 'referenceCard';
  header: boolean;
  userType?: "vendor" | "client" | "searchViews";
  children?: React.ReactNode;
  toolTipData?: string;
  cardHeader?: string;
}

const Card = styled.div<CardViewProps>`
  cursor: ${props =>
    `${props.theme.card.types[props.cardType].cursor || "default"}`};
  position: ${props => props.header && "relative"};
  padding: ${props => `${props.theme.card.types[props.cardType].padding || "0px"}`}; 
  width: ${props => `${props.theme.card.types[props.cardType].width}`};
  height: ${props => `${props.theme.card.types[props.cardType].height}`};
  background-color: ${props =>
    `${props.theme.card.types["viewCard"].backgroundColor}`};
  border-radius: ${props =>
    props.header
      ? "0px 30px 30px 30px"
      : `${props.theme.card.types["viewCard"].borderRadius}`};
`;

const CardView = ({ children, ...props }: CardViewProps) => {
  const { header, cardType, cardHeader, toolTipData } = props;
  return (
    <>
      {cardType !== 'referenceCard' && (<Card {...props}>
        {header && (
          <Tooltip title={toolTipData}>
            <CardWaterDropHeader cardType={cardType}>
              {cardHeader}
            </CardWaterDropHeader>
          </Tooltip>
        )}
        {children}
      </Card>)
    }
      {cardType === 'referenceCard' && (<Card {...props}>
        {header && (
          <Tooltip title={toolTipData}>
            <CardWaterDropHeader cardType={cardType}>
              {cardHeader}
            </CardWaterDropHeader>
          </Tooltip>
        )}
        {children}
      </Card>)}

      
    </>
  );
};

export default CardView;
