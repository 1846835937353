import { Types as ERfxTypes } from "./types";

export const ActionCreators: any = {
  getRfqQuotation: (data: { jobRequestId: string, gstn: string }) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.GET_RFQ_QUOTATION_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.get(`get_rfq_quotation/${data.jobRequestId}/${data.gstn}`),
  }),
  publishRfqQuotationFile: (data: any) => ({
    types: [
      ERfxTypes.ERFX_OPERATION_REQUEST,
      ERfxTypes.PUBLISH_RFQ_QUOTATION_FILE_SUCCESS,
      ERfxTypes.ERFX_OPERATION_FAILURE,
    ],
    promise: (client: any) => client.patch("publish_rfq_quotation_file", { data }),
  }),
  submitRfqQuotation: (data: any) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.PUBLISH_RFQ_QUOTATION_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.post("submit_rfq_quotation", { data }),
  }),
  removeRfqDocuments: (data: { requestId: string; name: string }) => ({
    types: [
      ERfxTypes.ERFX_OPERATION_REQUEST,
      ERfxTypes.REMOVE_RFQ_QUOTATION_DOCUMENT_SUCCESS,
      ERfxTypes.ERFX_OPERATION_FAILURE,
    ],
    promise: (client: any) => client.patch("delete_quotation_document", { data }),
  }),
  uploadNewVersion: (data: { requestId: string; name: string }) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.UPLOAD_NEW_VERSION_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.patch("delete_quotation_document", { data }),
  }),
  getFileHistory: (query: string) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.GET_FILE_HISTORY_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.get(`quotation_file_history/${query}`),
  }),
  addQuotations: (data: any) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.SET_QUOTATION_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.patch(`add_quotations`, { data }),
  }),
  restoreQuotationVersion: (data: any) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.RESET_QUOTATION_VERSION_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.patch(`restore_quotation_version`, { data }),
  }),
  setRequestJobID: (payload: any) => ({ type: ERfxTypes.SET_REQUEST_JOB_ID_SUCCESS, payload: payload }),
  resetQuotation: () => ({ type: ERfxTypes.RESET_QUOTATION_SUCCESS }),
  setTechnicalNotes: (payload: any) => ({ type: ERfxTypes.SET_TCEHINACAL_NOTES, payload: payload }),
  setCommercialNotes: (payload: any) => ({ type: ERfxTypes.SET_COMMERCIAL_NOTES, payload: payload }),
  setShowMonetizationModal: (payload: boolean) => {
    return {
      type: ERfxTypes.SET_SHOW_MONETIZATION_MODAL,
      payload
    };
  },
  setShowPQRModal: (payload: boolean) => {
    return {
      type: ERfxTypes.SET_SHOW_PQR_MODAL,
      payload
    };
  },

  getVendorDataSharingConsentStatus: (jobId, gstn) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.GET_VENDOR_DATA_SHARING_CONSENT_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client) =>
      client.get(`get_vendor_data_sharing_consent/${jobId}/${gstn}`, {}),
  }),
  saveVendorDataSharingConsentStatus: (data: any) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.SAVE_VENDOR_DATA_SHARING_CONSENT_SUCCESS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.post("save_vendor_data_sharing_consent", { data }),
  }),
  submitOpenQuotes:(data: any) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.SUBMIT_OPEN_QUOTATION, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.post("submit_open_quotation", { data }),
  }),
  submitPastExperienceOrPODocs:(data: any) => ({
    types: [ERfxTypes.ERFX_OPERATION_REQUEST, ERfxTypes.SUBMIT_PAST_EXPERIENCE_OR_PO_DOCS, ERfxTypes.ERFX_OPERATION_FAILURE],
    promise: (client: any) => client.patch("submit_past_experience_or_po_docs", { data }),
  }),
};

export default ActionCreators;
