import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Formik, useFormik, useFormikContext } from "formik";
import { Button } from "components/Button";
import InputBoxWithIcon from "components/Input/InputBoxWithIcon/input";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAuthStore,
  login,
  requestResetPassword,
  resetAuthData,
  setNotVerifiedData,
  setIsLoading,
} from "reducers/auth";
import SuccessMsg from "Authentication/SuccessMsg/SuccessMsg";
import { useHistory } from "react-router-dom";
import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
import { ApiConfig, AppConfig } from "ui-constants";
import openNotification from "constants/antdNotification";
import PasswordDesc from "Authentication/Register/PasswordDesc";
import { DisplayError, TagDiv } from "../Login.style";
import ResScreenErrorMess from "../Components/ResponsiveScreenErrorMessage";
import AuthenticationTags from "Authentication/AuthenticationTags/AuthenticationTags";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { AuthBttn } from "components/Button/AuthenticationButton/Button.styles";
import { BackBttn, ButtonDiv, Container, Divs, FormContainer, FormDiv, Header } from "./ResetPassword.styles";
import { PasswordProps, userProps } from "./Interfaces/ResetPasswordInterface";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
import { NavigateOrRestrictUserFromOnBoardingStatus, getPreviousGuestVisitedRouteOrMyProfileRoute } from "shared/handlers/restrictOrNavigateToOnBoarding";
import { heapAddUserProperties, heapTrackForSuccessfulLogin, initializeHeapAnalyticsOnlyForNonInternalVenwizUser } from "shared/handlers/heapAnalytics.handler";
import { notification } from "antd";
import { useLocation } from "react-router-dom";
import LogRegLayout from "Authentication/LogRegLayout/LogRegLayout";
import jwt_decode from "jwt-decode";
import { INITIAL_VALUES } from "./ResetPassword.constants";

function ResetPassword({ email }: userProps) {
  const ROUTE_TO_BE_VIEWED_AFTER_LOGIN = getPreviousGuestVisitedRouteOrMyProfileRoute();
  const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{10,}$/;
  const history = useHistory();
  const { STORAGE_KEYS } = AppConfig;
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { errorData, error, authedUserData, loaded, resetPwdToken, resetPasswordLoading } = authState;
  const [errorMess, setErrorMess] = useState("");
  const [bttnClickState, setBttnClickState] = useState(false);
  const initialValues: PasswordProps = INITIAL_VALUES;
  const AUTH_BUTTON_COLOR = "#FFFFFF"
  const AUTH_BUTTON_BG_COLOR = "#47C882"

  const [passwordStrength, setPasswordStrength] = useState(0);
  useEffect(() => {
    if (resetPwdToken === null) {
      history.push("/login");
    }
    setPasswordStrength(0);
  }, []);

  const handleClick = () => { };
  const validate = Yup.object().shape({
    password: Yup.string()
      .min(10, "Please enter your password in the correct format")
      .max(50, "Max 50 characters allowed")
      .matches(passwordRegex, "Please enter your password in the correct format")
      .required("Please enter a password"),
    confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const onResetSuccess = (email, password) => {
    dispatch(
      login(
        JSON.stringify({
          username: email,
          password,
        }),
      ),
    ).then(
      res => {
        /** For Heap Analytics */
        initializeHeapAnalyticsOnlyForNonInternalVenwizUser(email, window);
        heapAddUserProperties({
          heapWindowObj: (window as any)?.heap,
          value: { uniqueEmail: email, GSTN: res?.gstn },
        });
        heapTrackForSuccessfulLogin({windowObj: window,  email: res?.email, GSTN: res?.gstn });
        history.push(ROUTE_TO_BE_VIEWED_AFTER_LOGIN)
      },
      error => {
        if (error?.email) {
          if (!error?.isMobileVerified) {
            history.push("/registration");
            setNotVerifiedData(dispatch, {
              ...error,
              email,
              password,
            });
          }
        } else {
          openNotification(error.message);
        }
      },
    );
  };

  useEffect(() => {
    if (loaded && !error && authedUserData && bttnClickState) {
      SessionStorageService.set(STORAGE_KEYS.userData, authedUserData);

      LocalStorageService.set(STORAGE_KEYS.isRememberEnabled, 0);
      dispatch(clearAuthStore());
      /** New onboarding Navigation */
      const localStorageUserData = LocalStorageService.get(STORAGE_KEYS.userData);
      NavigateOrRestrictUserFromOnBoardingStatus({
        GSTN: localStorageUserData?.gstn || authedUserData?.gstn,
        dispatch,
        history,
        EMAIL: localStorageUserData?.email || authedUserData?.email,
      });
    }
  }, [authedUserData]);

  const logErr = formik => {
    return true;
  };
  const onSubmitHandler = (values, actions) => {
    setBttnClickState(true);
    const { password } = values;

    const DECODED_RESET_PASSWORD_TOKEN: {
      email: string
    } = jwt_decode(resetPwdToken);

    dispatch(
      requestResetPassword(
        {
          password,
          email: DECODED_RESET_PASSWORD_TOKEN?.email,
          resetPwdToken
        }
      ),
    ).then(
      res => {
        onResetSuccess(res.email, password);
      },
      error => {
        openNotification(error.message);
      },
    );
    actions.setSubmitting(false);
  }
  const onClickBackBttn = (e) => {
    resetAuthData(dispatch);
    setIsLoading(dispatch, { payload: false });
    history.push("/login");
  }
  return (
    <LogRegLayout>
      <Container>
        <Header>Reset Password</Header>
        {!!errorMess?.length && <ResScreenErrorMess>{errorMess}</ResScreenErrorMess>}
        <FormContainer>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            isInitialValid={validate.isValidSync(initialValues)}
            onSubmit={(values, actions) => {
              onSubmitHandler(values, actions)
            }}
          >
            {formik => (
              <FormDiv>
                <Divs $marginBottom={"30px"}>
                  <InputBoxWithIcon
                    inputDataAttribute={{ "data-tracking-id": HEAP_DATA_ATTRIBUTES.FORGOT_PASSWORD.CREATE_PASSWORD }}
                    handlePassword={(e: number): void => {
                      setPasswordStrength(e);
                    }}
                    name="password"
                    inputBoxSize="middle"
                    usedFor="registration"
                    formikError={formik.errors}
                    label="password"
                    withHeader={true}
                    touchedResponse={formik.touched}
                    placeholder={"Create New Password"}
                  ></InputBoxWithIcon>
                </Divs>
                <Divs $marginBottom={errorMess?.length ? "20px" : "0px"}>
                  <InputBoxWithIcon
                    inputDataAttribute={{ "data-tracking-id": HEAP_DATA_ATTRIBUTES.FORGOT_PASSWORD.CONFIRM_PASSWORD }}
                    handlePassword={(e: number): void => { }}
                    name="confirmPassword"
                    inputBoxSize="middle"
                    usedFor="registration"
                    formikError={formik.errors}
                    label="confirmPassword"
                    withHeader={true}
                    touchedResponse={formik.touched}
                    placeholder={"Confirm Password"}
                    errorCallback={(value: string) => {
                      setErrorMess(value);
                    }}
                  ></InputBoxWithIcon>
                </Divs>
                <PasswordDesc />
                <ButtonDiv>
                  <AuthBttn
                    $background={AUTH_BUTTON_BG_COLOR}
                    $color={AUTH_BUTTON_COLOR}
                    disabled={
                      !(
                        formik.values.confirmPassword === formik.values.password &&
                        formik.values.confirmPassword?.length &&
                        formik.values.password?.length &&
                        !formik.errors.password?.length
                      )
                    }
                    onClick={handleClick}
                    data-tracking-id={HEAP_DATA_ATTRIBUTES.FORGOT_PASSWORD.SAVE_CHANGES}
                    loading={resetPasswordLoading}
                  >
                    SAVE CHANGES
                  </AuthBttn>
                </ButtonDiv>
                <BackBttn>
                  <TagDiv
                    onClick={(e) => {
                      onClickBackBttn(e)
                    }}
                  >
                    <AuthenticationTags text={"< Back to Login"} underline="notRequired" textUpperCase />
                  </TagDiv>
                </BackBttn>
              </FormDiv>
            )}
          </Formik>
        </FormContainer>
      </Container>
    </LogRegLayout>
  );
}

export default ResetPassword;
