import React, { useState, useEffect } from "react";
import styled from "styled-components";
import OtpInput from "react-otp-input";
import AuthenticationTags from "../../../Authentication/AuthenticationTags/AuthenticationTags";
import { colors } from "../../../Theme";
import { useDispatch, useSelector } from "react-redux";
import {
  resendMobileOTP,
  resendEmailOTP,
  resendUserInfoOTP,
  resetOtpState,
} from "../../../reducers/auth";
import { desktopStyle, mobileStyle } from "./OtpBoxStyle";
import styles from "./OtpBox.module.scss";

interface OtpProps {
  text: React.ReactNode |string;
  type?: "mobile" | "email" | "userInfoMobile" | "forgotPassword";
  label?: string;
  name?: string;
  error?: any;
  touch?: any;
  reset?: boolean;
  onComplete?: (otp: string) => void;
  value?: string;
  onHandleError?:(type:string)=>void;
  onForgotPasswordResend?:()=>void
}

const OtpContainer = styled.div`
  display: flex;
  align-items: center;
  width:100%;
  justify-content:space-evenly;
  margin-bottom:32px;
  @media only screen and (max-width: 640px) {
    margin-bottom:0px;
  }
`;
const OtpInputWrap = styled(OtpInput)<any>`
  color: ${props => props.theme.colors.primary};
  font-size: 22px;
  input{
    border:${props => props?.$borderColor};
  }
`;
const Title = styled.div`
  color: ${props => props.theme.colors.darkBlue};
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 32px;
  @media only screen and (max-width: 640px) {
    font-weight: 300;
    font-size: 14px;
    text-align:center;
    padding-bottom: 0px;
    margin-bottom:20px;
  }
`;
const OtpDiv = styled.div`
  color: ${props => props.theme.colors.black};
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 640px) {
    padding: 0px;
    font-size: 14px;
    @supports (-webkit-touch-callout: none) {
      margin-bottom:16px;
    }
  }
`;

const OtpPadding = styled(OtpDiv)`
  font-weight: 700;
  font-size: 14px;
  padding: 0px 0px 0px 4px;
  @media only screen and (max-width: 640px) {
    font-weight: 600;
    padding: 0px 2px 0px 4px;
  }
`;


const HideOtpPadding=styled(OtpPadding)`
  @media only screen and (max-width: 640px) {
    display:none;
  }
`
const ShowTimer=styled.div`
  display:none;
  @media only screen and (max-width: 640px) {
    display:flex;
    justify-content:center;
    margin-top:13px;
  }

`
const OtpFailed = styled(OtpDiv)`
  color: ${props => props.theme.colors.darkRed};
  justify-content: space-between;
  @media only screen and (max-width: 640px) {
    display:none;
  }
`;

const HideOtpFailed=styled(OtpFailed)`
  display:block;
  margin-left:6px;
  @media only screen and (max-width: 640px) {
    display:none;
  }
;
`
const SuccessImg = styled.img<any>`
  width: 26px;
  height: 26px;
  cursor:${props=>props?.$cursor}
`;

const Wrapper=styled.div`
  width:100%;
  margin-bottom: 32px;
  @media only screen and (max-width: 640px) {
    margin-bottom:74px;
    align-items: center;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */ 
      margin-bottom:0px;
      }
    }
`;
const OtpBoxWrapper=styled.div`
  display:flex;
  width:100%;
  flex-direction:column;
  @media only screen and (max-width: 640px) {
    flex-direction:column-reverse;
    row-gap:16px;
  }
`
const OtpValidText=styled.div`
  display: flex; 
  font-weight: 600;
  font-size: 12px;
  width: 50%;
  @media only screen and (max-width: 640px) {
  width: 100%;
  font-size:12px;
  justify-content: center;
  }
`
const WarningImg = styled(SuccessImg)`
  display:block;
  width:13px;
  height:13px;
  @media only screen and (max-width: 1023px) {
   display:none;
  }
`;
const CloseImg = styled.img<any>`
  display:none;
  cursor:pointer;
  @media only screen and (max-width: 1023px) {
   display:block;
  }
`;

function OtpBox({ text, reset, type, value, onComplete, ...props }: OtpProps) {
  const authState = useSelector((state: any) => state.auth);
  const {
    mobileOtpError,
    emailOtpError,
    verifyEmailOTPData,
    verifyMobileOTPData,
    verifyEmailOTPMessage,
    errorData
  } = authState;
  const otpError = type === "mobile" || "forgotPassword" ? mobileOtpError : emailOtpError;
  const otpData = type === "mobile" || "forgotPassword" ? verifyMobileOTPData : verifyEmailOTPData;
  const [otp, setOtp] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [inputDisable, setInputDisable] = useState(false);
  let initialMinute = +(reset ? 5: sessionStorage.getItem("min") || 5);
  let initialSecond = +(reset ? 0 : sessionStorage.getItem("sec") || 0);
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSecond);
  const [didTimerEnd,setDidTimerEnd]=useState(false);

  const setErrorStateToEmpty=()=>{
    if(props?.onHandleError){
      props?.onHandleError("");
    }
  }

  const handleChange = (e: any) => {
    setOtp(e);
    setErrorFlag(false);
    setErrorStateToEmpty();
    resetOtpState(dispatch);
  };

  sessionStorage.setItem("min", `${minutes}`);
  sessionStorage.setItem("sec", `${seconds}`);
  const dispatch = useDispatch();

  useEffect(() => {
    onComplete(otp);
    if (!otpError) {
      setErrorFlag(true);
    }
    
  }, [otp, otpError]);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        if(minutes===0 && seconds===1){
          setDidTimerEnd(true)
        }
      }
      if (seconds === 0 && !otpData) {
        if (minutes === 0) {
          setInputDisable(true);
          setOtp("");
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  let borderColor = undefined;
  (otpError && errorFlag) || didTimerEnd
    ? (borderColor = `1px solid ${colors.darkRed}`)
    : (borderColor = `1px solid ${colors.whiteGray}`);

  const { userData } = authState;

  const handleTimer = () => {
    resetOtpState(dispatch);
    if(type==="forgotPassword" && props?.onForgotPasswordResend){
      props?.onForgotPasswordResend();
    }
    if (type === "mobile") {
      dispatch(
        resendMobileOTP(
          JSON.stringify({
            mobileNo: `${value}`,
          })
        )
      );
    }
    if (type === "email") {
      dispatch(
        resendEmailOTP(
          JSON.stringify({
            email: `${value}`,
          })
        )
      );
    }
    if (type === "userInfoMobile") {
      dispatch(
        resendUserInfoOTP(
          JSON.stringify({
            mobileNo: `${value}`,
          })
        )
      );
    }

    setMinutes(5);
    setSeconds(0);
    setInputDisable(false);
    setErrorFlag(false);
    setOtp("");
    setDidTimerEnd(false);
    props?.onHandleError("");
  };

  useEffect(() => {
    if (!otpError && otpData) {
      setInputDisable(true);
    }
  }, [otpError, otpData]);

useEffect(() => {
    if(otpError && errorFlag && !otpData && errorData?.length && !(seconds == 0 && minutes == 0)  && props?.onHandleError){
      props?.onHandleError("OTP Incorrect");
    }
    if(errorData?.length && props?.onHandleError){
      props?.onHandleError(errorData);
    }
    if(verifyEmailOTPMessage?.length && props?.onHandleError){
      props?.onHandleError(verifyEmailOTPMessage);
    }
}, [otpData,otpError,errorFlag,seconds,minutes,verifyEmailOTPMessage,errorData,didTimerEnd])

useEffect(() => {
  if(didTimerEnd && props?.onHandleError){
    props?.onHandleError("OTP Expired");
    }else if(props?.onHandleError){
      props?.onHandleError("");
    }
}, [didTimerEnd])

const onOtpClear=()=>{
  setOtp("");
  setErrorStateToEmpty();
  setErrorFlag(false);
  resetOtpState(dispatch);
}

  return (
    <Wrapper>
      <Title>{text}</Title>
      <OtpBoxWrapper>
      <OtpContainer>
        <OtpInputWrap
          value={otp}
          onChange={handleChange}
          containerStyle={{width:"100%",justifyContent:"space-between"}}
          numInputs={6}
          isInputNum
          isDisabled={inputDisable}
          $borderColor={borderColor}
          inputStyle={styles.container}
        />
        {!!(otp?.length) && <CloseImg src="/images/close.svg" onClick={()=>onOtpClear()}/>}
        {!otpError && otpData && (
          <SuccessImg src="/images/Auth/success-green.svg" />
        )}
      </OtpContainer>
      {minutes === 0 &&
      seconds === 0 &&
      
      (otpData === undefined || !otpData)  ? (
        <OtpFailed>
          <div><WarningImg src="/images/Auth/warning.svg" /> OTP Expired </div>
          <OtpPadding onClick={handleTimer}>
           {seconds === 0 && minutes === 0 &&  <AuthenticationTags
              text={`Resend OTP`}
              underline="required"
            />}
          </OtpPadding>
        </OtpFailed>
      ) : !otpData ? (
        <OtpDiv>
          <OtpValidText >
            OTP is valid for
            <OtpPadding>
              {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            </OtpPadding>
          </OtpValidText>
          <HideOtpPadding 
            onClick={handleTimer}
          >
          {seconds <= 59 && minutes <= 2  &&  <AuthenticationTags
              text={`Resend OTP`}
              underline="required"
            />}
          </HideOtpPadding>
        </OtpDiv>
      ) : (
        <OtpDiv>OTP Verified</OtpDiv>
      )}
      {otpError && errorFlag && !otpData && !(seconds == 0 && minutes == 0) && (
        <div style={{display:"flex",alignItems: "center"}}><WarningImg src="/images/Auth/warning.svg" /> <HideOtpFailed>OTP Incorrect</HideOtpFailed></div>
      )}
      </OtpBoxWrapper>
        <ShowTimer onClick={handleTimer}>
              {seconds <= 59 && minutes <= 2  && <AuthenticationTags
                  text={`Resend OTP`}
                  underline="required"
                />}
      </ShowTimer>
    </Wrapper>
  );
}
export default OtpBox;
