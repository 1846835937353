import { HEAP_CUSTOM_USER_PROPERTIES, HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
import { VENWIZ_INTERNAL_USER_EMAILS } from "constants/venwizInternalUserEmails.constants";

/**
 * Always call this function first before calling another heap function
 */
export const initializeHeapAnalytics = (windowObj: typeof window) => {
  if ((windowObj as any)?.heap) {
    if (!(windowObj as any)?.isHeapLoadInitialized) {
      (windowObj as any).isHeapLoadInitialized = true;
      /** call the function(heap.load) ONLY ONCE when:
       * the global variable `isHeapLoadInitialized` is absent/undefined  */
      (window as any)?.heap?.load(process.env.REACT_APP_HEAP_APP_ID);
    }
  }
};

/**
 * Do not track heap analytics for internal venwiz users
 */
export const initializeHeapAnalyticsOnlyForNonInternalVenwizUser = (email: string, windowObj: typeof window) => {
  if (email) {
    let isInternalVenwizUser = false;
    const lowerCasedEmail = email?.toLowerCase();
    VENWIZ_INTERNAL_USER_EMAILS.forEach(websiteName => {
      if (lowerCasedEmail?.includes(websiteName)) {
        isInternalVenwizUser = true;
      }
    });

    if (!isInternalVenwizUser) {
      /** initialize heap script - for a user not part of venwiz-internal-team */
      initializeHeapAnalytics(windowObj);
    }

    /** initialize heap script - for testing on QA */
    if (lowerCasedEmail === "vendorvenwiztest@gmail.com") {
      initializeHeapAnalytics(windowObj);
    }
  }
};


export interface IHeapIdentifyTrigger {
  heapWindowObj: any;
  value: { uniqueEmail?: string; GSTN: string };
}

export const heapAddUserProperties = ({ heapWindowObj, value }: IHeapIdentifyTrigger) => {
  const { uniqueEmail, GSTN } = value;
  const PROPERTIES: { [key in HEAP_CUSTOM_USER_PROPERTIES]?: string } = {};

  if (uniqueEmail) {
    // heap custom-properties - https://developers.heap.io/reference/adduserproperties
    PROPERTIES.customEmailId = uniqueEmail;
    if (GSTN) PROPERTIES.userGST = GSTN;
    if (heapWindowObj?.identify) heapWindowObj?.identify(uniqueEmail);
    if (heapWindowObj?.addUserProperties) heapWindowObj?.addUserProperties(PROPERTIES);
  }
};

export const heapTrackForSuccessfulLogin = ({
  windowObj,
  email,
  mobileNumber,
  GSTN,
  customEventName,
}: {
  windowObj: typeof window;
  email?: string;
  mobileNumber?: string;
  GSTN?: string;
  customEventName?: string;
}) => {
  const PROPERTIES: { [key in HEAP_CUSTOM_USER_PROPERTIES]?: string } = {};
  if (GSTN) PROPERTIES.loggedInVGSTN = GSTN;
  if (email) PROPERTIES.loggedInVEmail = email;
  if (mobileNumber) PROPERTIES.loggedInVMobileNumber = mobileNumber;

  heapTrackTrigger({
    windowObj,
    eventName: customEventName || HEAP_DATA_ATTRIBUTES.HEAP_TRACK.SUCCESSFUL_LOGIN,
    value: PROPERTIES,
  });
};

export const heapTrackTrigger = ({
  windowObj,
  eventName,
  value,
}: {
  windowObj: typeof window;
  eventName: string;
  value: any;
}) => {
  if ((windowObj as any)?.heap?.track) {
    return (windowObj as any)?.heap.track(eventName, value);
  }
};
