import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import styled from "styled-components";
import * as Yup from "yup";
import { Modal } from "antd";
import { Button } from "components/Button";
import CollapseComponent from "components/Collapse";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import {
  experienceInfoCreate,
  experienceInfoGet,
  experienceInfoOnboardingCreate,
  experienceInfoUpdate,
} from "reducers/experienceInfo";
import { showEditReducer } from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import SessionStorageService from "services/SessionStorageService";
import openNotification from "constants/antdNotification";
import { experienceLocalizationData } from "ui-constants/localizationData";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";

export const FormikForm = styled(Form)`
  overflow: auto;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;
const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  margin-bottom: 30px;
`;

const ContentInputs = styled.div`
  height: calc(100vh - 400px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;

const Div = styled.div`
  position: relative;
`;

const FormWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;
`;

const BtnMargin = styled.div`
  margin-left: 30px;
`;

const Experience = () => {
  const history = useHistory();
  let submit = [];
  const userInformation = {
    experienceId: "",
    experience: [
      {
        projectName: "",
        clientCompanyName: "",
        clientIndustry: "",
        city: "",
        projStartDate: "DD-MM-YYYY",
        projEndDate: "DD-MM-YYYY",
        description: "",
        document: {},
      },
    ],
  };

  const initialValue = {
    experienceId: "",
    experience: [
      {
        projectName: "",
        clientCompanyName: "",
        companyProfile: "",
        clientIndustry: "",
        city: "",
        projStartDate: "",
        projEndDate: "",
        description: "",
        document: {},
      },
    ],
  };

  const experienceInfoSelector = useSelector(
    (state: any) => state.experienceInfo
  );
  const [experienceInfoState, setExperienceInfoState] = useState(
    userInformation
  );
  const { experienceInfoData } = experienceInfoSelector; //reduce data
  const [showValidations, setShowValidation] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState(1);
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData } = userInfoState;
  const gstn = userInfoData?.gstn;

  useEffect(() => {
    dispatch(experienceInfoGet({}));
  }, []);

  useEffect(() => {
    if (
      experienceInfoData !== undefined &&
      experienceInfoData?.experience?.length > 0
    ) {
      setExperienceInfoState(experienceInfoData);
      showEditReducer(dispatch, (buttonState.editButton = true));
    } else {
      showEditReducer(dispatch, (buttonState.editButton = false));
    }
  }, [experienceInfoData?.experience]);

  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton } = buttonState;
  const dispatch = useDispatch();

  const handleClick = type => {
    if (type === "save") {
      if (experienceInfoState?.experienceId !== "") {
        delete experienceInfoState.experience["flag"];
        dispatch(
          experienceInfoUpdate({
            experienceId: experienceInfoState.experienceId,
            experience: experienceInfoState.experience,
          })
        ).then(
          res => {
            dispatch(dashboardPercentageGet({}));
            dispatch(experienceInfoGet({}));
          },
          err => {
            openNotification("Please fill all the required data");
          }
        );
      } else {
        setUpdateStatus(false);
        dispatch(
          experienceInfoCreate({
            gstn,
            experience: experienceInfoState.experience,
          })
        ).then(
          res => {
            dispatch(experienceInfoGet({}));
            dispatch(dashboardPercentageGet({}));
          },
          err => {
            openNotification("Please fill all the required data");
          }
        );
      }

      setShowValidation(true);
    }
    showEditReducer(dispatch, !editButton);
  };

  const handleAddProject = () => {
    setExperienceInfoState({
      ...experienceInfoState,
      experience: [
        ...experienceInfoState.experience,
        ...userInformation.experience,
      ],
    });
    setDefaultActiveKey(experienceInfoState.experience.length + 1);
  };

  const handleDeleteProject = index => {
    const experiences = [...experienceInfoState.experience];
    experiences.splice(index, 1);
    setExperienceInfoState({
      ...experienceInfoState,
      experience: [...experiences],
    });
  };

  const setUserData=()=>{
    if(Object.keys(experienceInfoData)?.length){
      setExperienceInfoState(experienceInfoData)
    }
  }
  const warning = () => {
    Modal.warning({
      title: "Warning",
      content:
        "Please fill all the required field in each of the experience card in order to save your data ",
      centered: true,
    });
  };

  const validate = Yup.object({});

  const validationfunc = () => {
    submit = [];
    submit = experienceInfoState.experience.map(item => {
      if (
        item.city !== "" &&
        item.clientCompanyName !== "" &&
        item.clientIndustry !== "" &&
        item.projEndDate !== "" &&
        item.projStartDate !== "" &&
        item.projStartDate !== "DD-MM-YYYY" &&
        item.projEndDate !== "DD-MM-YYYY" &&
        item.description.length
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (submit.includes(false)) {
      warning();
    } else {
      handleClick("save");
    }
  };
  return (
    <>
      <UserInfo.Provider value={[experienceInfoState, setExperienceInfoState]}>
        <Formik
          initialValues={initialValue}
          validationSchema={validate}
          onSubmit={(values, actions) => {}}
        >
          {formik => (
            <FormikForm>
              <ContentHeader>Experience</ContentHeader>
              <ProfileName>{experienceLocalizationData.heading}</ProfileName>
              <ContentInputs>
                <FormWrapper>
                  {experienceInfoState?.experience.map((item, index) => {
                    return (
                      <Div>
                        <CollapseComponent
                          handleValidation={e => {}}
                          formik={formik}
                          headerName={item?.projectName || `Project`}
                          subHeader={undefined}
                          usedFor="experience"
                          defaultActiveKey={defaultActiveKey}
                          buttonType={!editButton}
                          data={{ item, index }}
                          validationState={showValidations}
                          showDeleteOption = {true}
                          handleAlertMessage={e => {}}
                          handleDeleteProject={handleDeleteProject}
                        />
                      </Div>
                    );
                  })}
                </FormWrapper>
                {!editButton && (
                  <Button
                    onClick={handleAddProject}
                    variant="primary"
                    size="middle"
                    style={{
                      width: "140px",
                      height: "60px",
                      padding: "20px 105px",
                      borderRadius: "8px",
                    }}
                  >
                    Add More
                  </Button>
                )}
              </ContentInputs>
              <ButtonWrapper>
                <ProfileCreationButtons
                  usedAt={"experience"}
                  state={editButton}
                  handleStateChange={value => {
                    if (value === "cancel") {
                      showEditReducer(dispatch, !buttonState.editButton);

                   setUserData();
                    } else {
                      if (value === "save") {
                        validationfunc();
                        setShowValidation(true);
                      } else {
                        handleClick(value);
                      }
                    }
                  }}
                ></ProfileCreationButtons>
                {/* {editButton ? (
                  <Button
                    onClick={() => {
                      setShowValidation(false);
                      handleClick("edit");
                    }}
                    variant="primary"
                    size="middle"
                    style={{ padding: "20px 105px" }}
                  >
                    Edit
                  </Button>
                ) : (
                  <ButtonDiv type="submit">
                    <Button
                      onClick={() => {
                        validationfunc();
                        setShowValidation(true);
                      }}
                      variant="secondary"
                      size="middle"
                      style={{ padding: "20px 105px" }}
                    >
                      Save
                    </Button>
                  </ButtonDiv>
                )}
                <BtnMargin>
                  <Button
                    disabled={!buttonState.editButton}
                    onClick={() => {
                      history.push("/certifications");
                    }}
                    variant="secondary"
                    size="middle"
                    style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                  >
                    Next Section
                  </Button>
                </BtnMargin>
               */}
              </ButtonWrapper>
            </FormikForm>
          )}
        </Formik>
      </UserInfo.Provider>
    </>
  );
};

export default Experience;
