import styled from "styled-components";

export const HideDiv=styled.div`
  @media only screen and (max-width: 640px) {
   display:none
  }
`
export const DisplayError=styled.div`
    display:block;
    min-height: 32px;
    width:100vh;
    text-align:center;
    background: rgba(190, 0, 11, 0.05);
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #BE000B;
    margin-bottom:12px;
    padding: 6px 215px;
`

export const TagDiv = styled.div`
  display: flex;
  justify-content: center;
`;