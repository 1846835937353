import { CompanyInfoTab, ScopeOfWorkContainer, UserInfoTab } from "ProfileCreation";
import Capabilities from "ProfileCreation/Capabilities/Capabilities";
import CapabilitiesNew from "Profile/Capabilities/CapabilitiesNew";
import Certification from "ProfileCreation/Certification/Cerification";
import DocumentInfoTab from "ProfileCreation/Documents/documents";
import Experience from "ProfileCreation/Experience/Experience";
import UserInformation from "Profile/UserInfo/UserInfo";
import ExperienceNew from "Profile/Experience/Experience";
import People from "Profile/People/People";
import PeopleInfoTab from "ProfileCreation/PeopleInfo/PeopleInfo";
import References from "Profile/References/References";
import React from "react";
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { AppConfig } from "ui-constants";
import CompanyInformation from "Profile/CompanyInformation/CompanyInformation";
import Logo from "../assets/images/jb_upsource.png";
import { number } from "yup";
import Certifications from "Profile/Certifications/Certifications";
import Compliance from "Profile/Compliance/Compliance";
import Finance from "Profile/Finance/Finance";
import { HEAP_DATA_ATTRIBUTES } from "./heapAnalytics";

let error = false;
export const mockData = {
  carosuel: [
    {
      id: 1,
      text: "Create a digital profile on Venwiz and become visible to clients across industries.",
    },
    {
      id: 2,
      text: "Increase business leads by getting connected to the right clients at the right time",
    },
    {
      id: 3,
      text: "Manage all your queries and collaborations with clients from a single platform.",
    },
  ],
  terms: [
    {
      id: 1,
      p:
        "We offer the below mentioned registration services which may be subject to change in the future. All changes will be appended in the terms and conditions page and communicated to existing users by email.",
    },
    {
      id: 2,
      p:
        " Registration services are offered for individual subscribers only. If multiple individuals propose to access the same account or for corporate accounts kindly contact or write in to us. Subscription rates will vary for multiple same time access.",
    },
    {
      id: 3,
      p:
        "The nature and volume of Business Standard content services you consume on the digital platforms will vary according to the type of registration you choose, on the geography you reside or the offer you subscribe to.",
    },
    {
      id: 4,
      p:
        "We welcome users to register on our digital platforms. We offer the below mentioned registration services which may be subject to change in the future. All changes will be appended in the terms and conditions page and communicated to existing users by email.",
    },
    {
      id: 5,
      p:
        "Registration services are offered for individual subscribers only. If multiple individuals propose to access the same account or for corporate accounts kindly contact or write in to us. Subscription rates will vary for multiple same",
    },
  ],
  image: {
    imageSrc: "../assets/images/jb_upsource.png",
    altMesg: "logo",
  },
};
//onbairding

export const COMPANY_INFO_OPTION_ONBOARDING = {
  id: 2,
  key: "companyInfo",
  title: "Company Information",
  step: "1",
  icon: "/images/ProfileCreation/companyInfo.svg",
  cardName: "Company Info",
  status: 50,
  linkTo: "/onboarding/companyInfo",
  isVisibleOnOnboarding: true,
  autoFilled: true,
  autoFilledText: "Some fields have been auto-filled based on the information shared by you during registration.",
  isFilled: "companyinfoCompleteness",
  hasRequiredFields: true,
  render: () => <CompanyInformation />,
};

export const CAPABILITIES_INFO_OPTION_ONBOARDING = {
  id: 4,
  key: "capabilities",
  title: "Capabilities",
  step: "2",
  icon: "/images/ProfileCreation/capabilities.svg",
  cardName: "Capabilities",
  status: 99,
  linkTo: "/onboarding/capabilities",
  isVisibleOnOnboarding: true,
  autoFilled: true,
  autoFilledText:
    "Additional information like certifications and OEM partnerships enhance your profile and increase your chances of receiving job requests.",
  autoFilledTextForMobileScreen:
    "Additional information like certifications and OEM partnerships enhance your profile.",
  isFilled: "capabilitiesCompleteness",
  hasRequiredFields: true,
  render: () => <CapabilitiesNew />,
};

export const EXPERIENCE_INFO_OPTION_ONBOARDING = {
  id: 6,
  key: "experience",
  title: "Experience",
  step: "3",
  icon: "/images/ProfileCreation/experience.svg",
  cardName: "Experience",
  status: 44,
  linkTo: "/onboarding/experience",
  autoFilled: true,
  autoFilledText: "Experiences highlight your proficiency in a particular field. Add details of projects that you have undertaken in the last 3-5 years.",
  autoFilledTextForMobileScreen:
    "Experiences highlight your proficiency in a particular field. Add details of projects that you have undertaken in the last 3-5 years.",
  isVisibleOnOnboarding: true,
  isError: error,
  isFilled: "experienceCompleteness",
  hasRequiredFields: true,
  customButtonText: "Add New Experience",
  customButtonHeapDataTrackingId: HEAP_DATA_ATTRIBUTES.EXPERIENCE.ADD_NEW_PROJECT_BUTTON,
  /** typeof numberOfTimesCustomButtonIsClicked is number */
  render: ({ numberOfTimesCustomButtonIsClicked }) => <ExperienceNew numberOfTimesCustomButtonIsClicked={numberOfTimesCustomButtonIsClicked} />,
};

export const CERTIFICATIONS_INFO_OPTION_ONBOARDING = {
  id: 7,
  key: "certifications",
  title: "Certifications",
  step: "4",
  icon: "/images/ProfileCreation/certifications.svg",
  cardName: "Certifications",
  status: 55,
  linkTo: "/onboarding/certifications",
  autoFilled: false,
  isVisibleOnOnboarding: true,
  hasRequiredFields: false,
  render: () => <Certifications />,
};

export const COMPLIANCE_INFO_OPTION_ONBOARDING = {
  id: 12,
  key: "compliance",
  title: "Statutory Compliance",
  step: "4",
  icon: "/images/ProfileCreation/certifications.svg",
  cardName: "Compliance",
  status: 55,
  linkTo: "/onboarding/compliance",
  autoFilled: false,
  isVisibleOnOnboarding: true,
  hasRequiredFields: true,
  isFilled: "complianceCompleteness",
  render: () => <Compliance />,
};

//already existing
export const USER_INFO_OPTION = {
  id: 1,
  key: "userinfo",
  icon: "/images/ProfileCreation/userInfo.svg",
  cardName: "User Information",
  title: "User Information",
  status: 100,
  linkTo: "/userinfo",
  isVisibleOnOnboarding: false,
  autoFilled: true,
  hasRequiredFields: true,
  isFilled: "userInfoCompleteness",
  step: "1",
  render: () => <UserInformation />,
};

export const COMPANY_INFO_OPTION = {
  id: 2,
  key: "companyinfo",
  title: "Company Information",
  icon: "/images/ProfileCreation/companyInfo.svg",
  cardName: "Company Information",
  status: 50,
  linkTo: "/companyinfo",
  isVisibleOnOnboarding: true,
  autoFilled: true,
  isFilled: "companyinfoCompleteness",
  hasRequiredFields: true,
  step: "2",
  render: () => <CompanyInfoTab />,
};
export const SOW_INFO_OPTION = {
  id: 3,
  key: "scopeOfWork",
  icon: "/images/ProfileCreation/scopeOfWork.svg",
  cardName: "Scope of Work",
  status: 80,
  linkTo: "/scopeofwork",
  autoFilled: false,
  render: () => <ScopeOfWorkContainer />,
};
export const CAPABILITIES_INFO_OPTION = {
  id: 4,
  key: "capabilities",
  title: "Capabilities",
  step: "3",
  icon: "/images/ProfileCreation/capabilities.svg",
  cardName: "Capabilities",
  status: 99,
  linkTo: "/capabilities",
  isVisibleOnOnboarding: true,
  isFilled: "capabilitiesCompleteness",
  autoFilled: false,
  hasRequiredFields: true,
  render: () => <CapabilitiesNew />,
};
export const PEOPLE_INFO_OPTION = {
  id: 5,
  key: "people",
  icon: "/images/ProfileCreation/people.svg",
  cardName: "People",
  title: "People",
  status: 60,
  linkTo: "/people",
  isVisibleOnOnboarding: false,
  autoFilled: false,
  hasRequiredFields: true,
  render: () => <People />,
  step: "7",
};
export const EXPERIENCE_INFO_OPTION = {
  id: 6,
  key: "experience",
  title: "Experience",
  step: "4",
  icon: "/images/ProfileCreation/experience.svg",
  cardName: "Experience",
  status: 44,
  linkTo: "/experience",
  autoFilled: false,
  isVisibleOnOnboarding: true,
  isFilled: "experienceCompleteness",
  isError: error,
  hasRequiredFields: true,
  render: () => <ExperienceNew />,
};
export const CERTIFICATIONS_INFO_OPTION = {
  id: 7,
  key: "certifications",
  title: "Certifications",
  step: "4",
  icon: "/images/ProfileCreation/certifications.svg",
  cardName: "Certifications",
  status: 55,
  linkTo: "/certifications",
  autoFilled: false,
  isVisibleOnOnboarding: true,
  isFilled: "certificationsCompleteness",
  hasRequiredFields: false,
  render: () => <Certifications />,
};
export const REFERENCES_INFO_OPTION = {
  id: 8,
  key: "references",
  icon: "/images/ProfileCreation/reference.svg",
  cardName: "References",
  status: 53,
  linkTo: "/references",
  isVisibleOnOnboarding: false,
  autoFilled: false,
  title: "References",
  hasRequiredFields: false,
  // step: "7",
  render: () => <References />,
};
export const DOCUMENTS_INFO_OPTION = {
  id: 9,
  key: "documents",
  icon: "/images/ProfileCreation/documents.svg",
  cardName: "Documents",
  status: 53,
  linkTo: "/documents",
  autoFilled: false,
  render: () => <DocumentInfoTab />,
};
export const COMPLIANCE_INFO_OPTION = {
  id: 11,
  key: "compliance",
  title: "Statutory Compliance",
  step: "6",
  icon: "/images/ProfileCreation/certifications.svg",
  cardName: "Compliance",
  linkTo: "/compliance",
  onboardLink: "/onboarding/compliance",
  complianceSubText: true,
  isVisibleOnOnboarding: true,
  autoFilledText:
    "Verified statutory compliance details help Client onboard Vendors easily. Venwiz collects most of the information, additionally applicable fields have to be filled by you.",
  autoFilledTextForMobileScreen: "Verified statutory compliance details help Client onboard Vendors easily.",
  isFilled: "complianceCompleteness",
  hasRequiredFields: true,
  render: () => <Compliance />,
};
export const FINANCE_INFO_OPTION = {
  id: 12,
  key: "finance",
  title: "Financial Information",
  step: "5",
  icon: "/images/ProfileCreation/finance-info-icon.svg",
  cardName: "Financial Info",
  linkTo: "/finance",
  complianceSubText: false,
  isVisibleOnOnboarding: false,
  isFilled: "financeCompleteness",
  hasRequiredFields: true,
  render: () => <Finance />,
};

export const ProfileData = [
  USER_INFO_OPTION,
  COMPANY_INFO_OPTION,
  CAPABILITIES_INFO_OPTION,
  EXPERIENCE_INFO_OPTION,
  FINANCE_INFO_OPTION,
  COMPLIANCE_INFO_OPTION,
  /** Removing the below references option from the update profile page */
  ///// REFERENCES_INFO_OPTION, 
  PEOPLE_INFO_OPTION,
];
export const ProfileOnboardingData = [
  COMPANY_INFO_OPTION_ONBOARDING,
  CAPABILITIES_INFO_OPTION_ONBOARDING,
  EXPERIENCE_INFO_OPTION_ONBOARDING,
  COMPLIANCE_INFO_OPTION_ONBOARDING,
];

export const AvatarOptions = [
  {
    id: 1,
    cardName: "Invite",
    status: 53,
    linkTo: "/invite",
    checkAuthAccess: true,
  },
  {
    id: 2,
    cardName: "User Info",
    status: 53,
    linkTo: "/userinfo",
    checkAuthAccess: true,
  },
  {
    id: 3,
    cardName: "Marketing Suite",
    status: 53,
    linkTo: "/marketingsuite",
    checkAuthAccess: true,
  },
  {
    id: 4,
    cardName: "Help & Support",
    status: 53,
    linkTo: "/support",
    checkAuthAccess: true,
  },
  {
    id: 5,
    cardName: "Logout",
    status: 53,
    linkTo: "/login",
  },
];

export const DashboardData = {
  companyname: "UP Hardwares",
  logo: Logo,
  gstn: "GST - 22AAAAA0000A1Z5",
  phone: "022 - 32423473",
  email: "businessinfo@uphardwarespvtltd.co.in",
  address: "",
  cards: [
    {
      cardHead: "Profile Views",
      cardType: "normal",
      propertyName: "profileViews",
      leftDataPropertyName: "inLast30Days",
      leftDataLabel: "Last 30 Days",
      rightDataPropertyName: "total",
      rightDataLabel: "Total views",
      toolTipHover: "Number of times your profile was viewed by client",
    },
    {
      cardHead: "Contact Views",
      cardType: "normal",
      propertyName: "contactViews",
      leftDataPropertyName: "phoneNumberViews",
      leftDataLabel: "Total phone number views",
      rightDataPropertyName: "emailViews",
      rightDataLabel: "Total Email views",
      toolTipHover: "Number of times your email and phone number was viewed",
    },
    {
      cardType: "normal",
      cardHead: "Search Views",
      propertyName: "searchViews",
      leftDataPropertyName: "inLast30Days",
      leftDataLabel: "Last 30 days",
      rightDataPropertyName: "total",
      rightDataLabel: "Total search appearances",
      toolTipHover: "Number of times your profile came up in search list",
    },
  ],
};

export const DropdownData = {
  states: [
    { id: 1, value: "Karnataka" },
    { id: 2, value: "Kerala" },
    { id: 3, value: "TamilNadu" },
    { id: 4, value: "Telangana" },
    { id: 5, value: "Maharashtra" },
    { id: 6, value: "Rajasthan" },
  ],
  cities: [
    { id: 1, value: "Mangalore" },
    { id: 2, value: "Bangalore" },
    { id: 3, value: "Chennai" },
    { id: 4, value: "Mumbai" },
    { id: 5, value: "Kolkata" },
    { id: 6, value: "Pune" },
  ],
  countries: [{ id: 1, value: "India" }],
  clientIndustry: [
    {
      id: 1,
      label: "Oil & Gas",
      value: "Oil & Gas",
    },
    { id: 2, label: "Power & Energy", value: "Power & Energy" },
    {
      id: 3,
      label: "Metals & Mining",
      value: "Metals & Mining",
    },
    {
      id: 4,
      label: "Chemicals",
      value: "Chemicals",
    },
    {
      id: 5,
      label: "Civil & Construction",
      value: "Civil & Construction",
    },
    {
      id: 6,
      label: "Industrial Machinery & Tools",
      value: "Industrial Machinery & Tools",
    },
    { id: 7, label: "Aerospace & Defence", value: "Aerospace & Defence" },
    {
      id: 8,
      label: "Automotive, Farm and Earth moving equipments",
      value: "Automotive, Farm and Earth moving equipments",
    },
    {
      id: 9,
      label: "Transporation",
      value: "Transporation",
    },
    {
      id: 10,
      label: "Electronics",
      value: "Electronics",
    },
    {
      id: 11,
      label: "Agriculture",
      value: "Agriculture",
    },
    {
      id: 12,
      label: "Pharma, Life Sciences & Medical Devices",
      value: "Pharma, Life Sciences & Medical Devices",
    },
    { id: 13, label: "Paper & Packaging materials", value: "Paper & Packaging materials" },
    { id: 14, label: "Consumer Goods", value: "Consumer Goods" },
    { id: 15, label: "Telecom & IT", value: "Telecom & IT" },
    { id: 16, label: "Textile, Apparel & Luggage", value: "Textile, Apparel & Luggage" },
    { id: 17, label: "Food Processing & Beverage", value: "Food Processing & Beverage" },
    { id: 18, label: "Others", value: "Others" },
  ],
  industries: [
    {
      label: "Oil & Gas",
      value: "Oil & Gas",
    },
    { label: "Power & Energy", value: "Power & Energy" },
    {
      label: "Metals & Mining",
      value: "Metals & Mining",
    },
    {
      label: "Chemicals",
      value: "Chemicals",
    },
    {
      label: "Civil & Construction",
      value: "Civil & Construction",
    },
    {
      label: "Industrial Machinery & Tools",
      value: "Industrial Machinery & Tools",
    },
    { label: "Aerospace & Defence", value: "Aerospace & Defence" },
    {
      label: "Automotive, Farm and Earth moving equipments",
      value: "Automotive, Farm and Earth moving equipments",
    },
    {
      label: "Transporation",
      value: "Transporation",
    },
    {
      label: "Electronics",
      value: "Electronics",
    },
    {
      label: "Agriculture",
      value: "Agriculture",
    },
    {
      label: "Pharma, Life Sciences & Medical Devices",
      value: "Pharma, Life Sciences & Medical Devices",
    },
    { label: "Paper & Packaging materials", value: "Paper & Packaging materials" },
    { label: "Consumer Goods", value: "Consumer Goods" },
    { label: "Telecom & IT", value: "Telecom & IT" },
    { label: "Textile, Apparel & Luggage", value: "Textile, Apparel & Luggage" },
    { label: "Food Processing & Beverage", value: "Food Processing & Beverage" },
    { label: "Others", value: "Others" },
  ],
  overRideString: {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectAll: "Select All",
    selectSomeItems: "Select Industries",
  },
  turnOverSlab: [
    { id: 1, value: "Rs.0 to 40 lakhs" },
    { id: 2, value: "Rs.40 lakhs to 1.5 Cr." },
    { id: 3, value: "Rs.1.5 Cr. to 5 Cr." },
    { id: 4, value: "Rs.5 Cr. to 25 Cr." },
    { id: 5, value: "Rs.25 Cr. to 100 Cr." },
    { id: 6, value: "Rs.100 Cr. to 500 Cr." },
    { id: 6, value: "Rs.500 Cr. and above" },
  ],
  ProjectCost: [
    { id: 1, value: "less than ₹ 1 lakh" },
    { id: 2, value: "₹ 1- 10 lakh" },
    { id: 3, value: "₹ 10- 25 lakh" },
    { id: 4, value: "₹ 25 lakh- 50 lakh" },
    { id: 5, value: "₹ 50 lakh- 1 Cr" },
    { id: 6, value: "₹ 1- 5 Cr" },
    { id: 7, value: "greater than ₹ 5 Cr" }
  ],
  /**
   * FYI: same values are present in admin-ui dropdown,
   * and category selection is mandatory,
   * if not provided, it will be set to "Other"
   */
  galleryData: [
    { id: 1, value: "Product" },
    { id: 2, value: "Factory" },
    { id: 3, value: "Other" }
  ]
};
export const Work = {
  states: [
    {
      label: "Design & Engineering",
      value: "Design & Engineering",
    },
    {
      label: "Product development & Prototyping",
      value: "Product development & Prototyping",
    },
    { label: "Supply", value: "Supply" },
    { label: "Custom Manufacturing", value: "Custom Manufacturing" },
    {
      label: "Machining & Fabrication",
      value: "Machining & Fabrication",
    },
    { label: "Rentals & Logistics", value: "Rentals & Logistics" },
    { label: "Civil & Construction", value: "Civil & Construction" },
    { label: "Installation & Commissioning", value: "Installation & Commissioning" },
    { label: "Turnkey Solution", value: "Turnkey Solution" },
    {
      label: "Repair & Overhauling",
      value: "Repair & Overhauling",
    },
    { label: "Inspection & Audit", value: "Inspection & Audit" },
    { label: "Maintenance & AMC", value: "Maintenance & AMC" },
    {
      label: "Testing & Calibration",
      value: "Testing & Calibration",
    },
    { label: "Software Services", value: "Software Services" },
    { label: "Consultancy services", value: "Consultancy services" },
    { label: "Automation & Industrial IoT", value: "Automation & Industrial IoT" },
    {
      label: "Mechanical, Electrical & Plumbing Works",
      value: "Mechanical, Electrical & Plumbing Works",
    },
    { label: "Others", value: "Others" },
  ],
  overRideString: {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    selectSomeItems: "Select Scope of Work",
  },
};

export const Discipline = {
  states: [
    { label: "Mechanical", value: "Mechanical" },
    { label: "Automation", value: "Automation" },
    { label: "Electrical", value: "Electrical" },
    { label: "Architecture", value: "Architecture" },
    { label: "Structural/Civil", value: "Structural/Civil" },
    { label: "Electronics", value: "Electronics" },
    { label: "Process/Chemical", value: "Process/Chemical" },
    { label: "Instrumentation", value: "Instrumentation" },
    { label: "others", value: "others" },
  ],
  overRideString: {
    allItemsAreSelected: "All items are selected.",
    clearSearch: "Clear Search",
    noOptions: "No options",
    search: "Search",
    // selectAll: "Select All",
    selectSomeItems: "Discipline",
  },
};

export const stateGeoDbData = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jammu and Kashmir",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttarakhand",
  "Uttar Pradesh",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli",
  "Daman and Diu",
  "Delhi",
  "Lakshadweep",
  "Puducherry",
];

export const peopleInfo = {
  keyManagement: [
    {
      id: 1,
      name: "Codecraft",
      experience: "10years",
      qualification: "BE",
      designation: "ceo",
    },
  ],
  manStrength: [
    {
      id: 0,
      manPowerFullTime: number,
      manPowerPartTime: number,
      totalManPower: number,
      totalManPowerManufacturing: number,
      totalManPowerService: number,
    },
  ],
};
export const LocationsServed = {
  locationsServed: [
    {
      _id: "",
      country: "india",
      city: ["Mumbai", "Mangl"],
    },
    {
      _id: "",
      country: "",
      city: [],
    },
  ],
};

export const SettingsSidebar = [
  {
    icon: "/images/ProfileCreation/userInfo.svg",
    cardName: "Change Password",
    status: 100,
    linkTo: "/changePassword",
  },
];

export const colorArray = [
  "#FF6633",
  "#FFB399",
  "#FF33FF",
  "#FFFF99",
  "#00B3E6",
  "#E6B333",
  "#3366E6",
  "#999966",
  "#99FF99",
  "#B34D4D",
  "#80B300",
  "#809900",
  "#E6B3B3",
  "#6680B3",
  "#66991A",
  "#FF99E6",
  "#CCFF1A",
  "#FF1A66",
  "#E6331A",
  "#33FFCC",
  "#66994D",
  "#B366CC",
  "#4D8000",
  "#B33300",
  "#CC80CC",
  "#66664D",
  "#991AFF",
  "#E666FF",
  "#4DB3FF",
  "#1AB399",
  "#E666B3",
  "#33991A",
  "#CC9999",
  "#B3B31A",
  "#00E680",
  "#4D8066",
  "#809980",
  "#E6FF80",
  "#1AFF33",
  "#999933",
  "#FF3380",
  "#CCCC00",
  "#66E64D",
  "#4D80CC",
  "#9900B3",
  "#E64D66",
  "#4DB380",
  "#FF4D4D",
  "#99E6E6",
  "#6666FF",
];

export const locationServed = [
  {
    country: "india",
    city: ["bang", "Mumbai"],
  },
  {
    country: "Australia",
    city: ["Melbourne", "sydney"],
  },
];

export const inviteData = [
  {
    inviteType: "send",
    invited: "vendor",
    inviteeFullName: "Ranjith",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: false,
  },
  {
    inviteType: "resend",
    invited: "client",
    inviteeFullName: "Rohith",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: true,
  },
  {
    inviteType: "resend",
    invited: "client",
    inviteeFullName: "Athish",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: true,
  },
  {
    inviteType: "done",
    invited: "Vendor",
    inviteeFullName: "Ashraf",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: true,
  },
  {
    inviteType: "resend",
    invited: "client",
    inviteeFullName: "Swati",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: true,
  },
  {
    inviteType: "done",
    invited: "client",
    inviteeFullName: "Hemant",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: true,
  },
  {
    inviteType: "pending",
    invited: "client",
    inviteeFullName: "Deepak",
    inviteePhoneNumber: "+911234567890",
    inviteeEmail: "test@test.com",
    inviteeCompanyName: "string",
    invitedAt: "Pending, 02 days",
    resendInvite: true,
  },
];

export const jobScopingForm = {
  bulletPoints: [
    "The job has been posted by a client of Venwiz and Venwiz has no role in deciding the job requirement, vendor preferences or final vendor selection criterias. By taking part in the RFQ process, you agree that you are doing so at your sole discretion and without any liability and prejudice towards Venwiz.",
    "You have been referred as a potential vendor for the job based on the information shared by you in your Venwiz profile. However, Venwiz does not guarantee that you will be contacted, considered or selected for the job by the client.",
    "All commercial / contractual terms of the PO/LOI/WO including but not limited to price, shipping costs, payment methods, payment terms, date, period and mode of delivery, warranties related to products and services shall be directly agreed between the Client and you. Venwiz is not responsible and/or liable for any non-performance or breach of any contract entered into between You and the Client.",
    "Venwiz shall not and is not required to mediate or resolve any dispute or disagreement between You and the Client.",
    "The above T&Cs are in addition the standard Terms and Conditions agreed by you during registration with Venwiz.",
    "The commercial terms and conditions for availing the lead from Venwiz are as follows :",
  ],
  numbericalPoints: [
    "All prices are exclusive of taxes and duties. GST @18% will be applicable. GST credit is available.",
    "Lead fee shall be applicable when the client contacts the vendor to participate in the bids/ requests quotation/ invites for plant visit/ shortlist the vendor as a potential bidder for the job. Lead fee shall be payable within 3 days from the date of client connect. A lead for every job with the same client/ plant shall be considered an unique lead for pricing/ billing purposes.",
    "Commission fee shall be applicable when the vendor receives the PO/ WO/ LOI from the client. For Option 1, the commission fee shall be payable within 7 days from the date of receipt of the PO/ WO/ LOI from the client. For Option 2, the commission fee shall be payable within 3 days from the date of receipt of payment of each invoice from the client.",
    "In case of payment failure, the refund shall be made within 5-7 working days.",
  ],
};
export const experienceProfileTexts = {
  desktopScreen: {
    projScopeDesc: `Upload purchase order/ work order/ other contract documents issued by the client.
 Project scope and client served will be verified against this set of documents.`,
    uploadBttnText: "Upload Documents",
    endDate: "Project Completion Date",
    startDate: "Project Start Date",
    projectCost: "Project Cost",
  },
  mobileScreen: {
    projScopeDesc: `Upload purchase order/ work order/ other contract documents issued by the client.`,
    uploadBttnText: "Upload",
    endDate: "Completion Date",
    startDate: "Start Date",
  },
};

export const profileStatusConstants = {
  tasksCompleted: 3,
  totalExpProjects: 1,
  isCompanyProfileTaskCompleted: false,
  isExpTaskCompleted: false,
  isDocsCertTaskCompleted: false,
  isProductTourTaskCompleted: false,
};

export const companyProfileStatus = {
  isCompanyInfoCompleted: true,
  isCapabilitiesCompleted: true,
  isKeyCustomerCompleted: false,
  isFinancialInfoCompleted: false,
};
export const docsData = {
  isStatutoryAvailable: false,
  isTechCertAvailable: true,
};
