import React from "react";
import { Empty, Button } from "antd";

interface Props {
  customMess: string;
}
const EmptyComp = (props: Props) => {
  return (
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{
        height: 60,
      }}
      description={<span style={{ color: "black" }}>{props.customMess}</span>}
    ></Empty>
  );
};

export default EmptyComp;
