import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { number } from "yup";
import { Spin } from "antd";
import { Modal, Popover } from "antd";
import { Button } from "components/Button";
import { Label } from "components/Label";
import CardWithoutEdit from "components/Card/CardWithoutEdit/card";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import InputWithHeaderForEdit from "components/Input/InputBoxWithEdit/inputHeader";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import {
  manpowerStrengthCreate,
  manpowerStrengthGet,
  manpowerStrengthPost,
  peopleDelete,
  peopleGet,
  peoplePost,
} from "reducers/peopleInfo";
import {
  profileLayoutError,
  showEditReducer,
  toggleIsPageEdited,
} from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import SessionStorageService from "services/SessionStorageService";
import { peopleInformationLocalizationData } from "ui-constants/localizationData";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import ProfileInputHeading from "Profile/shared/Heading/Heading";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import FormField from "Profile/shared/FormField/FormField";
import { handleScrollToView } from "shared/handlers/form.handler";
import {
  HEAP_DATA_ATTRIBUTES,
  HEAP_DATA_TRACKING_ID,
} from "constants/heapAnalytics";
import { STYLES } from "Profile/UserInfo/UserInfo.styles";
import { Box } from "venwiz-ui";

const ContentInputs = styled.div`
  height: calc(100vh - 190px);
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
  padding: 0px 16px;
`;
const CardContainer = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;
const CardHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const PeopleInfoContainer = styled.div`
  display: ${(props) =>
    `${props.theme.card.types["peopleInfoContainer"].display}`};
  flex-direction: ${(props) =>
    `${props.theme.card.types["peopleInfoContainer"].flexDirection}`};
  margin-top: ${(props) =>
    `${props.theme.card.types["peopleInfoContainer"].marginTop}`};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  color: ${(props) => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${(props) => `${props.theme.fontWeights.bold}`};
`;

const ContentHeading = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${(props) => `${props.theme.colors.blue}`};
`;

const ContentSubHeading = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #011759;
`;

const InputFlexWrapper = styled.div`
  padding: 15px 10px 0px 0;
`;

const TextboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ButtonWrapper = styled.div`
  display: flex;
  padding: 19px 0px 20px 0px;
  position: fixed;
  bottom: 0;
  @media screen and (max-width: 640px) {
    z-index: 10;
    border-top: none;
    padding: 0px;
  }
`;
const AddKeyButtonWrap = styled.div`
  /* border-top: 1px solid #E5E5E5;*/
  padding-top: 15px;
`;

const FormWrapper = styled.div``;
const WrapperDiv = styled.div`
  margin-bottom: 20px;
`;
const Div = styled.div`
  margin-bottom: 20px;
`;
const HeaderDiv = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
`;
const BasicInformationMeassage = styled.div`
  padding: 30px 46px 30px 30px;
  border-radius: 12px;
  height: 111px;
  width: 100%;
  background-color: ${(props) => `${props.theme.colors.lightGray}`};
`;
const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
`;

const BtnMargin = styled.div`
  margin-left: 30px;
`;

const ManpowerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ManpowerDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const RequiredAnnotationLabel = styled.span<any>`
  color: ${(props) => props.color};
  margin-left: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;
const RequiredWrapper = styled.div`
  margin-left: 15px;
`;
const People = () => {
  let isFirstError = false;
  const history = useHistory();
  const gstn = getGstn(); //rewrite
  let validateData = false;
  const dispatch = useDispatch();
  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton } = buttonState;
  const peopleInfoSelector = useSelector((state: any) => state.people);
  const [mockButtonState, setMockButtonState] = useState(true);
  const {
    peopleData,
    peopleGetData,
    loading,
    loaded,
    error,
    peopleDeletedData,
    manpowerGetData,
    manpowerUpdateData,
  } = peopleInfoSelector;
  const [saveState, setSaveState] = useState(false);
  const [userInfo, setUserInfo] = useState(undefined);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [isDataSaving, setIsDataSaving] = useState(false);

  const [keyManagementState, setKeyManagementState] = useState({
    gstn: gstn,
    id: 0,
    vendorId: "",
    firstName: "",
    lastName: "",
    designation: "",
    qualification: "",
    experience: "",
    email: "",
  });
  const [manStrengthState, setManStrengthState] = useState({
    id: 0,
    manPowerFullTime: number || "",
    manPowerPartTime: number || "",
    totalManPower: number || "",
    totalManPowerManufacturing: number || "",
    totalManPowerService: number || "",
  });
  const warning = () => {
    Modal.warning({
      title: "Warning",
      content: "Please fill all the required field in order to save your data ",
      centered: true,
    });
  };

  useEffect(() => {
    if (
      peopleData !== null &&
      loading == false &&
      loaded == true &&
      error == false
    ) {
      setUserInfo({
        keyManagement: [],
        manStrength: [],
      });
    }
  }, [loading, loaded, error]);

  useEffect(() => {
    if (peopleGetData !== undefined) {
      peopleData.push(peopleGetData);
    }
  }, [peopleGetData]);

  useEffect(() => {
    dispatch(peopleGet({}));
    dispatch(manpowerStrengthGet({}));
    toggleIsPageEdited(dispatch, false);
  }, []);

  useEffect(() => {
    if (manpowerGetData) {
      setManStrengthState({
        id: 0,
        manPowerFullTime: manpowerGetData.fullTime,
        manPowerPartTime: manpowerGetData.partTime,
        totalManPower: manpowerGetData.total,
        totalManPowerManufacturing: manpowerGetData.inManufacturing,
        totalManPowerService: manpowerGetData.inServicing,
      });
    }
  }, [manpowerGetData]);

  useEffect(() => {
    if (peopleDeletedData) {
      dispatch(peopleGet({}));
    }
  }, [peopleDeletedData]);

  useEffect(() => {
    if (manpowerUpdateData) {
      dispatch(peopleGet({}));
      dispatch(manpowerStrengthGet({}));
    }
  }, [manpowerUpdateData]);

  const handleAddKey = () => {
    dispatch(
      peoplePost({
        gstn,
        vendorId: keyManagementState.vendorId,
        firstName: keyManagementState.firstName,
        email: keyManagementState.email,
        designation: keyManagementState.designation,
        experience: keyManagementState.experience,
      })
    ).then(() => {
      notificationFunc(
        "success",
        "Key Management details has been successfully updated."
      );
      toggleIsPageEdited(dispatch, false);
    });
    setKeyManagementState({
      ...keyManagementState,
      firstName: "",
      lastName: "",
      designation: "",
      qualification: "",
      experience: "",
      email: "",
    });
  };

  const handleClick = (value) => {
    if (value === "save") {
      setIsDataSaving(true);

      setSaveState(true);
      if (validateData) {
        profileLayoutError(dispatch, false);
        if (manpowerGetData && manpowerGetData.peopleStrengthId) {
          dispatch(
            manpowerStrengthPost({
              gstn,
              vendorId: "",
              peopleStrengthId: manpowerGetData.peopleStrengthId,
              total: manStrengthState.totalManPower,
              fullTime: manStrengthState.manPowerFullTime,
              partTime: manStrengthState.manPowerPartTime,
            })
          )
            .then((res) => {
              setIsDataSaving(false);
              notificationFunc("success");
              dispatch(dashboardPercentageGet({}));
              toggleIsPageEdited(dispatch, false);
            })
            .catch(() => {
              setIsDataSaving(false);
            });
        } else {
          dispatch(
            manpowerStrengthCreate({
              gstn,
              vendorId: "",
              total: manStrengthState.totalManPower,
              fullTime: manStrengthState.manPowerFullTime,
              partTime: manStrengthState.manPowerPartTime,
            })
          ).then((res) => {
            notificationFunc("success");
            dispatch(dashboardPercentageGet({}));
            toggleIsPageEdited(dispatch, false);
          });
        }
      } else {
        profileLayoutError(dispatch, true);
        setIsDataSaving(false);
      }
    }
    if (value === "edit") {
    }
  };

  const handleKeyManagementCard = (value: any) => {
    dispatch(
      peopleDelete({
        gstn,
        peopleKeyManagementId: value,
      })
    ).then(() => {
      notificationFunc(
        "success",
        "Key Management details successfully deleted."
      );
      toggleIsPageEdited(dispatch, false);
    });
  };

  const handleAddKeyManagement = (e: any) => {
    setKeyManagementState({
      ...keyManagementState,
      [e.target.name]: e.target.value.trimStart(),
    });
  };
  const handleManStrength = (e) => {
    if (e.target.value.length <= 5) {
      setManStrengthState({
        ...manStrengthState,
        [e.target.name]: parseInt(e.target.value),
      });
      toggleIsPageEdited(dispatch, true);
    }
  };
  useEffect(() => {
    if (
      keyManagementState.firstName !== "" ||
      keyManagementState.lastName !== "" ||
      keyManagementState.designation !== "" ||
      keyManagementState.experience !== "" ||
      keyManagementState.qualification !== ""
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [keyManagementState]);

  if (
    manStrengthState?.totalManPower === null ||
    Number.isNaN(manStrengthState?.totalManPower)
  ) {
    validateData = false;
  } else {
    validateData = true;
  }

  const onHandleScroll = (id) => {
    if (!isFirstError) {
      handleScrollToView(id);
      isFirstError = true;
    }
    return true;
  };
  return (
    <Box width={{
      default: "100%",
      sm: "75vw",
    }}>
      <UserInfo.Provider value={[userInfo, setUserInfo]}>
        <ContentInputs>
          <FormWrapper>
            <PeopleInfoContainer>
              <Div>
                <ContentHeading>Manpower Strength</ContentHeading>
                <ContentSubHeading>
                  {peopleInformationLocalizationData.manpowerStrengthHeading}
                </ContentSubHeading>
              </Div>
              <ManpowerWrapper>
                <WrapperDiv id="manpower">
                  <FormField
                    isStar
                    label="Total Manpower (no.)"
                    showError={
                      (manStrengthState.totalManPower === null ||
                        Number.isNaN(manStrengthState.totalManPower)) &&
                      saveState
                    }
                    // customErrorMessage={"Please enter total manpower"}
                  >
                    <InputWithHeaderForEdit
                      maxChar={5}
                      withHeader={false}
                      usedFor="profileCreation"
                      inputBoxSize="semiMiddle"
                      inputType="number"
                      importance="important"
                      label="manpower"
                      name="totalManPower"
                      value={manStrengthState.totalManPower}
                      onHandleChange={(e: any) => {
                        handleManStrength(e);
                      }}
                      inputBoxStyle={STYLES.InputStyle}
                      inputFieldError={
                        (manStrengthState.totalManPower === null ||
                          Number.isNaN(manStrengthState.totalManPower)) &&
                        saveState &&
                        onHandleScroll("manpower")
                      }
                      inputDataAttribute={{
                        [HEAP_DATA_TRACKING_ID]:
                          HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.MANPOWER.TOTAL,
                      }}
                    ></InputWithHeaderForEdit>
                  </FormField>
                </WrapperDiv>
                <WrapperDiv>
                  <TextboxWrapper>
                    <ManpowerDiv>
                      <ProfileInputHeading
                        isStar={false}
                        name="Manpower full time"
                      />
                      <InputWithHeaderForEdit
                        maxChar={5}
                        withHeader={false}
                        usedFor="profileCreation"
                        inputBoxSize="semiMiddle"
                        inputType="number"
                        importance="important"
                        label="Fulltime"
                        name="manPowerFullTime"
                        placeholder="Enter full time manpower"
                        value={manStrengthState.manPowerFullTime}
                        onHandleChange={(e: any) => {
                          handleManStrength(e);
                        }}
                        inputBoxStyle={STYLES.InputStyle}
                        inputDataAttribute={{
                          [HEAP_DATA_TRACKING_ID]:
                            HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.MANPOWER
                              .FULL_TIME,
                        }}
                      ></InputWithHeaderForEdit>
                    </ManpowerDiv>
                    <ManpowerDiv>
                      <ProfileInputHeading
                        isStar={false}
                        name="Manpower part time"
                      />

                      <InputWithHeaderForEdit
                        withHeader={false}
                        usedFor="profileCreation"
                        inputBoxSize="semiMiddle"
                        inputType="number"
                        importance="important"
                        label="PartTime"
                        name="manPowerPartTime"
                        inputBoxStyle={STYLES.InputStyle}
                        placeholder="Enter part time manpower"
                        value={manStrengthState.manPowerPartTime}
                        onHandleChange={(e: any) => {
                          handleManStrength(e);
                        }}
                        inputDataAttribute={{
                          [HEAP_DATA_TRACKING_ID]:
                            HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.MANPOWER
                              .PART_TIME,
                        }}
                      ></InputWithHeaderForEdit>
                    </ManpowerDiv>
                  </TextboxWrapper>
                </WrapperDiv>
              </ManpowerWrapper>

              <HeaderDiv>
                <ContentHeading>Key Management</ContentHeading>
                <ProfileName>
                  {peopleInformationLocalizationData.keyManagementHeading}
                </ProfileName>
              </HeaderDiv>
              <Wrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    style={STYLES.InputStyle}
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    name="firstName"
                    child="First Name"
                    fields="input"
                    placeholder="Full Name"
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.firstName}
                    {...{
                      [HEAP_DATA_TRACKING_ID]:
                        HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.FULL_NAME,
                    }}
                  />
                </InputFlexWrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    style={STYLES.InputStyle}
                    sizes="semiMiddle"
                    inputTextType="text"
                    name="designation"
                    importanceType="important"
                    child="Designation"
                    fields="input"
                    placeholder="Designation"
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.designation}
                    {...{
                      [HEAP_DATA_TRACKING_ID]:
                        HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.DESIGNATION,
                    }}
                  />
                </InputFlexWrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    style={STYLES.InputStyle}
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    child="email"
                    fields="input"
                    name="email"
                    placeholder="Email"
                    maxChar={50}
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.email}
                    {...{
                      [HEAP_DATA_TRACKING_ID]:
                        HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.E_MAIL,
                    }}
                  />
                </InputFlexWrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    style={STYLES.InputStyle}
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    child="experience"
                    fields="input"
                    name="experience"
                    placeholder="Industry Experience"
                    maxChar={200}
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.experience}
                    {...{
                      [HEAP_DATA_TRACKING_ID]:
                        HEAP_DATA_ATTRIBUTES.PEOPLE.TEXT_FIELD.INDUSTRY,
                    }}
                  />
                </InputFlexWrapper>
                <AddKeyButtonWrap onClick={() => {}}>
                  <Button
                    disabled={buttonDisable}
                    onClick={handleAddKey}
                    variant="blue"
                    size="small"
                    style={{
                      borderRadius: "8px",
                      width: "60px",
                      height: "40px",
                    }}
                  >
                    <img src="/images/plusBttn.svg" alt="plus btn" />
                  </Button>
                </AddKeyButtonWrap>
              </Wrapper>

              <CardHolder>
                {peopleData !== undefined &&
                  peopleData?.map((item, index) => {
                    return (
                      <CardWithoutEdit
                        usedFor="keyManagement"
                        onHandleDelete={(index) => {
                          handleKeyManagementCard(index);
                        }}
                        data={{ item, index }}
                        header={false}
                        cardType={"cardWithoutEdit"}
                        editable={false}
                      />
                    );
                  })}
              </CardHolder>
            </PeopleInfoContainer>
          </FormWrapper>
        </ContentInputs>
        <ButtonWrapper>
          <ProfileCreationButtons
            dataAttribute={{
              [HEAP_DATA_TRACKING_ID]:
                HEAP_DATA_ATTRIBUTES.PEOPLE.SAVE_AND_CONTINUE,
            }}
            isloading={isDataSaving}
            buttonText={"SAVE CHANGES"}
            pagination={true}
            pageNumber={7}
            usedAt={"people"}
            state={!buttonState.isPageEdited}
            handleStateChange={(value) => {
              if (value === "cancel") {
                setManStrengthState({
                  id: 0,
                  manPowerFullTime: manpowerGetData.fullTime,
                  manPowerPartTime: manpowerGetData.partTime,
                  totalManPower: manpowerGetData.total,
                  totalManPowerManufacturing: manpowerGetData.inManufacturing,
                  totalManPowerService: manpowerGetData.inServicing,
                });
              } else {
                isFirstError = false;

                handleClick(value);
              }
            }}
            style={{
              minWidth: "210px",
              height: "40px",
              borderRadius: "4px",
            }}
          ></ProfileCreationButtons>
        </ButtonWrapper>
      </UserInfo.Provider>
    </Box>
  );
};

export default People;
