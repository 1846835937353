import { InputWithEdit } from 'components/Input';
import { BoxContainer } from 'components/Input/InputBoxWithEdit/inputHeader';
import React from 'react';
import styled from 'styled-components';
import ProfileInputHeading from '../Heading/Heading';

interface FormFieldProps {
  children: React.ReactNode,
  showError: boolean;
  label: string;
  isStar: boolean;
  info?: string;
  customErrorMessage?: string;
  isPaddingNotRequired?: boolean;
  responsiveScreenLabel?:string;          
  dataTrackingId?: string;
}

interface ImageProp {
  backgroundImage: string;
}

const FormFieldWrapper = styled.div`
display: flex;
`;

export const ErrorMessageDiv = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #be000b;
  margin-top: 4px;
  column-gap: 11.5px;
  @media only screen and (max-width: 640px) {
  max-width:338px; 
  }
`;

export const ImageHolder = styled.img`
  margin-left: 6px;
  height: auto;
  width: 16px;
  height: 16px;
  align-self: center;
`;

export const InputFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  position: relative;
  @media only screen and (max-width: 640px) {
  ${BoxContainer}{
    width:92.05vw;
  }
  }
`;

const FormField = (props: FormFieldProps) => {

  return (
      <InputFlexWrapper>
        <ProfileInputHeading isStar={props.isStar} name={props.label} responsiveScreenName={props.responsiveScreenLabel} info={props.info} isPaddingNotRequired={props.isPaddingNotRequired}/>
        <FormFieldWrapper data-tracking-id={props?.dataTrackingId}>
          {props.children}
        </FormFieldWrapper>
        {
         !!props.showError &&
          <ErrorMessageDiv>
            <ImageHolder src="/images/warning.svg" />
            {props.customErrorMessage || "Required Field"}
          </ErrorMessageDiv>
        }
      </InputFlexWrapper>
  );
};

export default FormField;
