import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from 'services/LocalStorageService'
import { GET_CHAT_SHARED_FILES_FAILURE } from "./chat";
export const COMPANYINFO_REQUESTED = "userInfoApi/COMPANYINFO_REQUESTED";
export const COMPANYINFO_SUCCESS = "userInfoApi/COMPANYINFO_SUCCESS";
export const COMPANYINFO_FAILURE = "userInfoApi/COMPANYINFO_FAILURE";
export const KEYCONTACTS_BACKFILL_REQUESTED = "vendorProfieApi/KEYCONTACTS_BACKFILL_REQUESTED"
export const KEYCONTACTS_BACKFILL_SUCCESS = "vendorProfieApi/KEYCONTACTS_BACKFILL_SUCCESS"
export const KEYCONTACTS_BACKFILL_FAILURE = "vendorProfieApi/KEYCONTACTS_BACKFILL_FAILURE"

export const COMPANYINFO_DELETE_REQUESTED =
  "userInfoApi/COMPANYINFO_DELETE_REQUESTED";
export const COMPANYINFO_DELETE_SUCCESS =
  "userInfoApi/COMPANYINFO_DELETE_SUCCESS";
export const COMPANYINFO_DELETE_FAILURE =
  "userInfoApi/COMPANYINFO_DELETE_FAILURE";

export const COMPANYINFO_UPDATE_REQUESTED =
  "userInfoApi/COMPANYINFO_UPDATE_REQUESTED";
export const COMPANYINFO_UPDATE_SUCCESS =
  "userInfoApi/COMPANYINFO_UPDATE_SUCCESS";
export const COMPANYINFO_UPDATE_FAILURE =
  "userInfoApi/COMPANYINFO_UPDATE_FAILURE";

export const GET_COMPANYINFO_REQUESTED = "userInfoApi/GET_COMPANYINFO_REQUESTED";
export const GET_COMPANYINFO_SUCCESS = "userInfoApi/GET_COMPANYINFO_SUCCESS";
export const GET_COMPANYINFO_FAILURE = "userInfoApi/GET_COMPANYINFO_FAILURE";

export const COMPANYINFO_UPDATE_ONBOARDING_REQUESTED =
  "userInfoApi/COMPANYINFO_UPDATE_ONBOARDING_REQUESTED";
export const COMPANYINFO_UPDATE_ONBOARDING_SUCCESS =
  "userInfoApi/COMPANYINFO_UPDATE_ONBOARDING_SUCCESS";
export const COMPANYINFO_UPDATE_ONBOARDING_FAILURE =
  "userInfoApi/COMPANYINFO_UPDATE_ONBOARDING_FAILURE";

const getGstn = SessionStorageService.get("gstNum")||LocalStorageService.get("gstNum");

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  companyInfoData: null,

  updating: false,
  updated: false,
  updateError: false,
  updateErrorData: false,
  updateUserData: null,
  updateCompanyInfoData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANYINFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case COMPANYINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyInfoData: action.result,
      };

    case COMPANYINFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case COMPANYINFO_UPDATE_REQUESTED:
      return {
        ...state,
        updated: true,
      };
    case COMPANYINFO_UPDATE_SUCCESS:
      return {
        ...state,
        updating: false,
        updated: true,
        updateError: false,
        updateCompanyInfoData: action.result,
      };
    case COMPANYINFO_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case GET_COMPANYINFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case GET_COMPANYINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        companyInfoData: action.result,
      };

    case GET_COMPANYINFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };    

    default:
      return state;
  }
};
export const companyInfoGet = (data) => ({
  types: [COMPANYINFO_REQUESTED, COMPANYINFO_SUCCESS, COMPANYINFO_FAILURE],
  promise: (client) => client.get(`companyinfo_get_data`, {}),
});

export const getCompanyInfo = (data) => ({
  types: [GET_COMPANYINFO_REQUESTED, GET_COMPANYINFO_SUCCESS, GET_COMPANYINFO_FAILURE],
  promise: (client) => client.get(`companyinfo_get_onboarding_data`, {}),
});

export const companyInfoPost = (data) => ({
  types: [COMPANYINFO_REQUESTED, COMPANYINFO_SUCCESS, COMPANYINFO_FAILURE],
  promise: (client) =>
    client.post("companyinfo_post_data", {
      data,
    }),
});

export const companyInfoOnboardingPost = (data) => ({
  types: [COMPANYINFO_UPDATE_ONBOARDING_REQUESTED, COMPANYINFO_UPDATE_ONBOARDING_SUCCESS, COMPANYINFO_UPDATE_ONBOARDING_FAILURE],
  promise: (client) =>
    client.post("companyinfo_post_onboarding_data", {
      data,
    }),
});

export const companyInfoUpdate = (data) => {
  return {
    types: [
      COMPANYINFO_UPDATE_REQUESTED,
      COMPANYINFO_UPDATE_SUCCESS,
      COMPANYINFO_UPDATE_FAILURE,
    ],
    promise: (client) =>
      client.post("companyinfo_update_data", {
        data,
      }),
  };
};

export const backfillKeyContacts = (data) => {
  return {
    types: [
      KEYCONTACTS_BACKFILL_REQUESTED,
      KEYCONTACTS_BACKFILL_SUCCESS,
      KEYCONTACTS_BACKFILL_FAILURE,
    ],
    promise: (client) =>
      client.post("backfill_key_contacts", {
        data,
      }),
  };
};
