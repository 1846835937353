import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "components/Button/Button";
import CollapseComponent from "components/Collapse";
import {
  certificationInfoCreate,
  certificationInfoGet,
  certificationInfoPost,
} from "reducers/certificationInfo";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import { showEditReducer } from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import { certificationLocalizationData } from "ui-constants/localizationData";
import ProfileCreationButtons from "components/ProfileCreationButtons";

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentInputs = styled.div`
  height: calc(100vh - 400px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;

const FormWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
  margin-bottom: 30px;
`;
const BtnMargin = styled.div`
  margin-left: 30px;
`;
const Certification = () => {
  const userInformation = {
    basic: [],
    compliance: [],
    technical: [],
  };
  const certificationState = useSelector(
    (state: any) => state.certificationInfo
  );
  const {
    certificationInfoGetdata,
    certificationInfoPostData,
    certificationInfoCreateData,
  } = certificationState;



  const [dropdownSelected, setDropdownSelected] = useState([]);
  const [certificationInfoState, setCertificationInfoState] = useState(
    userInformation
  );
  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton } = buttonState;
  const dispatch = useDispatch();
  const history = useHistory();
  let certificateId = null;
  const handleClick = type => {
    if (type === "save") {
      certificateId = certificationInfoGetdata?.certificateId;
      if (certificationInfoGetdata.certificateId) {
        dispatch(
          certificationInfoPost({
            certificateId: certificateId,
            ...certificationInfoState,
          })
        ).then(res => dispatch(dashboardPercentageGet({})));
      } else {
        dispatch(
          certificationInfoCreate({
            ...certificationInfoState,
          })
        ).then(res => dispatch(dashboardPercentageGet({})));
      }
    }
    showEditReducer(dispatch, !editButton);
  };

  const renderCard = (element: string) => {
    setDropdownSelected([...dropdownSelected, element]);
  };

  useEffect(() => {
    dispatch(certificationInfoGet({}));
  }, []);

  useEffect(() => {
    if (certificationInfoGetdata?.basic?.length > 0) {
      showEditReducer(dispatch, (buttonState.editButton = true));
    } else {
      showEditReducer(dispatch, (buttonState.editButton = false));
    }
  }, [certificationInfoGetdata]);

  useEffect(() => {
    if (certificationInfoPostData || certificationInfoCreateData) {
      dispatch(certificationInfoGet({}));
    }
  }, [certificationInfoPostData, certificationInfoCreateData]);

  const addBasicCard = item => {
    setCertificationInfoState({
      ...certificationInfoState,
      basic: [
        ...certificationInfoState.basic,
        {
          name: item.documentName,
          certificationFile: {
            fileName: item?.fileName,
            fileUrl: item?.fileUrl,
            fileSize: item?.fileSize,
          },
        },
      ],
    });
  };

  const addComplianceCard = item => {
    setCertificationInfoState({
      ...certificationInfoState,
      compliance: [
        ...certificationInfoState.compliance,
        {
          name: item?.documentName,
          certificationFile: {
            fileName: item?.fileName,
            fileUrl: item?.fileUrl,
            fileSize: item?.fileSize,
          },
        },
      ],
    });
    let editCurrentData = certificationInfoState;
  };
  const addTechnicalCard = item => {
    setCertificationInfoState({
      ...certificationInfoState,
      technical: [
        ...certificationInfoState.technical,
        {
          name: item?.documentName,
          certificationFile: {
            fileName: item?.fileName,
            fileUrl: item?.fileUrl,
            fileSize: item?.fileSize,
          },
        },
      ],
    });
  };
  return (
    <>
      <UserInfo.Provider
        value={[certificationInfoState, setCertificationInfoState]}
      >
        <ContentHeader>Certifications</ContentHeader>
        <ProfileName>{certificationLocalizationData.heading}</ProfileName>
        <ContentInputs>
          <FormWrapper>
            <CollapseComponent
              buttonType={editButton}
              headerName="Basic"
              subHeader={certificationLocalizationData.basicInfo}
              usedFor="certification"
              showDeleteOption = {true}
              onHandleAddCard={e => {
                addBasicCard(e);
              }}
            />
            <CollapseComponent
              buttonType={editButton}
              headerName="Compliance"
              subHeader={certificationLocalizationData.complianceInfo}
              usedFor="certification"
              showDeleteOption = {true}
              onHandleAddCard={e => {
                addComplianceCard(e);
              }}
            />
            <CollapseComponent
              buttonType={editButton}
              headerName="Technical"
              subHeader={certificationLocalizationData.technicalInfo}
              usedFor="certification"
              showDeleteOption = {true}
              onHandleAddCard={e => {
                addTechnicalCard(e);
              }}
            />
          </FormWrapper>
        </ContentInputs>
        <ButtonWrapper>
          <ProfileCreationButtons
          usedAt={"certification"}
            state={editButton}
            handleStateChange={value => {
              if (value === "cancel") {
                showEditReducer(dispatch, !buttonState.editButton);
                dispatch(certificationInfoGet({}));
              } else {
                handleClick(value);
              }
            }}
          ></ProfileCreationButtons>
          {/* {editButton ? (
            <Button
              onClick={() => handleClick("edit")}
              variant="primary"
              size="middle"
              style={{ padding: "20px 105px" }}
            >
              Edit
            </Button>
          ) : (
              <Button
                onClick={() => handleClick("save")}
                variant="secondary"
                size="middle"
                style={{ padding: "20px 105px" }}
              >
                Save
              </Button>
            )}
              <BtnMargin>
          <Button
            disabled={!buttonState.editButton}
            onClick={() => {
              history.push("/references");
            }}
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Next Section
          </Button>
        </BtnMargin> */}
        </ButtonWrapper>
      </UserInfo.Provider>
    </>
  );
};

export default Certification;
