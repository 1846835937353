
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useField } from "formik";
import Input from "../InputBox/input";
import InputWithHeader from "../InputWithHeader/input";
import AntButton, { ButtonProps as AntButtonProps } from 'antd/es/button';
import { Button } from "antd";
import InputBoxForEdit from "../InputBoxWithEdit/inputBox";
import InputWithHeaderForEdit from "../InputBoxWithEdit/inputHeader";

type Sizes =
  | "small"
  | "semiMiddle"
  | "medium"
  | "middle"
  | "large"
  | "extraLarge"
  | "profileCreationmedium"
  | "profileCreationSmall"
  | undefined;


export interface InputBoxProps {
  hide?: boolean,
  buttonOnclick?: () => void,
  onChangeFirstField?: (e) => void,
  onChangeSecondField?: (e) => void,
  onChangeThirdField?: (e) => void,
  firstFieldValue?: string
  secondFieldValue?: string
  thirdFieldValue?: string
}

const ButtonAntD = styled(AntButton)`
 width: 100px;
 height: 60px;
 border-radius:8px;
`
const Wrapper = styled.div`
display:flex;
width:100%;
align-items: flex-end;
margin-bottom:12px;
`
const Div = styled.div`
margin-left:10px;
`
const ThreeInputField = ({ hide = true, ...props }: InputBoxProps) => {
  const [disable, setDisable] = useState(true);
  const inputRef = useRef(null);

  return (
    <>
      <Wrapper>
        <InputWithHeaderForEdit
          placeholder={"Enter Equipment Name"}
          usedFor={"profileCreation"}
          inputBoxSize={"profileCreationLarge"}
          inputType={"text"}
          importance="important"
          name={"city"}
          hidden={hide}
          onHandleChange={props.onChangeFirstField}
          value={props.firstFieldValue}
        // error={props.formikError}
        // touch={props.touchedResponse}
        >
          Enter Equipment Name
      </InputWithHeaderForEdit>
        <div>
          {hide && <InputBoxForEdit
            sizes={"profileCreationMedium"}
            inputTextType={"text"}
            importanceType={"important"}
            onHandleChange={props.onChangeSecondField}
            value={props.secondFieldValue}
            // fields={field}
            // otherProps={props}
            // setRef={refValue}
            // disabled={disable}
            placeholder={"Enter Make"}
          // buttonType={buttonType}
          />}
        </div>
        <Div>
          {hide && <InputBoxForEdit
            sizes={"profileCreationSmall"}
            inputTextType={"text"}
            importanceType={"important"}
            onHandleChange={props.onChangeThirdField}
            value={props.thirdFieldValue}
            // fields={field}
            // otherProps={props}
            // setRef={refValue}
            // disabled={disable}
            placeholder={"Enter Capacity"}
          // buttonType={buttonType}
          />}
        </Div>
        {hide && <Div><ButtonAntD type="primary" onClick={props.buttonOnclick}>
          Add
      </ButtonAntD >
        </Div>}
      </Wrapper>
    </>
  );
};

export default ThreeInputField;
