import { Button, Popover } from "antd";
import { ImageBase } from "components/Image/LogoImage/_LogoImage";
import styled from "styled-components";
import { Box } from "venwiz-ui";

export const  OuterWrapper = styled(Box)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow-y: hidden;
  background: #f4f6ff;
  @media only screen and (max-width: 640px) {
  overflow: hidden;
  }

`;

export const  Line = styled.div`
  height: 20px;
  border-left: 3px solid #215ec9;
  margin-right: 15px;
  margin-left: 15px;
`;

export const LogoWrapper = styled.div`
  width: auto;
  height: 100%;
  padding-top: 14px;
  cursor:pointer;
  ${ImageBase}{
    height:32px;
  }
  #desktopImg{
    display:block;
  }
  @media only screen and (max-width: 640px) {
  padding-top: 0px;
  }
`;

export const HeaderWrapper = styled.div`
  width: 100vw;
  padding: 22px 0px 27px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 0px;
  top: 0;
  background: #011759;
  z-index: 100;
  height: 60px;
  #mobileImg{
    display:none;
  }
  @media only screen and (max-width: 640px) {
  padding: 0px; 
  #mobileImg{
      display:block;
  }
  
  #line,#title,label,#desktopImg{
  display:none;
  }
  height:56px;
  position:fixed;
  ${LogoWrapper}{
  width:144px;
  height:36px;
  }
  }
`;

export const  LogoDiv = styled.div`
  width: 178px;
  cursor: pointer;
  @media only screen and (max-width: 640px) {
  width: auto;
  padding-left:15px;
  }
`;

export const  Div = styled.div`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const  AvatarArrow = styled.img`
  padding-left: 8px;
`;

export const  PopOverDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 50px;
`;

export const  PopOverContent = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #011759;
  padding: 10px 16px 10px 23px;
  cursor: pointer;
  &:hover {
    background: #F4F6FF;
    color: #215EC9;
  }
`;

export const  LoginButton = styled(Button)`
  background: #215ec9;
  border-radius: 32px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  img {
    width: 15px;
    height: 15px;
  }
`;
export const NavAvatarWrapper=styled.div`
  display:flex;
  & .ant-popover-inner-content{
    padding:10px 0px;
    min-width:150px;
  }
  & .ant-popover-arrow{
    display:none;
  }
  & .ant-popover{
  top: 50px !important;
  }
  @media only screen and (max-width: 640px) {
    align-items:center;
    & .ant-popover{
      display:none;
    }
  }
`
export const PopoverDiv=styled(Popover)`
  @media only screen and (max-width: 640px) {
    padding-right:15px;
  }
`

export const HidePopover=styled.div`
display:block;
@media only screen and (max-width: 640px) {
display:none;
visibility:hidden;
}
`
export const MessageWrapper=styled.div<any>`
display:none;
@media only screen and (max-width: 640px) {
display:${props=>props?.$display};
width:auto;
height:auto;
position:relative;
}

`
export const MessageDiv=styled.img`
@media only screen and (max-width: 640px) {
margin-right:20px;
width:20px;
height:20px;
}
`

export const Indicator = styled.div`
  font-weight: 600;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #47c882;
  position:absolute;
  top: -3px;
  right: 16px;
`;