export const DASHBOARD_PERCENTAGE_REQUESTED =
  "userInfoApi/DASHBOARD_PERCENTAGE_REQUESTED";
export const DASHBOARD_PERCENTAGE_SUCCESS =
  "userInfoApi/DASHBOARD_PERCENTAGE_SUCCESS";
export const DASHBOARD_PERCENTAGE_FAILURE =
  "userInfoApi/DASHBOARD_PERCENTAGE_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,

  percentageData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_PERCENTAGE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DASHBOARD_PERCENTAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        percentageData: action.result,
      };

    case DASHBOARD_PERCENTAGE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const dashboardPercentageGet = (data) => ({
    types: [DASHBOARD_PERCENTAGE_REQUESTED, DASHBOARD_PERCENTAGE_SUCCESS, DASHBOARD_PERCENTAGE_FAILURE],
    promise: (client) => client.get(`dashboard_percentage_get_data`, {}),
  });