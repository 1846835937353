import AntdToast from "components/Toast/AntdToast";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { IReceivedRFQFiles } from "Request/Interfaces/RequestInterfaces";

export const bytesToMB = (sizeInBytes: number) => {
  const sizeInMBSring = (sizeInBytes / (1024 * 1024)).toFixed(2);
  return sizeInMBSring === "0.00" ? 0.01 : sizeInMBSring;
};

const downloadFileFromUrl = (url: string) => {
  return fetch(url).then((res) => {
    return res.blob();
  });
};

const generateZipFile = (
  zipFile: typeof JSZip,
  setIsZipping: React.Dispatch<React.SetStateAction<boolean>>,
  zipFilename: string,
) => {
  zipFile
    .generateAsync({ type: "blob" }) // Blob has limit of 345 MB
    .then(function(content) {
      saveAs(content, `${zipFilename}.zip`);
      setIsZipping(false);
    })
    .catch((error) => {
      console.error("Unable to Create Zip File", error);
      setIsZipping(false);
      AntdToast({ type: "error", message: "Error", description: "Error in generating zip file" });
    });
};

export const zipSingleFileAndDownload = (
  fileObj: IReceivedRFQFiles,
  setIsZipping: React.Dispatch<React.SetStateAction<boolean>>,
  folderName?: string,
) => {
  const fileNameSuffix = fileObj?.name
  .split(".")
  .pop()
  .toLowerCase();
  if (fileNameSuffix === "zip") { 
    downloadFileFromUrl(fileObj?.file?.fileUrl).then((respBlob)=> {
      /** 
       * FYI: passing first param as fileObj?.file?.fileUrl downloads zip file;
       * with name directly from the url, regardless of second param is the name we want
       * ref: https://github.com/eligrey/FileSaver.js/issues/670
       */ 
      saveAs(respBlob, fileObj?.name)
    });
  } else {
    let fileNamePrefix;
    if (!folderName) {
      fileNamePrefix = fileObj?.name
        .split(".")
        .slice(0, -1)
        .join(".");
    }
    setIsZipping(true);
    let zipFile = new JSZip();
    let promise = downloadFileFromUrl(fileObj.file.fileUrl);
    zipFile.file(fileObj.name, promise);
    generateZipFile(zipFile, setIsZipping, folderName || fileNamePrefix);
  }
};

export const zipMultipleFilesAndDownload = (
  zipFileName: string,
  files: IReceivedRFQFiles[],
  setIsZipping: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsZipping(true);
  let zipFile = new JSZip();
  let folder = zipFile.folder(zipFileName);

  files.forEach((fileObj) => {
    /**
     * not using downloadFileFromUrl function gives base64 error
     * ref: https://github.com/Stuk/jszip/issues/366
     */
    let promise = downloadFileFromUrl(fileObj.file.fileUrl);
    folder?.file(`${fileObj.name}`, promise);
  });
  generateZipFile(zipFile, setIsZipping, `${zipFileName}.zip`);
};

export const getBytesFromMB = (MB: number) => MB*1024*1024;

export const COMMON_FILE_TYPES = {
  DWG: "dwg",
  VND: "vnd",
  AUTOCAD_DWG: "autocad_dwg",
  JPEG: "jpeg",
  JPG: "jpg",
  PNG: "png",
  TIFF: "tiff",
  BMP: "bmp",
  DOC: "doc",
  DOCX: "docx",
  XLSX: "xlsx",
  XLS: "xls",
  XML: "xml",
  PPT: "ppt",
  PPTX: "pptx",
  TXT: "txt",
  PDF: "pdf",
  ZIP: "zip",
  RAR: "rar",
  STP: "stp",
  CSV: "csv",
  WEBP: "webp",
} as const;

export const getFileExtension = (file) => {
  return file.name
    .split(".")
    .pop()
    .toLowerCase();
};

export const isFileSupported = (fileExtension: string, supportedFileExtensions: string[]) =>
  supportedFileExtensions?.includes(fileExtension);
