import { notification } from "antd";
import OtpEmailVerify from "Authentication/Register/OtpVerify/EmailOtpVerify";
import { Button } from "components/Button";
import { AntdModal } from "components/Modal";
import ApiConstant from "constants/ApiConstant";
import { HEAP_DATA_ATTRIBUTES, HEAP_DATA_TRACKING_ID } from "constants/heapAnalytics";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { requestEmailMobileOtp, sendEmailVerificationLink } from "reducers/auth";
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import styled from "styled-components";
import { VERIFY_EMAIL_MODAL_CONSTANTS } from "./verifyEmailModal.constants";
import { ButtonContainer, EmailAddress, ModalDesc, ModalTitle, SkipOption } from "./verifyEmailModal.styles";
interface IProps {
  handleCloseModal?: (x?: any) => void;
  handleSuccessState?: (x?: any) => void;
  otpInputBoxWidth?: string;
}

const VerifyEmailModal = (props: IProps) => {
  const [closePopup, setClosePopup] = useState(false);
  const [showOtpModal, setshowOtpModal] = useState(false);
  const [emailSentResult, setemailSentResult] = useState(false);
  const user = SessionStorageService.get("user") || LocalStorageService.get("user");
  const emailId = user?.email;
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);

  const onClose = (e?) => {
    SessionStorageService.set(ApiConstant.STORAGE_KEYS.showEmailVerificationPopup, false);
    setClosePopup(true);
    if (props?.handleCloseModal) props?.handleCloseModal();
  };

  const sendEmailOTP = () => {
    dispatch(
      requestEmailMobileOtp(
        JSON.stringify({
          email: emailId,
        }),
      ),
    ).then(res => {
      setshowOtpModal(true);
    });
  };

  const sendEmailLink = () => {
    dispatch(
      sendEmailVerificationLink(
        {
          email: emailId,
        },
      ),
    )
      .then(res => {
        setClosePopup(!closePopup);
        notification.success({
          message: "Success",
          description: res.message,
        });
      })
      .catch(err => {
        setClosePopup(!closePopup);
        notification.error({
          message: "Error",
          description: "Something went wrong",
        });
      });
  };

  return (
    <AntdModal
      closable
      header={
        <>
          <ModalTitle>{VERIFY_EMAIL_MODAL_CONSTANTS.title}</ModalTitle>
          <ModalDesc>
            {showOtpModal
              ? VERIFY_EMAIL_MODAL_CONSTANTS.descriptionWithOTP
              : VERIFY_EMAIL_MODAL_CONSTANTS.descriptionWithoutOTP}
            {showOtpModal && <EmailAddress>{emailId}</EmailAddress>}
          </ModalDesc>
        </>
      }
      hideFooter={true}
      headerPadding="40px 45px 0px 40px"
      closeIcon={
        <img
          src="/images/blue-x.svg"
          alt="close-icon"
          {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.EMAIL_VERIFICATION_MODAL.CLOSE }}
        />
      }
      bodyPadding="40px 45px 45px 40px"
      negativeAction={e => {
        onClose(e);
      }}
      closePopup={closePopup}
      body={
        showOtpModal ? (
          <OtpEmailVerify
            otpInputBoxWidth={props?.otpInputBoxWidth}
            email={emailId}
            handleEmailOtp={(e: boolean): void => {
              //setShowEmailSuccess(e);
            }}
            handleSuccessState={() => {
              if (props?.handleSuccessState) props?.handleSuccessState();
            }}
          />
        ) : (
          <ButtonContainer>
            <Button
              onClick={() => {
                sendEmailOTP();
              }}
              variant="secondary"
              size="middle"
              style={{
                width: "30%",
                borderRadius: "8px",
                height: "40px",
              }}
              loading={authState.loginEmailOtpLoading}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.EMAIL_VERIFICATION_MODAL.SEND_OTP_TO_EMAIL }}
            >
              SEND OTP
            </Button>
            <Button
              onClick={() => {
                sendEmailLink();
              }}
              style={{
                width: "70%",
                borderRadius: "8px",
                height: "40px",
              }}
              variant="secondary"
              size="middle"
              loading={authState.sendVerifyEmailLinkLoading}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.EMAIL_VERIFICATION_MODAL.SEND_OTP_TO_EMAIL }}
            >
              SEND VERIFICATION LINK
            </Button>
            {/* <SkipOption
              onClick={() => {
                onClose();
              }}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.EMAIL_VERIFICATION_MODAL.SKIP_FOR_NOW }}
            >
              SKIP FOR NOW
            </SkipOption> */}
          </ButtonContainer>
        )
      }
    />
  );
};

export default VerifyEmailModal;
