interface PasswordProps {
    getPassword?: string,
}
var percentageValue = 0;
const PasswordStrengthEstimator = (getPassword: string) => {

    var strongRegex = new RegExp("^(?=.{14,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
    var mediumRegex = new RegExp("^(?=.{10,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
    var enoughRegex = new RegExp("(?=.{8,}).*", "g");

    if (false === enoughRegex.test(getPassword)) {
        return 0

    } else if (strongRegex.test(getPassword)) {
        percentageValue = 0
        return 100;

    } else if (mediumRegex.test(getPassword)) {
        percentageValue = 0
        return 84;

    } else {
        percentageValue = 0
        return 50;
    }
}
export default PasswordStrengthEstimator;