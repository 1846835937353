import React, { useState } from "react";
import "antd/dist/antd.css";
import styled from "styled-components";
import { Select } from "antd";

const { Option } = Select;

type Sizes = "large" | "small" | "large" | "extralarge" | "medium" | "profileCreationSmall";
export interface DropdownProps {
  usedFor?: "profileCreation" | "onboarding" | "companyInfo_turnOverSlab" | "profileCreation_experience" | "image_video_upload" ;
  onChange?: (element: any) => void;
  onSearch?: (element: any) => void;
  data?: { id: number; value: string }[];
  sizes?: Sizes;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  error?: boolean;
  paddingNotRequired?: boolean;
  onBlurTrigger?:()=>void;
  onBlurEventRequired?:boolean;
  height?: string;
  $focusState?:boolean;
}

export const DropdownAntd = styled(Select)<DropdownProps>`
box-shadow: ${props =>
  props?.$focusState && "0px 0px 12px rgba(159, 179, 248, 0.4)"};
  width: ${props => `${props.theme.input.sizes[props.sizes].width}`};
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  font-weight: 500;
  margin-top:0;
  width: ${props => `${ props.usedFor === "profileCreation_experience" && "250px"}` } ;
  & .ant-select-selector {
    height: ${ props => props?.height || "60px" }!important;
    border-radius: 8px !important;
    border: ${props =>
      props.error
        ? "1px solid #BE000B !important"
        : "1px solid #D5D9EC !important"};
    background:  ${props =>
      props.error
        ? "rgba(190, 0, 11, 0.05) !important"
        : "#FFFFFF"};
    display: flex;
    align-items: center;
    padding-left: 20px !important;
    height: ${props => `${ (props.usedFor === "companyInfo_turnOverSlab" || props.usedFor === "profileCreation_experience" )
     && "40px"}` } !important ;
  }
  & .ant-select-selection-search-input {
    height: ${props => `${ (props.usedFor === "profileCreation_experience" )
     ? "40px" : props?.height || "60px" }` } !important ;
    padding-left: 9px !important;
  }
  & .ant-select-selection-placeholder {
    color: #011759;
    ;
    opacity:0.7;
    
    font-weight: 500;
    margin-top:${props =>
      props.usedFor == "profileCreation"
        ? props.paddingNotRequired
          ? "0px"
          : "8px"
        : "0px"};
    & .ant-select-selector {
        height: 60px !important;
        border-radius: 8px !important;
        border: ${props =>
          props.error
            ? "1px solid #BE000B !important"
            : "1px solid #D5D9EC !important"};
      background:  ${props =>
      props.error
        ? "rgba(190, 0, 11, 0.05) !important"
        : "#FFFFFF"};
        display: flex;
        align-items: center;  
        padding-left:20px !important;
       
    }
    & .ant-select-selection-search-input {
        height: 60px !important;
        padding-left:9px !important;
    }
    & .ant-select-selection-placeholder {
        font-weight: 500;
        font-size: 18px;
        font-style: normal;
        color: #011759 ;
        opacity: 0.7px;
    }
    & :not(.ant-select-customize-input){
        color:${props => props.theme.colors.primary};;
    }
    & .ant-select-arrow{
      margin-right:6px;
  }
}

  @media screen and (max-width: 640px) {
    width: ${props => `${props.theme.input.sizes[props.sizes]?.mobileScreen?.width}`};
  }
`;

const BasicDropdown = ({ ...props }: DropdownProps) => {
  const [focusState, setFocusState] = useState(false);

  return (
    <DropdownAntd
    onFocus={() => setFocusState(true)}
    bordered={!focusState}
    dropdownStyle={{
      borderRadius: "0px 0px 8px 8px",
      boxShadow: " 0px 10px 12px rgba(159, 179, 248, 0.4)",
      border: "1px solid #D4E5FE",
      borderTop: "none",
      paddingTop:"15px"
    }}
    dropdownAlign={{ offset: [0, -15] }}
      listItemHeight={5}
      listHeight={150}
      onBlur={e => {
        if(props?.onBlurEventRequired)
        props?.onBlurTrigger();
        setFocusState(false);
      }}
      {...props}
      suffixIcon={
        props.disabled 
        ? ""
        : <img
          style={{ marginRight: "20px" }}
          src="/images/DashBoard/arrow-down.svg"
        />
      }
      error={props.error}
      showSearch={!(props.usedFor === "companyInfo_turnOverSlab")}
      placeholder={props.placeholder}
      value={props.value}
      optionFilterProp="children"
      onChange={props.onChange}
      onSearch={props.onSearch}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={props.disabled}
      getPopupContainer={trigger => trigger.parentNode}
      notFoundContent={<div />}
    >
      {props.data.map((item, index) => {
        return (
          <Option key={index} value={item.value}>
            {item.value}
          </Option>
        );
      })}
    </DropdownAntd>
  );
};

export default BasicDropdown;
