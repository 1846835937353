import React from "react";
import styled from "styled-components";
import { Button } from "components/Button";

interface AccountProps {
  desc: string;
  isApproval?: boolean;
  userName?: string;
  isLogin?: boolean;
  children?: string;
  progress?: boolean;
  onNextButton?: (e: boolean) => void;
  usedFor?:string
}
interface Props{
  usedFor?:string
}
const Container = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding-top: ${props => props.usedFor==="invite"?"0":"50%"};
`;

const Desc = styled.div<Props>`
  font-weight: 600;
  font-size: ${props => props.usedFor==="invite"?"16px":"30px"};
  color: ${props => props.theme.colors.darkBlue};
  padding-top: 28px;
  padding-bottom: 60px;
`;
const SubDesc = styled.div`
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 24px;
  color: ${props => props.theme.colors.blue};
`;
const SubText = styled.div`
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: ${props => props.theme.colors.blue};
`;
function SuccessMsg({
  desc,
  progress,
  onNextButton,
  isApproval,
  userName,
  isLogin,
  children,
  usedFor
}: AccountProps) {
  const handleClick = () => {
    onNextButton(true);
  };
  return (
    <Container usedFor={usedFor}>
      <img
        src={progress ? "/images/progress.png" : "/images/Auth/success.svg"}
        alt="validated"
      />
      <Desc style={{fontSize: `${usedFor==="invite"?"16px":"30px"}`}}>{desc}</Desc>
      {isLogin && <SubDesc>Welcome to Venwiz family</SubDesc>}

      {progress ? (
        <Button
          onClick={handleClick}
          variant="secondary"
          size="middle"
          usedFor="succcess"
          style={{ padding: "20px 67px" }}
          buttonLoading={true}
        >
          {children}
        </Button>
      ) : (
          <Button
            onClick={handleClick}
            variant="secondary"
            size="middle"
            usedFor="succcess"
            style={{ padding: "20px 67px" }}
          >
            {children}
          </Button>
        )}

      {isApproval && (
        <Container >
          <SubDesc>Account approval is pending</SubDesc>
          <SubText>
            A primary user <b>{userName} </b>
            already exists in the system. You will receive a confirmation mail
            and SMS as soon as they approve your account, after which you will
            be able to login.
          </SubText>
        </Container>
      )}
    </Container>
  );
}

export default SuccessMsg;
