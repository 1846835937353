import React, { useContext, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { ErrorMessage, useField } from 'formik';

interface InputWithHeaderProps {
  name?: string;
}

const ErrorMessageDiv = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  color: #be000b;
`;
const ImageHolder = styled.div`
  background-image: url('/images/warning.svg');
  background-size: cover;
  margin-right: 3px;
  height: auto;
  width: 16px;
  height: 16px;
`;

const ErrorMessageComponent = ({ name }: InputWithHeaderProps) => (
  <ErrorMessageDiv>
    <ImageHolder />
    {name}
  </ErrorMessageDiv>
);

export default ErrorMessageComponent;
