export const COUNTRY_REQUESTED = "locations/COUNTRY_REQUESTED";
export const COUNTRY_SUCCESS = "locations/COUNTRY_SUCCESS";
export const COUNTRY_FAILURE = "locations/COUNTRY_FAILURE";

export const CITY_REQUESTED = "locations/CITY_REQUESTED";
export const CITY_SUCCESS = "locations/CITY_SUCCESS";
export const CITY_FAILURE = "locations/CITY_FAILURE";

export const STATE_REQUESTED = "locations/STATE_REQUESTED";
export const STATE_SUCCESS = "locations/STATE_SUCCESS";
export const STATE_FAILURE = "locations/STATE_FAILURE";

const initialState = {
  countryLoading: false,
  countryLoaded: false,
  countryError: false,
  countryErrorData: null,

  cityLoading: false,
  cityLoaded: false,
  cityError: false,
  cityErrorData: null,

  stateLoading: false,
  stateLoaded: false,
  stateError: false,
  stateErrorData: null,

  countryData: null,
  cityData: null,
  stateData: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY_REQUESTED:
      return {
        ...state,
        countryLoading: true,
      };

    case COUNTRY_SUCCESS:
      return {
        ...state,
        countryLoading: false,
        countryLoaded: true,
        countryError: false,
        countryData: action.result,
      };

    case COUNTRY_FAILURE:
      return {
        ...state,
        countryLoading: false,
        countryLoaded: true,
        countryError: true,
        countryErrorData: action.error.message,
      };
    case CITY_REQUESTED:
      return {
        ...state,
        cityLoading: true,
      };

    case CITY_SUCCESS:
      return {
        ...state,
        cityLoading: false,
        cityLoaded: true,
        cityError: false,
        cityData: action.result,
      };

    case CITY_FAILURE:
      return {
        ...state,
        cityLoading: false,
        cityLoaded: true,
        cityError: true,
        cityErrorData: action.error.message,
      };

    case STATE_REQUESTED:
      return {
        ...state,
        stateLoading: true,
      };

    case STATE_SUCCESS:
      return {
        ...state,
        stateLoading: false,
        stateLoaded: true,
        stateError: false,
        stateData: action.result,
      };

    case STATE_FAILURE:
      return {
        ...state,
        stateLoading: false,
        stateLoaded: true,
        stateError: true,
        stateErrorData: action.error.message,
      };
    default:
      return state;
  }
};

export const searchCountry = (params) => ({
  types: [COUNTRY_REQUESTED, COUNTRY_SUCCESS, COUNTRY_FAILURE],
  promise: (client) =>
    client.get(`country_get_data`, {
      params,
    }),
});
export const searchCity = (data) => ({
  types: [CITY_REQUESTED, CITY_SUCCESS, CITY_FAILURE],
  promise: (client) =>
    client.post(`city_post_data`, {
      data,
    }),
});
export const searchState = (data) => ({
  types: [STATE_REQUESTED, STATE_SUCCESS, STATE_FAILURE],
  promise: (client) =>
    client.post(`state_post_data`, {
      data,
    }),
});
