import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { ProfileData, USER_INFO_OPTION } from "constants/mockData";
import { Label } from "../../Label";

import { BasicLayOut } from "..";
import { useDispatch, useSelector } from "react-redux";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import { documentInfoGet } from "reducers/documentInfo";
import { referenceInfoGet } from "reducers/referenceInfo";
import { companyInfoGet } from "reducers/companyInfo";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { ButtonWithIcon } from "components/Button";
import { CardInfo, CardProgress } from "components/Card";
import SessionStorageService from "services/SessionStorageService";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { Modal as PlatformVideoModal } from "antd";
import DashboardLayout from "components/Layout/DashboardLayout/Layout";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import Modal from "Profile/Components/Modal/Modal";
import VerifyEmailModal from "Authentication/VerifyEmailModal/VerifyEmailModal";
interface ProfileCreationProps {
  bodyContent: React.ReactNode;
}

const InnerWrapper = styled.div`
  display: flex;
  width: calc(100% - 60px);
  background-color: ${props => `${props.theme.colors.white}`};
  border-radius: ${props => `${props.theme.globalStyles.borderRadius}`};
  // margin-top: 100px;
  margin-bottom: 37px;
  height: calc(100vh - 140px);
  min-width: 1332px;
`;
const ProfileCreationHeader = styled.div`
  margin-top: 16px;
  width: 330px;
  height: 39px;
  border-bottom: 1px solid #d4e5fe;
  display: flex;
  align-items: center;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentWrapper = styled.div`
  padding: 31px 60px 30px 60px;
  width: 100%;
`;

const ProfileProgress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 235px;
  padding: 26px 0px 26px 0px;
`;
const ProfileHeading = styled.div`
  color: #011759;
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
`;
const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 15px;
  font-weight: 300;
  word-break: break-all;
`;
const ProfileInfo = styled.div`
  height: calc(100vh - 510px);
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 8px;
    margin-bottom: 5px;
    margin-left: 10px;
  }
`;
export const ModalVideoContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const VideoModal = styled(PlatformVideoModal)`
  & .ant-modal-body {
    height: 600px;
    padding: 0px 0px 0px 0px;
  }

  & .ant-modal-footer {
    text-align: center;
    font-weight: 600;
    color: #215ec9;
    font-size: 20px;
  }
`;
const ProfileCreationLayOut = ({ bodyContent }: ProfileCreationProps) => {
  const { STORAGE_KEYS } = AppConfig;
  const sessionData = LocalStorageService.get(STORAGE_KEYS.isRememberEnabled)
    ? LocalStorageService.get(STORAGE_KEYS.userData)
    : SessionStorageService.get(STORAGE_KEYS.userData);
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState<any>(bodyContent || USER_INFO_OPTION);
  const history = useHistory();
  const dispatch = useDispatch();
  const percentageState = useSelector(
    (state: any) => state.dashboardPercentage
  );
  const { percentageData } = percentageState;
  const userInfoData =
    useSelector((state: any) => state.userInfoApi.userInfoData) || {};
  const companyInfoState = useSelector((state: any) => state.companyInfo);
  const { companyInfoData } = companyInfoState;
  const documentState = useSelector((state: any) => state.documentInfo);
  const { documentInfoGetData } = documentState;
  const referenceState = useSelector((state: any) => state.referenceInfo);
  const { referenceInfoGetdata } = referenceState;
  const applicationFlags = LocalStorageService.get(
    STORAGE_KEYS.applicationFlags
  );
  
  useEffect(() => {
    if (!percentageData?.gstn) {
      dispatch(dashboardPercentageGet({}));
    }
    if (!documentInfoGetData?.document) {
      dispatch(documentInfoGet({}));
    }
    if (!referenceInfoGetdata?.reference) {
      dispatch(referenceInfoGet({}));
    }
    if (!companyInfoData?.companyName) {
      dispatch(companyInfoGet({}));
    }
  }, []);

  const handleClick = () => {
    history.replace("/dashboard");
  };

  useEffect(() => {
    if (applicationFlags?.showDemoVideo) {
      applicationFlags.showDemoVideo = false;
      LocalStorageService.set(STORAGE_KEYS.applicationFlags, applicationFlags);
      setVisible(true);
    }
  }, [applicationFlags]);

  return (
    <BasicLayOut>
      <InnerWrapper>
        <CardProgress type="profileCreationSidebar">
          <ButtonWithIcon
            onClick={handleClick}
            size="small"
            variant="blue"
            iconPlacement="left"
            iconClass="icon-arrow-left"
          >
            Home
          </ButtonWithIcon>
          <ProfileCreationHeader>Profile</ProfileCreationHeader>
          <ProfileProgress>
            <ProgressBar
              percentageValue={percentageData ? percentageData.totalProfile : 0}
            />
            <ProfileHeading>
              <Label
                fontVariant="bold"
                text={companyInfoData?.companyName || ""}
                htmlFor="profileName"
              />
            </ProfileHeading>
            <ProfileName>
              {userInfoData?.firstName
                ? userInfoData.firstName + " " + userInfoData.lastName
                : ""}
            </ProfileName>
          </ProfileProgress>
          <ProfileInfo className="profileInfo">
            {ProfileData.map(menu => (
              <Link
                key={menu.id}
                to={menu.linkTo}
                className={
                  active && active.linkTo === menu.linkTo ? "active" : ""
                }
                style={{
                  display: "block",
                  padding: "5px 5px",
                  background:
                    active && active.linkTo === menu.linkTo
                      ? "#F4F6FF"
                      : "transparent",
                }}
              >
                <CardInfo
                  onClick={() => {
                    setActive(menu);
                  }}
                  usedAt="progressView"
                  percentValue={
                    menu.key === "userInformation"
                      ? 100
                      : percentageData?.[menu.key] || 0
                  }
                  title={menu.cardName}
                  imgSrc={menu.icon}
                />
              </Link>
            ))}
          </ProfileInfo>
        </CardProgress>
        <ContentWrapper>{active ? active.render() : null}</ContentWrapper>
      </InnerWrapper>
    </BasicLayOut>
  );
};

export default ProfileCreationLayOut;
