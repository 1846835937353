import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import { Button } from "components/Button";

interface ModalProp {
  header?: string | React.ReactNode;
  body?: React.ReactNode;
  positiveActionText?: string;
  negativeActionText?: string;
  closable?: boolean;
  width?: string | number;
  positiveAction?: () => void;
  negativeAction: (value?: string) => void;
  disabled?: boolean;
  buttonDirectionColumn?: boolean;
  variant?:
    | "danger"
    | "primary"
    | "secondary"
    | "white"
    | "blue"
    | "red"
    | undefined;
  positiveButtonColor?: string;
  positiveButtonBorderColor?: string;
  negativeButtonColor?: string;
  negativeButtonBorderColor?: string;
  maskClosable?: boolean;
  modalWidth?: string;
  alignment?: string;
  closableMargin?:string;
  positiveButtonBackgroundColor?:string;
  closeIcon?: React.ReactNode;
  headerPadding?: string;
  bodyPadding?: string;
  hideFooter?: boolean;
  closePopup?: boolean;
  modalBorderRadius?: string;
  buttonBorderRadius?: string;
}

const Base = styled.div`
  background-attachment: fixed;
`;

const ModalBox = styled(Modal)<ModalProp>`
  & .ant-modal-content {
    border-radius: ${props => props?.modalBorderRadius || "8px"};
    width: ${(props) => (props.modalWidth ? props.modalWidth : "100%")};
    text-align: ${(props) =>
      props.alignment? props.alignment : ""};
    font-weight: 800;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1em;
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
    padding: ${(props) => props?.headerPadding || "32px 0px 16px 32px"};
  }
  & .ant-modal-close {
    margin: ${props => props?.closableMargin?props?.closableMargin:"10px"};
   }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
  }
  & .ant-modal-body {
    padding: ${(props) => props?.bodyPadding || "0px 32px 32px"};
    color: ${(props) => props.theme.colors.primary};
    overflow-y: auto;
    line-height: 170%;
  }
`;
const Title = styled.div`
  color: #011759;;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`;

const ButtonWrap = styled.div<ModalProp>`
  display: flex;
  justify-content: space-around;
  width: ${props => (props?.buttonDirectionColumn ? "70%" : "100%")};
  height: ${props => (props?.buttonDirectionColumn ? "200px" : "none")};
  padding-bottom: 30px;
  flex-direction: ${props => (props?.buttonDirectionColumn ? "column" : "row")};
`;

function AntdModal(props: ModalProp) {
  const [isModalVisible, setIsModalVisible] = useState(true);

  useEffect(() => {
    setIsModalVisible(!props.closePopup);
  },[props.closePopup])

  const handleConfirm = () => {
    setIsModalVisible(false);
    //callback here
    props.positiveAction();
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = (e?: any) => {
    setIsModalVisible(false);
    props.negativeAction(e.currentTarget.id);
  };

  return (
    <Base>
      <ModalBox
        {...props}
        centered={true}
        title={props.header ? <Title>{props.header}</Title> : null}
        footer={
          props?.hideFooter ? null : (
            <ButtonWrap {...props}>
              {props.positiveActionText && (
                <Button
                  id="onOk"
                  onClick={handleConfirm}
                  variant={props.variant ? props.variant : "tertiary"}
                  color={
                    props.positiveButtonColor
                      ? props.positiveButtonColor
                      : "white"
                  }
                  size="middle"
                  style={{
                    padding: "14px 42px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "0.1em",
                    textTransform: "uppercase",
                    borderColor: props.positiveButtonBorderColor,
                    backgroundColor:props?.positiveButtonBackgroundColor
                  }}
                  disabled={props.disabled}
                  borderRadius={props.buttonBorderRadius}
                >
                  {props.positiveActionText}
                </Button>
              )}
              {props.negativeActionText && (
                <Button
                  onClick={handleCancel}
                  id="onCancel"
                  variant={"white"}
                  color={
                    props.negativeButtonColor
                      ? props.negativeButtonColor
                      : "secondary"
                  }
                  size="middle"
                  style={{
                    padding: "14px 42px",
                    fontWeight: "bold",
                    fontSize: "12px",
                    lineHeight: "18px",
                    letterSpacing: "0.1em",
                    textTransform: "uppercase",
                    borderColor: props.negativeButtonColor
                      ? props.negativeButtonColor
                      : "#BE000B",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {props.negativeActionText}
                </Button>
              )}
            </ButtonWrap>
          )
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={props.maskClosable ?? true }
        closeIcon={
          props?.closeIcon || (
            <img src="/images/blue-x.svg" alt="close button"></img>
          )
        }
        closable={props.closable}
        width={props.width}
      >
        {props.body}
      </ModalBox>
    </Base>
  );
}

export default AntdModal;
