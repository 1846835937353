import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
100% {
    transform: rotate(1turn);
}
`;
const Spinner = styled.div`
display:inline-block;
min-height:2em;
min-width:2em;
background:transparent;
border-radius:50%;
border: 3px solid #D4E5FE;
border-left-color:#215EC9;
animation: ${rotate} 0.7s linear infinite;
@media only screen and (max-width: 640px) {
max-height:10px;
max-width:10px;
}
`;
const CardSpinner = () => {
    return(
    <Spinner />
    )
}
export default CardSpinner;