import React, { useRef, useState, useEffect } from "react";
import Select, { StylesConfig } from "react-select";
import {
  Box,
  Card,
  Divider,
  Flex,
  Modal,
  Typography,
  InputWithLabel,
  StylesProps,
  Button,
  InputWithIcon,
} from "venwiz-ui";
import { IManufCapabilitiesModalProps } from "Profile/Capabilities/Interfaces/Capabilities.interfaces";
import {
  Formik,
  Form,
  FormikTouched,
  FormikErrors,
  FormikProps,
  FormikHelpers,
} from "formik";
import { RiCloseFill } from "react-icons/ri";
import { colors } from "Theme";
import { IPlantMachineDetail } from "venwiz-ui/dist/esm/components/organism/ManufCapabilitiesTable/ManufCapabilitiesTable.interface";
import {
  FORMIK_PLANT,
  PLANT_DROPDOWN_OPTIONS,
} from "Profile/Capabilities/components/PlantMachineDetails/plantMachineDetails.constant";
import { stateGeoDbData } from "constants/mockData";
import { MODAL_STYLES, FlexRow } from "./plantMachineDetailsModal.styles";
import {
  isWordWithSpace,
  isNumber,
  isDecimalNumber,
} from "shared/handlers/regex.handler";
import { toggleIsPageEdited } from "reducers/profileCreation";
import { useDispatch } from "react-redux";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
import { heapTrackTrigger } from "shared/handlers/heapAnalytics.handler";

interface IProps extends IManufCapabilitiesModalProps {
  showModal: boolean;
  handleClose: () => void;
}

export const AddOrEditPlantModal = (props: IProps) => {
  const {
    indexOfCurrPlant,
    isFormAddNewOrEditExisting,
  } = props;
  const ID_OF_EXISTING_PLANT = (props?.currPlant as IPlantMachineDetail)?._id;
  const { INITIAL_ERRORS, INITIAL_STATE, SCHEMA } = FORMIK_PLANT;
  const [formState, setFormState] = useState(INITIAL_STATE);
  const formRef = useRef<FormikProps<typeof INITIAL_STATE>>();
  const dispatch = useDispatch();
  const {
    CITY,
    STATE,
    TOTAL_AREA,
    WORKSHOP_AREA,
    CAPACITY,
    OTHER_INFORMATION,
    SAVE_PLANT_DETAILS,
  } = HEAP_DATA_ATTRIBUTES.CAPABILITIES.MANUFACTURING_CAPABILITIES.HEAP_TRACK.PLANT.FORM;

  useEffect(() => {
    const EXISTING_PLANT = props?.currPlant as IPlantMachineDetail;
    setFormState({
      // inject remaining keys like _id
      ...EXISTING_PLANT,
      location: EXISTING_PLANT?.location || { city: "", state: "" },
      totalArea: EXISTING_PLANT?.totalArea || { value: null, unit: "" },
      workshopArea: EXISTING_PLANT?.workshopArea || { value: null, unit: "" },
      capacity: EXISTING_PLANT?.capacity || { value: null, unit: "" },
      otherInfo: EXISTING_PLANT?.otherInfo || "",
      machines: EXISTING_PLANT?.machines || [],
    });
  }, [props?.currPlant]);

  const handleOnSubmitForm = (
    values: typeof INITIAL_STATE,
    formikHelpers: FormikHelpers<typeof INITIAL_STATE>
  ) => {
    const PLANT_PAYLOAD_OBJECT = {
      ...props?.currPlant,
      location: values?.location,
      totalArea: values?.totalArea,
      workshopArea: values?.workshopArea,
      capacity: values?.capacity,
      otherInfo: values?.otherInfo,
      machines: values?.machines || [],
    } as IPlantMachineDetail;

    if (isFormAddNewOrEditExisting === "EDIT_EXISTING") {
      /** Update Existing Plant */
      props?.setPlantMachineDetails((prev) =>
        prev?.map((plant: IPlantMachineDetail, plantIdx) => {
          const isSamePlant = (
            plant?._id 
            && ID_OF_EXISTING_PLANT 
            && plant?._id === ID_OF_EXISTING_PLANT
          ) || indexOfCurrPlant === plantIdx;

          return isSamePlant ? PLANT_PAYLOAD_OBJECT : plant;
        })
      );
    } else if (isFormAddNewOrEditExisting === "ADD_NEW") {
      /** Add New Plant */
      props?.setPlantMachineDetails((prev) => prev?.concat(PLANT_PAYLOAD_OBJECT));
    }

    /** Heap Analytics */
    heapTrackTrigger({
      windowObj: window,
      eventName: SAVE_PLANT_DETAILS,
      value: {
        city: values?.location?.city || "",
        state: values?.location?.state || "",
        totalAreaValue: values?.totalArea?.value || "",
        totalAreaUnit: values?.totalArea?.unit || "",
        workshopAreaValue: values?.workshopArea?.value || "",
        workshopAreaUnit: values?.workshopArea?.unit || "",
        capacityValue: values?.capacity?.value || "",
        capacityUnit: values?.capacity?.unit || "",
        otherInformation: values?.otherInfo || "",
      }
    });

    /** Clear State */
    props?.handleClose();
    setFormState(INITIAL_STATE);
    formikHelpers?.resetForm();
    toggleIsPageEdited(dispatch, true);
  };

  const handleOnChange = {
    plantCity: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const VAL = e?.target?.value;
      if (isWordWithSpace(VAL)) {
        setFormState((prev) => ({
          ...prev,
          location: { ...prev?.location, city: VAL || "" },
        }));
      } else if (!VAL?.length) {
        setFormState((prev) => ({
          ...prev,
          location: { ...prev?.location, city: "" },
        }));
      }
    },
    plantState: (item: { id: number; label: string; value: string }) => {
      setFormState((prev) => ({
        ...prev,
        location: { ...prev?.location, state: item?.value || "" },
      }));
    },
    plantTotalareaValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const VAL = e?.target?.value;
      setFormState((prev) => ({
        ...prev,
        totalArea: {
          ...prev?.totalArea,
          value: VAL?.length
            ? isDecimalNumber(VAL)
              ? Number(VAL)
              : prev?.totalArea?.value
            : null,
        },
      }));
    },
    plantTotalareaUnit: (item: {
      id: number;
      label: string;
      value: string;
    }) => {
      setFormState((prev) => ({
        ...prev,
        totalArea: {
          ...prev?.totalArea,
          unit: item?.value || "",
        },
      }));
    },
    plantWorkshopAreaValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const VAL = e?.target?.value;
      setFormState((prev) => ({
        ...prev,
        workshopArea: {
          ...prev?.workshopArea,
          value: VAL?.length
            ? isDecimalNumber(VAL)
              ? Number(VAL)
              : prev?.workshopArea?.value
            : null,
        },
      }));
    },
    plantWorkshopAreaUnit: (item: {
      id: number;
      label: string;
      value: string;
    }) => {
      setFormState((prev) => ({
        ...prev,
        workshopArea: {
          ...prev?.workshopArea,
          unit: item?.value || "",
        },
      }));
    },
    plantCapacityValue: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const VAL = e?.target?.value;
      setFormState((prev) => ({
        ...prev,
        capacity: {
          ...prev?.capacity,
          value: VAL?.length
            ? isDecimalNumber(VAL)
              ? Number(VAL)
              : prev?.capacity?.value
            : null,
        },
      }));
    },
    plantCapacityUnit: (item: { id: number; label: string; value: string }) => {
      setFormState((prev) => ({
        ...prev,
        capacity: {
          ...prev?.capacity,
          unit: item?.value || "",
        },
      }));
    },
    plantOtherInformation: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const VAL = e?.target?.value;
      setFormState((prev) => ({ ...prev, otherInfo: VAL || "" }));
    },
  };

  return (
    <Modal
      padding={"12px 20px 30px 20px"}
      backgroundStyles={{ zIndex: 1000 }}
      show={props?.showModal}
      handleClose={() => {}}
      closeIcon={
        <Box
          padding={"5px"}
          cursor={"pointer"}
          onClick={() => {
            /**
             * handleClose callback function must only be triggered on clicking the close icon
             * and not on clicking the modal handleClose prop
             * because it will close the modal:
             *  - even when the user clicks outside the modal
             *  - or if the form has validation errors
             */
            props?.handleClose();
          }}
        >
          <RiCloseFill size={20} color={"#666d77"} />
        </Box>
      }
      // To ensure that the modal is responsive and takes up the entire screen on mobile devices
      maxWidth={{ default: "100vw", sm: "770px" }}
      width={{ default: "100vw", sm: "75vw" }}
      maxHeight="80vh"
      height={{ default: "80vh", sm: "auto" }}
      overflow="auto"
      position="relative"
      modalHeader={
        <Typography
          padding="4px"
          fontSize={"14px"}
          fontWeight={600}
          color={colors.darkBlue}
        >
          Plant/Workshop Details
        </Typography>
      }
      modalBody={
        <Formik
          /** prop enableReinitialize
           *  helps to re-render form with new Initial State Values
           *  (like new values injected by API Call from useEffect) */
          enableReinitialize
          /** prop innerRef
           * is used to access form values outside the Formik Component */
          innerRef={formRef}
          initialValues={formState}
          validationSchema={SCHEMA}
          isInitialValid={SCHEMA.isValidSync(INITIAL_STATE)}
          initialErrors={INITIAL_ERRORS}
          onSubmit={handleOnSubmitForm}
        >
          {(formikProps) => {
            const {
              errors,
              touched,
              setValues,
              setFieldValue,
              values,
              isValid,
            } = formikProps;

            const ERROR_MESSAGE = {
              plantCity: errors.location?.city || "",
              plantState: errors.location?.state || "",
              plantTotalarea:
                errors.totalArea?.value || errors.totalArea?.unit || "",
              plantWorkshopArea:
                errors.workshopArea?.value || errors.workshopArea?.unit || "",
              plantCapacity:
                errors.capacity?.value || errors.capacity?.unit || "",
              plantOtherInformation: errors.otherInfo || "",
            };

            const HAS_ERRORS = {
              plantCity:
                touched.location?.city && Boolean(errors.location?.city),
              plantState:
                touched.location?.state && Boolean(errors.location?.state),
              plantTotalArea:
                (touched.totalArea?.value &&
                  Boolean(errors.totalArea?.value)) ||
                (touched.totalArea?.unit && Boolean(errors.totalArea?.unit)),
              plantWorkshopArea:
                (touched.workshopArea?.value &&
                  Boolean(errors.workshopArea?.value)) ||
                (touched.workshopArea?.unit &&
                  Boolean(errors.workshopArea?.unit)),
              plantCapacity:
                (touched.capacity?.value && Boolean(errors.capacity?.value)) ||
                (touched.capacity?.unit && Boolean(errors.capacity?.unit)),
              plantOtherInformation:
                touched.otherInfo && Boolean(errors.otherInfo),
            };

            return (
              <Form
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                {/* Form Field Wrapper */}
                <FlexRow>
                  {/* Row #1 */}
                  <FlexRow
                    flexDirection={{ default: "column", md: "row" }}
                  >
                    {/* Plant City */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      isMandatory
                      header={"City"}
                      id={`plant-city`}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.plantCity}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: { width: "100%" },
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        inputProps: {
                          style: MODAL_STYLES.COMMON.TEXT_INPUT_STYLES,
                          name: "plantCity",
                          label: "plantCity",
                          placeholder: "Bangalore",
                          value: values?.location?.city || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange.plantCity(e);
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              eventName: CITY,
                              value: { city: values?.location?.city || "" },
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.plantCity}
                    />

                    {/* Plant State Dropdown */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"State"}
                      id={`plant-state`}
                      inputVariant={"autocomplete"}
                      errorMessage={ERROR_MESSAGE.plantState}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "12px",
                          width: "100%",
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        inputProps: {
                          ...MODAL_STYLES.COMMON.SELECT_STYLES,
                          name: "plantState",
                          label: "plantState",
                          isClearable: true,
                          placeholder: "Karnataka",
                          value: (() => {
                            const VAL = values?.location?.state || "";
                            return VAL?.length
                              ? { label: VAL, value: VAL }
                              : null;
                          })(),
                          options: (() => {
                            return stateGeoDbData?.map((str: string) => ({
                              value: str,
                              label: str,
                            }));
                          })(),
                          onChange: (item: {
                            id: number;
                            label: string;
                            value: string;
                          }) => {
                            handleOnChange.plantState(item);
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              eventName: STATE,
                              value: { state: values?.location?.state || "" },
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.plantState}
                    />
                  </FlexRow>

                  {/* Row #2 */}
                  <FlexRow>
                    {/* Plant Total Area */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"Total Area"}
                      id={"plant-totalarea"}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.plantTotalarea}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "12px",
                          width: "100%",
                          minWidth: "200px"
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderLeft: "none",
                        borderRadius: "8px",
                        inputProps: {
                          name: "plantTotalarea",
                          label: "plantTotalarea",
                          placeholder: "99",
                          value: values?.totalArea?.value || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange.plantTotalareaValue(e);
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              eventName: TOTAL_AREA.VALUE,
                              value: { totalAreaValue: values?.totalArea?.value || "" },
                            });
                          },
                        },
                        rightIcon: (
                          <Select
                            placeholder={"unit"}
                            options={PLANT_DROPDOWN_OPTIONS.AREA}
                            value={(() => {
                              const VAL = values?.totalArea?.unit || "";
                              return VAL?.length
                                ? { label: VAL, value: VAL }
                                : null;
                            })()}
                            onChange={(item: {
                              id: number;
                              label: string;
                              value: string;
                            }) => {
                              handleOnChange.plantTotalareaUnit(item);
                            }}
                            onBlur={() => {
                              heapTrackTrigger({
                                windowObj: window,
                                eventName: TOTAL_AREA.UNIT,
                                value: { totalAreaUnit: values?.totalArea?.unit || "" },
                              });
                            }}
                            {...MODAL_STYLES.PLANT_MODAL
                              .MEASUREMENT_AUTOSELECT_INPUT_STYLES}
                          />
                        ),
                      }}
                      hasError={HAS_ERRORS.plantTotalArea}
                    />

                    {/* Plant Workshop Area */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"Workshop Area"}
                      id={"plant-workshop-area"}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.plantWorkshopArea}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "12px",
                          width: "100%",
                          minWidth: "200px"
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderLeft: "none",
                        borderRadius: "8px",
                        inputProps: {
                          name: "plantWorkshopArea",
                          label: "plantWorkshoArea",
                          placeholder: "1000",
                          value: values?.workshopArea?.value || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange.plantWorkshopAreaValue(e);
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              eventName: WORKSHOP_AREA.VALUE,
                              value: { workshopAreaValue: values?.workshopArea?.value || "" },
                            });
                          },
                        },
                        /** Workshop Area Measurement Unit */
                        rightIcon: (
                          <Select
                            placeholder={"unit"}
                            options={PLANT_DROPDOWN_OPTIONS.AREA}
                            value={(() => {
                              const VAL = values?.workshopArea?.unit || "";
                              return VAL?.length
                                ? { label: VAL, value: VAL }
                                : null;
                            })()}
                            onChange={(item: {
                              id: number;
                              label: string;
                              value: string;
                            }) => {
                              handleOnChange.plantWorkshopAreaUnit(item);
                            }}
                            onBlur={() => {
                              heapTrackTrigger({
                                windowObj: window,
                                eventName: WORKSHOP_AREA.UNIT,
                                value: { workshopAreaUnit: values?.workshopArea?.unit || "" },
                              });
                            }}
                            {...MODAL_STYLES.PLANT_MODAL
                              .MEASUREMENT_AUTOSELECT_INPUT_STYLES}
                          />
                        ),
                      }}
                      hasError={HAS_ERRORS.plantWorkshopArea}
                    />

                    {/* Plant Capacity */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{
                        width: "100%",
                        flex: 1,
                      }}
                      header={"Capacity"}
                      id={"plant-capacity"}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.plantCapacity}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        inputContainerProps: {
                          paddingLeft: "12px",
                          width: "100%",
                          minWidth: "200px"
                        },
                        border: `1px solid ${colors.whiteGray}`,
                        borderLeft: "none",
                        borderRadius: "8px",
                        inputProps: {
                          name: "plantCapacity",
                          label: "plantCapacity",
                          placeholder: "1000",
                          value: values?.capacity?.value || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange.plantCapacityValue(e);
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              eventName: CAPACITY.VALUE,
                              value: { capacityValue: values?.capacity?.value || "" },
                            });
                          },
                        },
                        rightIcon: (
                          <Select
                            placeholder={"unit"}
                            options={PLANT_DROPDOWN_OPTIONS.CAPACITY}
                            value={(() => {
                              const VAL = values?.capacity?.unit || "";
                              return VAL?.length
                                ? { label: VAL, value: VAL }
                                : null;
                            })()}
                            onChange={(item: {
                              id: number;
                              label: string;
                              value: string;
                            }) => {
                              handleOnChange.plantCapacityUnit(item);
                            }}
                            onBlur={() => {
                              heapTrackTrigger({
                                windowObj: window,
                                eventName: CAPACITY.UNIT,
                                value: { capacityUnit: values?.capacity?.unit || "" },
                              });
                            }}
                            {...MODAL_STYLES.PLANT_MODAL
                              .MEASUREMENT_AUTOSELECT_INPUT_STYLES}
                          />
                        ),
                      }}
                      hasError={HAS_ERRORS.plantCapacity}
                    />
                  </FlexRow>

                  {/* Row #3 */}
                  <FlexRow>
                    {/* Plant Other Information input */}
                    <InputWithLabel
                      padding={"4px"}
                      superContainerProps={{ width: "100%" }}
                      header={"Other Information"}
                      id={`plant-other-information`}
                      inputVariant={"input"}
                      errorMessage={ERROR_MESSAGE.plantOtherInformation}
                      fieldDescriptionStyles={{ paddingBottom: "0px" }}
                      inputWithIconProps={{
                        border: `1px solid ${colors.whiteGray}`,
                        borderRadius: "8px",
                        inputProps: {
                          style: MODAL_STYLES.COMMON.TEXT_INPUT_STYLES,
                          name: "plantOtherInformation",
                          label: "plantOtherInformation",
                          placeholder:
                            "Storage area, Expansion details, Testing Facilities etc",
                          value: values?.otherInfo || "",
                          onChange: (
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => {
                            handleOnChange.plantOtherInformation(e);
                          },
                          onBlur: () => {
                            heapTrackTrigger({
                              windowObj: window,
                              eventName: OTHER_INFORMATION,
                              value: { otherInformation: values?.otherInfo || "" },
                            });
                          },
                        },
                      }}
                      hasError={HAS_ERRORS.plantOtherInformation}
                    />
                  </FlexRow>
                </FlexRow>

                {/* Form Actions Wrapper */}
                <Flex
                  paddingLeft={"4px"}
                  width={"100%"}
                  alignItems={"center"}
                  marginTop={"20px"}
                  alignSelf={"center"}
                  justifyContent={"flex-start"}
                >
                  <Button
                    variant="primary"
                    colorSchema="blue"
                    width={"max-content"}
                    {...({ type: "submit" } as StylesProps)}
                    style={{
                      backgroundColor: colors.blue,
                      borderColor: colors.blue,
                    }}
                  >
                    {isFormAddNewOrEditExisting === "EDIT_EXISTING" ? "Update" : "Save"}
                  </Button>
                </Flex>
              </Form>
            );
          }}
        </Formik>
      }
    />
  );
};
