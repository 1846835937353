export const yearOptions = [
  "AY 2018-19",
  "AY 2019-20",
  "AY 2020-21",
  "AY 2021-22",
  "AY 2022-23",
  "AY 2023-24",
  "AY 2024-25",
  "AY 2026-27",
  "AY 2027-28",
  "AY 2028-29",
  "AY 2029-30",
];
