import { VerificationStatus } from "./enums";

export const getVerificationComponentBorderColor = (
  selectedValue: string,
  verificationStatus: VerificationStatus,
  isImportant?: boolean
) => {
  if (selectedValue?.length) {
    switch (verificationStatus) {
      case VerificationStatus.verified:
        return isImportant ? "#47C882 !important" : "#47C882"; //green
      case VerificationStatus.inProgress:
        return isImportant ? "#F4C234 !important" : "#F4C234"; //yellow
      case VerificationStatus.notVerified:
        return isImportant ? "#f45134 !important" : "#F45134"; //red
      default:
        return isImportant ? "#D4E5FE !important" : "#D4E5FE"; //default
    }
  }
  return isImportant ? "#D4E5FE !important" : "#D4E5FE";
};

export const getVerificationStatusImgSrc = (
  verificationStatus: VerificationStatus
) => {
  switch (verificationStatus) {
    case VerificationStatus.verified:
      return "/images/profile_complete.svg";
    case VerificationStatus.inProgress:
      return "/images/warning_yellow.svg";
    case VerificationStatus.notVerified:
      return "/images/warning.svg";
    default:
      return "";
  }
};

export const getObjectsWithoutNameKey = (objects: Array<{ name?: string }>) => {
  return objects?.length ? objects?.filter((item) => item?.name) : [];
};
