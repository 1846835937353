import { COMPLIANCE_VALUES } from "./constant";

export const getFunctionalDetails = (field, verification, data, gstInProgress) => {
  let documentDiv = "",
    verificationStatus = verification.verificationStatus,
    note = verification.note;

  if (["gst"].includes(field)) {
    if (!data?.document?.file?.fileUrl) {
      documentDiv = "upload";
      verificationStatus = "documentPending";
    } else if (data?.document?.file?.fileUrl && ["", "inProgress"].includes(verificationStatus)) {
      verificationStatus = "inProgress";
      note = verification.note || COMPLIANCE_VALUES[field].processNote;
    }
  } else {
    if (["pan"].includes(field)) {
      if (!data?.document?.file?.fileUrl) {
        documentDiv = "upload";
        if (gstInProgress) {
          verificationStatus = verification.verificationStatus || "inProgress";
        } else {
          verificationStatus = verification.verificationStatus || "documentPending";
        }
      }
    } else if (["cin", "esic", "epf"].includes(field) && data?.value) {
      if (!data?.document?.file?.fileUrl) {
        documentDiv = "upload";
        if (data.userUpdated) {
          verificationStatus = verification.verificationStatus || "documentPending";
        } else {
          if (gstInProgress) {
            verificationStatus = verification.verificationStatus || "inProgress";
          }
        }
      }
    } else if (["tan", "urn"].includes(field) && data?.value) {
      if (data.userUpdated) {
        if (!["verified", "notverified"].includes(verification?.verificationStatus)) {
          documentDiv = "verify";
          verificationStatus = verification.verificationStatus || "documentPending";
          note = verification.note || COMPLIANCE_VALUES[field].processNote;
        }
      } else if (gstInProgress) {
        verificationStatus = verification.verificationStatus || "inProgress";
      }
    } else if (["lin"].includes(field) && data?.value) {
      if (data.userUpdated) {
        note = verification.note;
      } else {
        if (gstInProgress) {
          verificationStatus = verification.verificationStatus || "inProgress";
        }
      }
    }
  }
  return { documentDiv, verificationStatus, note };
};
