import React from "react";
import styled from "styled-components";
import Button, { ButtonProps as AntButtonProps } from "../Button";

export interface ButtonProps extends AntButtonProps {
  buttonShape: "rounded" | "square";
  iconClass: string;
}

const ButtonBase = styled(Button)<ButtonProps>`
  border-radius: ${props =>
    `${
      props.buttonShape === "rounded" ? "50%" : props.theme.button.borderRadius
    }`};
  width: ${props =>
    `${props.theme.button.sizes[`${props.size}`].navBtnHeight}`};
`;

const Icon = styled.i<Partial<ButtonProps>>`
  font-size: ${props =>
    `${props.theme.button.sizes[props.size || "regular"].iconSize}`};
  &:before {
    color: ${props => `${props.theme.button.color}`};
  }
`;

const IconButton = (props: ButtonProps) => {
  const { iconClass } = props;
  return (
    <>
      <ButtonBase {...props}>
        <Icon className={iconClass} {...props} />
      </ButtonBase>
    </>
  );
};

export default IconButton;
