export const parametersConstants = [
  {
    bttnText: "Vendor competency",
    keyName: "vendorCompetency",
  },
  {
    bttnText: "Quality of execution",
    keyName: "qualityOfExecution",
  },
  {
    bttnText: "On-time execution",
    keyName: "onTimeExecution",
  },
  {
    bttnText: "Responsiveness",
    keyName: "responsiveness",
  },
  {
    bttnText: "Within budget",
    keyName: "withinBudget",
  },

  {
    bttnText: "Ethics",
    keyName: "ethics",
  },
  {
    bttnText: "Safety",
    keyName: "safety",
  },

  {
    bttnText: "Process & documentation",
    keyName: "processAndDocumentation",
  },
];

export const parameterDummyData = {
  vendorCompetency: {
    rating: 0,
    comment: "",
  },
  qualityOfExecution: {
    rating: 0,
    comment: "",
  },
  onTimeExecution: {
    rating: 0,
    comment: "",
  },
  responsiveness: {
    rating: 0,
    comment: "",
  },
  withinBudget: {
    rating: 0,
    comment: "",
  },
  ethics: {
    rating: 0,
    comment: "",
  },
  safety: {
    rating: 0,
    comment: "",
  },
  processAndDocumentation: {
    rating: 0,
    comment: "",
  },
};

export const overallDummyData = {
  overallRating: 0,
  overallReview: "",
};

