export const GET_JOB_REQ_REQUESTED = "jobRequest/GET_JOB_REQ_REQUESTED";
export const GET_JOB_REQ_SUCCESS = "jobRequest/GET_JOB_REQ_SUCCESS";
export const GET_JOB_REQ_FAILURE = "jobRequest/GET_JOB_REQ_FAILURE";
export const SAVE_JOB_REQ_INTEREST_RESPONSE_REQUESTED = "jobRequest/SAVE_JOB_REQ_INTEREST_RESPONSE_REQUESTED";
export const SAVE_JOB_REQ_INTEREST_RESPONSE_SUCCESS = "jobRequest/SAVE_JOB_REQ_INTEREST_RESPONSE_SUCCESS";
export const SAVE_JOB_REQ_INTEREST_RESPONSE_FAILURE = "jobRequest/SAVE_JOB_REQ_INTEREST_RESPONSE_FAILURE";
export const SUBMIT_QUOTATIONS_REQUESTED = "jobRequest/SUBMIT_QUOTATIONS_REQUESTED";
export const SUBMIT_QUOTATIONS_SUCCESS = "jobRequest/SUBMIT_QUOTATIONS_SUCCESS";
export const SUBMIT_QUOTATIONS_FAILURE = "jobRequest/SUBMIT_QUOTATIONS_FAILURE";
export const LOG_ACTIVITY_REQUEST = "jobRequest/LOG_ACTIVITY_REQUEST";
export const LOG_ACTIVITY_SUCCESS = "jobRequest/LOG_ACTIVITY_SUCCESS";
export const LOG_ACTIVITY_FAILURE = "jobRequest/LOG_ACTIVITY_FAILURE";
export const GET_ACTIVITY_LOG_REQUEST = "jobRequest/GET_ACTIVITY_LOG_REQUEST";
export const GET_ACTIVITY_LOG_SUCCESS = "jobRequest/GET_ACTIVITY_LOG_SUCCESS";
export const GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS = "jobRequest/GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS";
export const GET_ACTIVITY_LOG_FAILURE = "jobRequest/GET_ACTIVITY_LOG_FAILURE";
export const CLEAR_ACTIVITY_LOGS = "jobRequest/CLEAR_ACTIVITY_LOGS";
export const SET_SNAP_SHOT_MODAL = "jobRequest/SET_SNAP_SHOT_MODAL";
export const UPDATE_VENDOR_MONETIZATION_REQUESTED = "jobRequest/UPDATE_VENDOR_MONETIZATION_REQUESTED";
export const UPDATE_VENDOR_MONETIZATION_SUCCESS = "jobRequest/UPDATE_VENDOR_MONETIZATION_SUCCESS";
export const UPDATE_VENDOR_MONETIZATION_FAILURE = "jobRequest/UPDATE_VENDOR_MONETIZATION_FAILURE";
export const SAVE_PRICING_CONSENT_SUCCESS = "jobRequest/SAVE_PRICING_CONSENT_SUCCESS";
export const RESET_CAPTURE_PRICING_CONSENT_STATUS = "jobRequest/RESET_CAPTURE_PRICING_CONSENT_STATUS";
export const NEED_JOB_INFO_EMAIL_RESPONSE_SUCCESS = "jobRequest/NEED_JOB_INFO_EMAIL_RESPONSE_SUCCESS";
export const SAVE_VENDOR_PQR_RESPONSE= "jobRequest/SAVE_VENDOR_PQR_RESPONSE";
export const UPDATE_NO_INTEREST_RESPONSE= "erfx/UPDATE_NO_INTEREST_RESPONSE";
export const UPDATE_QUOTE_REMINDER= "erfx/UPDATE_QUOTE_REMINDER";
export const GET_JOB_FLOW_SUCCESS= "erfx/GET_JOB_FLOW_SUCCESS";
export const UPDATE_APPLY_JOB_STATUS_REQUESTED = "jobRequest/UPDATE_APPLY_JOB_STATUS_REQUESTED";
export const UPDATE_APPLY_JOB_STATUS_SUCCESS = "jobRequest/UPDATE_APPLY_JOB_STATUS_SUCCESS";
export const UPDATE_APPLY_JOB_STATUS_FAILURE = "jobRequest/UPDATE_APPLY_JOB_STATUS_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  jobReqData: null,
  isInterested: "",
  notInterestedReason: "",
  allActivityLogs: null,
  logActivityStatus: null,
  latestFiveActivityLogs: null,
  snapShotModal: null,
  jobFlow: null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_JOB_REQ_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case GET_JOB_REQ_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        jobReqData: action.result,
      };

    case GET_JOB_REQ_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case SAVE_JOB_REQ_INTEREST_RESPONSE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case SAVE_JOB_REQ_INTEREST_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };

    case SAVE_JOB_REQ_INTEREST_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: "",
      };
    case GET_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        allActivityLogs: action.result,
      };

    case GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS:
      return {
        ...state,
        latestFiveActivityLogs: action.result,
      };

    case LOG_ACTIVITY_SUCCESS:
      return {
        ...state,
        logActivityStatus: action.result,
      };

    case CLEAR_ACTIVITY_LOGS:
      return {
        ...state,
        latestFiveActivityLogs: null,
        allActivityLogs: null,
      };

    case SET_SNAP_SHOT_MODAL:
      return {
        ...state,
        snapShotModal: action.payload,
      };

    case UPDATE_VENDOR_MONETIZATION_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_VENDOR_MONETIZATION_SUCCESS:
    case SAVE_PRICING_CONSENT_SUCCESS:
      return {
        ...state,
        loading: false,
        jobReqData: { ...state.jobReqData, jobMonetization: action?.result?.data },
      };
    case UPDATE_VENDOR_MONETIZATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case RESET_CAPTURE_PRICING_CONSENT_STATUS:
      return {
        ...state,
      }
    case GET_JOB_FLOW_SUCCESS:
      return {
        ...state,
        jobFlow: action.result
      }
    
    case UPDATE_APPLY_JOB_STATUS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_APPLY_JOB_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };

    case UPDATE_APPLY_JOB_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: "",
      };

    default:
      return state;
  }
};

export const getJobReqData = (id) => ({
  types: [GET_JOB_REQ_REQUESTED, GET_JOB_REQ_SUCCESS, GET_JOB_REQ_FAILURE],
  promise: (client) => client.get(`get_job_request_data/${id}`, {}),
});

export const getJobReqDataV2 = (id, email, gstn, isLoggedIn, revisited, referer) => ({
  types: [GET_JOB_REQ_REQUESTED, GET_JOB_REQ_SUCCESS, GET_JOB_REQ_FAILURE],
  promise: (client) =>
    client.get(`get_job_request_data_v2/${id}/?email=${encodeURIComponent(email )|| ""}&gstn=${gstn || ""}&isLoggedIn=${isLoggedIn}&revisited=${revisited}&referer=${referer || ""}`, {}),
});

export const saveJobReqInterestResponse = (data) => ({
  types: [
    SAVE_JOB_REQ_INTEREST_RESPONSE_REQUESTED,
    SAVE_JOB_REQ_INTEREST_RESPONSE_SUCCESS,
    SAVE_JOB_REQ_INTEREST_RESPONSE_FAILURE,
  ],
  promise: (client) => client.put("save_job_request_interest_response", { data }),
});

export const submitQuotations = (data) => ({
  types: [SUBMIT_QUOTATIONS_REQUESTED, SUBMIT_QUOTATIONS_SUCCESS, SUBMIT_QUOTATIONS_FAILURE],
  promise: (client) => client.post("submit_quotations", { data }),
});

export const logActivity = (data) => ({
  types: [LOG_ACTIVITY_REQUEST, LOG_ACTIVITY_SUCCESS, LOG_ACTIVITY_FAILURE],
  promise: (client) => client.post("log_activity", { data }),
});

export const getActivityLog = (params) => ({
  types: [GET_ACTIVITY_LOG_REQUEST, GET_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE],
  promise: (client) => client.get(`get_activity_log/${params}`),
});

export const getLatestFiveActivityLog = (params) => ({
  types: [GET_ACTIVITY_LOG_REQUEST, GET_LATEST_FIVE_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE],
  promise: (client) => client.get(`get_latest_five_activity/${params}`),
});

export const resetActivityLog = () => ({
  type: CLEAR_ACTIVITY_LOGS,
});

export const setSnapShotModal = (payload) => ({
  type: SET_SNAP_SHOT_MODAL,
  payload
});

export const updateJobMonetization = data => {
  return {
    types: [
      UPDATE_VENDOR_MONETIZATION_REQUESTED,
      UPDATE_VENDOR_MONETIZATION_SUCCESS,
      UPDATE_VENDOR_MONETIZATION_FAILURE,
    ],
    promise: client => client.patch("update_job_monetization", { data }),
  };
};
export const updateJobMonetizationLastContacted = data => {
  return {
    types: UPDATE_VENDOR_MONETIZATION_SUCCESS,
    promise: client => client.patch("update_job_monetization_last_contacted", { data }),
  };
};
export const savePricingConsent = data => {
  return {
    types: [
      UPDATE_VENDOR_MONETIZATION_REQUESTED,
      SAVE_PRICING_CONSENT_SUCCESS,
      UPDATE_VENDOR_MONETIZATION_FAILURE,
    ],
    promise: client => client.put("save_pricing_consent", { data }),
  };
};
export const needJobInfoEmailResponse = data => {
  return {
    types: NEED_JOB_INFO_EMAIL_RESPONSE_SUCCESS,
    promise: client => client.post("need_job_info_email_response", { data })
  };
};

export const savePQR = data => {
  return {
    types: SAVE_VENDOR_PQR_RESPONSE,
    promise: vendor => vendor.post("save_pqr", { data })

  }
}

export const saveNoInterestResponse = (data) => {
  return {
    types: UPDATE_NO_INTEREST_RESPONSE,
    promise: (client) => client.patch("update_no_interest_response", { data }),
  }
}

export const updateQuoteReminder = (data) => {
  return {
    types: UPDATE_QUOTE_REMINDER,
    promise: (client) => client.patch("update_quote_reminder", {data}),
  }
}

export const getJobFlow = () => {
  return {
    types: [GET_JOB_REQ_REQUESTED,GET_JOB_FLOW_SUCCESS,GET_JOB_REQ_FAILURE],
    promise: (client) => client.get("get_job_flow", {}),
  }
}

export const updateApplyJobStatus = data => {
  return {
    types: [UPDATE_APPLY_JOB_STATUS_REQUESTED, UPDATE_APPLY_JOB_STATUS_SUCCESS, UPDATE_APPLY_JOB_STATUS_FAILURE],
    promise: (client) => client.post("apply_job_vendor", {data}),
  }
}
