import { message, notification } from "antd";
import { ArgsProps } from "antd/lib/notification";

interface ToastProps extends ArgsProps {};

const AntdToast = (props: ToastProps) => {
  const { type, message, description, ...remainingProps } = props;

  notification[type]({
    message: message,
    description,
    ...remainingProps
  });
};

export default AntdToast;