import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import LabelWithoutEdit from "components/Label/LabelWithoutEdit/labelWithoutEdit";
import EditAndDelete from "components/EditAndDelete/EditAndDelete";

interface CardWithoutEditProps {
  cardType:
    | "viewCard"
    | "referalCard"
    | "infoCard"
    | "cardWithDropletHeader"
    | "cardWithoutEdit";
  header: boolean;
  userType?: "vendor" | "client" | "searchViews";
  children?: React.ReactNode;
  toolTipData?: string;
  cardHeader?: string;
  altMesg?: string;
  editable?: boolean;
  data?: any;
  usedFor?: "keyManagement" | "manStrength";
  onHandleDelete?: (index) => void;
}

const CardWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const Card = styled.div<CardWithoutEditProps>`
  cursor: ${props =>
    `${props.theme.card.types[props.cardType].cursor || "default"}`};
  position: ${props => props.header && "relative"};
  width: 624px;
  min-height: 100px;
  height: auto;
  background-color: ${props =>
    `${props.theme.card.types["viewCard"].backgroundColor}`};
  border-radius: ${props =>
    props.header
      ? "0px 30px 30px 30px"
      : `${props.theme.card.types["cardWithoutEdit"].borderRadius}`};
  margin: 20px 20px 20px 0px;
`;

const CartDetails = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
`;
const ImgWrap = styled.div`
  width: 50%;
  height: 16px;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
  padding: 30px;
  cursor: pointer;
`;
const ImageTag = styled.img<Partial<CardWithoutEditProps>>`
  display: ${props =>
    props.theme.card.types["cardWithoutEdit"] ? "block" : "none"};
`;

const NameTag = styled.div`
  max-width: 170px;
  margin-right: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  color: #215ec9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 640px) {
    max-width: 100px;
  }
`;
const DesignationDIv = styled.div`
  max-width: 170px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #011759;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media only screen and (max-width: 640px) {
    max-width: 50px;
  }
`;

const EmailDiv = styled(DesignationDIv)`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  @media only screen and (max-width: 640px) {
    margin-left: 5px;
    max-width: 110px;  
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TextDiv = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #011759;
`;

const Row = styled.div`
  display: flex;
  width: 95%;
  align-items: baseline;
  @media only screen and (max-width: 640px) {
     width: 85%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`;

const Desc = styled(TextDiv)`
  font-weight: 400;
  width: 100%;

  margin-top: 15px;
  word-break: break-all
`;

const DeleteWrapper = styled.div`
  position: absolute;
  right: -35px;
  top: 0;
`;
const CardWithoutEdit = ({
  children,
  data,
  usedFor,
  editable,
  onHandleDelete,
  ...props
}: CardWithoutEditProps) => {
  const { header, cardType, cardHeader, toolTipData } = props;

  if (usedFor == "manStrength") {
    return (
      <Card {...props}>
        {header && (
          <Tooltip title={toolTipData}>
            {/* <CardWaterDropHeader cardType={cardType}>{cardHeader}</CardWaterDropHeader> */}
          </Tooltip>
        )}
        <CartDetails>
          <CardWrap>
            <LabelWithoutEdit
              HeaderText="Total manpower"
              DetailText={data.manStrengthState.totalManPower}
            />

            {!editable && (
              <EditAndDelete
                indexValue={data.item.peopleKeyManagementId}
                onHandleClick={(e, index) => onHandleDelete(index)}
              />
            )}
          </CardWrap>
          <LabelWithoutEdit
            HeaderText="Manpower Full time"
            DetailText={data.manStrengthState.manPowerFullTime}
          />
          <LabelWithoutEdit
            HeaderText="Manpower Part time"
            DetailText={data.manStrengthState.manPowerPartTime}
          />
          <LabelWithoutEdit
            HeaderText="Total manpower in manufacturing"
            DetailText={data.manStrengthState.totalManPowerManufacturing}
          />
          <LabelWithoutEdit
            HeaderText="Total manpower in service"
            DetailText={data.manStrengthState.totalManPowerService}
          />
        </CartDetails>
        {children}
      </Card>
    );
  }
  return (
    <Card {...props}>
      {header && (
        <Tooltip title={toolTipData}>
          {/* <CardWaterDropHeader cardType={cardType}>{cardHeader}</CardWaterDropHeader> */}
        </Tooltip>
      )}
      <CartDetails>
        {!editable && (
          <DeleteWrapper>
            <EditAndDelete
              indexValue={data.item.peopleKeyManagementId}
              onHandleClick={(e, index) => onHandleDelete(index)}
            />
          </DeleteWrapper>
        )}
        <Row>
          <Wrapper>
            <NameTag>
              {data.item.firstName?.length
                ? data.item.firstName
                : "No FullName"}
            </NameTag>
            <DesignationDIv>
              {data.item.designation?.length
                ? data.item.designation
                : "No Designation"}
            </DesignationDIv>
          </Wrapper>

          <EmailDiv>
            {data.item.email?.length ? data.item.email : "No Email"}
          </EmailDiv>
        </Row>
        <Row>
          <Desc>
            {data.item.experience !== "" ? data.item.experience : "No Desc"}
          </Desc>
        </Row>
      </CartDetails>
      {children}
    </Card>
  );
};

export default CardWithoutEdit;
