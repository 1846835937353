import styled from 'styled-components';

export const ModalTitle = styled.div`
  color: #215ec9;
  font-weight: 600;
`;

export const ModalDesc = styled.div`
  color: #011759;
  font-size: 14px;
  margin-top: 8px;
  letter-spacing: 0.01em;
  line-height: 21px;
  opacity: 0.7;
  font-weight: 400;
`;

export const SkipOption = styled.div`
  color: #215ec9;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
`;
export const EmailAddress = styled.span`
  color: #011759;
  font-weight: 600;
  opacity: 1;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
`