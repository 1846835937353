import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
export const COMPLIANCE_REQUESTED = "compliance/COMPLIANCE_REQUESTED";
export const COMPLIANCE_SUCCESS = "compliance/COMPLIANCE_SUCCESS";
export const COMPLIANCE_FAILURE = "compliance/COMPLIANCE_FAILURE";
export const COMPLIANCE_UPDATE_SUCCESS = "compliance/COMPLIANCE_UPDATE_SUCCESS";
export const COMPLIANCE_VERIFY_TAN_URN_REQUESTED = "compliance/COMPLIANCE_VERIFY_TAN_URN_REQUESTED";
export const COMPLIANCE_VERIFY_TAN_URN_SUCCESS = "compliance/COMPLIANCE_VERIFY_TAN_URN_SUCCESS";
export const COMPLIANCE_VERIFY_TAN_URN_FAILURE = "compliance/COMPLIANCE_VERIFY_TAN_URN_FAILURE"
const getGstn = SessionStorageService.get("gstNum") || LocalStorageService.get("gstNum");
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userData: null,
  complianceInfo: undefined,

  complianceUpdatedData: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPLIANCE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case COMPLIANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        complianceInfo: action.result,
      };

    case COMPLIANCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    case COMPLIANCE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        complianceUpdatedData: action.result,
      };
    case COMPLIANCE_VERIFY_TAN_URN_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case COMPLIANCE_VERIFY_TAN_URN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        result: action.result,
      };
    case COMPLIANCE_VERIFY_TAN_URN_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        result: action.error.message,
      };
    default:
      return state;
  }
};
export const getCompliance = (data) => ({
  types: [COMPLIANCE_REQUESTED, COMPLIANCE_SUCCESS, COMPLIANCE_FAILURE],
  promise: (client) => client.get("compliance_get_data"),
});

export const updateCompliance = (gstn, data) => ({
  types: [COMPLIANCE_REQUESTED, COMPLIANCE_SUCCESS, COMPLIANCE_FAILURE],
  promise: (client) => client.put(`update_compliance_data/${gstn}`, { data: { changes: data } }),
});

export const verifyTanUrn = (field, value) => ({
  types: [COMPLIANCE_VERIFY_TAN_URN_REQUESTED, COMPLIANCE_VERIFY_TAN_URN_SUCCESS, COMPLIANCE_VERIFY_TAN_URN_FAILURE],
  promise: (client) => client.get(`verify_urn_tan/${field}/${value}`),
});
