import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  profileLayoutError,
  resetProfileCompletionStatus,
  showEditReducer,
  toggleIsPageEdited,
} from "reducers/profileCreation";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import { getCompliance, updateCompliance } from "reducers/compliance";
import { getGstn } from "constants/auth";
import { ButtonWrapper, ContentInputs, Spinner } from "Profile/Compliance/Compliance.styles";
import { Spin } from "antd";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import ComplianceContent from "./components/ComplianceContent";
import ComplianceCheckBoxes from "./components/ComplinaceCheckBoxes";
import notificationFunc from "components/Notification";
import _ from "lodash";
import { updateProfileFlagToTrue } from "shared/handlers/updateProfiileFlag.handler";
import SessionStorageService from "services/SessionStorageService";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";
import { Box } from "venwiz-ui";

const Compliance = () => {
  const initialComplianceState = {
    gst: { checked: true },
    pan: { checked: true },
    lin: { checked: false },
    cin: { checked: false },
    urn: { checked: false },
    esic: { checked: false },
    epf: { checked: false },
    tan: { checked: false },
  };

  const isOnboarding = window.location.href.includes("onboarding");
  const dispatch = useDispatch();
  const history = useHistory();
  const localStorageUserData = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData);
  const profileCompletionData = useSelector((state: any) => state.profileCreation).profileCompletionStatus;
  const buttonState = useSelector((state: any) => state.profileCreation);
  const complianceState = useSelector((state: any) => state.compliance);
  const { complianceInfo, loaded, error } = complianceState;
  const [complianceData, setComplianceData] = useState({ gst: [] });
  const gstn = getGstn();
  const authState = useSelector((state: any) => state.auth);
  const { isLoading } = authState;
  const [isDataSaving, setIsDataSaving] = useState(false); // handled
  const [checkBoxList, setCheckBoxList] = useState(initialComplianceState);
  const [currentChanges, setCurrentChanges] = useState([]);
  const [newFieldCount, setNewFieldCount] = useState(0);
  const [gstDocUpload, setGstDocUpload] = useState(false);
  const [currentUniqueIndex, setCurrentUniqueIndex] = useState(0);
  const [mandatoryFieldError, setMandatoryFieldError] = useState(false);
  const [saveBttnState, setSaveBttnState] = useState(false);
  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false);
    if (isOnboarding) {
      if (localStorageUserData?.isNewUser) {
        if (localStorageUserData?.ProfileFlag?.isProfileCompleted) {
          history.push("/dashboard");
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isOnboarding && profileCompletionData.hasOwnProperty("profileCompleteness")) {
      if (profileCompletionData?.profileCompleteness) {
        const guestVisitedLinks = SessionStorageService.get(AppConfig.STORAGE_KEYS.guestVisitedLinks);
        if (guestVisitedLinks?.length) {
          history.push(guestVisitedLinks);
        } else {
          history.push("/userinfo");
        }
      } else {
        if (profileCompletionData?.companyinfoCompleteness) {
          if (profileCompletionData?.capabilitiesCompleteness) {
            if (profileCompletionData?.experienceCompleteness) {
              dispatch(getCompliance({}));
            } else {
              history.push("/onboarding/experience");
            }
          } else {
            history.push("/onboarding/capabilities");
          }
        } else {
          history.push("/onboarding/companyInfo");
        }
      }
    } else {
      dispatch(getCompliance({}));
    }
    //showEditReducer(dispatch, (buttonState.editButton = false));
  }, [profileCompletionData]);

  useEffect(() => {
    if (complianceInfo) {
      // Setting check-box status
      setCheckBoxList({
        ...checkBoxList,
        lin: { checked: complianceInfo.lin?.length ? true : false },
        cin: { checked: complianceInfo.cin?.length ? true : false },
        urn: { checked: complianceInfo.urn?.length ? true : false },
        esic: { checked: complianceInfo.esic?.length ? true : false },
        epf: { checked: complianceInfo.epf?.length ? true : false },
        tan: { checked: complianceInfo.tan?.length ? true : false },
      });
      let complianceInfoCopy = _.cloneDeep(complianceInfo);
      Object.keys(complianceInfoCopy).forEach((field) => {
        if (field === "gst" && complianceInfoCopy[field]?.[0]?.document?.file?.fileUrl) {
          setGstDocUpload(true);
        }
        if (complianceInfoCopy[field].length === 0) {
          complianceInfoCopy[field].push({ value: "", userUpdated: true, uniqueIndex: 0 });
        }
      });
      setComplianceData(complianceInfoCopy);
    }
  }, [complianceInfo]);

  const handleCheckBoxList = (e: boolean, value: string) => {
    let checkBoxListCopy = _.cloneDeep(checkBoxList);
    checkBoxListCopy[value].checked = e;
    setCheckBoxList(checkBoxListCopy);
  };

  const handleComplianceValueChange = (field, fieldIndex, value, uniqueIndex) => {
    toggleIsPageEdited(dispatch, true);
    let complianceDataCopy = _.cloneDeep(complianceData);
    if (complianceDataCopy[field].length === 0) {
      complianceDataCopy[field].push({ value });
    } else {
      complianceDataCopy[field][fieldIndex].value = value;
      complianceDataCopy[field][fieldIndex]["userUpdated"] = true;
    }
    let currentChangesCopy = _.cloneDeep(currentChanges);
    let currentIndex = -1;
    currentChangesCopy.forEach((ele, index) => {
      if (ele.key === field) {
        if (
          complianceDataCopy[field][fieldIndex].userUpdated &&
          complianceDataCopy[field][fieldIndex].uniqueIndex === uniqueIndex
        ) {
          currentIndex = index;
        } else if (ele.oldValue === complianceInfo[field]?.[fieldIndex]?.value) {
          currentIndex = index;
        }
      }
    });
    if (currentIndex !== -1) {
      currentChangesCopy[currentIndex].value = value;
    } else {
      const change = {
        key: field,
        oldValue: complianceInfo[field]?.[fieldIndex]?.value || null,
        value: value,
      };
      currentChangesCopy.push(change);
    }
    setComplianceData(complianceDataCopy);
    setCurrentChanges(currentChangesCopy);
  };

  const handleAddNew = (field) => {
    let complianceDataCopy = _.cloneDeep(complianceData);
    complianceDataCopy[field].push({ userUpdated: true, value: "", uniqueIndex: newFieldCount + 1 });
    setNewFieldCount(newFieldCount + 1);
    toggleIsPageEdited(dispatch, true);
    setCurrentUniqueIndex(newFieldCount + 1);
    setComplianceData(complianceDataCopy);
  };

  const handleUploadDocument = (field, value, file, fileName) => {
    toggleIsPageEdited(dispatch, true);
    if (field === "gst") {
      setGstDocUpload(true);
    }
    const document = {
      name: fileName,
      file,
    };
    let complianceDataCopy = _.cloneDeep(complianceData);
    const currentcomplianceDataIndex = complianceDataCopy[field].findIndex((ele) => ele.value === value);
    if (currentcomplianceDataIndex !== -1) {
      complianceDataCopy[field][currentcomplianceDataIndex]["document"] = document;
      let currentChangesCopy = _.cloneDeep(currentChanges);
      const currentEntryIndex = currentChangesCopy.findIndex((ele) => ele.key === field && ele.value === value);
      if (currentEntryIndex !== -1) {
        currentChangesCopy[currentEntryIndex]["document"] = document;
        if (currentChangesCopy[currentEntryIndex]?.deleteDocument) {
          delete currentChangesCopy[currentEntryIndex].deleteDocument;
        }
      } else {
        const change = {
          key: field,
          oldValue: value || null,
          value,
          document,
        };
        currentChangesCopy.push(change);
      }
      setComplianceData(complianceDataCopy);
      setCurrentChanges(currentChangesCopy);
    }
  };

  const handleDeleteDocument = (field, value) => {
    let complianceDataCopy = _.cloneDeep(complianceData);
    const currentcomplianceDataIndex = complianceDataCopy[field].findIndex((ele) => ele.value === value);
    delete complianceDataCopy[field][currentcomplianceDataIndex].document;

    let currentChangesCopy = _.cloneDeep(currentChanges);
    const currentEntryIndex = currentChangesCopy.findIndex((ele) => ele.key === field && ele.value === value);
    if (currentEntryIndex !== -1) {
      currentChangesCopy[currentEntryIndex]["deleteDocument"] = true;
      delete currentChangesCopy[currentEntryIndex].document;
    } else {
      const change = {
        key: field,
        oldValue: value || null,
        value,
        deleteDocument: true,
      };
      currentChangesCopy.push(change);
    }

    setComplianceData(complianceDataCopy);
    setCurrentChanges(currentChangesCopy);
    if (field === "gst") {
      setGstDocUpload(false);
      setCheckBoxList(initialComplianceState);
    }
  };

  const checkForGstnFile = () => {
    const value: any = complianceData?.gst;
    const returnValue = value?.[0]?.document?.file?.fileUrl?.length ? true : false;
    setMandatoryFieldError(!returnValue);
    return returnValue;
  };

  useEffect(() => {
    if (saveBttnState && complianceData?.gst?.length > 0) {
      checkForGstnFile();
    }
  }, [complianceData]);
  const handleSaveClick = (value) => {
    if (value === "save") {
      setIsDataSaving(true);
      if (currentChanges.length > 0) {
        if (checkForGstnFile()) {
          dispatch(updateCompliance(gstn, currentChanges))
            .then(() => {
              setIsDataSaving(false);
              toggleIsPageEdited(dispatch, false);
              isOnboarding
                ? SessionStorageService.get("guestVisitedLinks")?.length
                  ? history.push(SessionStorageService.get("guestVisitedLinks"))
                  : history.push("/dashboard")
                : notificationFunc("success");
              if (isOnboarding) {
                updateProfileFlagToTrue("isProfileCompleted");
                updateProfileFlagToTrue("isComplianceCompleted");
                resetProfileCompletionStatus(dispatch);
              }
              dispatch(getCompliance({}));
              setCurrentChanges([]);
              setNewFieldCount(0);
            })
            .catch(() => {
              setIsDataSaving(false);
            });
        } else {
          setIsDataSaving(false);
        }
      } else {
        setIsDataSaving(false);
        toggleIsPageEdited(dispatch, false);
        isOnboarding
          ? SessionStorageService.get("guestVisitedLinks")?.length
            ? history.push(SessionStorageService.get("guestVisitedLinks"))
            : history.push("/dashboard")
          : notificationFunc("success");
        dispatch(getCompliance({}));
        setCurrentChanges([]);
        setNewFieldCount(0);
      }
    }
  };

  const handleVerify = (field, value, status) => {
    let currentChangesCopy = _.cloneDeep(currentChanges);
    const currentEntryIndex = currentChangesCopy.findIndex((ele) => ele.key === field && ele.value === value);
    if (currentEntryIndex !== -1) {
      currentChangesCopy[currentEntryIndex]["status"] = status;
    }
    setCurrentChanges(currentChangesCopy);
  };

  const gstDocumentVerified = complianceData?.["verification"]?.gst.verificationStatus;
  return (
    <Box width={{
      default: "100%",
      sm: "75vw",
    }}>
      {isLoading && (
        <Spinner>
          <Spin style={{ position: "absolute", top: "50%" }} size="large" tip="Loading..." />
        </Spinner>
      )}

      <ContentInputs>
        <ComplianceCheckBoxes checkBoxList={checkBoxList} handleCheckBoxList={handleCheckBoxList} />

        {complianceData &&
          Object.keys(complianceData).length > 0 &&
          Object.entries(checkBoxList).map((field, index) => {
            let key = field[0],
              value = field[1];
            if (value.checked && complianceData[key] && complianceData[key].length > 0) {
              if (
                (!gstDocUpload || checkForMobileScreen()) &&
                ["cin", "lin", "epf", "tan", "urn", "esic"].includes(key)
              ) {
                return <></>;
              } else {
                return (
                  <ComplianceContent
                    key={index}
                    data={complianceData[key]}
                    verification={complianceData["verification"][key]}
                    field={key}
                    gstDocumentUploaded={gstDocUpload}
                    gstDocumentVerified={gstDocumentVerified}
                    handleComplianceValueChange={handleComplianceValueChange}
                    handleUploadDocument={handleUploadDocument}
                    handleAddNew={handleAddNew}
                    handleDeleteDocument={handleDeleteDocument}
                    handleVerify={handleVerify}
                    currentUniqueIndex={currentUniqueIndex}
                    gstError={mandatoryFieldError}
                  />
                );
              }
            }
          })}
      </ContentInputs>

      <ButtonWrapper $padding={"0px 0px 20px 0px"}>
        <ProfileCreationButtons
          dataAttribute={{ "data-tracking-id": HEAP_DATA_ATTRIBUTES.COMPLIANCE.SAVE_AND_CONTINUE }}
          isloading={isDataSaving}
          buttonText={isOnboarding ? "SAVE & CONTINUE" : "SAVE CHANGES"}
          usedAt={"onboarding"}
          state={!buttonState.isPageEdited}
          pagination={true}
          pageNumber={6}
          handleStateChange={(value) => {
            if (value === "cancel") {
            } else {
              setSaveBttnState(true);
              handleSaveClick(value);
            }
          }}
          style={{
            minWidth: "210px",
            height: "40px",
            borderRadius: "4px",
          }}
        ></ProfileCreationButtons>
      </ButtonWrapper>
    </Box>
  );
};

export default Compliance;
