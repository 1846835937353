import { Modal } from 'antd';
import { ContentInputs } from 'Profile/Capabilities/CapabilitiesNew.styles';
import styled from 'styled-components';

export const Container=styled.div`
display:none;
`
export const ModalDiv=styled(Modal)`
width: 356px;
height: 438px;
background: #FFFFFF;
border: 1px solid #D4E5FE;
box-shadow: 0px 0px 21px rgba(0, 0, 0, 0.15);
border-radius: 8px;

.ant-modal-header {
padding:20px 24px;
border-radius: 8px;
border-bottom:1px solid #D8D8D8;

}
.ant-modal-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1em;
    color: #011759;
    opacity: 0.7;
}

.ant-modal-content {
border-radius: 8px;
}
.ant-modal-body {
display: flex;
max-height: 381px;
overflow-y: scroll;
padding: 20px;
padding-left: 16px;
flex-direction: column;
row-gap: 20px;
margin-right: 10px;
padding-top: 10px;
margin-top: 00px;
::-webkit-scrollbar {
    width: 8px;
    margin-right: 60px;
  }

::-webkit-scrollbar-track {
    background: #F4F6FF;
    margin:48px 0px 137px 0px;
    border-radius:2px;
}

::-webkit-scrollbar-thumb {
    width: 8px;
    height: 104px;
    background: #d5d9ec;
    border-radius: 10px;
}
@media screen and (max-width: 640px) {
    display:block
    & .ant-modal-body {
    margin-top:0px;
    padding-top:10px;
    }
 }
}
`

export const AddInfoModal=styled(ModalDiv)`
@media screen and (max-width: 640px) {
& .ant-modal-wrap {
height:calc(100vh - 123px)!important;
overflow:hidden;
}
${ContentInputs}{
height: calc(100vh - 251px);
padding:12px 12px 60px 6px;

}
width: 100vw;
max-width: 100vw;
border-radius: 0px;
margin: 4px auto;
height: auto;
@supports (-webkit-touch-callout: none) {
height: auto;
}
ant-modal-mask {
background:transparent;

}
.ant-modal-header {
border-radius: 0px;    
}
    
.ant-modal-content {
border-radius: 0px;
}
.ant-modal-body {
overflow: hidden;
padding:0px;
max-height:100%;
margin-right:5px;
max-height:auto;
@supports (-webkit-touch-callout: none) {
    height: auto;
}
}
.ant-modal-title  {
font-weight: 600;
font-size: 12px;
letter-spacing: 0.1em;
color: #011759;
opacity: 0.7;
}
& .AddInfoModal{
height:calc(100% - 60px);
}

}
`