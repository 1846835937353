import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Button } from "components/Button";
import CardTag from "components/Card/CardTag/Card";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import { fileUploadPost } from "reducers/fileUpload";
import { UserInfo } from "ContextApi/ContextApi";
import openNotification from "constants/antdNotification";
interface ReferemcesProps {
  buttonType?: boolean;
  data?: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const WarningMsg = styled.div`
  height: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #215ec9;
  margin: 10px 0px 22px 0px;
`;
const InputFlexWrapper = styled.div`
  padding: 10px 10px 0px 0;
`;

const InputDivWrap = styled.div`
  max-width: 840px;
  position: relative;
`;

const InputWrap = styled.input`
  position: absolute;
  right: 160px;
  opacity: 0;
  z-index: 1;
  width: 160px;
  height: 60px;
`;

const Image = styled.img`
  margin-right: 8.5px;
`;
const CardWrapper = styled.div`
  max-width: 550px;
`;

const DocumentInfo = styled.div`
  max-width: 550px;
`;
const ReferenceContainer = ({ data }: ReferemcesProps) => {
  const [reference, setReference] = useContext(UserInfo);
  const [fileName, setFileName] = useState("");
  const dispatch = useDispatch();
  const [tempDoc, setTempDoc] = useState([]);
  const buttonState = useSelector((state: any) => state.profileCreation);

  const getFile = (e: any) => {
    const file = e.target.files[0];
    const fileName = file?.name;
    let editCurrentData = [...reference];
    if (file?.size < 20971520) {
      setFileName(fileName);
      setTempDoc([{ name: fileName, file: { fileSize: file?.size } }]);
      dispatch(fileUploadPost(file)).then(
        res => {
          editCurrentData[data.index].completionCertificate = {
            name: fileName,
            file: res,
          };
          setTempDoc([]);
          setReference([...editCurrentData]);
          setFileName("");
        },
        error => {
          setTempDoc([]);
        }
      );
    } else {
      openNotification(
        "File size exceeds the 20Mb limit. Please try uploading a smaller file size"
      );
    }
  };

  const handleOnChange = e => {
    let editCurrentData = [...reference];
    editCurrentData[data.index] = {
      ...editCurrentData[data.index],
      [e.target.name]: e.target.value,
    };
    setReference(editCurrentData);
  };

  const handleAddKey = () => {};

  const handleOnDelete = item => {
    let editCurrentData = [...reference];
    editCurrentData[data.index] = {
      ...editCurrentData[data.index],
      ["completionCertificate"]: {
        fileName: "",
        fileSize: "",
        fileUrl: "",
      },
    };
    setReference(editCurrentData);
  };

  return (
    <>
      <Wrapper>
        <InputFlexWrapper>
          <InputBoxForEdit
            maxChar={50}
            sizes="semiMiddle"
            inputTextType="text"
            importanceType="important"
            child="First Name"
            fields="input"
            name="clientCompanyName"
            placeholder="Client Company Name"
            value={reference[data.index].clientCompanyName}
            onHandleChange={e => {
              handleOnChange(e);
            }}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <InputBoxForEdit
            maxChar={50}
            sizes="semiMiddle"
            inputTextType="text"
            importanceType="important"
            child="Last Name"
            fields="input"
            name="contactName"
            value={reference[data.index].contactName}
            placeholder="Contact Person Name"
            onHandleChange={e => {
              handleOnChange(e);
            }}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <InputBoxForEdit
            sizes="semiMiddle"
            inputTextType="text"
            importanceType="important"
            child="Designation"
            fields="input"
            name="mobileNo"
            value={reference[data.index].mobileNo}
            placeholder="Mobile Number (Optional)"
            onHandleChange={e => {
              handleOnChange(e);
            }}
            maxChar={10}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <InputBoxForEdit
            maxChar={256}
            sizes="semiMiddle"
            inputTextType="text"
            importanceType="important"
            child="Qualification"
            fields="input"
            name="clientEmail"
            value={reference[data.index].clientEmail}
            placeholder="Email"
            onHandleChange={e => {
              handleOnChange(e);
            }}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <InputBoxForEdit
            sizes="semiMiddle"
            inputTextType="text"
            importanceType="important"
            child="Industry Experience"
            fields="input"
            name="roleInOrg"
            value={reference[data.index].roleInOrg}
            placeholder="Role in the company"
            onHandleChange={e => {
              handleOnChange(e);
            }}
            maxChar={50}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <InputBoxForEdit
            maxChar={50}
            sizes="semiMiddle"
            inputTextType="text"
            importanceType="important"
            child="Industry Experience"
            fields="input"
            name="plantName"
            value={reference[data.index].plantName}
            placeholder="Plant Name"
            onHandleChange={e => {
              handleOnChange(e);
            }}
          />
        </InputFlexWrapper>
        <InputFlexWrapper>
          <InputDivWrap>
            <InputWrap
              type="file"
              onChange={getFile}
              accept="application/pdf,image/jpeg"
            />
            <InputBoxForEdit
              sizes="large"
              inputTextType="text"
              importanceType="important"
              child="Upload References (Optional)"
              fields="input"
              placeholder={reference[data.index].fileName}
              value={fileName}
              buttonDisable
            />

            <Button
              onClick={handleAddKey}
              variant="blue"
              size="small"
              style={{
                borderRadius: "0px 8px 8px 0px",
                width: "160px",
                marginTop: "0px",
                position: "relative",
                left: "-160px",
                top: "-3px",
                height: "60px",
              }}
            >
              Browse & Upload
            </Button>
          </InputDivWrap>
        </InputFlexWrapper>
      </Wrapper>
      <WarningMsg>
        <Image src="/images/ProfileCreation/info.svg"></Image>
        Supports .pdf and .jpeg formats. Max. file size 20mb.
      </WarningMsg>
      <DocumentInfo>
        Documents received from this client Eg. Reference letter, Completion
        certificate etc.
      </DocumentInfo>
      <CardWrapper>
        {tempDoc.map(item => {
          return (
            <CardTag
              cardText={item.name}
              usedFor="fileUploadTag"
              fileSize={item?.file?.fileSize / 1000000}
              closable={true}
              loading
            />
          );
        })}
        {reference[data.index].completionCertificate?.name && (
          <a
            href={
              buttonState.editButton &&
              reference[data.index]?.completionCertificate?.file?.fileUrl
            }
          >
            <CardTag
              onClose={() => {
                handleOnDelete(
                  reference[data.index].completionCertificate.name
                );
              }}
              cardText={reference[data.index].completionCertificate.name}
              usedFor="fileUploadTag"
              fileSize={
                reference[data.index].completionCertificate?.file?.fileSize /
                1000000
              }
              closable={false}
              cursorType="pointer"
            />
          </a>
        )}
      </CardWrapper>
    </>
  );
};

export default ReferenceContainer;
