import ApiConstant from "constants/ApiConstant";
import SessionStorageService from "services/SessionStorageService";

export const handleCancelRetainSearchKey = () => {
  SessionStorageService.set(ApiConstant.STORAGE_KEYS.searchKeyData, {
    filterValues: [],
    searchWord: "",
    retainKey: false,
  });
};
