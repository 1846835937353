import { colors } from 'Theme';
import React, { useEffect, useRef, useState } from 'react'
import { Box,
  Button,
  Flex,
  PopOver,
  Typography,
  POPOVER_PLACEMENT_OPTION,
  Modal,
  FloatingButton,
} from 'venwiz-ui';
import { StyledIframe, Z_INDEX } from './HelpButtonWithPopOver.styles';
import { HEAP_DATA_TRACKING_ID } from 'constants/heapAnalytics';
import { RxCross2 } from 'react-icons/rx';
import LocalStorageService from 'services/LocalStorageService';
import { AppConfig } from 'ui-constants';

export interface IHelpButtonWithPopOverProps {
  VIDEO_PROPS: { SRC: string; TITLE: string };
  handlePositiveAction: ({ callbackToClosePopOver }: { 
    callbackToClosePopOver: () => void 
  }) => void;
  contentProps: { 
    introductoryTitle: string;
    featureTitle: string; 
    sectionTitle: string 
  };
  heapDataTrackingId: {
    HELP_BUTTON: string,
    CONTINUE_BUTTON: string;
    CLOSE_MODAL_BUTTON: string,
  };
  specialConditionToShowTextPopOver: boolean;
}

export const HelpButtonWithPopOver = (props: IHelpButtonWithPopOverProps) => {
  const {
    VIDEO_PROPS,
    heapDataTrackingId,
    contentProps,
    specialConditionToShowTextPopOver,
  } = props;

  const HEAD_DATA_TRACKING_OBJ = {
    CLOSE_MODAL_BUTTON: { [HEAP_DATA_TRACKING_ID]: heapDataTrackingId.CLOSE_MODAL_BUTTON },
    HELP_BUTTON: { [HEAP_DATA_TRACKING_ID]: heapDataTrackingId.HELP_BUTTON },
    CONTINUE_BUTTON: { [HEAP_DATA_TRACKING_ID]: heapDataTrackingId.CONTINUE_BUTTON },
  };
  const tooltipParentRef = useRef<null | HTMLDivElement>();
  const iframeContainerRef = useRef<null | HTMLDivElement>();
  const textPopOverContainerRef = useRef<null | HTMLDivElement>();
  const [isPopOverContentVisible, setIsPopOverContentVisible] = useState(false);
  const togglePopOverContentVisibility = () => tooltipParentRef?.current?.click();
  /** source is only from Backend */
  const accessedHelp = Boolean(LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)?.accessedHelp)
  const [showBlurredBackground, setShowBlurredBackground] = useState(false);
  const [isImageComponentLoaded, setIsImageComponentLoaded] = useState(false); /** Question mark button */
  const [showPopUpVideoForExperienceRequestForReview, setShowPopUpVideoForExperienceRequestForReview] = useState(false);

  /**
   * Show popOverContent by-default only when:
   * - page is loaded && and data fetching is done
   * - and when exp-tut-video is not viewed
   * - and when imageComponent is loaded */
  useEffect(() => {
    if (specialConditionToShowTextPopOver
      && isImageComponentLoaded
      && accessedHelp === false
    ) {
      if (isPopOverContentVisible === false) togglePopOverContentVisibility();
      setIsPopOverContentVisible(true);
      setShowBlurredBackground(true);
    }

  }, [specialConditionToShowTextPopOver, isImageComponentLoaded, accessedHelp]);

  /** 
   * when component is unmounted:
   * Reset background blur and imageComponentLoaded */
  useEffect(() => {
    return () => {
      setShowBlurredBackground(false);
      setIsImageComponentLoaded(false);
    }
  }, []);

  const handlePopupsAndPopOverContent = () => {
    setShowBlurredBackground(false);
    setShowPopUpVideoForExperienceRequestForReview(true);
  };

  const handleClosePopOver = () => {
    const isParentRendered = window?.getComputedStyle(textPopOverContainerRef?.current)?.display === "flex";
    if (textPopOverContainerRef?.current && isParentRendered) {
      /** trigger handleClosePopOver only when parent is rendered */
      togglePopOverContentVisibility();
      setIsPopOverContentVisible(false);
      setShowBlurredBackground(false);
      setIsImageComponentLoaded(false);
    }
  };

  return (
    <>
      {/* Blur background when pop over is visible */}
      {showBlurredBackground && (
        <Box
          position={"fixed"}
          top={"0px"}
          right={"0px"}
          left={"0px"}
          bottom={"0px"}
          height={"100vh"}
          width={"100vw"}
          backgroundColor={"#00000080"}
          style={{
            backdropFilter: "blur(2px)",
          }}
          zIndex={Z_INDEX.BlurredBackground}
        />
      )}
      {/* Sticky bottom right - Help Button */}
      <Flex
        zIndex={Z_INDEX.PopOverContainer}
        alignItems={"center"}
        justifyContent={"center"}
        position={"fixed"}
        bottom={"40px"}
        right={{ default: "8px", xs: "10px", sm: "20px" }}
        height={"max-content"}
        padding={"16px"}
      >
        <PopOver
          trigger={"click"}
          doNotClosePopOverWhenClickedOutside
          placement={POPOVER_PLACEMENT_OPTION.LT}
          childrenContainerProps={{ ref: tooltipParentRef }}
          popoverContentProps={{ 
            display: accessedHelp ? "none" : "block",
            boxShadow: "0px 8px 36px rgba(0, 0, 0, 0.16)",
            style:{
              position: "relative",
              zIndex: "2",
              background: colors.white,
              borderRadius: "16px",
              padding: "24px",
            },
          }}
          popoverContainerProps={{
            borderRadius: "16px",
            top: "-345px",
            left: "-272px",
            width: "300px",
          }}
          popoverArrowProps={{
            display: accessedHelp ? "none" : "block",
            top: "",
            height: "30px",
            width: "30px",
            right: "12px",
            bottom: "-10px",
          }}
          popoverContent={accessedHelp ? (
            <></>
          ) : (
            <>
              {/* show this component only once */}
              <Flex
                display={isImageComponentLoaded ? "flex" : "none"}
                ref={textPopOverContainerRef}
                color={"#011759"}
                alignItems={"center"}
                flexDirection={"column"}
                justifyContent={"center"}
                position={"relative"}
              >
                <Flex 
                  position={"absolute"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  right={"0px"}
                  top={"0px"}
                  cursor="pointer"
                  onClick={() => handleClosePopOver()}
                >
                  <RxCross2 size={"20"} color={"#818cac"} />
                </Flex>
                <Typography
                  fontSize={"20px"} 
                  fontWeight={700}
                  marginBottom={"10px"}
                >
                  Your Guide to the Platform!
                </Typography>
                <Typography
                  fontWeight={500}
                  fontSize={{ default: "14px", xs: "16px" }}
                  marginBottom={"16px"}
                >
                  Click the floating question mark to discover materials like help videos and more.
                </Typography>
                <Button
                  width={"100%"}
                  colorSchema={"blue"}
                  variant={"primary"}
                  color={"#fff"}
                  style={{ borderRadius: "10px", background: colors.blue }}
                  onClick={() => {
                    props?.handlePositiveAction({
                      callbackToClosePopOver: () => {
                        handlePopupsAndPopOverContent();
                      }
                    });
                  }}
                  {...HEAD_DATA_TRACKING_OBJ.CONTINUE_BUTTON}
                >
                  Continue
                </Button>
              </Flex>
            </>
          )}
        >
          <FloatingButton 
            containerProps={{
              bottom: "64px",
              right: "0px",
              paddingRight: "10px",
            }}
            children={(
              <Flex
                padding={"6px"}
                borderRadius={"50%"}
                alignItems={"center"}
                justifyContent={"center"}
                backgroundColor={"white"}
                cursor={"pointer"}
                onClick= {() => {
                  props?.handlePositiveAction({
                    callbackToClosePopOver: () => {
                      handlePopupsAndPopOverContent();
                    }
                  });
                }}
                {...HEAD_DATA_TRACKING_OBJ.HELP_BUTTON}
              >
                <Flex
                  width={"28px"}
                  height={"28px"}
                  cursor={"pointer"}
                  borderRadius={"50%"}
                  alignItems={"center"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  backgroundColor={colors.blue}
                >
                  <img
                    alt="help-button"
                    style={{ cursor: "pointer" }}
                    onLoad={() => setIsImageComponentLoaded(true)}
                    src="/images/help-question-mark-white-icon.svg" 
                  />
                </Flex>
              </Flex>
            )}
          />
        </PopOver>
      </Flex>

      {/* Video Player Popup */}
      <>
        <Modal
          show={showPopUpVideoForExperienceRequestForReview}
          gap={"12px"}
          padding={{ default: "16px" }}
          width={{ default: "100vw", sm: "70vw" }}
          handleClose={() => {}}
          closeIcon={(
            <Flex
              cursor={"pointer"}
              alignContent={"center"}
              justifyContent={"center"}
              onClick={() => {
                setShowPopUpVideoForExperienceRequestForReview(false);
              }} 
              {...HEAD_DATA_TRACKING_OBJ.CLOSE_MODAL_BUTTON}
            >
              <RxCross2 size={"20"} color={"#818cac"} />
            </Flex>
          )}
          modalHeader={
            <Flex
              width={{ default: "80vw", md: "100%"}}
              paddingBottom={"4px"}
              flexWrap={"wrap"}
            >
              <Flex
                width={"max-content"}
                alignItems={"center"}
                gap={"10px"}
                flexWrap={"wrap"}
                paddingBottom={"0px"}
              >
                <Typography
                  fontWeight={600}
                  fontSize={"18px"}
                  color={colors.darkBlue}
                >
                  {contentProps.introductoryTitle}
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={"18px"}
                  color={colors.darkBlue}
                >
                  {contentProps.featureTitle}
                </Typography>
                <Box
                  borderRadius={"16px"}
                  padding={"8px 16px"}
                  backgroundColor={"#DEC43C"}
                >
                  <Typography
                    color={colors.white}
                    fontWeight={600}
                    fontSize={"10px"}
                  >
                    {contentProps.sectionTitle}
                  </Typography>
                </Box>
              </Flex>
            </Flex>
          }
          modalBody={(
            <>
              <Flex
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                ref={iframeContainerRef}
                width={"100%"}
                height={{ default: "40vh", sm: "500px" }}
              >
                <StyledIframe
                  allowFullScreen
                  src={VIDEO_PROPS.SRC}
                  title={VIDEO_PROPS.TITLE}
                  width={iframeContainerRef?.current?.offsetWidth
                    ? `${iframeContainerRef?.current?.offsetWidth - 32}px`
                    : "100%"
                  }
                  height={iframeContainerRef?.current?.offsetHeight
                    ? `${iframeContainerRef?.current?.offsetHeight}px`
                    : "100%"
                  }
                />
              </Flex>
            </>
          )}
        />
      </>
    </>
  )
}
