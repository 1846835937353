import React from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import moment, { Moment } from "moment";
import { HEAP_DATA_TRACKING_ID } from "constants/heapAnalytics";
type usedFor =
  | "login"
  | "profileCreationHighlitedlabel"
  | "registration"
  | "profileCreation"
  | "profileOnboarding"
  | "companyInfo"
  | undefined;

interface DateWithLabelProps {
  children?: React.ReactNode;
  usedFor: usedFor;
  width?: string;
  placeholder?: string;
  disabled?: boolean;
  showToday?: boolean;
  onChange: (date: any, dateString: any) => void;
  format?: string;
  defaultValue?: any;
  inputFieldError?: boolean;
  picker?: "month";
  disableDateBefore?: string;
  value?: Moment;
  inputDataTrackingId?: string;
}
interface DatePickerStyling {
  placeHolderColor?: any;
  inputFieldError?: boolean;
}

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: 180px;
  color: ${props => props.theme.colors.darkBlue};
  margin-right: 10px;
  position: relative;
  @media screen and (max-width: 640px) {
  width: 190px;
  margin-right: 0px;
  }
`;

const HeaderName = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
`;
const DatePickerAntd = styled(DatePicker)<DatePickerStyling>`
  & .ant-picker-input > input {
    font-weight: 500;
    color: ${props => props.theme.colors.darkBlue};
    padding-left: 10px;
  }
 .ant-picker{
    border-color: ${props => props.inputFieldError && "#BE000B"};
  }
  &.ant-picker-large{
    width: 550px;
    height: 60px;
    border-radius: 8px;
    color:#011759;
    border: 1px solid #D5D9EC;
  }
  &.error {
    border:1px solid #BE000B;
    background: rgba(190, 0, 11, 0.05);
  }
`;
export const VerficationStatus = styled.img`
cursor: pointer;
`;
const DatePickerWithLabel = ({ children, ...props }: DateWithLabelProps) => {
  function disabledDate(current) {
    // disable dates for completion date based on start date
    if(props?.disableDateBefore) 
      return current && (current < moment(props.disableDateBefore) || current > moment().endOf("day"))
   
      // Can not select days before today and today
    return current && current > moment().endOf("day");
  }
  return (
    <BoxContainer
      style={{ height: `${props.usedFor}` === "login" ? "auto" : "auto" }}
    >
      {children && 
      <HeaderName
        style={{
          height: `${props.usedFor}` === "login" ? "21px" : "18px",
          marginBottom: `${props.usedFor}` === "login" ? "15px" : "10px",
          opacity: `${props.usedFor === "profileCreation" ? "0.7" : "1"}`,
        }}
      >
        {children}
      </HeaderName>
}
      <DatePickerAntd
        {...{ [HEAP_DATA_TRACKING_ID]: props?.inputDataTrackingId || "" }}
        placeholder={props.placeholder}
        disabledDate={disabledDate}
        disabled={props.disabled}
        size="middle"
        className={props.inputFieldError ? "error" : ""}
        format={props.format}
        onChange={(date, dateString) => props.onChange(date, dateString)}
        defaultValue={props.defaultValue}
        inputFieldError={props.inputFieldError}
        style={{ height: "40px", borderRadius: "8px", paddingRight: "20px" }}
        suffixIcon={<img src="/images/calender.svg"></img>}
        picker={props.picker}
        value={props.value}
      />
    </BoxContainer>
  );
};

export default DatePickerWithLabel;