import { BoxContainer } from "components/DatePicker/DatePickerWithLabel/DatePickerWithLabel";
import { DropdownAntd } from "components/Dropdown/BasicDropdown/BasicDropdown";
import styled from "styled-components";

export const ContentInputs = styled.div`
height: calc(100vh - 291px);
width: 75vw;
overflow-y: scroll;
::-webkit-scrollbar-thumb {
}
padding-bottom: 30px;
margin-top: 20px;
display: flex;
justify-content: space-between;
position: relative;
flex-wrap: wrap;

@media only screen and (max-width: 640px) {
display: flex;
justify-content: flex-start;
width: 100%;
padding-left: 15px;
height:auto;
padding-top:12px;
overflow:auto;

#brochure{
margin-top:20px;
}
}
`;

export const FormWrapper = styled.div`
@media only screen and (max-width: 640px) {
height: 100%;
padding: 0;
overflow: scroll;
margin: 0;
padding-bottom: 60px;
@supports (-webkit-touch-callout: none) {
 padding-bottom: 120px;
}
}
`;

export const FormWrapperSection = styled.div`
display: grid;
grid-template-columns: 580px auto;
width: 790px;
#companyName,#website{
    margin-right:30px;
}
#address {
    width: 560px;
    margin-right:10px;
}
@media only screen and (max-width: 640px) {
#companyName,#website{
    margin-right:0px;
}
display: flex;
width: auto;
#address {
/* wrt to 430px screen vw is being assigned*/
width: 76.21vw;
margin-right:10px;
}
}
@media only screen and (max-width: 400px) {
    #address {
    margin-right:0px;
    }
}
`;

export const CompNameWebsiteWrapper=styled.div`
display:flex;
flex-direction:column;

@media only screen and (max-width: 640px) {
width:100%;
max-width: calc(100vw - 176.4px);
margin-bottom: 20px;
margin-right: 13px;
}
`
export const ResScreenWebsiteWrapper=styled.div`
margin-top:20px;
display:none;
@media only screen and (max-width: 640px) {
display:block;
}
`
export const WebsiteWrapper=styled.div`
display:block;
@media only screen and (max-width: 640px) {
display:none;
}
`
export const FormWrapperDiv = styled.div<any>`
    display: flex;
    flex-wrap: wrap;
    height: fit-content;
    row-gap: 26px;
    position: relative;
    @media only screen and (max-width: 640px) {
    #turnOverSlab{
    margin-right:13px;  
    }
    #yoc{
     order:1;
    }
    max-width:${props=>props?.$maxWidth};
    }
    @media only screen and (max-width: 400px) {
    max-width:${props=>props?.$respScreenMaxWidth};
    }
`;

export const InputDiv = styled.div`
    margin-bottom: 20px;
    @media only screen and (max-width: 640px) {
    margin-right: 20px;
    margin-bottom: 0px;
    margin-right: 0px; 
    ${BoxContainer},${DropdownAntd}{
    width:calc(44.145vw);
    max-width:190px;
    }
    @media only screen and (max-width: 400px) {
        ${BoxContainer},${DropdownAntd}{
        width:calc(43.145vw);
        max-width:190px;
        }
    }
`;

export const InputDivWrap = styled.div`
width: 550px;
position: relative;
`;

export const InputWrap = styled.input`
width: 200px;
position: absolute;
left:0;
opacity: 0;
z-index: 1;
height: 40px;
cursor: pointer;
@media screen and (max-width: 640px) {
    width:40px;
}
`;

export const WarningMsg = styled.div`
display: flex;
margin-top: 5px;
`;

export const Div = styled.div`
width: 20px;
height: 20px;
margin-right: 10px;
display: flex;
align-items: center;
justify-content: center;
`;

export const TagContainer = styled.div`
display: flex;
flex-wrap: wrap;
margin-top: 12px;
row-gap: 10px;
max-width: 550px;
column-gap: 10px;
margin-bottom: 10px;
margin-top: 0;
@media only screen and (max-width: 640px) {
margin-top: 10px;
row-gap: 3px;
}
`;

export const CardTagContainer = styled.div`
width: 204px;
`;

export const ProfileImageWrapper = styled.div`
width: 178px;
height: 178px;
margin-right: 22px;
position: absolute;
right: 0;
@media screen and (max-width: 640px) {
display:none;
}
`;
 
export const ResScreenImageWrapper = styled.div`
display:none;
@media screen and (max-width: 640px) {
width: auto;
height: auto;
display:block;
}
`;
export const Spinner = styled.div`
position: absolute;
top: 50%;
left: 50%;
text-align: center;
font-size: 10px;
backdrop-filter: blur(0.5px);
z-index: 1500;
overflow: show;
margin: auto;
top: 0;
left: 0;
bottom: 0;
right: 0;
`;
export const ErrorMessageDiv = styled.div`
display: flex;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 18px;
color: #be000b;
margin-top: 4px;
`;

export const VerficationStatus = styled.img`
position: absolute;
width: 20px;
height: 20px;
top: 52px;
right: 28px;
cursor: pointer;
`;

export const AddressStatusTooltipDiv = styled.div`
.ant-tooltip{
left: 150px !important;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
right: 15px;
}
.ant-tooltip-arrow {
width: 25px;
height: 20px;
}
.ant-tooltip-arrow-content {
width: 17px;
height: 17px;
bottom: 7px;
}
@media screen and (max-width: 640px) {
    .ant-tooltip{
        left: 0px !important;
    }
}
`;

export const UploadIcon = styled.img`
height: 14px;
width: 14px;
margin-right: 10px;
@media screen and (max-width: 640px) {
margin-right: 0px;
}
`;

export const BrocuherTextDiv = styled.div<any>`
display: block ;
font-family: Poppins;
font-size: 14px;
line-height: 21px;
text-align: justify;
letter-spacing: 0.01em;
color: rgba(1, 23, 89, 0.6);
width: 550px;
.desktopText{
    display:block;
} 
.mobileText{
    display:none;
}
@media screen and (max-width: 640px) {
width:79vw;
max-width:338px;
font-size: 12px;
line-height: 18px;
text-align: justify;
letter-spacing: 0.01em;
    .mobileText{
        display:block;
    }
    .desktopText {
        display:none;
    }
@media screen and (max-width: 400px) {
width:76vw;
}
`;

export const Bttn=styled.button<any>`
position:relative;
width: 200px;
height: 40px;
background: #F4F6FF;
color:  ${props=>props?.$color};
margin-left: 10px;
margin-top: ${props=>props?.$marginTop};
font-weight: 600;
font-size: 14px;
background: ${props=>props?.$backgroundColor};
border: 1px solid #D4E5FE;
border-radius: 8px;
@media screen and (max-width: 640px) {
width:40px;
font-size: 0px;
background: ${props=>props?.$onError && "rgba(190, 0, 11, 0.05)"} ;
border:  ${props=>props?.$onError && "1px solid #BE000B"};
}
`