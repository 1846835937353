import { Tabs } from "antd";
// import { ClientCommunicationActions } from "ChatModule/actions";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSharedFiles } from "reducers/chat";
import styled from "styled-components";
interface Props {
  display: string;
}

interface Styles {
  $display: string;
  $width: string;
}

const SharedFilesSection = styled.div<Styles>`
  width: ${(props) => props?.$width};

  @media only screen and (max-width: 640px) {
    display: ${(props) => props?.$display};
    width: 88%;
  }
`;

const Header = styled.span`
  color: #215ec9;
  font-weight: 600;
  font-size: 20px;
  @media only screen and (max-width: 640px) {
    font-size: 16px;
    color: #011759;
  }
`;

const FileWrapper = styled.div`
  height: calc(100vh - 280px);
  overflow-y: scroll;
  @media only screen and (max-width: 640px) {
    height: calc(100vh - 175px);
    padding-bottom: 70px;
  }
`;

const FileContainer = styled.div`
  border: 1px solid #d4e5fe;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  @media only screen and (max-width: 640px) {
    width: 95%;
  }
`;

const FileType = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  font-size: 11px;
  justify-content: center;
  margin-right: 15px;
  font-weight: 700;
`;

const FileDetails = styled.div`
  max-width: 70%;
`;
const FileName = styled.div`
  color: #011759;
  font-weight: 600;
  overflow-wrap: anywhere;
`;
const FileSharedDate = styled.div`
  color: #011759;
  font-size: 11px;
`;

const SharedFiles = (props: Props) => {
  const { TabPane } = Tabs;
  const dispatch = useDispatch();
  const chatInfo = useSelector((state: any) => state.chatInfo);
  const { data, sharedFiles } = chatInfo;

  useEffect(() => {
    if (data !== null && data?.chat_id) {
      const queryParam = {
        sender: "vendor",
        chatId: data?.chat_id,
      };
      dispatch(getSharedFiles(queryParam));
    }
  }, [data]);

  const sharedFile = (file: any, date: any, id: number) => {
    const getFileType = (fileType: any) => {
      switch (file?.type) {
        case "image/jpg":
        case "image/jpeg":
          return <FileType style={{ background: "#FFB82A" }}>.jpg</FileType>;
        case "image/png":
          return <FileType style={{ background: "#FFB82A" }}>.png</FileType>;
        case "application/pdf":
          return <FileType style={{ background: "#C80A0A" }}>.pdf</FileType>;
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "doc":
        case "docx":
          return (
            <FileType key={id} style={{ background: "#2B7CD3" }}>
              .doc
            </FileType>
          );
        // case 'csv':
        //   return (<FileType style={{background:"#54B9A0"}}>.csv</FileType>)
        // case 'application/vnd.ms-excel':
        // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        //   return (<FileType style={{background:"#54B9A0"}}>.xls</FileType>)
      }
    };

    return (
      <FileContainer
        key={id}
        onClick={() => {
          window.open(file?.file?.fileUrl, "_blank");
        }}
      >
        {getFileType(file?.type)}
        <FileDetails>
          <FileName>{file?.name}</FileName>
          <FileSharedDate>{moment(date).format(" DD-MM-YYYY")}</FileSharedDate>
        </FileDetails>
      </FileContainer>
    );
  };
  const hasSharedFiles = sharedFiles?.received?.length || sharedFiles?.sent?.length;
  return (
    <SharedFilesSection
      $width={hasSharedFiles ? "40%" : "0"}
      $display={props?.display}
    >
      {!!(sharedFiles?.received?.length || sharedFiles?.sent?.length) && (
        <>
          <Header>Shared Files</Header>
          <Tabs defaultActiveKey="1" style={{ height: "calc(100%-20px)" }}>
            <TabPane tab={`Received ( ${sharedFiles?.received?.length} )`} key="1">
              <FileWrapper>
                {sharedFiles?.received &&
                  sharedFiles?.received.map((message: any) => {
                    return sharedFile(message.sharedFiles[0], message.date, message.message_id);
                  })}
              </FileWrapper>
              </TabPane>
            <TabPane tab={`Sent ( ${sharedFiles?.sent?.length} )`} key="2">
              <FileWrapper>
                {sharedFiles?.sent &&
                  sharedFiles?.sent.map((message: any) => {
                    return sharedFile(message.sharedFiles[0], message.date, message.message_id);
                  })}
              </FileWrapper>
            </TabPane>
          </Tabs>
        </>
      )}
    </SharedFilesSection>
  );
};

export default SharedFiles;
