/* eslint-disable */
import React, { useState, Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Main from "Main";
import PageLoader from "components/PageLoader";
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { AppConfig } from "ui-constants";
const { STORAGE_KEYS } = AppConfig;
import Header from "components/Layout/BasicLayOut/Header";
import ResetPassword from "Authentication/Login/ResetPassword/ResetPassword";
import VerifyPassword from "Authentication/Login/VerifyPassword/VerifyPassword";
import VerifyEmail from "Authentication/Login/VerifyEmail/VerifyEmail";
import { OpenGotoVendorsPage } from "OpenGotoVendorsPage/OpenGotoVendorsPage";
import { OpenVendorsEscrowInfoPage } from "OpenVendorsEscrowInfoPage/OpenVendorsEscrowInfoPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userData = LocalStorageService.get(STORAGE_KEYS.isRememberEnabled)
    ? LocalStorageService.get(STORAGE_KEYS.userData)
    : SessionStorageService.get(STORAGE_KEYS.userData);
  return (
    <>
      <Header />
      <Route
        {...rest}
        render={(props) =>
          userData && userData !== null ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathName: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    </>
  );
};

const RouteWithNavbar = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Header />
          <Component {...props} />
        </>
      )}
    />
  );
};

const RedirectToLoginOrRegistration = () => {
  let location = useLocation();
  const prevLocationState = location?.state?.from || null;

  if (prevLocationState) {
    const prevSearchParams = location?.state?.from?.search
    const prevPathname = location?.state?.from?.pathname
    const isExistingUser = prevSearchParams === AppConfig.EXISTING_USER_SEARCH_PARAMS;
    if (isExistingUser) {
      SessionStorageService.set(STORAGE_KEYS.prevGuestRouteOfExistingUser, prevPathname);
      return <Redirect to="/login" />;
    }
  }

  return <Redirect to="/registration" />;
};

//Request
const PlantVisit = React.lazy(() => import("Request/PlantVisit/PlantVisit"));
const SelectVendor = React.lazy(() =>
  import("Request/PlantVisit/SelectVendor")
);
const CreateRequest = React.lazy(() => import("Request/RequestList"));
//Dashboard
const Dashboard = React.lazy(() => import("Dashboard"));
//ChatModule
const ChatModule = React.lazy(() => import("ChatModule"));
//Auhentication
const Login = React.lazy(() => import("Authentication/Login"));
const Registration = React.lazy(() => import("Authentication/Register"));
const ForgotPassword = React.lazy(() =>
  import("Authentication/ForgotPassword/ForgotPassword")
);
// old-onboarding
// const CompanyInfoOnboarding = React.lazy(() => import("Profile/CompanyInformation"));
// const ExperienceOnboarding = React.lazy(() => import("Profile/Experience"));
// const CapabilitiesOnboarding = React.lazy(() => import("Profile/Capabilities"));
// const ComplianceOnboarding = React.lazy(() => import("Profile/Compliance"));
// const CertificatesOnboarding = React.lazy(() => import("Profile/Certifications"));

// new-onboarding
const NewOnboarding = React.lazy(() => import("NewOnboarding"));
const NewOnboardingWithoutUserLogin = React.lazy(() =>
  import("NewOnboarding/NewOnboardingWithoutUserLogin")
);
//vendor-request
const VendorRequest = React.lazy(() => import("Request/JobRequest/JobRequest"));
//Profile
const UserInfo = React.lazy(() => import("Profile/UserInfo"));
const ViewProfile = React.lazy(() => import("Profile/ViewProfile/ViewProfile"));
const Support = React.lazy(() => import("Support"));
const Invite = React.lazy(() => import("Invite"));
const CompanyInfo = React.lazy(() => import("Profile/CompanyInformation"));
const Capabilities = React.lazy(() => import("Profile/Capabilities"));
const Compliance = React.lazy(() => import("Profile/Compliance"));
const People = React.lazy(() => import("Profile/People"));
const Experience = React.lazy(() => import("Profile/Experience"));
const OpenExperience = React.lazy(() => import("Profile/Experience/OpenExperience"));
const OpenSubmitQuotes = React.lazy(() => import("Request/JobRequest/QuotationFirstOpenPages/Pages/Quotation.page"));
const NotInterestedPage = React.lazy(() => import("Request/JobRequest/QuotationFirstOpenPages/Pages/NotInterested.page"));
const QuoteReminder = React.lazy(() => import("Request/JobRequest/QuotationFirstOpenPages/Pages/QuoteReminder.page"));
const Finance = React.lazy(() => import("Profile/Finance"));
const PricingPolicy = React.lazy(() => import("Request/JobRequest/QuotationFirstOpenPages/Pages/PricingPolicy.page"));
// const Certifications = React.lazy(() => import("Profile/Certifications"));
const References = React.lazy(() => import("Profile/References"));

const ChangePassword = React.lazy(() => import("Settings/ChangePassword"));
const MarketingSuite = React.lazy(() => import("MarketingSuite"));

const PricingPolicyPdf = React.lazy(() => import("PricingPolicyPdf"));

const ProfileCreation = React.lazy(() => import("Profile"));
const ActivityLogs = React.lazy(() =>
  import("Request/JobRequest/ActivityLogs/AllActivityLogs")
);
const Routes = (
  <Router>
    <Suspense fallback={<PageLoader />}>
      <Main>
        <Switch>
          <RouteWithNavbar
            exact
            path="/job-request/:requestId"
            component={VendorRequest}
          />
          {/* Open Jobs */}
          <RouteWithNavbar
            exact
            path="/open-jobs"
            component={CreateRequest}
          />
           <RouteWithNavbar
            exact
            path="/job/:requestId/job-scope"
            component={VendorRequest}
          />
           <RouteWithNavbar
            exact
            path="/job/:requestId/quotations"
            component={VendorRequest}
          />
           <RouteWithNavbar
            exact
            path="/job/:requestId/activity-logs"
            component={VendorRequest}
          />
          <RouteWithNavbar
            exact
            path="/pricing-policy"
            component={PricingPolicyPdf}
          />
          <Route exact path="/" component={Registration}>
            <RedirectToLoginOrRegistration />
          </Route>
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/:inviteId/login" component={Login} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <Route
            exact
            path="/new-onboarding-link"
            component={NewOnboardingWithoutUserLogin}
          />
          <Route
            exact
            path="/goto-vendors"
            component={OpenGotoVendorsPage}
          />
          <Route
            exact
            path="/vendor-agreement"
            component={OpenVendorsEscrowInfoPage}
          />
          <Route exact path="/forgotpassword/:id" component={VerifyPassword} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <PrivateRoute
            exact
            path="/marketingsuite"
            component={MarketingSuite}
          />
          {/* <PrivateRoute exact path="/onboarding/capabilities" component={CapabilitiesOnboarding} /> */}
          {/* <PrivateRoute exact path="/onboarding/experience" component={ExperienceOnboarding} /> */}
          {/* <PrivateRoute exact path="/onboarding/companyInfo" component={CompanyInfoOnboarding} /> */}
          {/* <PrivateRoute exact path="/onboarding/certifications" component={CertificatesOnboarding} /> */}
          {/* <PrivateRoute exact path="/onboarding/compliance" component={ComplianceOnboarding} /> */}
          <PrivateRoute
            exact
            path="/onboarding/:id"
            component={NewOnboarding}
          />
          <Route
            exact
            path="/email-verification-link/:id"
            component={VerifyEmail}
          />
          <PrivateRoute exact path="/onboarding" component={NewOnboarding} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/support" component={Support} />
          <PrivateRoute exact path="/invite" component={Invite} />
          <PrivateRoute exact path="/communication" component={ChatModule} />
          <PrivateRoute exact path="/userinfo" component={UserInfo} />
          <PrivateRoute exact path="/my-profile" component={ViewProfile} />
          <PrivateRoute exact path="/companyinfo" component={CompanyInfo} />
          <PrivateRoute exact path="/capabilities" component={Capabilities} />
          <PrivateRoute exact path="/people" component={People} />
          <PrivateRoute exact path="/experience" component={Experience} />
          <PrivateRoute exact path="/compliance" component={Compliance} />
          <PrivateRoute exact path="/finance" component={Finance} />
          {/* <PrivateRoute exact path="/certifications" component={Certifications} /> */}
          {/* <PrivateRoute exact path="/references" component={References} /> */}
          {/* <Route exact path="/onboarding" component={ProfileCreation} /> */}
          <Route exact path="/changepassword" component={ChangePassword} />
          <PrivateRoute exact path="/registration" component={Registration} />
          <PrivateRoute exact path="/support" component={Support} />
          <PrivateRoute
            exact
            path="/request/plantvisit/:requestId"
            component={PlantVisit}
          />
          <PrivateRoute exact path="/request" component={SelectVendor} />
          {/* Jobs */}
          <PrivateRoute exact path="/interested-jobs" component={CreateRequest} />
          <PrivateRoute exact path="/plant-visits" component={CreateRequest} />
          <PrivateRoute
            exact
            path="/activity-logs/:requestId"
            component={ActivityLogs}
          />
          <Route exact path="/open-experience" component={OpenExperience} />
          <RouteWithNavbar exact path="/:requestId/submit-quotes" component={OpenSubmitQuotes} />
          <RouteWithNavbar exact path="/:requestId/vendor-not-interested" component={NotInterestedPage} />
          <RouteWithNavbar exact path="/:requestId/quote-reminder" component={QuoteReminder} />
          <RouteWithNavbar exact path="/:requestId/pricing&policy-acceptance" component={PricingPolicy} />   
        </Switch>
      </Main>
    </Suspense>
  </Router>
);

export default Routes;
