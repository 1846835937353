import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import 'antd/dist/antd.css';
import { Progress } from 'antd';
interface MeterProps {
  percentageValue: number;
}
const BarDiv = styled.div`
width:355px;
`;
const ProgressAnt = styled(Progress)`
.ant-progress-outer {
    width:75%;
}
.ant-progress-text{
    margin-left:10px;
    color:${(props) => `${props.theme.colors.darkBlue}`};
}
`;

function Meter({ percentageValue }: MeterProps) {
  const [percentValue, setPercentValue] = useState(percentageValue);
  const [color, setColor] = useState('');
  
  useEffect(() => {
    setPercentValue(percentageValue);

  }, [percentageValue]);

  const handlePercent = () => {
    if (percentageValue <= 50) {
      setColor('red');
      return 'Weak Password';
    }
    if (percentageValue < 85 && percentageValue > 50) {
      setColor('orange');
      return 'Good Password';
    }
    setColor('#47C882');
    return 'Strong Password';
  };
  return (
    <BarDiv>
      <ProgressAnt
        strokeColor={color}
        strokeWidth={4}
        percent={percentValue}
        status="active"
        format={() => handlePercent()}
      />

    </BarDiv>
  );
}

export default Meter;
