import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { AuthContainer, Spinner } from 'Authentication';
import { Spin } from "antd";
import { Container } from 'Authentication/AuthenticationModal/AuthenticationModel.styles';
import { verifyGotoVendorToken } from 'reducers/goto-vendors';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import notificationFunc from 'components/Notification';


export const OpenGotoVendorsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const TOKEN = urlParams?.get("token")?.trim() || "";
  const { loading, loaded } = useSelector((state: RootState) => state.gotoVendors);

  useEffect(() => {
    if (TOKEN) {
      (dispatch(verifyGotoVendorToken({ token: TOKEN })) as any)
        .then(res => {
          notificationFunc("success", "Goto Vendor Successfully Updated");
          history.push("/login");
        })
        .catch(err => {
          notificationFunc("error", "Goto Vendor Invalid");
          history.push("/login");
        });
    } else {
      notificationFunc("error", "Goto Vendor Invalid");
      history.push("/login");
    }
  }, [TOKEN])
  

  return (
    <AuthContainer>
      {loading &&(
        <Spinner>
          <Spin
            style={{ position: "absolute", top: "50vh" ,left:"45%"}}
            size="large"
            tip="Loading..."
          />
        </Spinner>
      )}
      <Container>
      </Container>
    </AuthContainer>
  )
}
