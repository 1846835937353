import React, { useRef, useState } from "react";
import styled from "styled-components";

type Sizes =
  | "small"
  | "semiMiddle"
  | "middle"
  | "large"
  | "extraLarge"
  | "profileCreationMedium"
  | "profileCreationSmall"
  | "profileCreationLarge"
  | "medium"
  | "uploadInputSizeMedium"
  | "uploadInputSizeSmall"
  | "uploadInputSizeLarge"
  | "extraLargeNew"
  |"registrationPhoneView"
  |"registrationNew"
  | undefined;

export interface InputBoxProps {
  usedFor?: "capability" | "profileCreation_address" ;
  sizes: Sizes;
  error?: any;
  touch?: any;
  inputTextType?: any;
  importanceType: string;
  label?: string;
  name?: string;
  child?: string;
  fields?: any;
  otherProps?: any;
  ref?: any;
  placeholder?: string;
  disabled?: boolean;
  setRef?: boolean;
  secondInput?: boolean;
  value?: any;
  onHandleChange?: (e: any) => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  buttonDisable?: boolean;
  buttonType?: boolean;
  inputFieldError?: boolean;
  type?: string | number;
  maxChar?: any;
  minChar?: any;
  style?: any;
}

export const InputBox = styled.input<InputBoxProps>`
  ::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: ${props => `${props.style?.["fontSize"] || "16px"}`};
    line-height: ${props => `${props.style?.["lineHeigth"] || "24px"}`};;
    /* identical to box height */
    color: #011759;
    opacity: 0.4;
  }

  width: ${props =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].width}` : ""};
  height: ${props =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].height}` : ""};
  border-radius: ${props =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].borderRadius}` : ""};
  border: ${props =>
    props.sizes
      ? `${
          props.theme.input.sizes[props.sizes][
            props.importanceType === "optional"
              ? "borderOptional"
              : "borderImportant"
          ]
        }`
      : ""};
  color: ${props => `${props.theme.colors.darkBlue}`};
  padding-left: ${props =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].paddingLeft}` : ""};
  padding-right: ${props => (props.child === "Password" ? "45px" : "")};
  outline: none;
  font-size: ${props =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].fontSize}` : ""};
  font-weight: ${props =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].fontWeight}` : ""};
  background-color: ${props => props.usedFor == "capability" && ` #F4F6FF;`};
  border: ${props => props.usedFor && "1px solid #F4C234"};
  background-color: ${props => props.disabled && `${props.theme.colors.grayDisabledBg}`};

  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
  }

  ::-webkit-calendar-picker-indicator::after {
    content: "";
    display: block;
    background: url("/images/calender.svg") no-repeat;
    background-size: 10%;
    width: 100px;
    height: 100px;
    position: absolute;
    transform: translateX(-2%);
  }

  input[type="date"]:before {
    content: MM-YYYY !important;
    color: #aaa;
    margin-right: 0.5em;
  }
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "MM-YYYY";
  }
  &.error{
    border:1px solid #BE000B;
    background: rgba(190, 0, 11, 0.05);
  }

  @media only screen and (max-width: 640px) {
    ::-webkit-input-placeholder {
      font-weight: 500;
      font-size: 12px;
    line-height: 21px;
    }
    width: ${(props) =>
      props.sizes ? `${props.theme.input.sizes[props.sizes]?.mobileStyles?.width}` : ""};
    height: ${(props) =>
      props.sizes ? `${props.theme.input.sizes[props.sizes]?.mobileStyles?.height}` : ""};
    font-size: ${(props) =>
        props.sizes ? `${props.theme.input.sizes[props.sizes]?.mobileStyles?.fontSize}` : ""};
  }
`;

const InputBoxForEdit = ({
  placeholder,
  type,
  setRef,
  inputTextType,
  child,
  disabled,
  secondInput,
  name,
  label,
  fields,
  buttonDisable,
  buttonType,
  onChange,
  value,
  error,
  touch,
  onHandleChange,
  maxChar,
  minChar,
  style,
  inputFieldError,
  ...rest
}: InputBoxProps) => {
  let today: any = new Date();
  if (inputTextType === "date") {
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    let yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;
  }

  const inputRef = useRef(null);

  const [combineState, setCombineState] = useState(false);

  const handleEventChange = e => {
    const inputName = e.target.name;
    if (onHandleChange) {
      onHandleChange(e);
    }
  };

  const handleDateValue = () => {
    if (
      name === "projStartDate" &&
      maxChar !== "DD-MM-YYYY" &&
      maxChar !== ""
    ) {
      return maxChar;
    }
    return today;
  };

  return (
    <InputBox
      className={inputFieldError ? "error" : ""}
      style={style}
      type={inputTextType ? inputTextType : "text"}
      {...rest}
      placeholder={placeholder || child}
      // {...fields}
      autoComplete="off"
      // ref={inputRef}
      disabled={buttonDisable || false}
      name={name}
      label={label}
      value={value}
      maxLength={maxChar}
      max={inputTextType === "date" ? handleDateValue() : ""}
      min={inputTextType === "date" ? minChar : ""}
      onChange={handleEventChange}
      otherProps
    />
  );
};

export default InputBoxForEdit;
