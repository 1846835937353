import React from "react";
import styled from "styled-components";

type Sizes =
  | "small"
  | "semiMiddle"
  | "medium"
  | "middle"
  | "large"
  | "extraLarge"
  | "profileCreationMedium"
  | "profileCreationLarge"
  | "profileCreationSmall"
  | "registrationNew"
  | "registrationPhoneView"
  | undefined;

export interface InputBoxProps {
  sizes: Sizes;
  inputTextType?: any;
  importanceType: string;
  child?: string;
  fields?: any;
  name?: string;
  otherProps?: any;
  ref?: any;
  placeholder?: string;
  disabled?: boolean;
  setRef?: boolean;
  buttonType?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  max?: number;
  change?: boolean;
  inputFieldError?: boolean;
  inputFieldSuccess?: boolean;
  dataAttribute?: object;
  style?: React.CSSProperties;
}

export const InputBox = styled.input<InputBoxProps>`
  ::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    line-height: 27px;
    opacity: 0.6;
    color: rgba(17, 43, 122, 0.4);
  }

  width: ${(props) =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].width}` : ""};
  height: ${(props) =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].height}` : ""};
  border-radius: ${(props) =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].borderRadius}` : ""};
  border: ${(props) =>
    props.sizes
      ? `${
          props.theme.input.sizes[props.sizes][
            props.importanceType === "optional"
              ? "borderOptional"
              : "borderImportant"
          ]
        }`
      : ""};
  color: ${(props) => `${props.theme.colors.darkBlue}`};
  padding-left: ${(props) =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].paddingLeft}` : ""};
  padding-right: ${(props) => (props.name === "password" || props.name === "confirmPassword" ? "42px" : "")};
  outline: none;
  font-size: ${(props) =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].fontSize}` : ""};
  font-weight: ${(props) =>
    props.sizes ? `${props.theme.input.sizes[props.sizes].fontWeight}` : ""};

  &.error {
    border: 1px solid #be000b;
    background: rgba(190, 0, 11, 0.05);
  }

  &.success {
    border: 1px solid #47c882;
  }
  :disabled {
    cursor: no-drop;
  }
  @media only screen and (max-width: 640px) {
    ::-webkit-input-placeholder {
      font-weight: 500;
      font-size: 12px;
    line-height: 21px;
    }
    width: ${(props) =>
      props.sizes ? `${props.theme.input.sizes[props.sizes]?.mobileStyles?.width}` : ""};
    height: ${(props) =>
      props.sizes ? `${props.theme.input.sizes[props.sizes]?.mobileStyles?.height}` : ""};
    font-size: ${(props) =>
        props.sizes ? `${props.theme.input.sizes[props.sizes]?.mobileStyles?.fontSize}` : ""};
  }
`;

const Input = ({
  placeholder,
  change,
  setRef,
  inputTextType,
  child,
  disabled,
  buttonType,
  fields,
  name,
  max,
  value,
  inputFieldError,
  inputFieldSuccess,
  onChange,
  dataAttribute,
  ...rest
}: InputBoxProps) => {
  const classnameErrorOrSuccess = () => {
    switch (true) {
      case inputFieldError:
        return "error";
      case inputFieldSuccess:
        return "success";
      default:
        return "";
    }
  };


  return (
    <InputBox
      type={inputTextType ? inputTextType : "text"}
      {...rest}
      placeholder={placeholder || child}
      {...fields}
      {...dataAttribute}
      autoComplete="off"
      name={name}
      disabled={buttonType || false}
      // onChange = {onChange}
      // onChange={change && handleEventChange}
      value={value || undefined}
      maxLength={max || undefined}
      className={classnameErrorOrSuccess()}
    />
  );
};

export default Input;
