export const HEAP_DATA_TRACKING_ID = "data-tracking-id";
export const HEAP_DATA_TRACKING_VALUE = "data-tracking-value";

/** Custom user properties */
export enum HEAP_CUSTOM_USER_PROPERTIES {
  /** used at user email after user-login */
  customEmailId = "customEmailId",
  /** user GST after user-login */
  userGST = "userGST",

  /** for successful user login */
  loggedInVGSTN = "loggedInVGSTN",
  loggedInVEmail = "loggedInVEmail",
  loggedInVMobileNumber = "loggedInVMobileNumber",

  /** for successful user registration */
  regVMobileNumber = "regVMobileNumber",
  regVGSTN = "regVGSTN",
  regVEmail = "regVEmail",

  /** for successful onboarding  */
  onbVGSTN = "onbVGSTN", // GST Number
  // success file uploads
  onbV_file_GSTN = "onbV_FILE_GST",
  onbV_file_Brochure = "onbV_file_Brochure",
  onbV_file_KeyCustomers = "onbV_file_KeyCustomers",
  // text
  onbV_text_KeyCustomers = "onbV_text_KeyCustomers",
  onbV_text_Brochure = "onbV_text_Brochure",
}

export const HEAP_DATA_ATTRIBUTES = {
  HEAP_TRACK: {
    SUCCESSFUL_LOGIN: "successful-login",
  },

  NAVIGATION: {
    PROFILE_DROPDOWN: {
      MENU_ITEM_PROFILE: "navigation-profile",
      MENU_ITEM_UPDATE: "navigation-profile-update",
    },
    VAS: {
      HOMEPAGE: "navigation-vas-homepage",
      PRO_FINANCE: "navigation-vas-pro-finance",
      PRO_SOURCE: "navigation-vas-pro-source",
      PRO_CONNECT: "navigation-vas-pro-connect",
    },
    COMMUNITY: "navigation-community",
  },

  EMAIL_VERIFICATION_MODAL: {
    SEND_OTP_TO_EMAIL: "email-verification-send-otp-to-email",
    SKIP_FOR_NOW: "email-verification-skip-for-now",
    // class="ant-modal-close"
    CLOSE: "email-verification-close-modal",
  },

  REGISTRATION: {
    MOBILE: "register-mobilenumber",
    DESIGNATION: "register-designation",
    FULL_NAME: "register-name",
    EMAIL: "register-email",
    GST_NUMBER: "register-gstnumber",
    PASSWORD: "register-password",
    TNC: "register-tnc",
    NEXT: "register-next",
    REGISTER_CTA: "register-cta",
    WHATS_APP_OPTIN: "register-checkbox-whatsapp-option",
    OTP_VERIFICATION: {
      SUBMIT: "verification-submit",
      BACK_TO_EDIT: "verification-back-to-edit",
      LOGIN_LINK: "login",
      // implemented below trigger on parent container
      OTP_FILL: "verification-otp-fill",
      SEND_OTP: "verification-send-otp",
      RE_SEND_OTP: "verification-re-send-otp",
      MOBILE_NUMBER_OTP: "verification-six-digit-mobile-otp",
    },
    HEAP_TRACK: {
      SUCCESSFUL_REGISTRATION: "successful-registration",
    },
  },

  ONBOARDING: {
    COMPANY_NAME: "onboarding-company-name",
    WEBSITE: "onboarding-website",
    KEY_CUSTOMERS: {
      FILE_UPLOAD: "onboarding-file-upload-key-customers",
      ADD_CUSTOMERS: {
        BUTTON: "onboarding-add-customers-button",
        TEXT_VALUE: "onboarding-add-customers-text-value",
      },
    },
    COMPANY_BROCHURE: {
      FILE_UPLOAD: "onboarding-file-upload-company-brochure",
      ADD_SERVICE_OFFERINGS: {
        BUTTON: "onboarding-add-service-offerings-button",
        TEXT_VALUE: "onboarding-add-service-offerings-text-value",
      },
    },
    FILE_UPLOAD: {
      GST: "onboarding-file-upload-gst",
      PAST_PROJECTS_AND_EXP: "onboarding-file-upload-past-projects-and-exp",
      HEAP_TRACK: {
        SUCCESSFUL_GST_FILE_UPLOAD: "onboarding-file-upload-success-gst",
        SUCCESSFUL_KEY_CUSTOMERS_FILE_UPLOAD:
          "onboarding-file-upload-success-key-customers",
        SUCCESSFUL_COMPANY_BROCHURE_FILE_UPLOAD:
          "onboarding-file-upload-success-company-brochure",
        SUCCESSFUL_PAST_PROJECTS_AND_EXP_FILE_UPLOAD:
          "onboarding-file-upload-past-projects-and-exp",
      },
    },
    CHECKBOX_HAS_IN_HOUSE_PLANT_OR_WORKSHOP:
      "onboarding-checkbox-manufacturing-products",
    /** implemented multi-select-data-tracking-id on parent component */
    MULTI_SELECT_DROP_DOWN: {
      TYPE_OF_EQUIPMENTS: "onboarding-multi-select-types-of-equipment",
      SERVICE_CAPABILITIES: "onboarding-multi-select-service-capabilities",
      LOCATIONS_SERVED: "onboarding-multi-select-locations-served",
      INDUSTRIES_SERVED: "onboarding-multi-select-industries-served",
      TYPE_OF_EQUIPMENTS_HEAP_TRACK:
        "onboarding-multi-select-types-of-equipment-heap-track",
      SERVICE_CAPABILITIES_HEAP_TRACK:
        "onboarding-multi-select-service-capabilities-heap-track",
      LOCATIONS_SERVED_HEAP_TRACK:
        "onboarding-multi-select-locations-served-heap-track",
      INDUSTRIES_SERVED_HEAP_TRACK:
        "onboarding-multi-select-industries-served-heap-track",
    },
    SAVE_AND_CONTINUE: "onboarding-save-and-continue",
    SUCCESS_MODAL: {
      VIEW_PROFILE_BUTTON: "onboarding-success-modal-view-profile-button",
      VIEW_DASHBOARD_BUTTON: "onboarding-success-modal-view-dashboard-button",
    },
    SUCCESSFUL_ONBOARDING_HEAP_TRACK_EVENT_NAME: "onboarding-successful",
  },

  ONBOARDING_OPEN_LINK: {
    COMPANY_NAME: "onboarding-open-link-company-name",
    WEBSITE: "onboarding-open-link-website",
    KEY_CUSTOMERS: {
      FILE_UPLOAD: "onboarding-open-link-file-upload-key-customers",
      ADD_CUSTOMERS: {
        BUTTON: "onboarding-open-link-add-customers-button",
        TEXT_VALUE: "onboarding-open-link-add-customers-text-value",
      },
    },
    COMPANY_BROCHURE: {
      FILE_UPLOAD: "onboarding-open-link-file-upload-company-brochure",
      ADD_SERVICE_OFFERINGS: {
        BUTTON: "onboarding-open-link-add-service-offerings-button",
        TEXT_VALUE: "onboarding-open-link-add-service-offerings-text-value",
      },
    },
    FILE_UPLOAD: {
      GST: "onboarding-open-link-file-upload-gst",
      PAST_PROJECTS_AND_EXP:
        "onboarding-open-link-file-upload-past-projects-and-exp",
      GST_HEAP_TRACK: "onboarding-open-link-file-upload-gst-heap-track",
      KEY_CUSTOMERS_HEAP_TRACK:
        "onboarding-open-link-file-upload-key-customers-heap-track",
      COMPANY_BROCHURE_HEAP_TRACK:
        "onboarding-open-link-file-upload-company-bronchure-heap-track",
      PAST_PROJECTS_AND_EXP_HEAP_TRACK:
        "onboarding-open-link-file-upload-past-projects-and-exp-heap-track",
      HEAP_TRACK: {
        SUCCESSFUL_GST_FILE_UPLOAD: "onboarding-file-upload-success-gst",
        SUCCESSFUL_KEY_CUSTOMERS_FILE_UPLOAD:
          "onboarding-file-upload-success-key-customers",
        SUCCESSFUL_COMPANY_BROCHURE_FILE_UPLOAD:
          "onboarding-file-upload-success-company-brochure",
        SUCCESSFUL_PAST_PROJECTS_AND_EXP_FILE_UPLOAD:
          "onboarding-file-upload-past-projects-and-exp",
      },
    },
    CHECKBOX_HAS_IN_HOUSE_PLANT_OR_WORKSHOP:
      "onboarding-open-link-checkbox-manufacturing-products",
    /** implemented multi-select-data-tracking-id on parent component */
    MULTI_SELECT_DROP_DOWN: {
      TYPE_OF_EQUIPMENTS:
        "onboarding-open-link-multi-select-types-of-equipment",
      SERVICE_CAPABILITIES:
        "onboarding-open-link-multi-select-service-capabilities",
      LOCATIONS_SERVED: "onboarding-open-link-multi-select-locations-served",
      INDUSTRIES_SERVED: "onboarding-open-link-multi-select-industries-served",
      TYPE_OF_EQUIPMENTS_HEAP_TRACK:
        "onboarding-open-link-multi-select-types-of-equipment-heap-track",
      SERVICE_CAPABILITIES_HEAP_TRACK:
        "onboarding-open-link-multi-select-service-capabilities-heap-track",
      LOCATIONS_SERVED_HEAP_TRACK:
        "onboarding-open-link-multi-select-locations-served-heap-track",
      INDUSTRIES_SERVED_HEAP_TRACK:
        "onboarding-open-link-multi-select-industries-served-heap-track",
    },
    SAVE_AND_CONTINUE: "onboarding-open-link-save-and-continue",
    SUCCESS_MODAL: {
      VIEW_PROFILE_BUTTON:
        "onboarding-open-link-success-modal-view-profile-button",
      VIEW_DASHBOARD_BUTTON:
        "onboarding-open-link-success-modal-view-dashboard-button",
    },
  },

  VIEWPROFILE: {
    DASHBOARD: "dashboard-viewprofile",
    NAVIGATION: {
      PROFILE: "dashboard-navigation-viewprofile",
    },
  },

  VENDOR_PROFILES: {
    HEAP_TRACK: {
      BROCHURE_LINK: "vpv-page-brochure-link-heap-track",
      WEBSITE_LINK: "vpv-page-website-link-heap-track",
      CASE_STUDY_LINK: "vpv-page-case-study-link-heap-track",
      HEAP_TRACK_MACHINE_GALLERY_BUTTON: "vpv-manufacturing-capabilities-machine-gallery-button-heap-track",
    },
    COMPANY_INFO_EDIT_CTA: "vpv-page-capabilities-company-info-edit-click",
    BROCHURE_LINK: "vendor-profile-page-brochure-link",
    CASE_STUDY_LINK: "vendor-profile-page-case-study-link",
    VIEW_GALLERY_CTA: "vendor-profile-page-view-gallery-cta",
    WEBSITE_LINK: "vendor-profile-page-website-link",
  },

  USER_INFO: {
    SAVE_AND_CONTINUE: "userinfo-saveandcontinue",
  },

  COMPANY_INFO: {
    NAME: "companyinfo-name",
    YOI: "companyinfo-yoi",
    ADDRESS_EDIT: "companyinfo-address-edit",
    PROOF_UPLOAD: "companyinfo-proof-upload",
    DOC_UPLOAD: "companyinfo-doc-upload",
    SAVE_AND_CONTINUE: "companyinfo-saveandcontinue",
    // implemented below trigger on parent container
    ANNUAL_TURN_OVER: "companyinfo-anual-turnover-dropdown",
  },

  CAPABILITIES: {
    UPLOAD: {
      CUSTOMER_LIST: "capabilities-customerlist-upload",
      IMAGE: "capabilities-image-upload",
      CERTIFICATE: "capabilities-certificate-upload",
      OEM: "capabilities-oem-upload",
      BULK_IMAGE_UPLOAD_SUCCESS: "capabilities-bulk-image-upload",
      BULK_VIDEO_UPLOAD: "capabilities-bulk-video-upload",
    },
    NAME: {
      CERTIFICATE: "capabilities-certificate-name",
      OEM: "capabilities-oem-name",
    },
    KEY_CUSTOMERS: "capabilities-keycustomers",
    SERVICE_OFFERINGS: "capabilities-service-offerings",
    PRODUCT_INHOUSE: "capabiltiies-product-inhouse",
    ADD_NEW_PLANT_DETAILS: "capabilities-plantdetails-addnew",
    SAVE_AND_CONTINUE: "capabilities-saveandcontinue",
    EXPAND: {
      CERTIFICATE: "capabilities-expand-certificate",
      OEM: "capabilities-expand-oem",
      // implemented below trigger on parent container
      MANUFACTURE: "capabilities-expand-manufacture",
    },
    // implemented below trigger on parent container
    DROPDOWN: {
      INDUSTRIES_SERVED: "capabilities-industriesserved-dropdown",
      SCOPE_OF_WORK: "capabilities-scopeofwork-dropdown",
      INDIAN_LOCATIONS: "capabilities-locations-india-dropdown",
    },
    MANUFACTURING_CAPABILITIES: {
      ON_CLICK: {
        ADD_NEW_PLANT: "manufacturing-capabilities-on-click-add-new-plant",
      },
      HEAP_TRACK: {
        PLANT: {
          TABLE: {
            EDIT: "manufacturing-capabilities-edit-plant-heap-track",
            DELETE: "manufacturing-capabilities-delete-plant-heap-track",
          }, 
          FORM: {
            CITY: "manufacturing-capabilities-plant-city-heap-track",
            STATE: "manufacturing-capabilities-plant-state-heap-track",
            TOTAL_AREA: {
              VALUE: "manufacturing-capabilities-plant-total-area-value-heap-track",
              UNIT: "manufacturing-capabilities-plant-total-area-unit-heap-track",
            },
            WORKSHOP_AREA: {
              VALUE: "manufacturing-capabilities-plant-workshop-area-value-heap-track",
              UNIT: "manufacturing-capabilities-plant-workshop-area-unit-heap-track",
            },
            CAPACITY: {
              VALUE: "manufacturing-capabilities-plant-capacity-value-heap-track",
              UNIT: "manufacturing-capabilities-plant-capacity-unit-heap-track",
            },
            OTHER_INFORMATION: "manufacturing-capabilities-plant-other-info-heap-track",
            SAVE_PLANT_DETAILS: "manufacturing-capabilities-save-plant-details-heap-track",
          },
        },
        MACHINE: {
          TABLE: {
            ADD_NEW: "manufacturing-capabilities-add-new-machine-heap-track",
            EDIT: "manufacturing-capabilities-edit-machine-heap-track",
            DELETE: "manufacturing-capabilities-delete-machine-heap-track",
          },
          FORM: {
            PROCESS: "manufacturing-capabilities-machine-process-heap-track",
            MACHINE_NAME: "manufacturing-capabilities-machine-name-heap-track",
            NUMBER_OF_MACHINES: "manufacturing-capabilities-number-of-machines-heap-track",
            MODEL_NAME: "manufacturing-capabilities-model-name-heap-track",
            MACHINE_MAKE: "manufacturing-capabilities-machine-make-heap-track",
            SPECIFICATION: "manufacturing-capabilities-specification-heap-track",
            OTHER_INFORMATION: "manufacturing-capabilities-other-info-heap-track",  
            SAVE_MACHINE_DETAILS: "manufacturing-capabilities-save-machine-details-heap-track",
          },
        },
      },
    },
  },

  EXPERIENCE: {
    EXPAND_PROJECT: "experience-expand-project",
    COMPANY_NAME: "experience-company-name",
    CITY: "experience-city",
    DOC_UPLOAD: "experience-doc-upload",
    DESCRIPTION: "experience-description",
    START_DATE: "experience-start-date",
    COMPLETION_DATE: "experience-completion-date",
    SAVE_AND_CONTINUE: "experience-saveandcontinue",
    EXP_AND_REQUEST_REVIEW_SAVE_AND_CONTINUE: "experience-and-request-review-saveandcontinue",
    // implemented below trigger on parent container
    CLIENT_INDUSTRY: "experience-client-industry",
    ADD_NEW_PROJECT_BUTTON: "experience-add-new-project",
    REQUEST_FOR_REVIEW: {
      VIEW_REVIEW_BUTTON: "experience-request-for-review-button-view-review",
      PREVIEW_LINK_FOR_REVIEW_FORM: "experience-request-for-review-preview-link-for-review-form",
    },
    EXPLAINER_VIDEO: {
      HELP_BUTTON: "experience-explainer-video-help-button-click",
      CONTINUE_BUTTON: "experience-explainer-video-continue-button-click",
      CLOSE_VIDEO_MODAL_BUTTON: "experience-explainer-video-close-video-modal-button-click",
    },
    HEAP_TRACK: {
      COMPANY_TITLE: "experience-company-title-heap-track",
      CLIENT_INDUSTRY_DROPDOWN: "experience-client-industry-dropdown-heapp-track",
      DESCRIPTION: "experience-description-heap-track",
      LOCATION: "experience-location-heap-track",
      PROJECT_START_DATE: "experience-project-start-date-heap-track",
      PROJECT_COMPLETION_DATE: "experience-project-completion-date-heap-track",
      PROJECT_COST: "experience-project-cost-heap-track",
      PROJECT_DOCS_FILE_UPLOAD: {
        SUCCESS: "experience-project-docs-file-upload-success-heap-track",
        FAILURE: "experience-project-docs-file-upload-failure-heap-track",
      },
      REQUEST_FOR_REVIEW: {
        CLIENT_USER_NAME: "experience-request-for-review-client-name-heap-track",
        CLIENT_EMAIL: "experience-request-for-review-client-email-heap-track",
        CLIENT_DESIGNATION: "experience-request-for-review-client-designation-heap-track",
        NOTE: "experience-request-for-review-note-heap-track",
      }
    },
  },

  FINANCIAL_INFO: {
    UPLOAD_CHECK_BUTTON: "financial-info-upload-cheque-button",
    SAVE_AND_CONTINUE: "financial-info-saveandcontinue",
    TEXT_FIELD: {
      IFSC_CODE: "financial-info-ifsc-code",
      BANK_ACC_NUMBER: "financial-info-bank-account-number",
      BANK_NAME: "financial-info-bank-name",
      ACC_HOLDER_NAME: "financial-info-account-holder-name",
    },
  },

  COMPLIANCE: {
    CHECKBOX: {
      GST: "compliance-gst-checkbox",
      PAN: "compliance-pan-checkbox",
      LIN: "compliance-lin-checkbox",
      URN: "compliance-urn-checkbox",
    },
    UPLOAD: {
      GST: "compliance-gst-upload",
      PAN: "compliance-pan-upload",
      LIN: "compliance-lin-upload",
      URN: "compliance-urn-upload",
    },
    FILL: {
      GST: "compliance-gst-fill",
      PAN: "compliance-pan-fill",
      LIN: "compliance-lin-fill",
      URN: "compliance-urn-fill",
    },
    SAVE_AND_CONTINUE: "compliance-saveandcontinue",
  },

  REFERENCES: {
    SAVE_AND_CONTINUE: "references-saveandcontinue",
  },

  PEOPLE: {
    TEXT_FIELD: {
      MANPOWER: {
        TOTAL: "people-total-manpower",
        FULL_TIME: "people-full-time-manpower",
        PART_TIME: "people-part-time-manpower",
      },
      FULL_NAME: "people-full-name",
      DESIGNATION: "people-designation",
      E_MAIL: "people-e-mail",
      INDUSTRY: "people-industry",
    },
    SAVE_AND_CONTINUE: "people-saveandcontinue",
  },

  LOGIN: {
    EMAIL: "login-email",
    PASSWORD: "login-password",
    PHONE: "login-phone",
    REMEMBER_ME: "login-remember-me",
    FORGOT_PASSWORD: "login-forgot-password",
    CTA: "login-cta",
    OTP_SEND: "login-otp-send",
    OTP_LOGIN: "login-otp",
    PHONE_OTP_LOGIN_CTA: "login-cta-phone-no-login",
    REGISTER_LINK: "register",
  },

  FORGOT_PASSWORD: {
    EMAIL: "forgot-password-email",
    OTP_SEND: "forgot-password-otp-send",
    RESET: "forgot-password-reset",
    CREATE_PASSWORD: "forgot-password-create-password",
    CONFIRM_PASSWORD: "forgot-password-confirm-password",
    SAVE_CHANGES: "forgot-password-save-changes",
    // implemented below trigger on parent container
    OTP_FILL: "forgot-password-otp",
  },

  REQUESTS_LISTING_PAGE: {
    RELEVANT_JOB_SUMMARY_CARD: {
      EDIT_PROFILE_LINK: "requests-listing-edit-profile-link-click",
    },
    TABS: {
      OPEN_JOBS: "requests-listing-open-jobs-tab-click",
      INTERESTED_JOBS: "requests-listing-interested-jobs-tab-click",
      PLANT_VISITS: "requests-listing-plant-visits-tab-click",
    },
    VIEW_JOB_BUTTON: {
      OPEN_JOBS: {
        RELEVANT_JOB: "requests-listing-open-jobs-view-relevant-job-click",
        IRRELEVANT_JOB: "requests-listing-open-jobs-view-non-relevant-job-click",
      },
      INTERESTED_JOBS: {
        RELEVANT_JOB: "requests-listing-interested-jobs-view-relevant-job-click",
        IRRELEVANT_JOB: "requests-listing-interested-jobs-view-non-relevant-job-click",
      },
      PLANT_VISITS: "requests-listing-plant-visits-view-job-click",
    },
    FLOATING_HELP_BUTON: {
      OPEN_JOBS: {
        HELP_BUTTON: "req-list-open-jobs-video-help-button-click",
        CONTINUE_BUTTON: "req-list-open-jobs-video-continue-button-click",
        CLOSE_VIDEO_MODAL_BUTTON: "req-list-open-jobs-video-close-video-modal-button-click",
      },
    },
    OPEN_JOBS_BANNER: {
      IMAGE: "requests-listing-open-jobs-banner-image-click",
      BUTTON: "requests-listing-open-jobs-banner-button-click"
    }
  },

  JOB_REQUEST_DETAILS_PAGE: {
    CLICK_INTERESTED: "job-request-details-clicked-i-am-interested",
    CLICK_NOT_INTERESTED: "job-request-details-clicked-i-am-not-interested",
    VIEW_PRICING_TERMS_CTA: "job-request-details-view-pricing-terms-cta",
    VIEW_PRICING_AGREEMENT_CTA:
      "job-request-details-view-pricing-agreement-cta",
    AGREE_PRICING_TERMS_CTA: "job-request-details-accept-pricing-terms-cta",
    PRICING_QUERY_CONTACT_CTA: "job-request-details-pricing-query-contact-cta",
    PRICING_QUERY_EMAIL_CTA: "job-request-details-pricing-query-email-cta",
    DOWNLOAD_RFQ_DOCUMENT: "job-request-details-download-rfq-document",

    UPLOAD_NEW_QUOTATION_DOCS_CTA:
      "job-request-details-upload-new-quotation-docs-cta",
    SUBMIT_QUOTATION_CTA: "job-request-details-submit-quotation-cta",
    CONTINUE_TO_MODIFY_QUOTATION_CTA:
      "job-request-details-continue-to-modify-quotation-cta",
    DOWNLOAD_QUOTATION_FILE: "job-request-details-download-quotation-file-cta",
    UPLOAD_NEW_FILE_VERSION_CTA:
      "job-request-details-upload-new-file-version-cta",
    REMOVE_FILE_CTA: "job-request-details-remove-file-cta",
    FILE_HISTORY_CTA: "job-request-details-file-history-cta",
    RESTORE_FILE_CTA: "job-request-details-restore-file-cta",
    MARK_FILE_AS_FINAL_CTA: "job-request-details-mark-file-as-final-cta",
    UPDATE_CAPABILITIES_LINK: "job-request-details-update-capabilities-link",
    LOGIN_TO_VIEW_JOB: "job-request-details-login-to-view-job-cta",
    APPLY_JOB: "job-request-details-apply-job-cta"
  },

  QUICK_LOGIN: {
    JOB_SCOPE_LOGIN_HEAP_TRACK: "open-job-scope-quick-login-heap-track",
    SEND_OTP_LOGIN: "send-otp-quick-login",
  }
} as const;
