import React from "react";
import { ThemeProvider } from "styled-components";
import { inputTheme } from "components/Input/InputStyles";
import { cardTheme } from "components/Card/InputStyles";
import { buttonTheme } from "./components/Button/ButtonStyles";
import { labelTheme } from "./components/Label/LabelStyles";
import { dropdownTheme } from "./components/Dropdown/DropdownStyles";
import { textAreaTheme } from "./components/TextArea/InputStyles";

export const colors = {
  primary: "#112B7A",
  secondary: "#47C882",
  blue: "#215EC9",
  darkBlue: "#011759",
  black: " #261C4B",
  blackLight: "#1C252B",
  green: "#00C396",
  red: "#BE000B",
  darkRed: "#BF000B",
  lightRed: "#FF1F47",
  orange: "#FF8200",
  white: "#FFFFFF",
  placeholderGray: "#ABAEB3",
  whiteGray: "#D5D9EC",
  whiteFifty: "rgba(255, 255, 255, 0.5)",
  grayLight: "#B5C3C9",
  gray: "#98A6AD",
  grayDark: "#4A5A65",
  lightGray: "#F4F6FF",
  grayDisabledBg: "#E5E5E5",
  textGray: '#C0C1C2',
  textBlue: "#0077CC"
} as const;

export type Colors = typeof colors;

export const globalStyles = {
  fontFamily: "Poppins",
  baseFontSize: "10px",
  bodyBG: "#FFFFFF",
  // bodyBG: "#11151A",
  baseFontColor: "#FFFFFF",
  borderRadius: "60px",
};

const fontSizes = {
  hero: {
    fontSize: "30px",
    lineHeight: "45px",
    fontStyle: "italic",
    fontWeight: "600",
    letterSpacing: " 0.01em",
  },
  h1: {
    fontSize: "2.4rem",
    lineHeight: "3.5rem",
  },
  h2: {
    fontSize: "20px",
    lineHeight: "2.9rem",
  },
  h3: {
    fontSize: "14px",
    lineHeight: "2.4rem",
  },
  body: {
    fontSize: "1.3rem",
    lineHeight: "1.9rem",
  },
  body2: {
    fontSize: "14px",
    lineHeight: "1.8rem",
  },
  caption: {
    fontSize: "1.1rem",
    lineHeight: "1.7rem",
  },
  normal: {
    fontSize: "11px",
    lineHeight: "16.5px;",
    fontStyle: "normal",
    fontWeight: "600",
    letterSpacing: " 0.01em",
  },
  profileHeader: {
    fontSize: "14px",
    lineHeight: "1.313rem",
  },
  scopeOfWork: {
    color: "#011759",
    fontSize: "12px",
    fontWeight: "600",
  },
  avatar: {
    fontSize: "14px",
    lineHeight: "21px",
  },
  brochureSize: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#215EC9",
    lineHeight: "18px",
  },
  profileCreation: {
    fontSize: "12px",
    fontWeight: "600",
    lineHeight: "18px",
  },
  profileCreationCard: {
    fontSize: "14px",
    fontWeight: "600",
    color: colors.primary,
    marginRight: "18px",
  },
  profileName: {
    fontSize: "18px",
    lineHeight: "27px",
    fontStyle: "normal",
    color: colors.primary,
  },
  profileCreationHighlitedlabel: {
    fontSize: "12px",
    color: colors.darkBlue,
    lineHeight: "18px",
    fontStyle: "normal",
  },
  referalCardHeader: {
    fontSize: "14px",
    lineHeight: "21px",
    fontStyle: "normal",
  },
};

const fontWeights = {
  light: "300",
  regular: "400",
  medium: "500",
  bold: "600",
  extremeBold: "700",
};

const theme = {
  colors,
  button: { ...buttonTheme(colors) },
  input: { ...inputTheme(colors) },
  card: { ...cardTheme(colors) },
  label: { ...labelTheme(colors) },
  dropdown: { ...dropdownTheme(colors) },
  textbox: { ...textAreaTheme(colors) },
  fontSizes,
  fontWeights,
  globalStyles,
};

const Theme = ({ children }: any) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
