export const GOTO_VENDORS_VERIFY_TOKEN_REQUESTED = "goto-vendors/GOTO_VENDORS_VERIFY_TOKEN_REQUESTED";
export const GOTO_VENDORS_VERIFY_TOKEN_SUCCESS = "goto-vendors/GOTO_VENDORS_VERIFY_TOKEN_SUCCESS";
export const GOTO_VENDORS_VERIFY_TOKEN_FAILURE = "goto-vendors/GOTO_VENDORS_VERIFY_TOKEN_FAILURE";

export const initialState = {
  loading: false,
  loaded: false,
  error: "",
  data: {},
  verificationStatus: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GOTO_VENDORS_VERIFY_TOKEN_REQUESTED:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case GOTO_VENDORS_VERIFY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        verificationStatus: "VERIFIED",
      };

    case GOTO_VENDORS_VERIFY_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
        verificationStatus: "NOT_VERIFIED",
      };

    default:
      return state;
  }
};

/**
 * 
 * @param {{ token: string }} data 
 */
export const verifyGotoVendorToken = data => ({
  types: [
    GOTO_VENDORS_VERIFY_TOKEN_REQUESTED,
    GOTO_VENDORS_VERIFY_TOKEN_SUCCESS,
    GOTO_VENDORS_VERIFY_TOKEN_FAILURE
  ],
  promise: client => client.post("verify_goto_vendor_token", { data }),
});
