import styled from "styled-components";

export const ContentInputs = styled.div`
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 30px;
  padding : 0px 16px;
`;

export const InputDiv = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding: 19px 0px 20px 0px;
  position: fixed;
  bottom: 0;
  @media screen and (max-width: 640px) {
    z-index: 10;
    border-top: none;
    padding: 0px;
  }
`;

export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 10px;
  backdrop-filter: blur(0.5px);
  z-index: 1500;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const RowWrapper = styled.div`
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
`;

export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;
`;

export const AddBttn = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #215ec9;
  margin-top: -10px;
  cursor: pointer;
  width: 76px;
  margin-top: 10px;
`;

export const InputWrapper = styled.input`
  position: absolute;
  opacity: 0;
`;

export const UploadButton = styled.div<any>`
  width: 300px;
  height: 40px;
  background: ${(props) => (props.errorState ? "rgba(190, 0, 11, 0.05)" : "#F4F6FF")};
  border: ${(props) => (props.errorState ? "1px solid #BE000B" : "1px solid #D4E5FE")};
  border-radius: 8px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
`;

export const LogoUpload = styled.div`
  position: relative;
  padding-right: 6px;
`;

export const UploadText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #215ec9;
`;

export const DocumentUploadDiv = styled.div`
  display: flex;
  margin-top: -5px;
  flex-wrap: wrap;
`;

export const DocumentUploadWrapper = styled.div`
  margin-top: -15px;
`;
