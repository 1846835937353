export const checkNewWords = (stateValStr: string, content: string) => {
  const userTypedWords = stateValStr?.split(' ');
  const contentWords = content?.split(' ');
  let newWords = [];
  contentWords.forEach((word) => {
    if (!userTypedWords?.includes(word)) {
      newWords?.push(word);
    }
  });
  return newWords;
}

export const cleanFormatting = ({ stateVal, content }: {
  stateVal: string, content: string;
}) => {
  const temporaryElement = document.createElement('div');
  temporaryElement.innerHTML = content;
  const elementsToRemoveStyle = temporaryElement?.querySelectorAll('*');
  elementsToRemoveStyle.forEach((element) => {
    element?.removeAttribute('style');
    if (checkNewWords(stateVal, content).length > 2) {
      if (element?.tagName === 'SPAN' && element?.innerHTML === '<br>') {
        element?.remove();
      }
    }
  });
  return temporaryElement.innerHTML;
};

/**
 * @param htmlString string
 * returns string without empty html elements
 * Example: 
 *  "<p></p>" 
 *  "<div></div>" 
 *  "<span></span>"
 *  etc...
 */
export const removeEmptyHTMLElementsFromString = (htmlString: string) => {
  if (!htmlString) return '';
  return htmlString.replace(/<[^>/]*><\/[^>]*>/g, '');
};
