export const VAS_NAVIGATION_LINKS = {
  HOMEPAGE: "https://sites.google.com/venwiz.com/venwiz-vas/home?utm_source=Platform&utm_medium=nav+header&utm_campaign=Venwiz+VAS",
  PRO_FINANCE: "https://sites.google.com/venwiz.com/venwiz-pro-finance/home?utm_source=Platform&utm_medium=nav+header&utm_campaign=Venwiz+VAS",
  PRO_SOURCE: "https://sites.google.com/venwiz.com/venwiz-pro-source/home?utm_source=Platform&utm_medium=nav+header&utm_campaign=Venwiz+VAS",
  PRO_CONNECT: "https://sites.google.com/venwiz.com/venwiz-proconnect/home?utm_source=Platform&utm_medium=nav+header&utm_campaign=Venwiz+VAS",
} as const;

export const COMMUNITY_NAVIGATION_LINKS = {
  HOMEPAGE: "https://sites.google.com/venwiz.com/vendorcommunity/home",
};
