import UploadImage from "components/UploadImage/UploadImage";
import React, { useEffect, useState } from "react";
import { ApiConfig } from "ui-constants";
import { Upload, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { LogoImage } from "../Image";
import { getHeaders } from "constants/auth";
import { TextAreaWithHeader } from "components/TextArea";
import Button from "components/Button/Button";
import ImgCardTag from "./Card";
import { Label } from "components/Label";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import ProfileInputHeading from "Profile/shared/Heading/Heading";
import { setIsLoading } from "reducers/auth";

interface ImageData {
  fileName: string;
  fileSize: number;
  fileUrl: string;
}

interface NewImageProps {
  onHandleUpload?: (imageList: any) => void;
  buttonState: boolean;
  data?: any;
  dataTrackingId?: string;
}

interface CurrentImageProps {
  imageName: any;
  file: object;
}

interface FlexProps {
  imgList: any;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: max-content;
  max-width: 900px;
  height: max-content;
  flex-wrap: wrap;
  @media screen and (max-width: 1270px) {
    margin-top: 30px;
  }
  @media screen and (max-width: 1175px) {
    flex-direction: column;
  }
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justifu-content: space-between;

  height: 200px;
  width: 415px;
  min-width: 415px;
  max-width: 50%;
`;
const TextArea = styled.textarea`
  ::-webkit-input-placeholder {
    color: 011759, 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #011759;
    opacity: 0.7;
  }
  width: 100%;
  height: 104px;

  border-radius: ${props => props.theme.textbox.sizes.small.borderRadius};
  border: ${props => props.theme.textbox.sizes.small.border};
  color: ${props => props.theme.textbox.sizes.small.color};
  resize: ${props => props.theme.textbox.sizes.small.resize};
  padding: ${props => props.theme.textbox.sizes.small.padding};
  outline: ${props => props.theme.textbox.sizes.small.outline};
`;
const TextAreaWrapper = styled.div`
  height: 100px;
`;
const ButtonWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  height: 30px;
`;
const UploadContainer = styled.div`
  width: 415px;
  height: max-content;
  max-height: 235px;
  min-width: 415px;
  max-width: 50%;
`;

const ImageContainerWrapper = styled.div`
  margin-left: 0px;
  margin-top: 20px;
  @media screen and (max-width: 1175px) {
    margin-left: 00px;
    margin-top: 20px;
  }
`;
const ImageListContainer = styled.div`
  width: 395px;
  height: 80%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  background: #f4f6ff;
  align-items: center;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 12px;
  padding: 10px 10px;
  @media screen and (max-width: 1270px) {
    width: 350px;
    align-items: baseline;
    overflow-x: hidden;
  }

  @media screen and (max-width: 1220px) {
    width: 300px;
    align-items: baseline;
    overflow-x: hidden;
  }
  @media screen and (max-width: 1170px) {
    width: 415px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  width: 100%;
  width: 93%;
  justify-content: space-between;
`;

const FlexColumn = styled.div`
  display: flex;
  width: 415px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 1270px) {
    flex-direction: column;
  }
`;

const WarningMsg = styled.div`
  display: flex;
  margin-top: 8px;
`;

const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Heading = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #011759;

  opacity: 0.7;
`;

const InputFlexWrapper = styled.div`
  padding: 10px 10px 0px 0;
`;

const InputDivWrap = styled.div`
  width: 100%;
  position: relative;
  height: 40px;
`;

const InputWrap = styled.input`
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 160px;
  height: 60px;
  bottom: 70px;
  right: -45px;
  cursor: pointer;
`;
const ImgUploadBttn = styled.div`
  border-radius: 8px;
  width: 550px;
  margin-top: 0px;
  position: relative;
  height: 40px;
`;
const NewUploadImage = (props: NewImageProps) => {
  const [description, setDescription] = useState("");
  const [tempBucketData, setTempBucketData] = useState([]);
  const [imgStore, setImgStore] = useState<ImageData>({
    fileName: "",
    fileSize: 0,
    fileUrl: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [imgList, setImgList] = useState<any>([]);
  const [currentImage, setCurrentImage] = useState<CurrentImageProps>();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const handleUpload = (imageDetails: any, name: string) => {
    // setImgStore(imageDetails);
    setTempBucketData([
      ...tempBucketData,
      {
        imageName: name,
        description: "image",
        file: { ...imageDetails },
      },
    ]);
  };

  const removeImg = (name, number) => {
    setImgList(imgList.filter((item, index) => index !== number));
    setTempBucketData(tempBucketData.filter((item, index) => index !== number));
  };

  const onHandleDescription = e => {
    setDescription(e.target.value);
  };

  useEffect(() => {
    if (props?.data?.length) {
      setImgList(props?.data);
      setTempBucketData(props?.data);
    }
  }, [props.data]);

  useEffect(() => {
    props.onHandleUpload(tempBucketData);
  }, [tempBucketData]);


  const renderImageContainer = () => {
    return (
      <UploadContainer>
        <InputFlexWrapper>
          <InputDivWrap>
            <ImgUploadBttn>
              <UploadImage
                isError={(value, message) => {
                  setError(value);
                  setErrorMessage(message);
                }}
                getImgSrc={(img, name, currentFile) => {
                  setCurrentImage({
                    imageName: name,
                    file: { fileUrl: img, fileName: name },
                  });

                  setImgList([
                    ...imgList,
                    {
                      imageName: name,
                      file: { fileUrl: img, fileName: name },
                    },
                  ]);

                  setImgStore({
                    fileName: "",
                    fileSize: 0,
                    fileUrl: "",
                  });
                  setDescription("");
                }}
                height="40px"
                width="550px"
                usedAt="scopeOfWork"
                title="Company Logo"
                imgSrc={""}
                optional
                onUpload={handleUpload}
                disabled={props.buttonState}
                data-tracking-id={props?.dataTrackingId}
              />
            </ImgUploadBttn>
          </InputDivWrap>
        </InputFlexWrapper>

        <WarningMsg>
          <Div>
            <LogoImage
              imageSrc={
                error
                  ? "/images/warning.svg"
                  : "/images/ProfileCreation/info.svg"
              }
              altMesg="edit button"
              imageType="icon"
            />
          </Div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Label
              fontVariant="bold"
              htmlFor="brochureSize"
              text={
                error
                  ? errorMessage
                  : "Supports .png and .jpeg formats. Max. file size 10MB"
              }
              error={error}
            />
          </div>
        </WarningMsg>
      </UploadContainer>
    );
  };

  const renderFlexColumn = () => {
    return (
      <div style={{ height: "100%", width: "550px" }}>
        <ProfileInputHeading
          isPaddingNotRequired
          isStar={false}
          name="Image Upload"
          info="Upload images related to your product/ service offerings"
        />
        <FlexColumn>
          {renderImageContainer()}
        </FlexColumn>
      </div>
    );
  };
  return (
    <Container>
      {renderFlexColumn()}

      {imgList.length && (
        <ImageContainerWrapper>
          <Heading>Gallery</Heading>
          <ImageListContainer>
            {imgList.map((item, index) => {
              return (
                <div style={{ marginTop: "5px" }}>
                  <ImgCardTag
                    data={item}
                    closable={props.buttonState}
                    onClose={() => {
                      removeImg(item.imageName, index);
                    }}
                  />
                </div>
              );
            })}
          </ImageListContainer>
        </ImageContainerWrapper>
      )}
    </Container>
  );
};

export default NewUploadImage;
