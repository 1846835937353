import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Modal } from "antd";
import CollapseComponent from "components/Collapse";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import {
  experienceInfoGet,
  experienceInfoOnboardingCreate,
  experienceInfoUpdate
} from "reducers/experienceInfo";
import {
  profileLayoutError,
  resetProfileCompletionStatus,
  showEditReducer,
  toggleIsPageEdited
} from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import openNotification from "constants/antdNotification";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import { updateProfileFlagToTrue } from "shared/handlers/updateProfiileFlag.handler";
import {
  FormikForm,
  AddMore,
  ContentInputs,
  Div,
  FormWrapper
} from "./Experience.styles";
import { ButtonWrapper } from "Profile/Profile.styles";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { VerificationStatus } from "Profile/enums";
import { HEAP_DATA_ATTRIBUTES, HEAP_DATA_TRACKING_ID } from "constants/heapAnalytics";
import { Box, Button, Flex, Typography, Modal as VenwizUIModal } from "venwiz-ui";
import { isRequestForReviewEmailValid } from "./constants.experience";
import { RiCloseFill } from "react-icons/ri";
import { colors } from "Theme";
import { useMediaQuery } from "hooks/useMediaQuery.hook";
import { DEVICE } from "ui-constants/mediaQueries.constants";
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { AppConfig } from "ui-constants";
import { VISUALLY_EMPTY_STRING } from "components/CustomReactQuillEditor/reqctQuill.constants";
import { removeEmptyHTMLElementsFromString } from "components/CustomReactQuillEditor/reactQuill.helper";

interface ExperienceProps {
  numberOfTimesCustomButtonIsClicked: number;
}

const ExperienceNew = (props: ExperienceProps) => {
  const isFirstError = useRef<boolean>(true);
  const history = useHistory();
  const userEmail = (() => {
    let USER: { email?: string } = {};
    const localUserData = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData);
    USER = !localUserData
      ? SessionStorageService.get(AppConfig.STORAGE_KEYS.userData) || {}
      : localUserData || {};
    return USER?.email ? USER?.email : "";
  })();
  const [isDataSaving, setIsDataSaving] = useState(false);
  const [showReviewSuccessDesriptionInSuccessModal, setShowReviewSuccessDesriptionInSuccessModal] = useState(false);
  const [showExperienceAndReviewSuccesModal, setShowExperienceAndReviewSuccesModal] = useState(false);
  let submit = [];
  const userInformation = {
    experienceId: "",
    experience: [
      {
        projectName: "",
        clientCompanyName: "",
        clientIndustry: "",
        city: "",
        projStartDate: "MM-YYYY",
        projEndDate: "MM-YYYY",
        description: "",
        descriptionPlainText: "",
        document: {},
        verificationStatus: VerificationStatus?.inProgress,
        /** Do not send review object 
         * when user has not added request for review details */
        // review: {
        //   clientDetails: {
        //     company: "",
        //     name: "",
        //     email: "",
        //     designation: "",
        //   },
        //   note: "",
        // },
      }
    ]
  };

  const initialValue = {
    experienceId: "",
    experience: [
      {
        projectName: "",
        clientCompanyName: "",
        companyProfile: "",
        clientIndustry: "",
        city: "",
        projStartDate: "",
        projEndDate: "",
        description: "",
        descriptionPlainText: "",
        document: {},
        verificationStatus: VerificationStatus?.inProgress,
        /** Do not send review object 
         * when user has not added request for review details */
        // review: {
        //   clientDetails: {
        //     company: "",
        //     name: "",
        //     email: "",
        //     designation: "",
        //   },
        //   note: "",
        // }
      }
    ]
  };

  const experienceInfoSelector = useSelector(
    (state: any) => state.experienceInfo
  );
  const [experienceInfoState, setExperienceInfoState] = useState(
    userInformation
  );
  const { experienceInfoData, tempData } = experienceInfoSelector; //reduce data
  const [isNewRequestForReviewAdded, setIsNewRequestForReviewAdded] = useState(false);
  const [showValidations, setShowValidation] = useState(false);
  const [saveState, setSaveState] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState(1);
  const [listOfExpandedAccordions, setListOfExpandedAccordions] = useState([1]);
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData } = userInfoState;
  const gstn = getGstn();
  const profileCompletionData = useSelector(
    (state: any) => state.profileCreation
  ).profileCompletionStatus;
  const isOnBoarding = window.location.href.includes("onboarding");
  const isScreenWidthGreaterThan640px = useMediaQuery(DEVICE.sm);

  const handleAddNewExperience = () => {
    setExperienceInfoState({
      ...experienceInfoState,
      experience: [
        ...experienceInfoState.experience,
        ...userInformation.experience
      ]
    });

    const NthItem = experienceInfoState?.experience?.length > 0 
      ? experienceInfoState?.experience?.length + 1 : 1
    /** open newest Experience accordion */
    setListOfExpandedAccordions([NthItem]);
  };

  /**
   * When custombutton from parent component is clicked, 
   * this useEffect will be called - which will adds a new experience
   */
  useEffect(() => {
    if (props?.numberOfTimesCustomButtonIsClicked) {
      handleAddNewExperience();
    }
  }, [props?.numberOfTimesCustomButtonIsClicked]);

  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false);
  }, []);

  useEffect(() => {
    if (
      isOnBoarding &&
      profileCompletionData.hasOwnProperty("profileCompleteness")
    ) {
      if (profileCompletionData?.profileCompleteness) {
        history.push("/userinfo");
      } else {
        if (profileCompletionData?.companyinfoCompleteness) {
          if (profileCompletionData?.capabilitiesCompleteness) {
            if (profileCompletionData?.experienceCompleteness) {
              dispatch(experienceInfoGet({}));
            }
          } else {
            history.push("/onboarding/capabilities");
          }
        } else {
          history.push("/onboarding/companyInfo");
        }
      }
    } else {
      dispatch(experienceInfoGet({}));
    }

    if (!isOnBoarding) {
      dispatch(experienceInfoGet({}));
    }
  }, [profileCompletionData]);

  useEffect(() => {
    if (
      experienceInfoData !== undefined &&
      experienceInfoData?.experience?.length > 0
    ) {
      setExperienceInfoState(experienceInfoData);
      if (isOnBoarding) {
        showEditReducer(dispatch, (buttonState.editButton = true));
      }
      /** open newest Experience accordion */
      setListOfExpandedAccordions([experienceInfoData?.experience?.length]);
    } else {
      showEditReducer(dispatch, (buttonState.editButton = false));
    }
  }, [experienceInfoData?.experience]);

  /**
   * Watch all changes in experienceInfoState - if user adds a new request for review:
   * then change the button text to "SAVE CHANGES & REQUEST REVIEW"
   */
  useEffect(() => {
    let isAnyRequestForReviewPresent = false;
    experienceInfoState?.experience?.forEach((item, index) => {
      if ((item as any)?.review && !(item as any)?.review?.reviewStatus) {
        isAnyRequestForReviewPresent = true;
      }
    });
    setIsNewRequestForReviewAdded(isAnyRequestForReviewPresent);
  });
  
  /////////
  /**
   * below code - causes multiple re-renders
   * TODO: remove this code after QA testing
   */
  // useEffect(() => {
  //   if (tempData?.length) {
  //     setExperienceInfoState({
  //       experienceId: experienceInfoData?.experienceId,
  //       experience: tempData
  //     });
  //     console.log("Experience - tempData");
  //   }
  // }, [tempData]);
  /////////

  const whetherToShowRequestReviewDescriptionInSuccessModal = (expInfoState: typeof experienceInfoState) => {
    /**
     * checks if a review is newly added for any experience,
     * if yes, then show the description for review-request-successfully-saved in success modal
     */
    let showSuccessModal = false;
    expInfoState?.experience?.forEach((item, index) => {
      if (!(item as any)?.review?.reviewStatus && (item as any)?.review?.clientDetails?.email) {
        showSuccessModal = true;
      } 
    });

    showSuccessModal 
      ? setShowReviewSuccessDesriptionInSuccessModal(true)
      : setShowReviewSuccessDesriptionInSuccessModal(false);
    setShowExperienceAndReviewSuccesModal(true);
  };

  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton, isPageEdited } = buttonState;
  const dispatch = useDispatch();

  const handleClick = type => {
    if (type === "save") {
      setIsDataSaving(true);
      if (experienceInfoState?.experienceId?.length) {
        delete experienceInfoState.experience["flag"];
        dispatch(
          experienceInfoUpdate({
            gstn,
            experienceId: experienceInfoState.experienceId,
            experience: experienceInfoState.experience
          })
        ).then(
          res => {
            setIsDataSaving(false);
            if (isOnBoarding) {
              history.push("/onboarding/compliance")
            } else {
              // notificationFunc("success");
              whetherToShowRequestReviewDescriptionInSuccessModal(experienceInfoState);
            }
            /** hide validation error messages when successfully saved */
            setShowValidation(false);
            dispatch(dashboardPercentageGet({}));
            dispatch(experienceInfoGet({}));
            toggleIsPageEdited(dispatch, false);
          },
          err => {
            setIsDataSaving(false);
            const MESSAGE = err?.message && typeof err?.message === "string" ? err?.message : "Please fill all the required data";
            openNotification(MESSAGE);
          }
        );
      } else {
        setUpdateStatus(false);
        dispatch(
          experienceInfoOnboardingCreate({
            gstn,
            experience: experienceInfoState.experience
          })
        ).then(() => {
          resetProfileCompletionStatus(dispatch);
          if (isOnBoarding) {
            updateProfileFlagToTrue("isExperiencesCompleted");
            history.push("/onboarding/compliance");
          }
          setIsDataSaving(false);
          toggleIsPageEdited(dispatch, false);
          whetherToShowRequestReviewDescriptionInSuccessModal(experienceInfoState);
        }).catch(() => {
          setIsDataSaving(false);
        });
      }

      setShowValidation(true);
    }
  };

  const handleDeleteProject = index => {
    const experiences = [...experienceInfoState.experience];
    experiences.splice(index, 1);
    setExperienceInfoState({
      ...experienceInfoState,
      experience: [...experiences]
    });
    toggleIsPageEdited(dispatch, true);
  };

  const setUserData = () => {
    if (Object.keys(experienceInfoData)?.length) {
      setExperienceInfoState(experienceInfoData);
    }
  };

  const warning = () => {
    Modal.warning({
      title: "Warning",
      content:
        "Please fill all the required field in each of the experience card in order to save your data ",
      centered: true
    });
  };

  const validate = Yup.object({});

  //To Decide which are the mandatory field in project scope based on screen size
  const projectValidation = (item, index) => {
    const IS_PROJECT_DESC_ERROR = !item.description 
    || item.description === VISUALLY_EMPTY_STRING
    || removeEmptyHTMLElementsFromString(item.description) === "";
  
    const IS_VALID_PROJECT_DESC = !IS_PROJECT_DESC_ERROR;
    
    if (checkForMobileScreen()) {
      return IS_VALID_PROJECT_DESC || item?.document?.documentFile?.fileName;
      // return item.description.length || item?.document?.documentFile?.fileName;
    } else {
      return IS_VALID_PROJECT_DESC;
      // return item.description.length;
    }
  };

  /**
   * This function is used to validate the request for review form
   * present in each experience section
   */
  const isRequestForReviewFormValid = (item, index) => {
    if (!item?.review) {
      /** When review object is not present */
      return true;
    }

    const NOTE = item?.review?.note || ""; // not mandatory
    const EMAIL = item?.review?.clientDetails?.email || "";
    const CLIENT_USER_NAME = item?.review?.clientDetails?.name || "";
    const DESIGNATION = item?.review?.clientDetails?.designation || "";

    if (
      /** When all fields are empty */
      NOTE === "" && EMAIL === "" && CLIENT_USER_NAME === "" && DESIGNATION === ""
      /** When all fields are valid */
      || CLIENT_USER_NAME && DESIGNATION && EMAIL && isRequestForReviewEmailValid(EMAIL)
      /** When all fields are valid & Note is empty */
      || NOTE && CLIENT_USER_NAME && DESIGNATION && EMAIL && isRequestForReviewEmailValid(EMAIL)
    ) {
      /** VALID FORM */
      return true;
    } else {
      /** IN_VALID FORM */
      return false;
    }
  };

  const validationfunc = () => {
    submit = [];
    submit = experienceInfoState.experience.map((item, index) => {
      if (
        item.city.trim() !== "" &&
        item.clientCompanyName.trim() !== "" &&
        projectValidation(item, index) &&
        isRequestForReviewFormValid(item, index)
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (submit.includes(false)) {
      // warning();
      profileLayoutError(dispatch, true);
    } else {
      handleClick("save");
      // tempData(dispatch, null);
      profileLayoutError(dispatch, false);
    }
  };

  return (
    <>
      <Box width={{
        default: "100%",
        xxs: "100%",
        sm: "75vw",
      }}>
        <UserInfo.Provider value={[experienceInfoState, setExperienceInfoState]}>
          <Formik
            initialValues={initialValue}
            validationSchema={validate}
            onSubmit={(values, actions) => {}}
          >
            {formik => (
              <FormikForm>
                <ContentInputs>
                  <FormWrapper
                    style={{
                      gap: "20px",
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    {experienceInfoState?.experience.map((item, index) => {
                      item.clientCompanyName = item?.clientCompanyName || (item as any)?.companyProfile || "";

                      return (
                        <Div>
                          <CollapseComponent
                            listOfExpandedAccordions={listOfExpandedAccordions}
                            setListOfExpandedAccordions={setListOfExpandedAccordions}
                            showNewAccordionLayout
                            newExperienceLayout
                            handleValidation={e => {}}
                            formik={formik}
                            headerName={
                              item?.projectName || `Project ${index + 1}`
                            }
                            subHeader={undefined}
                            usedFor="experience"
                            defaultActiveKey={defaultActiveKey}
                            buttonType={!editButton}
                            data={{ item, index }}
                            validationState={showValidations}
                            handleAlertMessage={e => {}}
                            showDeleteOption={true}
                            handleDeleteProject={handleDeleteProject}
                            isFirstError={isFirstError}
                            projectLength={
                              experienceInfoState?.experience?.length
                            }
                          />
                        </Div>
                      );
                    })}
                  </FormWrapper>
                </ContentInputs>
              </FormikForm>
            )}
          </Formik>
          <ButtonWrapper $padding={"19px 0px 20px 0px"} style={{ width: "100%" }}>
            <ProfileCreationButtons
              withCustomStyles
              dataAttribute={{ 
                [HEAP_DATA_TRACKING_ID]: isNewRequestForReviewAdded
                  ? HEAP_DATA_ATTRIBUTES.EXPERIENCE.EXP_AND_REQUEST_REVIEW_SAVE_AND_CONTINUE
                  : HEAP_DATA_ATTRIBUTES.EXPERIENCE.SAVE_AND_CONTINUE
              }}
              isloading={isDataSaving}
              buttonText={isNewRequestForReviewAdded
                ? "SAVE CHANGES & REQUEST REVIEW"
                : isOnBoarding 
                  ? "SAVE & CONTINUE" 
                  : "SAVE CHANGES"
              }
              usedAt={"onboarding"}
              state={!isPageEdited}
              pagination={true}
              pageNumber={4}
              handleStateChange={value => {
                isFirstError.current = true;
                if (value === "cancel") {
                  setUserData();  
                } else {
                  if (value === "save") {
                    validationfunc();
                    setShowValidation(true);
                  } else {
                    handleClick(value);
                  }
                }
              }}
              style={{
                minWidth: isScreenWidthGreaterThan640px ? "210px" : "100vw",
                height: "40px",
                borderRadius: isScreenWidthGreaterThan640px ? "4px" : "0px",
              }}
            ></ProfileCreationButtons>
          </ButtonWrapper>
        </UserInfo.Provider>
      </Box>

      {/* Modal - Request for Review Success Modal */}
      {showExperienceAndReviewSuccesModal && (
        <VenwizUIModal
          top={{ default: "35vh", sm: "0" }}
          left={"0"}
          right={"0"}
          bottom={"0"}
          padding={"24px"}
          maxWidth={"310px"}
          show={showExperienceAndReviewSuccesModal}
          handleClose={() => setShowExperienceAndReviewSuccesModal(false)}
          closeIcon={<></>}
          modalHeader={<></>}
          modalBody={
            <Flex
              gap={"24px"}
              flexDirection={"column"}
            >
              <Typography
                fontSize={"20px"}
                fontWeight={700}
                color={"#011759"}
              >
                {`Experience${experienceInfoState?.experienceId?.length > 1 ? "s" : ""} have been saved successfully`}
              </Typography>
              {showReviewSuccessDesriptionInSuccessModal && (
                <Typography
                  fontSize={"16px"}
                  fontWeight={500}
                  color={"#011759"}
                >
                  Your request for the review has been shared with the Client
                </Typography>
                )}
              <Button
                colorSchema="blue"
                variant="primary"
                height={"42px"}
                width={"100%"}
                color={"#fff"}
                style={{ background: colors.blue }}
                onClick={() => setShowExperienceAndReviewSuccesModal(false)}
              >
                Got It
              </Button>
            </Flex>
          }
        />
      )}
    </>
  );
};

export default ExperienceNew;
