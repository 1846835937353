import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
export const EXPERIENCE_INFO_REQUESTED = "experience/EXPERIENCE_INFO_REQUESTED";
export const EXPERIENCE_INFO_SUCCESS = "experience/EXPERIENCE_INFO_SUCCESS";
export const EXPERIENCE_INFO_FAILURE = "experience/EXPERIENCE_INFO_FAILURE";
export const EXPERIENCE_CREATE_SUCCESS = "experience/EXPERIENCE_CREATE_SUCCESS";
export const EXPERIENCE_UPDATE_FAILURE = "experience/EXPERIENCE_UPDATE_FAILURE";
export const EXPERIENCE_UPDATE_SUCCESS = "experience/EXPERIENCE_UPDATE_SUCCESS";
export const EXPERIENCE_UPDATE_REQUESTED =
  "experience/EXPERIENCE_UPDATE_REQUESTED";

export const EXPERIENCE_UPDATE_TUTORIAL_VIEWED_REQUESTED = "experience/EXPERIENCE_UPDATE_TUTORIAL_VIEWED_REQUESTED";
export const EXPERIENCE_UPDATE_TUTORIAL_VIEWED_SUCCESS = "experience/EXPERIENCE_UPDATE_TUTORIAL_VIEWED_SUCCESS";
export const EXPERIENCE_UPDATE_TUTORIAL_VIEWED_FAILURE = "experience/EXPERIENCE_UPDATE_TUTORIAL_VIEWED_FAILURE";

export const EXPERIENCE_TEMP_DATA = "experience/EXPERIENCE_TEMP_DATA";
const getGstn =
  SessionStorageService.get("gstNum") || LocalStorageService.get("gstNum");
export const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userData: undefined,
  experienceInfoData: undefined,
  experienceInfoCreateData: undefined,

  updateLoading: false,
  updateLoaded: false,
  updateError: false,
  tempData: null,
};

const USER = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)
  || SessionStorageService.get(AppConfig.STORAGE_KEYS.userData)
  || {};
const userEmail = USER?.email ? USER?.email : "";

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPERIENCE_INFO_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EXPERIENCE_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        experienceInfoData: {
          ...action.result,
          experienceTutorialViewedByUsers: action?.result?.experienceTutorialViewedByUsers || [],
        },
      };

    case EXPERIENCE_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    case EXPERIENCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        experienceInfoCreateData: action.result,
      };
    case EXPERIENCE_UPDATE_REQUESTED:
    case EXPERIENCE_UPDATE_TUTORIAL_VIEWED_REQUESTED:
      return {
        ...state,
        updateLoading: true,
      };

    case EXPERIENCE_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateLoaded: true,
        updateError: false,
        experienceInfoCreateData: action.result,
      };

    case EXPERIENCE_UPDATE_FAILURE:
    case EXPERIENCE_UPDATE_TUTORIAL_VIEWED_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateLoaded: true,
        updateError: true,
        errorData: action.error.message,
      };

    case EXPERIENCE_UPDATE_TUTORIAL_VIEWED_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateLoaded: true,
        updateError: false,
        experienceInfoData: {
          ...state.experienceInfoData,
          experienceTutorialViewedByUsers: action?.result?.experienceTutorialViewedByUsers || [],
        },
      };

    case EXPERIENCE_TEMP_DATA:
      return {
        ...state,
        tempData: action.payload,
      };
    default:
      return state;
  }
};
export const experienceInfoGet = data => ({
  types: [
    EXPERIENCE_INFO_REQUESTED,
    EXPERIENCE_INFO_SUCCESS,
    EXPERIENCE_INFO_FAILURE,
  ],
  promise: client => client.get(`experienceinfo_get_data`, {}),
});

export const experienceInfoCreate = data => ({
  types: [
    EXPERIENCE_INFO_REQUESTED,
    EXPERIENCE_CREATE_SUCCESS,
    EXPERIENCE_INFO_FAILURE,
  ],
  promise: client => client.post("experienceinfo_create_data", { data }),
});

export const experienceInfoOnboardingCreate = data => ({
  types: [
    EXPERIENCE_INFO_REQUESTED,
    EXPERIENCE_CREATE_SUCCESS,
    EXPERIENCE_INFO_FAILURE,
  ],
  promise: client => client.post("experienceinfo_create_onboarding_data", { data }),
});
export const experienceInfoDelete = data => ({
  types: [
    EXPERIENCE_INFO_REQUESTED,
    EXPERIENCE_INFO_SUCCESS,
    EXPERIENCE_INFO_FAILURE,
  ],
  promise: client => client.del("experienceinfo_delete_data", { data }),
});

export const experienceInfoUpdate = data => ({
  types: [
    EXPERIENCE_UPDATE_REQUESTED,
    EXPERIENCE_UPDATE_SUCCESS,
    EXPERIENCE_UPDATE_FAILURE,
  ],
  promise: client =>
    client.post("experienceinfo_update_data", {
      data,
    }),
});

export const updateExperienceTutorialViewedForAVendor = data => ({
  types: [
    EXPERIENCE_UPDATE_TUTORIAL_VIEWED_REQUESTED,
    EXPERIENCE_UPDATE_TUTORIAL_VIEWED_SUCCESS,
    EXPERIENCE_UPDATE_TUTORIAL_VIEWED_FAILURE,
  ],
  promise: client => client.post("update_experience_tutorial_viewed_for_a_vendor", { data }),
});

export const tempData = (dispatch, bool) => {
  dispatch({
    type: EXPERIENCE_TEMP_DATA,
    payload: bool,
  });
};

export const checkIfUserExists = data => {
  return ({
    types: [
      EXPERIENCE_INFO_REQUESTED,
      EXPERIENCE_INFO_SUCCESS,
      EXPERIENCE_INFO_FAILURE,
    ],
    promise: client => client.post(`check_if_user_exists`, { data }),
  })
};

export const getExperiencesOpen = data => {
  return ({
    types: [
      EXPERIENCE_INFO_REQUESTED,
      EXPERIENCE_INFO_SUCCESS,
      EXPERIENCE_INFO_FAILURE,
    ],
    promise: client => client.post(`open_experiences`, { data }),
  })
};

export const openExperienceInfoUpdate = data => ({
  types: [
    EXPERIENCE_UPDATE_REQUESTED,
    EXPERIENCE_UPDATE_SUCCESS,
    EXPERIENCE_UPDATE_FAILURE,
  ],
  promise: client =>
    client.post("experienceinfo_update_data", {
      data,
    }),
});