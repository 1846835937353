import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import MultiPageViewer from "./Component/MultiPageViewer";
import { Button } from "components/Button";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  align-items: center;
`;
const PdfWrapper = styled.div`
  height: 80%;
  overflow-y: scroll;
  margin-bottom: 10px;
`;
const ButtonDiv = styled(Button)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
  height: 60px;
`;
function MarketingBrochure() {
  const marketingSuiteState = useSelector((state: any) => state.marketingSuite);
  const { marketingBrochureCard } = marketingSuiteState;
  return (
    <Container>
      <PdfWrapper>
        <MultiPageViewer
          pdf={marketingBrochureCard?.data?.url}
        ></MultiPageViewer>
      </PdfWrapper>
      <ButtonDiv
        onClick={() => {}}
        variant="secondary"
        size="middle"
        style={{ width: "450px", padding: "20px 105px" }}
      >
        <a href={marketingBrochureCard?.data?.url} download>
          Download brochure
        </a>
      </ButtonDiv>
    </Container>
  );
}

export default MarketingBrochure;
