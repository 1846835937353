import React from "react";
import styled from "styled-components";
import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";

interface IProfileInputHeadingProps {
  name: string;
  info?: string;
  position?: "right" | "left" | "top" | "bottom";
  isStar: boolean;
  isPaddingNotRequired?: boolean;
  id?: string;
  responsiveScreenName?:string
}
const Container = styled.div<any>`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding-top: ${props => (props.paddingNotRequired ? "0px" : "20px")};
`;
export const Name = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.1em;
  color: #011759;
  opacity: 0.7;
  margin-right: 7px;
  #mobileLabel{
    display:none;
  }
  @media only screen and (max-width: 640px) {
    opacity: 1;
    font-weight: 600;
    #mobileLabel{
      display:inline;
    }
    #desktopLabel{
    display:none;
    }
  }
`;
const InfoTooltip = styled(Tooltip)``;
const Star = styled.span`
  color: red;
  margin-right: 5px;
`;

const TooltipDiv = styled(Tooltip)`
  cursor: pointer;
`;

const   ProfileInputHeading = (props: IProfileInputHeadingProps) => {
  const getPlacementDetails=()=>{
    if(checkForMobileScreen())
    {
      return "bottom"
    }
    return  "right"
  }
  return (
    <Container paddingNotRequired={props.isPaddingNotRequired} id={props?.id}>
      <Name>
        {props.isStar && <Star>*</Star>}
        <span id="desktopLabel">{props.name}</span>
        <span id="mobileLabel">{props.responsiveScreenName || props.name}</span>
      </Name>
      <TooltipDiv
        color="#ffffff"
        placement={props.position ? props.position :getPlacementDetails()}
        title={props.info}
        overlayInnerStyle={{
          fontSize: "12px",
          color: "#011759",
          padding: checkForMobileScreen()?"12px 14px":"18px 20px",
          borderRadius: "12px",
          width: "max-content",
          maxWidth: checkForMobileScreen()?"calc(100vw - 120px)":"416px",
          whiteSpace: "normal",
        }}
      >
        {props.info && <InfoCircleTwoTone style={{ fontSize: "14px" }} />}
      </TooltipDiv>
    </Container>
  );
};

export default ProfileInputHeading;
