import React from "react";
import {
  RowDiv,
  ColDiv,
  RightContainer,
  LeftContainer,
  RightCarouselWrapper,
  RightLogoWrapper,
  Logo,
  CarouselBase,
  CarouselText,
  FooterWrapper,
  FooterText,
  Spinner,
} from "./LogRegLayout.styles";
import { resetAuthData } from "reducers/auth";
import { mockData } from "constants/mockData";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { AppConfig } from "ui-constants";

interface Props {
  children: React.ReactNode;
}

const LogRegLayout = (props: Props) => {
  const routeData = AppConfig.authRouteData;
  const authState = useSelector((state: any) => state.auth);
  const { isLoading } = authState;
  const pathname = window.location.pathname === "/login" ? "register" : "login";
  const history = useHistory();
  const dispatch = useDispatch();
  const handleRoute = () => {
    if (routeData[pathname].name.toLowerCase() === "login") {
      resetAuthData(dispatch);
    }
    history.push(routeData[pathname].link);
  };
  return (
    <>
      <RowDiv>
        <ColDiv $mobScreenMaxHeight={"67px"} $mobScreenBgColor={"rgb(1,23,89)"}>
          <RightContainer>
            <RightLogoWrapper>
              <Logo />
              <FooterWrapper>
                <FooterText $weight={500} $marginLeft={"0px"}>
                  {routeData[pathname].text}
                </FooterText>
                <FooterText onClick={handleRoute} $weight={800} $marginLeft={"3px"} $cursor={"pointer"}>
                  {routeData[pathname].name}
                </FooterText>
              </FooterWrapper>
            </RightLogoWrapper>
            <RightCarouselWrapper>
              <CarouselBase autoplay>
                {mockData.carosuel.map((item) => (
                  <CarouselText key={item.id}>{item.text}</CarouselText>
                ))}
              </CarouselBase>
            </RightCarouselWrapper>
          </RightContainer>
        </ColDiv>
        <ColDiv
          $mobScreenHeight={"calc(100vh - 67px)"}
          $mobScreenBorderRadius={"20px 20px 0px 0px"}
          $mobScreenMarginTop={"67px"}
          $mobScreenPosition={"fixed"}
          $mobScreenBgColor={"#FFFFFF"}
        >
          {isLoading && (
            <Spinner>
              <Spin style={{ position: "absolute", top: "50vh", left: "45%" }} size="large" tip="Loading..." />
            </Spinner>
          )}
          <LeftContainer>{props.children}</LeftContainer>
        </ColDiv>
      </RowDiv>
    </>
  );
};

export default LogRegLayout;
