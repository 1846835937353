import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {  referenceInfoGet } from "reducers/referenceInfo";
import { documentInfoGet } from "reducers/documentInfo";

interface cardInfoProp {
  usedAt: "progressView" | "progressForm";
  percentValue?: number;
  title?: string;
  onClick?: any;
  imgSrc?: string;
  key?: number;
}

const Card = styled.div<cardInfoProp>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.usedAt == "progressView" ? "100%" : "355px")};
  height: ${props => (props.usedAt == "progressView" ? "50px" : "40px")};
  background-color: ${props =>
    `${props.theme.card.types.referalCard.backgroundColor}`};
  border-radius: ${props =>
    props.usedAt == "progressForm" ? "8px 0px 0px 8px" : "0px"};
  color: black;
`;

const LabelContainer = styled.div`
  display: flex;
  width: auto;
  justify-content: space-between;
`;
const LabelSignal = styled.img<cardInfoProp>`
  opacity: ${props => (props.percentValue === 100 ? "0" : (props.title==="Documents" || props.title==="References"?"0":"1"))};
  width: auto;
  height: auto;
  padding-right: 12px;
`;
LabelSignal.defaultProps = {
  src: "images/circle.svg",
};
const LabelIcon = styled.img`
  width: auto;
  height: auto;
  padding-right: 12px;
`;
// LabelIcon.defaultProps = {
//   src: "images/Note.svg",
// };
const CompletedIcon = styled.img`
  width: auto;
  height: auto;
`;
CompletedIcon.defaultProps = {
  src: "images/vector.svg",
};

const LabelName = styled.div`
  width: auto;
  height: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 170%;
`;

const StatusContainer = styled.div`
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  text-align: right;
  color: #ff1f47;
  padding-left: 12px;
`;

const CardInfo = ({ onClick, key, ...rest }: cardInfoProp) => {
  const dispatch=useDispatch();
  const { percentValue, title, imgSrc } = rest;
  const documentState = useSelector((state: any) => state.documentInfo);
  const { documentInfoGetData } = documentState;
  const referenceState = useSelector((state: any) => state.referenceInfo);
  const { referenceInfoGetdata } = referenceState;

  const document = documentInfoGetData?.document;
  const references = referenceInfoGetdata?.reference;

  const handlePercentageCalc = percentValue => {
    if (title.toLowerCase() === "documents") {
  
      return document?.length ? <CompletedIcon /> : "";
    }

    if (title.toLowerCase() === "references") {
 
      return references?.length ? <CompletedIcon /> : "";
    }
    return `${percentValue}%`;
  };

  return (
    <>
      <Card {...rest} onClick={onClick}>
        <LabelContainer>
          <LabelSignal {...rest} />
          <LabelIcon src={imgSrc} />
          <LabelName>{title}</LabelName>
        </LabelContainer>
        <StatusContainer>
          {percentValue <= 99 ? (
            handlePercentageCalc(percentValue)
          ) : (
            <CompletedIcon />
          )}
        </StatusContainer>
      </Card>
    </>
  );
};

export default CardInfo;
