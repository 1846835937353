import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { number } from "yup";
import { Spin } from "antd";
import { Modal, Popover } from "antd";
import { Button } from "components/Button";
import { Label } from "components/Label";
import CardWithoutEdit from "components/Card/CardWithoutEdit/card";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";
import InputWithHeaderForEdit from "components/Input/InputBoxWithEdit/inputHeader";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import {
  manpowerStrengthCreate,
  manpowerStrengthGet,
  manpowerStrengthPost,
  peopleDelete,
  peopleGet,
  peoplePost,
} from "reducers/peopleInfo";
import { showEditReducer } from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import SessionStorageService from "services/SessionStorageService";
import { peopleInformationLocalizationData } from "ui-constants/localizationData";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";

const ContentInputs = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;
const CardContainer = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;
const CardHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const PeopleInfoContainer = styled.div`
  display: ${props =>
    `${props.theme.card.types["peopleInfoContainer"].display}`};
  flex-direction: ${props =>
    `${props.theme.card.types["peopleInfoContainer"].flexDirection}`};
  margin-top: ${props =>
    `${props.theme.card.types["peopleInfoContainer"].marginTop}`};
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentHeading = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: ${props => `${props.theme.colors.blue}`};
`;

const ContentSubHeading = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #011759;
`;

const InputFlexWrapper = styled.div`
  padding: 10px 10px 0px 0;
`;

const TextboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const AddKeyButtonWrap = styled.div`
  /* border-top: 1px solid #E5E5E5;*/
  padding: 19px 0px 40px 0px;
`;

export const FormWrapper = styled.div`
  width: 840px;
  @media only screen and (max-width: 640px) {
    width: 100vw;
    padding: 0px 16px;
  }
`;

const WrapperDiv = styled.div`
  margin-bottom: 20px;
`;
const Div = styled.div`
  margin-bottom: 35px;
`;
const HeaderDiv = styled.div`
  margin-bottom: 20px;
`;
const BasicInformationMeassage = styled.div`
  padding: 30px 46px 30px 30px;
  border-radius: 12px;
  height: 111px;
  width: 100%;
  background-color: ${props => `${props.theme.colors.lightGray}`};
`;
const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
`;

const BtnMargin = styled.div`
  margin-left: 30px;
`;

const PeopleInfoTab = () => {
  const history = useHistory();
  const getGstn = SessionStorageService.get("gstNum"); //rewrite
  let validateData = false;
  const dispatch = useDispatch();
  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton } = buttonState;
  const peopleInfoSelector = useSelector((state: any) => state.people);
  const {
    peopleData,
    peopleGetData,
    loading,
    loaded,
    error,
    peopleDeletedData,
    manpowerGetData,
    manpowerUpdateData,
  } = peopleInfoSelector;
  const [saveState, setSaveState] = useState(false);
  const [userInfo, setUserInfo] = useState(undefined);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [keyManagementState, setKeyManagementState] = useState({
    gstn: getGstn,
    id: 0,
    vendorId: "",
    firstName: "",
    lastName: "",
    designation: "",
    qualification: "",
    experience: "",
  });
  const [manStrengthState, setManStrengthState] = useState({
    id: 0,
    manPowerFullTime: number || "",
    manPowerPartTime: number || "",
    totalManPower: number || "",
    totalManPowerManufacturing: number || "",
    totalManPowerService: number || "",
  });
  const warning = () => {
    Modal.warning({
      title: "Warning",
      content: "Please fill all the required field in order to save your data ",
      centered: true,
    });
  };

  useEffect(() => {
    if (
      peopleData !== null &&
      loading == false &&
      loaded == true &&
      error == false
    ) {
      setUserInfo({
        keyManagement: [],
        manStrength: [],
      });
    }
  }, [loading, loaded, error]);

  useEffect(() => {
    if (peopleGetData !== undefined) {
      peopleData.push(peopleGetData);
    }
  }, [peopleGetData]);

  useEffect(() => {
    dispatch(peopleGet({}));
    dispatch(manpowerStrengthGet({}));
  }, []);

  useEffect(() => {
    if (
      peopleData &&
      peopleData.length < 1 &&
      manpowerGetData &&
      Object.keys(manpowerGetData).length < 1
    ) {
      showEditReducer(dispatch, (buttonState.editButton = false));
    } else {
      buttonState.editButton = true;
    }
  }, [manpowerGetData]);

  useEffect(() => {
    if (manpowerGetData) {
      setManStrengthState({
        id: 0,
        manPowerFullTime: manpowerGetData.fullTime,
        manPowerPartTime: manpowerGetData.partTime,
        totalManPower: manpowerGetData.total,
        totalManPowerManufacturing: manpowerGetData.inManufacturing,
        totalManPowerService: manpowerGetData.inServicing,
      });
    }
  }, [manpowerGetData]);

  useEffect(() => {
    if (peopleDeletedData) {
      dispatch(peopleGet({}));
    }
  }, [peopleDeletedData]);

  useEffect(() => {
    if (manpowerUpdateData) {
      dispatch(peopleGet({}));
      dispatch(manpowerStrengthGet({}));
    }
  }, [manpowerUpdateData]);

  const handleAddKey = () => {
    dispatch(
      peoplePost({
        gstn: keyManagementState.gstn,
        vendorId: keyManagementState.vendorId,
        firstName: keyManagementState.firstName,
        lastName: keyManagementState.lastName,
        qualification: keyManagementState.qualification,
        designation: keyManagementState.designation,
        experience: keyManagementState.experience,
      })
    );
    setKeyManagementState({
      ...keyManagementState,
      firstName: "",
      lastName: "",
      designation: "",
      qualification: "",
      experience: "",
    });
  };

  const handleClick = value => {
    if (value === "save") {
      setSaveState(true);
      if (validateData) {
        showEditReducer(dispatch, !editButton);
        if (manpowerGetData && manpowerGetData.peopleStrengthId) {
          dispatch(
            manpowerStrengthPost({
              gstn: getGstn,
              vendorId: "",
              peopleStrengthId: manpowerGetData.peopleStrengthId,
              total: manStrengthState.totalManPower,
              fullTime: manStrengthState.manPowerFullTime,
              partTime: manStrengthState.manPowerPartTime,
              inManufacturing: manStrengthState.totalManPowerManufacturing,
              inServicing: manStrengthState.totalManPowerService,
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        } else {
          dispatch(
            manpowerStrengthCreate({
              gstn: getGstn,
              vendorId: "",
              total: manStrengthState.totalManPower,
              fullTime: manStrengthState.manPowerFullTime,
              partTime: manStrengthState.manPowerPartTime,
              inManufacturing: manStrengthState.totalManPowerManufacturing,
              inServicing: manStrengthState.totalManPowerService,
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        }
      } else {
        warning();
      }
    }
    if (value === "edit") {
      showEditReducer(dispatch, !editButton);
    }
  };

  const handleKeyManagementCard = (value: any) => {
    dispatch(
      peopleDelete({
        peopleKeyManagementId: value,
      })
    );
  };

  const handleAddKeyManagement = (e: any) => {
    setKeyManagementState({
      ...keyManagementState,
      [e.target.name]: e.target.value.trimStart(),
    });
  };
  const handleManStrength = e => {
    if (e.target.value.length <= 5) {
      setManStrengthState({
        ...manStrengthState,
        [e.target.name]: parseInt(e.target.value),
      });
    }
  };
  useEffect(() => {
    if (
      keyManagementState.firstName !== "" ||
      keyManagementState.lastName !== "" ||
      keyManagementState.designation !== "" ||
      keyManagementState.experience !== "" ||
      keyManagementState.qualification !== ""
    ) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [keyManagementState]);

  if (
    manStrengthState?.totalManPower === null ||
    Number.isNaN(manStrengthState?.totalManPower)
  ) {
    validateData = false;
  
  } else {
    validateData = true;

  }

  if (editButton) {
    return (
      <>
        <ContentHeader>People</ContentHeader>
        <FormWrapper>
          <CardContainer>
            <ContentHeading>Key Management</ContentHeading>

            <CardHolder>
              {peopleData !== undefined &&
                peopleData.map((item, index) => {
                  return (
                    <CardWithoutEdit
                      usedFor="keyManagement"
                      data={{ item, index }}
                      header={false}
                      cardType={"cardWithoutEdit"}
                      editable={editButton}
                    />
                  );
                })}
            </CardHolder>
            <ContentHeading>Manpower Strength</ContentHeading>
            <CardHolder>
              {manpowerGetData &&
                Object.keys(manpowerGetData).length !== 0 &&
                loaded &&
                !error && (
                  <CardWithoutEdit
                    usedFor="manStrength"
                    onHandleDelete={index => {
                      delete userInfo[index];
                    }}
                    data={{ manStrengthState }}
                    header={false}
                    cardType={"cardWithoutEdit"}
                    editable={editButton}
                  />
                )}
            </CardHolder>
          </CardContainer>
        </FormWrapper>
        <ButtonWrapper>
        <ProfileCreationButtons
        usedAt={"people"}
          state={buttonState.editButton}
          handleStateChange={value => {
            if (value === "cancel") {
              showEditReducer(dispatch, !buttonState.editButton);
            } else {
              handleClick(value);
            }
          }}
        ></ProfileCreationButtons>
          {/* <Button
            onClick={() => handleClick("edit")}
            variant="primary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Edit
          </Button>
          <BtnMargin>
            <Button
              disabled={false}
              onClick={() => {
                history.push("/experience");
              }}
              variant="secondary"
              size="middle"
              style={{ width: "253px", padding: "20px 0px 19px 0px" }}
            >
              Next Section
            </Button>
          </BtnMargin> */}
         
        </ButtonWrapper>
      </>
    );
  }

  return (
    <>
      <UserInfo.Provider value={[userInfo, setUserInfo]}>
        <ContentHeader>People</ContentHeader>
        <ContentInputs>
          <FormWrapper>
            <PeopleInfoContainer>
              <HeaderDiv>
                <ContentHeading>Key Management</ContentHeading>
                <ProfileName>
                  {peopleInformationLocalizationData.keyManagementHeading}
                </ProfileName>
              </HeaderDiv>
              <Wrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    name="firstName"
                    child="First Name"
                    fields="input"
                    placeholder="First Name"
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.firstName}
                  />
                </InputFlexWrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    name="lastName"
                    child="Last Name"
                    fields="input"
                    placeholder="Last Name"
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.lastName}
                  />
                </InputFlexWrapper>

                <InputFlexWrapper>
                  <InputBoxForEdit
                    sizes="semiMiddle"
                    inputTextType="text"
                    name="designation"
                    importanceType="important"
                    child="Designation"
                    fields="input"
                    placeholder="Designation"
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.designation}
                  />
                </InputFlexWrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    child="Qualification"
                    fields="input"
                    name="qualification"
                    placeholder="Qualification"
                    maxChar={50}
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.qualification}
                  />
                </InputFlexWrapper>
                <InputFlexWrapper>
                  <InputBoxForEdit
                    sizes="semiMiddle"
                    inputTextType="text"
                    importanceType="important"
                    child="experience"
                    fields="input"
                    name="experience"
                    placeholder="Industry Experience"
                    maxChar={200}
                    onHandleChange={(e: any) => {
                      handleAddKeyManagement(e);
                    }}
                    value={keyManagementState.experience}
                  />
                </InputFlexWrapper>
              </Wrapper>
              <AddKeyButtonWrap onClick={() => {}}>
                <Button
                  disabled={buttonDisable}
                  onClick={handleAddKey}
                  variant="blue"
                  size="small"
                  style={{ borderRadius: "8px", padding: "21px 40px" }}
                >
                  Add
                </Button>
              </AddKeyButtonWrap>
              <CardHolder>
                {peopleData !== undefined &&
                  peopleData.map((item, index) => {
                    return (
                      <CardWithoutEdit
                        usedFor="keyManagement"
                        onHandleDelete={index => {
                          handleKeyManagementCard(index);
                        }}
                        data={{ item, index }}
                        header={false}
                        cardType={"cardWithoutEdit"}
                        editable={editButton}
                      />
                    );
                  })}
              </CardHolder>
              <Div>
                <ContentHeading>Manpower Strength</ContentHeading>
                <ContentSubHeading>
                  {peopleInformationLocalizationData.manpowerStrengthHeading}
                </ContentSubHeading>
              </Div>
              <WrapperDiv>
                <InputWithHeaderForEdit
                  maxChar={5}
                  withHeader={true}
                  usedFor="profileCreation"
                  inputBoxSize="middle"
                  inputType="number"
                  importance="important"
                  label="manpower"
                  name="totalManPower"
                  value={manStrengthState.totalManPower}
                  onHandleChange={(e: any) => {
                    handleManStrength(e);
                  }}
                >
                  Total manpower in numbers*
                </InputWithHeaderForEdit>
                {(manStrengthState.totalManPower === null ||
                  Number.isNaN(manStrengthState.totalManPower)) &&
                  saveState && (
                    <ErrorMessageComponent
                      name={"Please enter total manpower"}
                    />
                  )}
              </WrapperDiv>
              <WrapperDiv>
                <TextboxWrapper>
                  <InputWithHeaderForEdit
                    maxChar={5}
                    withHeader={true}
                    usedFor="profileCreation"
                    inputBoxSize="small"
                    inputType="number"
                    importance="important"
                    label="Fulltime"
                    name="manPowerFullTime"
                    value={manStrengthState.manPowerFullTime}
                    onHandleChange={(e: any) => {
                      handleManStrength(e);
                    }}
                  >
                    Manpower full time
                  </InputWithHeaderForEdit>
                  <InputWithHeaderForEdit
                    withHeader={true}
                    usedFor="profileCreation"
                    inputBoxSize="small"
                    inputType="number"
                    importance="important"
                    label="PartTime"
                    name="manPowerPartTime"
                    value={manStrengthState.manPowerPartTime}
                    onHandleChange={(e: any) => {
                      handleManStrength(e);
                    }}
                  >
                    Manpower part time
                  </InputWithHeaderForEdit>
                </TextboxWrapper>
              </WrapperDiv>
              <WrapperDiv>
                <InputWithHeaderForEdit
                  withHeader={true}
                  usedFor="profileCreation"
                  inputBoxSize="middle"
                  inputType="number"
                  importance="important"
                  label="manufacturing"
                  name="totalManPowerManufacturing"
                  value={manStrengthState.totalManPowerManufacturing}
                  onHandleChange={(e: any) => {
                    handleManStrength(e);
                  }}
                >
                  Total manpower in manufacturing
                </InputWithHeaderForEdit>
              </WrapperDiv>
              <WrapperDiv>
                <InputWithHeaderForEdit
                  withHeader={true}
                  usedFor="profileCreation"
                  inputBoxSize="middle"
                  inputType="number"
                  importance="important"
                  label="service"
                  name="totalManPowerService"
                  value={manStrengthState.totalManPowerService}
                  onHandleChange={(e: any) => {
                    handleManStrength(e);
                  }}
                >
                  Total manpower in service
                </InputWithHeaderForEdit>
              </WrapperDiv>
            </PeopleInfoContainer>
          </FormWrapper>
        </ContentInputs>
        <ButtonWrapper>
        <ProfileCreationButtons
          usedAt={"people"}

          state={buttonState.editButton}
          handleStateChange={value => {
            if (value === "cancel") {
              showEditReducer(dispatch, !buttonState.editButton);
              setManStrengthState({
                id: 0,
                manPowerFullTime: manpowerGetData.fullTime,
                manPowerPartTime: manpowerGetData.partTime,
                totalManPower: manpowerGetData.total,
                totalManPowerManufacturing: manpowerGetData.inManufacturing,
                totalManPowerService: manpowerGetData.inServicing,
              });
            } else {
              handleClick(value);
            }
          }}
        ></ProfileCreationButtons>
          {/* {
            <Button
              onClick={() => handleClick("save")}
              variant="secondary"
              size="middle"
              style={{ width: "253px", padding: "20px 0px 19px 0px" }}
            >
              Save
            </Button>
          }

          <BtnMargin>
            <Button
              disabled={true}
              onClick={() => {
                
              }}
              variant="secondary"
              size="middle"
              style={{ width: "253px", padding: "20px 0px 19px 0px" }}
            >
              Next Section
            </Button>
          </BtnMargin> */}
        </ButtonWrapper>
      </UserInfo.Provider>
    </>
  );
};

export default PeopleInfoTab;
