import { IOnboardingInitialState } from "NewOnboarding/newOnboarding.interfaces";
import { Types as OnBoardingTypes } from "./types";

export const onboardingInitialState: IOnboardingInitialState = {
  loading: false,
  onboardingData: {
    userOnboarded: false,
    companyName: "",
    companyInfoId: "",
    website: "",

    // gst related
    gstCertificate: null,
    gstVerificationStatus: "",
    gstn: "",

    // Key Customers
    keyCustomers: [],
    customersListFile: null,

    // Company Brochure
    serviceDescription: "",
    companyBrochure: null,

    // checkbox
    inHousePlant: false,

    // Past Projects/ Experience Files
    experience: [],

    secondaryUser: null,
  },

  // dropdowns - Type of equipments / products
  equipmentFamilyList: [],

  // dropdowns - Service capabilities
  serviceGroupList: [],
};

// type of keys of OnBoardingTypes object
type IKeys = keyof typeof OnBoardingTypes;

interface IACTION_TYPE {
  // type of values of OnBoardingTypes
  type: typeof OnBoardingTypes[IKeys];
  result: any;
}

export default (state = onboardingInitialState, action: IACTION_TYPE) => {
  switch (action.type) {
    case OnBoardingTypes.ONBOARDING_OPERATION_REQUESTED:
      return { ...state, loading: true };

    case OnBoardingTypes.ONBOARDING_OPERATION_FAILURE:
      return { ...state, loading: false, error: action?.result };

    case OnBoardingTypes.GET_ONBOARDING_SUCCESS:
      return { ...state, loading: false, onboardingData: action?.result };

    case OnBoardingTypes.GET_SERVICE_GROUP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        // storing serviceGroupList as array of objects
        serviceGroupList:
          action?.result?.map((serviceName: string) => ({ value: serviceName, label: serviceName })) || [],
      };

    case OnBoardingTypes.GET_EQUIPMENT_FAMILY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        // storing equipmentFamilyList as array of objects
        equipmentFamilyList:
          action?.result?.map((equipFamilyName: string) => ({ value: equipFamilyName, label: equipFamilyName })) || [],
      };

    case OnBoardingTypes.POST_ONBOARDING_SUCCESS:
      return { ...state, loading: false };

    case OnBoardingTypes.VERIFY_SUB_USER_GST_CERT_SUCCESS:
      return {
        ...state,
        loading: false,
        onboardingData: { ...state.onboardingData, secondaryUser: action?.result }
      };

    case OnBoardingTypes.VERIFY_ONBOARDING_LINK_FAILURE:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};
