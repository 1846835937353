import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "components/Button";
import SinglePage from "./Component/PdfViewer";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface CardProps {
  onPdfUpload: () => void;
}
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 0 40px;
  padding-top: 20px;
`;

const TextContainer = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 20px;
  color: rgba(1, 23, 89, 0.7);
`;

const TextDescription = styled.div`
  font-weight: 600;
  letter-spacing: 0.01em;
`;

const TextNote = styled.div`
  font-weight: 400;
`;

const BusinessCardContainer = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

const ButtonDiv = styled(Button)`
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #ffffff;
  height: 60px;
`;
const BusinessCard = (props: CardProps) => {
  const marketingSuiteState = useSelector((state: any) => state.marketingSuite);
  const { digitalBusCard } = marketingSuiteState;
  const history = useHistory();

  useEffect(() => {
    if (digitalBusCard?.data?.url) {
      props.onPdfUpload();
    }
  }, [digitalBusCard]);

  return (
    <Container>
      <TextContainer>
        <TextDescription>
          {"You can update your info "}
          <a
            onClick={() => history.push("/userinfo")}
            style={{ textDecoration: "underline" }}
          >
            here
          </a>
          {" and download the digital business card"}
        </TextDescription>
        <TextNote>
          Note : A Digital Business Card is an interactive PDF with clickable
          links
        </TextNote>
      </TextContainer>
      <BusinessCardContainer>
        <SinglePage pdf={digitalBusCard?.data?.url}></SinglePage>
      </BusinessCardContainer>
      <ButtonDiv
        onClick={() => {}}
        variant="secondary"
        size="middle"
        style={{ width: "370px", padding: "20px 105px", alignSelf: "center" }}
      >
        <a href={digitalBusCard?.data?.url} download>
          Download digital business card
        </a>
      </ButtonDiv>
    </Container>
  );
};

export default BusinessCard;
