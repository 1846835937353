import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Modal, Popover } from "antd";
import { Button } from "components/Button";
import { DatePickerWithLabel } from "components/DatePicker";
import BasicDropdown from "components/Dropdown/BasicDropdown/BasicDropdown";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";
import { InputWithEdit, InputWithHeader } from "components/Input";
import { Label } from "components/Label";
import UploadImage from "components/UploadImage/UploadImage";
import {
  companyInfoGet,
  companyInfoPost,
  companyInfoUpdate,
} from "reducers/companyInfo";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import { searchCity, searchState } from "reducers/locations";
import { showEditReducer } from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { companyInformationLocalizationData } from "ui-constants/localizationData";
import InputWithHeaderForEdit from "components/Input/InputBoxWithEdit/inputHeader";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";

const FormikForm = styled(Form)`
  overflow: auto;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentInputs = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;

  padding-bottom: 30px;
`;
const BasicInformationMeassage = styled.div`
  padding: 30px 46px 30px 30px;
  border-radius: 12px;
  height: 111px;
  width: 100%;
  background-color: ${props => `${props.theme.colors.lightGray}`};
`;

const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
`;
const InputFlexWrapper = styled.div`
  display: flex;
  padding-top: 40px;
  width: 582px;
  flex-direction: column;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InputFlexColumn = styled.div`
  display: block;
`;
const FormWrapper = styled.div``;
const InputDropdownWrapper = styled.div`
  width: 551px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const ProfileImageWrapper = styled.div`
  width: 178px;
  height: 178px;
  margin: 40px 0% 40px 40px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const ImgTag = styled.img`
  width: 20px;
  height: 20px;
  margin-top: 26px;
`;
const ImgDiv = styled.div`
  display: flex;
  align-items: center;
`;
const PopoverDiv = styled.div`
  font-size: 12px;
  color: #261c4b;
  font-weight: 400;
  width: 230px;
`;
const InputWrapper = styled.div`
  margin-top: 8px;
`;
export const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;

  display: flex;
  justify-content: center;
`;

interface ImageData {
  fileName: string;
  fileSize: number;
  fileUrl: string;
}

const BtnMargin = styled.div`
  margin-left: 30px;
`;
const CompanyInfoTab = () => {
  const history = useHistory();
  let validateData = false;
  const cinRegex = /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
  const nameValidations = /^(?!\s*$).+/;

  const companyInfoState = useSelector((state: any) => state.companyInfo);
  const dispatch = useDispatch();
  const { companyInfoData, loaded, error, updatedError } = companyInfoState;

  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton } = buttonState;

  const locationState = useSelector((state: any) => state.locations);
  const { cityLoading, stateLoading } = locationState;

  const companyInformation = {
    vendorId: `${SessionStorageService.get("vendorId") ||
      LocalStorageService.get("vendorId")}`,
    companyName: "",
    yoc: "",
    gstn: `${SessionStorageService.get("gstNum") ||
      LocalStorageService.get("vendorId")}`,
    website: "",
    cin: "",
    fulladdress: {
      address: "",
      state: undefined,
      city: undefined,
      pincode: "",
    },
    turnOver: "",
    companyLogo: {},
  };

  const [imgStore, setImgStore] = useState<ImageData>({
    fileName: "",
    fileSize: 0,
    fileUrl: "",
  });
  const [saveState, setSaveState] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [userInfo, setUserInfo] = useState(companyInformation);

  useEffect(() => {
    dispatch(companyInfoGet({}));
    if (companyInfoData && Object.keys(companyInfoData).length > 1) {
      setUserInfo({ ...userInfo });
    }
  }, []);

  useEffect(() => {
    if (error || updatedError) {
      dispatch(companyInfoGet({}));
      companyInfoData &&
        Object.keys(companyInfoData).length > 1 &&
        setUserInfo({ ...companyInfoData });
    }
  }, [error, updatedError]);

  useEffect(() => {
    if (companyInfoData && Object.keys(companyInfoData).length < 3) {
      showEditReducer(dispatch, (buttonState.editButton = false));
    } else {
      showEditReducer(dispatch, (buttonState.editButton = true));
    }
  }, [companyInfoData]);

  useEffect(() => {
    if (companyInfoData !== null) {
      setUserInfo({ ...companyInfoData });
      setImgStore(companyInfoData.companyLogo);
    }
    if (companyInfoData?.fulladdress?.state) {
      dispatch(
        searchState({
          countryCode: "in",
          state: companyInfoData?.fulladdress?.state,
        })
      ).then(res => {
        setSelectedState(res?.data);
      });
    }
  }, [companyInfoData]);

  const warning = () => {
    Modal.warning({
      title: "Warning",
      content: "Please fill all the required field in order to save your data ",
      centered: true,
    });
  };

  const handleClick = type => {
    if (type === "edit" && !error) {
      showEditReducer(dispatch, !editButton);
    }
    if (type === "save") {
      if (validateData) {
        if (!companyInfoData.hasOwnProperty("companyInfoId")) {
          dispatch(
            companyInfoPost({
              ...userInfo,
              companyLogo: imgStore,
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        } else {
          dispatch(
            companyInfoUpdate({
              companyInfoId: companyInfoData.companyInfoId,
              ...userInfo,
              companyLogo: imgStore,
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        }
        showEditReducer(dispatch, !editButton);
      } else {
        warning();
      }
    }
    if (loaded && !error) {
    }
  };

  const handleOnChange = e => {
    if (e?.target?.name === "turnOver") {
      // limiting the max value to 5000 and decimal to 2 places
      if (e.target.value <= 5000) {
        let value = e.target.value.toString();
        let validatedValue =
          value.indexOf(".") >= 0
            ? parseFloat(
                value.substr(0, value.indexOf(".")) +
                  value.substr(value.indexOf("."), 3)
              )
            : e.value;

        setUserInfo({ ...userInfo, [e.target.name]: e.target.value.trim() });
      }
    } else {
      setUserInfo({
        ...userInfo,
        [e.target?.name]: e.target?.value.trimStart(),
      });
    }
  };

  const handleAddressChange = e => {
    if (e.target?.name === "pincode") {
      if (e.target.value.length < 7) {
        setUserInfo({
          ...userInfo,
          fulladdress: {
            ...userInfo.fulladdress,
            [e.target.name]: parseInt(e.target.value?.trimStart()?.trimEnd()),
          },
        });
      }
    } else {
      setUserInfo({
        ...userInfo,
        fulladdress: {
          ...userInfo.fulladdress,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const handleUpload = (imageDetails: any) => {
    setImgStore(imageDetails);
  };

  const handleCityChange = (element: string) => {
    setUserInfo({
      ...userInfo,
      fulladdress: { ...userInfo.fulladdress, city: element },
    });
  };
  const handleStateChange = (element: string) => {
    setSelectedState(
      stateList.filter(item => {
        return item.name === element;
      })
    );
    setUserInfo({
      ...userInfo,
      fulladdress: { ...userInfo.fulladdress, state: element, city: undefined },
    });
  };

  const getCity = (element: string) => {
    if (!cityLoading) {
      dispatch(
        searchCity({
          stateCode: selectedState[0]?.isoCode,
          city: element,
        })
      ).then(
        res => {
          setCityList(res?.data);
        },
        error => {}
      );
    }
  };

  const getState = (element: string) => {
    if (!stateLoading) {
      dispatch(
        searchState({
          countryCode: "in",
          state: element,
        })
      ).then(
        res => {
          setStateList(res?.data);
        },
        error => {}
      );
    }
  };

  const handleDateChange = (date, dateString) => {
    setUserInfo({
      ...userInfo,
      yoc: `${dateString}`,
    });
  };

  if (
    userInfo.yoc !== undefined &&
    userInfo.yoc !== "" &&
    userInfo.fulladdress?.address !== undefined &&
    userInfo.fulladdress?.address !== "" &&
    userInfo.fulladdress?.city !== undefined &&
    userInfo.fulladdress?.city !== "" &&
    userInfo.fulladdress?.state !== undefined &&
    userInfo.fulladdress?.state !== "" &&
    userInfo.fulladdress?.pincode?.toString()?.trimStart()?.length === 6 &&
    userInfo.turnOver !== undefined &&
    userInfo.turnOver !== "" &&
    userInfo.companyName !== ""
  ) {
    validateData = true;
  } else {
    validateData = false;
  }
  const validate = Yup.object({
    companyName: Yup.string()
      .max(100, "Max 35 characters allowed")
      .matches(
        nameValidations,
        "Company name cannot be blank- please enter your company name"
      )
      .default(userInfo.companyName),

    cin: Yup.string()
      .min(21, "Please enter the CIN number in correct format")
      .max(21, "Please enter the number CIN number")
      .matches(cinRegex, "Please enter the CIN number in correct format")
      .default(userInfo.cin),
    pincode: Yup.number(),
  });

  return (
    <>
      <UserInfo.Provider value={[userInfo, setUserInfo]}>
        <Formik
          initialValues={companyInformation}
          validationSchema={validate}
          isInitialValid={validate.isValidSync(companyInformation)}
          onSubmit={values => {}}
        >
          {formik => (
            <FormikForm>
              <ContentHeader>Company Information</ContentHeader>
              <ContentInputs>
                <BasicInformationMeassage>
                  <Label
                    fontVariant="bold"
                    text={companyInformationLocalizationData.heading}
                    htmlFor="profileHeader"
                  />
                  <ProfileName>
                    {companyInformationLocalizationData.subHeading}
                  </ProfileName>
                </BasicInformationMeassage>
                <Wrapper>
                  <FormWrapper>
                    <InputFlexWrapper>
                      <InputWithHeaderForEdit
                        maxChar={100}
                        withHeader={true}
                        buttonDisable={editButton}
                        usedFor="profileCreation"
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        label="Company Name"
                        name="companyName"
                        error={formik.errors}
                        touch={formik.touched}
                        value={userInfo.companyName}
                        placeholder="Enter Company Name"
                        onHandleChange={e => {
                          handleOnChange(e);
                        }}
                      >
                        Company Name*
                      </InputWithHeaderForEdit>
                      {(userInfo.companyName === undefined ||
                        userInfo.companyName === "") &&
                        saveState && (
                          <ErrorMessageComponent
                            name={"Please enter your company"}
                          />
                        )}
                    </InputFlexWrapper>
                    <InputFlexWrapper>
                      <DatePickerWithLabel
                        usedFor="profileCreation"
                        placeholder={
                          userInfo.hasOwnProperty("yoc") &&
                          userInfo.yoc.length > 1
                            ? userInfo.yoc
                            : "DD - MM - YYYY"
                        }
                        disabled={editButton}
                        format="DD-MM-YYYY"
                        onChange={(date, dateString) =>
                          handleDateChange(date, dateString)
                        }
                      >
                        Year of Incorporation*
                      </DatePickerWithLabel>
                      {(userInfo.yoc === undefined || userInfo.yoc === "") &&
                        saveState && (
                          <ErrorMessageComponent
                            name={"Please enter your year of incorporations"}
                          />
                        )}
                    </InputFlexWrapper>
                    <InputFlexWrapper>
                      <ImgDiv>
                        <InputWithHeaderForEdit
                          withHeader={true}
                          usedFor="profileCreation"
                          buttonDisable
                          inputBoxSize="large"
                          inputType="text"
                          importance="important"
                          label="GST NO"
                          placeholder="Enter GST No"
                          value={userInfo.gstn}
                          name="gstNo"
                          onHandleChange={e => {
                            handleOnChange(e);
                          }}
                        >
                          GST NO
                        </InputWithHeaderForEdit>

                        {!editButton && (
                          <Popover
                            placement="right"
                            content={
                              <PopoverDiv>
                                GST number used during registration cannot be
                                changed. Please contact Venwiz admin for any
                                modification
                              </PopoverDiv>
                            }
                          >
                            <ImgTag src="/images/ProfileCreation/info.svg" />
                          </Popover>
                        )}
                      </ImgDiv>
                    </InputFlexWrapper>
                    <InputFlexWrapper>
                      <InputWithHeaderForEdit
                        withHeader={true}
                        usedFor="profileCreation"
                        buttonDisable={editButton}
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        label="Website"
                        name="website"
                        value={userInfo.website}
                        placeholder="Enter Website"
                        onHandleChange={e => {
                          handleOnChange(e);
                        }}
                      >
                        Website (Optional)
                      </InputWithHeaderForEdit>
                    </InputFlexWrapper>
                    <InputFlexWrapper>
                      <InputWithHeaderForEdit
                        withHeader={true}
                        usedFor="profileCreation"
                        buttonDisable={editButton}
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        value={userInfo.cin}
                        label="CIN"
                        onHandleChange={e => {
                          handleOnChange(e);
                        }}
                        name="cin"
                        placeholder="Enter CIN"
                      >
                        CIN
                      </InputWithHeaderForEdit>
                    </InputFlexWrapper>
                    <InputFlexColumn>
                      <InputFlexWrapper>
                        <InputWithHeaderForEdit
                          withHeader={true}
                          usedFor="profileCreation"
                          inputBoxSize="large"
                          inputType="text"
                          importance="important"
                          error={formik.errors}
                          touch={formik.touched}
                          label="fullAddress"
                          value={
                            userInfo.hasOwnProperty("fulladdress")
                              ? userInfo.fulladdress.address
                              : ""
                          }
                          name="address"
                          placeholder="Enter Address"
                          buttonDisable={editButton}
                          onHandleChange={e => {
                            handleAddressChange(e);
                          }}
                        >
                          Address*
                        </InputWithHeaderForEdit>
                      </InputFlexWrapper>
                      <InputDropdownWrapper>
                        <BasicDropdown
                          placeholder="State"
                          sizes="medium"
                          data={stateList?.map((item, index) => {
                            return { id: index, value: item.name };
                          })}
                          onChange={(element: string) => {
                            handleStateChange(element);
                          }}
                          onSearch={(element: string) => getState(element)}
                          disabled={editButton}
                          usedFor="profileCreation"
                          value={
                            userInfo.hasOwnProperty("fulladdress")
                              ? userInfo.fulladdress.state
                              : undefined
                          }
                        />
                        <BasicDropdown
                          sizes="medium"
                          placeholder="City"
                          data={cityList?.map((item, index) => {
                            return { id: index, value: item.name };
                          })}
                          onChange={(element: string) => {
                            handleCityChange(element);
                          }}
                          onSearch={(element: string) => getCity(element)}
                          usedFor="profileCreation"
                          value={
                            userInfo.hasOwnProperty("fulladdress")
                              ? userInfo.fulladdress.city
                              : undefined
                          }
                          disabled={editButton}
                        />
                        <InputWrapper>
                          <InputWithHeaderForEdit
                            withHeader={false}
                            maxChar={6}
                            usedFor="profileCreation"
                            inputType="number"
                            importance="important"
                            error={formik.errors}
                            touch={formik.touched}
                            value={
                              userInfo.hasOwnProperty("fulladdress")
                                ? userInfo?.fulladdress?.pincode
                                    ?.toString()
                                    ?.trimStart()
                                    .trimEnd()
                                : ""
                            }
                            buttonDisable={editButton}
                            onHandleChange={e => {
                              handleAddressChange(e);
                            }}
                            placeholder="Pincode"
                            inputBoxSize="semiMiddle"
                            name="pincode"
                          />
                        </InputWrapper>
                      </InputDropdownWrapper>
                      {(userInfo.fulladdress?.address === undefined ||
                        userInfo.fulladdress?.address === "" ||
                        userInfo.fulladdress?.city === undefined ||
                        userInfo.fulladdress?.city === "" ||
                        userInfo.fulladdress?.state === undefined ||
                        userInfo.fulladdress?.state === "" ||
                        userInfo.fulladdress?.pincode?.toString()?.length <
                          6) &&
                        saveState && (
                          <ErrorMessageComponent
                            name={"Please enter  all address input fields"}
                          />
                        )}
                    </InputFlexColumn>
                    <InputFlexWrapper>
                      <InputWithHeaderForEdit
                        usedFor="profileCreation"
                        buttonDisable={editButton}
                        inputBoxSize="large"
                        inputType="number"
                        importance="important"
                        label="Annual Turn Over (INR in crores)"
                        name="turnOver"
                        error={formik.errors}
                        touch={formik.touched}
                        value={userInfo.turnOver}
                        onHandleChange={handleOnChange}
                        placeholder={
                          "Eg. Enter 1 for 1 Crore, 0.5 for 50 Lakhs"
                        }
                        withHeader
                      >
                        Annual Turnover (INR in Crores)*
                      </InputWithHeaderForEdit>
                      {(userInfo.turnOver === undefined ||
                        userInfo.turnOver === "") &&
                        saveState && (
                          <ErrorMessageComponent
                            name={"Please enter your annual turnover"}
                          />
                        )}
                    </InputFlexWrapper>
                  </FormWrapper>
                  <ProfileImageWrapper>
                    <UploadImage
                      height="178px"
                      width="178px"
                      title="Company Logo"
                      imgSrc={imgStore?.fileUrl}
                      optional
                      onUpload={handleUpload}
                      disabled={editButton}
                    />
                  </ProfileImageWrapper>
                </Wrapper>
              </ContentInputs>
              <ButtonWrapper>
                <ProfileCreationButtons
                  usedAt={"companyInfo"}
                  state={editButton}
                  handleStateChange={value => {
                    if (value === "cancel") {
                      showEditReducer(dispatch, !buttonState.editButton);
                      setUserInfo({ ...companyInfoData });
                    } else {
                      handleClick(value);
                    }
                  }}
                ></ProfileCreationButtons>
                {/* {editButton ? (
                  <Button
                    onClick={() => handleClick("edit")}
                    variant="primary"
                    size="middle"
                    style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setSaveState(true);
                      handleClick("save");
                    }}
                    variant="secondary"
                    size="middle"
                    style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                  >
                    Save
                  </Button>
                )}
                  <BtnMargin>
          <Button
            disabled={!buttonState.editButton}
            onClick={() => {
              history.push("/scopeofwork");
            }}
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Next Section
          </Button>
        </BtnMargin> */}
              </ButtonWrapper>
            </FormikForm>
          )}
        </Formik>
      </UserInfo.Provider>
    </>
  );
};

export default CompanyInfoTab;
