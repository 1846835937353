import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 10px;
  backdrop-filter: blur(2px);
  z-index: 1500;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const PageSpinner = ({isLoading}) => {

  return (
    <>
      { isLoading && (
        <Spinner>
          <Spin
            style={{ position: "absolute", top: "40%" , left:"45%" }}
            size="large"
            tip="Loading..."
          />
        </Spinner> )}
    </>
  )
}
