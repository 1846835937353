import React from "react";
import "antd/dist/antd.css";
import BasicDropdown, { DropdownProps } from "../BasicDropdown/BasicDropdown";
import styled from "styled-components";
import { Button } from "../../Button";

export interface DropdownWitHeader extends DropdownProps {
  children?: React.ReactNode;
  width?: string;
  headerFlag: boolean;
  onClick?: (e: any) => void;
  hide?: boolean;
  placeholder?: string;
  buttonChild?: boolean;
  buttonText?: string;
  onButtonClick?: () => void;
  value?: string;
  error?: boolean;
  paddingNotRequired?: boolean;
  onBlurTrigger?: () => void;
  onBlurEventRequired?: boolean;
  height?: string;
}

const BoxContainer = styled.div<Partial<DropdownWitHeader>>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${props => (props.width ? props.width : "auto")};
  height: auto;
  color: ${props => props.theme.colors.darkBlue};
  position: relative;
`;
const HeaderName = styled.div`
  width: auto;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
  display: flex;
  justify-content: space-between;
`;

const DropdownWithHeader = ({
  hide,
  headerFlag,
  ...props
}: DropdownWitHeader) => {
  return (
    <BoxContainer>
      {headerFlag && (
        <HeaderName
          style={{
            height: `${props.usedFor}` === "login" ? "21px" : "18px",
            marginBottom: `${props.usedFor}` === "login" ? "15px" : "10px",
            opacity: `${props.usedFor === "profileCreation" ? "0.7" : "1"}`,
          }}
        >
          {props.children}
          {props.buttonChild ? (
            <Button
              style={{ fontWeight: "bold", fontSize: "12px" }}
              usedFor="dropdownClear"
              size="small"
              variant="white"
              onClick={props.onButtonClick}
              children={props.buttonText}
            />
          ) : (
            <div style={{ paddingLeft: "230px" }}></div>
          )}
        </HeaderName>
      )}
      {hide && (
        <BasicDropdown
          onBlurTrigger={() => {
            if (props?.onBlurEventRequired) props?.onBlurTrigger();
          }}
          onBlurEventRequired
          paddingNotRequired={props?.paddingNotRequired}
          error={props?.error}
          value={props.value}
          disabled={props.disabled}
          sizes={props.sizes}
          data={props.data}
          usedFor={props.usedFor}
          onChange={props.onChange}
          placeholder={props.placeholder}
          onSearch={props.onSearch}
          height={props?.height}
        />
      )}
    </BoxContainer>
  );
};

export default DropdownWithHeader;
