export const certificationLocalizationData = {
  heading:"Add all the certifications you have received",
  basicInfo: "Upload basic company certificates- GST, Aadhar, PAN, MSME, CIN etc.",
  technicalInfo: "Upload active technical licenses/certificates. Eg: IBR III-C, ASME UV etc.",
  complianceInfo: "Upload relevant compliance certificates- ISO/ IEC, ESIC, EPFO, OSHAS etc.",
};

export const userInformationLocalizationData = {
  heading:
    "We have auto filled this based on the information shared by you during registration. ",
  subHeading:
    " If the information isn’t accurate, please edit and make sure you update the changes before exiting this page",
};

export const companyInformationLocalizationData = {
  heading:
    "We have auto filled this based on the information shared by you during registration. ",
  subHeading:
    "If the information isn’t accurate, please edit and make sure you  update the changes before exiting this page",
};

export const peopleInformationLocalizationData = {
  keyManagementHeading: "List down all your key management in your company.",
  manpowerStrengthHeading: "List down your total number of manpower.",
};

export const documentsReqLocalizationData = {
  heading:
    "Upload documents such as permits, licenses, solvency certificate or brochure etc. Please note that you can only upload 10 documents",
};
export const experienceLocalizationData = {
  heading: "Mention atleast 3  big projects undertaken by your company.",
};

export const referenceLocalizationData = {
  heading: "Mention atleast 3  big projects undertaken by your company.",
};
