import { DisplayError } from 'Authentication/Login/Login.style'
import React from 'react'
import { Img } from './ResponsiveScreenErrorMessage.style'

const  ResScreenErrorMess=({children})=> {
    return (
        <DisplayError>
            <Img src="/images/Auth/warning.svg"></Img>   
            {children} 
        </DisplayError>
    )
}

export default ResScreenErrorMess;
