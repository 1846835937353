import { Colors } from 'Theme';
// eslint-disable-next-line import/prefer-default-export
export const textAreaTheme = (colors:Colors) => ({
  sizes: {
    small:{
      width: '550px',
      height:'220px',
      borderRadius:'8px',
      border:'1px solid #D5D9EC',
      color:'black',
      resize:'none',
      padding:'20px',
      margin:'50px',
      outline:'none'
    },
    large:{
      width: '700px',
      height:'220px',
      borderRadius:'8px',
      border:'1px solid #D5D9EC',
      color:'black',
      resize:'none',
      padding:'10px',
      margin:'50px'
    },
    medium:{
      width: '550px',
      height:'80px',
      borderRadius:'8px',
      border:'1px solid #D5D9EC',
      color:'black',
      resize:'none',
      padding:'20px',
      margin:'50px',
      outline:'none',
      mobileStyles:{
        width: "100%",
        height:"100%",
      }
    },
    expPrjDesc:{
      width: '840px',
      height:'80px',
      borderRadius:'8px',
      border:'1px solid #D5D9EC',
      color:'black',
      resize:'none',
      padding:'18px 20px',
      margin:'50px',
      outline:'none',
      lineHeight: '21px',
    },
    PopupDesc:{
      width: '100%',
      height:'80px',
      borderRadius:'8px',
      border:'1px solid #D5D9EC',
      color:'black',
      resize:'none',
      padding:'18px 20px',
      margin:'50px',
      outline:'none',
      lineHeight: '21px',
    }
  }
});
