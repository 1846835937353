import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import ApiClient from 'services/ApiService';
import createMiddleware from 'reducers/middleware/clientMiddleware';
import rootReducer from 'reducers';

const createHistory = require('history').createBrowserHistory;

export const history = createHistory();

const client = new ApiClient();
const initialState = {};
const enhancers = [];
const middleware = [
  createMiddleware(client),
  thunk
];

if (process.env.NODE_ENV === "development") {
  const { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ } = window;

  if (typeof __REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === "function") {
    enhancers.push(__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
  }
}


const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
);

const store = createStore(
  rootReducer,
  initialState,
  composedEnhancers
);

export default store;
