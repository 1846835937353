export const COMPLIANCE_VALUES = {
  gst: {
    checkBoxLabel: 'GST',
    title: 'Goods and Services Tax number (GST)',
    defaultNote: 'Upload GST Reg-06 certificate to verify',
    placeHolder: '',
    processNote: 'Processing GST-Reg 06 certificate',
  },
  pan: {
    checkBoxLabel: 'PAN',
    title: 'Permanent Account Number (PAN)',
    defaultNote: 'Upload PAN scan',
    placeHolder: '',
  },
  lin: {
    checkBoxLabel: 'LIN',
    title: 'Labour Identification Number (LIN)',
    defaultNote: '',
    placeHolder: 'Ex : 1-2418-8945-6',
    processNote: '',
  },
  cin: {
    checkBoxLabel: 'CIN',
    title: 'Corporate Identification Number (CIN)',
    defaultNote: '',
    placeHolder: 'Ex : U28100KA2017PTC3',
  },
  urn: {
    checkBoxLabel: 'URN',
    title: 'Udyam Registration Number (URN)',
    defaultNote: '',
    placeHolder: 'Ex : UDYAM-KR-03-00093',
    processNote: 'Verify your URN',
  },
  esic: {
    checkBoxLabel: 'ESIC',
    title: "Employees' State Insurance Code (ESIC)",
    defaultNote: 'Upload ESIC registration copy (Form C11)',
    placeHolder: 'Ex : 530004000000000',
  },
  epf: {
    checkBoxLabel: 'EPF',
    title: "Employees' Provident Fund (EPF) Number",
    defaultNote: 'Upload EPF allotment letter scan',
    placeHolder: 'Ex : BGBNG1708883000',
  },
  tan: {
    checkBoxLabel: 'TAN',
    title: 'Tax deduction & collection Account Number (TAN)',
    defaultNote: '',
    placeHolder: 'Ex : BLRT13079D',
    processNote: 'Verify your TAN',
  },
};

export const STATUS_ICON = {
  verified: { image: '/images/Compliance/verification_success.svg', border: '#47C882' },
  notVerified: { image: '/images/Compliance/verification_red.svg', border: '#BE000B' },
  documentPending: { image: '/images/Compliance/verification_yellow.svg', border: '#F4C234' },
  inProgress: { image: '/images/Compliance/verification_processing.svg', border: '#D4E5FE' },
};

export const DASHBOARD_PROFILE_CREATION_STATUS = [
  {
    keyName: 'profile',
    text: 'Complete your company profile',
    bttnText: 'Learn how?',
    updatedBttnText: 'Update Profile',
    modal: true,
    modalHeaderText: 'To complete this task you need to',
    modalMobileHeaderText: 'Company Profile',
    apiKey: 'isCompanyProfileTaskCompleted',
  },
  {
    keyName: 'experience',
    text: 'Add key projects/work orders',
    bttnText: 'Update Experiences',
    updatedBttnText: 'Update Experiences',
    modal: false,
    modalHeaderText: '',
    modalMobileHeaderText: '',
    apiKey: 'isExpTaskCompleted',
    routeTo: '/experience',
  },
  {
    keyName: 'addMore',
    text: 'Add company documents/certificates',
    bttnText: 'Learn how?',
    updatedBttnText: 'Add More',
    modal: true,
    modalHeaderText: 'Add docs to boost profile credibility on Venwiz',
    modalMobileHeaderText: 'Documents / Certifications',
    apiKey: 'isDocsCertTaskCompleted',
  },
  {
    keyName: 'productVideo',
    text: 'Take a product tour',
    bttnText: 'Watch Video',
    updatedBttnText: 'Watch Again',
    modal: true,
    modalHeaderText: 'Watch Product Tour',
    modalMobileHeaderText: 'Product Tour',
    apiKey: 'isProductTourTaskCompleted',
  },
];

export const DASHBOARD_PROFILE_CREATION_STATUS_TEXT={
  desc:{
    mobileText:"Complete these simple steps to shape up your profile.",
    desktopText:"Complete below actions to get your company profile in the best shape"
  }
}
