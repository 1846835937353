import React from 'react'
import styled from 'styled-components'

interface ImageProps {
    imageSrc?: any,
    imageType: 'logo' | 'icon',
    altMesg: string,
    onClick?: (e:any) => void,
    id?:string
}

export const ImageBase = styled.img<Partial<ImageProps>>`
    width: 100%;
    height: 100%;
`

const LogoImage = ({ imageSrc, imageType, altMesg, onClick,id }: ImageProps) => {
    return (
        <ImageBase id={id} src={imageSrc} alt={altMesg} onClick = {onClick}/>
    )
}

export default LogoImage