import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import InputWithHeaderForEdit from "../inputHeader";
import { LogoImage } from "../../../Image";
import { UserContext } from "ContextApi/ContextApi";
type Sizes =
  | "small"
  | "middle"
  | "large"
  | "semiMiddle"
  | "extraLarge"
  | undefined;
type usedFor = "login" | "registration" | "profileCreation" | undefined;

interface InputWithEditProps {
  usedFor?: usedFor;
  data?: any;
  inputBoxSize?: Sizes;
  children?: Array<string>;
  inputType: string;
  importance: string;
  // label?: string;
  // name: string;
  error?: any;
  touch?: any;
  imgSrc?: string;
  placeholder?: string;
  multipleChild?: Array<string>;
  onChange?: (e) => void;
  buttonDisable?: boolean;
  withHeader?: boolean;
}

const Div = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-top: 22px;
`;

const TwoInputWithEdit = ({
  onChange,

  buttonDisable,
  ...props
}: InputWithEditProps) => {
  const [editClick, setEditClick] = useState(false);
  const [disableState, setDisableState] = useState(true);

  const handleClick = () => {
    setEditClick(!editClick);
  };

  return (
    <>
      <InputWithHeaderForEdit
        maxChar={35}
        usedFor={props.usedFor}
        inputBoxSize={props.inputBoxSize}
        inputType={props.inputType}
        importance={props.importance}
        label="first Name"
        name="firstName"
        value={props.data.firstName}
        // refValue={editClick}
        placeholder={"First Name"}
        // disable={buttonDisable}
        buttonDisable={buttonDisable}
        onHandleChange={(e: any) => {
          onChange(e);
        }}
        withHeader={props.withHeader}
        type={"text"}
      >
        First Name
      </InputWithHeaderForEdit>
      <InputWithHeaderForEdit
        maxChar={50}
        usedFor={props.usedFor}
        inputBoxSize={props.inputBoxSize}
        inputType={props.inputType}
        importance={props.importance}
        label="last Name"
        name="lastName"
        value={props.data.lastName}
        placeholder={"Last Name"}
        buttonDisable={buttonDisable}
        onHandleChange={(e: any) => {
          onChange(e);
        }}
        withHeader={props.withHeader}
        type={"text"}
      >
        Last Name
      </InputWithHeaderForEdit>
      {props.imgSrc && (
        <Div onClick={handleClick}>
          <LogoImage
            imageSrc={props.imgSrc}
            altMesg="edit button"
            imageType="icon"
          />
        </Div>
      )}
    </>
  );
};
export default TwoInputWithEdit;
