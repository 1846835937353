export const USERINFO_REQUESTED = "userInfoApi/USERINFO_REQUESTED";
export const USERINFO_SUCCESS = "userInfoApi/USERINFO_SUCCESS";
export const USERINFO_FAILURE = "userInfoApi/USERINFO_FAILURE";

export const USERINFO_UPDATE_SUCCESS = "userInfoApi/USERINFO_UPDATE_SUCCESS";


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userInfoData: null,
  otpError: false,

  userUpdateResponse: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERINFO_REQUESTED:
      return {
        ...state,
        loading: true,
        userUpdateResponse:null
      };

    case USERINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        userInfoData: action.result,
      };

    case USERINFO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        userUpdateResponse: action.result,
      };

    case USERINFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    default:
      return state;
  }
};
export const userInfoGet = (data) => ({
  types: [USERINFO_REQUESTED, USERINFO_SUCCESS, USERINFO_FAILURE],
  promise: (client) => client.get("userinfo_get_data", {}),
});

export const userInfoUpdate = (data) => {
  return {
    types: [USERINFO_REQUESTED, USERINFO_UPDATE_SUCCESS, USERINFO_FAILURE],
    promise: (client) =>
      client.post("userinfo_update_data", {
        data,
      }),
  };
};
