import styled from "styled-components";
import { FileInfoWrapper } from "components/Card/CardTag/Card";
import { Select } from "antd";

export const Container = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
`;

export const TagAndFileConainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  width: 550px;
  background: #f4f6ff;
  border: 1px solid #d4e5fe;
  border-radius: 8px;
  height: 60px;
  padding-left: 10px;
  @media screen and (max-width: 640px) {
    row-gap: 8px;
    width: 100%;
    border: 0px;
    align-items: flex-start;
    padding-left: 0px;
  }
`;

export const DeleteIcon = styled.img`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 20px;
  max-width: 20px;
  @media screen and (max-width: 640px) {
    display: none;
  }
`;
export const DeleteIconWrapper = styled.div``;

export const SelectDiv = styled.div`
  position: relative;
`;

export const UploadFileDiv = styled.div<any>`
  display: flex;
  align-items: center;
  position: relative;
  width: 180px;
  height: 40px;
  background: #f4f6ff;
  color: #215ec9;
  font-weight: 600;
  font-size: 14px;
  input {
    width: 180px;
    height: 20px;
  }
  span {
    width: max-content;
    padding: 0px;
    color: #215ec9;
  }

  @media screen and (max-width: 640px) {
    height: 36px;
    background: ${props => !props?.$isDocUploaded && "#215EC9"};
    border: ${props => !props?.$isDocUploaded && "1px solid #D4E5FE"};
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    span {
      font-size: 12px;
      color: #ffffff;
    }
    ${FileInfoWrapper} {
      margin: 0px 0.97vw 0px 6px;
      label {
        color: #215ec9;
      }
    }
  }
`;

// Select Styles

export const SelectDropdown = styled(Select)<any>`
  .ant-select-selector {
    height: 40px !important;
    width: 319px !important;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #011759 !important;
    background: #ffffff;
    border-radius: 8px !important;
    box-shadow: none !important;
    border-color: ${props => props?.$borderColor || "#D4E5FE !important;"};
    border: ${props => `${props?.$focusState && "0px !important"}`};
    .ant-select-selection-placeholder {
      margin: 8px 0px;
      color: #112b7a;
      opacity: 0.4;
      line-height: 24px;
    }
    .ant-select-selection-search-input {
      height: 16px !important;
    }
  }

  .ant-select-selection-search {
    margin-top: 8px;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
    margin: 8px 0px !important;
    padding: 30px 10px 0px 10px;
    opacity: 0.7;
  }
  @media screen and (max-width: 640px) {
    height: 36px !important;
    width: 42.55vw !important;
    .ant-select-selector {
      width: 42.55vw !important;
      height: 36px !important;
      font-size: 12px;
      opacity: 0.7;
    }
    .ant-select-selection-placeholder {
      font-size: 12px;
      margin: 5px 0px !important;
    }
  }
`;

export const SelectDropdownDiv = styled.div<any>`
  //dropdown
  .ant-select-dropdown {
    top: 33px !important;
    left: 0px !important;
  }
  .ant-select-item-option-content {
    color: #011759;
    opacity: 0.7;
  }
  .rc-virtual-list-scrollbar {
    display: ${props => props?.optionsLength > 2 && "initial !important"};
  }

  //tooltip
  .ant-tooltip {
    left: 102px !important;
    top: 38px !important;
  }
  .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
    right: 17px;
  }
  .ant-tooltip-arrow {
    width: 25px;
    height: 20px;
  }
  .ant-tooltip-arrow-content {
    width: 17px;
    height: 17px;
    bottom: 7px;
  }
  @media screen and (max-width: 640px) {
    .ant-tooltip {
      width: 218px;
      left: 3px !important;
      top: 38px !important;
    }
    .ant-tooltip-arrow {
      left: 138px;
    }
  }
`;

export const VerficationStatus = styled.img<any>`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 20px;
  cursor: pointer;
  @media screen and (max-width: 640px) {
    right: 10px;
    width: 35px;
    background-color: ${props => props?.$bgColor};
  }
`;
