import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { userInfoGet } from "../../../reducers/userInfo";
import LocalStorageService from "../../../services/LocalStorageService";
import SessionStorageService from "../../../services/SessionStorageService";
import { handleLogout } from "../../../shared/handlers/logout.handler";
import { handleCancelRetainSearchKey } from "../../../shared/handlers/requestHandlers";
import { getUserDetails } from "../../../shared/handlers/getUserLoginStatus";
import { AvatarOptions } from "../../../constants/mockData";
import { LogoImage } from "../../Image";
import { Label } from "../../Label";
import { Avatar } from "../../Profile";
import {
  AvatarArrow,
  Div,
  HeaderWrapper,
  LoginButton,
  NavAvatarWrapper,
  LogoDiv,
  LogoWrapper,
  PopOverContent,
  PopoverDiv,
  HidePopover,
  MessageDiv,
  Indicator,
  MessageWrapper,
} from "./Layout.styles";
import NavigationTab from "../NavigationTab/NavigationTab";
import SideNavigationTab from "../NavigationTab/SideNavigationTab";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { AppConfig } from "ui-constants";
import { getSelectedChat } from "reducers/chat";
import { marketingSuiteRoute } from "shared/handlers/publishModalhandler";

interface LayOutProps {
  title?: string;
  children?: React.ReactNode;
}

const Header = ({ title, children }: LayOutProps) => {
  const [currentRoute, setCurrentRoute] = useState("/dashboard");
  const isOnboarding = window.location.href.includes("onboarding");
  const chatInfo = useSelector((state: any) => state.chatInfo);
  const { data } = chatInfo;
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData } = userInfoState;
  const companyInfoState = useSelector((state: any) => state.companyInfo);
  const { companyInfoData } = companyInfoState;
  const [showSideNav, setShowSideNav] = useState(false);
  const [sideNavType, setSideNavType] = useState("");
  const totalUnreadMsgs = useSelector((state: any) => state.chatInfo?.totalUnreadMessage);
  const dashboardReducer = useSelector((state: any) => state.dashboard);
  const { dashboardData } = dashboardReducer;
  const userData = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData);
  const isOnboardingRoute = window.location.href.includes("onboarding");
  const [companyLogo, setCompanyLogo] = useState({ companyLogo: { fileUrl: "" } });
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state: any) => state.auth);
  const { authedUserData } = authState;
  const [hideOptionId, setHideOptionId] = useState(0);
  useEffect(() => {
    if (getUserDetails()) {
      dispatch(userInfoGet({})).then((res) => {
        SessionStorageService.set("user", res);
      });
    }
  }, [LocalStorageService]);

  const setImgState = (logoData) => {
    setCompanyLogo({ companyLogo: logoData?.companyLogo });
  };
  useEffect(() => {
    if (dashboardData?.companyLogo) {
      setImgState(dashboardData);
      LocalStorageService.set(AppConfig.STORAGE_KEYS.userData, { ...userData, companyLogo: dashboardData.companyLogo });
    } else {
      const logoData = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData);
      setImgState(logoData);
    }
  }, [dashboardData]);

  useEffect(() => {
    if (authedUserData?.ProfileFlag?.isProfileCompleted) {
      setHideOptionId(0);
    } else {
      setHideOptionId(2);
    }
  }, [authedUserData]);

  const handleClick = (item, e) => {
    if (item.cardName === "Logout") {
      handleLogout({ dispatch });
    }
    if (item.linkTo.toLowerCase() === "/marketingsuite") {
      e.preventDefault();
      marketingSuiteRoute(dispatch);
    }
    handleCancelRetainSearchKey();
  };

  const handleRoute = () => {
    setShowSideNav(false);
    if (!isOnboardingRoute) {
      history.push("/dashboard");
    }
    handleCancelRetainSearchKey();
  };

  const handleOnLogin = () => {
    history.push("/login");
  };

  const renderLink = (item) => {
    return (
      <Link
        onClick={(e) => handleClick(item, e)}
        to={item.linkTo}
        className={currentRoute === item.linkTo ? "active" : ""}
      >
        {<PopOverContent key={item.id}>{item.cardName}</PopOverContent>}
      </Link>
    );
  };
  const protectedLinkCheck = (item) => {
    if (item?.checkAuthAccess) {
      if (!isOnboarding) {
        return renderLink(item);
      }
    } else {
      return renderLink(item);
    }
  };

  const setSideNavState = (type) => {
    setShowSideNav(true);
    setSideNavType(type);
  };

  const communicationRoute = () => {
    if (data) {
      getSelectedChat(dispatch, (chatInfo.data = null));
    } else {
      history.push("/communication");
    }
  };

  return (
    <HeaderWrapper>
      <LogoWrapper>
        <LogoDiv>
          <LogoImage
            id="desktopImg"
            imageSrc="/images/DashBoard/venwiz-logo-white.svg"
            altMesg="logo"
            imageType="logo"
            onClick={handleRoute}
          />
          <LogoImage
            id="mobileImg"
            imageSrc="/images/DashBoard/venwiz-logo-white-text.svg"
            altMesg="logo"
            imageType="logo"
            onClick={handleRoute}
          />
        </LogoDiv>
      </LogoWrapper>
      {getUserDetails() ? (
        <NavAvatarWrapper>
          <NavigationTab></NavigationTab>
          {/* TODO: commented for future reference */}
          {/* <MessageWrapper
            $display={isOnboarding ? "none" : "block"}
            onClick={() => {
              setShowSideNav(false);
              communicationRoute();
            }}
          >
            <MessageDiv src="/images/mobile-message-icon.svg" alt="message icon" />
            {totalUnreadMsgs > 0 && <Indicator />}
          </MessageWrapper> */}
          <PopoverDiv
            placement="bottomRight"
            getPopupContainer={(trigger) => trigger.parentElement}
            content={<HidePopover>{AvatarOptions.map((item) => protectedLinkCheck(item))}</HidePopover>}
            trigger="hover"
          >
            <Div>
              <Avatar
                imgSrc={companyLogo?.companyLogo?.fileUrl}
                data={userInfoData?.firstName}
                onClick={() => {
                  checkForMobileScreen() && setSideNavState("popoverContent");
                }}
              />
              <Label text={""} htmlFor="avatar" fontVariant="bold" cursorType="pointer" />
              <AvatarArrow id="desktopImg" src="/images/DashBoard/arrow-down.svg" />
              {!isOnboarding && (
                <AvatarArrow
                  id="mobileImg"
                  src="/images/DashBoard/hamburger-icon.svg"
                  onClick={() => {
                    checkForMobileScreen() && !isOnboarding && setSideNavState("navbar");
                  }}
                />
              )}
              {showSideNav && (
                <SideNavigationTab
                  width={"50vw"}
                  contentType={sideNavType}
                  onClose={() => {
                    setShowSideNav(false);
                  }}
                ></SideNavigationTab>
              )}
            </Div>
          </PopoverDiv>
        </NavAvatarWrapper>
      ) : (
        <LoginButton type="primary" shape="round" onClick={handleOnLogin}>
          <img style={{ marginRight: "10px" }} src="/images/login-avatar.svg" />
          Login to Venwiz
        </LoginButton>
      )}
    </HeaderWrapper>
  );
};

export default Header;
