import React from 'react'
import styled from 'styled-components'

interface LabelBgProps {
    children: React.ReactNode,
    type: 'cardHeader'
}

const LabelBase = styled.label<Partial<LabelBgProps>>`
    position:absolute;
    margin: 30px 0px 0px 30px;
    width: ${props => `${props.theme.label.sizes[`${props.type}`].width}`};
    height: ${props => `${props.theme.label.sizes[`${props.type}`].height}`};
    background-color: ${props => `${props.theme.label.sizes[`${props.type}`].bgColor}`};
    padding: ${props => `${props.theme.label.sizes[`${props.type}`].padding}`};
    border-radius: ${props => `${props.theme.label.sizes[`${props.type}`].borderRadius}`};
    font-size: ${props => `${props.theme.label.sizes[`${props.type}`].fontSize}`};
    line-height: ${props => `${props.theme.label.sizes[`${props.type}`].lineHeight}`};
    font-weight: ${props => `${props.theme.label.sizes[`${props.type}`].fontWeight}`};

`

const LabelWithBg = ({ children, ...rest }: LabelBgProps) => {
    return (
        <LabelBase {...rest}>{children}</LabelBase>
    )
}

export default LabelWithBg