import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components";
import { Modal } from "antd";
import { Button } from "components/Button";
import OtpBox from 'components/Otp/OtpBox/OtpBox';
import { userInfoOtpVerify } from 'reducers/auth';
import { userInfoGet } from 'reducers/userInfo';
import { AppConfig } from 'ui-constants';

interface otpVerify {
  phoneNum: string,
}

const Base = styled.div`
  background-attachment: fixed;
`;

const ModalBox = styled(Modal)`
  width: 560px !important;
  & .ant-modal-content {
    border-radius: 60px;
  }
  & .ant-modal-header {
    border-radius: 60px 60px 0 0;
    border: 0px;
    padding: 60px 0px 16px 60px;
  }
  & .ant-modal-close {
    margin: 10px;
   }
  & .ant-modal-footer {
    text-align: center;
    border-top: 0px;
    display: flex;
    justify-content: center;
  }
  & .ant-modal-body {
    padding: 0px 60px 0px 60px;
    color: ${props => props.theme.colors.darkBlue};
    height: 52vh;
    overflow-y: auto;
    line-height: 170%;
    max-height: 400px;
  }
`;

const Title = styled.div`
  color: ${props => props.theme.colors.blue};
  font-weight: 600;
  font-size: 30px;
`;

const SubTitle = styled.div`
  font-weight: 300;
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  padding:60px 0px 40px 0px;
`;


function UpdateVerifyModal({ phoneNum }: otpVerify) {
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [otpStore, setOtpStore] = useState("");
  const dispatch = useDispatch();
  const authState = useSelector((state: any) => state.auth);
  const { userInfoOtpLoaded, userInfoOtpLoading, userInfoOtpSuccess } = authState

  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userUpdateResponse } = userInfoState;

  const { OTP } = AppConfig;

  useEffect(() => {
    if (userUpdateResponse && userUpdateResponse.mobileVerificationRequired) {
      setIsModalVisible(true)
    }
  }, [userUpdateResponse])

  const handleClick = () => {
    if (!userInfoOtpLoading) {
      dispatch(
        userInfoOtpVerify(
          JSON.stringify({
            mobileNo: `${phoneNum}`,
            otp: otpStore,
          })
        )
      ).then(res=>{
        setIsModalVisible(false)
        dispatch(userInfoGet({}));
      })
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = (e: any) => {
    setIsModalVisible(false);
    dispatch(userInfoGet({}));
  };
  const handleComplete = (otp: string) => {
    setOtpStore(otp)
  };
  return (
    <Base>
      <ModalBox
        centered={true}
        title={
          <Title>Update Verification</Title>
        }
        footer={
          <Button
            onClick={handleClick}
            variant="secondary"
            size="large"
            style={{ marginBottom: '60px' }}
          >
            SUBMIT
                    </Button>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        closeIcon={<img src="/images/xCircle.svg" alt='close button'></img>}
      >
        <SubTitle>
          Enter the OTP that’s been sent to your mobile number ending with ${phoneNum.slice(-3)}
        </SubTitle>
        <OtpBox
          value={phoneNum}
          type={'userInfoMobile'}
          text={'Enter OTP'}
          onComplete={handleComplete}
          reset
        />
      </ModalBox>
    </Base>
  )
}

export default UpdateVerifyModal
