import React, { useEffect, useState } from "react";
import { Checkbox as AntdCheckbox } from "antd";
import styles from "./Checkbox.module.scss";
import styled from "styled-components";

interface CheckboxProps {
  value: string | React.ReactNode;
  onChange: (e: boolean) => void;
  trueData?: Array<string>;
  isChecked?: boolean;
  key?: number;
  isDisabled?: boolean;
  setBlueBackground?:boolean;
  dataTrackingId?: string;
  marginBottom?: string
}
const CheckboxDiv=styled(AntdCheckbox)<any>`
.ant-checkbox + span {
  font-size:${props => props?.$setBlueBackground && "14px"};
  opacity:${props => props?.$setBlueBackground && "0.7"};
  font-weight:${props => props?.$setBlueBackground && "500"};
}
& .ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${props => props?.$setBlueBackground && props.theme.colors.blue};
}
.ant-checkbox-checked .ant-checkbox-inner:after {
  opacity:  ${props => props?.$setBlueBackground && "1"};
}
`
const Checkbox = (props: CheckboxProps) => {
  const { isChecked = false } = props;
  const handleOnChange = (e: any) => {
    props.onChange(e.target.checked);
  };

  return (
    <div className={styles.container}>
      <CheckboxDiv
        key={props.key}
        onChange={handleOnChange}
        checked={isChecked}
        style={styles}
        disabled={props.isDisabled}
        $setBlueBackground={props?.setBlueBackground}
        data-tracking-id={props?.dataTrackingId}
      >
        {props.value}
      </CheckboxDiv>
    </div>
  );
};
export default Checkbox;
