import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "components/Button/Button";
import { CardTag } from "components/Card";
import { LogoImage } from "components/Image";
import { Label } from "components/Label";
import UploadFile from "components/UploadFile/UploadFile";
import { fileUploadPost } from "reducers/fileUpload";
import { UserInfo } from "ContextApi/ContextApi";
import openNotification from "constants/antdNotification";
import ProfileInputHeading from "Profile/shared/Heading/Heading";
import { toggleIsPageEdited } from "reducers/profileCreation";

interface CertificationProps {
  inputType?: string;
  data?: any;
  onHandleAddCard?: (e: any) => void;
  onHandleAddButton?: (e: any) => void;
}

const Container = styled.div`
  width: 100%;
  height: auto;
`;
const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CertificateDiv = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const InputFlex = styled.div`
  display: block;
  max-width: 390px;
  `;
const DivWrapper = styled.div`
  margin-bottom: 5px;
  width: 61vw;
`;
const ButtonDiv = styled.div`
  padding: 10px;
`;
const FormWrapper = styled.div`
  display: flex;
`;

const Header = styled.div`
  color: #011759;

  font-sixe: 12px;
`;

const WarningImg = styled.img`
  width: 20px;
  height: 20px;
`;

const CertificationsContainer = ({
  inputType,
  onHandleAddCard,
}: CertificationProps) => {
  const [certificationInfoState, setCertificationInfoState] = useContext(
    UserInfo
  );

  const dispatch = useDispatch();
  const certificationState = useSelector(
    (state: any) => state.certificationInfo
  );
  const { certificationInfoGetdata } = certificationState;

  const buttonState = useSelector((state: any) => state.profileCreation);
  const { isPageEdited } = buttonState;
  const [tempDoc, setTempDoc] = useState([]);

  const [file, setFile] = useState();
  const [fileTitle, setFileName] = useState("");
  const [documentName, setDocumentName] = useState("");

  const [certificate, setCertificate] = useState([]);

  const [basicData, setBasicData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [technicalData, setTechnicalData] = useState([]);

  const [basicState, setBasicState] = useState(false);
  const [complianceState, setComplianceState] = useState(false);
  const [technicalState, setTechnicalState] = useState(false);
  const [basicFileError, setBasicFileError] = useState(false);
  const [technicalFileError, setTechnicalFileError] = useState(false);
  const [complianceFileError, setComplianceFileError] = useState(false);

  useEffect(() => {
    if (certificationInfoGetdata?.basic) {
      setBasicData(certificationInfoGetdata.basic);
      setComplianceData(certificationInfoGetdata.compliance);
      setTechnicalData(certificationInfoGetdata.technical);
      setCertificationInfoState(certificationInfoGetdata);
    }
  }, [certificationInfoGetdata]);

  const closeTag = (data: any) => {
    setCertificate(certificate.filter(item => item !== data));
    toggleIsPageEdited(dispatch, true)
  };

  const handleBasicDataAdd = (file: any) => {
    const fileName = fileTitle || file.name;
    const dupFileFlag = basicData?.some((item: any) => item?.name === fileName);
    if (file !== undefined && fileName.match(/\S/) && !dupFileFlag) {
      dispatch(fileUploadPost(file)).then(
        res => {
          setBasicData([
            ...basicData,
            {
              name: fileName,
              certificationFile: res,
            },
          ]);
          setTempDoc([]);
          onHandleAddCard({ documentName: fileName, ...res });
          toggleIsPageEdited(dispatch, true);
        },
        error => {
          openNotification(error?.message);
          setTempDoc([]);
          setBasicState(!basicState);
        }
      );
      setTempDoc([{ name: fileName, certificationFile: { fileSize: file } }]);
      // setBasicState(!basicState);
      setFileName("");
    }
  };

  const handleComplianceDataAdd = (file: any) => {
    const fileName = fileTitle || file.name;
    const dupFileFlag = complianceData?.some(
      (item: any) => item?.name === fileName
    );
    if (file !== undefined && fileName.match(/\S/) && !dupFileFlag) {
      dispatch(fileUploadPost(file)).then(
        res => {
          setComplianceData([
            ...complianceData,
            {
              name: fileName,
              certificationFile: res,
            },
          ]);
          setTempDoc([]);
          onHandleAddCard({ documentName: fileName, ...res });
          toggleIsPageEdited(dispatch, true);
        },
        error => {
          openNotification(error?.message);
          setTempDoc([]);
          setComplianceState(!complianceState);
        }
      );
      setTempDoc([{ name: fileName, certificationFile: { fileSize: file } }]);
      // setComplianceState(!complianceState);
      setFileName("");
    }
  };

  const handleTechnicalDataAdd = (file: any) => {
    const fileName = fileTitle || file.name;
    const dupFileFlag = technicalData?.some(
      (item: any) => item?.name === fileName
    );
    if (file !== undefined && fileName.match(/\S/) && !dupFileFlag) {
      dispatch(fileUploadPost(file)).then(
        res => {
          setTechnicalData([
            ...technicalData,
            {
              name: fileName,
              certificationFile: res,
            },
          ]);
          setTempDoc([]);
          onHandleAddCard({ documentName: fileName, ...res });
          toggleIsPageEdited(dispatch, true);
        },
        error => {
          setTempDoc([]);
          setTechnicalState(!technicalState);
          openNotification(error?.message);
        }
      );
      setTempDoc([{ name: fileName, certificationFile: { fileSize: file } }]);
      // setTechnicalState(!technicalState);
    }
  };

  const closeCard = (caseType, data: any) => {
    switch (caseType) {
      case "Close_Basic-Tag":
        setCertificationInfoState({
          ...certificationInfoState,
          basic: [
            ...certificationInfoState.basic.filter(item => {
              if (item.name !== data.name) {
                return item;
              }
            }),
          ],
        });

        setBasicData([
          ...basicData.filter(item => {
            if (item.name !== data.name) {
              return item;
            }
          }),
        ]);
        toggleIsPageEdited(dispatch, true);
        break;
      case "Close_Compliance-Tag":
        setCertificationInfoState({
          ...certificationInfoState,
          compliance: [
            ...certificationInfoState.compliance.filter(item => {
              if (item.name !== data.name) {
                return item;
              }
            }),
          ],
        });
        setComplianceData([
          ...complianceData.filter(item => {
            if (item.name !== data.name) {
              return item;
            }
          }),
        ]);
        toggleIsPageEdited(dispatch, true);
        break;
      case "Close_Technical-Tag":
        setCertificationInfoState({
          ...certificationInfoState,
          technical: [
            ...certificationInfoState.technical.filter(item => {
              if (item.name !== data.name) {
                return item;
              }
            }),
          ],
        });
        setTechnicalData([
          ...technicalData.filter(item => {
            if (item.name !== data.name) {
              return item;
            }
          }),
        ]);
        toggleIsPageEdited(dispatch, true);
        break;
      default:
    }
  };

  return (
    <>
      <Container>
        {
          <CertificateDiv>
            {inputType === "Basic" && (
              <DivWrapper>
                <Header>
                  <ProfileInputHeading
                    isStar={false}
                    name="Certification Details"
                  />
                </Header>
                <FormWrapper>
                  {!basicState && (
                    <UploadFile
                      name="basic"
                      onHandleFileUpload={(e: any) => {
                        setFile(e);
                        handleBasicDataAdd(e);
                      }}
                      onHandleFileName={(e: any) => {
                        setFileName(e.target.value.trim());
                        e.target.value.trim().length && toggleIsPageEdited(dispatch, true);
                      }}
                      getDocumentName={(e: any) => setDocumentName(e)}
                      onFileUploadError={(e: any) => {
                        setBasicFileError(e);
                      }}
                    />
                  )}
                </FormWrapper>
                <InputFlex>
                  {tempDoc.map(item => {
                    return (
                      <CardTag
                        onClose={() => closeTag(item)}
                        cardText={item.name}
                        data={item}
                        usedFor="fileUploadTag"
                        usedAt="experience"
                        cursorType="pointer"
                        fileSize={
                          item?.certificationFile?.fileSize?.size / 1000000
                        }
                        closable={false}
                        loading
                      />
                    );
                  })}
                  {basicData?.map(item => {
                    return (
                      <a
                        href={
                          buttonState.editButton &&
                          item?.certificationFile?.fileUrl
                        }
                      >
                        <CardTag
                          usedAt="experience"
                          closable={false}
                          data={item}
                          onClose={() => {
                            closeCard("Close_Basic-Tag", item);
                          }}
                          cardText={
                            item.name || item?.certificationFile?.fileName
                          }
                          usedFor="fileUploadTag"
                          cursorType="pointer"
                          fileSize={
                            item?.certificationFile?.fileSize?.size / 1000000 ||
                            item?.certificationFile?.fileSize / 1000000
                          }
                        />
                      </a>
                    );
                  })}
                </InputFlex>
              </DivWrapper>
            )}

            {inputType === "Compliance" && (
              <DivWrapper>
                <Header>
                  <ProfileInputHeading
                    isStar={false}
                    name="Certification Details"
                    info=""
                  />
                </Header>
                <FormWrapper>
                  {!complianceState && (
                    <UploadFile
                      name="compliance"
                      onHandleFileUpload={(e: any) => {
                        setFile(e);
                        handleComplianceDataAdd(e);
                        onHandleAddCard({ file, fileTitle });
                      }}
                      onHandleFileName={(e: any) => {
                        setFileName(e.target.value.trim());
                        e.target.value.trim().length && toggleIsPageEdited(dispatch, true);
                      }}
                      getDocumentName={(e: any) => setDocumentName(e)}
                      onFileUploadError={(e: any) => setComplianceFileError(e)}
                    />
                  )}
                  
                </FormWrapper>
                <InputFlex>
                  {tempDoc.map(item => {
                    return (
                      <CardTag
                        onClose={() => closeTag(item)}
                        cardText={item.name}
                        data={item}
                        usedFor="fileUploadTag"
                        cursorType="pointer"
                        fileSize={
                          item?.certificationFile?.fileSize?.size / 1000000
                        }
                        closable={false}
                        loading
                      />
                    );
                  })}
                  {complianceData?.map(item => {
                    if (item?.name)
                      return (
                        <a
                          href={
                            buttonState.editButton &&
                            item?.certificationFile?.fileUrl
                          }
                        >
                          <CardTag
                            usedAt="experience"
                            closable={false}
                            onClose={() =>
                              closeCard("Close_Compliance-Tag", item)
                            }
                            cardText={item?.name}
                            data={item}
                            usedFor="fileUploadTag"
                            cursorType="pointer"
                            fileSize={
                              item?.certificationFile?.file?.size / 1000000 ||
                              item?.certificationFile?.fileSize / 1000000
                            }
                          />
                        </a>
                      );
                  })}
                </InputFlex>
              </DivWrapper>
            )}

            {inputType === "Technical" && (
              <DivWrapper>
                <Header>
                  {" "}
                  <ProfileInputHeading
                    isStar={false}
                    name="Certification Details"
                  />
                </Header>
                <FormWrapper>
                  {!technicalState && (
                    <UploadFile
                      name="technical"
                      onHandleFileUpload={(e: any) => {
                        setFile(e);
                        handleTechnicalDataAdd(e);
                        onHandleAddCard({ file, fileTitle });
                      }}
                      onHandleFileName={(e: any) => {
                        setFileName(e.target.value.trim());
                        e.target.value.trim().length && toggleIsPageEdited(dispatch, true);
                      }}
                      getDocumentName={(e: any) => setDocumentName(e)}
                      onFileUploadError={(e: any) => setTechnicalFileError(e)}
                    />
                  )}
                  {/* {!technicalState && !editButton && (
                    <ButtonDiv>
                        <Button
                        onClick={() => {
                            if (technicalState) {
                              setTechnicalState(!technicalState);
                            }
                        }}
                        variant="blue"
                        size="small"
                        style={{
                            width: "60px",
                            marginTop: "0px",
                            height: "60px",
                            border: "1px solid white",
                        }}
                        >
                         <img src="/images/plusBttn.svg" />
                        </Button>
                    </ButtonDiv>
                    )} */}
                </FormWrapper>
                <InputFlex>
                  {tempDoc.map(item => {
                    return (
                      <CardTag
                        onClose={() => closeTag(item)}
                        cardText={item.name}
                        usedFor="fileUploadTag"
                        fileSize={
                          item?.certificationFile?.fileSize?.size / 1000000
                        }
                        closable={false}
                        loading
                      />
                    );
                  })}
                  {technicalData?.map(item => {
                    return (
                      <a
                        href={
                          buttonState.editButton &&
                          item?.certificationFile?.fileUrl
                        }
                      >
                        <CardTag
                          usedAt="experience"
                          closable={false}
                          onClose={() => closeCard("Close_Technical-Tag", item)}
                          cardText={item?.name}
                          usedFor="fileUploadTag"
                          cursorType="pointer"
                          fileSize={
                            item?.certificationFile?.file?.size / 1000000 ||
                            item?.certificationFile?.fileSize / 1000000
                          }
                        />
                      </a>
                    );
                  })}
                </InputFlex>
              </DivWrapper>
            )}
          </CertificateDiv>
        }
          <Wrapper>
            <Div>
              {basicFileError || technicalFileError || complianceFileError ? (
                <WarningImg src="/images/Auth/warning.svg" />
              ) : (
                <LogoImage
                  imageSrc="/images/ProfileCreation/info.svg"
                  altMesg="edit button"
                  imageType="icon"
                />
              )}
            </Div>
            <Label
              error={
                basicFileError || technicalFileError || complianceFileError
              }
              fontVariant="bold"
              htmlFor="brochureSize"
              text="Supports .pdf and .jpeg formats. Max. file size 10MB"
            />
          </Wrapper>
      </Container>
    </>
  );
};

export default CertificationsContainer;
