import React from 'react'
import styled from 'styled-components';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import { colors } from '../../Theme'

interface ProgressBarProps {
    percentageValue: number
}
const BarBase = styled.div`
width:150px;
font-size: 10px;
`;
const Percent = styled.div`
font-size: 30px;
color: ${(props) => props.theme.colors.blue};
font-weight: bold;
font-style: italic;
`;
const Title = styled.div`
width: 70px;
text-align: center;
font-size: 11px;
color:${(props) => props.theme.colors.darkBlue};
`;

function ProgressBar({ ...props }: ProgressBarProps) {

    return (
        <BarBase>
            <CircularProgressbarWithChildren
                value={props.percentageValue}
                strokeWidth={10}
                styles={buildStyles({
                    pathTransitionDuration: 0.9,
                    pathColor: `${colors.green}`,
                    trailColor: `${colors.darkBlue}`,
                    strokeLinecap: 'round',
                })}
            >
                <Percent>{props.percentageValue}%</Percent>
                <Title>Overall Completion</Title>
            </CircularProgressbarWithChildren>
        </BarBase>
    )
}

export default ProgressBar
