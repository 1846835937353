import React from 'react'
import { PasswordDiscription,Text,List,UnorderedList,FlexDiv,HideDiv } from './PasswordDesc.styles';
import { InfoCircleTwoTone, QuestionCircleTwoTone } from "@ant-design/icons";
const PasswordDesc=()=> {
    const renderPhoneView=()=>{
    return <UnorderedList>
              <List>
                <Text>Password should be atleast 10 characters with a mix of upper and lower case letters.</Text>
              </List>
              <List>
                <Text>Password should contain atleast 1 number or a special character.</Text>
              </List>
          </UnorderedList>
    }
    return (
        <>
        <FlexDiv>
          <HideDiv><InfoCircleTwoTone/></HideDiv>
          <PasswordDiscription>
          10+ char, upper & lower case, special char
          </PasswordDiscription>
        </FlexDiv>
        {renderPhoneView()}
      </>
    )
}

export default PasswordDesc;
