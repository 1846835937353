import React from "react";
import styled from "styled-components";
interface CardWithoutEditProps {
  cardType?:
    | "viewCard"
    | "referalCard"
    | "infoCard"
    | "cardWithDropletHeader"
    | "cardWithoutEdit";
  header?: boolean;
  userType?: "vendor" | "client" | "searchViews";
  children?: React.ReactNode;
  toolTipData?: string;
  cardHeader?: string;
  altMesg?: string;
  editable?: boolean;
  onHandleClick?:(e,index)=>void,
  indexValue?:any
}

const ImgWrap = styled.div`
  width: 100px;
  height: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding: 30px;
  cursor: pointer;
`;
const ImageTag = styled.img<Partial<CardWithoutEditProps>>`
  display: ${props =>
    props.theme.card.types["cardWithoutEdit"] ? "block" : "none"};
`;

const EditAndDelete = ({indexValue,onHandleClick}:CardWithoutEditProps) => {
  const handleDeleteClick = () => {

  };
  return (
    <ImgWrap key={indexValue} onClick={(e)=>onHandleClick(e,indexValue)}>
      <ImageTag  src="/images/ProfileCreation/trash.svg" altMesg="Delete"  />
    </ImgWrap>
  );
};

export default EditAndDelete;
