export const SCOPEOFWORK_REQUESTED = "scopeOfWorkInfo/SCOPEOFWORK_REQUESTED";
export const SCOPEOFWORK_SUCCESS = "scopeOfWorkInfo/SCOPEOFWORK_SUCCESS";
export const SCOPEOFWORK_FAILURE = "scopeOfWorkInfo/SCOPEOFWORK_FAILURE";

export const SCOPEOFWORK_POST_REQUESTED =
  "scopeOfWorkInfo/SCOPEOFWORK_POST_REQUESTED";
export const SCOPEOFWORK_POST_SUCCESS =
  "scopeOfWorkInfo/SCOPEOFWORK_POST_SUCCESS";
export const SCOPEOFWORK_POST_FAILURE =
  "scopeOfWorkInfo/SCOPEOFWORK_POST_FAILURE";

  export const SCOPEOFWORK_CREATE_REQUESTED =
  "scopeOfWorkInfo/SCOPEOFWORK_CREATE_REQUESTED";
export const SCOPEOFWORK_CREATE_SUCCESS =
  "scopeOfWorkInfo/SCOPEOFWORK_CREATE_SUCCESS";
export const SCOPEOFWORK_CREATE_FAILURE =
  "scopeOfWorkInfo/SCOPEOFWORK_CREATE_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: null,

  scopeOfWorkInfoGetData: null,
  scopeOfWorkInfoPostData: null,
  scopeOfWorkInfoCreateData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SCOPEOFWORK_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case SCOPEOFWORK_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        scopeOfWorkInfoGetData: action.result,
      };

    case SCOPEOFWORK_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case SCOPEOFWORK_POST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SCOPEOFWORK_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        scopeOfWorkInfoPostData: action.result,
      };
    case SCOPEOFWORK_POST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case SCOPEOFWORK_CREATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case SCOPEOFWORK_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        scopeOfWorkInfoCreateData: action.result,
      };
    case SCOPEOFWORK_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const scopeOfWorkInfoGet = (data) => ({
  types: [SCOPEOFWORK_REQUESTED, SCOPEOFWORK_SUCCESS, SCOPEOFWORK_FAILURE],
  promise: (client) => client.get(`scopeofwork_info_get_data`, {}),
});

export const scopeOfWorkInfoPost = (data) => ({
  types: [
    SCOPEOFWORK_POST_REQUESTED,
    SCOPEOFWORK_POST_SUCCESS,
    SCOPEOFWORK_POST_FAILURE,
  ],
  promise: (client) =>
    client.post("scopeofwork_info_post_data", {
      data,
    }),
});

export const scopeOfWorkInfoCreate = (data) => ({
  types: [
    SCOPEOFWORK_CREATE_REQUESTED,
    SCOPEOFWORK_CREATE_SUCCESS,
    SCOPEOFWORK_CREATE_FAILURE,
  ],
  promise: (client) =>
    client.post("scopeofwork_info_create_data", {
      data,
    }),
});