import { Types as VendorProfileTypes } from "./types";

export const ActionCreators: any = {
  getVendorProfileData: (data: any) => ({
    types: [
      VendorProfileTypes.VENDOR_PROFILE_GET_REQUESTED,
      VendorProfileTypes.VENDOR_PROFILE_GET_SUCCESS,
      VendorProfileTypes.VENDOR_PROFILE_GET_FAILURE,
    ],
    promise: client => client.get('get_vendor_profile',{}),
  }),

}

export default ActionCreators;
