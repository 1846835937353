export const OEMTAGS_REQUESTED = "oemTags/OEMTAGS_REQUESTED";
export const OEMTAGS_SUCCESS = "oemTags/OEMTAGS_SUCCESS";
export const OEMTAG_CREATE_SUCCESS = "oemTags/OEMTAG_CREATE_SUCCESS";
export const OEMTAGS_FAILURE = "oemTags/OEMTAGS_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,

  oemTagsData: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case OEMTAGS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case OEMTAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        oemTagsData: action.result,
      };
    
    case OEMTAG_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };

    case OEMTAGS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const getOemTags = () => ({
  types: [OEMTAGS_REQUESTED, OEMTAGS_SUCCESS, OEMTAGS_FAILURE],
  promise: (client) =>
    client.get("get_oem_tags_lib")
});

export const createOemTag = (data) => ({
  types: [OEMTAGS_REQUESTED, OEMTAG_CREATE_SUCCESS, OEMTAGS_FAILURE],
  promise: (client) =>
    client.get("create_oem_tag", data )
});
