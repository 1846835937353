import styled, { keyframes } from "styled-components";
import { Flex, Box } from "venwiz-ui";
import { colors } from "Theme";

export const LargeImageAndVideoFlexContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const GalleryThumbnailsFlexContainer = styled(Flex)`
  ::-webkit-scrollbar {
    height: 6px;  /* height of horizontal scrollbar ← You're missing this */
    width: 4px;   /* width of vertical scrollbar */
  }

  scrollbar-behavior: smooth;
  transition: all 0.3s ease-in-out;
`;

export const CaptionInputFieldContainerBox = styled(Box)<{$pulsingBorder?: boolean}>`
  @keyframes border-pulse {
    0% { border-color: ${colors.blue}; }
    25% { border-color: rgba(255, 255, 255, 1); }
    50% { border-color: ${colors.blue}; }
    75% { border-color: rgba(255, 255, 255, 0); }
    100% { border-color: ${colors.blue}; }
  }
  
  .caption-input-field-container {
    border-radius: 6px;
    animation-iteration-count: 3;
    border: 2px solid ${colors.blue};
    transition: all 0.3s ease-in-out;
    ${props => props?.$pulsingBorder ? "animation: border-pulse 5s" : ""};
  }
`;
