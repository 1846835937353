import Checkbox from "components/ClientCheckbox/Checkbox";
import { Work, stateGeoDbData } from "constants/mockData";
import React, { useEffect, useState } from "react";
import { Container, ModalDiv } from "./Modal.styles";
interface Props {
  type: string;
  data: any;
  onHandleStatesChange: (e: boolean, value: string) => void;
  onHandleScopeOfWorkChange: (e: any) => void;
  visibility: boolean;
  onClose: () => void;
  headerName: string;
}
const CapabilitiesModal = (props: Props) => {
  const { data } = props;
  const [selectedData, setSelectedData] = useState([]);
  const [stateDropdownDetails, setStateDropdownDetails] = useState([]);
  const [scopeOfWorkDetails, setScopeOfWorkDetails] = useState([]);

  const updateStateList = () => {
    if (data?.length) {
      setStateDropdownDetails([
        ...stateGeoDbData.filter(item => data.includes(item)),
        ...stateGeoDbData.filter(item => !data.includes(item)),
      ]);
    } else {
      setStateDropdownDetails(stateGeoDbData);
    }
  };

  const updateSofList = () => {
    if (data?.length) {
      const valueArr = data.map(item => item.value);
      setScopeOfWorkDetails([
        ...Work.states.filter(item => valueArr.includes(item.value)),
        ...Work.states.filter(item => !valueArr.includes(item.value)),
      ]);
    } else {
      setScopeOfWorkDetails(Work.states);
    }
  };
  useEffect(() => {
    if (props?.visibility) {
      props?.type === "states" ? updateStateList() : updateSofList();
    }
  }, [props?.visibility]);

  const onHandleScopeChange = (e, selectedValue) => {
    if (e) {
      props?.onHandleScopeOfWorkChange([...data, selectedValue]);
    } else {
      props?.onHandleScopeOfWorkChange(
        data.filter(item => {
          return item?.value !== selectedValue.value;
        }),
      );
    }
  };

  const onHandleLocationChange = (e, selectedValue) => {
    props?.onHandleStatesChange(e, selectedValue);
  };

  useEffect(() => {
    if (data?.length) {
      props?.type === "scopeOfWork"
        ? setSelectedData(
            data?.map(item => {
              return item?.value;
            }),
          )
        : setSelectedData(
            data?.map(item => {
              return item;
            }),
          );
    } else {
      setSelectedData([]);
    }
  }, [data]);

  const renderScopeOfWork = () => {
    return (
      <>
        {scopeOfWorkDetails.map((item, index) => {
          return (
            <Checkbox
              key={index + 1}
              isChecked={selectedData.includes(item.value)}
              value={item.value}
              onChange={e => onHandleScopeChange(e, item)}
              setBlueBackground
            />
          );
        })}
      </>
    );
  };

  const renderStates = () => {
    return (
      <>
        {stateDropdownDetails.map((item, index) => {
          return (
            <Checkbox
              key={index + 1}
              isChecked={selectedData.includes(item)}
              value={item}
              onChange={e => onHandleLocationChange(e, item)}
              setBlueBackground
            />
          );
        })}
      </>
    );
  };

  const renderContent = () => {
    return props.type === "scopeOfWork" ? renderScopeOfWork() : renderStates();
  };
  return (
    <Container>
      <ModalDiv
        title={props?.headerName}
        maskClosable={false}
        footer={null}
        visible={props.visibility}
        closeIcon={<img src="/images/close-blue.svg" alt="close button" />}
        maskStyle={{ background: " rgba(255, 255, 255, 0.3)", backdropFilter: "blur(12px)" }}
        onCancel={props.onClose}
      >
        {renderContent()}
      </ModalDiv>
    </Container>
  );
};

export default CapabilitiesModal;
