import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../InputBox/input";
import { ErrorMessage, useField } from "formik";
import { useSelector } from "react-redux";
import LogoImage from "components/Image/LogoImage/_LogoImage";

type usedFor =
  | "login"
  | "registration"
  | "profileCreation"
  | "addButton"
  | undefined;
type Sizes =
  | "small"
  | "medium"
  | "middle"
  | "large"
  | "extraLarge"
  | "semiMiddle"
  | "profileCreationLarge"
  | "profileCreationMedium"
  | "profileCreationSmall"
  | "registrationNew"
  | "registrationPhoneView"
  | undefined;

interface InputWithHeaderProps {
  change?: boolean;
  withHeader?: boolean;
  imgSrc?: string;
  usedFor?: usedFor;
  inputBoxSize?: Sizes;
  children?: string;
  inputType?: string;
  importance: string;
  label?: string;
  name: string;
  error?: any;
  touch?: any;
  onChange?: (e: object) => void;
  refValue?: boolean;
  placeholder?: string;
  disable?: boolean;
  buttonType?: boolean;
  max?: number;
  hidden?: boolean;
  value?: any;
  validationState?: boolean;
  labelSize?: string;
  inputFieldError?: boolean;
  inputFieldSuccess?: boolean;
  errorElement?: React.ReactNode | React.ReactChild | any;
  inputDataAttribute?: object;
  style?: React.CSSProperties;
  handleApiValidate?: (e: object) => void;
}
const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 22px;
  position: absolute;
  right: -20px;
  top: 25px;
`;

const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: auto;
  color: ${(props) => props.theme.colors.darkBlue};
  position: relative;
`;
const HeaderName = styled.div<any>`
  width: auto;
  font-size: ${(props) => props.labelSize || "14px"};
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
`;
export const ErrorMessageDiv = styled.div<any>`
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #be000b;
  position: absolute;
  bottom: -20px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 640px) {
    bottom: -18px;
    top:${props=>props?.$top || "calc(100% + 2px)"} ;
    font-size: 8px;
  }
`;

const ImageHolder = styled.div<{ imageType: "success-green" | "warning" }>`
  background-image: ${(props) => `url(/images/Auth/${props?.imageType}.svg)`};
  background-size: cover;
  margin-right: 3px;
  height: auto;
  width: 16px;
  height: 16px;
  @media only screen and (max-width: 640px) {
    width: 13px;
    height: 13px;
  }
`;

const Emptyheader = styled.div`
  margin-top: 7px;
`;

const InputWithHeader = ({
  hidden = true,
  label,
  withHeader,
  validationState,
  change,
  disable,
  refValue,
  max,
  onChange,
  buttonType,
  style,
  ...props
}: InputWithHeaderProps) => {
  const [field] = useField(props);
  const elementName = field.name;
  const authState = useSelector((state: any) => state.auth);

  const {
    checkIsDuplicateMobile,
    checkIsDuplicateEmail,
    checkDuplicateGstNo,
    userData,
  } = authState;

  useEffect(() => {
    change && onChange(field);
  }, [field.value]);

  useEffect(() => {
    if (userData !== null && props.usedFor === "registration") {
      field.value = userData[props.name];
    }
  }, []);

  return (
    <BoxContainer
      style={{ height: `${props.usedFor}` === "login" ? "auto" : "auto" }}
    >
      {props.children !== undefined ? (
        <HeaderName
          labelSize={props.labelSize}
          style={{
            height: `${props.usedFor}` === "login" ? "21px" : "18px",
            marginBottom: `${props.usedFor}` === "login" ? "15px" : "10px",
            opacity: `${props.usedFor === "profileCreation" ? "0.7" : "1"}`,
          }}
        >
          {props.children}
        </HeaderName>
      ) : (
        <></>
      )}

      <Input
        change={change}
        sizes={props.inputBoxSize}
        inputTextType={props.inputType}
        importanceType={props.importance}
        child={props.children}
        fields={field}
        otherProps={props}
        setRef={refValue}
        disabled={disable}
        placeholder={props.placeholder}
        buttonType={buttonType}
        onChange={onChange}
        value={props.value}
        name={props.name}
        max={max}
        inputFieldError={props.inputFieldError}
        inputFieldSuccess={props.inputFieldSuccess}
        dataAttribute={props.inputDataAttribute}
        style={style}
      />
      {props?.imgSrc && (
        <Div onClick={() => {}}>
          <LogoImage
            imageSrc={props?.imgSrc}
            altMesg="edit button"
            imageType="icon"
          />
        </Div>
      )}

      {(props.error[elementName] === "Required" ||
        props.error[elementName] !== undefined) &&
        props.touch[elementName] === true && (
          <ErrorMessageDiv $top={props?.name==="password"?"calc(100% + 5px)":"calc(100% + 2px)"}>
            <ImageHolder imageType="warning" />
            <ErrorMessage name={field.name} />
          </ErrorMessageDiv>
        )}

      {validationState &&
        (props.error[elementName] === "Required" ||
          props.error[elementName] !== undefined) &&
        props.touch[elementName] === true && (
          <ErrorMessageDiv >
            <ImageHolder imageType="warning" />
            <ErrorMessage name={field.name} />
          </ErrorMessageDiv>
        )}

      {!(
        props.error["email"] === "Required" ||
        props.error["email"] !== undefined
      ) &&
        elementName == "email" &&
        checkIsDuplicateEmail && (
          <ErrorMessageDiv>
            <ImageHolder imageType="warning" />
            This email id already exists
          </ErrorMessageDiv>
        )}
      {!(
        props.error["phoneNumber"] === "Required" ||
        props.error["phoneNumber"] !== undefined
      ) &&
        elementName == "phoneNumber" &&
        checkIsDuplicateMobile && (
          <ErrorMessageDiv>
            <ImageHolder imageType="warning" />
            This phone number already exists
          </ErrorMessageDiv>
        )}
      {!(
        props.error["phoneNo"] === "Required" ||
        props.error["phoneNo"] !== undefined
      ) &&
        elementName == "phoneNo" &&
        checkIsDuplicateMobile && (
          <ErrorMessageDiv>
            <ImageHolder imageType="warning" />
            This phone number already exists
          </ErrorMessageDiv>
        )}
      {/* {!(
        props.error["gstNo"] === "Required" ||
        props.error["gstNo"] !== undefined
      ) &&
        elementName == "gstNo" &&
        checkDuplicateGstNo && (
          <ErrorMessageDiv>
            <ImageHolder imageType="warning" />
            This GST number already exists
          </ErrorMessageDiv>
        )} */}
      {/* {!(
        props.error["gstNo"] === "Required" ||
        props.error["gstNo"] !== undefined
      ) &&
        elementName == "gstNo" &&
        checkDuplicateGstNo && (
          <ErrorMessageDiv>
            <ImageHolder imageType="warning" />
            This GST number already exists
          </ErrorMessageDiv>
        )} */}
      {props?.errorElement}
    </BoxContainer>
  );
};

export default InputWithHeader;
