import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Button } from "components/Button";
import InputBoxWithIcon from "components/Input/InputBoxWithIcon/input";
import Meter from "components/PasswordMeter/Meter";
import Validator from "components/PasswordValidator/Validator";
import { showEditReducer } from "reducers/profileCreation";

interface MyFormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentInputs = styled.div`
  height: calc(100vh - 225px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
`;
const BasicInformationMeassage = styled.div`
  padding: 30px 46px 30px 30px;
  border-radius: 12px;
  height: 111px;
  width: 100%;
  background-color: ${props => `${props.theme.colors.lightGray}`};
`;

const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
`;
const InputFlexWrapper = styled.div`
  display: flex;
  padding-bottom: 30px;
  width: 550px;
  align-items: center;
  justify-content: space-between;
`;

const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;
const FormWrapper = styled.div`
  display: flex;
  max-width: 440px;
  flex-direction: column;
`;
const ProfileImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 178px;
  height: 178px;
  border-radius: 12px;
  margin: 40px;
  background-color: ${props => `${props.theme.colors.lightGray}`};
`;
const Selected = styled.div`
  width: auto;
  height: auto;
  border: 1px solid black;

  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const ButtonWrapper = styled.div`
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const InputFlexColumn = styled.div`
  max-width: 550px;
  display: block;
  padding-bottom: 40px;

  max-width: 550px;
  display: block;
  padding-bottom: 40px;
`;
const InputFlex = styled.div`
  display: block;
  max-width: 550px;
  padding-bottom: 40px;
`;
const FlexWrapper = styled.div`
  padding-top: 12px;
  max-width: 550px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  height: auto;
`;
const InputWrap = styled.input`
  position: relative;
  right: 0px;
  left: -170px;
  opacity: 0;
  z-index: 1;
  width: 160px;
  height: 60px;
  top: 27px;
  bottom: 0px;
`;
const BrochureDiv = styled.div`
  display: flex;
`;
const InputCountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;
const LabelWrap = styled.div`
  padding-bottom: 10px;
`;

const Divs = styled.div`
  display: flex;
  height: auto;
  margin-bottom: 30px;
`;
const FormikForm = styled(Form)`
  overflow: auto;
`;

const ChangePassword = () => {
  const [passwordStrength, setPasswordStrength] = useState(0);
  const dispatch = useDispatch();

  const initialValues: MyFormValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validate = Yup.object({
    // currentPassword: Yup.string()
    //   .min(8, "Please enter your password in the correct format")
    //   .max(50, "Max 50 characters allowed")
    //   .required("Please enter a password"),
    newPassword: Yup.string()
      .min(8, "Please enter your password in the correct format")
      .max(50, "Max 50 characters allowed")
      .required("Please enter a password"),
    // confirmPassword: Yup.string()
    //   .min(8, "MPlease enter your password in the correct format")
    //   .max(50, "Max 50 characters allowed")
    //   .required("Please enter a password"),
  });

  const buttonState = useSelector((state: any) => state.profileCreation);

  const handleClick = () => {
    showEditReducer(dispatch, !buttonState.editButton);
  };

  useEffect(() => {
    showEditReducer(dispatch, (buttonState.editButton = true));
  }, []);

  return (
    <>
      <ContentHeader>Change Password</ContentHeader>
      <ContentInputs>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validate}
            isInitialValid={validate.isValidSync(initialValues)}
            onSubmit={(values, actions) => {
              // dispatch({ type: AUTH_REGISTRATION, result: values });

              actions.setSubmitting(false);
              // handleMobileOtp(true);
            }}
          >
            {formik => (
              <FormikForm>
                <InputBoxWithIcon
                  withHeader={true}
                  inputBoxSize="middle"
                  usedFor="registration"
                  name="currentPassword"
                  formikError={formik.errors}
                  label="password"
                  touchedResponse={formik.touched}
                  placeholder="Enter Current Password"
                >
                  Current Password
                </InputBoxWithIcon>
                <InputBoxWithIcon
                  withHeader={true}
                  inputBoxSize="middle"
                  usedFor="registration"
                  handlePassword={(e: number) => {
                    setPasswordStrength(e);
                  }}
                  name="newPassword"
                  formikError={formik.errors}
                  label="password"
                  touchedResponse={formik.touched}
                  placeholder="Enter New Password"
                >
                  New Password
                </InputBoxWithIcon>
                <Divs>
                  <Meter percentageValue={passwordStrength} />
                </Divs>
                <Divs>
                  <Validator formikData={formik} />
                </Divs>
                <InputBoxWithIcon
                  withHeader={true}
                  inputBoxSize="middle"
                  usedFor="registration"
                  name="confirmPassword"
                  formikError={formik.errors}
                  label="password"
                  touchedResponse={formik.touched}
                  placeholder="Enter New Password"
                >
                  Confirm Password
                </InputBoxWithIcon>
                <ButtonWrapper>
                  {buttonState.editButton ? (
                    <Button
                      onClick={handleClick}
                      variant="primary"
                      size="middle"
                      style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                    >
                      Edit
                    </Button>
                  ) : (
                      <Button
                        onClick={handleClick}
                        variant="secondary"
                        size="middle"
                        style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                      >
                        Save
                      </Button>
                    )}
                </ButtonWrapper>
              </FormikForm>
            )}
          </Formik>
        </FormWrapper>
      </ContentInputs>
      {/* <ButtonWrapper>
        {buttonState.editButton ? (
          <Button
            onClick={handleClick}
            variant="primary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Edit
          </Button>
        ) : (
          <Button
            onClick={handleClick}   
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Save
          </Button>
        )}
      </ButtonWrapper> */}
    </>
  );
};

export default ChangePassword;
