import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import { Dispatch } from "redux";
import { Location } from "history";
import { OnBoardingAction } from "NewOnboarding/NewOnboardingReducers";
import { VerificationStatus } from "Profile/enums";
import SessionStorageService from "services/SessionStorageService";

type ICustomHistoryType = { location: Location; push: (x?: any) => void };

export const restrictOrNavigateOnBoarding = (history: ICustomHistoryType) => {
  const { PUBLIC_ROUTES } = AppConfig;
  const isOnboarding = window.location.href.includes("onboarding");
  const isPublicRoute = PUBLIC_ROUTES.includes(history.location.pathname);
  const localStorageData = LocalStorageService.get(
    AppConfig.STORAGE_KEYS.userData
  );

  const ROUTE_TO_BE_VIEWED_AFTER_LOGIN = getPreviousGuestVisitedRouteOrMyProfileRoute();

  const navigateTo = (route) => {
    if (isOnboarding || isPublicRoute) {
      history.push(route);
    } else {
      history.push(`${history.location.pathname}${history.location.search}`);
    }
  };

  if (localStorageData?.secondaryUserVerificationStatus) {
    /** secondary user - redirections */
    const secUserVerifStatus: keyof typeof VerificationStatus =
      localStorageData?.secondaryUserVerificationStatus;
    switch (secUserVerifStatus) {
      case "verified":
        /** secondary user who has completed onboarding */
        if (sessionStorage.getItem("guestVisitedLinks")?.length) {
          history.push(sessionStorage.getItem("guestVisitedLinks"));
          sessionStorage.removeItem("guestVisitedLinks")
        } else {
          navigateTo(ROUTE_TO_BE_VIEWED_AFTER_LOGIN);
        }
        break;

      case "inProgress":
      case "documentPending":
      case "notVerified":
        /** opens transition screen which shows registration status */
        history.push("/onboarding");
        break;

      default:
        break;
    }
  } else if (localStorageData?.ProfileFlag?.userOnboarded) {
    /** primary user who has completed onboarding */
    if (sessionStorage.getItem("guestVisitedLinks")?.length) {
      history.push(sessionStorage.getItem("guestVisitedLinks"));
      sessionStorage.removeItem("guestVisitedLinks")
    } else if(history.location.pathname.includes('open-jobs')){
      if(sessionStorage.getItem("openJobsTrackingLinks")?.length){
        if (
          sessionStorage.getItem("openJobsTrackingLinks") ===
          `${history.location.pathname}${history.location.search}`
        ) {
          history.push(sessionStorage.getItem("openJobsTrackingLinks"));
        } else {
          history.push(
            `${history.location.pathname}${history.location.search}`
          );
        }
      }else{
        history.push(`${history.location.pathname}${history.location.search}`);
      }
    } else {
      navigateTo(ROUTE_TO_BE_VIEWED_AFTER_LOGIN);
    }
  } else {
    /** user who is still in onboarding phase */
    history.push("/onboarding");
  }
};

export const NavigateOrRestrictUserFromOnBoardingStatus = ({
  GSTN,
  dispatch,
  history,
  EMAIL,
}: {
  GSTN: string;
  dispatch: Dispatch<any>;
  history: { location: Location; push: (x?: any) => void };
  EMAIL: string;
}) => {
  const { userData, userOnboarded } = AppConfig.STORAGE_KEYS;
  if (GSTN) {
    dispatch(OnBoardingAction.getOnboardingData(GSTN, EMAIL)).then((resp) => {
      /** setting local-storage ProfileFlag object with 'userOnboarded' key via GET API(new-onboarding)  */
      LocalStorageService.set(userData, {
        ...LocalStorageService.get(userData),
        ProfileFlag: {
          ...LocalStorageService.get(userData)?.ProfileFlag,
          [userOnboarded]: resp?.[userOnboarded],
        },
      });

      /** for secondary user */
      if (resp?.secondaryUser) {
        LocalStorageService.set(userData, {
          ...LocalStorageService.get(userData),
          secondaryUserVerificationStatus:
            resp?.secondaryUser?.gstnVerificationStatus ||
            VerificationStatus.documentPending,
        });
      }

      restrictOrNavigateOnBoarding(history);
    });
  }
};

export const getPreviousGuestVisitedRouteOrMyProfileRoute = () => {
  const { INITIAL_PAGE_TO_BE_VIEWED, PUBLIC_ROUTES } = AppConfig;
  const PREV_GUEST_VISITED_LINK = SessionStorageService.get(AppConfig.STORAGE_KEYS.prevGuestRouteOfExistingUser);
  if (PREV_GUEST_VISITED_LINK) {
    const IS_NOT_PUBLIC_ROUTE = !PUBLIC_ROUTES?.includes(PREV_GUEST_VISITED_LINK);
    if (IS_NOT_PUBLIC_ROUTE) {
      SessionStorageService.remove(AppConfig.STORAGE_KEYS.prevGuestRouteOfExistingUser);
      return PREV_GUEST_VISITED_LINK as string;
    }
  }

  return INITIAL_PAGE_TO_BE_VIEWED.myProfile;
};
