import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
export const PEOPLE_REQUESTED = "people/PEOPLE_REQUESTED";
export const PEOPLE_SUCCESS = "people/PEOPLE_SUCCESS";
export const PEOPLE_FAILURE = "people/PEOPLE_FAILURE";

export const MANPOWER_STRENGTH_REQUESTED = "people/MANPOWER_STRENGTH_REQUESTED";
export const MANPOWER_STRENGTH_SUCCESS = "people/MANPOWER_STRENGTH_SUCCESS";
export const MANPOWER_STRENGTH_FAILURE = "people/MANPOWER_STRENGTH_FAILURE";
export const MANPOWER_UPDATE_STRENGTH_SUCCESS =
  "people/MANPOWER_UPDATE_STRENGTH_SUCCESS";

export const PEOPLE_POST_FAILURE = "people/PEOPLE_POST_FAILURE";
export const PEOPLE_POST_SUCCESS = "people/PEOPLE_POST_SUCCESS";
export const PEOPLE_POST_REQUESTED = "people/PEOPLE_POST_REQUESTED";

export const PEOPLE_DELETE_FAILURE = "people/PEOPLE_DELETE_FAILURE";
export const PEOPLE_DELETE_SUCCESS = "peoplePEOPLE_DELETE_SUCCESS";
export const PEOPLE_DELETE_REQUESTED = "people/PEOPLE_DELETE_REQUESTED";

const getGstn =
  SessionStorageService.get("gstNum") || LocalStorageService.get("gstNum");

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  peopleData: undefined, //get
  peopleGetData: undefined, //post
  peopleDeletedData: undefined,

  manpowerDataLoading: false,
  manpowerDataLoaded: false,
  manpowerGetData: undefined,
  manPowerUpdateDate: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PEOPLE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case PEOPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        peopleData: action.result,
      };

    case PEOPLE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case MANPOWER_STRENGTH_REQUESTED:
      return {
        ...state,
        manpowerDataLoading: true,
      };

    case MANPOWER_STRENGTH_SUCCESS:
      return {
        ...state,
        manpowerDataLoading: false,
        manpowerDataLoaded: true,
        error: false,
        manpowerGetData: action.result,
      };

    case MANPOWER_STRENGTH_FAILURE:
      return {
        ...state,
        manpowerDataLoading: false,
        manpowerDataLoaded: true,
        error: true,
        errorData: action.error.message,
      };

    case MANPOWER_UPDATE_STRENGTH_SUCCESS:
      return {
        ...state,
        manpowerDataLoading: false,
        manpowerDataLoaded: true,
        error: false,
        manpowerUpdateData: action.result,
      };
    case PEOPLE_POST_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case PEOPLE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        peopleGetData: action.result,
      };

    case PEOPLE_POST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case PEOPLE_DELETE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case PEOPLE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        peopleDeletedData: action.result,
      };

    case PEOPLE_DELETE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error,
      };

    default:
      return state;
  }
};
export const peopleGet = (data) => ({
  types: [PEOPLE_REQUESTED, PEOPLE_SUCCESS, PEOPLE_FAILURE],
  promise: (client) => client.get(`people_get_data`, {}),
});

export const peopleUpdate = (data) => ({
  types: [PEOPLE_REQUESTED, PEOPLE_SUCCESS, PEOPLE_FAILURE],
  promise: (client) => client.get("people_update_data", {}),
});

export const peopleDelete = (data) => ({
  types: [
    PEOPLE_DELETE_REQUESTED,
    PEOPLE_DELETE_SUCCESS,
    PEOPLE_DELETE_FAILURE,
  ],
  promise: (client) => client.del("people_delete_data", { data }),
});

export const peoplePost = (data) => ({
  types: [PEOPLE_POST_REQUESTED, PEOPLE_POST_SUCCESS, PEOPLE_POST_FAILURE],
  promise: (client) =>
    client.post("people_create_data", {
      data,
    }),
});
export const manpowerStrengthCreate = (data) => ({
  types: [
    MANPOWER_STRENGTH_REQUESTED,
    MANPOWER_STRENGTH_SUCCESS,
    MANPOWER_STRENGTH_FAILURE,
  ],
  promise: (client) =>
    client.post("manpower_strength_create_data", {
      data,
    }),
});
export const manpowerStrengthPost = (data) => ({
  types: [
    MANPOWER_STRENGTH_REQUESTED,
    MANPOWER_UPDATE_STRENGTH_SUCCESS,
    MANPOWER_STRENGTH_FAILURE,
  ],
  promise: (client) =>
    client.post("manpower_strength_update_data", {
      data,
    }),
});
export const manpowerStrengthGet = (data) => ({
  types: [
    MANPOWER_STRENGTH_REQUESTED,
    MANPOWER_STRENGTH_SUCCESS,
    MANPOWER_STRENGTH_FAILURE,
  ],
  promise: (client) => client.get(`manpower_strength_get_data`, {}),
});
