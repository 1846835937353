import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "components/Button/Button";
import CollapseComponent from "components/Collapse";
import {
  certificationInfoCreate,
  certificationInfoGet,
  certificationInfoPost,
} from "reducers/certificationInfo";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import {
  profileLayoutError,
  resetProfileCompletionStatus,
  showEditReducer,
  toggleIsPageEdited,
} from "reducers/profileCreation";
import { UserInfo } from "ContextApi/ContextApi";
import { certificationLocalizationData } from "ui-constants/localizationData";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import notificationFunc from "components/Notification";
import { getGstn } from "constants/auth";
import LocalStorageService from "services/LocalStorageService";
import ApiConstant from "constants/ApiConstant";
import { updateProfileFlagToTrue } from "shared/handlers/updateProfiileFlag.handler";
import { AppConfig } from "ui-constants";
import { ButtonWrapper } from "Profile/Profile.styles";

const ContentInputs = styled.div`
  height: calc(100vh - 291px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
  margin-top: 10px;
`;

const FormWrapper = styled.div``;

const Certifications = () => {
  const [isDataSaving, setIsDataSaving] = useState(false);

  const userInformation = {
    basic: [],
    compliance: [],
    technical: [],
  };
  const certificationState = useSelector(
    (state: any) => state.certificationInfo
  );
  const {
    certificationInfoGetdata,
    certificationInfoPostData,
    certificationInfoCreateData,
  } = certificationState;

  const [dropdownSelected, setDropdownSelected] = useState([]);
  const [certificationInfoState, setCertificationInfoState] = useState(
    userInformation
  );
  const buttonState = useSelector((state: any) => state.profileCreation);
  const isOnboarding =  window.location.href.includes("onboarding");
  const { isPageEdited } = buttonState;
  const dispatch = useDispatch();
  const history = useHistory();
  const localStorageUserData = LocalStorageService.get(
    AppConfig.STORAGE_KEYS.userData
  );
  let certificateId = null;
  const profileCompletionData = useSelector(
    (state: any) => state.profileCreation
  ).profileCompletionStatus;
  const handleClick = type => {
    if (type === "save") {
      const gstn=getGstn();
      setIsDataSaving(true);
      certificateId = certificationInfoGetdata?.certificateId;
      if (certificationInfoGetdata?.certificateId) {
        dispatch(
          certificationInfoPost({
            certificateId: certificateId,
            gstn,
            ...certificationInfoState,
          })
        )
          .then(res => {
            setIsDataSaving(false);
            notificationFunc("success");
            dispatch(dashboardPercentageGet({}));
          })
          .catch(() => {
            setIsDataSaving(false);
          });
      } else {
        dispatch(
          certificationInfoCreate({
            gstn,
            ...certificationInfoState,
          })
        ).then(res => {
          dispatch(dashboardPercentageGet({}));
          resetProfileCompletionStatus(dispatch);
          updateProfileFlagToTrue('isCertificatesCompleted')
          /**
           * As Certifications is last section, 
           * hence setting isProfileCompleted to true
          */ 
          updateProfileFlagToTrue('isProfileCompleted') 
          history.push("/dashboard");
        });
      }
    }
  };

  const renderCard = (element: string) => {
    setDropdownSelected([...dropdownSelected, element]);
  };

  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false)
    if(isOnboarding){
      if (localStorageUserData?.isNewUser) {
        if (localStorageUserData?.ProfileFlag?.isProfileCompleted) {
          history.push("/dashboard");
        }
      }
    }
  }, []);
  useEffect(() => {
    if (
      isOnboarding &&
      profileCompletionData.hasOwnProperty("profileCompleteness")
    ) {
      if (profileCompletionData?.profileCompleteness) {
        history.push("/userinfo");
      } else {
        if (profileCompletionData?.companyinfoCompleteness) {
          if (profileCompletionData?.capabilitiesCompleteness) {
            if (profileCompletionData?.experienceCompleteness) {
              if (profileCompletionData?.certificationsCompleteness) {
                dispatch(certificationInfoGet());
              }
            } else {
              history.push("/onboarding/experience");
            }
          } else {
            history.push("/onboarding/capabilities");
          }
        } else {
          history.push("/onboarding/companyInfo");
        }
      }
    } else {
      dispatch(certificationInfoGet());
    }
  }, [profileCompletionData]);


  useEffect(() => {
    if (certificationInfoPostData || certificationInfoCreateData) {
      //  dispatch(certificationInfoGet({}));
    }
  }, [certificationInfoPostData, certificationInfoCreateData]);

  const addBasicCard = item => {
    setCertificationInfoState({
      ...certificationInfoState,
      basic: [
        ...certificationInfoState.basic,
        {
          name: item.documentName,
          certificationFile: {
            fileName: item?.fileName,
            fileUrl: item?.fileUrl,
            fileSize: item?.fileSize,
          },
        },
      ],
    });
    toggleIsPageEdited(dispatch, true)
  };

  const addComplianceCard = item => {
    setCertificationInfoState({
      ...certificationInfoState,
      compliance: [
        ...certificationInfoState.compliance,
        {
          name: item?.documentName,
          certificationFile: {
            fileName: item?.fileName,
            fileUrl: item?.fileUrl,
            fileSize: item?.fileSize,
          },
        },
      ],
    });
    let editCurrentData = certificationInfoState;
    toggleIsPageEdited(dispatch, true)
  };
  const addTechnicalCard = item => {
    setCertificationInfoState({
      ...certificationInfoState,
      technical: [
        ...certificationInfoState.technical,
        {
          name: item?.documentName,
          certificationFile: {
            fileName: item?.fileName,
            fileUrl: item?.fileUrl,
            fileSize: item?.fileSize,
          },
        },
      ],
    });
    toggleIsPageEdited(dispatch, true)
  };

  useEffect(() => {
    profileLayoutError(dispatch, false);
    toggleIsPageEdited(dispatch, false);
  }, []);
  return (
    <>
      <UserInfo.Provider
        value={[certificationInfoState, setCertificationInfoState]}
      >
        <ContentInputs>
          <FormWrapper>
            <CollapseComponent
              headerName="Basic"
              subHeader={certificationLocalizationData.basicInfo}
              usedFor="onboarding-certification"
              showDeleteOption={false}
              defaultActiveKey={1}
              onHandleAddCard={e => {
                addBasicCard(e);
              }}
              onHandleAddButton={() => {}}
            />
            <CollapseComponent
              headerName="Technical"
              subHeader={certificationLocalizationData.technicalInfo}
              usedFor="onboarding-certification"
              showDeleteOption={false}
              defaultActiveKey={1}
              onHandleAddCard={e => {
                addTechnicalCard(e);
              }}
            />
            <CollapseComponent
              headerName="Compliance"
              subHeader={certificationLocalizationData.complianceInfo}
              usedFor="onboarding-certification"
              showDeleteOption={false}
              defaultActiveKey={1}
              onHandleAddCard={e => {
                addComplianceCard(e);
              }}
            />
          </FormWrapper>
        </ContentInputs>
        <ButtonWrapper $padding={"19px 0px 20px 0px"}>
          <ProfileCreationButtons
            isloading={isDataSaving}
            usedAt={"onboarding"}
            buttonText={isOnboarding ? "SAVE & SUBMIT" : "SAVE CHANGES"}
            lastProfileSection={isOnboarding} // during on boarding certification is the last section but not in edit.
            pagination={true}
            pageNumber={5}
            state={ isOnboarding ? false : !isPageEdited }
            handleStateChange={value => {
              if (value === "cancel") {
              } else {
                handleClick(value);
              }
            }}
          ></ProfileCreationButtons>
        </ButtonWrapper>
      </UserInfo.Provider>
    </>
  );
};

export default Certifications;
