import { colors } from "Theme";
import styled from "styled-components";
import { Flex, Typography } from "venwiz-ui";
import { BREAK_POINTS } from "ui-constants/mediaQueries.constants";
import { AutoComplete } from "antd";
import { AutoCompleteProps } from "antd/lib/auto-complete/index";

const IS_SCREEN_WIDTH_GREATER_480px = window.innerWidth > BREAK_POINTS.xs;

export const FlexRow = styled(Flex)`
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  input::placeholder {
    color: ${colors.placeholderGray};
  }
`;

/** max height for menuList of Select Dropdown  */
const MAX_MENU_HEIGHT = 130;

export const MODAL_STYLES = {
  COMMON: {
    SELECT_STYLES: {
      /** styles for Select */
      styles: {
        container: (baseStyles, props) => {
          return {
            ...baseStyles,
            height: "40px",
            width: "100%",
          };
        },
        menu: (baseStyles, props) => {
          return {
            ...baseStyles,
            marginTop: "24px",
          };
        },
        menuList: (baseStyles, props) => {
          return {
            ...baseStyles,
            backgroundColor: "hsl(0,0%,100%)",
            boxShadow:
              "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
          };
        },
        control: (baseStyles, props) => {
          return {
            ...baseStyles,
            "&:hover": { border: "0px", boxShadow: "none" },
            "&:focus": { border: "0px", boxShadow: "none" },
            width: "100%",
            height: "100%",
            borderRadius: "8px",
          };
        },
        option: (defaultStyles, props) => ({
          ...defaultStyles,
          color: colors.darkBlue,
          fontSize: "14px",
        }),
        placeholder: (defaultStyles, props) => ({
          ...defaultStyles,
          color: colors.placeholderGray,
        }),
      },
      isClearable: true,
      borderRadius: "8px",
      menuPlacement: "auto",
      maxMenuHeight: MAX_MENU_HEIGHT,
    },
    TEXT_INPUT_STYLES: {
      height: "40px",
      padding: "12px",
      // width: IS_SCREEN_WIDTH_GREATER_480px ? "300px" : "205px",
    },
  },
  PLANT_MODAL: {
    /** Used at measurement related inputs */
    MEASUREMENT_AUTOSELECT_INPUT_STYLES: {
      styles: {
        container: (baseStyles, props) => {
          return {
            ...baseStyles,
            height: "40px",
            width: "130px",
          };
        },
        control: (baseStyles, props) => ({
          ...baseStyles,
          "&:hover": {
            border: "0px",
            boxShadow: "none",
          },
          "&:focus": {
            border: "0px",
            boxShadow: "none",
          },
          height: "100%",
          border: "0px",
          width: "100%",
          boxShadow: "none",
          borderRadius: "0px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          backgroundColor: "rgba(33, 94, 201, 0.10)",
        }),
        menu: (baseStyles, props) => {
          return {
            ...baseStyles,
            // marginTop: "24px",
            // maxWidth: "calc( 100% + 12px )",
          };
        },
        menuList: (baseStyles, props) => {
          return {
            ...baseStyles,
            backgroundColor: "hsl(0,0%,100%)",
            boxShadow:
              "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
          };
        },
        option: (defaultStyles, props) => ({
          ...defaultStyles,
          color: colors.darkBlue,
          fontSize: "14px",
        }),
        placeholder: (defaultStyles, props) => ({
          ...defaultStyles,
          color: colors.placeholderGray,
        }),
      },
      isClearable: true,
      borderRadius: "8px",
      menuPlacement: "auto",
      maxMenuHeight: MAX_MENU_HEIGHT,
    },
  },
  MACHINE_MODAL: {
    /** Used at all dropdown related inputs of machine modal */
    AUTOSUGGEST_DROPDOWN_INPUT_STYLES: {
      styles: {
        container: (baseStyles, props) => {
          return {
            ...baseStyles,
            height: "40px",
            // width: "130px",
            // width: "100%",
          };
        },
        control: (baseStyles, props) => ({
          ...baseStyles,
          "&:hover": {
            border: "0px",
            boxShadow: "none",
          },
          "&:focus": {
            border: "0px",
            boxShadow: "none",
          },
          height: "100%",
          border: "0px",
          width: "100%",
          boxShadow: "none",
          borderRadius: "0px",
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
        }),
        option: (defaultStyles, props) => ({
          ...defaultStyles,
          color: colors.darkBlue,
          fontSize: "14px",
        }),
        placeholder: (defaultStyles, props) => ({
          ...defaultStyles,
          color: colors.placeholderGray,
        }),
      },
      isClearable: true,
      borderRadius: "8px",
      menuPlacement: "auto",
      maxMenuHeight: MAX_MENU_HEIGHT,
    },
  },
};

export const StyledCustomDropdown = {
  MandatoryTypography: styled(Typography)`
    ::after {
      content: "*";
      color: #FF0000;
      margin-right: 4px;
    }
  `,
  // TODO: FIXME: fix border-radius
  CustomDropdown: styled(AutoComplete)<AutoCompleteProps>`
    & .ant-select-selector {
        border: 0px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;

      & .ant-select-selection-search-input {
        width: 100%;
        border: 0px;
        height: 40px;
        min-height: 40px;
        max-height: 40px;
        border-radius: 12px;
        display: flex;
        align-items: center;
      }
    }
  `,
};

