import React, { useState } from "react";
import { COMPLIANCE_VALUES, STATUS_ICON } from "../constant";
import { LogoImage } from "components/Image";
import openNotification from "constants/antdNotification";
import { useDispatch } from "react-redux";
import { fileUploadPost } from "reducers/fileUpload";
import {
  SingleComplianceContainer,
  InputWithVerifcationIcon,
  VerificationImage,
  ConditionalDiv,
  InputWrapper,
  ActivityImage,
  NoteText,
  ViewDocument,
} from "../Compliance.styles";
import { verifyTanUrn } from "reducers/compliance";
import { getFunctionalDetails } from "../helper";
import _ from "lodash";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";

export interface ComplianceInputProps {
  data: any;
  field: string;
  index: any;
  verification: any;
  gstInProgress: boolean;
  handleComplianceValueChange: any;
  handleUploadDocument: any;
  handleDeleteDocument: any;
  getNote:(text:string)=>void
  handleVerify: any;
  focusField?: boolean;
}

const SingleComplianceContent = (props: ComplianceInputProps) => {
  const dispatch = useDispatch();
  const [verification, setVerificaiton] = useState(_.cloneDeep(props.verification || {}) || { verificationStatus: "", note: "" });
  const uploadInputProps = {
    "data-tracking-id": HEAP_DATA_ATTRIBUTES.COMPLIANCE.UPLOAD?.[props.field.toUpperCase()] || "", 
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file?.size / 1024 / 1024 > 10) {
      openNotification("File size exceeds the 10Mb limit. Please try uploading a smaller file size");
    }
    if (typeof file !== "undefined" && file?.size / 1024 / 1024 <= 10) {
      dispatch(fileUploadPost(file)).then(
        (res) => {
          props.handleUploadDocument(props.field, props.data.value, res, file.name);
          if (["cin", "esic", "epf", "pan"].includes(props.field)) {
            setVerificaiton({ ...verification, verificationStatus: "verified" });
          }
        },
        (error) => {
          openNotification(error?.message);
        }
      );
    }
  };

  const handleVerify = (field, value) => {
    if (value) {
      if (["tan", "urn"].includes(field)) {
        dispatch(verifyTanUrn(field, value)).then(
          (res) => {
            if (res?.success && res?.data?.result && Object.keys(res.data.result).length > 0) {
              setVerificaiton({ verificationStatus: "verified", note: "Verified" });
              props.handleVerify(field, value, "verified");
            } else {
              setVerificaiton({ verificationStatus: "notVerified", note: "Not Verified" });
              props.handleVerify(field, value, "notVerified");
            }
          },
          (error) => {
            setVerificaiton({ verificationStatus: "notVerified", note: "Not Verified" });
          }
        );
      } else {
        setVerificaiton({ verificationStatus: "inProgress", note: "Verification Pending" });
      }
    }
  };

  const onKeyPress = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp("[a-zA-Z0-9-]");
    if (!specialCharRegex.test(e.key)) {
      e.preventDefault();
      return false;
    }
  };

  const { value, document } = props.data;

  const { documentDiv, verificationStatus, note } = getFunctionalDetails(
    props.field,
    verification,
    props.data,   
    props.gstInProgress,
  );

  const getText=()=>{
    const text=note || (documentDiv === "upload" ? COMPLIANCE_VALUES[props.field].defaultNote : "");
    props.getNote(text);
    return text
  }
  return (
    <>
    <SingleComplianceContainer>
      <InputWithVerifcationIcon
        data-tracking-id={HEAP_DATA_ATTRIBUTES.COMPLIANCE.FILL?.[props.field.toUpperCase()] || ""}
        placeholder={COMPLIANCE_VALUES[props.field].placeHolder}
        value={value}
        disabled={["gst", "pan"].includes(props.field)}
        onChange={(e: any) => {
          if (["lin"].includes(props.field)) {
            setVerificaiton({ ...verification, verificationStatus: "inProgress" });
          }
          props.handleComplianceValueChange(props.field, props.index, e.target.value, props.data.uniqueIndex);
        }}
        autoFocus={props.focusField}
        onKeyPress={onKeyPress}
        suffix={
          verificationStatus ? (
            <VerificationImage>
              <LogoImage imageSrc={STATUS_ICON[verificationStatus]?.image} altMesg="status" imageType="icon" />
            </VerificationImage>
          ) : (
            <></>
          )
        }
        $border={STATUS_ICON[verificationStatus]?.border}
      />
      {documentDiv === "upload" && (
        <ConditionalDiv>
          <InputWrapper 
            {...uploadInputProps} // data-tracking-id="compliance-gst-upload"
            type="file" 
            onChange={(e) => handleFileUpload(e)} 
          />
          <ActivityImage>
            <LogoImage imageSrc="/images/Compliance/compliance_upload.svg" altMesg="status" imageType="icon" />
          </ActivityImage>
          Upload
        </ConditionalDiv>
      )}
      {documentDiv === "verify" && (
        <ConditionalDiv
          onClick={() => {
            handleVerify(props.field, props.data.value);
          }}
        >
          <ActivityImage>
            <LogoImage imageSrc="/images/Compliance/verify_compliance_field.svg" altMesg="status" imageType="icon" />
          </ActivityImage>
          Verify
        </ConditionalDiv>
      )}

      <NoteText>{getText()}</NoteText>
      {document?.file?.fileName && (
        <ViewDocument>
          <LogoImage imageSrc="/images/ProfileCreation/certifications.svg" altMesg="doc" imageType="icon" />
          <a href={document?.file?.fileUrl}>{checkForMobileScreen?"View":(document.name || document?.file?.fileName || "").substring(0, 10)}</a>
          <LogoImage
            imageSrc="/images/xCircle.svg"
            altMesg="close"
            imageType="icon"
            onClick={(e) => {
              if (["cin", "esic", "epf", "pan"].includes(props.field)) {
                setVerificaiton({ ...verification, verificationStatus: "documentPending" });
              }
              props.handleDeleteDocument(props.field, props.data.value);
            }}
          />
        </ViewDocument>
      )}
    </SingleComplianceContainer>
    </>
  );
};

export default SingleComplianceContent;
