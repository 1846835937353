import { Colors } from "Theme";

// eslint-disable-next-line import/prefer-default-export
export const inputTheme = (colors: Colors) => ({
  sizes: {
    uploadInputSizeSmall: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "280px",
      iconSize: "1.6rem",
      borderRadius: "6px",
    },
    uploadInputSizeMedium: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "414px",
      iconSize: "1.6rem",
      borderRadius: "6px",
    },
    uploadInputSizeLarge: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "500px",
      iconSize: "1.6rem",
      borderRadius: "6px",
    },
    small: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "300px",
      iconSize: "1.6rem",
      borderRadius: "6px",
    },
    middle: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "1.9rem",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "48px",
      width: "100%",
      iconSize: "27px",
      borderRadius: "4px",
      mobileStyles:{
        fontSize: "12px",
        height:"36px",
        width: "280px",
      }
    },
    medium: {
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "1.9rem",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "270px",
      iconSize: "27px",
      borderRadius: "6px",
    },
    large: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "550px",
      iconSize: "1.6rem",
      borderRadius: "8px",
      mobileStyles:{
        fontSize: "12px",
        height:"40px",
        width: "100%",
      }
    },
    semiMiddle: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "270px",
      iconSize: "1.6rem",
      borderRadius: "8px",
    },
    extraLarge: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "550px",
      iconSize: "1.6rem",
      borderRadius: "8px",
    },
    extraLargeNew: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "60px",
      width: "760px",
      iconSize: "1.6rem",
      borderRadius: "8px",
    },
    registrationNew: {
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "1.9rem",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "48px",
      width: "100%",
      iconSize: "27px",
      borderRadius: "6px",
      mobileStyles:{
        fontSize: "12px",
        height:"36px",
        width: "280px",
      }
    },
    registrationPhoneView:{
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height:"36px",
      width: "280px",
      iconSize: "27px",
      borderRadius: "6px",
    },
    profileCreationLarge: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "40px",
      width: "550px",
      iconSize: "1.6rem",
      borderRadius: "8px",
      mobileStyles:{
        width:"100% !important"
      }
    },
    profileCreationMedium: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "40px",
      width: "330px",
      iconSize: "1.6rem",
      borderRadius: "8px",
      mobileStyles:{
      width: "100%",
      fontSize: "12px",
      }
    },
    profileCreationSmall: {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "21px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #011759",
      paddingLeft: "20px",
      height: "40px",
      width: "180px",
      iconSize: "1.6rem",
      borderRadius: "8px",
      mobileScreen:{
      width: "190px",
      }
    },
    agentCode: {
      display: "flex",
      justifyContent: "center",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "24px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #D5D9EC",
      paddingLeft: "20px",
      iconSize: "1.6rem",
      borderRadius: "6px",
      height: "32px",
      width: "100%",
      mobileStyles:{
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "18px",
        paddingLeft: "25px",
        height: "36px",
        width: "118px",
      }
    },
    agentCodePhoneView: {
      display: "flex",
      justifyContent: "center",
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "18px",
      borderImportant: "1px solid #D5D9EC",
      borderOptional: "1px solid #D5D9EC",
      paddingLeft: "25px",
      iconSize: "1.6rem",
      borderRadius: "6px",
      height: "36px",
      width: "118px",
    },
  },
  borderRadius: "4px",
  color: colors.gray,
  states: {
    default: colors.grayDark,
    hover: colors.primary,
    active: colors.primary,
    disabled: colors.grayDark,
    success: colors.green,
    failure: colors.red,
  },
});
