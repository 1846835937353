import { Colors } from 'Theme';
export const buttonTheme = (colors: Colors) => ({
  sizes: {
    box:{
      width: "40px",
      height: "40px",
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '21px',
      navBtnHeight: '60px',
      borderRadius: '8px',
      iconSize: '3.2rem'
    },
    small: {
      fontSize: '11px',
      lineHeight: '16.5px',
      // padding: '0.5rem 0.8rem',
      borderRadius: '15px',
      height: '24px',
      iconSize: '1.2rem'
    },
    middle: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '21px',
      navBtnHeight: '60px',
      borderRadius: '30px',
      iconSize: '3.2rem'
    },
    large: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      padding: '20px 195px',
      height: '60px',
      borderRadius: '100px',
      // iconSize: '2rem'
      mobileStyles:{
        width:"100%",
        padding: '13px 59px',
        height: '36px',
      }
    },
    mobileView: {
      width:"100%",
      lineHeight: '21px',
      padding: '13px 59px',
      height: '36px',
      // iconSize: '2rem'
    },
    upload: {
      // borderRadius: '0px 8px 8px 0px', 
      // width: '160px', 
      // position:'relative',
      // left:'-5px',
      // top:'-3px',
      // height: '60px',
      // fontWeight: 600,
      color: "#112B7A",
      fontSize: '12px'
    }
  },
  // borderRadius: '4px',
  color: colors.white,
  states: {
    primary: {
      hover: '#112B7A',
      active: '#112B7A',
      disabled: '#112B7A'
    },
    secondary: {
      hover: '#47C882',
      active: '#47C882',
      disabled: '#47C882'
    },
    white: {
      hover: '#47C882',
      active: '#47C882',
      disabled: '#47C882'
    },
    blue: {
      hover: '#215EC9',
      active: '#215EC9',
      disabled: '#215EC9'
    },
    red: {
      hover: '#FFFFFF',
      active: '#BE000B',
      disabled: '#828B90',
    },
    default:{
      hover: '#fffff',
      active: '#fffff',
      disabled: '#fffff',
    }
  }
});
