import api from "../ui-constants/api";

export const DASHBOARD_REQUEST = "dashboard/DASHBOARD_REQUEST";
export const DASHBOARD_SUCCESS = "dashboard/DASHBOARD_SUCCESS";
export const DASHBOARD_FAILURE = "dashboard/DASHBOARD_FAILURE";
export const UPDATE_DASHBOARD_DATA = "dashboard/UPDATE_DASHBOARD_DATA";

const initialState = {
  data: null,
  error: null,
  dashboardData: null,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
      };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        data: action.result.data,
      };
    case DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case UPDATE_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default dashboard;

export const getDashboardAnalyticsData = () => ({
  types: [DASHBOARD_REQUEST, DASHBOARD_SUCCESS, DASHBOARD_FAILURE],
  promise: (client) => client.get("dashboard_analytics_data"),
});

export const updateDashBoardData = (dispatch, data) => {
  dispatch({
    type: UPDATE_DASHBOARD_DATA,
    payload: data,
  });
};
