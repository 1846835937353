import { Button } from "components/Button";
import profileCreationSections from "constants/section";
import ProfileCreationSections from "constants/section";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import styles from "./ProfileCreationButtons.module.scss";
import cn from "classnames";
import SessionStorageService from "services/SessionStorageService";
import ApiConstant from "constants/ApiConstant";
import { checkForMobileScreen } from "shared/handlers/getUserScreenSize.handler";
import { ProfileData } from "constants/mockData";
import { Flex } from "venwiz-ui";
import { colors } from "Theme";

interface ButtonProps {
  state: boolean;
  handleStateChange: (value: string) => void;
  usedAt: string;
  lastProfileSection?: boolean;
  pagination?: boolean;
  pageNumber?: number;
  buttonText?: string;
  isloading?: boolean;
  dataAttribute?: object;
  style?: object;
  withCustomStyles?: boolean;
}

const ButtonPairs = styled.div`
  display: flex;
  width: 500px;
  justify-content: space-between;
  @media only screen and (max-width: 640px) {
    width: 100vw;
  }
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;
`;
const ProfileCreationButtons = (props: ButtonProps) => {
  const { usedAt } = props;
  const history = useHistory();
  const paginationRoute = [
    "",
    "/userinfo",
    "/companyinfo",
    "/capabilities",
    "/experience",
    "/finance",
    "/compliance",
    // "/references",
    "/people",
  ];
  const isOnBoarding = window.location.href.includes("onboarding");

  const renderSaveStateWithCustomStyles = () => {
    return (
      <Flex width={"100%"} justifyContent={"space-between"}>
        <ButtonPairs
          style={{ width: "75vw" }}
        >
          <Button
            buttonLoading={props?.isloading}
            disabled={props.state}
            onClick={() => {
              props.handleStateChange("save");
            }}
            variant="secondary"
            size="middle"
            style={props?.style}
            $mobileScreenStyles={{
              borderRadius: "0px",
              width: "100% !important",
            }}
            {...props?.dataAttribute}
          >
            {props?.buttonText}
          </Button>
          {props?.pagination && !isOnBoarding && (
            <Flex
              display={{ default: "none", xxs: "none", sm: "flex" }}
              alignItems={"center"}
              justifyContent={"space-between"}
              fontStyle={"normal"}
              fontWeight={400}
              fontSize={"14px"}
              lineHeight={"21px"}
              letterSpacing={"0.01em"}
              color={colors.blue}
              style={{ userSelect: "none" }}
            >
              <div className={styles.pagination}>
                {props?.pageNumber !== 1 && (
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      history.push(`${paginationRoute[props?.pageNumber - 1]}`);
                    }}
                  >
                    <img
                      src="/images/prev.svg"
                      alt="next page icon"
                      className={styles.adjustSpacing}
                    />
                    <div className={styles.paginationFont}>Prev</div>
                  </div>
                )}

                <div className={styles.page}>
                  <div>Section {props?.pageNumber}</div>
                </div>

                {props?.pageNumber !== ProfileData?.length && (
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      history.push(`${paginationRoute[props?.pageNumber + 1]}`);
                    }}
                  >
                    <div className={styles.adjustSpacing}> Next</div>
                    <img src="/images/next.svg" alt="next page icon" />
                  </div>
                )}
              </div>
            </Flex>
          )}
        </ButtonPairs>
      </Flex>
    );
  };

  const saveState = () => {
    return (
      <Wrapper>
        <ButtonPairs>
          <Button
            buttonLoading={props?.isloading}
            disabled={props.state}
            onClick={() => {
              props.handleStateChange("save");
            }}
            variant="secondary"
            size="middle"
            style={props?.style}
            $mobileScreenStyles={{
              borderRadius: "0px",
              width: "100% !important",
            }}
            {...props?.dataAttribute}
          >
            {props?.buttonText}
          </Button>
          {props?.pagination && !isOnBoarding && (
            <div className={styles.container}>
              <div className={styles.pagination}>
                {props?.pageNumber !== 1 && (
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      history.push(`${paginationRoute[props?.pageNumber - 1]}`);
                    }}
                  >
                    <img
                      src="/images/prev.svg"
                      alt="next page icon"
                      className={styles.adjustSpacing}
                    />
                    <div className={styles.paginationFont}>Prev</div>
                  </div>
                )}

                <div className={styles.page}>
                  <div>Section {props?.pageNumber}</div>
                </div>

                {props?.pageNumber !== ProfileData?.length && (
                  <div
                    style={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      history.push(`${paginationRoute[props?.pageNumber + 1]}`);
                    }}
                  >
                    <div className={styles.adjustSpacing}> Next</div>
                    <img src="/images/next.svg" alt="next page icon" />
                  </div>
                )}
              </div>
            </div>
          )}
        </ButtonPairs>
      </Wrapper>
    );
  };

  if (!props?.withCustomStyles) {
    return (
      <Container>{saveState()}</Container>
    );
  }

  return <Container>{renderSaveStateWithCustomStyles()}</Container>;
};

export default ProfileCreationButtons;
