import React from "react";
import styled from "styled-components";
type usedFor = "login" | "registration" | "profileCreation" | undefined;
type sizes = "small" | "large" | "medium" | "expPrjDesc" | "PopupDesc";
interface textAreaProps {
  placeholder?: string;
  size?: sizes;
  usedFor?: usedFor;
  headerLabel?: any;
  buttonDisable?: boolean;
  onChange?: (e: any) => void;
  value?: string;
  name?: string;
  max?: number;
  error?: boolean;
}

export const Textarea = styled.textarea<Partial<textAreaProps>>`
  ::-webkit-input-placeholder {
    color: 011759, 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #011759;
    opacity: 0.4;
  }
  width: ${props => props.theme.textbox.sizes[props.size].width};
  height: ${props => props.theme.textbox.sizes[props.size].height};
  border-radius: ${props => props.theme.textbox.sizes[props.size].borderRadius};
  border: ${props => (props.error ? "1px solid #BE000B" : props.theme.textbox.sizes[props.size].border)};
  background: ${props => (props.error ? "rgba(190, 0, 11, 0.05) !important" : "#FFFFFF")};
  color: ${props => props.theme.textbox.sizes[props.size].color};
  resize: ${props => props.theme.textbox.sizes[props.size].resize};
  padding: ${props => props.theme.textbox.sizes[props.size].padding};
  outline: ${props => props.theme.textbox.sizes[props.size].outline};
  line-height: ${props => props.theme.textbox.sizes[props.size].lineHeight};
  @media only screen and (max-width: 640px) {
    width: ${props => props.theme.textbox.sizes[props.size]?.mobileStyles?.width};
    height: ${props => props.theme.textbox.sizes[props.size]?.mobileStyles?.height};
    padding: 8px 18px;
  }
`;

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: auto;
  color: ${props => props.theme.colors.darkBlue};
  margin-right: 10px;
  position: relative;
`;
const HeaderName = styled.div`
  width: auto;
  font-size: 12px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
`;
const TextAreaWithHeader = ({ name, max, ...props }: textAreaProps) => {
  return (
    <BoxContainer>
      {!!props?.headerLabel?.length && (
        <HeaderName
          style={{
            height: `${props.usedFor}` === "login" ? "21px" : "18px",
            marginBottom: `${props.usedFor}` === "login" ? "15px" : "10px",
            opacity: `${props.usedFor === "profileCreation" ? "0.7" : "1"}`,
          }}
        >
          {props?.headerLabel}
        </HeaderName>
      )}
      <Textarea
        maxLength={max}
        placeholder={props.placeholder}
        autoComplete="off"
        disabled={props.buttonDisable}
        onChange={e => props.onChange(e)}
        value={props.value}
        {...props}
        name={name}
      />
    </BoxContainer>
  );
};

export default TextAreaWithHeader;
