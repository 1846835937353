import styled from "styled-components";
import { Button as Bttn } from "antd";
import { Form } from "formik";
import { styleProps } from "./Interfaces/ResetPasswordInterface";



export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60.32%;
  @media only screen and (max-width: 640px) {
    width: 100%;
    align-items: center;
    height: 75vh;
  }
`;
export const Header = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 45px;
  color: ${(props) => props.theme.colors.secondary};
  margin-bottom: 32px;
`;
export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media only screen and (max-width: 640px) {
    width: auto;
    height: 100%;
  }
`;
export const EmailDiv = styled.div`
  background: ${(props) => props.theme.colors.lightGray};
  display: flex;
  align-items: center;
  padding: 11px 185px 11px 20px;
  border-radius: 6px;
  margin-bottom: 30px;
`;
export const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;
  margin-top: 20px;
  width: 100%;
  margin-top: 32px;
  padding: 0px;
  @media only screen and (max-width: 640px) {
    width: 280px;
    align-items: center;
    margin-top: 65%;
  }
`;

export const ResetBttn = styled(Bttn)`
  background: #47c882;
  border-radius: 30px;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  width: 100%;
  height: 60px;
  &:disabled {
    border-color: #d7eadf;
    background: #d7eadf;
    color: #ffffff;
  }
  @media only screen and (max-width: 640px) {
    height: 36px;
    font-size: 14px;
  }
`;
export const Avatar = styled.div`
  border-radius: 12px;
  width: 38px;
  height: 38px;
  line-height: 38px;
  background-color: #215ec9;
  text-align: center;
  font-weight: 800;
  color: #ffffff;
`;

export const Email = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.colors.primary};
  padding-left: 20px;
`;

export const Divs = styled.div<styleProps>`
  margin-bottom: ${(props) => props.$marginBottom};
  @media only screen and (max-width: 640px) {
    width: 280px;
  }
`;

export const BackBttn = styled.div`
  padding-top: 20px;
`;

export const FormDiv = styled(Form)`
  @media only screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
