import { Drawer } from "antd";
import SharedFiles from "ChatModule/SharedFiles/SharedFiles";
import EmptyComp from "components/Empty";
import { AvatarOptions } from "constants/mockData";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { handleLogout } from "shared/handlers/logout.handler";
import { marketingSuiteRoute } from "shared/handlers/publishModalhandler";
import IconWrapper from "./IconWrapper";
import { DrawerDiv, NavItem } from "./Nav.styles";
import { BttnDiv, GreenIndicator, IconWrap } from "./NavigationTab";
import { VAS_NAVIGATION_LINKS, COMMUNITY_NAVIGATION_LINKS } from "./navigation.constants";
import { colors } from "Theme";
import { Flex, Box, Typography } from "venwiz-ui";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { MdGroups } from "react-icons/md";
import { HEAP_DATA_TRACKING_ID, HEAP_DATA_ATTRIBUTES } from "constants/heapAnalytics";

interface Props {
  onClose: () => void;
  contentType: string;
  width: string;
}

const SideNavigationTab = (props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isOnboarding = window.location.href.includes("onboarding");
  const [placement, setPlacement] = useState("left");
  const [showVASMenu, setShowVASMenu] = useState(false);
  const chatInfo = useSelector((state: any) => state.chatInfo);
  const { data, sharedFiles } = chatInfo;
  const hasSharedFiles = sharedFiles?.received?.length || sharedFiles?.sent?.length;
  const PADDING_LEFT = "6.46vw"

  const handleClick = (item, e) => {
    if (item.cardName === "Logout") {
      handleLogout({ dispatch });
    }

    if (item.linkTo.toLowerCase() === "/marketingsuite") {
      e.preventDefault();
      marketingSuiteRoute(dispatch);
    }
    props.onClose();
  };

  const renderLink = (item) => {
    return (
      <Link onClick={(e) => handleClick(item, e)} to={item.linkTo}>
        {<NavItem key={item.id}>{item.cardName}</NavItem>}
      </Link>
    );
  };

  const protectedLinkCheck = (item) => {
    if (item?.checkAuthAccess) {
      if (!isOnboarding) {
        return renderLink(item);
      }
    } else {
      return renderLink(item);
    }
  };

  const isProfilePage = (pathName) => {
    return (
      pathName === "/userinfo" ||
      pathName === "/companyinfo" ||
      pathName === "/capabilities" ||
      pathName === "/experience" ||
      pathName === "/certifications" ||
      // pathName === "/references" ||
      pathName === "/people"
    );
  };

  const routeTo = (route) => {
    history.push(route);
  };

  const renderNavTab = () => {
    return (
      <>
        <BttnDiv>
          <IconWrap 
            buttonBasePaddingLeft={PADDING_LEFT}
            $isSelected={window.location.pathname === "/dashboard"}
          >
            <IconWrapper
              title={"Dashboard"}
              onClick={() => routeTo("/dashboard")}
              variant={window.location.pathname === "/dashboard" ? "primary" : "secondary"}
              size="middle"
              $iconClass="icon-dashboard"
            />
          </IconWrap>
        </BttnDiv>
        <BttnDiv>
          <IconWrap 
            buttonBasePaddingLeft={PADDING_LEFT}
            $isSelected={isProfilePage(window.location.pathname)}
          >
            <IconWrapper
              title={"Profile"}
              onClick={() => routeTo("/userinfo")}
              variant={isProfilePage(window.location.pathname) ? "primary" : "secondary"}
              size="middle"
              $iconClass="icon-vendor"
            ></IconWrapper>
          </IconWrap>
        </BttnDiv>
        <BttnDiv>
          <IconWrap 
            buttonBasePaddingLeft={PADDING_LEFT}
            $isSelected={window.location.pathname?.match("/request")}
          >
            <IconWrapper
              title={"Jobs"}
              onClick={() => routeTo("/open-jobs")}
              variant={
                window.location.pathname?.match("/request") || window.location.pathname?.match("/job-request")
                  ? "primary"
                  : "secondary"
              }
              size="middle"
              $iconClass=""
              customIcon={(
                <img
                  src={window.location.pathname?.match("/request") || window.location.pathname?.match("/job-request")
                    ? "/images/work.svg"
                    : "/images/work_blue.svg"
                  }
                  alt="jobs"
                />
              )}
            ></IconWrapper>
          </IconWrap>
        </BttnDiv>

        <BttnDiv>
          <IconWrap buttonBasePaddingLeft={PADDING_LEFT}>
            <IconWrapper
              $iconClass=""
              size="middle"
              variant={"primary"}
              title={"Community"}
              customIcon={<MdGroups fontSize={"28px"} color={colors.darkBlue} />}
              onClick={() => {
                window.open(COMMUNITY_NAVIGATION_LINKS.HOMEPAGE, "_blank");
              }}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.NAVIGATION.COMMUNITY }}
            ></IconWrapper>
          </IconWrap>
        </BttnDiv>
        
        {/* Mobile View - VAS Button */}
        <Box
          background={"white"}
          paddingLeft={PADDING_LEFT}
        >
          <Flex
            gap={"22px"}
            paddingRight={"12px"}
            background={"white"}
            alignItems={"flex-end"}
          >
            {/* VAS HomePage Link */}
            <Flex
              onClick={() => {
                window.open(VAS_NAVIGATION_LINKS.HOMEPAGE, "_blank");
              }}
              fontSize={"14px"}
              fontWeight={600}
              color={colors.darkBlue}
              gap={"10px"}
              alignItems={"flex-end"}
            >
              <img
                alt="vas"
                src={"images/bank_blue.svg"}
              />
              <Flex
                flexDirection={"column"}
                justifyContent={"flex-start"}
              >
                {/* New Badge */}
                <Flex 
                  width={"min-content"}
                  alignItems={"center"} 
                  justifyContent={"center"}
                  borderRadius={"2px"}
                  padding={"4px"}
                  background={"linear-gradient(180deg, #F6C033 0%, #E16308 100%)"}
                  height={"14px"}
                  fontWeight={600}
                  color={"#fff"}
                >
                  New
                </Flex>
                Vendor Plus
              </Flex>
            </Flex>
            {/* Chevron Icon */}
            <Flex
              onClick={(e) => {
                e?.stopPropagation();
                setShowVASMenu(prev => !prev)
              }}
              padding={"3px"}
              fontSize={"12px"}
              cursor={"pointer"}
              color={colors.blue}
              borderRadius={"100%"}
              justifyContent={"center"}
              backgroundColor={"#DAE4F6"}
              alignItems={"flex-end"}
            >
              {showVASMenu ? <FiChevronUp /> : <FiChevronDown />}
            </Flex>
          </Flex>
          {/* VAS Nested Menu */}
          {showVASMenu && (
            <>
              <Flex
                gap={"8px"}
                marginTop={"12px"}
                paddingLeft={"8px"}
                flexDirection={"column"}
                style={{ whiteSpace: "nowrap" }}
              >
                <Typography
                  onClick={() => window.open(VAS_NAVIGATION_LINKS.PRO_FINANCE, "_blank")}
                  fontSize={"12px"}
                  color={colors.darkBlue}
                  cursor={"pointer"}
                >
                  ProFinance
                </Typography>
                <Typography
                  onClick={() => window.open(VAS_NAVIGATION_LINKS.PRO_SOURCE, "_blank")}
                  fontSize={"12px"}
                  color={colors.darkBlue}
                  cursor={"pointer"}
                >
                  ProSource
                </Typography>
                <Typography
                  onClick={() => window.open(VAS_NAVIGATION_LINKS.PRO_CONNECT, "_blank")}
                  fontSize={"12px"}
                  color={colors.darkBlue}
                  cursor={"pointer"}
                >
                  ProConnect
                </Typography>
              </Flex>
            </>
          )}
        </Box>
      </>
    );
  };
  return (
    <>
      <DrawerDiv closable={false} onClose={props.onClose} visible={true} key={placement} $width={props.width}>
        {props?.contentType === "popoverContent" && AvatarOptions.map((item) => protectedLinkCheck(item))}
        {props?.contentType === "navbar" && renderNavTab()}

        {props?.contentType === "sharedFiles" &&
          (hasSharedFiles ? <SharedFiles display={"block"} /> : <EmptyComp customMess={"No Files Shared"} />)}
      </DrawerDiv>
    </>
  );
};

export default SideNavigationTab;
