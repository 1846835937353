
import LocalStorageService from "services/LocalStorageService";
import SessionStorageService from "services/SessionStorageService";
import { ApiConfig, AppConfig } from "ui-constants";
export const getHeaders = () => {
  const userData = LocalStorageService.get(
    AppConfig.STORAGE_KEYS.isRememberEnabled
  )
    ? LocalStorageService.get("userData")
    : SessionStorageService.get("userData");
  const token =
    userData && userData.access_token ? userData.access_token : "007";
  const headers = `Bearer ${token}`;
  return headers;
};

export const getGstn = () => {
  const gstn = JSON.parse(sessionStorage.getItem("user"))?.gstn || LocalStorageService.get("userData")?.gstn;
  return gstn;
};

export const getEmail = () => {
  const gstn = JSON.parse(sessionStorage.getItem("user"))?.email || LocalStorageService.get("userData")?.email;
  return gstn;
};
