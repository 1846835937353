import styled from "styled-components";
import { Input, Tag } from "antd";

//Component: Compliance

export const ButtonWrapper = styled.div<any>`
  display: flex;
  padding: ${props=>props?.$padding};
  position: fixed;
  bottom: 0;
  @media only screen and (max-width: 640px) {
    padding:0px;
    position:fixed;
    bottom:0;
  }
`;

export const ContentInputs = styled.div`
  height: calc(100vh - 250px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
  margin-top: 20px;
@media only screen and (max-width: 640px) {
  height: calc(100vh - 241px);
}
`;

export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 10px;
  backdrop-filter: blur(0.5px);
  z-index: 1500;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

// Component: ComplianceContent
export const ContentContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 640px) {
  margin: 20px 16px;
  }
`;

export const Note=styled.span`
display:none;
@media only screen and (max-width: 640px) {
display:block;
font-size: 12px;
line-height: 18px;
color: rgba(1, 23, 89, 0.7);
}
`
export const BaseText = styled.div`
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #011759;
  opacity: 0.7;
`;

export const RectBox = styled.div<any>`
  background: #f4f6ff;
  border: ${props=>props?.$borderColor};
  border-radius: 8px;
  margin-right: 40px;
  @media only screen and (max-width: 640px) {
    margin-right: 0px;
  }
`;

export const Required = styled.div`
  color: #be000b;
`;

export const AddNew = styled.div`
  display: flex;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #215ec9;
  margin-right: 45px;
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const GSTUploadInfo = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #011759;
  padding-top: 40px;
  padding-bottom: 40px;
  @media only screen and (max-width: 640px) {
    display:none;
  }
`;

export const ViewAll = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #215EC9;
  padding-left: 20px;
  margin-left: auto;
  margin-right: 20px;
`;

// Component: ComplianceCheckboxes

export const CheckBoxesList = styled.div`
  columns: 4;
`;
export const HeaderText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.1em;
  color: #011759;   
  opacity: 0.7;
`;
export const CheckBoxContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media only screen and (max-width: 640px) {
display:none
  }
`;

// Componenet: SingleComplianceComponent

export const ConditionalDiv = styled.div`
  display: flex;
  position: relative;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #215ec9;
  align-self: center;
  margin-left: 20px;
  input{
  cursor:pointer;
  }
  @media only screen and (max-width: 640px) {
    font-size:12px;
  }
`;

export const NoteText = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: rgba(1, 23, 89, 0.7);
  align-self: center;
  margin-left: 20px;
  @media only screen and (max-width: 640px) {
   display:none;
  }
`;

export const ViewDocument = styled(Tag)<any>`
  background: #FFFFFF;
  border-radius: 8px;
  align-self: center;
  margin-left: auto;
  padding: 8px;
  img {
    width: 20px;
  }
  a {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #011759;
    padding: 10px;
  }  
  @media only screen and (max-width: 640px) {
    padding: 0px;
    background: transparent;
    border-radius: 0px;
    border:none;
    img {
      width: 14px;
      height: 14px;
    }
    a{
    font-size: 12px;
    }
   }
  @media only screen and (max-width: 380px) {
    margin-left: 5px;
  }
`;

export const SingleComplianceContainer = styled.div`
  display: flex;
  padding: 10px;
`;

export const InputWithVerifcationIcon = styled(Input)<any>`
  width: 252px;
  height: 40px;
  background: #ffffff;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  border: ${(props) => (props?.$border ? `1px solid ${props.$border}` : "1px solid #D4E5FE")};
  .ant-input {
    font-size: 16px;
    color: #011759;
  }
  .ant-input[disabled] {
    color: #606060;
  }
  @media only screen and (max-width: 640px) {
  width: 58.60vw;
  }
`;

export const VerificationImage = styled.div`
  padding: 6px;
`;

export const ActivityImage = styled.div`
  align-self: center;
  padding-right: 6px;
  padding-left: 6px;
  @media only screen and (max-width: 640px) {
   font-size:12px;
  }
`;

export const InputWrapper = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
`;