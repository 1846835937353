import styled from "styled-components";
import  { ErrorMessageDiv } from "../InputWithHeader/input";

export const PasswordDiv = styled.div<any>`
  display: inline-block;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 640px) {
    ${ErrorMessageDiv} {
      display: ${(props) => props?.$hideErrorMess && "none"};
    }
  }
`;

export const IconWrapper = styled.div<any>`
  position: absolute;
  height: 100%;
  right: 20px;
  top:0;
  display: flex;
  align-items: center;
  width:3%;
  @media only screen and (max-width:640px){
  width:auto;
  bottom:${(props) => props?.$bottom || "4px"};
}
`;