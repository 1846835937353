import styled from "styled-components";

export const PublishModalWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 640px) {
    margin-top: 20px;
  }
`;
export const PublishModalBttn = styled.button`
  width: 200px;
  height: 40px;
  border: 2px solid #215ec9;
  color: #ffffff;
  background: #215ec9;
  border-radius: 20px;
  margin-top: 20px;
  font-weight: 800;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #ffffff;
  cursor:pointer;
`;
export const PublishModalActionMess = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #011759;
  opacity: 0.8;
`;
