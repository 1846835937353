import * as Yup from "yup";
import React, { useEffect, useState } from "react";

export const lowerCase = /[a-z]/;
export const numbers = /[0-9]/;
export const OTP_REGEX = /^[0-9]{6}$/;
export const upperCase = /[A-Z]/;
export const specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
export const nameValidations = /^[a-zA-Z ]{2,35}$/;
export const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])((?=.*?[0-9])|(?=.*?[#?!@$%^&*-])).{8,}$/;
export const phoneRegExp = /^[6-9]\d{9}$/;
export const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// export const emailRegex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
export let errorArr = [];
export let message = "Please enter a password";
export const validate = Yup.object({
  fullName: Yup.string()
    .max(35, "Max 35 characters allowed")
    .matches(nameValidations, "Please enter a valid full name")
    .required("Please enter your full name"),
  phoneNo: Yup.string()
    .matches(phoneRegExp, "Enter Valid Mobile number")
    .max(10, "Must be 10 digits")
    .required("Please enter your Mobile number"),
  email: Yup.string()
    .email("Please enter your email address in the correct format")
    .max(256, "Max 256 characters allowed")
    .matches(emailRegex, "Please enter the Email Address")
    .required("Please enter the Email Address"),
  gstNo: Yup.string()
    .min(15, "Please check and re-enter the correct GST number")
    .max(15, "Please check and re-enter the correct GST number")
    .matches(gstRegex, "Please check and re-enter the correct GST number")
    .required("Please enter your company's GST number"),
  phoneNumberOTP: Yup.string()
    .min(6, "Enter 6-digit OTP")
    .max(6, "Enter 6-digit OTP")
    .matches(OTP_REGEX, "Enter 6-digit OTP")
    .required("Enter OTP"),
  password: Yup.string()
    .min(10, "Include atleast 10 characters")
    .max(50, "Max 50 characters allowed")
    .required("Please enter a password")
    .test("email_async_validation", "Email Validation Error", function (value) {
      // Use function
      errorArr = [];
      if (!(specialChar.test(value) || numbers.test(value))) {
        errorArr.push("number or special char");
      }
      if (!lowerCase.test(value)) {
        errorArr.push("lowercase char");
      }
      if (!upperCase.test(value)) {
        errorArr.push("uppercase char");
      }

      if (errorArr.length === 1) {
        return this.createError({
          message: `Include atleast 1 ${errorArr[0]} `,
        });
      } else if (errorArr.length === 2) {
        return this.createError({
          message: `Include atleast 1 ${errorArr[1]} and  ${errorArr[0]}`,
        });
      } else {
        return true;
      }
    }),
  // companyRole: Yup.string()
  //   .max(50, "")
  //   .required("Please enter your role in the company"),
  // companyName: Yup.string()
  //   .max(50, "")
  //   .required("Please enter your company name"),
  // termsAndConditionCheckbox: Yup.boolean().oneOf(
  //   [true],
  //   "Please accept the terms and conditions"
  // ),
  termsAndConditionCheckbox: Yup.boolean()
    .oneOf([true], "Please accept the terms and conditions"),
});
