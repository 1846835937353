import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import useInterval from "hooks/useInterval";
import { getChatList, getSelectedChat } from "reducers/chat";
import { handleCancelRetainSearchKey } from "shared/handlers/requestHandlers";
import { getNewUpdateRequestCount } from "reducers/PlantVisitRequest";
import IconWrapper, { ButtonBase, Title, Icon } from "./IconWrapper";
import { Menu, Dropdown, Button } from "antd";
import { marketingSuiteRoute } from "shared/handlers/publishModalhandler";
import {
  HEAP_DATA_ATTRIBUTES,
  HEAP_DATA_TRACKING_ID,
} from "constants/heapAnalytics";
import { Box, Flex } from "venwiz-ui";
import LocalStorageService from "services/LocalStorageService";
import { VAS_NAVIGATION_LINKS, COMMUNITY_NAVIGATION_LINKS } from "./navigation.constants";
import { MdGroups } from "react-icons/md";
export const IconWrap = styled.div<any>`
  width: max-content;
  height: 60px;
  display: flex;
  position: relative;
  @media only screen and (max-width: 640px) {
    height: 40px;
    width: 100%;
    ${ButtonBase} {
      background: ${(props) =>
        props?.$isSelected ? "#215EC9" : "transparent"};
      border-bottom: none;
      width: 100%;
      padding-left: ${props => props?.buttonBasePaddingLeft || "10.46vw"};
    }
    ${Icon} {
      &:before {
        color: ${(props) => (props?.$isSelected ? "#FFFFFF" : "#011759")};
        font-size: 20px;
      }
    }
    ${Title} {
      color: ${(props) => (props?.$isSelected ? "#FFFFFF" : "#011759")};
      font-size: 14px;
    }
  }
`;

const ButtonContainer = styled.div<any>`
  width: 100%;
  height: 85%;
  display: flex;
  align-items: center;
  display: ${(props) => props?.isOnboarding && "none"};
  @media only screen and (max-width: 640px) {
    display: none;
  }
`;
export const BttnDiv = styled.div<any>`
  margin-right: 20px;
  background-color: ${(props) => props?.$backgroundColor};
  @media only screen and (max-width: 640px) {
    width: 100%;
    margin-bottom: 3.25vh;
  }
`;

export const GreenIndicator = styled.div`
  font-weight: 600;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background: #47c882;
  margin-left: 8px;
`;

const DropdownDiv = styled(Dropdown)`
  height: 60px;
  width: max-content;
`;

const MenuItem = styled(Menu.Item)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #011759;
  padding: 15px;
  :hover {
    color: #215ec9;
    background: #f4f6ff;
  }
`;

export const ImageDiv = styled.img<any>`
  filter: ${(props) =>
    props?.$applyfilter &&
    "brightness(0) saturate(100%) invert(66%) sepia(46%) saturate(518%) hue-rotate(95deg) brightness(93%) contrast(89%)"};
`;

type INavMenuItems = "dashboard"
  | "profile"
  | "requests"
  | "communication"
  | "vas"
  | "community";

const NavigationTab = () => {
  const isOnBoarding = window.location.href.includes("onboarding");
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [onHoverState, setOnHoverState] = useState(false);
  const [isVasHovered, setIsVasHovered] = useState(false);
  const [hoveredMenuItemName, setHoveredMenuItemName] = useState<
    INavMenuItems | ""
  >("");
  const userInfoData =
    useSelector((state: any) => state.userInfoApi.userInfoData) || {};
  const totalUnreadMsgs = useSelector(
    (state: any) => state.chatInfo?.totalUnreadMessage
  );
  const totalNewUpdateRequestCount = useSelector(
    (state: any) => state.plantVisitRequest?.newUpdateRequestCount
  );
  const chatInfo = useSelector((state: any) => state.chatInfo);
  useEffect(() => {
    if (userInfoData?.gstn)
      dispatch(getNewUpdateRequestCount(userInfoData?.gstn));
  }, [userInfoData]);

  useInterval(
    () => {
      const queryParam = {
        sender: "vendor",
        sender_id: userInfoData?.gstn,
      };
      dispatch(getChatList(queryParam));
      dispatch(getNewUpdateRequestCount(userInfoData?.gstn));
    },
    // Delay in milliseconds or null to stop it
    10000
  );

  useEffect(() => {
    const queryParam = {
      sender: "vendor",
      sender_id: userInfoData?.gstn,
    };
    dispatch(getChatList(queryParam));
    if (location.pathname !== "/communication" && !location.pathname.includes("job")) {
      getSelectedChat(dispatch, (chatInfo.data = null));
    }
  }, [location]);

  const handleClick = (selection) => {
    switch (selection.toLowerCase()) {
      case "dashboard": {
        history.push("/dashboard");
        break;
      }
      case "support": {
        history.push("/support");
        break;
      }
      case "chats": {
        history.push("/communication");
        break;
      }
      case "invite": {
        history.push("/invite");
        break;
      }
      case "marketingsuite": {
        marketingSuiteRoute(dispatch);
        break;
      }
      case "profile": {
        LocalStorageService.set(
          "locationNameToUserInfoRoute",
          history.location.pathname
        );
        history.push("/userinfo");
        break;
      }
      case "vas-homepage": {
        window.open(VAS_NAVIGATION_LINKS.HOMEPAGE, "_blank");
        break;
      }
      case "community": {
        window.open(COMMUNITY_NAVIGATION_LINKS.HOMEPAGE, "_blank");
        break;
      }
      case "requests": {
        history.push("/open-jobs");
        break;
      }
      case "view": {
        history.push("/my-profile");
        break;
      }
      default:
        break;
    }
    handleCancelRetainSearchKey();
  };

  const handleMenuClick = (e) => {
    if (e.key === "2") {
      handleClick("profile");
    } else if (e.key === "0") {
      handleClick("view");
    } else {
      marketingSuiteRoute(dispatch);
    }
  };

  /** Nested sub-menu in Profle menu item */
  const menu = (
    <Menu onClick={handleMenuClick}>
      <MenuItem key="0">View</MenuItem>
      <MenuItem key="1">Share</MenuItem>
      <MenuItem
        key="2"
        {...{
          [HEAP_DATA_TRACKING_ID]:
            HEAP_DATA_ATTRIBUTES.NAVIGATION.PROFILE_DROPDOWN.MENU_ITEM_UPDATE,
        }}
      >
        Update
      </MenuItem>
    </Menu>
  );

  const onHoverMenuItemHandler = (hoveredName: typeof hoveredMenuItemName) => ({
    onMouseEnter: () => setHoveredMenuItemName(hoveredName),
    onMouseLeave: () => setHoveredMenuItemName(""),
  });

  const renderProfileDropdown = () => {
    return (
      <DropdownDiv overlay={menu}>
        <div
          style={{ display: "flex", alignItems: "center" }}
          onMouseEnter={() => {
            setOnHoverState(true);
            onHoverMenuItemHandler("profile")?.onMouseEnter();
          }}
          onMouseLeave={() => {
            setOnHoverState(false);
            onHoverMenuItemHandler("profile")?.onMouseLeave();
          }}
        >
          <Box position={"relative"}>
            <IconWrap>
              <IconWrapper
                {...{
                  [HEAP_DATA_TRACKING_ID]:
                    HEAP_DATA_ATTRIBUTES.NAVIGATION.PROFILE_DROPDOWN
                      .MENU_ITEM_PROFILE,
                }}
                $isHovered={onHoverState || hoveredMenuItemName === "profile"}
                title={"Profile"}
                onClick={() => handleClick("profile")}
                variant={
                  isProfilePage(window.location.pathname) ||
                  hoveredMenuItemName === "profile"
                    ? "primary"
                    : "secondary"
                }
                size="middle"
                $iconClass="icon-vendor"
                $prefixImg={
                  <ImageDiv
                    src="/images/DashBoard/down-arrow.svg"
                    $applyfilter={isProfilePage(window.location.pathname)}
                    style={{ marginLeft: "12px" }}
                  />
                }
              ></IconWrapper>
            </IconWrap>
            {/* show bottom border */}
            {isProfilePage(window.location.pathname) && menuItemBottomBorder}
          </Box>
        </div>
      </DropdownDiv>
    );
  };

  const handleOnVASMenuClick = (e) => {
    switch (e.key) {
      case "0": {
        window.open(VAS_NAVIGATION_LINKS.PRO_FINANCE, "_blank");
        break;
      }
      case "1": {
        window.open(VAS_NAVIGATION_LINKS.PRO_SOURCE, "_blank");
        break;
      }
      case "2": {
        window.open(VAS_NAVIGATION_LINKS.PRO_CONNECT, "_blank");
        break;
      }
      default:
        break;
    }
  };

  /** Nested sub-menu in Profle menu item */
  const vasMenu = (
    <Menu onClick={handleOnVASMenuClick}>
      <MenuItem 
        key="0"
        {...{
          [HEAP_DATA_TRACKING_ID]:
            HEAP_DATA_ATTRIBUTES.NAVIGATION.VAS.PRO_FINANCE,
        }}
      >
        ProFinance
      </MenuItem>
      <MenuItem 
        key="1"
        {...{
          [HEAP_DATA_TRACKING_ID]:
            HEAP_DATA_ATTRIBUTES.NAVIGATION.VAS.PRO_SOURCE,
        }}
      >
        ProSource
      </MenuItem>
      <MenuItem 
        key="2"
        {...{
          [HEAP_DATA_TRACKING_ID]:
            HEAP_DATA_ATTRIBUTES.NAVIGATION.VAS.PRO_CONNECT,
        }}
      >
        ProConnect
      </MenuItem>
    </Menu>
  );

  const renderVASDropdown = () => {
    return (
      <DropdownDiv overlay={vasMenu}>
        <div
          style={{ display: "flex", alignItems: "center" }}
          onMouseEnter={() => {
            setIsVasHovered(true);
            onHoverMenuItemHandler("vas")?.onMouseEnter();
          }}
          onMouseLeave={() => {
            setIsVasHovered(false);
            onHoverMenuItemHandler("vas")?.onMouseLeave();
          }}
        >
          <Box position={"relative"}>
            <IconWrap>
              <IconWrapper
                {...{
                  [HEAP_DATA_TRACKING_ID]:
                    HEAP_DATA_ATTRIBUTES.NAVIGATION.VAS.HOMEPAGE,
                }}
                $isHovered={isVasHovered || hoveredMenuItemName === "vas"}
                title={"Vendor Plus"}
                onClick={() => handleClick("vas-homepage")}
                variant={hoveredMenuItemName === "vas"
                  ? "primary"
                  : "secondary"
                }
                size="middle"
                $iconClass="icon-vendor"
                customIcon={(
                  <Flex
                    position={"relative"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <img
                      alt="vas"
                      src={hoveredMenuItemName === "vas"
                        ? "/images/bank_green.svg"
                        : "/images/bank_white.svg"
                      }
                    />
                    <Flex 
                      position={"absolute"}
                      top={"-12px"}
                      right={"-30px"}
                      alignItems={"center"} 
                      justifyContent={"center"}
                      borderRadius={"2px"}
                      padding={"4px"}
                      background={"linear-gradient(180deg, #F6C033 0%, #E16308 100%)"}
                      height={"14px"}
                      fontWeight={600}
                      color={"#fff"}
                    >
                      New
                    </Flex>
                  </Flex>
                )}
                $prefixImg={
                  <ImageDiv
                    src="/images/DashBoard/down-arrow.svg"
                    style={{ marginLeft: "12px" }}
                    $applyfilter={hoveredMenuItemName === "vas"}
                  />
                }
              ></IconWrapper>
            </IconWrap>
          </Box>
        </div>
      </DropdownDiv>
    );
  };

  const isProfilePage = (pathName) => {
    return (
      pathName === "/userinfo" ||
      pathName === "/companyinfo" ||
      pathName === "/capabilities" ||
      pathName === "/experience" ||
      pathName === "/certifications" ||
      // pathName === "/references" ||
      pathName === "/people" ||
      pathName === "/my-profile"
    );
  };

  const menuItemBottomBorder = (
    <Box
      width={"100%"}
      bottom={"0px"}
      position={"absolute"}
      border={"2px solid #47C882"}
    />
  );

  const TRIGGER_CASES = {
    REQUESTS: window.location.pathname?.match("/request") ||
      window.location.pathname?.match("/job-request") ||
      window.location.pathname?.match("/job") ||
      hoveredMenuItemName === "requests" ||
      window.location.pathname?.match("/open-jobs") ||
      window.location.pathname?.match("/interested-jobs") ||
      window.location.pathname?.match("/plant-visits"),
  }

  return (
    <ButtonContainer isOnboarding={isOnBoarding}>
      {/* Menu Item - Dashboard */}
      <BttnDiv
        $backgroundColor={
          window.location.pathname === "/dashboard" && onHoverState
            ? "#215EC9"
            : "#011759"
        }
        {...onHoverMenuItemHandler("dashboard")}
      >
        <Box position={"relative"} height={"100%"}>
          <IconWrap>
            <IconWrapper
              title={"Dashboard"}
              onClick={() => handleClick("dashboard")}
              size="middle"
              $iconClass="icon-dashboard"
              variant={
                window.location.pathname === "/dashboard" ||
                hoveredMenuItemName === "dashboard"
                  ? "primary"
                  : "secondary"
              }
              $noBackgroundHover={true}
            />
          </IconWrap>
          {/* show bottom border */}
          {window.location.pathname === "/dashboard" && menuItemBottomBorder}
        </Box>
      </BttnDiv>

      {/* Menu Item - Profile */}
      <BttnDiv $backgroundColor={onHoverState ? "#215EC9" : "#011759"}>
        {renderProfileDropdown()}
      </BttnDiv>

      {/* Menu Item - Requests/Jobs */}
      <BttnDiv {...onHoverMenuItemHandler("requests")}>
        <Box position={"relative"} height={"100%"}>
          <IconWrap>
            <IconWrapper
              title={"Jobs"}
              onClick={() => handleClick("requests")}
              variant={
                TRIGGER_CASES.REQUESTS
                  ? "primary"
                  : "secondary"
              }
              size="middle"
              $iconClass=""
              customIcon={(
                <img
                  src={TRIGGER_CASES.REQUESTS
                    ? "/images/work_green.svg"
                    : "/images/work.svg"
                  }
                  alt="jobs"
                />
              )}
              $prefixImg={
                <></>
                // totalNewUpdateRequestCount > 0 ? (
                //   <GreenIndicator></GreenIndicator>
                // ) : (
                //   <></>
                // )
              }
              $noBackgroundHover={true}
            ></IconWrapper>
          </IconWrap>
          {/* show bottom border */}
          {Boolean(window.location.pathname?.match("/request") || window.location.pathname?.match("/job-request") || window.location.pathname?.match("/job") || window.location.pathname?.match("/interested-jobs") || window.location.pathname?.match("/plant-visits")) && menuItemBottomBorder}
        </Box>
      </BttnDiv>

      {/* Menu Item - VAS */}
      <BttnDiv $backgroundColor={onHoverState ? "#215EC9" : "#011759"}>
        {renderVASDropdown()}
      </BttnDiv>

      {/* Menu Item - Community */}
      <BttnDiv {...onHoverMenuItemHandler("community")}>
        <Box position={"relative"} height={"100%"}>
          <IconWrap>
            <IconWrapper
              size="middle"
              $prefixImg={<></>}
              title={"Community"}
              $iconClass="icon-vendor"
              $noBackgroundHover={true}
              onClick={() => handleClick("community")}
              $isHovered={hoveredMenuItemName === "community"}
              variant={hoveredMenuItemName === "community"
                ? "primary"
                : "secondary"
              }
              customIcon={(
                <MdGroups 
                  fontSize={"28px"}
                  color={hoveredMenuItemName === "community" 
                    ? "#47c882" 
                    : "#fff"
                  }
                />
              )}
              {...{ [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.NAVIGATION.COMMUNITY }}
            ></IconWrapper>
          </IconWrap>
        </Box>
      </BttnDiv>

      {/* commented for future reference */}
      {/* Menu Item - Messaging */}
      {/* <BttnDiv
        $backgroundColor={onHoverState ? "#215EC9" : "#011759"}
        {...onHoverMenuItemHandler("communication")}
      >
        <Box positin={"absolute"}>
          <IconWrap>
            <IconWrapper
              title={"Messaging"}
              onClick={() => handleClick("chats")}
              variant={
                window.location.pathname === "/communication" ||
                hoveredMenuItemName === "communication"
                  ? "primary"
                  : "secondary"
              }
              size="middle"
              $iconClass="icon-Chats"
              $prefixImg={
                totalUnreadMsgs > 0 ? <GreenIndicator></GreenIndicator> : <></>
              }
              $noBackgroundHover={true}
            ></IconWrapper>
          </IconWrap>
          // show bottom border
          {Boolean(window.location.pathname === "/communication") &&
            menuItemBottomBorder}
        </Box>
      </BttnDiv> */}
    </ButtonContainer>
  );
};

export default NavigationTab;
