import styled from "styled-components";
import { Button as Bttn } from "antd";

interface AuthBttnProps {
  $background: string;
  $color: string;
  $marginTop?: string;
}

export const AuthBttn = styled(Bttn) <AuthBttnProps>`
  width: 100%;
  height: 40px;
  margin: 0px 0px 2px 0px;
  border: 2px solid #47c882;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${(props) => props?.$color};
  background: ${(props) => props?.$background};
  &:hover {
    color: ${(props) => props?.$color};
    background: ${(props) => props?.$background};
  }
  &:disabled {
    border-color: #d7e9df;
    background: #d7e9df;
    color: #ffffff;
  }
  #spanDesktoptext {
    display: block;
  }
  #spanMobiletext {
    display: none;
  }
  @media only screen and (max-width: 640px) {
    margin-top: ${(props) => props?.$marginTop};
    #spanDesktoptext {
      display: none;
    }
    #spanMobiletext {
      display: block;
    }
    width: 280px;
    height: 36px;
    font-weight: 800;
    font-size: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 0px;
  }
`;
