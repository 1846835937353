import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "antd";
import { IconButton } from "components/Button";
import { BasicLayOut } from "components/Layout";
import { companyInfoGet } from "reducers/companyInfo";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";

import { documentInfoGet } from "reducers/documentInfo";
import { referenceInfoGet } from "reducers/referenceInfo";
import useInterval from "hooks/useInterval";
import { getChatList, getChatMessages, getSelectedChat } from "reducers/chat";
import { handleCancelRetainSearchKey } from "shared/handlers/requestHandlers";
import { getNewUpdateRequestCount } from "reducers/PlantVisitRequest";
import { AppConfig } from "ui-constants";
import LocalStorageService from "services/LocalStorageService";
import { profileCompletionStatus } from "reducers/profileCreation";
import VerifyEmailModal from "Authentication/VerifyEmailModal/VerifyEmailModal";
import SessionStorageService from "services/SessionStorageService";
import ApiConstant from "constants/ApiConstant";
import NotPublishedModal from "MarketingSuite/ShareableLinkModal/NotPushliedModal";
import { Box, Flex } from "venwiz-ui";

interface DashboardLayoutProps {
  children: any;
  height?: string;
  usedAt?: string;
  sideBarStyle?: any;
  innerWrapperMargin?: string;
  innerWrapperWidth?: string;
  innerWrapperHeight?: string;
  maxWidth?: string;
  flexContainerPadding?: object;
  marginBottom?: string;
  // below prop causes cursor to show tooltip text as the string value passed in it
  title?: string;
}

const FlexInfoContainer = styled.div`
  width: 100%;
  height: calc(100vh - 60px);
  overflow-y: hidden;
  @media screen and (max-width: 640px) {
    padding: 0px;
    height: calc(100% - 150px);
    margin-top: 56px;
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  position: fixed;
  height: 100%;
  z-index: 99;
  background: #f4f6ff;
  width: 100px;
  align-items: center;
  @media screen and (max-width: 640px) {
    display: none;
  }
`;

const FlexContainer = styled(Flex)<DashboardLayoutProps>`
  width: 100%;
  @media screen and (max-width: 640px) {
    padding: 0px;
  }
`;

const InnerWrapper = styled.div<DashboardLayoutProps>`
  display: flex;
  overflow: auto;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 20px 30px rgb(159 179 248 / 12%);
  height: 100%;
  overflow: auto;
  @media screen and (max-width: 640px) {
    margin-top: 0px;
    margin-left: 0;
    width: 100vw;
    min-width: 0px;
    height: calc(100% - 56px);
    border-radius: 0px;
    position: fixed;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BttnDiv = styled.div`
  margin-bottom: 30px;
  @media screen and (max-height: 890px) {
    margin-bottom: 27px;
  }
  @media screen and (max-height: 850px) {
    margin-bottom: 25px;
  }
  @media screen and (max-height: 800px) {
    margin-bottom: 20px;
  }
`;

const UnreadChatCount = styled.div`
  font-weight: 600;
  color: #ffffff;
  border: 1px solid;
  border-radius: 50%;
  padding: 2px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  position: absolute;
  right: -10px;
  z-index: 100;
  background: #be000c;
`;

const DashboardLayout = (props: DashboardLayoutProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const marketingSuiteState = useSelector((state: any) => state.marketingSuite);
  const { ShowPublishModal } = marketingSuiteState;
  const userInfoRoute = window.location.href
    .toLocaleLowerCase()
    .includes("userinfo");
  const userInfoData =
    useSelector((state: any) => state.userInfoApi.userInfoData) || {};
  const location = useLocation();
  const chatInfo = useSelector((state: any) => state.chatInfo);
  const showEmailVerificationPopup =
    JSON.parse(
      SessionStorageService.get(
        ApiConstant.STORAGE_KEYS.showEmailVerificationPopup
      )
    ) || "";

  useEffect(() => {
    if (userInfoData?.gstn)
      dispatch(getNewUpdateRequestCount(userInfoData?.gstn));
  }, [userInfoData]);

  useInterval(
    () => {
      const queryParam = {
        sender: "vendor",
        sender_id: userInfoData?.gstn,
      };
      dispatch(getChatList(queryParam));
      dispatch(getNewUpdateRequestCount(userInfoData?.gstn));
    },
    // Delay in milliseconds or null to stop it
    10000
  );
  useEffect(() => {
    const queryParam = {
      sender: "vendor",
      sender_id: userInfoData?.gstn,
    };
    dispatch(getChatList(queryParam));
    if (location.pathname !== "/communication" && !location.pathname.includes("job")) {
      getSelectedChat(dispatch, (chatInfo.data = null));
    }
  }, [location]);

  return (
    <BasicLayOut title={props.title}>
      {showEmailVerificationPopup && userInfoRoute && (
        <VerifyEmailModal></VerifyEmailModal>
      )}
      {ShowPublishModal && <NotPublishedModal />}
      <FlexInfoContainer>
        <InnerWrapper>
          <FlexContainer {...props} padding={props.flexContainerPadding || "32px"}>{props.children}</FlexContainer>
        </InnerWrapper>
      </FlexInfoContainer>
    </BasicLayOut>
  );
};

export default DashboardLayout;
