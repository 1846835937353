import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import {
  restrictOrNavigateOnBoarding,
  NavigateOrRestrictUserFromOnBoardingStatus,
} from "shared/handlers/restrictOrNavigateToOnBoarding";
import {
  initializeHeapAnalytics,
  initializeHeapAnalyticsOnlyForNonInternalVenwizUser,
} from "shared/handlers/heapAnalytics.handler";

const Main = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { PUBLIC_ROUTES } = AppConfig;
  const isPublicRoute = PUBLIC_ROUTES.includes(history.location.pathname);
  const localStorageData = LocalStorageService.get(
    AppConfig.STORAGE_KEYS.userData
  );

  /** For Heap Analytics */
  useEffect(() => {
    /**
     * For a logged in user:
     * initialize heap analytics - after page is refreshed,
     * by calling heap.load function
     * reference: https://developers.heap.io/docs/web#base-installation
     */
    if (localStorageData?.email) {
      initializeHeapAnalyticsOnlyForNonInternalVenwizUser(
        localStorageData?.email,
        window
      );
    } else {
      /**
       * For a non-logged in user:
       * initialize heap analytics - after page is refreshed,
       * by calling heap.load function
       * reference: https://developers.heap.io/docs/web#base-installation
       */
      if (
        isPublicRoute
      ) {
        initializeHeapAnalytics(window);
      }
    }
  }, [localStorageData]);

  /** For Navigation */
  useEffect(() => {
    if (!isPublicRoute) {
      NavigateOrRestrictUserFromOnBoardingStatus({
        GSTN: localStorageData?.gstn,
        dispatch,
        history,
        EMAIL: localStorageData?.email,
      });
    }
    if (
      isPublicRoute &&
      LocalStorageService.get(AppConfig.STORAGE_KEYS.isRememberEnabled) &&
      localStorageData.access_token
    ) {
      restrictOrNavigateOnBoarding(history);
    }
  }, []);

  return <>{children}</>;
};
export default Main;
