import React, { useContext, useEffect, useState } from "react";
import openNotification from "constants/antdNotification";
import BasicDropdown from "components/Dropdown/BasicDropdown/BasicDropdown";
import TextAreaWithHeader from "components/TextArea/TextAreaWithHeader/TextArea";
import { UserInfo } from "ContextApi/ContextApi";
import { DropdownData, experienceProfileTexts } from "constants/mockData";
import { CardTag } from "components/Card";
import { fileUploadPost } from "reducers/fileUpload";
import { useDispatch, useSelector } from "react-redux";
import { message, Tooltip } from "antd";
import { tempData } from "reducers/experienceInfo";
import ProfileInputHeading from "Profile/shared/Heading/Heading";
import FormField, {
  ErrorMessageDiv,
  ImageHolder,
} from "Profile/shared/FormField/FormField";
import { handleScrollToView } from "shared/handlers/form.handler";
import {
  CompanyVerficationStatus,
  CompanyVerifyTooltipDiv,
  Container,
  ContainerDiv,
  DateDesktopView,
  DateMobileView,
  DropdownWrapper,
  EXPERIENCE_STYLES,
  FlexWrapContainer,
  Heading,
  InputWrap,
  ProdDescBttnWrapper,
  ProjectDocTextDiv,
  ProjScopeWrapper,
  CustomTagContainer,
  UploadBttn,
  UploadIcon,
  VenwizUIDatePickerWithLabel,
  ExperienceCustomReactQuillEditorWrapper,
} from "./Experience.styles";
import { toggleIsPageEdited } from "reducers/profileCreation";
import { DatePickerWithLabel } from "components/DatePicker";
import moment from "moment";
import {
  checkForMobileScreen,
  getUserScreenSize,
} from "shared/handlers/getUserScreenSize.handler";
import {
  AddManuallyBttn,
  LineHider,
} from "Profile/Capabilities/CapabilitiesNew.styles";
import {
  getVerificationComponentBorderColor,
  getVerificationStatusImgSrc,
} from "Profile/helpers";
import { VerificationStatus } from "Profile/enums";
import {
  HEAP_DATA_ATTRIBUTES,
  HEAP_DATA_TRACKING_ID,
} from "constants/heapAnalytics";
import { RiCloseCircleLine, RiMenuAddFill } from "react-icons/ri";
import {
  IoCalendarNumberOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";
import { GrUpload } from "react-icons/gr";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";
import { FaCheckCircle } from "react-icons/fa";
import { useMediaQuery } from "hooks/useMediaQuery.hook";
import { DEVICE } from "ui-constants/mediaQueries.constants";
import {
  Accordion,
  Box,
  Button,
  Card,
  Flex,
  InputWithLabel,
  POPOVER_PLACEMENT_OPTION,
  Typography,
  Upload,
  Tooltip as VenwizTooltip,
} from "venwiz-ui";
import { DummyAddReviewForm } from "./AddReviewDummyForm/AddReviewDummyForm";
import { ViewReviewModal } from "./ViewReviewModal/ViewReviewModal";
import { CLICKED_REVIEW_INITIAL_STATE } from "./ViewReviewModal/constants";
import {
  REQUEST_FOR_REVIEW_FORM_STATUS,
  isRequestForReviewEmailValid,
} from "./constants.experience";
import { colors } from "Theme";
import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai";
import LocalStorageService from "services/LocalStorageService";
import { AppConfig } from "ui-constants";
import { heapTrackTrigger } from "shared/handlers/heapAnalytics.handler";
import { domainNames } from "constants/domainNames";
import { HelpButtonWithPopOver } from "components/HelpButtonWithPopOver/HelpButtonWithPopOver";
import SessionStorageService from "services/SessionStorageService";
import { updateUserKnowsAboutHelpButton } from "reducers/auth";
import { RootState } from "reducers";
import { FORM_DATA_KEY } from "Request/Constants/constants";
import { FileOperationsAction } from "shared/redux/file-operations";
import ReactQuill from "react-quill";
import { 
  modules, 
  BLOCK_TAG_NAME,
  VISUALLY_EMPTY_STRING, 
  DIV_WITH_EMPTY_SPACES_PATTERN,
  VISUALLY_EMPTY_STRING2
} from "components/CustomReactQuillEditor/reqctQuill.constants";
import { 
  cleanFormatting,
  removeEmptyHTMLElementsFromString,
} from "components/CustomReactQuillEditor/reactQuill.helper";
import { reactQuillCustomStyles } from "components/CustomReactQuillEditor/reactQuillEditor.styles";

interface ExperienceProps {
  isFirstError?: React.MutableRefObject<boolean>;
  data?: any;
  validationState?: boolean;
  formik?: any;
  onSubmit?: () => void;
  handleAlertMessage?: (e: any) => void;
}

const ExperienceConatainerNew = ({
  data,
  handleAlertMessage,
  validationState,
  isFirstError,
}: ExperienceProps) => {
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const isMobileView = checkForMobileScreen();
  const isLargeDesktop = useMediaQuery(DEVICE.lg);
  const isScreenWidthLessThan640px = !useMediaQuery(DEVICE.sm);
  const [showViewReviewModal, setShowViewReviewModal] = useState(false);
  const [addManuallyBttnState, setAddManuallyBttnState] = useState(true);
  const USER_GSTN = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)?.gstn || urlParams.get("gstn");
  const USER_EMAIL = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)?.email 
    || SessionStorageService.get(AppConfig.STORAGE_KEYS.userData)?.email
    || "";
  const ACCESSED_HELP = Boolean(LocalStorageService.get(AppConfig.STORAGE_KEYS.userData)?.accessedHelp)
  const [accessedHelpFromLocalStorage, setAccessedHelpFromLocalStorage] = useState(ACCESSED_HELP);
  const [showDummyAddReviewForm, setShowDummyAddReviewForm] = useState(false);
  const [showRequestReviewAccordion, setShowRequestReviewAccordion] = useState(true);
  const [
    areRequestForReviewInputsDisabled,
    setAreRequestForReviewInputsDisabled,
  ] = useState(false);
  const [clickedReview, setClickedReview] = useState(
    CLICKED_REVIEW_INITIAL_STATE
  );
  const profileTextsType = getUserScreenSize();
  const [tempDoc, setTempDoc] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [fileName, setFileName] = useState("");
  const [experienceInfoState, setExperienceInfoState] = useContext(UserInfo);
  const experienceInfoSelector = useSelector(
    (state: RootState) => state.experienceInfo
  );

  // text editor related values
  const quillRef = React.useRef<any>(null);
  const Quill = ReactQuill.Quill 
  var Block = Quill.import('blots/block');
  Block.tagName = BLOCK_TAG_NAME;
  Quill.register(Block);

  /**
   * returns proper date string in format YYYY-MM
   * even if backend sends date in format "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
   */
  const getProperStartOrEndDate = (dateString: string) => {
    if (
      dateString === "MM-YYYY" ||
      dateString === "" ||
      (dateString && dateString?.length === 7)
    )
      return dateString;
    const momentObj = moment(dateString, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
    const year = momentObj.format("YYYY");
    const month = momentObj.format("MM");
    return `${year}-${month}`;
  };

  const { experienceInfoData } = experienceInfoSelector;
  const PROJ_START_DATE = getProperStartOrEndDate(data?.item?.projStartDate);
  const PROJ_END_DATE = getProperStartOrEndDate(data?.item?.projEndDate);

  const isProjectEndDateDisabledByDefault =
    PROJ_START_DATE === "MM-YYYY" || !PROJ_START_DATE ? true : false;
  const [disableDatesBefore, setDisableDatesBefore] = useState(
    isProjectEndDateDisabledByDefault
  );
  const [projMinEndDate, setProjMinEndDate] = useState(new Date());
  const IS_REQUEST_FOR_REVIEW_ALREADY_PRESENT =
    data?.item?.review?._id || false;
  const [requestForReviewFormErrors, setRequestForReviewFormErrors] = useState({
    clientUserName: false,
    designation: false,
    email: false,
    note: false,
  });
  const PROJECT_NAME_FOR_HEAP_TRACK = `Project ${(data?.index || 0) + 1}` || "";

  const TooltipMsg = {
    notVerified: "Verification failed, please upload correct document",
    inProgress: "Upload project document to verify",
  };

  useEffect(() => {
    const IS_REQUEST_FOR_REVIEW_INPUT_FIELD_DISABLED = (() => {
      const REVIEW_STATUS: string = data?.item?.review?.reviewStatus;
      if (!REVIEW_STATUS) {
        /**
         * If review status is not present, then the request for review form
         * is not present, so we can enable the input fields
         */
        return false;
      }

      return ([
        REQUEST_FOR_REVIEW_FORM_STATUS.REVIEW_REQUESTED,
        REQUEST_FOR_REVIEW_FORM_STATUS.REVIEW_ACCEPTED,
      ] as string[])?.includes(REVIEW_STATUS);
    })();
    setAreRequestForReviewInputsDisabled(
      IS_REQUEST_FOR_REVIEW_INPUT_FIELD_DISABLED
    );
  }, [data?.item?.review?.reviewStatus]);

  /**
   * This function is used to validate the request for review form
   * present in each experience section
   */
  const isRequestForReviewFormValid = (item, index) => {
    const NOTE = item?.review?.note || ""; // not mandatory
    const EMAIL = item?.review?.clientDetails?.email || "";
    const CLIENT_USER_NAME = item?.review?.clientDetails?.name || "";
    const DESIGNATION = item?.review?.clientDetails?.designation || "";

    if (
      /** Default Form State */
      (NOTE === "" &&
        EMAIL === "" &&
        CLIENT_USER_NAME === "" &&
        DESIGNATION === "") ||
      /** All fields are filled & Email is valid */
      (CLIENT_USER_NAME &&
        DESIGNATION &&
        EMAIL &&
        isRequestForReviewEmailValid(EMAIL))
    ) {
      return true;
    } else {
      /** Any field is filled & Email is invalid */
      return false;
    }
  };

  //To Decide which are the mandatory field in project scope based on screen size
  const projectValidation = () => {
    if (isMobileView) {
      return data?.description?.length || documents.length;
    } else {
      return data?.description?.length;
    }
  };

  useEffect(() => {
    if (
      data.item.city !== "" &&
      data.item.clientCompanyName !== "" &&
      (data.item.clientIndustry !== "" || isMobileView) &&
      projectValidation()
    ) {
      handleAlertMessage(true);
    } else {
      handleAlertMessage(false);
    }
  }, [experienceInfoState]);

  /**
   * UseEffect Checks & validates Request for Review Form
   */
  useEffect(() => {
    if (!areRequestForReviewInputsDisabled) {
      /**
       * Validate the request for review form,
       * only if the form is not disabled;
       *  - which happens only when the experience has its review-form's reviewStatus is:
       *    either "reviewRequested"(by client) or "reviewRequested"(by vendor)
       */
      const IS_VALID = isRequestForReviewFormValid(data?.item, data?.index);
      const NOTE = data?.item?.review?.note || "";
      const EMAIL = data?.item?.review?.clientDetails?.email || "";
      const CLIENT_USER_NAME = data?.item?.review?.clientDetails?.name || "";
      const DESIGNATION = data?.item?.review?.clientDetails?.designation || "";

      if (IS_VALID) {
        setRequestForReviewFormErrors({
          clientUserName: false,
          designation: false,
          email: false,
          note: false,
        });

        const GSTN: string = USER_GSTN;
        if (GSTN && !IS_REQUEST_FOR_REVIEW_ALREADY_PRESENT) {
          /** Add or Remove GSTN for new review object withn the current experience object */
          let editedCurrentExperienceData = [...experienceInfoState.experience];
          const currentExperienceObj = {
            ...editedCurrentExperienceData[data.index],
            descriptionPlainText: quillRef.current?.unprivilegedEditor?.getText() || "",
            flag: true,
          };
          if (
            EMAIL &&
            isRequestForReviewEmailValid(EMAIL) &&
            CLIENT_USER_NAME &&
            DESIGNATION
          ) {
            /**
             * when the form is valid and all mandatory fields are filled,
             * - inject GSTN for the review object with the current experience
             */
            if (isRequestForReviewFormValid(currentExperienceObj, data.index)) {
              currentExperienceObj.review["gstn"] = USER_GSTN;
              /** Inject client company name from experience object */
              currentExperienceObj.review.clientDetails["company"] =
                currentExperienceObj.clientCompanyName;
            }
          } else if (!EMAIL && !CLIENT_USER_NAME && !DESIGNATION && !NOTE) {
            /**
             * when the form is valid and all mandatory fields are empty,
             * - remove GSTN for the review object with the current experience
             */
            if (currentExperienceObj?.review)
              delete currentExperienceObj["review"];
          }
          editedCurrentExperienceData[data.index] = currentExperienceObj;
          updateState(editedCurrentExperienceData);
        }
      } else if (!IS_VALID) {
        const isEmailValid = EMAIL && isRequestForReviewEmailValid(EMAIL);
        const isClientUserNameValid = CLIENT_USER_NAME;
        const isDesignationValid = DESIGNATION;
        const isNoteValid =
          (!EMAIL && !CLIENT_USER_NAME && !DESIGNATION && !NOTE) ||
          (EMAIL &&
            isRequestForReviewEmailValid(EMAIL) &&
            CLIENT_USER_NAME &&
            DESIGNATION);

        setRequestForReviewFormErrors((prev) => ({
          ...prev,
          email: !isEmailValid,
          clientUserName: !isClientUserNameValid,
          designation: !isDesignationValid,
          note: !isNoteValid,
        }));
      }
    }
  }, [
    // experience fields
    data?.item?.clientCompanyName,
    // request for review fields
    data?.item?.review?.note,
    data?.item?.review?.clientDetails?.email,
    data?.item?.review?.clientDetails?.name,
    data?.item?.review?.clientDetails?.designation,
  ]);

  /**
   * This function is used to update the state of the experience
   */
  const updateState = (
    udpdatedExperienceData: typeof experienceInfoState.experience
  ) => {
    setExperienceInfoState({
      experienceId: experienceInfoData?.experienceId,
      experience: udpdatedExperienceData,
    });
    tempData(dispatch, udpdatedExperienceData);
  };

  const handleOnChange = (e, type = "others") => {
    let editCurrentData = [...experienceInfoState.experience];

    if (type === "clientIndustry") {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        ["clientIndustry"]: e,
        flag: true,
      };
      toggleIsPageEdited(dispatch, true);
    } else if (type === "documents") {
      if (documents[0] === undefined) {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          ["document"]: {},
          flag: true,
        };
      } else {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          ["document"]: documents[0],
          flag: true,
        };
      }
    } else if (type === "projStartDate") {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        [type]: e ? moment(e).format("YYYY-MM") : "MM-YYYY",
        ["projEndDate"]: "MM-YYYY",
        flag: true,
      };

      /** set minimum end date for the project */
      const DATE_STRING_WITH_NEXT_MONTH = moment(e)
        .add(1, "month")
        .format("YYYY-MM");
      !e
        ? setProjMinEndDate(new Date())
        : setProjMinEndDate(new Date(DATE_STRING_WITH_NEXT_MONTH));
    } else if (type === "projEndDate") {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        [type]: e ? moment(e).format("YYYY-MM") : "MM-YYYY",
        flag: true,
      };
    } else if (type === "projectCost") {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        ["projectCost"]: e,
        flag: true,
      };
      toggleIsPageEdited(dispatch, true);
    } else if (type === "requestReviewClientUserName") {
      if (!IS_REQUEST_FOR_REVIEW_ALREADY_PRESENT) {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          flag: true,
          review: {
            ...editCurrentData[data.index]?.review,
            clientDetails: {
              ...editCurrentData[data.index]?.review?.clientDetails,
              name: e,
            },
          },
        };
        toggleIsPageEdited(dispatch, true);
      }
    } else if (type === "requestReviewDesignation") {
      if (!IS_REQUEST_FOR_REVIEW_ALREADY_PRESENT) {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          flag: true,
          review: {
            ...editCurrentData[data.index]?.review,
            clientDetails: {
              ...editCurrentData[data.index]?.review?.clientDetails,
              designation: e,
            },
          },
        };
        toggleIsPageEdited(dispatch, true);
      }
    } else if (type === "requestReviewEmail") {
      if (!IS_REQUEST_FOR_REVIEW_ALREADY_PRESENT) {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          flag: true,
          review: {
            ...editCurrentData[data.index]?.review,
            clientDetails: {
              ...editCurrentData[data.index]?.review?.clientDetails,
              email: e,
            },
          },
        };
        toggleIsPageEdited(dispatch, true);
      }
    } else if (type === "requestReviewNote") {
      if (!IS_REQUEST_FOR_REVIEW_ALREADY_PRESENT) {
        editCurrentData[data.index] = {
          ...editCurrentData[data.index],
          flag: true,
          review: {
            ...editCurrentData[data.index]?.review,
            note: e,
          },
        };
        toggleIsPageEdited(dispatch, true);
      }
    } else {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        [e.target.name]: e.target.value.trimStart(),
        flag: true,
      };
      toggleIsPageEdited(dispatch, true);
    }

    updateState(editCurrentData);
  };

  const getFile = (e: any) => {
    const file = e.target.files[0];
    if (file.size / 1024 / 1024 > 20) {
      message.error(
        `File size exceeds the 20 mb limit. Please try uploading a smaller file size`
      );
    }
    if (typeof file !== "undefined" && file.size / 1024 / 1024 < 20) {
      setFileName(file?.name);
      const HEAP_FILE_OBJ = {
        fileName: file?.name,
        fileSize: file?.size,
        fileExtension: file?.name?.split(".")?.pop(),
      };

      /** Handle Permanent File Upload with single API call */      
      const formData = new FormData();
      formData.append(FORM_DATA_KEY, file);
      dispatch(FileOperationsAction.uploadFilesToPermanentFolder(formData))
        .then((permanentFiles) => {
          const uploadedFile = permanentFiles?.[0];
          setVerificationStatus("added");
          setDocuments([{ name: file?.name, documentFile: { ...uploadedFile } }]);
          setTempDoc([]);
          setFileName("");
          heapTrackTrigger({
            windowObj: window,
            eventName:
              HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                .PROJECT_DOCS_FILE_UPLOAD.SUCCESS,
            value: HEAP_FILE_OBJ,
          });
          toggleIsPageEdited(dispatch, true);
        })
        .catch((err) => {
          openNotification("Document Upload Failed");
          setTempDoc([]);
          heapTrackTrigger({
            windowObj: window,
            eventName:
              HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                .PROJECT_DOCS_FILE_UPLOAD.FAILURE,
            value: HEAP_FILE_OBJ,
          });
        });
      setTempDoc([
        {
          name: file?.name,
          documentFile: { fileName: file?.name, fileSize: file?.size },
        },
      ]);
    }
  };

  const closeTag = (data) => {
    setDocuments(
      documents.filter((item) => {
        return item.name !== data.name;
      })
    );
    setVerificationStatus("removed");
    toggleIsPageEdited(dispatch, true);
  };

  const setVerificationStatus = (docAction: "removed" | "added") => {
    let editCurrentData = [...experienceInfoState.experience];
    if (docAction === "removed") {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        verificationStatus: VerificationStatus.inProgress,
      };
    } else {
      editCurrentData[data.index] = {
        ...editCurrentData[data.index],
        verificationStatus: VerificationStatus.verified,
      };
    }
    setExperienceInfoState({
      experienceId: experienceInfoData?.experienceId,
      experience: editCurrentData,
    });
  };

  useEffect(() => {
    handleOnChange(null, "documents");
  }, [documents]);

  useEffect(() => {
    if (experienceInfoData?.experience) {
      const value = experienceInfoData?.experience[data.index]?.document;

      if (value?.name) {
        setDocuments([value]);
      }
    }
  }, [experienceInfoData]);

  const onHandleScroll = (id) => {
    if (isFirstError.current) {
      handleScrollToView(id);
      isFirstError.current = false;
    }
    return true;
  };

  const renderUploadedCards = () => {
    const VARIABLE_TEXT_LENGTH = isScreenWidthLessThan640px ? 12 : 14;
    const getTextWithEllipsis = (text: string) => {
      if (text) {
        const value = text.split(".");
        if (text.length > 20) {
          if (value.length <= 1) {
            return `${value[0].substring(
              0,
              VARIABLE_TEXT_LENGTH
            )}...${value[0].substring(value[0].length - 4)}`;
          }
          return `${value[0].substring(
            0,
            VARIABLE_TEXT_LENGTH
          )}...${value[0].substring(value[0].length - 4)}.${value[1]}`;
        }
      }

      return text;
    };

    return (
      <>
        {documents.length > 0 && (
          <CustomTagContainer style={{ marginTop: "10px" }}>
            {documents?.map((doc) => (
              <Card
                width={"max-content"}
                display={"flex"}
                gap={"8px"}
                height="40px"
                alignItems={"center"}
                justifyContent={"space-between"}
                color={`${colors.blue} !important`}
                key={doc?.documentFile?.fileUrl || ""}
                border={`1px solid ${colors.blue}`}
                padding={"4px 6px"}
                borderRadius={"8px"}
                marginBottom={"8px"}
                marginRight={"8px"}
              >
                <Flex
                  marginRight={"4px"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <IoDocumentTextOutline color={colors.blue} size={20} />
                </Flex>
                <a href={doc?.documentFile?.fileUrl || ""} target="_blank">
                  <Typography
                    cursor={"pointer"}
                    color={"rgb(33, 94, 201)"}
                    style={{ textDecoration: "underline" }}
                    width={{ default: "30%", sm: "max-content" }}
                  >
                    {getTextWithEllipsis(doc?.name)}
                  </Typography>
                </a>
                <Flex
                  marginLeft={"2px"}
                  width={"max-content"}
                  cursor={"pointer"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  onClick={() => {
                    closeTag(doc);
                    toggleIsPageEdited(dispatch, true);
                  }}
                >
                  <RiCloseCircleLine fontSize={"20px"} />
                </Flex>
              </Card>
            ))}
          </CustomTagContainer>
        )}
      </>
    );
  };

  const projDescErrorState = () => {
    const IS_PROJECT_DESC_ERROR = !data.item.description 
      || data.item.description === VISUALLY_EMPTY_STRING || data.item.description === VISUALLY_EMPTY_STRING2
      || removeEmptyHTMLElementsFromString(data.item.description) === "";
    
    const IS_VALID_PROJECT_DESC = !IS_PROJECT_DESC_ERROR;
    
    if (checkForMobileScreen()) {
      // return !(data.item.description.length || documents.length);
      return !(IS_VALID_PROJECT_DESC || documents.length);
    } else {
      // return !data.item.description.length;
      return !IS_VALID_PROJECT_DESC;
    }
  };

  const IS_PROJECT_DESC_FIELD_ERROR = projDescErrorState() 
    && validationState 
    && onHandleScroll(`project-or-contact-desc${data?.index}`);

  return (
    <>
      {/* Main Container for new Experience Fields */}
      <Flex
        gap={"12px"}
        width={"100%"}
        paddingTop={"12px"}
        flexDirection={"column"}
        backgroundColor={"rgb(237, 243, 252)"}
        aria-label={"Experience Form Container"}
        padding={{ default: "22px 4px", xs: "6px", sm: "22px" }}
      >
        {/* Flex Row #1 */}
        <Flex {...EXPERIENCE_STYLES.ROW}>
          {/* COMPANY NAME - Input Field */}
          <InputWithLabel
            {...EXPERIENCE_STYLES.INPUT_FIELD}
            isMandatory
            inputVariant={"input"}
            header="COMPANY TITLE"
            id={`companyName${data?.index}`}
            errorMessage={"Required Field"}
            fieldDescriptionStyles={{ paddingBottom: "0px" }}
            inputWithIconProps={{
              rightIcon: (
                <>
                  {data.item.clientCompanyName.length > 0 &&
                    (data?.item?.verificationStatus !==
                    VerificationStatus?.verified ? (
                      <VenwizTooltip
                        placement={
                          isScreenWidthLessThan640px
                            ? POPOVER_PLACEMENT_OPTION.BL
                            : POPOVER_PLACEMENT_OPTION.BR
                        }
                        popoverContentProps={{
                          width: "200px",
                          color: "#011759",
                        }}
                        tooltipText={
                          data?.item?.verificationStatus ===
                          VerificationStatus?.notVerified
                            ? TooltipMsg.notVerified
                            : TooltipMsg.inProgress
                        }
                        children={
                          <CompanyVerficationStatus
                            position="initial"
                            src={getVerificationStatusImgSrc(
                              data?.item?.verificationStatus
                            )}
                          />
                        }
                      />
                    ) : (
                      <CompanyVerficationStatus
                        cursor={"default"}
                        position={"initial"}
                        src={getVerificationStatusImgSrc(
                          data?.item?.verificationStatus
                        )}
                      />
                    ))}
                </>
              ),
              inputProps: {
                name: "clientCompanyName",
                placeholder: "Enter company/ plant name",
                value: data?.item?.clientCompanyName || "",
                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleOnChange(e);
                },
                [HEAP_DATA_TRACKING_ID]:
                  HEAP_DATA_ATTRIBUTES.EXPERIENCE.COMPANY_NAME,
                onBlur: (e: any) => {
                  heapTrackTrigger({
                    windowObj: window,
                    eventName:
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK.COMPANY_TITLE,
                    value: {
                      project: PROJECT_NAME_FOR_HEAP_TRACK,
                      companyTitle: e?.target?.value || "",
                    },
                  });
                },
              },
            }}
            hasError={
              data.item.clientCompanyName.trim() === "" && validationState && onHandleScroll(`companyName${data?.index}`)
            }
          />

          {/* CLIENT INDUSTRY - DropDown Input Field*/}
          <InputWithLabel
            {...EXPERIENCE_STYLES.INPUT_FIELD}
            header={"CLIENT INDUSTRY"}
            inputVariant={"autocomplete"}
            id={`client-industry${data?.index}`}
            fieldDescriptionStyles={{ paddingBottom: "0px" }}
            tooltipProps={{ placement: isLargeDesktop ? "bottom" : "br" }}
            inputWithIconProps={{
              inputProps: {
                styles: {
                  /** Styles for react autocomplete */
                  option: (defaultStyles, props) => ({
                    ...defaultStyles,
                    color: "#011759",
                  }),
                },
                placeholder: "Enter Industry",
                maxMenuHeight: 190,
                value: (() => {
                  const VAL: string = data?.item?.clientIndustry;
                  return VAL?.length ? { label: VAL, value: VAL } : null;
                })(),
                options: DropdownData?.clientIndustry,
                onChange: (item: {
                  id: number;
                  label: string;
                  value: string;
                }) => {
                  handleOnChange(item?.value, "clientIndustry");
                  heapTrackTrigger({
                    windowObj: window,
                    eventName:
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                        .CLIENT_INDUSTRY_DROPDOWN,
                    value: {
                      project: PROJECT_NAME_FOR_HEAP_TRACK,
                      clientIndustry: item?.value || "",
                    },
                  });
                },
              },
            }}
          />

          {/* LOCATION(CITY) - Input Field */}
          <InputWithLabel
            {...EXPERIENCE_STYLES.INPUT_FIELD}
            isMandatory
            inputVariant={"input"}
            className={"ClientCity"}
            header="LOCATION"
            id={`client-city${data?.index}`}
            errorMessage={"Required Field"}
            fieldDescriptionStyles={{ paddingBottom: "0px" }}
            inputWithIconProps={{
              inputProps: {
                name: "city",
                label: "City",
                placeholder: "Enter City Name",
                value: data?.item?.city || "",
                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleOnChange(e);
                },
                [HEAP_DATA_TRACKING_ID]: HEAP_DATA_ATTRIBUTES.EXPERIENCE.CITY,
                onBlur: (e: any) => {
                  heapTrackTrigger({
                    windowObj: window,
                    eventName:
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK.LOCATION,
                    value: {
                      project: PROJECT_NAME_FOR_HEAP_TRACK,
                      clientLocation: e?.target?.value || "",
                    },
                  });
                },
              },
            }}
            hasError={
              data.item.city.trim() === "" &&
              validationState &&
              onHandleScroll(`client-city${data?.index}`)
            }
          />
        </Flex>

        {/* PROJECT/CONTRACT DESCRIPTION - TextArea Container - Flex Row #2 - */}
        <Flex {...EXPERIENCE_STYLES.ROW}>
          {/* PROJECT/CONTRACT DESCRIPTION - TextArea */}
          <Flex
            gap={"12px"}
            alignItems={"flex-start"}
            padding={{ default: "0 12px", lg: "0" }}
            width={{ default: "100%", lg: "750px" }}
            id={`project-or-contact-desc${data?.index}`}
          >
            <Flex
              flexDirection={"column"}
              gap={"8px"}
            >
              <Typography
                variant={"span"}
                fontSize={"12px"}
                fontWeight={600}
                color={"#011759"}
                style={{ letterSpacing: "0.1em", opacity: "0.7" }}
              >
                PROJECT/CONTRACT DESCRIPTION
                <Typography
                  variant={"span"}
                  color={"rgb(255, 0, 0)"}
                  fontSize={"12px"}
                >
                  *
                </Typography>
              </Typography>
              <>
                {/* Previous Text Area for reference */}
                {/* <InputWithLabel
                  backgroundColor={"rgb(255, 255, 255)"}
                  id={`project-or-contact-desc${data?.index}`}
                  isMandatory
                  inputVariant={"textarea"}
                  header={"PROJECT/CONTRACT DESCRIPTION"}
                  errorMessage="Please enter minimum 50 characters"
                  fieldDescriptionStyles={{ paddingBottom: "0px" }}
                  width={{
                    default: "100%",
                    xxs: (EXPERIENCE_STYLES.INPUT_FIELD.width as any)?.default,
                    lg: "755px",
                  }}
                  leftIcon={
                    <Box
                      color={"#7E8FAB"}
                      fontSize={"20px"}
                      alignSelf={"flex-start"}
                    >
                      <RiMenuAddFill />
                    </Box>
                  }
                  tooltipProps={{
                    placement: isScreenWidthLessThan640px
                      ? POPOVER_PLACEMENT_OPTION.BL
                      : POPOVER_PLACEMENT_OPTION.BR,
                  }}
                  hasError={
                    projDescErrorState() &&
                    validationState &&
                    onHandleScroll(`project-or-contact-desc${data?.index}`)
                  }
                  inputWithIconProps={{
                    display: "flex",
                    alignItems: "flex-start",
                    inputProps: {
                      color: "#011759",
                      padding: "4px",
                      paddingTop: "0px",
                      marginTop: "-3px",
                      name: "description",
                      value: data?.item?.description || "",
                      minHeight: { default: "92px", xs: "69px", sm: "70px" },
                      [HEAP_DATA_TRACKING_ID]:
                        HEAP_DATA_ATTRIBUTES.EXPERIENCE.DESCRIPTION,
                      placeholder:
                        "Describe project/ contract scope, highlights and achievements",
                      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        handleOnChange(e);
                      },
                      onBlur: (e: any) => {
                        heapTrackTrigger({
                          windowObj: window,
                          eventName:
                            HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                              .DESCRIPTION,
                          value: {
                            project: PROJECT_NAME_FOR_HEAP_TRACK,
                            experienceDescription: e?.target?.value || "",
                          },
                        });
                      },
                    },
                  }}
                /> */}

                {/* Rich Text Editor */}
                <ExperienceCustomReactQuillEditorWrapper
                  isError={IS_PROJECT_DESC_FIELD_ERROR}
                  innerWidth={"100%"}
                  width={{
                    default: "100%",
                    xxs: (EXPERIENCE_STYLES.INPUT_FIELD.width as any)?.default,
                    lg: "755px",
                  }}
                >
                  <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    preserveWhitespace
                    modules={modules}
                    value={data?.item?.description || ""}
                    style={reactQuillCustomStyles({
                      isError: IS_PROJECT_DESC_FIELD_ERROR,
                    })}
                    placeholder={
                      "Describe project/ contract scope, highlights and achievements"
                    }
                    onChange={(value, delta, sources, editor) => {
                      const VAL = data?.item?.description || "";
                      let formattedValue = cleanFormatting({
                        content: value,
                        stateVal: VAL,
                      });

                      const handleOnChangeStateForProjDescWithReactQuill = (value: string) => {
                        let editCurrentData = [...experienceInfoState.experience];
                        editCurrentData[data.index] = {
                          ...editCurrentData[data.index]
                        };
                        editCurrentData[data.index].description = value;
                        if (quillRef?.current?.unprivilegedEditor?.getText) {
                          const descriptionPlainText = quillRef.current?.unprivilegedEditor?.getText();
                          editCurrentData[data.index].descriptionPlainText = descriptionPlainText || "";
                        }
                        updateState(editCurrentData);
                        toggleIsPageEdited(dispatch, true);
                      }

                      if (
                        formattedValue === VISUALLY_EMPTY_STRING || formattedValue === VISUALLY_EMPTY_STRING2
                        || DIV_WITH_EMPTY_SPACES_PATTERN.test(value)
                      ) {
                        /** When:
                         * 1. user clears everything in the textarea, visually it looks empty, 
                         *    but the value is not empty, it contains the string value of VISUALLY_EMPTY_STRING
                         * 2. after the above step if the user enters empty spaces, 
                         *    then reset the state value to empty string
                         * 
                         * In the above cases reset the value to empty string & show error,
                         * because service description is a required field
                         */
                        handleOnChangeStateForProjDescWithReactQuill("");
                      } else {
                        handleOnChangeStateForProjDescWithReactQuill(formattedValue);
                      }
                    }}
                    onBlur={() => {
                      const VAL = data?.item?.description || "";
                      heapTrackTrigger({
                        windowObj: window,
                        eventName:HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK.DESCRIPTION,
                        value: {
                          project: PROJECT_NAME_FOR_HEAP_TRACK,
                          experienceDescription: VAL,
                        },
                      });
                      if (projDescErrorState() && validationState) {
                        onHandleScroll(`project-or-contact-desc${data?.index}`);
                      }
                    }}
                  />
                </ExperienceCustomReactQuillEditorWrapper>
                {(IS_PROJECT_DESC_FIELD_ERROR) && (
                  <Typography
                    color={"#D91515"}
                    textAlign={"left"}
                    paddingTop={"6px"}
                    paddingBottom={"0px"}
                    fontStyle={"italic"}
                    fontSize={{
                      default: "8px",
                      xxs: "8px",
                      sm: "12px",
                    }}
                  >
                    Please enter minimum 50 characters
                  </Typography>
                )}
              </>
            </Flex>
          </Flex>
        </Flex>

        {/* File Upload Button - Company Brochure - Flex Row #3 */}
        <Flex {...EXPERIENCE_STYLES.ROW} justifyContent={{ default: "center" }}>
          <Flex
            gap={"12px"}
            alignItems={"flex-start"}
            padding={{ default: "0 12px", lg: "0" }}
            width={{ default: "100%", lg: "750px" }}
          >
            <Box>
              <Typography
                fontSize={"12px"}
                fontWeight={600}
                color={"#011759"}
                marginBottom={"10px"}
                style={{ letterSpacing: "0.1em", opacity: "0.7" }}
              >
                Project/ Contract Documents
              </Typography>
              <Typography
                fontWeight={400}
                color={"rgba(1, 23, 89, 0.6)"}
                fontSize={{ default: "12px", sm: "14px" }}
                style={{ textAlign: "justify" }}
              >
                {experienceProfileTexts[profileTextsType]?.projScopeDesc}
              </Typography>

              {/* Mobile View - Upload Button */}
              <Box
                display={{ default: "block", sm: "none" }}
                marginTop={"8px"}
                marginBottom={"8px"}
              >
                <ProdDescBttnWrapper>
                  <UploadBttn
                    $error={projDescErrorState() && validationState}
                    onClick={() => {}}
                  >
                    <UploadIcon
                      src={`/images/${
                        projDescErrorState() &&
                        validationState &&
                        checkForMobileScreen()
                          ? "upload_blue"
                          : "upload_white"
                      }.svg`}
                    />
                    {experienceProfileTexts[profileTextsType]?.uploadBttnText}
                    <InputWrap
                      data-tracking-id={
                        HEAP_DATA_ATTRIBUTES.EXPERIENCE.DOC_UPLOAD
                      }
                      onClick={() => {}}
                      type="file"
                      onInput={getFile}
                      accept="application/pdf,image/jpeg,image/png,.docx"
                      id="companyBrochure"
                      style={{
                        width: "200px",
                        height: "40px",
                        right: 0,
                        top: 0,
                      }}
                    />
                  </UploadBttn>
                </ProdDescBttnWrapper>
              </Box>
              {renderUploadedCards()}
            </Box>
            {/* Desktop View - Upload Button */}
            <Box display={{ default: "none", sm: "block" }}>
              <ProdDescBttnWrapper>
                <UploadBttn
                  $error={projDescErrorState() && validationState}
                  onClick={() => {}}
                >
                  <UploadIcon
                    src={`/images/${
                      projDescErrorState() &&
                      validationState &&
                      checkForMobileScreen()
                        ? "upload_blue"
                        : "upload_white"
                    }.svg`}
                  />
                  {experienceProfileTexts[profileTextsType]?.uploadBttnText}
                  <InputWrap
                    data-tracking-id={
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.DOC_UPLOAD
                    }
                    onClick={() => {}}
                    type="file"
                    onInput={getFile}
                    accept="application/pdf,image/jpeg,image/png,.docx"
                    id="companyBrochure"
                    style={{ width: "200px", height: "40px", right: 0, top: 0 }}
                  />
                </UploadBttn>
              </ProdDescBttnWrapper>
            </Box>
          </Flex>
        </Flex>

        {/* Flex Row #4  - Non Mandatory Fields */}
        <Flex {...EXPERIENCE_STYLES.ROW}>
          {/* DatePicker - PROJECT START DATE */}
          <VenwizUIDatePickerWithLabel
            {...EXPERIENCE_STYLES.INPUT_FIELD}
            id={"projStartDate" + data?.index}
            header="PROJECT START DATE"
            leftIcon={<IoCalendarNumberOutline color={"#7E8FAB"} />}
            inputVariant={"reactdatepicker"}
            inputWithIconProps={{
              // close icon to reset project start date
              rightIcon: (
                <>
                  {PROJ_START_DATE !== "MM-YYYY" && (
                    <Flex
                      alignSelf={"flex-end"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={"max-content"}
                      fontSize={"18px"}
                      color={colors.blue}
                      onClick={() => {
                        setDisableDatesBefore(true);
                        handleOnChange("", "projStartDate");
                        toggleIsPageEdited(dispatch, true);
                      }}
                    >
                      <AiFillCloseCircle />
                    </Flex>
                  )}
                </>
              ),
              inputProps: {
                color: "black",
                width: "100%",
                maxDate: new Date(),
                dateFormat: "MM-YYYY",
                showMonthYearPicker: true,
                showFourColumnMonthYearPicker: true,
                name: experienceProfileTexts[profileTextsType]?.startDate,
                placeholderText: "Enter Start Date",
                value: PROJ_START_DATE || "",
                onChange: (dateString) => {
                  setDisableDatesBefore(dateString ? false : true);
                  handleOnChange(dateString, "projStartDate");
                  heapTrackTrigger({
                    windowObj: window,
                    eventName:
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                        .PROJECT_START_DATE,
                    value: {
                      project: PROJECT_NAME_FOR_HEAP_TRACK,
                      startDate: dateString
                        ? moment(dateString).format("YYYY-MM")
                        : "MM-YYYY",
                    },
                  });
                  toggleIsPageEdited(dispatch, true);
                },
              },
            }}
          />

          {/* DatePicker - PROJECT COMPLETION DATE */}
          <VenwizUIDatePickerWithLabel
            {...EXPERIENCE_STYLES.INPUT_FIELD}
            id={"projEndDate" + data?.index}
            header="PROJECT COMPLETION DATE"
            leftIcon={<IoCalendarNumberOutline color={"#7E8FAB"} />}
            inputVariant={"reactdatepicker"}
            inputWithIconProps={{
              // close icon to reset project end date
              rightIcon: (
                <>
                  {PROJ_END_DATE !== "MM-YYYY" && (
                    <Flex
                      alignSelf={"flex-end"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      width={"max-content"}
                      fontSize={"18px"}
                      color={colors.blue}
                      onClick={() => {
                        /** Clear project end date */
                        handleOnChange("", "projEndDate");
                        toggleIsPageEdited(dispatch, true);
                      }}
                    >
                      <AiFillCloseCircle />
                    </Flex>
                  )}
                </>
              ),
              inputProps: {
                color: "black",
                width: "100%",
                minDate: projMinEndDate || new Date(),
                dateFormat: "MM-YYYY",
                showMonthYearPicker: true,
                showFourColumnMonthYearPicker: true,
                disabled: disableDatesBefore, // disable until project start date is selected
                name: experienceProfileTexts[profileTextsType]?.endDate,
                placeholderText: "Enter Completion Date",
                value: PROJ_END_DATE || "",
                onChange: (dateString) => {
                  /** Add Project End Date */
                  handleOnChange(dateString, "projEndDate");
                  toggleIsPageEdited(dispatch, true);
                  heapTrackTrigger({
                    windowObj: window,
                    eventName:
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                        .PROJECT_COMPLETION_DATE,
                    value: {
                      project: PROJECT_NAME_FOR_HEAP_TRACK,
                      endDate: dateString
                        ? moment(dateString).format("YYYY-MM")
                        : "MM-YYYY",
                    },
                  });
                },
              },
            }}
          />

          {/* Dropdown Input Field - PROJECT COST */}
          <InputWithLabel
            {...EXPERIENCE_STYLES.INPUT_FIELD}
            header={"PROJECT COST"}
            inputVariant={"autocomplete"}
            className={"project-cost-dropdown"}
            id={"projectCost" + data?.index}
            fieldDescriptionStyles={{ paddingBottom: "0px" }}
            tooltipProps={{ placement: isLargeDesktop ? "bottom" : "br" }}
            inputWithIconProps={{
              inputProps: {
                styles: {
                  /** Styles for react autocomplete */
                  option: (defaultStyles, props) => ({
                    ...defaultStyles,
                    color: "#011759",
                  }),
                },
                name: experienceProfileTexts[profileTextsType]?.projectCost,
                placeholder: "Enter Project Cost",
                value: (() => {
                  const VAL: string = data?.item?.projectCost;
                  return VAL?.length ? { label: VAL, value: VAL } : null;
                })(),
                options:
                  DropdownData?.ProjectCost?.map((item) => ({
                    ...item,
                    label: item?.value,
                  })) || [],
                onChange: (item: { id: number; value: string }) => {
                  handleOnChange(item?.value, "projectCost");
                  heapTrackTrigger({
                    windowObj: window,
                    eventName:
                      HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK.PROJECT_COST,
                    value: { projectCOst: item?.value || "" },
                  });
                },
              },
            }}
          />
        </Flex>

        {/* Request For Review - Accordion Container - Flex Row #5 */}
        <Box>
          <Accordion
            id={`request-review-accordion-container-${data?.index}`}
            isExpand={showRequestReviewAccordion}
            onExpand={() => {
              setShowRequestReviewAccordion((prev) => !prev);
            }}
            accordionOpenIcon={<></>}
            accordionCloseIcon={<></>}
            containerProps={{
              margin: "0 auto",
              borderRadius: "10px",
              width: {
                default: (EXPERIENCE_STYLES.INPUT_FIELD.width as any)?.default,
                lg: "755px",
              },
              background:
                "linear-gradient(153deg, rgba(71,200,130,0.1026611328125) 0%, rgba(33,94,201,0.1) 50%)",
            }}
            headerProps={{
              backgroundColor: "inherit",
              color: "black",
              width: "100%",
              padding: "14px",
              display: "flex",
              flexDirection: "column",
              cursor: "pointer",
            }}
            accordionTitle={
              <>
                <Flex
                  gap={"12px"}
                  width={"100%"}
                  cursor={"pointer"}
                  alignContent={"center"}
                  justifyContent={"space-between"}
                >
                  <Flex alignItems={"center"} justifyContent={"flex-start"}>
                    <Typography
                      fontWeight={400}
                      fontSize={"12px"}
                      color={"#011759"}
                      cursor={"pointer"}
                      style={{ letterSpacing: "0.1em" }}
                    >
                      REQUEST FOR REVIEW(PROJECT FEEDBACK)
                    </Typography>
                  </Flex>

                  <Flex
                    gap={"12px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    {/* Viiew Review Button - Opens View Review Modal */}
                    {data?.item?.review?.reviewStatus &&
                    data?.item?.review?.reviewStatus ===
                      REQUEST_FOR_REVIEW_FORM_STATUS.REVIEW_ACCEPTED ? (
                      <Button
                        variant={"primary"}
                        colorSchema={"blue"}
                        width={"max-content"}
                        height={"22px"}
                        style={{
                          borderRadius: "4px",
                          padding: "0 12px",
                        }}
                        onClick={() => {
                          setShowViewReviewModal(true);
                          setClickedReview(data?.item?.review || {});
                        }}
                        {...{
                          [HEAP_DATA_TRACKING_ID]:
                            HEAP_DATA_ATTRIBUTES.EXPERIENCE.REQUEST_FOR_REVIEW
                              .VIEW_REVIEW_BUTTON,
                        }}
                      >
                        View Review
                      </Button>
                    ) : data?.item?.review?.reviewStatus ===
                      REQUEST_FOR_REVIEW_FORM_STATUS.REVIEW_REQUESTED ? (
                      <Button
                        disabled={true}
                        variant={"primary"}
                        colorSchema={"blue"}
                        width={"max-content"}
                        height={"22px"}
                        style={{
                          borderRadius: "4px",
                          padding: "0 12px",
                          cursor: "no-drop",
                        }}
                      >
                        Review Requested
                      </Button>
                    ) : (
                      <></>
                    )}

                    {/* Accordion Caret Icon */}
                    <Flex
                      alignItems={"flex-start"}
                      justifyContent={"center"}
                      cursor={"pointer"}
                      fontSize={"30px"}
                    >
                      {showRequestReviewAccordion ? (
                        <RxCaretUp />
                      ) : (
                        <RxCaretDown />
                      )}
                    </Flex>
                  </Flex>
                </Flex>

                {/* Description with Link */}
                <Typography
                  fontWeight={400}
                  paddingTop={"4px"}
                  color={"#4D5D8B"}
                  fontSize={"11px"}
                  cursor={"pointer"}
                >
                  <>
                    Reviews from your past clients add more credibility to your
                    profile. Please fill the details below to request a review
                    for this project. An email will be sent to the client with a
                    review form (
                  </>
                  <Typography
                    variant="span"
                    fontWeight={400}
                    color={"#215EC9"}
                    fontSize={"10px"}
                    cursor={"pointer"}
                    padding={"0px 1px"}
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      setShowDummyAddReviewForm(true);
                    }}
                    {...{
                      [HEAP_DATA_TRACKING_ID]:
                        HEAP_DATA_ATTRIBUTES.EXPERIENCE.REQUEST_FOR_REVIEW
                          .PREVIEW_LINK_FOR_REVIEW_FORM,
                    }}
                  >
                    Preview here
                  </Typography>
                  <>
                    ). Once the client responds, the review gets added to your
                    profile.
                  </>
                </Typography>
              </>
            }
            contentProps={{
              background: "inherit",
              padding: { default: "8px", xs: "12px" },
            }}
          >
            {/* Accordion - Content */}
            <Flex
              gap={"12px"}
              flexDirection={"column"}
              alignItems={"flex-start"}
              justifyContent={"center"}
            >
              {/* Request for Review - Flex Row#1 */}
              <Flex
                gap={"12px"}
                width={{ default: "100%", xxs: "90vw", lg: "800px" }}
                flexDirection={{ default: "column", lg: "row" }}
                justifyContent={{ default: "flex-start", lg: "flex-start" }}
                alignItems={{ default: "flex-start", lg: "flex-start" }}
              >
                {/* Input Field - CLIENT USER NAME */}
                <InputWithLabel
                  {...EXPERIENCE_STYLES.INPUT_FIELD}
                  width={{ default: "80vw", lg: "235px" }}
                  isMandatory
                  inputVariant={"input"}
                  header="CLIENT USER NAME"
                  id={`request-review-client-user-name-${data?.index}`}
                  errorMessage={"Required Field"}
                  fieldDescriptionStyles={{ paddingBottom: "0px" }}
                  inputWithIconProps={{
                    inputProps: {
                      disabled: areRequestForReviewInputsDisabled,
                      name: "clientUserName",
                      placeholder: "Enter client user name",
                      value: data?.item?.review?.clientDetails?.name || "",
                      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const VALUE = e?.currentTarget?.value
                          ? e?.currentTarget?.value?.trimStart()
                          : "";
                        handleOnChange(VALUE, "requestReviewClientUserName");
                      },
                      onBlur: (e: any) => {
                        heapTrackTrigger({
                          windowObj: window,
                          eventName:
                            HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                              .REQUEST_FOR_REVIEW.CLIENT_USER_NAME,
                          value: {
                            project: PROJECT_NAME_FOR_HEAP_TRACK,
                            clientUserName: e?.target?.value || "",
                          },
                        });
                      },
                    },
                  }}
                  hasError={
                    validationState &&
                    requestForReviewFormErrors?.clientUserName &&
                    onHandleScroll(
                      `request-review-client-user-name-${data?.index}`
                    )
                  }
                />

                {/* Input Field - DESIGNATION */}
                <InputWithLabel
                  {...EXPERIENCE_STYLES.INPUT_FIELD}
                  isMandatory
                  width={{ default: "80vw", lg: "235px" }}
                  inputVariant={"input"}
                  header="DESIGNATION"
                  id={`request-review-designation-${data?.index}`}
                  errorMessage={"Required Field"}
                  fieldDescriptionStyles={{ paddingBottom: "0px" }}
                  inputWithIconProps={{
                    inputProps: {
                      disabled: areRequestForReviewInputsDisabled,
                      name: "designation",
                      placeholder: "Enter Designation",
                      value:
                        data?.item?.review?.clientDetails?.designation || "",
                      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const VALUE = e?.currentTarget?.value
                          ? e?.currentTarget?.value?.trimStart()
                          : "";
                        handleOnChange(VALUE, "requestReviewDesignation");
                      },
                      onBlur: (e: any) => {
                        heapTrackTrigger({
                          windowObj: window,
                          eventName:
                            HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                              .REQUEST_FOR_REVIEW.CLIENT_DESIGNATION,
                          value: {
                            project: PROJECT_NAME_FOR_HEAP_TRACK,
                            designation: e?.target?.value || "",
                          },
                        });
                      },
                    },
                  }}
                  hasError={
                    validationState &&
                    requestForReviewFormErrors?.designation &&
                    onHandleScroll(`request-review-designation-${data?.index}`)
                  }
                />

                {/* Input Field - EMAIL ADDRESS */}
                <InputWithLabel
                  isMandatory
                  {...EXPERIENCE_STYLES.INPUT_FIELD}
                  width={{ default: "80vw", lg: "235px" }}
                  inputVariant={"input"}
                  header="EMAIL ADDRESS"
                  // errorMessage={"Email is not present or invalid"}
                  errorMessage={"Valid Client Email is mandatory"}
                  id={`request-review-email-${data?.index}`}
                  fieldDescriptionStyles={{ paddingBottom: "0px" }}
                  inputWithIconProps={{
                    inputProps: {
                      disabled: areRequestForReviewInputsDisabled,
                      name: "email",
                      placeholder: "Enter Email Address",
                      value: data?.item?.review?.clientDetails?.email || "",
                      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const VALUE = e?.currentTarget?.value
                          ? e?.currentTarget?.value?.trimStart()
                          : "";
                        handleOnChange(VALUE, "requestReviewEmail");
                      },
                      onBlur: (e: any) => {
                        heapTrackTrigger({
                          windowObj: window,
                          eventName:
                            HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                              .REQUEST_FOR_REVIEW.CLIENT_EMAIL,
                          value: {
                            project: PROJECT_NAME_FOR_HEAP_TRACK,
                            designation: e?.target?.value || "",
                          },
                        });
                      },
                    },
                  }}
                  hasError={
                    validationState &&
                    requestForReviewFormErrors.email &&
                    onHandleScroll(`request-review-email-${data?.index}`)
                  }
                />
              </Flex>

              {/* Request for Review - Flex Row#2 - Note */}
              <Flex
                width={{ default: "100%", xxs: "90vw", lg: "100%" }}
                alignItems={"flex-start"}
              >
                <InputWithLabel
                  {...EXPERIENCE_STYLES.INPUT_FIELD}
                  width={{ default: "80vw", lg: "730px" }}
                  inputVariant={"input"}
                  header="ADD A NOTE"
                  id={`request-review-note-${data?.index}`}
                  inputWithIconProps={{
                    inputProps: {
                      disabled: areRequestForReviewInputsDisabled,
                      name: "note",
                      placeholder:
                        "Enter A Note text that you want to share with your client",
                      value: data?.item?.review?.note || "",
                      onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        const VALUE = e?.currentTarget?.value
                          ? e?.currentTarget?.value?.trimStart()
                          : "";
                        handleOnChange(VALUE, "requestReviewNote");
                      },
                      onBlur: (e: any) => {
                        heapTrackTrigger({
                          windowObj: window,
                          eventName:
                            HEAP_DATA_ATTRIBUTES.EXPERIENCE.HEAP_TRACK
                              .REQUEST_FOR_REVIEW.NOTE,
                          value: {
                            project: PROJECT_NAME_FOR_HEAP_TRACK,
                            designation: e?.target?.value || "",
                          },
                        });
                      },
                    },
                  }}
                />
              </Flex>
            </Flex>
          </Accordion>
        </Box>
      </Flex>

      {/* Modal - DummyAddReviewForm */}
      {showDummyAddReviewForm && (
        <DummyAddReviewForm
          handleCloseModal={() => setShowDummyAddReviewForm(false)}
        />
      )}

      {/* Modal - View Review */}
      {showViewReviewModal && (
        <ViewReviewModal
          clickedReview={clickedReview}
          showReviewModal={showViewReviewModal}
          handleClose={() => {
            setShowViewReviewModal(false);
            setClickedReview(CLICKED_REVIEW_INITIAL_STATE);
          }}
        />
      )}

      {/* Sticky bottom right - Help Button */}
      <HelpButtonWithPopOver
        specialConditionToShowTextPopOver={
          experienceInfoSelector?.loading === false 
          && experienceInfoSelector?.loaded === true
        }
        heapDataTrackingId={{
          HELP_BUTTON: HEAP_DATA_ATTRIBUTES.EXPERIENCE.EXPLAINER_VIDEO.HELP_BUTTON,
          CONTINUE_BUTTON: HEAP_DATA_ATTRIBUTES.EXPERIENCE.EXPLAINER_VIDEO.CONTINUE_BUTTON,
          CLOSE_MODAL_BUTTON: HEAP_DATA_ATTRIBUTES.EXPERIENCE.EXPLAINER_VIDEO.CLOSE_VIDEO_MODAL_BUTTON,
        }}
        contentProps={{
          introductoryTitle: "Introduction to new feature",
          featureTitle: "“Request for Review(Project Feedback)”",
          sectionTitle: "Experience Section",
        }}
        VIDEO_PROPS={{
          SRC: "https://d2kbahwkgd7n6c.cloudfront.net/1687863285087-bc1deb39-2950-4286-88e4-e87426c74898.mp4",
          TITLE: "request-for-review-demo-video",
        }}
        handlePositiveAction={({ callbackToClosePopOver }) => {
          if (accessedHelpFromLocalStorage) {
            callbackToClosePopOver();
          } else if (USER_EMAIL) {
            dispatch(updateUserKnowsAboutHelpButton({ 
              email: USER_EMAIL
            })).then((resp) => {
              if (resp?.status === "SUCCESS") {
                const existingUserData = LocalStorageService.get(AppConfig.STORAGE_KEYS.userData);
                existingUserData.accessedHelp = true;
                LocalStorageService.set(AppConfig.STORAGE_KEYS.userData, existingUserData)
                setAccessedHelpFromLocalStorage(true);
                callbackToClosePopOver();
              }
            });
          }
        }}
      />
    </>
  );
};

export default ExperienceConatainerNew;
