import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { AuthContainer, Spinner } from 'Authentication';
import { Spin } from "antd";
import { Container } from 'Authentication/AuthenticationModal/AuthenticationModel.styles';
import { verifyVendorsEscrowInfoToken } from 'reducers/vendors-escrow-info';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'reducers';
import notificationFunc from 'components/Notification';
import { Box, Flex, Typography, Card } from 'venwiz-ui';
import { colors } from 'Theme';
import Header from 'components/Layout/BasicLayOut/Header';
import { IFile } from "NewOnboarding/newOnboarding.interfaces";
import { VENDOR_ESCRO_DOCUMENTS } from './openVendorEscrowInfoPage.constants';


export const OpenVendorsEscrowInfoPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(window.location.search);
  const TOKEN = urlParams?.get("token")?.trim() || "";
  const { loading, loaded } = useSelector((state: RootState) => state.vendorsEscrowInfo);
  /** Oreder is Important */
  const [escrowRequirementsDocsList, setEscrowRequirementsDocsList] = React.useState<string[]>([
    // "GST Certificate",
    // "Cert. of Incorporation",
    // "Company PAN",
    VENDOR_ESCRO_DOCUMENTS.AUTHORIZED_PERSON_AADHAR_AND_PAN,
    VENDOR_ESCRO_DOCUMENTS.BANK_ACCOUNT_NUMBER_AND_IFSC_CODE,
    VENDOR_ESCRO_DOCUMENTS.ACCOUNT_HOLDER_NAME,
  ]);
  const [filteredEscrowRequirementsDocsList, setFilteredEscrowRequirementsDocsList] = React.useState<string[]>(escrowRequirementsDocsList);

  useEffect(() => {
    if (TOKEN) {
      (dispatch(verifyVendorsEscrowInfoToken({ token: TOKEN })) as any)
        .then(res => {
          notificationFunc("success", "Successfully Accepted Vendor-Agreement");
          const IS_GSTN_EXISTING = res?.existingComplianceDocs?.gst?.[0]?.document?.file?.fileUrl;
          const IS_PAN_EXISTING = res?.existingComplianceDocs?.pan?.[0]?.document?.file?.fileUrl;

          setEscrowRequirementsDocsList(prev => {
            let requiredDocs = [];
            if (IS_GSTN_EXISTING && IS_PAN_EXISTING) {
              requiredDocs = [
                VENDOR_ESCRO_DOCUMENTS.CERTIFICATE_OF_INCORPORATION
              ];
            } else if (IS_GSTN_EXISTING && !IS_PAN_EXISTING) {
              requiredDocs = [
                VENDOR_ESCRO_DOCUMENTS.CERTIFICATE_OF_INCORPORATION,
                VENDOR_ESCRO_DOCUMENTS.COMPANY_PAN
              ];
            } else if (!IS_GSTN_EXISTING && IS_PAN_EXISTING) {
              requiredDocs = [
                VENDOR_ESCRO_DOCUMENTS.GSTN_CERTIFICATE,
                VENDOR_ESCRO_DOCUMENTS.CERTIFICATE_OF_INCORPORATION,
              ];
            } else {
              requiredDocs = [
                VENDOR_ESCRO_DOCUMENTS.GSTN_CERTIFICATE,
                VENDOR_ESCRO_DOCUMENTS.CERTIFICATE_OF_INCORPORATION,
                VENDOR_ESCRO_DOCUMENTS.COMPANY_PAN
              ];
            }
            return requiredDocs.concat(prev);              
          });
        })
        .catch(err => {
          notificationFunc("error", "Unable to accept Vendor Agreement");
          history.push("/login");
        });
    } else {
      notificationFunc("error", "Unable to accept Vendor Agreement");
      history.push("/login");
    }
  }, [TOKEN])

  /** render latest list of documents in the UI */
  useEffect(() => {
    setFilteredEscrowRequirementsDocsList(escrowRequirementsDocsList);
  }, [escrowRequirementsDocsList]);
  
  if (loading) {
    /** Show spinner when the verification is in progress */
    return (
      <AuthContainer>
          <Spinner>
            <Spin
              style={{ position: "absolute", top: "50vh" ,left:"45%"}}
              size="large"
              tip="Loading..."
            />
          </Spinner>
        <Container>
        </Container>
      </AuthContainer>
    )
  } else if (loaded && TOKEN) {
    /** show the following information to the user,
     * after successfully verifying the vendor-agreement acceptance */

    return (
      <Box position={"relative"}>
        <Box 
          position={"absolute"}
          top={"0"}
          left={"0"}
        >
          <Header />
        </Box>
        <Flex
          alignItems={"center"}
          justifyContent={"center"}
          verticalAlign={"middle"}
          height={"100vh"}
          width={"100vw"}
          backgroundColor={"#dae6f9"}
        >
          <Card
            width={"max-content"}
            height={"max-content"}
            color={`${colors.darkBlue} !important`}
            padding={"2.5rem"}
            borderRadius={"8px"}
            marginBottom={"8px"}
            marginRight={"8px"}
          >
            <Box height={"100%"}>
              <Typography
                variant={"h4"}
                color={`${colors.darkBlue} !important`}
                marginBotom={"1.5rem"}
              >
                Thank you for accepting the escrow agreement. We have sent you a mail with the agreement attached.
              </Typography>
              <Typography
                variant={"p"}
                marginBotom={"1rem"}
              >
                Please reply to the mail with the following required information:
              </Typography>
              <Box
                as={"ol"}
                style={{ paddingInlineStart: "2.75rem" }}
              >
                {filteredEscrowRequirementsDocsList?.length > 0 ? (
                  <>
                    {filteredEscrowRequirementsDocsList?.map((docString, index) => {
                      return (
                        <Box
                          as={"li"}
                          key={docString}
                          marginBottom={"0.25rem"}
                        >
                          <Typography variant={"p"}>
                            {docString}
                          </Typography>
                        </Box>
                      )
                    })}
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Card>
        </Flex>
      </Box>
    )
  } else {
    /** 
     * If the token is not present in the url,
     * then show the following information to the user
     * and redirect to login page */
    return (
      <></>
    )
  }

}
