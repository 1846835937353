import { Colors } from 'Theme'

export const labelTheme = (colors: Colors) => ({
  sizes: {
    hero: {
      color: colors.blue
    },
    h1: {
      color: colors.blue
    },
    h2: {
      color: colors.darkBlue
    },
    h3: {
      color: colors.darkBlue
    },
    body: {
      color: colors.blue
    },
    body2: {
      color: colors.darkBlue
    },
    normal: {
      color: colors.darkBlue
    },
    avatar: {
      color: colors.blue
    },
    cardHeader: {
      fontSize: '10px',
      lineHieght: '15px',
      width: 'auto',
      height: 'auto',
      padding: '5px 12px 4px 12px',
      bgColor: '#112B7A',
      fontWeight: '600',
      borderRadius: '12px',
      color: colors.primary
    },
    profileHeader: {
      fontSize: '12px',
      fontWeight: '600',
      color: colors.primary,
    },
    scopeOfWork:{
      color:"#011759",
      fontSize: '12px',
      fontWeight: '600',
     
    },
    profileCreationCard: {
      fontSize: '14px',
      fontWeight: '600',
      color: colors.primary,
      marginRight: '18px'
    },
    brochureSize: {
      fontSize: '12px',
      fontWeight: '600',
      color:'#215EC9',
      lineHeight: '18px'
    },
    profileName:{
      color: colors.primary
    },
    referalCardHeader:{
      color: colors.blue
    },
    profileCreation:{
      color:'#011759',
    },
  }
})