import React, { useState } from "react";
import { COMPLIANCE_VALUES } from "../constant";
import SingleComplianceContent from "./SingleComplianceContent";
import { AddNew, BaseText, ContentContainer, HeaderContainer, RectBox, Required, GSTUploadInfo, ViewAll, Note } from "../Compliance.styles";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";

export interface ComplianceContentProps {
  data: any;
  field: string;
  gstDocumentUploaded: boolean;
  gstDocumentVerified: string;
  verification: any;
  handleComplianceValueChange: any;
  handleUploadDocument: any;
  handleAddNew: any;
  handleDeleteDocument: any;
  handleVerify: any;
  currentUniqueIndex: number;
  gstError?:boolean;
}

const ComplianceContent = (props: ComplianceContentProps) => {
  const [viewAll, setViewAll] = useState(false);
  const [note, setNote] = useState("")
  const handleViewAll = (e) => {
    setViewAll(!viewAll);
  };

  const gstInProgress = props.gstDocumentUploaded && !["verified", "notVerified"].includes(props.gstDocumentVerified);
  return (
    <>
      <ContentContainer>
        <HeaderContainer>
          <BaseText>
            {props.field === "gst" && <Required>*</Required>}
            {COMPLIANCE_VALUES[props.field].title}
          </BaseText>
          {!["gst", "pan", "cin"].includes(props.field) && (
            <ViewAll onClick={(e) => handleViewAll(e)}>{"View " + (viewAll ? "Less" : "All" + "(" + props.data.length + ")")}</ViewAll>
          )}
          {["lin", "epf", "tan", "esic", "urn"].includes(props.field) && (
            <AddNew
              onClick={() => {
                setViewAll(true);
                props.handleAddNew(props.field);
              }}
            >
              + Add New
            </AddNew>
          )}
        </HeaderContainer>
        <RectBox $borderColor={props.gstError && props.field==="gst"?"1px solid #BE000B":"1px solid #d4e5fe"}>
          {props.data.map((entry, index) => {
            const currentVerification = Array.isArray(props.verification)
              ? props.verification.find((ele) => ele.value === entry.value)
              : props.verification;
            const focusField = (entry.uniqueIndex === props.currentUniqueIndex);
            if (index > 2 && !viewAll) {
              return <></>;
            } else {
              return (
                <SingleComplianceContent
                  key={index}
                  handleComplianceValueChange={props.handleComplianceValueChange}
                  field={props.field}
                  data={entry}
                  index={index}
                  verification={currentVerification}
                  gstInProgress={gstInProgress}
                  handleUploadDocument={props.handleUploadDocument}
                  handleDeleteDocument={props.handleDeleteDocument}
                  getNote={(text)=>{setNote(text)}}
                  handleVerify={props.handleVerify}
                  focusField={focusField}
                />
              );
            }
          })}
        </RectBox>
        {(props.gstError && props.field==="gst") ? <ErrorMessageComponent name={"Upload GST Certificate"} />:<Note>{note}</Note>}
        
        {!props.gstDocumentUploaded && props.field === "pan" && (
          <GSTUploadInfo>Upload GST to view / update other applicable fields - LIN, CIN, URN, EPF, TAN</GSTUploadInfo>
        )}
      </ContentContainer>
    </>
  );
};

export default ComplianceContent;
