import api from "../ui-constants/api";
export const GET_SELECTED_CHAT_ID = "chat/GET_SELECTED_CHAT_ID";
export const CHAT_LIST_REQUESTED = "chat/CHAT_LIST_REQUESTED";
export const CHAT_LIST_SUCCESS = "chat/CHAT_LIST_SUCCESS";
export const CHAT_LIST_FAILURE= "chat/CHAT_LIST_FAILURE";
export const CHAT_MESSAGES_REQUESTED = "chat/CHAT_MESSAGES_REQUESTED";
export const CHAT_MESSAGES_SUCCESS = "chat/CHAT_MESSAGES_SUCCESS";
export const CHAT_MESSAGES_FAILURE = "chat/CHAT_MESSAGES_FAILURE";
export const CHAT_OLD_MESSAGES_REQUESTED ="chat/CHAT_OLD_MESSAGE_REQUESTED";
export const CHAT_OLD_MESSAGES_SUCCESS = "chat/CHAT_OLD_MESSAGE_SUCCESS";
export const CHAT_OLD_MESSAGES_FAILURE = "chat/CHAT_OLD_MESSAGE_FAILURE";
export const CHAT_SEND_MESSAGE_REQUESTED ="chat/CHAT_SEND_MESSAGE_REQUESTED";
export const CHAT_SEND_MESSAGE_SUCCESS = "chat/CHAT_SEND_MESSAGE_SUCCESS";
export const CHAT_SEND_MESSAGE_FAILURE = "chat/CHAT_SEND_MESSAGE_FAILURE"; 

export const DELETE_ALL_CHAT_REQUESTED = "chat/DELETE_ALL_CHAT_REQUESTED";
export const DELETE_ALL_CHAT_SUCCESS = "chat/DELETE_ALL_CHAT_SUCCESS";
export const DELETE_ALL_CHAT_FAILURE = "chat/DELETE_ALL_CHAT_FAILURE";

export const DELETE_CHAT_REQUESTED = "chat/DELETE_CHAT_REQUESTED";
export const DELETE_CHAT_SUCCESS = "chat/DELETE_CHAT_SUCCESS";
export const DELETE_CHAT_FAILURE = "chat/DELETE_CHAT_FAILURE";

export const RESET_HAS_MORE_MESSAGES= "chat/RESET_HAS_MORE_MESSAGES";
export const SET_IS_SCROLLING_ACTIVE= "chat/SET_IS_SCROLLING_ACTIVE";
// export const DISABLE_EDIT = "profilecreation/DISABLE_EDIT"

export const CHAT_FILES_UPLOAD_REQUEST= "chat/CHAT_FILES_UPLOAD_REQUEST";
export const CHAT_FILES_UPLOAD_SUCCESS= "chat/CHAT_FILES_UPLOAD_SUCCESS";
export const CHAT_FILES_UPLOAD_FAILURE= "chat/CHAT_FILES_UPLOAD_FAILURE";

 export const GET_CHAT_SHARED_FILES_REQUESTED = "chat/GET_CHAT_SHARED_FILES_REQUESTED";
 export const GET_CHAT_SHARED_FILES_SUCCESS = "chat/GET_CHAT_SHARED_FILES_SUCCESS";
 export const GET_CHAT_SHARED_FILES_FAILURE = "chat/GET_CHAT_SHARED_FILES_FAILURE";


const initialState = {
  data: null,
  loading: false,
  loaded: false,
  error: false,
  errorData: null,
  chatList:null,
  chatDetails:null,
  newMessage: {},
  messages: [],
  sentMessageStatus: false,
  totalUnreadMessage: 0,
  deletedChat: false,
  deltedChatMessage: null,
  hasMoreMessages: false,
  isScrollingActive: false,
  fileUploadData: null,
  sharedFiles: null,
  selectedCommunicationType: null

};

const chatInfo = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_LIST_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CHAT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        chatListData: action.result,
        totalUnreadMessage: action.result.totalUnreadMessages
      };

    case CHAT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case CHAT_MESSAGES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CHAT_MESSAGES_SUCCESS:
      if(state.isScrollingActive) {
          return {
            ...state,
            loading: false,
            loaded: true,
            error: false,
            messages: action.result.messages,
            hasMoreMessages:true,
            selectedCommunicationType: action.result.communicationType
          }
      } else {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        messages: action.result.messages,
        hasMoreMessages: action.result.messages.length > 4 ? true : false,
        selectedCommunicationType: action.result.communicationType
      };
    }

    case CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
 
    case CHAT_OLD_MESSAGES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CHAT_OLD_MESSAGES_SUCCESS:
      if(action.result.messages.length){
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          chatDetails: action.result.message,
          messages:[...action.result.messages,...state.messages],
          hasMoreMessages: true,
        };
      } else {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          hasMoreMessages: false,
        };
      }
      

    case CHAT_OLD_MESSAGES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
      
    case CHAT_SEND_MESSAGE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CHAT_SEND_MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        sentMessageStatus: action.result
      };

    case CHAT_SEND_MESSAGE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case GET_SELECTED_CHAT_ID:
      return {
        ...state,
        data: action.payload,
      };

    // case DELETE_ALL_CHAT_REQUESTED:
    //   return {
    //     ...state,
    //     loading: true,
    //   };

    // case DELETE_ALL_CHAT_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     deletedChat: true,
    //     deltedChatMessage: action.result
    //   };

    // case DELETE_ALL_CHAT_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     deletedChat: false,
    //     errorData: action.error.message,
    //   };
    case DELETE_CHAT_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case DELETE_CHAT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        deletedChat: true,
        messages: action.result.messages
      };

    case DELETE_CHAT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        deletedChat: false,
        errorData: action.error.message,
      };

    case RESET_HAS_MORE_MESSAGES:
      return {
        ...state,
        hasMoreMessages: false
      };
    case SET_IS_SCROLLING_ACTIVE:
      return {
        ...state,
        isScrollingActive: action.payload
      };
      case CHAT_FILES_UPLOAD_REQUEST:
        return {
          ...state
        };
      case CHAT_FILES_UPLOAD_SUCCESS: 
        return {
          ...state,
          fileUploadData:action.result 
        }
      case CHAT_FILES_UPLOAD_FAILURE: 
        return {
          ...state,
          error: action.error
        }
        case GET_CHAT_SHARED_FILES_REQUESTED:
          return {
            ...state,
          };
    
        case GET_CHAT_SHARED_FILES_SUCCESS:
          return {
            ...state,
            error: false,
            sharedFiles: action.result
          };
    
        case GET_CHAT_SHARED_FILES_FAILURE:
          return {
            ...state,
            error: true,
            errorData: action.error.message,
          };
      
  
    default:
      return state;
  }
};

export default chatInfo;

export const getSelectedChat = (dispatch, data) => {
  dispatch({
    type: GET_SELECTED_CHAT_ID,
    payload: data,
  });
};
export const getChatList = (params) => ({
  types: [CHAT_LIST_REQUESTED,
    CHAT_LIST_SUCCESS,
    CHAT_LIST_FAILURE],
  promise: (client) => client.get('chatlist_get_data', {
    params,
  },
  api.hosttype.communication
  )
});

export const getChatMessages = (params) => ({
  types: [CHAT_MESSAGES_REQUESTED,
          CHAT_MESSAGES_SUCCESS, 
          CHAT_MESSAGES_FAILURE],
  promise: (client) => client.get(`chat_get_messages`,{
    params
  },api.hosttype.communication)
});

export const deleteChat = (data) => ({
  types: [DELETE_CHAT_REQUESTED, DELETE_CHAT_SUCCESS, DELETE_CHAT_FAILURE],
  promise: (client) => client.put('delete_chat', {
    data,
  },
  api.hosttype.communication
  )
});

export const getOldChatMessages = (params) => ({
  types: [CHAT_OLD_MESSAGES_REQUESTED, 
          CHAT_OLD_MESSAGES_SUCCESS,
          CHAT_OLD_MESSAGES_FAILURE],
  promise: (client) => client.get(`chat_get_old_messages`,{
    params
  },
  api.hosttype.communication)
}
);

export const sendMessage = (data) => ({
  types: [CHAT_SEND_MESSAGE_REQUESTED,
          CHAT_SEND_MESSAGE_SUCCESS,
          CHAT_OLD_MESSAGES_FAILURE],
  promise:(client) => client.post('send_message',
      {
        data
      },
      api.hosttype.communication)
});

export const resetHasMoreMessages= (data) => ({
    type: RESET_HAS_MORE_MESSAGES,
    payload: data,
  });

export const setIsScrollingActive =  (data) =>({
    type: SET_IS_SCROLLING_ACTIVE,
    payload: data
});

export const uploadFile = (data) => ({
  types:
  [
    CHAT_FILES_UPLOAD_REQUEST,
    CHAT_FILES_UPLOAD_SUCCESS,
    CHAT_FILES_UPLOAD_FAILURE
  ],
  promise: (client) =>
    client.post(
      "upload_file",
      {
        files: [{ key: "file", value: data }],
      },
      "communication"
    ),
});

export const getSharedFiles =  (params) => ({
  types: [
    GET_CHAT_SHARED_FILES_REQUESTED,
    GET_CHAT_SHARED_FILES_SUCCESS,
    GET_CHAT_SHARED_FILES_FAILURE,
  ],
  promise: (client) =>
    client.get(
      `get_shared_files`,
      {
        params,
      },
      "communication"
    )
});




