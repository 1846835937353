import { combineReducers } from "redux";
import auth from "./auth.js";
import gotoVendors from"./goto-vendors";
import vendorsEscrowInfo from "./vendors-escrow-info";
import userInfoApi from "./userInfo.js";
import companyInfo from "./companyInfo.js";
import capabilities from "./capabilities";
import tags from "./tags";
import people from "./peopleInfo";
import experienceInfo from "./experienceInfo";
import referenceInfo from "./referenceInfo";
import profileCreation from "./profileCreation";
import certificationInfo from "./certificationInfo";
import documentInfo from "./documentInfo";
import chatInfo from "./chat";
import scopeOfWorkInfo from "./scopeOfWorkInfo";
import fileUpload from "./fileUpload";
import dashboardPercentage from "./dashboardPercentage";
import locations from "./locations";
import inviteApi from "./invite";
import dashboard from "./dashboard";
import marketingSuite from "./marketingSuite";
import plantVisitRequest from "./PlantVisitRequest";
import shareableLink from "./shareableLink";
import jobRequest from "./jobRequest";
import oemTagsLibrary from "./oemTagsLibrary";
import techCertsLib from "./techCertificatesLibrary";
import compliance from "./compliance";
import finance from "./finance";
import profileCreationStatus  from "./profileCreationStatus";
import userInfoSection from "./userInfoSection";
import {FileOperationsReducer} from "../shared/redux/file-operations";
import { ERfxReducer } from "../Request/ERfxReducer/index";
import { OnBoardingReducer } from "NewOnboarding/NewOnboardingReducers";
import { VendorProfileReducer } from "../Profile/ViewProfile/ViewProfileReducers";

const appReducer = combineReducers({
  auth,
  userInfoApi,
  companyInfo,
  profileCreation,
  capabilities,
  tags,
  referenceInfo,
  experienceInfo,
  certificationInfo,
  people,
  scopeOfWorkInfo,
  documentInfo,
  fileUpload,
  dashboardPercentage,
  locations,
  chatInfo,
  inviteApi,
  dashboard,
  marketingSuite,
  plantVisitRequest,
  jobRequest,
  oemTagsLibrary,
  techCertsLib,
  compliance,
  finance,
  profileCreationStatus,
  userInfoSection,
  FileOperationsReducer,
  ERfxReducer,
  OnBoardingReducer,
  gotoVendors,
  vendorsEscrowInfo,
  VendorProfileReducer
});

const rootReducer = (state, action) => appReducer(state, action);

export type RootState = ReturnType<typeof rootReducer> 

export default rootReducer;
