import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
export const CAPABILITIES_REQUESTED = "capabilities/CAPABILITIES_REQUESTED";
export const CAPABILITIES_SUCCESS = "capabilities/CAPABILITIES_SUCCESS";
export const CAPABILITIES_FAILURE = "capabilities/CAPABILITIES_FAILURE";
export const CAPABILITIES_UPDATE_SUCCESS = "capabilities/CAPABILITIES_UPDATE_SUCCESS";
export const CAPABILITIES_CAPTION_UPDATE_SUCCESS = "capabilities/CAPABILITIES_CAPTION_UPDATE_SUCCESS";
export const CAPABILITIES_CAPTION_UPDATE_REDUX_STATE = "capabilities/CAPABILITIES_CAPTION_UPDATE_REDUX_STATE";

const getGstn =
  SessionStorageService.get("gstNum") || LocalStorageService.get("gstNum");

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userData: null,
  capabilitiesData: undefined,
  capabilitiesUpdatedData: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAPABILITIES_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case CAPABILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        capabilitiesData: action.result,
      };

    case CAPABILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    case CAPABILITIES_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        capabilitiesUpdatedData: action.result,
      };

    case CAPABILITIES_CAPTION_UPDATE_SUCCESS:
      const gallery = action?.result?.gallery?.length > 0 ? [
        // preserving the temp videos present in state
        ...state?.capabilitiesData?.gallery?.filter(item => item?.isNotPresentInDB),
        ...action.result?.gallery,
      ] : state?.capabilitiesData?.gallery || [];

      const companyVideo = action?.result?.companyVideo?.length > 0 ? [
        // preserving the temp videos present in state
        ...state?.capabilitiesData?.companyVideo?.filter(item => item?.isNotPresentInDB),
        ...action.result?.companyVideo,
      ] : state?.capabilitiesData?.companyVideo || [];

      return {
        ...state,
        loaded: true,
        error: false,
        loading: false,
        capabilitiesData: { ...state?.capabilitiesData, gallery, companyVideo },
      };

    case CAPABILITIES_CAPTION_UPDATE_REDUX_STATE:
      let galleryState = state?.capabilitiesData?.gallery;
      let companyVideoState = state?.capabilitiesData?.companyVideo;
      if (action?.payload?.type === "IMAGE") {
        galleryState = action?.payload?.galleryData?.filter(mediaObj => {
          return mediaObj?.imageName
        });
      } else if (action?.payload?.type === "VIDEO") {
        companyVideoState = action?.payload?.galleryData?.filter(mediaObj => {
          return !mediaObj?.imageName
        });
      }

      return {
        ...state,
        capabilitiesData: {
          ...state?.capabilitiesData,
          gallery: galleryState,
          companyVideo: companyVideoState
        },
      };

    default:
      return state;
  }
};
export const capabilitiesGet = data => ({
  types: [CAPABILITIES_REQUESTED, CAPABILITIES_SUCCESS, CAPABILITIES_FAILURE],
  promise: client => client.get(`capabilities_get_data`, {}),
});
export const capabilitiesOnboardingGet = data => ({
  types: [CAPABILITIES_REQUESTED, CAPABILITIES_SUCCESS, CAPABILITIES_FAILURE],
  promise: client => client.get(`capabilities_get_onboarding_data`, {}),
});
export const capabilitiesPost = data => ({
  types: [CAPABILITIES_REQUESTED, CAPABILITIES_SUCCESS, CAPABILITIES_FAILURE],
  promise: client =>
    client.post("capabilities_post_data", {
      data,
    }),
});

export const capabilitiesUpdateCaptionPost = data => ({
  types: [
    CAPABILITIES_REQUESTED,
    CAPABILITIES_CAPTION_UPDATE_SUCCESS,
    CAPABILITIES_FAILURE
  ],
  promise: client => client.post("capabilities_update_caption_data", { data }),
});

/** @param {{ type: "IMAGE" | "VIDEO", galleryData: any[] }} payload */
export const capabilitiesUpdateCaptionState = (payload) => ({
  type: CAPABILITIES_CAPTION_UPDATE_REDUX_STATE,
  payload,
});

export const capabilitiesOnboardingPost = data => ({
  types: [CAPABILITIES_REQUESTED, CAPABILITIES_SUCCESS, CAPABILITIES_FAILURE],
  promise: client =>
    client.post("capabilities_post_onboarding_data", {
      data,
    }),
});

export const capabilitiesUpdate = data => ({
  types: [
    CAPABILITIES_REQUESTED,
    CAPABILITIES_UPDATE_SUCCESS,
    CAPABILITIES_FAILURE,
  ],
  promise: client =>
    client.post("capabilities_update_onboarding_data", {
      data,
    }),
});
