import { boolean } from "yup/lib/locale";
import { Types as ERfxTypes } from "./types";

const initialState = {
  quotations: null,
  jobRequestId: null,
  fileHistoryData: null,
  loading: null,
  finalCommercialDocs: null,
  finalTechDocs: null,
  haNewUpdatesForPublish: false,
  quotationPublished: false,
  newCommenrcialNotes: "",
  newTechnicalNotes: "",
  showMonetizationModal: false,
  showPQRModal: false,
  vendorDataSharing: null,
};

export default (state = initialState, action: any) => {
  switch (action.type) {
    case ERfxTypes.ERFX_OPERATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ERfxTypes.ERFX_OPERATION_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case ERfxTypes.GET_RFQ_QUOTATION_SUCCESS:
    case ERfxTypes.SET_QUOTATION_SUCCESS:
    case ERfxTypes.UPLOAD_NEW_VERSION_SUCCESS:
    case ERfxTypes.PUBLISH_RFQ_QUOTATION_FILE_SUCCESS:
    case ERfxTypes.REMOVE_RFQ_QUOTATION_DOCUMENT_SUCCESS:
    case ERfxTypes.RESET_QUOTATION_VERSION_SUCCESS:
      return {
        ...state,
        quotations: action?.result?.data || action?.result,
        finalCommercialDocs: action?.result?.data?.finalCommercialDocs?.length || action?.result?.finalCommercialDocs?.length || 0,
        finalTechDocs: action?.result?.data?.finalTechDocs?.length || action?.result?.finalTechDocs?.length || 0,
        haNewUpdatesForPublish: action?.result?.data?.haNewUpdatesForPublish || action?.result?.haNewUpdatesForPublish,
        quotationPublished: action?.result?.data?.quotationPublish || action?.result?.quotationPublish,
        loading: false,
      };
    case ERfxTypes.PUBLISH_RFQ_QUOTATION_SUCCESS:
      return {
        ...state,
        quotations: action?.result?.data || action?.result,
        haNewUpdatesForPublish: action?.result?.data?.haNewUpdatesForPublish || action?.result?.haNewUpdatesForPublish,
        quotationPublished: action?.result?.data?.quotationPublish || action?.result?.quotationPublish,
        loading: false,
      }
    case ERfxTypes.SET_REQUEST_JOB_ID_SUCCESS:
      return {
        ...state,
        jobRequestId: action?.payload,
        loading: false,
      };
    case ERfxTypes.GET_FILE_HISTORY_SUCCESS:
      return {
        ...state,
        fileHistoryData: action?.result[0],
        loading: false,
      };
    case ERfxTypes.SET_COMMERCIAL_NOTES:
      return {
        ...state,
        newCommenrcialNotes: action?.payload
      };
    case ERfxTypes.SET_TCEHINACAL_NOTES:
      return {
        ...state,
        newTechnicalNotes: action?.payload
      };
    case ERfxTypes.RESET_QUOTATION_SUCCESS:
      return initialState;
    case ERfxTypes.SET_SHOW_MONETIZATION_MODAL:
      return {
        ...state,
        showMonetizationModal: action.payload
      };
    case ERfxTypes.SET_SHOW_PQR_MODAL:
      return {
        ...state,
        showPQRModal: action.payload
      };
    case ERfxTypes.GET_VENDOR_DATA_SHARING_CONSENT_SUCCESS: 
    return{
      ...state,
      vendorDataSharing: action?.result?.data,
      loading: false,
    }
    case ERfxTypes.SAVE_VENDOR_DATA_SHARING_CONSENT_SUCCESS: 
    return{
      ...state,
      loading: false,
    }
    default:
      return state;
  }
};
