export const EXPERIENCE_REQUESTED = "certificationInfo/EXPERIENCE_REQUESTED";
export const EXPERIENCE_SUCCESS = "certificationInfo/EXPERIENCE_SUCCESS";
export const EXPERIENCE_FAILURE = "certificationInfo/EXPERIENCE_FAILURE";

export const EXPERIENCE_POST_REQUESTED =
  "certificationInfo/EXPERIENCE_POST_REQUESTED";
export const EXPERIENCE_POST_SUCCESS =
  "certificationInfo/EXPERIENCE_POST_SUCCESS";
export const EXPERIENCE_POST_FAILURE =
  "certificationInfo/EXPERIENCE_POST_FAILURE";

export const EXPERIENCE_CREATE_REQUESTED =
  "certificationInfo/EXPERIENCE_CREATE_REQUESTED";
export const EXPERIENCE_CREATE_SUCCESS =
  "certificationInfo/EXPERIENCE_CREATE_SUCCESS";
export const EXPERIENCE_CREATE_FAILURE =
  "certificationInfo/EXPERIENCE_CREATE_FAILURE";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: null,

  certificationInfoGetdata: null,
  certificationInfoPostData: null,
  certificationInfoCreateData: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPERIENCE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case EXPERIENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        certificationInfoGetdata: action.result,
      };

    case EXPERIENCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case EXPERIENCE_POST_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case EXPERIENCE_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        certificationInfoPostData: action.result,
      };
    case EXPERIENCE_POST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    case EXPERIENCE_CREATE_REQUESTED:
      return {
        ...state,
        loading: true,
      };
    case EXPERIENCE_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        certificationInfoCreateData: action.result,
      };
    case EXPERIENCE_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };

    default:
      return state;
  }
};

export const certificationInfoGet = data => ({
  types: [EXPERIENCE_REQUESTED, EXPERIENCE_SUCCESS, EXPERIENCE_FAILURE],
  promise: client => client.get(`certification_info_get_data`, {}),
});

export const certificationInfoPost = data => ({
  types: [
    EXPERIENCE_POST_REQUESTED,
    EXPERIENCE_POST_SUCCESS,
    EXPERIENCE_POST_FAILURE,
  ],
  promise: client =>
    client.post("certification_info_post_data", {
      data,
    }),
});
export const certificationInfoCreate = data => ({
  types: [
    EXPERIENCE_CREATE_REQUESTED,
    EXPERIENCE_CREATE_SUCCESS,
    EXPERIENCE_CREATE_FAILURE,
  ],
  promise: client =>
    client.post("certification_info_create_data", {
      data,
    }),
});
