import SessionStorageService from "services/SessionStorageService";
import LocalStorageService from "services/LocalStorageService";
export const FINANCE_REQUESTED = "finance/FINANCE_REQUESTED";
export const FINANCE_SUCCESS = "finance/FINANCE_SUCCESS";
export const FINANCE_FAILURE = "finance/FINANCE_FAILURE";
export const FINANCE_UPDATE_SUCCESS = "finance/FINANCE_UPDATE_SUCCESS";
const getGstn = SessionStorageService.get("gstNum") || LocalStorageService.get("gstNum");
const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userData: null,
  financeInfo: undefined,

  financeUpdatedData: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FINANCE_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case FINANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        financeInfo: action.result,
      };

    case FINANCE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    case FINANCE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        financeUpdatedData: action.result,
      };
    default:
      return state;
  }
};
export const getFinanceInfo = (data) => ({
  types: [FINANCE_REQUESTED, FINANCE_SUCCESS, FINANCE_FAILURE],
  promise: (client) => client.get("finance_get_data"),
});

export const updateFinanceInfo = (data) => ({
  types: [FINANCE_REQUESTED, FINANCE_SUCCESS, FINANCE_FAILURE],
  promise: (client) => client.put(`update_finance_data/`, { data }),
});

export const createFinanceInfo = (data) => ({
  types: [FINANCE_REQUESTED, FINANCE_SUCCESS, FINANCE_FAILURE],
  promise: (client) => client.post(`create_finance_data/`, { data }),
});
