import React, { useEffect, useState,} from "react";
import styled from "styled-components";
import { Spin } from "antd";
import { Carousel } from "antd";
import AuthenticationModal from "Authentication/AuthenticationModal/AuthenticationModal";
import { mockData } from "constants/mockData";
import { useDispatch, useSelector } from "react-redux";
import Footer, { FooterType } from "components/Footer/FooterComp/footer";
import { useHistory } from "react-router-dom";
import { resetAuthData } from "reducers/auth";
interface AuthProps {
  modalHeight?: string;
  footerContent: any;
  bodyContent: any;
}

export const AuthContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/images/Auth/background.png");
  background-size: cover;
  background-attachment: fixed;
  overflow: auto;
  @media only screen and (max-width: 1023px) {
    min-width: auto;
    overflow: hidden;
    background-color: #011759;
  }
  @media only screen and (max-width: 640px) {
    background-color: #011759;
    background-image: none;
  }
`;

export const Container = styled.div`
  padding: 30px 60px 20px 60px;
  display: flex;
  justify-content: space-between;
  position: relative;
  @media only screen and (max-width: 640px) {
    padding: 0px 60px 20px 00px;
  }
`;

const ContainerBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  padding-top: 30px;
  @media only screen and (max-width: 640px) {
    padding-top: 20px;
    padding-left: 20px;
    flex-direction: row;
    z-index:20000;
    height:40px;
  }
`;
const Logo = styled.div`
  background-image: url("/images/logo.svg");
  width: 62px;
  height: 50px;
  @media only screen and (max-width: 640px) {
    width: 34px;
    height: 28px;
    background-repeat: round;
  }
`;
const Transition = styled.div<any>`
  align-items: center;
  padding-top: 75px;
  display: flex;
  justify-content: center;
  width: 50vw;
  @media only screen and (max-width: 1023px) {
    visibility:hidden;
    width: ${props=>props?.$width};
  }
`;
const CarouselBase = styled(Carousel)`
  & .slick-dots-bottom {
    bottom: -2px;
  }

  & .slick-list {
    text-align: center;
  }

  & .slick-dots {
    & li button {
      width: 9px;
      height: 9px;
      border-radius: 50%;
    }

    & li.slick-active {
      width: 4%;
    }
  }

  height: 370px;
  width: 568px;
  color: #ffff;
  line-height: 66.67px;
`;

const CarouselText = styled.div`
  font-weight: bold;
  font-size: 50px;
  line-height: 133.33%;
  text-align: center;
  color: ${(props) => `${props.theme.colors.white}`};
`;

const ButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 35px;
`;

export const Spinner = styled.div`
  position: absolute;
  text-align: center;
  font-size: 10px;
  backdrop-filter: blur(2px);
  z-index: 1500;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const FooterWrapper=styled.div`
width:auto;
visibility:hidden;
display:flex;
@media only screen and (max-width: 1023px) {
  visibility:visible;
}
`
const FooterText=styled.div<any>`
font-weight: ${(props) => `${props.$weight}`};
font-size: 12px;
line-height: 18px;
text-align: right;
color: #FFFFFF;
margin-left:${(props) => `${props.$marginLeft}`};
cursor:${(props) => `${props?.$cursor}`}
`

const Authentication = (props: AuthProps) => {
  const routeData={register:{
    name:"Register",link:"/registration",text:"Dont have an account?"
  },login:{
    name:"Login",link:"/login",text:"Have an account?"
  }}
  const history=useHistory();
  const dispatch = useDispatch()
  const [showRequestDemo, setShowRequestDemo] = useState(false);
  const authState = useSelector((state: any) => state.auth);
  const { isLoading } = authState;
  const pathname=window.location.pathname==="/login"?"register":"login"
  const handleClick = () => {
    setShowRequestDemo(!showRequestDemo);
  };
  
  const handleRoute=()=>{
    if(routeData[pathname].name.toLowerCase()==="login")
    {
      resetAuthData(dispatch);
    }
    history.push(routeData[pathname].link)
  }
  return (
    <AuthContainer>
              {isLoading &&(
          <Spinner>
            <Spin
              style={{ position: "absolute", top: "50vh" ,left:"45%"}}
              size="large"
              tip="Loading..."
            />
          </Spinner>
        )}
      <Container>
        <ContainerBox>
          <Logo />
          <Transition 
            $width={routeData[pathname].name.toLowerCase()==="login"
                      ? "calc(100vw - 31%)"
                      : "calc(100vw - 37%)"
                    }>
            <CarouselBase autoplay>
              {mockData.carosuel.map((item) => (
                <CarouselText key={item.id}>{item.text}</CarouselText>
              ))}
            </CarouselBase>
          </Transition>
          <FooterWrapper>
            <FooterText 
              $weight={500} 
              $marginLeft={"0px"}>
                {routeData[pathname].text}
            </FooterText>
            <FooterText 
              onClick={handleRoute} 
              $weight={800} 
              $marginLeft={"3px"} 
              $cursor={"pointer"}>
                {routeData[pathname].name}
            </FooterText>
          </FooterWrapper>
        </ContainerBox>
        <AuthenticationModal
          modalHeight={props.modalHeight}
          bodyContent={props.bodyContent}
          footerContent={props.footerContent}
        />
      </Container>
    </AuthContainer>
  );
};

export default Authentication;
