import { GET_CHAT_SHARED_FILES_FAILURE } from "./chat";

export const ENABLE_EDIT = "profileCreation/ENABLE_EDIT";
export const SET_ERROR_STATE = "profileCreation/SET_ERROR_STATE";
export const GET_PROFILE_COMPLETION__STATUS_REQUEST = "profileCreation/GET_PROFILE_COMPLETION__STATUS_REQUEST";
export const GET_PROFILE_COMPLETION__STATUS_SUCCESS = "profileCreation/GET_PROFILE_COMPLETION__STATUS_SUCCESS";
export const GET_PROFILE_COMPLETION__STATUS_FAILURE = "profileCreation/GET_PROFILE_COMPLETION__STATUS_FAILURE";
export const RESET_PROFILE_COMPLETION__STATUS_ = "profileCreation/RESET_PROFILE_COMPLETION__STATUS_";
export const TOGGLE_PAGE_EDITED = "profileCreation/TOGGLE_PAGE_EDITED";

const initialState = {
  editButton: true,
  layoutError: false,
  profileCompletionStatus: {},
  error: {},
  isPageEdited: false,
};

const profileCreation = (state = initialState, action) => {
  switch (action.type) {
    case ENABLE_EDIT:
      return {
        ...state,
        editButton: action.payload,
      };

    case SET_ERROR_STATE:
      return {
        ...state,
        layoutError: action.payload,
      };
    case GET_PROFILE_COMPLETION__STATUS_REQUEST: 
      return{
        ...state,
        profileCompletionStatus:{},
        };
    case GET_PROFILE_COMPLETION__STATUS_SUCCESS: 
      return{
        ...state,
          profileCompletionStatus: action.result
        };
    case GET_PROFILE_COMPLETION__STATUS_FAILURE: 
      return{
        ...state,
        profileCompletionStatus : action.error.message,
          }
    case RESET_PROFILE_COMPLETION__STATUS_: 
        return{
            ...state,
            profileCompletionStatus: {},
            };
    case TOGGLE_PAGE_EDITED : 
      return{
        ...state,
        isPageEdited: action.payload
      }
    default:
      return state;
  }
};
export default profileCreation;

export const showEditReducer = (dispatch, bool) => {
  dispatch({
    type: ENABLE_EDIT ,
    payload: bool,
  });
};

export const profileLayoutError = (dispatch, bool) => {
  dispatch({
    type: SET_ERROR_STATE,
    payload: bool,
  });
};

export const profileCompletionStatus = (data) => {
  return {
    types: [
      GET_PROFILE_COMPLETION__STATUS_REQUEST,
      GET_PROFILE_COMPLETION__STATUS_SUCCESS,
      GET_CHAT_SHARED_FILES_FAILURE,
    ],
    promise: (client) =>
      client.get("get_profile_completion_status", {
        data,
      }),
  };
};

export const resetProfileCompletionStatus = (dispatch) => {
  dispatch({
    type: RESET_PROFILE_COMPLETION__STATUS_,
  });
};

export const toggleIsPageEdited = (dispatch, bool) => {
  dispatch({
    type: TOGGLE_PAGE_EDITED,
    payload: bool,
  });
};
