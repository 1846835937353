export const USERINFO_SECTION_REQUESTED = "userInfoSectionApi/USERINFO_SECTION_REQUESTED";

export const USERINFO_SECTION_SUCCESS = "userInfoSectionApi/USERINFO_SECTION_SUCCESS";
export const USERINFO_SECTION_UPDATE_SUCCESS = "userInfoSectionApi/USERINFO_SECTION_UPDATE_SUCCESS";

export const USERINFO_SECTION_FAILURE = "userInfoSectionApi/USERINFO_SECTION_FAILURE";


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,

  userInfoSectionData: null,
  userSectionUpdateResponse: null,

};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERINFO_SECTION_REQUESTED:
      return {
        ...state,
        loading: true
      };

    case USERINFO_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        userInfoSectionData: action.result,
      };

    case USERINFO_SECTION_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        userSectionUpdateResponse: action.result,
      };

    case USERINFO_SECTION_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        errorData: action.error.message,
      };
    default:
      return state;
  }
};

export const userInfoSectionGet = () => ({
  types: [USERINFO_SECTION_REQUESTED, USERINFO_SECTION_SUCCESS, USERINFO_SECTION_FAILURE],
  promise: (client) => client.get("userinfo_section_get_data", {}),
});

export const userInfoSectionUpdate = (data) => {
  return {
    types: [USERINFO_SECTION_REQUESTED, USERINFO_SECTION_UPDATE_SUCCESS, USERINFO_SECTION_FAILURE],
    promise: (client) =>
      client.post("userinfo_section_update_data", {
        data,
      }),
  };
};
