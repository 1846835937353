import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "plyr-react/dist/plyr.css";
import { Input, Button } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ShareableLinkContent from "./ShareableLinkModal/ShareableLinkContent";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 40px 0px 40px;
`;
const ProfileLink = () => {
  const history = useHistory();
  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData } = userInfoState;

  return (
    <Container>
      <ShareableLinkContent />
    </Container>
  );
};

export default ProfileLink;
