import React, { useEffect } from "react";
import styled from "styled-components";
import { Tag } from "antd";

import CardSpinner from "components/Spinner/CardSpinner";
import Label from "components/Label/Label";
interface CardTagProps {
  cardText?: string;
  usedFor?:
    | "selectItem"
    | "selectItemWithLogo"
    | "fileUploadTag"
    | "fileUploadReferenceTag"
    | "dropDownCard";
  onClose?: (e: any) => void;
  closeIcon?: any;
  fileSize?: number;
  closable?: boolean;
  loading?: boolean;
  cursorType?: "pointer" | "default";
  keys?: number;

  data?: any;
}

const TagAntd = styled(Tag)<CardTagProps>`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: ${props => `${props.theme.card.types.cardTags.selectItem.padding}`};
  border-radius: ${props =>
    `${props.theme.card.types.cardTags.selectItem.borderRadius}`};
  width: 80%;
  margin: ${props =>
    `${props.theme.card.types.cardTags.selectItem.margin || "0px"}`};
  border: none;
  cursor: ${props => `${props.cursorType || "default"}`};
  justify-content: space-between;
  & :hover {
    opacity: unset;
  }
  @media screen and (max-width: 1270px) {
    width: 45%;
    padding: 20px 0px 19px 0px;
  }
  @media screen and (max-width: 1220px) {
    width: 30%;
    padding: 20px 0px 19px 0px;
  }
  @media screen and (max-width: 1175px) {
    padding: ${props =>
      `${props.theme.card.types.cardTags.selectItem.padding}`};

    width: 80%;
  }
`;

const LinkTo = styled.a`
  cursor: pointer;
  width: 188px;
  min-width: 188px;
  @media screen and (max-width: 1270px) {
    width: 70%;
    min-width: 70%;
  }
  @media screen and (max-width: 1220px) {
    width: 50%;
    min-width: 50%;
  }
  @media screen and (max-width: 1175px) {
    width: 188px;
    min-width: 188px;
  }
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Container = styled.div`
  display: flex;
  width: 375px;
`;

const Img = styled.img`
  min-width: 150px;
  max-width: 150px;
  height: 60px;
  border-radius: 12px 0px 0px 12px;
`;
// props =>
//     props.header
// 0px 452px 0px 15px

const ImgCardTag = (props: CardTagProps) => {
  const charCheck = text => {
    const value = text.split(".");
    if (value[0].length > 20) {
      return `${value[0].substring(0, 14)}...${value[0].substring(
        value[0].length - 4
      )}.${value[1]}`;
    }

    return text;
  };


  return (
    <Container key={props.keys}>
      <Img src={props?.data?.file?.fileUrl} />
      {
        <TagAntd
          key={props.cardText}
          icon={
            props.usedFor === "selectItemWithLogo" && (
              <img src="/images/ProfileCreation/info.svg"></img>
            )
          }
          {...props}
          closable={!props.closable}
          closeIcon={
            props.closeIcon ? (
              <img src={props.closeIcon}></img>
            ) : (
              <img src="/images/xCircle.svg"></img>
            )
          }
          onClose={e => props.onClose(e)}
        >
          <LinkTo href={props?.data?.file?.fileUrl} target="_blank">
            <Label
              text={charCheck(props?.data?.imageName)}
              htmlFor="profileCreationCard"
              fontVariant="bold"
              cursorType="pointer"
            />
          </LinkTo>
        </TagAntd>
      }
    </Container>
  );
};

export default ImgCardTag;
