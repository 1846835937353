export const getUserScreenSize= () => {
    if(window.innerWidth < 640){
        return "mobileScreen"
    }
    return "desktopScreen"
};

export const checkForMobileScreen= () => {
  if(window.innerWidth < 640){
      return true
  }
  return false
};

export const checkForSmallerMobileScreen= () => {
    if(window.innerWidth < 390){
        return true
    }
    return false
  };
  