import React, { useEffect, useState } from "react";
import MultiSelect from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Button from "components/Button/Button";
import { CardTag } from "components/Card";
import InputWithIcon from "components/Input/InputBoxWithEdit/InputWithIcon/input";
import ThreeInputField from "components/Input/InputWithAddButton/ThreeInputField";
import TwoInputField from "components/Input/InputWithAddButton/TwoInputField";
import ThreeInputFieldWithEdit from "components/Input/InputWithEditAndDelete/ThreeInputFieldWithEdit";
import TwoInputFieldWithEdit from "components/Input/InputWithEditAndDelete/TwoInputFieldWithEdit";
import TextAreaWithHeader from "components/TextArea/TextAreaWithHeader/TextArea";
import {
  capabilitiesGet,
  capabilitiesPost,
  capabilitiesUpdate,
} from "reducers/capabilities";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import { showEditReducer } from "reducers/profileCreation";
import SessionStorageService from "services/SessionStorageService";
import { Discipline, Work } from "constants/mockData";
import { Modal } from "antd";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";

interface DisplayProps {
  hide?: boolean;
  onChange?: (e: any) => void;
}

const HeaderName = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
  display: flex;
  justify-content: space-between;
  color: #011759;
`;

const MultiSelectDropdown = styled(MultiSelect)`
  width: 550px;
  border-radius: 12px;
  height: 60px !important;
  color: #011759;
`;
const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentInputs = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;

const CapabiltyTitles = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #215ec9;
  margin-bottom: 40px;
`;
const DropdownWithTagDiv = styled.div`
  width: auto;
  height: auto;
  margin-bottom: 12px;
`;
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  row-gap: 10px;
  max-width: 550px;
`;
const HeaderTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  margin-bottom: 10px;

  letter-spacing: 0.1em;

  color: #011759;

  opacity: 0.7;
`;
const FormWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const InputIconWrapper = styled.div`
  margin-top: 12px;
`;
const InputCardIconWrapper = styled.div`
  margin-top: 12px;
  max-width: 550px;
`;
const TextAreaWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;
const CardContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;
const DisplayToggle = styled.div<DisplayProps>`
  display: ${props => (`${props.hide}` ? "block" : "none")};
  width: auto;
  height: auto;
`;

const PlantLocationWrapper = styled.div`
  max-width: 730px;
  display: flex;
  justify-content: space-between;
`;
const ErrorMessageAlignment = styled.div`
  margin-top: -20px;
`;
const BtnMargin = styled.div`
  margin-left: 30px;
`;
const Capabilities = ({ onChange }: DisplayProps) => {
  const history = useHistory();
  const capabilitiesState = useSelector((state: any) => state.capabilities);
  const buttonState = useSelector((state: any) => state.profileCreation);
  const dispatch = useDispatch();
  const { capabilitiesData, loaded } = capabilitiesState;
  let validateData = false;

  const [selectedWork, setSelectedWork] = useState([]);
  const [otherWorkState, setOtherWorkState] = useState(false);
  const [otherDisciplineState, setOtherDisciplineState] = useState(false);
  const [selectedDiscipline, setSelectedDiscipline] = useState([]);
  const [serviceDesc, setServiceDesc] = useState("");

  const [city, setCity] = useState("");
  const [capacityPlantLocation, setCapacityPlantLocation] = useState("");
  const [plantLocation, setPlantLocation] = useState([]);

  const [keyEquipment, setKeyEquipment] = useState([]);
  const [keyEquipmentName, setKeyEquipmentName] = useState("");
  const [keyEquipmentMake, setkeyEquipmentMake] = useState("");
  const [keyEquipmentCapacity, setkeyEquipmentCapacity] = useState("");
  const [saveState, setSaveState] = useState(false);

  const [natureOfWork, setNatureOfWork] = useState({
    selectedData: [],
    onChangeData: "",
  });

  const [discipline, setDiscipline] = useState({
    selectedData: [],
    onChangeData: "",
  });

  const [products, setProducts] = useState({
    selectedData: [],
    onChangeData: "",
  });

  const setUserData = () => {
    if (Object.keys(capabilitiesData)?.length) {
      setServiceDesc(capabilitiesData.serviceCapabilities.description);
      setSelectedWork(capabilitiesData.serviceCapabilities?.natureOfWork?.name || []);

      setNatureOfWork({
        selectedData: capabilitiesData.serviceCapabilities.natureOfWork?.others,
        onChangeData: "",
      });
      setDiscipline({
        selectedData: capabilitiesData.serviceCapabilities.discipline?.others,
        onChangeData: "",
      });
      setSelectedDiscipline(
        capabilitiesData.serviceCapabilities.discipline?.name
      );

      setProducts({
        ...products,
        selectedData: [...capabilitiesData.manufacturingCapabilities.products],
      });
      setKeyEquipment([
        ...capabilitiesData.manufacturingCapabilities.keyEquipments,
      ]);
      setPlantLocation([
        ...capabilitiesData.manufacturingCapabilities.plantLocation,
      ]);
    }
  };
  useEffect(() => {
    if (selectedWork?.length === 0) {
      setOtherWorkState(false);
      setNatureOfWork({
        selectedData: [],
        onChangeData: "",
      });
    }
    const checkOthers = selectedWork?.filter(item => {
      return item.value === "others";
    });
    if (checkOthers?.length) {
      setOtherWorkState(true);
    } else {
      setNatureOfWork({
        selectedData: [],
        onChangeData: "",
      });
      setOtherWorkState(false);
    }
  }, [selectedWork]);

  useEffect(() => {
    if (selectedDiscipline?.length === 0) {
      setOtherDisciplineState(false);
      setDiscipline({
        selectedData: [],
        onChangeData: "",
      });
    }

    const checkOthers = selectedDiscipline?.filter(item => {
      return item.value === "others";
    });

    if (checkOthers?.length) {
      setOtherDisciplineState(true);
    } else {
      setDiscipline({
        selectedData: [],
        onChangeData: "",
      });
      setOtherDisciplineState(false);
    }
  }, [selectedDiscipline]);

  useEffect(() => {
    dispatch(capabilitiesGet({}));
  }, []);

  useEffect(() => {
    if (capabilitiesData && Object.keys(capabilitiesData)?.length < 2) {
      showEditReducer(dispatch, (buttonState.editButton = false));
    } else {
      showEditReducer(dispatch, (buttonState.editButton = true));
    }
  }, [capabilitiesData]);

  useEffect(() => {
    if (
      capabilitiesData &&
      Object.keys(capabilitiesData)?.length !== 0 &&
      loaded
    ) {
      setUserData();
    //   setServiceDesc(capabilitiesData.serviceCapabilities.description);
    //   setSelectedWork(capabilitiesData.serviceCapabilities.natureOfWork?.name);

    //   setNatureOfWork({
    //     selectedData: capabilitiesData.serviceCapabilities.natureOfWork?.others,
    //     onChangeData: "",
    //   });
    //   setDiscipline({
    //     selectedData: capabilitiesData.serviceCapabilities.discipline?.others,
    //     onChangeData: "",
    //   });
    //   setSelectedDiscipline(
    //     capabilitiesData.serviceCapabilities.discipline?.name
    //   );

    //   setProducts({
    //     ...products,
    //     selectedData: [...capabilitiesData.manufacturingCapabilities.products],
    //   });
    //   setKeyEquipment([
    //     ...capabilitiesData.manufacturingCapabilities.keyEquipments,
    //   ]);
    //   setPlantLocation([
    //     ...capabilitiesData.manufacturingCapabilities.plantLocation,
    //   ]);
     }
  }, [capabilitiesData]);

  const warning = () => {
    Modal.warning({
      title: "Warning",
      content: "Please fill all the required field in order to save your data ",
      centered: true,
    });
  };

  const validateInput = () => {
    if (
      selectedWork?.length &&
      selectedDiscipline?.length &&
      serviceDesc !== undefined &&
      serviceDesc !== ""
    ) {
      validateData = true;
    } else {
      validateData = false;
    }
  };

  const handleClick = type => {
    if (type === "edit") {
      setSaveState(false);
      showEditReducer(dispatch, !buttonState.editButton);
    }
    validateInput();
    if (type === "save") {
      setSaveState(true);
      if (validateData) {
        showEditReducer(dispatch, !buttonState.editButton);
        if (Object.keys(capabilitiesData)?.length === 0) {
          dispatch(
            capabilitiesPost({
              gstn: `${SessionStorageService.get("gstNum")}`,
              vendorId: "asassa",
              serviceCapabilities: {
                natureOfWork: {
                  name: Object.values(selectedWork),
                  others: [...natureOfWork.selectedData],
                },
                discipline: {
                  name: Object.values(selectedDiscipline),
                  others: [...discipline.selectedData],
                },
                description: serviceDesc,
              },
              manufacturingCapabilities: {
                products: products.selectedData,
                plantLocation: [...plantLocation],
                keyEquipments: [...keyEquipment],
              },
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        } else {
          dispatch(
            capabilitiesUpdate({
              capabilitiesId: capabilitiesData.capabilitiesId,
              gstn: `${SessionStorageService.get("gstNum")}`,
              vendorId: "asassa",
              serviceCapabilities: {
                natureOfWork: {
                  name: Object.values(selectedWork),
                  others: [...natureOfWork.selectedData],
                },
                discipline: {
                  name: Object.values(selectedDiscipline),
                  others: [...discipline.selectedData],
                },
                description: serviceDesc,
              },
              manufacturingCapabilities: {
                products: products.selectedData,
                plantLocation: [...plantLocation],
                keyEquipments: [...keyEquipment],
              },
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        }
      } else {
        warning();
      }
    }
  };

  const handleNatureOfWorkChange = (e: any) => {
    setNatureOfWork({
      ...natureOfWork,
      onChangeData: e.target.value,
    });
  };

  const handleDisciplineChange = (e: any) => {
    setDiscipline({
      ...discipline,
      onChangeData: e.target.value,
    });
  };

  const handleProductsChange = (e: any) => {
    setProducts({
      ...products,
      onChangeData: e.target.value,
    });
  };

  const addNatureOfWorkTags = (e: any) => {
    natureOfWork.onChangeData?.length !== 0 &&
      !natureOfWork.selectedData.includes(natureOfWork.onChangeData) &&
      setNatureOfWork({
        ...natureOfWork,
        selectedData: [...natureOfWork.selectedData, natureOfWork.onChangeData],
      });
  };

  const addDisciplineTags = (e: any) => {
    discipline.onChangeData?.length !== 0 &&
      !discipline.selectedData.includes(discipline.onChangeData) &&
      setDiscipline({
        ...discipline,
        selectedData: [...discipline.selectedData, discipline.onChangeData],
      });
  };

  const addProductsTags = (e: any) => {
    products.onChangeData?.length !== 0 &&
      !products.selectedData.includes(products.onChangeData) &&
      setProducts({
        ...products,
        selectedData: [...products.selectedData, products.onChangeData],
      });
  };

  const handleCityChange = (e: any) => {
    setCity(e.target.value);
  };

  const handleCapacityChange = (e: any) => {
    setCapacityPlantLocation(e.target.value);
  };

  const addPlantation = () => {
    if (city?.length > 0 && capacityPlantLocation?.length > 0) {
      setPlantLocation([
        ...plantLocation,
        { city: city, plantCapacity: capacityPlantLocation },
      ]);
      setCity("");
      setCapacityPlantLocation("");
    }
  };

  const handlePlantLocationDelete = (e, value) => {
    setPlantLocation([
      ...plantLocation?.filter((item, index) => {
        return index !== value;
      }),
    ]);
  };

  const handleEquipmentNameChange = (e: any) => {
    setKeyEquipmentName(e.target.value);
  };
  const handleEquipmentMakeChange = (e: any) => {
    setkeyEquipmentMake(e.target.value);
  };
  const handleEquipmentCapacityChange = (e: any) => {
    setkeyEquipmentCapacity(e.target.value);
  };
  const handleTextChange = (e: any) => {
    setServiceDesc(e.target.value.trimStart());
  };

  const addkeyEquipment = () => {
    if (
      keyEquipmentName?.length > 0 &&
      keyEquipmentMake?.length > 0 &&
      keyEquipmentCapacity?.length > 0
    ) {
      setKeyEquipment([
        ...keyEquipment,
        {
          equipment: keyEquipmentName,
          make: keyEquipmentMake,
          capacity: keyEquipmentCapacity,
        },
      ]);
      setKeyEquipmentName("");
      setkeyEquipmentMake("");
      setkeyEquipmentCapacity("");
    }
  };

  const handleKeyEquipmentDelete = (e, value) => {
    setKeyEquipment([
      ...keyEquipment?.filter((item, index) => {
        return index !== value;
      }),
    ]);
  };

  const closeCard = (caseType, data: any) => {
    switch (caseType) {
      case "Close_Nature_Of_Work":
        setSelectedWork([
          ...selectedWork?.filter(item => {
            return data.value !== item.value;
          }),
        ]);
        break;
      case "Close_Nature_Of_Work_Add":
        setNatureOfWork({
          ...natureOfWork,
          selectedData: natureOfWork.selectedData?.filter(item => item !== data),
        });
        break;
      case "Close_Discipline":
        setSelectedDiscipline([
          ...selectedDiscipline?.filter(item => {
            return data.value !== item.value;
          }),
        ]);
        break;
      case "Close_Discipline_Add":
        setDiscipline({
          ...discipline,
          selectedData: discipline.selectedData?.filter(item => item !== data),
        });
        break;
      case "Close_Products_Add":
        setProducts({
          ...products,
          selectedData: products.selectedData?.filter(item => item !== data),
        });
        break;
      default:
    }
  };

  return (
    <>
      <ContentHeader>Capabilities</ContentHeader>
      <ContentInputs>
        <FormWrapper>
          <CapabiltyTitles>Service Capabilities</CapabiltyTitles>
          <DropdownWithTagDiv>
            <HeaderName
              style={{
                height: "18px",
                marginBottom: "10px",
                opacity: "0.7",
              }}
            >
              Nature of Work*
            </HeaderName>
            {!buttonState.editButton && (
              <MultiSelectDropdown
              hasSelectAll={false}
                overrideStrings={Work.overRideString}
                disabled={buttonState.editButton}
                options={Work.states}
                value={selectedWork?.length?selectedWork:[]}
                onChange={e => {
                  setSelectedWork(e);
                }}
                labelledBy="Select Nature Of Work"
              />
            )}

            {!(natureOfWork?.selectedData?.length || selectedWork?.length) &&
              saveState &&
              !buttonState.editButton && (
                <ErrorMessageAlignment>
                  <ErrorMessageComponent
                    name={"This field is required. Please update."}
                  />
                </ErrorMessageAlignment>
              )}

            <TagContainer>
              {selectedWork?.map(item => {
                return (
                  <CardTag
                    onClose={() => closeCard("Close_Nature_Of_Work", item)}
                    usedFor="selectItem"
                    cardText={item.value}
                    closable={buttonState.editButton}
                  />
                );
              })}
            </TagContainer>

            {!buttonState.editButton && otherWorkState && (
              <InputCardIconWrapper>
                <InputWithIcon
                  inputBoxSize="large"
                  usedFor="profileCreation"
                  importanceType="important"
                  buttonOnClick={(e: any) => {
                    addNatureOfWorkTags(e);
                  }}
                  placeholder="Enter nature of work for others"
                  // child="Key Customers"
                  onHandleChange={(e: any) => {
                    handleNatureOfWorkChange(e);
                  }}
                  // hidden={!buttonState.editButton}
                  withHeader={false}
                  hidden={!buttonState.editButton}
                />
              </InputCardIconWrapper>
            )}

            <TagContainer>
              {natureOfWork?.selectedData?.map(item => {
                return (
                  <CardTag
                    onClose={() => closeCard("Close_Nature_Of_Work_Add", item)}
                    usedFor="selectItem"
                    cardText={item}
                    closable={buttonState.editButton}
                  />
                );
              })}
            </TagContainer>
          </DropdownWithTagDiv>
          <DropdownWithTagDiv>
            <HeaderName
              style={{
                height: "18px",
                marginBottom: "10px",
                opacity: "0.7",
              }}
            >
              Discipline*
            </HeaderName>
            {!buttonState.editButton && (
              <MultiSelectDropdown
              hasSelectAll={false}
                overrideStrings={Discipline.overRideString}
                options={Discipline.states}
                value={selectedDiscipline?.length?selectedDiscipline:[]}
                onChange={e => {
                  setSelectedDiscipline(e);
                }}
                labelledBy="Select Discipline"
              />
            )}

            {!(discipline.selectedData?.length || selectedDiscipline?.length) &&
              saveState &&
              !buttonState.editButton && (
                <ErrorMessageAlignment>
                  <ErrorMessageComponent
                    name={"This field is required. Please update."}
                  />
                </ErrorMessageAlignment>
              )}

            <TagContainer>
              {selectedDiscipline?.map(item => {
                return (
                  <CardTag
                    onClose={() => closeCard("Close_Discipline", item)}
                    usedFor="selectItem"
                    cardText={item.value}
                    closable={buttonState.editButton}
                  />
                );
              })}
            </TagContainer>

            {!buttonState.editButton && otherDisciplineState && (
              <InputCardIconWrapper>
                <InputWithIcon
                  inputBoxSize="large"
                  usedFor="profileCreation"
                  importanceType="important"
                  buttonOnClick={(e: any) => {
                    addDisciplineTags(e);
                  }}
                  placeholder="Enter discipline for others"
                  onHandleChange={(e: any) => {
                    handleDisciplineChange(e);
                  }}
                  withHeader={false}
                  hidden={!buttonState.editButton}
                />
              </InputCardIconWrapper>
            )}

            <TagContainer>
              {discipline?.selectedData?.map(item => {
                return (
                  <CardTag
                    onClose={() => closeCard("Close_Discipline_Add", item)}
                    usedFor="selectItem"
                    cardText={item}
                    closable={buttonState.editButton}
                  />
                );
              })}
            </TagContainer>
          </DropdownWithTagDiv>

          <DropdownWithTagDiv>
            <TextAreaWrapper>
              <TextAreaWithHeader
                headerLabel="Detailed description of the services*"
                placeholder="Enter detailed description of your services provided with details of your industry, capacity and quality of the services"
                size="small"
                usedFor="profileCreation"
                buttonDisable={buttonState.editButton}
                value={serviceDesc}
                onChange={handleTextChange}
              />
              {!serviceDesc && saveState && !buttonState.editButton && (
                <ErrorMessageComponent
                  name={"This field is required. Please update."}
                />
              )}
            </TextAreaWrapper>
          </DropdownWithTagDiv>

          <CapabiltyTitles>
            Manufacturing capabilities (If applicable)
          </CapabiltyTitles>
          <DropdownWithTagDiv>
            <InputIconWrapper>
              <InputCardIconWrapper>
                <InputWithIcon
                  inputBoxSize="large"
                  usedFor="profileCreation"
                  importanceType="important"
                  buttonOnClick={(e: any) => {
                    addProductsTags(e);
                  }}
                  placeholder="Enter list of products you manufacture"
                  child="Products"
                  onHandleChange={(e: any) => {
                    handleProductsChange(e);
                  }}
                  hidden={!buttonState.editButton}
                  withHeader={true}
                  // hidden={buttonToggle}
                />
              </InputCardIconWrapper>
              <TagContainer>
                {products.selectedData.map(item => {
                  return (
                    <CardTag
                      onClose={() => closeCard("Close_Products_Add", item)}
                      usedFor="selectItem"
                      cardText={item}
                      closable={buttonState.editButton}
                    />
                  );
                })}
              </TagContainer>
              <CardContainer>
                <HeaderTitle>Plant Location</HeaderTitle>
                <TwoInputField
                  hide={!buttonState.editButton}
                  buttonOnclick={addPlantation}
                  onChangeFirstField={e => handleCityChange(e)}
                  onChangeSecondField={e => handleCapacityChange(e)}
                  firstFieldValue={city}
                  secondFieldValue={capacityPlantLocation}
                />
                {plantLocation?.length !== 0 &&
                  plantLocation.map((element, index) => {
                    return (
                      <TwoInputFieldWithEdit
                        hide={!buttonState.editButton}
                        indexValue={index}
                        valueFirstField={element.city}
                        valueSecondField={element.plantCapacity}
                        onHandleDelete={(e, value) => {
                          handlePlantLocationDelete(e, value);
                        }}
                      />
                    );
                  })}
              </CardContainer>
              <CardContainer>
                <HeaderTitle>Key Equipment</HeaderTitle>
                <ThreeInputField
                  hide={!buttonState.editButton}
                  buttonOnclick={addkeyEquipment}
                  onChangeFirstField={e => handleEquipmentNameChange(e)}
                  onChangeSecondField={e => handleEquipmentMakeChange(e)}
                  onChangeThirdField={e => handleEquipmentCapacityChange(e)}
                  firstFieldValue={keyEquipmentName}
                  secondFieldValue={keyEquipmentMake}
                  thirdFieldValue={keyEquipmentCapacity}
                />

                {keyEquipment.map((element, index) => {
                  return (
                    <ThreeInputFieldWithEdit
                      hide={!buttonState.editButton}
                      indexValue={index}
                      valueFirstField={element.equipment}
                      valueSecondField={element.make}
                      valueThirdField={element.capacity}
                      onHandleDelete={(e, value) => {
                        handleKeyEquipmentDelete(e, value);
                      }}
                    />
                  );
                })}
              </CardContainer>
            </InputIconWrapper>
          </DropdownWithTagDiv>
        </FormWrapper>
      </ContentInputs>
      <ButtonWrapper>
        <ProfileCreationButtons
          usedAt={"capabilities"}
          state={buttonState.editButton}
          handleStateChange={value => {
            if (value === "cancel") {
              showEditReducer(dispatch, !buttonState.editButton);
              setUserData();
            } else {
              handleClick(value);
            }
          }}
        ></ProfileCreationButtons>
        {/* {buttonState.editButton ? (
          <Button
            onClick={() => handleClick("edit")}
            variant="primary"
            size="middle"
            style={{ padding: "20px 105px" }}
          >
            Edit
          </Button>
        ) : (
          <Button
            onClick={() => handleClick("save")}
            variant="secondary"
            size="middle"
            style={{ padding: "20px 105px" }}
          >
            Save
          </Button>
        )}

        <BtnMargin>
          <Button
            disabled={!buttonState.editButton}
            onClick={() => {
              history.push("/people");
            }}
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Next Section
          </Button>
        </BtnMargin> */}
      </ButtonWrapper>
    </>
  );
};
export default Capabilities;
