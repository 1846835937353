import React from 'react';
import styled from 'styled-components';
import Button, { ButtonProps as AntButtonProps } from 'antd/es/button';
import { Sizes } from 'components/utils';

export interface ButtonProps extends AntButtonProps {
  $iconClass: string;
  onClick: () => void;
  variant?: 'primary' | 'secondary' | 'white' | 'blue' | 'tertiary' | 'danger' | 'red';
  size: Sizes;
  flexible?: boolean;
  buttonLoading?: boolean;
  color?: string;
  $isHovered?: boolean;
  $noBackgroundHover?: boolean;
  $prefixImg?: React.ReactNode;
  customIcon?: React.ReactNode;
}

export const ButtonBase = styled(Button)<any>`
  display: flex;
  width: auto;
  height: 100%;
  background: ${(props) => props?.$backgroundColor};
  border: none;
  align-items: center;
  &:hover {
    background: ${(props) => props?.$backgroundColor};
  }
  &:active,
  &:focus {
    background: ${(props) => props?.$backgroundColor};
  }
`;

export const Icon = styled.i<Partial<ButtonProps>>`
  &:before {
    color: ${(props) => props?.color};
    font-size: 20px;
  }
`;

export const Title = styled.div<any>`
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  color: ${(props) => props?.$color};
  margin-left: 10px;
`;
const IconWrapper = (props: ButtonProps) => {
  const { $iconClass } = props;
  const getColor = () => {
    if (props?.variant === 'primary') {
      return '#47C882';
    }
    return '#FFFFFF';
  };
  const color = getColor();
  return (
    <>
      <ButtonBase
        {...props}
        $backgroundColor={props?.$noBackgroundHover 
          ? '#011759' 
          : props?.$isHovered 
            ? '#215EC9'
            : '#011759'
        }
        $color={props?.variant === 'primary' ? '#47C882' : '#011759'}
      >
        {props?.customIcon || <Icon className={$iconClass} color={color} {...props} />}
        <Title $color={color}>{props?.title}</Title>
        {props?.$prefixImg}
      </ButtonBase>
    </>
  );
};

export default IconWrapper;
