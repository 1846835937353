export const Types = {
  ERFX_OPERATION_REQUEST: "erfx/ERFX_OPERATION_REQUEST",
  ERFX_OPERATION_FAILURE: "erfx/ERFX_OPERATION_FAILURE",

  GET_RFQ_QUOTATION_SUCCESS: "erfx/GET_RFQ_QUOTATION_SUCCESS",

  PUBLISH_RFQ_QUOTATION_FILE_SUCCESS: "erfx/PUBLISH_RFQ_QUOTATION_FILE_SUCCESS",
  
  PUBLISH_RFQ_QUOTATION_SUCCESS: "erfx/PUBLISH_RFQ_QUOTATION_SUCCESS",

  REMOVE_RFQ_QUOTATION_DOCUMENT_SUCCESS: "erfx/REMOVE_RFQ_QUOTATION_DOCUMENT_SUCCESS",

  SET_REQUEST_JOB_ID_SUCCESS: "erfx/SET_REQUEST_JOB_ID_SUCCESS",

  UPLOAD_NEW_VERSION_SUCCESS: "erfx/UPLOAD_NEW_VERSION_SUCCESS",

  GET_FILE_HISTORY_SUCCESS: "erfx/GET_FILE_HISTORY_SUCCESS",

  SET_QUOTATION_SUCCESS: "erfx/SET_QUOTATION_SUCCESS",

  RESET_QUOTATION_VERSION_SUCCESS: "erfx/RESET_QUOTATION_VERSION_SUCCESS",

  RESET_QUOTATION_SUCCESS: "erfx/RESET_QUOTATION_SUCCESS",

  SET_TCEHINACAL_NOTES: "erfx/SET_TCEHINACAL_NOTES",

  SET_COMMERCIAL_NOTES: "erfx/SET_COMMERCIAL_NOTES",

  SET_CLICK_ON_PRICING: "erfx/SET_CLICK_ON_PRICING",

  SET_SHOW_MONETIZATION_MODAL: "erfx/SET_SHOW_MONETIZATION_MODAL",

  SET_SHOW_PQR_MODAL: "erfx/SET_SHOW_PQR_MODAL",

  UPDATE_LAST_ACTIVITY_DATE_SUCCESS: "erfx/UPDATE_LAST_ACTIVITY_DATE_SUCCESS",

  GET_VENDOR_DATA_SHARING_CONSENT_SUCCESS:  "erfx/GET_VENDOR_DATA_SHARING_CONSENT_SUCCESS",

  SAVE_VENDOR_DATA_SHARING_CONSENT_SUCCESS:  "erfx/SAVE_VENDOR_DATA_SHARING_CONSENT_SUCCESS",

  SUBMIT_OPEN_QUOTATION: "erfx/SUBMIT_OPEN_QUOTATION",

  SUBMIT_PAST_EXPERIENCE_OR_PO_DOCS: "  erfx/SUBMIT_PAST_EXPERIENCE_OR_PO_DOCS"
};
