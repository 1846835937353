import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { notification } from "antd";
import { Popover } from "antd";
import { phoneRegExp } from "Authentication/Register/RegisterContainer/RegistrationRules";
import { UserInfoModal } from "ProfileCreation";
import { Button } from "components/Button";
import { InputWithEdit, InputWithHeader } from "components/Input";
import TwoInputWithEdit from "components/Input/InputBoxWithEdit/TwoInputBoxWithEdit/input";
import { Label } from "components/Label";
import { checkDuplicateMobile, checkFakeDuplicateMobile } from "reducers/auth";
import { showEditReducer } from "reducers/profileCreation";
import { userInfoGet, userInfoUpdate } from "reducers/userInfo";
import { UserInfo } from "ContextApi/ContextApi";
import { userInformationLocalizationData } from "ui-constants/localizationData";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";

export const FormikForm = styled(Form)`
  overflow: auto;
`;

const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentInputs = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  padding-bottom: 30px;
`;
const BasicInformationMeassage = styled.div`
  padding: 30px 46px 30px 30px;
  border-radius: 12px;
  height: 111px;
  width: 100%;
  background-color: ${props => `${props.theme.colors.lightGray}`};
`;

const ProfileName = styled.div`
  color: #011759;
  font-size: 14px;
  line-height: 23px;
  font-weight: 300;
`;
const InputFlexWrapper = styled.div`
  display: flex;
  padding-top: 40px;
  width: auto;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormWrapper = styled.div``;
const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const ImgTag = styled.img`
  width: 20px;
  height: 20px;
`;
const ImgDiv = styled.div`
  margin-top: 27px;
`;
const PopoverDiv = styled.div`
  font-size: 12px;
  color: #261c4b;
  font-weight: 400;
  width: 250px;
`;
export const ButtonDiv = styled.button`
  border: none;
  outline: none;
  background: transparent;

  display: flex;
  justify-content: center;
`;

const BtnMargin = styled.div`
  margin-left: 30px;
`;
const UserInfoTab = () => {
  const history = useHistory();
  const userInformation = {
    gstn: "",
    userInfoId: "",
    vendorId: "_id",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };
  const dispatch = useDispatch();
  const phoneRegex = /^(\+91\d{10})$/;

  const userInfoState = useSelector((state: any) => state.userInfoApi);
  const { userInfoData, error, userUpdateResponse, errorData } = userInfoState;

  const authState = useSelector((state: any) => state.auth);
  const { checkIsDuplicateMobile } = authState;

  const buttonState = useSelector((state: any) => state.profileCreation);
  const { editButton } = buttonState;

  const [userInfo, setUserInfo] = useState(userInformation);
  const [showPhoneValue, setshowPhoneValue] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    if (error) {
      dispatch(userInfoGet({}));
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      openNotification(errorData);
    }
  }, [error]);

  useEffect(() => {
    showEditReducer(dispatch, (buttonState.editButton = true));
  }, []);

  useEffect(() => {
    if (userInfoData !== null) {
      setUserInfo(userInfoData);
    }
  }, [userInfoData]);

  useEffect(() => {
    if (showPhoneValue != "" && showPhoneValue.length === 13) {
      if (userInfoData.phoneNumber !== showPhoneValue) {
        dispatch(
          checkDuplicateMobile(
            JSON.stringify({
              mobileNo: `${showPhoneValue}`,
            })
          )
        );
      } else {
        checkFakeDuplicateMobile(dispatch, false);
      }
    }
  }, [showPhoneValue]);

  useEffect(() => {
    if (checkIsDuplicateMobile) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
  }, [checkIsDuplicateMobile]);

  const validate = Yup.object({
    phoneNumber: Yup.string()
      .matches(
        phoneRegex,
        "Invalid ! please enter your mobile number in the correct format"
      )
      .max(13, "Please enter your mobile number in the correct format"),
  });

  const handleFormikValidation = e => {
    setUserInfo({ ...userInfo, [e.name]: e.value });
  };

  const handlePhoneBlur = e => {
    if (e != showPhoneValue) {
      setshowPhoneValue(e);
    }
  };

  const handleOnChange = e => {
    const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const numberRegex = /\d/;
    if (
      !specialChar.test(e.target.value) &&
      !numberRegex.test(e.target.value)
    ) {
      setUserInfo({ ...userInfo, [e.target.name]: e.target.value.trimStart() });
    }
  };

  const handleClick = type => {
    if (type === "edit") {
      showEditReducer(dispatch, !editButton);
    }
    if(type==="save"){
      dispatch(
        userInfoUpdate({
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          phoneNumber: userInfo.phoneNumber,
        })
      ).then(res => {
        if (!res?.mobileVerificationRequired) {
          dispatch(userInfoGet({}));
        }
      });
      showEditReducer(dispatch, !editButton);
    }
  };

  const openNotification = placement => {
    notification.error({
      message: `Error`,
      description: placement,
    });
  };

  return (
    <>
      <UserInfo.Provider value={[userInfo, setUserInfo]}>
        <Formik
          initialValues={userInformation}
          validationSchema={validate}
          onSubmit={values => {
            dispatch(
              userInfoUpdate({
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                phoneNumber: userInfo.phoneNumber,
              })
            ).then(res => {
              if (!res?.mobileVerificationRequired) {
                dispatch(userInfoGet({}));
              }
            });
            showEditReducer(dispatch, !editButton);
          }}
        >
          {formik => (
            <FormikForm>
              <ContentHeader>User Information</ContentHeader>
              <ContentInputs>
                {
                  <BasicInformationMeassage>
                    <Label
                      fontVariant="bold"
                      text={userInformationLocalizationData.heading}
                      htmlFor="profileHeader"
                    />
                    <ProfileName>
                      {userInformationLocalizationData.subHeading}
                    </ProfileName>
                  </BasicInformationMeassage>
                }

                <Wrapper>
                  <FormWrapper>
                    <InputFlexWrapper>
                      <TwoInputWithEdit
                        withHeader
                        usedFor="profileCreation"
                        buttonDisable={editButton}
                        inputBoxSize="semiMiddle"
                        inputType="text"
                        importance="important"
                        placeholder="Enter First Name"
                        data={userInfo}
                        onChange={e => {
                          handleOnChange(e);
                        }}
                      />
                    </InputFlexWrapper>
                    <InputFlexWrapper>
                      <InputWithEdit
                        withHeader
                        buttonDisable
                        usedFor="profileCreation"
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        label="Email Address"
                        name="email"
                        value={userInfo.email}
                        placeholder="Enter Email"
                        onChange={e => {
                          handleOnChange(e);
                        }}
                      >
                        Email
                      </InputWithEdit>
                    </InputFlexWrapper>
                    <InputFlexWrapper>
                      <InputWithHeader
                        withHeader
                        change={true}
                        buttonType={editButton}
                        usedFor="profileCreation"
                        inputBoxSize="large"
                        inputType="text"
                        importance="important"
                        label="Mobile Number"
                        name="phoneNumber"
                        error={formik.errors}
                        touch={formik.touched}
                        labelSize="12px"
                        value={userInfo.phoneNumber}
                        placeholder="Enter Mobile Number"
                        {...(formik.touched["phoneNumber"] &&
                          formik.errors["phoneNumber"] === undefined &&
                          handlePhoneBlur(formik.values.phoneNumber))}
                        onChange={e => {
                          handleFormikValidation(e);
                        }}
                      >
                        Mobile Number
                      </InputWithHeader>
                      {!editButton && (
                        <ImgDiv>
                          <Popover
                            placement="right"
                            content={
                              <PopoverDiv>
                                The updated mobile number will be used for all
                                future logins
                              </PopoverDiv>
                            }
                          >
                            <ImgTag src="/images/ProfileCreation/info.svg" />
                          </Popover>
                        </ImgDiv>
                      )}
                      {userUpdateResponse &&
                        userUpdateResponse.mobileVerificationRequired && (
                          <UserInfoModal phoneNum={userInfo.phoneNumber} />
                        )}
                    </InputFlexWrapper>
                  </FormWrapper>
                </Wrapper>
              </ContentInputs>
              <ButtonWrapper>
                <ProfileCreationButtons
        usedAt={"userInfo"}

                  state={editButton}
                  handleStateChange={value => {
                    if (value === "cancel") {
                      showEditReducer(dispatch, !buttonState.editButton);
                      setUserInfo(userInfoData);
                    } else {
                      handleClick(value);
                    }
                  }}
                ></ProfileCreationButtons>
                {/* {editButton ? (
                  <Button
                    onClick={() => handleClick("edit")}
                    variant="primary"
                    size="middle"
                    style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                  >
                    Edit
                  </Button>
                ) : (
                  <ButtonDiv>
                    <Button
                      onClick={() => handleClick("save")}
                      variant="secondary"
                      size="middle"
                      style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                      disabled={buttonDisable}
                    >
                      Save
                    </Button>
                  </ButtonDiv>
                )}

                <BtnMargin>
                  <Button
                    disabled={!buttonState.editButton}
                    onClick={() => {
                      history.push("/companyinfo");
                    }}
                    variant="secondary"
                    size="middle"
                    style={{ width: "253px", padding: "20px 0px 19px 0px" }}
                  >
                    Next Section
                  </Button>
                </BtnMargin> */}
              </ButtonWrapper>
            </FormikForm>
          )}
        </Formik>
      </UserInfo.Provider>
    </>
  );
};

export default UserInfoTab;
