export const USER_INVITE_REQUESTED = "inviteApi/USER_INVITE_REQUESTED";
export const USER_INVITE_SUCCESS = "inviteApi/USER_INVITE_SUCCESS";
export const USER_INVITE_FAILURE = "inviteApi/USER_INVITE_FAILURE";

export const GET_INVITEE_LIST_REQUESTED = "inviteApi/GET_INVITEE_LIST_REQUESTED";
export const GET_INVITEE_LIST_SUCCESS = "inviteApi/GET_INVITEE_LIST_SUCCESS";
export const GET_INVITEE_LIST_FAILURE = "inviteApi/GET_INVITEE_LIST_FAILURE";

export const RESEND_INVITE_REQUESTED = "inviteApi/RESEND_INVITE_REQUESTED";
export const RESEND_INVITE_SUCCESS = "inviteApi/RESEND_INVITE_SUCCESS";
export const RESEND_INVITE_FAILURE = "inviteApi/RESEND_INVITE_FAILURE";
// export const USERINFO_UPDATE_SUCCESS = "userInfoApi/USERINFO_UPDATE_SUCCESS";

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorData: false,
  userInviteResponse: null,
  otpError: false,
  resendInviteResponse: null,

  userUpdateResponse: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_INVITE_REQUESTED:
      return {
        ...state,
        loading: true,
        inviteResponse:null
      };

    case USER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        inviteResponse: action.result,
      };

    case USER_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        inviteResponse: action.result,
      };

      case RESEND_INVITE_REQUESTED:
      return {
        ...state,
        loading: true,
        resendInviteResponse: null,
      };

    case RESEND_INVITE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        resendInviteResponse: action.result,
      };

    case RESEND_INVITE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        resendInviteResponse: action.error.message,
      };

      case GET_INVITEE_LIST_REQUESTED:
        return {
          ...state,
          loading: true,
          inviteeListResponse: false,
        };
  
      case GET_INVITEE_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          inviteeListData: action.result.data,
        };
  
      case GET_INVITEE_LIST_FAILURE:
        return {
          ...state,
          loading: false,
          loaded: true,
          error: false,
          inviteeListResponse: action.error.message,
        };

    // case USERINFO_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     loaded: true,
    //     error: true,
    //     errorData: action.error.message,
    //   };
    default:
      return state;
  }
};
export const sendInvite = (data) => ({
  types: [USER_INVITE_REQUESTED, USER_INVITE_SUCCESS, USER_INVITE_FAILURE],
  promise: (client) => client.post("send_invite_api", {data}),
});
export const getInviteeList= (data) => ({
    types: [
      GET_INVITEE_LIST_REQUESTED,
      GET_INVITEE_LIST_SUCCESS,
      GET_INVITEE_LIST_FAILURE,
    ],
    promise: (client) => client.get("get_invitee_list", { }),
  })

  export const  resendInvite= (data) => ({
    types: [
      RESEND_INVITE_REQUESTED,
      RESEND_INVITE_SUCCESS,
      RESEND_INVITE_FAILURE,
    ],
    promise: (client) => client.post("resend_invite_api", { data }),
  })
// export const userInfoUpdate = (data) => {
//   return {
//     types: [USERINFO_REQUESTED, USERINFO_UPDATE_SUCCESS, USERINFO_FAILURE],
//     promise: (client) =>
//       client.post("userinfo_update_data", {
//         data,
//       }),
//   };
// };
