import React, { useState, useEffect } from "react";
import styled from "styled-components";

interface ValidatorProps {
  formikData?: any;
}

const ValidatorDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
const ImgDiv = styled.div`
  width: 20px;
  height: 20px;
`;
const Successimg = styled.div`
  background-image: url("/images/Auth/validated.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
`;
const Warningimg = styled.div`
  background-image: url("/images/Auth/cross.svg");
  width: 20px;
  height: 20px;
  background-size: cover;
`;
const Text = styled.div`
  padding-left: 8px;
  font-size: 12px;
  color: ${props => `${props.theme.colors.darkBlue}`};
`;
const ValidatorBox = styled.div`
  display: flex;
  padding-top: 15px;
`;
function Validator({formikData }: ValidatorProps) {
  const lowerRegex = /^[a-z.\s_-]+$/;
  const upperRegex = /^[A-Z.\s_-]+$/;
  const numorSpecialRegex = /^[0-9!@#$%^&*(),.?":{}|<>]/;
  const [passwordStrength,setPasswordStrength] = useState([]);

  const passwordValidate = (regex: any) => {
    let value = false;
    passwordStrength.map((char: string) => {
      if (value === false) {
        value = regex.test(char);
      }
    });
    return value;
  };
  useEffect(() => {
    setPasswordStrength(formikData.values.password.split(""));
  }, [formikData]);
  return (
    <ValidatorDiv>
      <ValidatorBox>
        <ImgDiv>
          {formikData.values.password.length >= 8 ? (
            <Successimg />
          ) : (
            <Warningimg />
          )}
        </ImgDiv>
        <Text>Use at least 8 characters</Text>
      </ValidatorBox>
      <ValidatorBox>
        <ImgDiv>
          {passwordValidate(lowerRegex) && passwordValidate(upperRegex) ? (
            <Successimg />
          ) : (
            <Warningimg />
          )}
        </ImgDiv>
        <Text>Use both upper and lower case letters</Text>
      </ValidatorBox>
      <ValidatorBox>
        <ImgDiv>
          {passwordValidate(numorSpecialRegex) ? (
            <Successimg />
          ) : (
            <Warningimg />
          )}
        </ImgDiv>
        <Text>Include atleast one number or special character</Text>
      </ValidatorBox>
    </ValidatorDiv>
  );
}

export default Validator;
