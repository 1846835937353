export const PUT_SHAREABLE_LINK_SUCCESS =
  "shareableLink/PUT_SHAREABLE_LINK_SUCCESS";

const initialState = {
preview:null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case PUT_SHAREABLE_LINK_SUCCESS:
      return {
        ...state,
        preview: action.result,
      };
    default:
      return state;
  }
};

export const putShareableLink = data => ({
  types: [PUT_SHAREABLE_LINK_SUCCESS,PUT_SHAREABLE_LINK_SUCCESS,PUT_SHAREABLE_LINK_SUCCESS],
  promise: client =>
    client.post("put_shareable_link",{data}),
});
