import axios from "axios";

export const FILEUPLOAD_REQUESTED = "fileUpload/COMPANYINFO_REQUESTED";
export const FILEUPLOAD_SUCCESS = "fileUpload/COMPANYINFO_SUCCESS";
export const FILEUPLOAD_FAILURE = "fileUpload/COMPANYINFO_FAILURE";
export const MULTIPLE_FILE_UPLOAD_REQUESTED = "fileUpload/MULTIPLE_FILE_UPLOAD_REQUESTED";
export const MULTIPLE_FILE_UPLOAD_SUCCESS = "fileUpload/MULTIPLE_FILE_UPLOAD_SUCCESS";
export const MULTIPLE_FILE_UPLOAD_FAILURE = "fileUpload/MULTIPLE_FILE_UPLOAD_FAILURE";

const initialState = {
  uploadLoading: false,
  uploadLoaded: false,
  uploadError: false,
  uploadErrorData: false,

  fileUploadData: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case FILEUPLOAD_REQUESTED:
      return {
        ...state,
        uploadLoading: true,
      };

    case FILEUPLOAD_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        uploadLoaded: true,
        uploadError: false,
        fileUploadData: action.result,
      };

    case FILEUPLOAD_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        uploadLoaded: true,
        uploadError: true,
        uploadErrorData: action.error.message,
      };

    default:
      return state;
  }
};

//upload_files_to_permanent_folder
export const fileUploadPost = (data) => ({
  types: [FILEUPLOAD_REQUESTED, FILEUPLOAD_SUCCESS, FILEUPLOAD_FAILURE],
  promise: (client) =>
    client.post("file_upload_post_data", {
      files: [{ key: "file", value: data }],
    }),
});

export const multipleFilesUploadPost = (multipleFilesFormData) => ({
  types: [MULTIPLE_FILE_UPLOAD_REQUESTED, MULTIPLE_FILE_UPLOAD_SUCCESS, MULTIPLE_FILE_UPLOAD_SUCCESS],
  promise: (client) => client.post("upload_multiple_files", { multipleFilesFormData }),
});

export const fileUploadPostWithPercentage = (data, onUploadProgress) => {
  const formData = new FormData();
  formData.append("file", data);
  return ({
    types: [FILEUPLOAD_REQUESTED, FILEUPLOAD_SUCCESS, FILEUPLOAD_FAILURE],
    promise: async (client) =>
      await axios.post(process.env.REACT_APP_API_URL + "/v1/file/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      }),
  })
}

export const fileUploadPermanent = (data) => {
  let filesData = [];
  filesData.push(data);
  return ({
    types: [FILEUPLOAD_REQUESTED, FILEUPLOAD_SUCCESS, FILEUPLOAD_FAILURE],
    promise: async (client) =>
      await client.post("file_upload_post_data_permanent", { data: filesData }),
  })
};