import React, { useState } from 'react'
import { RiCloseFill } from 'react-icons/ri';
import { Box, Card, Divider, Flex, Modal, Typography } from 'venwiz-ui'
import { CLICKED_REVIEW_INITIAL_STATE } from './constants';
import { useMediaQuery } from 'hooks/useMediaQuery.hook';
import { DEVICE } from 'ui-constants/mediaQueries.constants';
import Rating from 'components/Rating/Rating';
import { colors } from 'Theme';

interface IProps {
  handleClose: () => void;
  showReviewModal: boolean;
  clickedReview: typeof CLICKED_REVIEW_INITIAL_STATE;
}

const COLORS = {
  grey_8: "#8d9095",
  black_2: "#303030",
  black_3: "rgba(81, 79, 110, 1)"
};

export const ViewReviewModal = (props: IProps) => {
  const { clickedReview } = props;
  const [isShowMore, setIsShowMore] = useState(false);
  const isDesktopView = useMediaQuery(DEVICE.xs);
  const DETAIL_REVIEW_LENGTH = clickedReview?.detailedReview && Object.keys(clickedReview?.detailedReview)?.length > 0;
  const SHOW_ELLIPSIS = isDesktopView || isShowMore
    ? clickedReview?.overallReview
    : clickedReview?.overallReview?.length > 100
    ? clickedReview?.overallReview?.slice(0, 100) + "..."
    : clickedReview?.overallReview?.slice(0, 100);  
  const showViewMore = !isDesktopView && !isShowMore && clickedReview?.overallReview?.length > 100;
  const showViewLess = !isDesktopView && isShowMore && clickedReview?.overallReview?.length > 100;

  return (
    <Modal
      show={props?.showReviewModal}
      handleClose={() => {
        props?.handleClose();
      }}
      closeIcon={
        <Box padding={"5px"} cursor={"pointer"}>
          <RiCloseFill size={20} color={"#666d77"} />
        </Box>
      }
      // To ensure that the modal is responsive and takes up the entire screen on mobile devices
      maxWidth={{
        default: "100vw",
        sm: "60vw",
      }}
      width={{
        default: "100vw",
        sm: DETAIL_REVIEW_LENGTH ? "75vw" : "40vw",
      }}
      maxHeight="90vh"
      height={{
        default: "100vh",
        sm: "auto",
      }}
      overflow="auto"
      modalBody={
        <Flex flexWrap="wrap" flexDirection={{ default: "column", sm: "row" }} gap={"20px"}>
          <Box
            width={{
              default: "100% !important",
              sm: DETAIL_REVIEW_LENGTH ? "49% !important" : "100% !important",
            }}
          >
            <Flex alignItems="center">
              <Flex
                width="56px"
                height="56px"
                borderRadius={"50%"}
                marginRight="20px"
                minWidth="56px"
                justifyContent="center"
                alignItems="center"
                border={`4px solid ${"#ffffff"}`}
                background="rgba(66, 99, 235, 0.1)"
                color="#215EC9"
                fontWeight={500}
                fontSize="28px"
              >
                {clickedReview?.clientDetails?.company?.charAt(0) || ""}
              </Flex>
              <Typography fontSize="16px" fontWeight="600" color={COLORS.black_3}>
                {clickedReview?.clientDetails?.company}
              </Typography>
            </Flex>
            <Box>
              <Typography fontSize="12px" fontWeight="400" color={COLORS.grey_8} marginTop="20px">
                Posted By{" "}
                <Typography variant="span" fontWeight="600" color={COLORS.black_3}>
                  {clickedReview?.clientDetails?.name}{" "}
                  {clickedReview?.clientDetails?.designation && `, ${clickedReview?.clientDetails?.designation}`}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography fontSize="12px" fontWeight="400" color={COLORS.grey_8}>
                Posted On{" "}
                <Typography variant="span" fontWeight="600" color={COLORS.black_3}>
                  {clickedReview?.createdAt?.split("T")?.[0]}
                </Typography>
              </Typography>
            </Box>
            {!isDesktopView && <Divider marginTop="20px" />}
            <Box marginTop="20px">
              <Typography fontSize="40px" fontWeight="600" color={COLORS.black_2}>
                {clickedReview?.overallRating}
                <Typography variant="span" fontWeight="600" color={"black"} marginLeft="12px">
                  {Rating && (
                    <Rating
                      value={clickedReview?.overallRating}
                      color={"#FFCB45"}
                      disabledStatus
                      // size="40px"
                    />
                  )}
                </Typography>
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize="14px"
                fontWeight="400"
                color={COLORS.black_2}
                marginTop="20px"
                maxHeight={{
                  sm: "60vh",
                }}
                overflow={"auto"}
                textAlign="justify"
              >
                {SHOW_ELLIPSIS}
                {showViewMore && (
                  <Typography
                    variant="span"
                    fontSize="14px"
                    fontWeight="600"
                    color={colors.blue}
                    onClick={() => {
                      setIsShowMore(!isShowMore);
                    }}
                  >
                    View More
                  </Typography>
                )}
                {showViewLess && (
                  <Typography
                    variant="span"
                    fontSize="14px"
                    fontWeight="600"
                    color={colors.blue}
                    onClick={() => {
                      setIsShowMore(!isShowMore);
                    }}
                  >
                    {" "}
                    View Less
                  </Typography>
                )}
              </Typography>
            </Box>
            {!isDesktopView && <Divider marginTop="20px" />}
          </Box>
          {DETAIL_REVIEW_LENGTH && (
            <Box
              width={{
                default: "100% !important",
                sm: "48% !important",
              }}
              marginTop="15px"
            >
              <Typography fontSize="16px" fontWeight="600" color={COLORS.black_3} marginBottom="8px">
                Rating by Parameter
              </Typography>
              <Card>
                {clickedReview?.detailedReview &&
                  Object.keys(clickedReview?.detailedReview)?.map((key: string) => {
                    const VALUE = clickedReview?.detailedReview[key]?.rating || 0;
                    return (
                      <Flex flexDirection="column" marginBottom="21px">
                        <Flex alignItems="center">
                          <Typography fontSize="14px" fontWeight="500" color={colors.blue}>
                            {key.replace(/([A-Z])/g, " $1").replace(/^./, function (str: string) {
                              return str.toUpperCase();
                            })}
                          </Typography>
                          <Box marginLeft="16px">
                            {Rating && (
                              <Rating
                                // size="14px"
                                value={VALUE}
                                disabledStatus
                                color={"#FFCB45"}
                              />
                            )}
                          </Box>
                        </Flex>
                        <Flex>
                          <Typography
                            fontSize="14px"
                            fontWeight="400"
                            color={COLORS.black_2}
                            opacity="0.8"
                            textAlign="justify"
                          >
                            {clickedReview?.detailedReview[key]?.comment}
                          </Typography>
                        </Flex>
                      </Flex>
                    );
                  })}
              </Card>
            </Box>
          )}
        </Flex>
      }
      position="relative"
      modalHeader={<Box padding="4px"></Box>}
    />
  )
}
