import React, { useEffect, useState } from "react";
import MultiSelect from "react-multi-select-component";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Modal, Popover } from "antd";
import { Button } from "components/Button";
import { CardTag } from "components/Card";
import { DropdownWithHeader } from "components/Dropdown";
import { LogoImage } from "components/Image";
import InputWithHeaderForEdit from "components/Input/InputBoxWithEdit/inputHeader";
import InputWithIcon from "components/Input/InputBoxWithEdit/InputWithIcon/input";
import { Label } from "components/Label";
import { TextAreaWithHeader } from "components/TextArea";
import { dashboardPercentageGet } from "reducers/dashboardPercentage";
import { fileUploadPost } from "reducers/fileUpload";
import { searchCountry, searchState } from "reducers/locations";
import { showEditReducer } from "reducers/profileCreation";
import {
  scopeOfWorkInfoCreate,
  scopeOfWorkInfoGet,
  scopeOfWorkInfoPost,
} from "reducers/scopeOfWorkInfo";

import openNotification from "constants/antdNotification";
import { DropdownData } from "constants/mockData";
import { InputWithEdit } from "components/Input";
import ErrorMessageComponent from "components/ErrorMessage/ErrorMesssage";
import { useHistory } from "react-router-dom";
import ProfileCreationButtons from "components/ProfileCreationButtons";
import NewUploadImage from "components/NewUploadImage/NewUploadImage";
import InputBoxForEdit from "components/Input/InputBoxWithEdit/inputBox";

const HeaderName = styled.div`
  width: auto;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 10%;
  display: flex;
  justify-content: space-between;
  color: #011759;
`;

const MultiSelectDropdown = styled(MultiSelect)`
  max-width: 550px;
  height: 60px !important;
  color: #011759;
  .clear-selected-button {
    display: none;
  }
`;
const InputCardIconWrapper = styled.div`
  margin-bottom: 12px;
  max-width: 550px;
`;
const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  height: 99px;
  color: ${props => `${props.theme.colors.blue}`};
  font-size: 20px;
  font-weight: ${props => `${props.theme.fontWeights.bold}`};
`;

const ContentInputs = styled.div`
  height: calc(100vh - 351px);
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar-thumb {
  }
  padding-bottom: 30px;
`;

const InputFlexWrapper = styled.div`
  display: flex;
  padding-bottom: 30px;
  width: 550px;

  justify-content: space-between;
`;

const Div = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
`;
const FormWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: flex;
  border-top: 1px solid #e5e5e5;
  padding: 19px 0px 20px 0px;
`;
const InputFlexColumn = styled.div`
  max-width: 550px;
  display: block;
  padding-bottom: 40px;

  max-width: 550px;
  display: block;
  padding-bottom: 40px;
`;
const InputFlex = styled.div`
  display: block;
  max-width: 550px;
  padding-bottom: 40px;
`;
const FlexWrapper = styled.div`
  padding-top: 12px;
  max-width: 550px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 12px;
  height: auto;
`;
const InputWrap = styled.input`
  position: relative;
  right: 0px;
  left: -170px;
  opacity: 0;
  z-index: 1;
  width: 160px;
  height: 60px;
  top: 27px;
  bottom: 0px;
`;
const BrochureDiv = styled.div`
  display: flex;
`;
const InputCountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  justify-content: flex-start;
  max-height: 190px;
  width: 260px;
  overflow: auto;
`;
const LabelWrap = styled.div`
  padding-bottom: 10px;
  padding=-top: 10px;
`;
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  row-gap: 10px;
  max-width: 550px;
`;
const ErrorMessageAlignment = styled.div`
  margin-top: -5px;
`;

const BtnMargin = styled.div`
  margin-left: 30px;
`;

const ScopeOfWork = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  let validateData = false;
  const [saveState, setSaveState] = useState(false);
  const buttonState = useSelector((state: any) => state.profileCreation);
  const scopeOfWorkInfoState = useSelector(
    (state: any) => state.scopeOfWorkInfo
  );
  const { scopeOfWorkInfoGetData } = scopeOfWorkInfoState;

  const percentageState = useSelector(
    (state: any) => state.dashboardPercentage
  );
  const { percentageData } = percentageState;

  const locationState = useSelector((state: any) => state.locations);
  const { countryLoading, countryLoaded, stateLoading } = locationState;
  const [isIndia, setIsIndia] = useState(false);
  const [selectedIndustriesServed, setSelectedIndustriesServed] = useState([]);
  const [aboutUs, setAboutUs] = useState("");
  const [keyCustomers, setKeyCustomers] = useState("");
  const [keyCustomerData, setKeyCustomerData] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [fileName, setFileName] = useState("Add your company brochure");
  const [tempDoc, setTempDoc] = useState([]);
  const [galleryData, setGalleryData] = useState<any>();
  const [otherIndustriesServedState, setOtherIndustriesServedState] = useState(
    false
  );
  const [otherIndustriesServed, setOtherIndustriesServed] = useState({
    selectedData: [],
    onChangeData: "",
  });

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [locationServed, setLocationServed] = useState([]);
  const [selectedState, setSelectedState] = useState(undefined);

  const setUserData = () => {
    if (Object.keys(scopeOfWorkInfoGetData)?.length) {
      const {
        aboutUs,
        industryServed,
        locationServed,
        keyCustomers,
        companyBrochure,
      } = scopeOfWorkInfoGetData;
      setAboutUs(aboutUs);
      setSelectedIndustriesServed(industryServed?.name);
      setOtherIndustriesServed({
        selectedData: industryServed?.others,
        onChangeData: "",
      });
      if (locationServed && locationServed.length > 0) {
        setLocationServed([...locationServed]);
        setKeyCustomerData(keyCustomers);
        setCertificate([...companyBrochure]);
      }
    }
  };
  useEffect(() => {
    if (selectedIndustriesServed.length === 0) {
      setOtherIndustriesServedState(false);
    }
    selectedIndustriesServed.map(item => {
      if (item.value == "others") {
        setOtherIndustriesServedState(true);
      } else {
        setOtherIndustriesServedState(false);
      }
    });
  }, [selectedIndustriesServed]);

  useEffect(() => {
    if (percentageData?.scopeOfWork > 50) {
      showEditReducer(dispatch, (buttonState.editButton = true));
    } else {
      showEditReducer(dispatch, (buttonState.editButton = false));
    }
    dispatch(scopeOfWorkInfoGet({}));
  }, [percentageData]);

  useEffect(() => {
    if (selectedIndustriesServed.length === 0) {
      setOtherIndustriesServedState(false);
      setOtherIndustriesServed({
        selectedData: [],
        onChangeData: "",
      });
    }
    const checkOthers = selectedIndustriesServed.filter(item => {
      return item.value === "others";
    });
    if (checkOthers.length) {
      setOtherIndustriesServedState(true);
    } else {
      setOtherIndustriesServed({
        selectedData: [],
        onChangeData: "",
      });
      setOtherIndustriesServedState(false);
    }
  }, [selectedIndustriesServed]);

  useEffect(() => {
    if (scopeOfWorkInfoGetData?.industryServed) {
      setUserData();
    }
  }, [scopeOfWorkInfoGetData]);

  const getFile = (e: any) => {
    let file = e.target.files[0];
    const dupFileFlag = certificate?.some(
      (item: any) => item?.name === file?.name
    );
    if (typeof file !== "undefined" && file?.size < 20971520 && !dupFileFlag) {
      setFileName(file?.name);
      dispatch(fileUploadPost(file)).then(
        res => {
          setCertificate([...certificate, { name: file?.name, file: res }]);
          setTempDoc([]);
          setFileName("Add your company brochure");
        },
        error => {
          setTempDoc([]);
        }
      );
      setTempDoc([{ name: file?.name, file: { fileSize: file?.size } }]);
    } else {
      if (file?.size > 20971520) {
        openNotification(
          "File size exceeds the 20Mb limit. Please try uploading a smaller file size"
        );
      }
    }
  };

  const handleAddKey = () => {};
  const warning = () => {
    Modal.warning({
      title: "Warning",
      content: "Please fill all the required field in order to save your data ",
      centered: true,
    });
  };

  const handleClick = type => {
    let keyCustomersArray;
    if (keyCustomers !== "") {
      keyCustomersArray = keyCustomers
        .split(",")
        .map(keyCustomer => keyCustomer.trim());
    }
    const saveData = {
      aboutUs,
      industryServed: {
        name: selectedIndustriesServed,
        others: otherIndustriesServed.selectedData,
      },
      locationServed: [...locationServed],
      keyCustomers: keyCustomerData,
      companyBrochure: certificate,
    };

    setSaveState(true);
    if (type === "save") {
      if (validateData) {
        showEditReducer(dispatch, !buttonState.editButton);
        if (
          scopeOfWorkInfoGetData &&
          scopeOfWorkInfoGetData.sowId &&
          keyCustomersArray
        ) {
          dispatch(
            scopeOfWorkInfoPost({
              ...saveData,
              keyCustomers: [...saveData.keyCustomers, ...keyCustomersArray],
              sowId: scopeOfWorkInfoGetData.sowId,
              gallery: [...galleryData.images],
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        } else if (scopeOfWorkInfoGetData && scopeOfWorkInfoGetData.sowId) {
          dispatch(
            scopeOfWorkInfoPost({
              ...saveData,
              sowId: scopeOfWorkInfoGetData.sowId,
              gallery: [...galleryData.images],
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        } else {
          dispatch(
            scopeOfWorkInfoCreate({
              ...saveData,
            })
          ).then(res => dispatch(dashboardPercentageGet({})));
        }
      } else {
        warning();
      }
    }
    if (type == "edit") {
      showEditReducer(dispatch, !buttonState.editButton);
    }
  };
  const renderCountry = (element: string) => {
    setSelectedCountry(
      countryList.filter(item => {
        return item.name === element;
      })
    );
    let flag = false;
    locationServed.map(item => {
      if (item?.country === element) return (flag = true);
    });
    if (!flag) {
      setLocationServed([...locationServed, { country: element, state: [] }]);
    }

    if (element === "India") {
      setIsIndia(true);
    } else {
      setIsIndia(false);
    }
  };

  const getCountry = (element: string) => {
    if (!countryLoading) {
      dispatch(
        searchCountry({
          key: element,
        })
      ).then(
        res => {
          setCountryList(res?.data);
        },
        error => {}
      );
    }
  };

  const renderState = (element: string) => {
    let countryIndex = null;
    setSelectedState(element);
    locationServed.map((item, index) => {
      if (item.country === selectedCountry[0]?.name) {
        return (countryIndex = index);
      }
    });
    if (countryIndex !== null) {
      const dupData = locationServed;
      dupData[countryIndex] = {
        ...dupData[countryIndex],
        state: [...dupData[countryIndex].state, element],
      };
      setLocationServed([...dupData]);
    }
    // if (!locationServed[0].state.includes(element)) {
    //   const dupData = locationServed;
    //   dupData[0] = {
    //     ...dupData[0],
    //     state: [...dupData[0].state, element],
    //   };
    //   setLocationServed([...dupData]);
    // }
    setSelectedState([]);
  };

  const getState = (element: string) => {
    if (!stateLoading) {
      dispatch(
        searchState({
          countryCode: selectedCountry[0]?.code,
          state: element,
        })
      ).then(
        res => {
          setStateList(res?.data);
        },
        error => {}
      );
    }
  };

  const handleChange = (e: any) => {
    setKeyCustomers(e.target.value);
  };

  const handleAboutUsChange = value => {
    setAboutUs(value.trimStart());
  };
  const handleIndustriesServedChange = (e: any) => {
    setOtherIndustriesServed({
      ...otherIndustriesServed,
      onChangeData: e.target.value,
    });
  };
  const addIndustriesServedTags = (e: any) => {
    otherIndustriesServed.onChangeData.length !== 0 &&
      !otherIndustriesServed.selectedData.includes(
        otherIndustriesServed.onChangeData
      ) &&
      setOtherIndustriesServed({
        ...otherIndustriesServed,
        selectedData: [
          ...otherIndustriesServed.selectedData,
          otherIndustriesServed.onChangeData,
        ],
      });
  };

  const closeCard = (caseType, data: any) => {
    switch (caseType) {
      case "Close Industry Served":
        setSelectedIndustriesServed([
          ...selectedIndustriesServed.filter(item => item.value !== data.value),
        ]);
        break;
      case "Close_Industries_Served_Add":
        setOtherIndustriesServed({
          ...otherIndustriesServed,
          selectedData: otherIndustriesServed.selectedData.filter(
            item => item !== data
          ),
        });
        break;
      case "Close Key Customer":
        setKeyCustomerData(keyCustomerData.filter(item => item !== data));
        break;
      case "Close Uploaded File":
        setFileName("Add your company brochure");
        setCertificate(certificate.filter(item => item !== data));
        break;
      default:
    }
  };
  const onCloseState = (item, index) => {
    setLocationServed(
      locationServed.map(data => {
        if (data.state.includes(item)) {
          data.state.splice(index, 1);
        }
        return data;
      })
    );
  };
  const onCloseCountry = item => {
    setLocationServed(
      locationServed.filter(data => {
        return item !== data?.country;
      })
    );
  };

  if (
    certificate.length === 0 ||
    selectedIndustriesServed.length === 0 ||
    locationServed.length === 0 ||
    (keyCustomerData.length === 0 && keyCustomers.length === 0)
  ) {
    validateData = false;
  } else {
    validateData = true;
  }
  return (
    <>
      <ContentHeader>Scope of Work</ContentHeader>
      <ContentInputs>
        <FormWrapper>
          <InputFlexWrapper>
            <TextAreaWithHeader
              headerLabel="About Us"
              usedFor="profileCreation"
              size="small"
              placeholder="Short description about your company"
              buttonDisable={!buttonState.editButton ? false : true}
              onChange={(e: any) => handleAboutUsChange(e.target.value)}
              value={aboutUs}
            />
          </InputFlexWrapper>
          <InputFlexColumn>
            <HeaderName
              style={{
                height: "18px",
                marginBottom: "10px",
                opacity: "0.7",
              }}
            >
              Industries Served*
            </HeaderName>
            {!buttonState.editButton && (
              <MultiSelectDropdown
                hasSelectAll={false}
                disableSearch={true}
                overrideStrings={DropdownData.overRideString}
                disabled={buttonState.editButton}
                options={DropdownData.industries}
                value={selectedIndustriesServed}
                onChange={e => {
                  setSelectedIndustriesServed(e);
                }}
                labelledBy="Nature Of Work"
              />
            )}
            <ErrorMessageAlignment>
              {selectedIndustriesServed.length === 0 && saveState && (
                <ErrorMessageComponent
                  name={"Please select Industries served"}
                />
              )}
            </ErrorMessageAlignment>
            <FlexWrapper>
              {selectedIndustriesServed.map(item => {
                return (
                  <CardTag
                    onClose={() => closeCard("Close Industry Served", item)}
                    usedFor="selectItem"
                    cardText={item.value}
                    closable={buttonState.editButton ? true : false}
                  />
                );
              })}
            </FlexWrapper>
          </InputFlexColumn>
          {!buttonState.editButton && otherIndustriesServedState && (
            <InputCardIconWrapper>
              <InputWithIcon
                inputBoxSize="large"
                usedFor="profileCreation"
                importanceType="important"
                buttonOnClick={(e: any) => {
                  addIndustriesServedTags(e);
                }}
                placeholder="Enter industries served  for others"
                onHandleChange={(e: any) => {
                  handleIndustriesServedChange(e);
                }}
                withHeader={false}
                hidden={!buttonState.editButton}
              />
            </InputCardIconWrapper>
          )}
          {otherIndustriesServed.selectedData && (
            <TagContainer>
              {otherIndustriesServed.selectedData.map(item => {
                return (
                  <CardTag
                    onClose={() =>
                      closeCard("Close_Industries_Served_Add", item)
                    }
                    usedFor="selectItem"
                    cardText={item}
                    closable={buttonState.editButton}
                  />
                );
              })}
            </TagContainer>
          )}
          <LabelWrap>
            <Label
              fontVariant="medium"
              htmlFor="scopeOfWork"
              text="Locations Served"
            />
          </LabelWrap>
          <InputFlexWrapper>
            <DropdownWithHeader
              hide={buttonState.editButton ? false : true}
              placeholder="Select Country"
              sizes="medium"
              data={countryList.map((item, index) => {
                return { id: index, value: item.name };
              })}
              onChange={(element: string) => {
                renderCountry(element);
              }}
              onSearch={(element: string) => {
                getCountry(element);
              }}
              usedFor="profileCreation"
              headerFlag={true}
              buttonChild={buttonState.editButton ? false : true}
              buttonText="Clear"
              onButtonClick={() => {
                setLocationServed([]);
                // setCountryList([]);
                // setStateList([]);
                // setSelectedCountry([]);
                // setSelectedState(undefined);
              }}
              // value={selectedCountry?.[0]?.name || undefined}
              // value={selectedCountry?.[0]?.name || undefined}
            >
              Country*
            </DropdownWithHeader>
            <DropdownWithHeader
              disabled={!isIndia}
              hide={buttonState.editButton ? false : true}
              placeholder="Select State"
              sizes="medium"
              data={stateList.map((item, index) => {
                return { id: index, value: item.name };
              })}
              onChange={(element: string) => renderState(element)}
              onSearch={(element: string) => getState(element)}
              usedFor="profileCreation"
              headerFlag={true}
              buttonChild={buttonState.editButton ? false : true}
              buttonText="Clear"
              onButtonClick={() => {
                if (locationServed && locationServed?.length > 0) {
                  setLocationServed(
                    locationServed.filter(item => {
                      return item?.state.splice(0);
                    })
                  );
                }
                setSelectedState(undefined);
              }}
              value={selectedState}
            >
              State*
            </DropdownWithHeader>
          </InputFlexWrapper>
          {locationServed.length !== 0 && (
            <InputFlexWrapper>
              <InputCountryWrapper>
                {locationServed &&
                  locationServed?.length > 0 &&
                  locationServed.map((item, index) => {
                    return (
                      <CardTag
                        key={index}
                        onClose={() => {
                          onCloseCountry(item?.country);
                        }}
                        cardText={item?.country}
                        usedFor="selectItem"
                        // closable={buttonState.editButton ? true : false}
                        closable={buttonState.editButton ? true : false}
                      />
                    );
                  })}
              </InputCountryWrapper>

              <InputCountryWrapper>
                {locationServed &&
                  locationServed?.length > 0 &&
                  locationServed.map((item, index) => {
                    return item?.state?.map((item, index) => {
                      return (
                        <CardTag
                          key={index}
                          onClose={() => {
                            onCloseState(item, index);
                          }}
                          cardText={item}
                          usedFor="selectItem"
                          closable={buttonState.editButton ? true : false}
                        />
                      );
                    });
                  })}
              </InputCountryWrapper>
            </InputFlexWrapper>
          )}
          <ErrorMessageAlignment>
            {locationServed.length === 0 && saveState && (
              <ErrorMessageComponent name={"Please select Location served"} />
            )}
          </ErrorMessageAlignment>
          <InputFlexColumn>
            <InputWithEdit
              withHeader={true}
              usedFor="profileCreation"
              inputBoxSize="large"
              inputType="text"
              importance="important"
              label="Key Customers"
              placeholder="Key Customers"
              value={keyCustomers}
              name="keyCustomer"
              hidden={!buttonState.editButton}
              onChange={e => {
                handleChange(e);
              }}
            >
              {"Key Customers*"}
            </InputWithEdit>
            {keyCustomerData.length === 0 &&
              keyCustomers.length === 0 &&
              saveState && (
                <ErrorMessageComponent name={"Please add a key customer"} />
              )}
            <FlexWrapper>
              {buttonState.editButton &&
              !!keyCustomerData &&
              keyCustomerData.length === 0 ? (
                <div></div>
              ) : (
                !!keyCustomerData &&
                keyCustomerData.map((item, index) => {
                  return (
                    <CardTag
                      key={index}
                      onClose={() => closeCard("Close Key Customer", item)}
                      cardText={item}
                      usedFor="selectItem"
                      closable={buttonState.editButton ? true : false}
                    />
                  );
                })
              )}
            </FlexWrapper>
          </InputFlexColumn>
          <BrochureDiv>
            <InputWithHeaderForEdit
              withHeader={true}
              inputBoxSize="large"
              name="companyBrochure"
              usedFor="profileCreation"
              inputType="text"
              importance="important"
              children="Company Brochure*"
              placeholder={fileName}
              buttonDisable
              hidden={!buttonState.editButton}
            ></InputWithHeaderForEdit>
            {!buttonState.editButton && (
              <InputWrap
                type="file"
                onChange={getFile}
                accept="application/pdf,image/jpeg"
              />
            )}

            {!buttonState.editButton && (
              <Button
                onClick={handleAddKey}
                variant="blue"
                size="small"
                style={{
                  borderRadius: "0px 8px 8px 0px",
                  width: "160px",
                  position: "relative",
                  left: "-330px",
                  top: "28px",
                  height: "60px",
                }}
              >
                Browse & Upload
              </Button>
            )}
          </BrochureDiv>
          {!buttonState.editButton && (
            <Wrapper>
              <Div>
                <LogoImage
                  imageSrc="/images/ProfileCreation/info.svg"
                  altMesg="edit button"
                  imageType="icon"
                />
              </Div>
              <Label
                fontVariant="bold"
                htmlFor="brochureSize"
                text="Supports .pdf and .jpeg formats. Max. file size 20mb."
              />
            </Wrapper>
          )}
          {certificate.length === 0 && saveState && (
            <ErrorMessageComponent name={"Please add 1 file"} />
          )}
          <InputFlex>
            {tempDoc.map(item => {
              return (
                <CardTag
                  cardText={item.name}
                  usedFor="fileUploadTag"
                  fileSize={item?.file?.fileSize / 1000000}
                  closable={true}
                  loading
                />
              );
            })}
            {certificate.map(item => {
              return (
                <a href={buttonState.editButton && item?.file?.fileUrl}>
                  <CardTag
                    onClose={() => closeCard("Close Uploaded File", item)}
                    cardText={item?.name}
                    usedFor="fileUploadTag"
                    fileSize={item?.file?.fileSize / 1000000}
                    closable={buttonState.editButton ? true : false}
                    cursorType={
                      (buttonState.editButton && "pointer") || "default"
                    }
                  />
                </a>
              );
            })}
          </InputFlex>

          <NewUploadImage
            buttonState={buttonState.editButton}
            onHandleUpload={imageList => {
              setGalleryData({
                images: [...imageList],
              });
            }}
            data={scopeOfWorkInfoGetData?.gallery}
          />
        </FormWrapper>
      </ContentInputs>
      <ButtonWrapper>
        <ProfileCreationButtons
          usedAt={"scopeOfWork"}
          state={buttonState.editButton}
          handleStateChange={value => {
            if (value === "cancel") {
              showEditReducer(dispatch, !buttonState.editButton);
              setUserData();
            } else {
              handleClick(value);
            }
          }}
        ></ProfileCreationButtons>
        {/* {buttonState.editButton ? (
          <Button
            onClick={() => handleClick("edit")}
            variant="primary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Edit
          </Button>
        ) : (
          <Button
            onClick={() => handleClick("save")}
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Save
          </Button>
        )}
        
  <BtnMargin>
          <Button
            disabled={!buttonState.editButton}
            onClick={() => {
              history.push("/capabilities");
            }}
            variant="secondary"
            size="middle"
            style={{ width: "253px", padding: "20px 0px 19px 0px" }}
          >
            Next Section
          </Button>
        </BtnMargin> */}
      </ButtonWrapper>
    </>
  );
};

export default ScopeOfWork;
