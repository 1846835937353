import React from 'react'
import styled from 'styled-components'

interface ImageProps {
    imageSrc: string,
    imageType: 'image',
    altMesg: string
}

const ImageBase = styled.img<Partial<ImageProps>>`
    position: absolute;
    top: -34px;
    width: 226px;
    height: 196px;
    object-fit: contain;
`

const Image = ({ imageSrc, imageType, altMesg }: ImageProps) => {
    return (
        <ImageBase src={imageSrc} alt={altMesg} />
    )
}

export default Image