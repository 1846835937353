import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useField } from "formik";
import Input from "../InputBox/input";
import InputWithHeader from "../InputWithHeader/input";
import AntButton, { ButtonProps as AntButtonProps } from "antd/es/button";
import { Button, Tooltip } from "antd";
import InputWithHeaderForEdit from "../InputBoxWithEdit/inputHeader";
import InputBoxForEdit from "../InputBoxWithEdit/inputBox";

type Sizes =
  | "small"
  | "semiMiddle"
  | "medium"
  | "middle"
  | "large"
  | "extraLarge"
  | "profileCreationmedium"
  | "profileCreationSmall"
  | undefined;

export interface InputBoxProps {
  hide?: boolean,
  buttonOnclick?: () => void,
  onChangeFirstField?: (e) => void,
  onChangeSecondField?: (e) => void,
  firstFieldValue?: string,
  secondFieldValue?: string

}
const Div = styled.div`
margin-left:10px;
`
const ButtonAntD = styled(AntButton)`
  width: 100px;
  height: 60px;
  border-radius: 8px;
`;
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  margin-bottom: 12px;
  
`;

const TooltipText = styled.span`

  width: 120px;
  background-color: #555;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  
  &:after{
    content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
  }`;
const TwoInputField = ({ hide = true, ...props }: InputBoxProps) => {
  const [disable, setDisable] = useState(true);
  const inputRef = useRef(null);

  return (
    <>
      <Wrapper>

        <InputWithHeaderForEdit
          placeholder={"Enter City Name"}
          usedFor={"profileCreation"}
          inputBoxSize={"large"}
          inputType={"text"}
          importance="important"
          name={"city"}
          hidden={hide}
          onHandleChange={props.onChangeFirstField}
          value={props.firstFieldValue}
        // error={props.formikError}
        // touch={props.touchedResponse}
        >
          Enter City Name
        </InputWithHeaderForEdit>

        {hide && <>
          <Tooltip placement="topLeft" title={"Enter the number of units of product that are produced by the plant and the time period (per week or month)"}>
          <InputBoxForEdit
          sizes={"profileCreationSmall"}
          inputTextType={"text"}
          importanceType={"important"}
          onHandleChange={props.onChangeSecondField}
          value={props.secondFieldValue}
          // fields={field}
          // otherProps={props}
          // setRef={refValue}
          // disabled={disable}
          placeholder={"Enter Capacity"}
        // buttonType={buttonType}
        />
      </Tooltip>
       
         </>
        }
        {hide && <Div><ButtonAntD onClick={props.buttonOnclick} type="primary">Add</ButtonAntD></Div>}

      </Wrapper>
    </>
  );
};

export default TwoInputField;
